//import { useCallback } from "react";
//import { useSelector } from "react-redux";
import { Box, Grid, Divider, Avatar, IconButton,
  //styled, CardContent, CardMedia,
} from "@mui/material"; //Stack, styled,
//import { grey } from "@mui/material/colors";
import moment from "moment";
import { SA_IMG_BASE_URL, SA_ASSETS_PATH } from 'config/app.config';
import { FeedView, getSMediaIcons } from 'config/ui.config';
import { Typography, AlbumPost,
  Card, CardTextWrapper, CardMediaWrapper
} from 'core';
import AdmCSuggestCommandBar from './AdmCSuggestCommandBar';
import { fGetRefDates, fGetStrAbbr, fStrToColor, fGetStrSlug,
  fGetNumAbbr, fNormalizeTextContent
} from 'lib/appFx';
//---------------------------------------------------------

//
//---------------------------------------------------------

export default function AdmCSuggestCard ({
  theme, cSPData, acsPMethods, dDir='col',
}) {
  //cSPData?.postId === 'DRde7Lva' && console.log('cSPDataCard', cSPData);
  //---------------------------------------------------------

  return (
    <Box id={'ctnAcsPost_'+cSPData.postId} className='bgWh' >
      <Card className='xwA'>
        <AdmCSCardHead cSPData={cSPData} />
        <Divider />
        <AdmCSCardContent cSPData={cSPData} dDir={dDir} />
        {/*<Divider />*/}
        {cSPData.pStatus === 'pending' && (
          <AdmCSuggestCommandBar
            cSPData={cSPData} acsPMethods={acsPMethods}
          />
        )}
      </Card>
    </Box>
  );
}
//=========================================================
//---------------------------------------------------------

const sMIcnSx = {width: '2.29rem', height: '2.29rem'};
const usrNameSx = {
  paddingTop: '0.1rem', fontSize: '1.13rem', fontWeight: '700',
  color: '#9e9e9e',
};
const txSmSx = {
	paddingTop: '0.1rem', fontSize: '1rem',  fontWeight: '400',
  color: '#9e9e9e',
	maxWidth: {xs: '6rem', vsm: '8rem', sm: '9rem', md: '7rem', lg: '11rem'},
	maxHeight: '1.7rem', overflow: 'clip', //overFlowY: 'clip',	  
};
//---------------------------------------------------------

const AdmCSCardHead = ({cSPData}) => {
  //console.log('cSPData', cSPData);
  //const sADateRefs = fGetRefDates();//console.log('sADateRefs', sADateRefs);
  //const csPDate = moment(cSPData.postDateTime).
  //  format(sADateRefs.momentDateTimeDispFormat);
  const csPDate = moment(cSPData.postDateTime).fromNow();
  const userName = cSPData.pUser.dispName;
  const profilePic = cSPData.pUser.profilePic;

  const fGetUserAvtar = (uData) => {
    const rBG = fStrToColor(uData.dispName);
    //const rBG = '#e9e9e9';
    return (
      <Avatar className='icnSq' variant="square"
        sx={{bgcolor: rBG, width: '2.29rem', height: '2.29rem'}}
        alt={uData.dispName} title={uData.dispName}
        src={uData.profilePic}
      >{fGetStrAbbr(uData.dispName)}</Avatar>
    );
  };
  //---------------------------------------------------------

  return (
    <Box className='dInFNoW'>
      <Box className='dInFNoW'>
        {fGetUserAvtar(cSPData.pUser)}
        <Box ml={1.5}>
          <Typography sx={usrNameSx}>
            {userName}
          </Typography>
          <Typography sx={txSmSx}>
            {csPDate}
          </Typography>
        </Box>
      </Box>
      <Box className=''>
        <IconButton size='small'
          sx={{cursor: 'default',}}//sMIcnSx
          //onClick={fHandleClickSM}
          fontSize="1rem"
        >
          {getSMediaIcons(cSPData?.sMKey, 'initCap')}
        </IconButton>
      </Box>
    </Box>
  );
};
//=========================================================
//---------------------------------------------------------

const AdmCSCardContent = ({cSPData, dDir='xcol'}) => {
  //if(cSPData?.postId === 'D2ZKBVek') { console.log('cSPDataCC', cSPData); }
  //const readMoreThreshold = 280;
  //const defPImgSrc = SA_IMG_BASE_URL + '/app/404_img2.jpg';
  const defPImgSrc = SA_ASSETS_PATH + '/imgs/app/404_img2.jpg';
  let pText = cSPData.pText;//+'';
  //className='ctnCardText', ctnCardMedia, cardMediaImg
  const txCardOpt = {
    flgEnableCollapse: true,
    //flgEnableCollapse: false,
    //toggleThreshold: readMoreThreshold,
  };
  const txSmallSx = {
    fontStyle: 'italic',
    color: '#808080',//theme.palette.secondary.light,
    textDecoration: 'none',
    fontWeight: 400,
    size: 'xsmall'
  };
  //----------------------
  //if(cSPData.postId === 'ywoek14M') {console.log('cSPDataCntn', cSPData); }

  let acsPMedia;
  if(cSPData.hasMedia) {
    if(!pText.length) { dDir = 'col'; }
    const mediaSrc = cSPData?.pLeadImg ?? defPImgSrc;
    //const mediaSrc = cSPData?.pImg || defPImgSrc;
    //const mediaSrc = cSPData?.pImg;
    const pMedia = {
      urls: [mediaSrc],
      type: 'img',//img|album|vdo
      mediaType: 'image/jpg',
    };//console.log('pMedia', pMedia);
    const mediaCardOpt = {
      dispDir: dDir,
      //forceFixHeight: false,
      forceFixHeight: dDir !== 'col' ,
      lazyLoad: true,
    };

    acsPMedia = (
      <CardMediaWrapper mediaContent={pMedia} cardOption={mediaCardOpt} />
      );
  } else { dDir = 'col';}
  //----------------------

  let txNotes, lblTx='', reviewerJsx, reviewText='';
  if(cSPData?.pApprover?.name?.length) {
    const reviewDate = cSPData?.postReviewDateTime ?
      moment(cSPData.postReviewDateTime).fromNow() : '';
    reviewerJsx = (
      <Typography display="inline" sx={txSmallSx}>
        {//' (By '+cSPData.pApprover.name+reviewDate+')'
          'Reviewed '+reviewDate+' (By '+cSPData.pApprover.name+')'
        }
      </Typography>
    );
  }

  switch(cSPData?.pStatus.toLowerCase()) {
    case 'rejected':
      lblTx = 'Reason';
      txNotes = cSPData?.pApprover?.notes || 'NA';
      reviewText = (
        <Box mt={0.5}>
          {lblTx}<span className="txB">: </span>{txNotes}
        </Box>
      );
      break;
    case 'approved':
      lblTx = 'Notes';
      txNotes = cSPData?.pApprover?.notes || '';
      reviewText = '';/*(
        <Box mt={0.5}>
          {lblTx}<span className="txB">: </span>{txNotes}
        </Box>
      );*/
      break;
  }

  if(reviewerJsx || reviewText) {
    txNotes = (
      <>
        <Divider /><br/>
        <Box>
          {reviewerJsx && reviewerJsx}
          {reviewText && reviewText}
        </Box>
      </>
    );
  }
  //----------------------
  //---------------------------------------------------------

  return (
    <>
      <Box className='' my={'1rem'}
        sx={{ padding: !cSPData.hasMedia && 0,
          //[theme.breakpoints.down('sm')]: { padding: 0 },
        }}
      > { dDir === 'col' ? (
          <>
            <CardTextWrapper textContent={pText} cardOption={txCardOpt} />
            { acsPMedia }
          </>
        ) : (
          <Grid container rowSpacing={0} columnSpacing={0}
            columns={{ xs: 4, sm: 8, md: 12 }}
            alignItems={'top'}//'center' //justifyContent={'center'}
            className=''
          >
            <Grid item xs={6} className='xtBdr2'>
              <CardTextWrapper textContent={pText} cardOption={txCardOpt} />
            </Grid>
            <Grid item xs={6} className='xtBdr2'>
              { acsPMedia }
            </Grid>
          </Grid>
        )}
      </Box>
      { txNotes }
    </>
  )
};
//---------------------------------------------------------
