import { createSlice } from '@reduxjs/toolkit';
import { sSApi } from './sSApi';
import { API_BASE_URL, SA_DEF_ORG_GROUP_ID } from 'config/app.config';
//---------------------------------------------------------

export const orgFeedApi = sSApi.injectEndpoints({
  endpoints: (builder) => ({
    feedAction: builder.mutation({
      query: (params) => {//console.log('fAParams', params);
        return {
          url: '/feed/action',
          method: 'POST',
          body: params,
        };
      },
    }),
    orgFeed: builder.query({
      //query: (params) => { return { url: aUrl, body: params };},
      query: (params) => {
        let aUrl,
          orgId,
          page,
          grpFilterIds = [],
          selSMIds = [];
        page = params?.page ? params?.page : 1;
        orgId = params?.orgId; //orgId = SA_DEF_ORG_ID;
        grpFilterIds = params?.orgGroupIds || [];
        if (!grpFilterIds?.length) {
          grpFilterIds = [SA_DEF_ORG_GROUP_ID];
        }
        aUrl =
          '/company/' + orgId +
          '/feed?group_filter_ids=' +
          JSON.stringify(grpFilterIds);
        //JSON.stringify([SA_DEF_ORG_GROUP_ID]);
        if (params?.filterSMIds?.length) {
          selSMIds = JSON.stringify(params?.filterSMIds);
          aUrl += '&snetworkplatform_ids=' + selSMIds;
        }
        aUrl += '&page=' + page;
        return { url: aUrl, method: 'GET' };
      },
      /*serializeQueryArgs: ({endpointName}) => {
        return endpointName;
      },
      merge: (currentCache, newItems) => {
        //currentCache.push({...newItems});
        //currentCache.results.push(...newItems.results);
      },
      forceRefetch({currentArg, previousArg}) {
        return currentArg !== previousArg
      },*/
    }),
    orgBroadcast: builder.query({
      query: (params) => {
        let aUrl, orgId, page;
        orgId = params.orgId;
        page = params?.page ? params?.page : 1;
        aUrl = '/company/' + orgId + '/broadcasts?page=' + page;
        return { url: aUrl };
      },
    }),
    broadcast: builder.query({
      query: (params) => {
        let aUrl,
          page,
          filters = [];
        page = params?.page ? params?.page : 1;
        filters = params?.filters;
        filters = JSON.stringify(filters);
        aUrl = '/broadcast?page=' + page;
        aUrl += 'filter=' + filters;
        return { url: aUrl };
      },
    }),
    /*orgLeaderboard: builder.query({
      query: (params) => {
        let aUrl, orgId, page, groupId;
        orgId = params.orgId;
        page = params?.page ? params?.page : 1;
        groupId = params?.groupId ? params.groupId : 1;
        aUrl = '/company/' + orgId + '/leaderboard?group_id=' + groupId;
        aUrl += '&page=' + page;
        return { url: aUrl };
      },
    }),*/
    orgFeedCompData: builder.query({
      async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
        let page = _arg?.page;
        !page && (page = 1);
        let forAdmin = '0';
        if (_arg?.forAdmin) {
          forAdmin = parseInt(_arg?.forAdmin) ? '1' : '0';
        }
        const dInf = await fetchWithBQ(
          '/broadcast?page=' + page + '&filter=[]'
        );
        if (dInf.error) throw dInf.error;
        let aUrl =
          API_BASE_URL + '/company/' + _arg?.orgId + '/feed?for_admin=' + forAdmin;
        _arg?.grpFilterIds &&
          (aUrl += 'group_filter_ids=' + JSON.stringify(_arg.grpFilterIds));
        aUrl += '&page=' + page;
        const dInf2 = await fetchWithBQ(aUrl);
        if (dInf2.error) throw dInf2.error;
        return dInf.data?.broadcasts && dInf2.data?.feed
          ? { data: [dInf.data, dInf2.data] }
          : dInf.data && dInf2.data
            ? { data: [dInf.data, dInf2.data] }
            : { error: [dInf.error, dInf2.error] };
      },
    }),
    userFeedList: builder.query({
      query: (params) => {
        let aUrl;
        //if(params?.data) {
        const dataArray = params?.data ? Object.values(params.data) : [];
        const parameters = dataArray
          .filter((data) => { return data?.status; })
          .map((data) => { return data.id; });
        aUrl = `platform/feed?page=1&snetworkplatform_ids=${JSON.stringify(
          parameters
        )}`;
        //}
        return { url: aUrl };
      },
    }),
  }),
});
//---------------------------------------------------------

export const {
  useOrgFeedQuery,
  useLazyOrgFeedQuery,
  useFeedActionMutation,
  useOrgBroadcastQuery,
  //useOrgLeaderboardQuery,
  usePrefetch,
  useUserFeedListQuery,
} = orgFeedApi;
//---------------------------------------------------------

const fListFilterM = {
  all: { title: 'all', selected: false },
  fb: { title: 'facebook', selected: false },
  tw: { title: 'twitter', selected: false },
  yt: { title: 'youTube', selected: false },
  ln: { title: 'linkedIn', selected: false },
  bl: { title: 'blog', selected: false },
  bc: { title: 'broadcast', selected: false },
};
const sMUserFeedFilters = {
  selFilter: null,//XclusiveFilter
  filters: {
    fb: { status: false },//id:
    tw: { status: false },
    yt: { status: false },
    ln: { status: false },
    ig: { status: false },
  }
};
const orgFeedSlice = createSlice({
  name: 'orgFeed',
  initialState: {
    details: null,
    orgGroups: null,
    sMediaFilters: null,
    sortOpt: null,
    feedSearchText: null,
    sMediaFilterParams: null,
    sMediaUserFeedFilters: null,
  },
  reducers: {
    userSelectedFeed: (state, action) => {
      state.userSelectedFeed = action.payload;
    },
    userSubmittedFeed: (state, action) => {
      state.userSubmittedFeed = action.payload;
    },
    setFeedMetaInfo: (state, action) => {
      state.details = action.payload?.details;
    },
    initFeedListModifiers: (state, action) => {
      if (state.orgGroups === null) {
        let orgGrp = {};
        if (action.payload?.company) {
          action.payload.company.forEach((g) => {
            orgGrp[g.id] = {
              id: g.id,
              title: g.name,
              isDefault: false,
              selected: false,
              details: g,
            };
          });
          if (orgGrp.hasOwnProperty(SA_DEF_ORG_GROUP_ID)) {
            orgGrp[SA_DEF_ORG_GROUP_ID].isDefault = true;
            orgGrp[SA_DEF_ORG_GROUP_ID].selected = true;
          }
        }//console.log('rOrgGrp', orgGrp);
        state.orgGroups = orgGrp;
        state.sMediaFilters = fListFilterM;
        state.feedSearchText = '';
        //state.sortOpt = '';ByDate
      }
    },
    //resetOrgGroups: (state, action) => state.orgGroups = {},
    setSelectedGroupFilter: (state, action) => {//console.log('SSS', action.payload?.selGroups);
      const selGrps = action.payload?.selGroups;
      const oGs = state.orgGroups;
      Object.keys(oGs).forEach((gId) => {
        if (selGrps.indexOf(gId) > -1) {
          oGs[gId].selected = true;
        } else {
          oGs[gId].selected = false;
        }
      });
    },
    toggleFeedListFilterGroup: (state, action) => {
      const grpId = action.payload?.groupId;
      grpId &&
        (state.orgGroups[grpId].selected = !state.orgGroups[grpId].selected);
    },
    setSelectedSMFilter: (state, action) => {
      const selMKeys = action.payload?.selSMs;
      const sMKeys = state.sMediaFilters;
      for (let sMK in sMKeys) {
        if (selMKeys.includes(sMK)) {
          state.sMediaFilters[sMK].selected = true;
        } else {
          state.sMediaFilters[sMK].selected = false;
        }
      }
    },
    toggleFeedListFilterSM: (state, action) => {
      const sMKey = action.payload?.sMKey;
      sMKey &&
        (state.sMediaFilters[sMKey].selected =
          !state.sMediaFilters[sMKey].selected);
    },
    setFeedListSearchText: (state, action) => {
      state.feedSearchText = action.payload?.searchtext || '';
    },
    setMediaFilterParams: (state, action) => {
      state.sMediaFilterParams = action.payload || '';
    },
    initSMediaUserFeedFilters: (state, action) => {
      if (!state.sMediaUserFeedFilters) {
        state.sMediaUserFeedFilters = sMUserFeedFilters;
      }

      if (action.payload?.theSMData
        && !state.sMediaUserFeedFilters.selFilter
      ) {
        Object.keys(state.sMediaUserFeedFilters.filters).forEach((sK) => {
          if (action.payload.theSMData.hasOwnProperty(sK) &&
            action.payload.theSMData[sK]['activTokenId']) {
            state.sMediaUserFeedFilters.filters[sK].status = true;
          }
        });
      }//console.log('sMediaUserFeedFilters', state.sMediaUserFeedFilters);
    },
    setSMediaUserFeedXclusiveFilter: (state, action) => {
      if (action.payload?.sMKey &&
        state.sMediaUserFeedFilters.filters.hasOwnProperty(action.payload.sMKey)
      ) {
        Object.keys(state.sMediaUserFeedFilters.filters).forEach((sK) => {
          if (sK === action.payload.sMKey) {
            state.sMediaUserFeedFilters.filters[sK].status = true;
            state.sMediaUserFeedFilters.selFilter = sK;
          } else {
            state.sMediaUserFeedFilters.filters[sK].status = false;
          }
        });
      }
    },
    resetSMediaUserFeedFilters: (state, action) => {
      state.sMediaUserFeedFilters.selFilter = null;
      if (action.payload?.theSMData) {
        Object.keys(state.sMediaUserFeedFilters.filters).forEach((sK) => {
          if (action.payload.theSMData.hasOwnProperty(sK) &&
            action.payload.theSMData[sK]['activTokenId']) {
            state.sMediaUserFeedFilters.filters[sK].status = true;
          }
        });
      }
    },
  },
});

export default orgFeedSlice.reducer;
export const {
  setOrgFeedMetaInfo,
  initFeedListModifiers, //resetOrgGroups,
  //toggleFeedListFilterGroup, toggleFeedListFilterSM,
  setSelectedGroupFilter,
  setSelectedSMFilter,
  setFeedListSearchText,
  setMediaFilterParams,
  initSMediaUserFeedFilters,
  setSMediaUserFeedXclusiveFilter,
  //resetSMediaUserFeedFilters,
  userSelectedFeed,
  userSubmittedFeed,
} = orgFeedSlice.actions;

export const getOrgGroupsData = (state) => state.orgFeed.orgGroups;
export const getSMFilterData = (state) => state.orgFeed.sMediaFilters;
export const getSMFilterParams = (state) => state.orgFeed.sMediaFilterParams;
export const getUserSelectedFeed = (state) => state.orgFeed.userSelectedFeed;
export const getUserSubmittedFeed = (state) => state.orgFeed.userSubmittedFeed;
export const getFeedListModifiers = (state) => {
  let selGrps = [];
  let selSMFilter = [];
  if (state.orgFeed?.orgGroups) {
    const oG = state.orgFeed.orgGroups;
    for (let g in oG) {
      oG[g]?.selected && selGrps.push(oG[g].id);
    }
    selGrps.sort();
  }
  if (state.orgFeed?.sMediaFilters) {
    const sMF = state.orgFeed.sMediaFilters;
    for (let sK in sMF) {
      sMF[sK]?.selected && selSMFilter.push(sK);
    }
    selSMFilter.sort();
  }
  return {
    selGroups: selGrps,
    selSMKey: selSMFilter,
    //searchText: state.orgFeed.feedSearchText || ''
  };
};
export const getFeedListMetaData = (state) => {
  return {
    sMediaMgr: state.sMedia?.theSMgr?.media,
    sMIdKeyMap: state.sMedia?.sMIdKeyMap,
    feedListModifiers: getFeedListModifiers(state),
    authUserData: state.auth.details,//?
  };
};
export const getSMediaUserFeedFilters = state => {
  return state.orgFeed.sMediaUserFeedFilters;
}
//---------------------------------------------------------
