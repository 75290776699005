import { Grid } from "@mui/material";
import { Card, Typography } from "core";
import React from "react";

export default function BadgeCard({ theme }) {
	const aUsrName = 'Priya';//`${authUser?.first_name}`;
  return (
    <Grid container xs={12}>
      <Grid item>
        <Card>
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="center"
          >
            <Grid
              item
              alignItems="center"
              justifyContent="center"
              textAlign="center"
            >
              <Typography size="medium" fontWeight={900}>
                Well done, {aUsrName}
              </Typography>
              <Typography size="medium" fontWeight={900}>
                You can win this badge this week!
              </Typography>
            </Grid>
            <img
              src="/assets/images/badge.png"
              alt="badge"
              height={200}
              width={200}
            />
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
}
