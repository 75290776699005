import { Button, Avatar, Grid, Stack, Box } from "@mui/material";
import { grey } from "@mui/material/colors";
import { styled } from "@mui/system";
import { badges } from "config/ui.config";
import { Card, Typography } from "core";
import React from "react";
import { Link } from "react-router-dom";
import PlayCircleOutlinedIcon from "@mui/icons-material/PlayCircleOutlined";

const StyledViewAllButton = styled(Button)(({ theme }) => ({
  background: theme.palette.background.paper,
  color: grey[600],
  border: "2px solid grey",
  boxShadow: "none",
  padding: "0 10px",
  width: 90,
  display: "flex",
  justifyContent: "center",
  alignSelf: "center",
  textAlign: "center",
  "&:hover": {
    background: theme.palette.background.paper,
  },
}));

const users = [
  {
    name: " Dr.Tony Trpkovski",
    profile: "/assets/images/profile.png",
  },
  {
    name: "Courtney Cook",
    profile: "/assets/user/courtney.avif",
  },
  {
    name: "Alex Suprun",
    profile: "/assets/user/alex.avif",
  },
  {
    name: "Dahiana Waszaj",
    profile: "/assets/user/dahiana.avif",
  },
  {
    name: "Julian Wan",
    profile: "/assets/user/julian.avif",
  },
];
export default function BadgesHolder() {
  return (
    <Card sx={{ paddingY: "2rem",width: {lg:'270px'}, }}>
      <Grid
        container
        spacing={8}
        justifyContent="center"
        textAlign="left"
        flexDirection="column"
      >
        <Grid item>
          <Stack spacing={1} justifyContent="center" alignItems="center">
            {badges["butterfly"]}
            <Stack justifyContent="center" alignItems="center">
              <Typography fontSize="large" fontWeight={900}>
                Social Butterfly
              </Typography>
              <Typography fontSize="medium">Badge Holder</Typography>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack spacing={4} justifyContent="center" alignItems="center">
            {users.map(({ profile, name, total_points, position }, index) => (
              <Stack
                key={index}
                spacing={2}
                direction="row"
                alignItems="center"
                textAlign="left"
              >
                <Avatar
                  src={profile}
                  alt={name}
                  sx={{ height: 60, width: 60 }}
                />
                <Stack spacing={0.5} justifyContent="start" width="160px">
                  <Typography
                    color_variant="dark"
                    fontWeight={800}
                    size="small"
                  >
                    {name}
                  </Typography>
                  <Box sx={{ justifyContent: "start", display: "flex" }}>
                    <StyledViewAllButton component={Link} size="small">
                      View
                    </StyledViewAllButton>
                  </Box>
                </Stack>
              </Stack>
            ))}
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack justifyContent="center" alignItems="center">
            <PlayCircleOutlinedIcon sx={{ transform: "rotate(90deg)" }} />
          </Stack>
        </Grid>
      </Grid>
    </Card>
  );
}
