import { Box } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { forwardRef } from "react";
import PropTypes from "prop-types";
import { SA_APP_NAME } from "config/app.config";
import { AUTH_ROUTES } from "config/routes.config";

const style = { mt: 4 };
const PageContainer = forwardRef(
  ({ title, secondary, meta, children, disableMargin, ...rest }, ref) => {
    const pageTitle = () => {
      if (!title || title === AUTH_ROUTES.SignIn.title) {
        return SA_APP_NAME;
      }
      //return `${title} | SA_APP_NAME`;
      return title + ' | ' + SA_APP_NAME;
    };

    return (
      <>
        <Helmet>
          <title>{pageTitle()}</title>
          {meta}
        </Helmet>

        <Box sx={!disableMargin ? style : {}}>{children}</Box>
      </>
    );
  }
);

PageContainer.propTypes = {
  title: PropTypes.string.isRequired,
  meta: PropTypes.arrayOf([PropTypes.element]),
  children: PropTypes.element.isRequired,
  secondary: PropTypes.element,
  sx: PropTypes.object,
};

export default PageContainer;
