import { useState, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import moment from "moment";
//import { saveAs } from "file-saver";
import { yupResolver } from "@hookform/resolvers/yup";
import { Close } from "@mui/icons-material";
import { Box, Grid, Stack, Tab, Tabs,
  FormControl, ListItemText, MenuItem, Select,
  CardContent, Pagination, Divider,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { styled } from "@mui/system";
//import { IoCaretDownSharp } from "react-icons/io5";
import { SA_DEF_ORG_ID, MAX_FILE_UPLOAD_SIZE,
  SA_DATE_DISP_FORM, SA_DEF_ORG_GROUP_ID
} from 'config/app.config';
import { downloadImage } from 'lib/appFx';
//import { formatBytes } from 'lib/utils';
import { Button, Card, FormProvider, Typography } from 'core';
import { ContentSuggestionListSkeleton } from 'core/Skeletons';
import { ContentSuggestionSchema } from 'schema';
import {
  useContentSuggestionCreateMutation,
  useContentSuggestionListQuery,
} from 'services/contentSuggestionApi';
import {
  userSelectedFeed, getUserSelectedFeed,
  userSubmittedFeed, getUserSubmittedFeed,
} from 'services/orgFeedApi';
//import { selectUserOrgId } from 'services/auth/authSlice';
//import { useOrgUsersQuery } from 'services/orgApi';
//---------------------------------------------------------

const StyledAnalyticsHeader = styled(Stack)(({ theme, highlight }) => ({
  padding: '0.5rem',
  backgroundColor: theme.palette.background.section,
}));

const StyledButton2 = styled(Box)(({ theme }) => ({
  background: theme.palette.secondary.dark,
  color: theme.palette.background.paper,
  fontSize: '14px', padding: '8px 2rem',
}));
const StyledButton = styled(Button)(({ theme }) => ({
  maxWidth: '9.5rem', fontSize: '14px', fontWeight: 600,
  border: `3px solid ${grey[400]}`,
  boxShadow: 'none',
  //margin: '0.6rem 0 1.5rem 0', //margin: '1.5rem 0',
  color: theme.palette.secondary.dark,
  backgroundColor: theme.palette.background.paper,
  '&:hover': { backgroundColor: theme.palette.background.paper, },
}));
const StyledTab = styled(Tab)(({ theme }) => ({
  background: '#fff',
  border: '1px solid #e6e7e9',
  color: theme.palette.secondary.dark,
}));
const StyledGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: { maxWidth: '100%', flexBasis: '100%', },
}));
const StyledTextArea = styled('textarea')(({ theme }) => ({
  height: 300, border: 'none', padding: '1rem',
  fontSize: '15px', letterSpacing: '0.5px',
  fontFamily: "'Roboto','Helvetica','Arial',sans-serif",
  '&:focus::placeholder': { color: 'transparent', },
}));
const StyledStack = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
}));
const Error = styled('div')(({ theme }) => ({
  color: theme.palette.error.main,
}));
const SuccessBox = styled(Box)(({ theme }) => ({
  position: 'absolute', right: '20%', top: '3%',
  transition: 'ease-in-out',
  [theme.breakpoints.down('sm')]: { right: '6%', },
}));
const StyledTypography = styled(Typography)(({ theme }) => ({
  minWidth: 120, paddingRight: '1rem', fontWeight: 600,
  color: theme.palette.secondary.dark,
}));
const StyledFormControl = styled(FormControl)(({ theme }) => ({
  minWidth: 300, maxWidth: 300,
  margin: '1.5rem 0', outline: 'none',
  background: theme.palette.background.paper,
  '.MuiOutlinedInput-notchedOutline': { border: 0, outline: 0, },
  [theme.breakpoints.down('md')]: { minWidth: 200, },
}));
const StyledCloseIconContainer = styled(Box)(({ theme }) => ({
  position: 'absolute', top: -3, right: -120,
  padding: 2, alignItems: 'center', display: 'flex',
  background: '#c7c7c7', borderRadius: '100%',
}));
//---------------------------------------------------------

export default function SubmitContent({ title, theme }) {
  const getUserSubmittedFeeds = useSelector(getUserSubmittedFeed);
  const userSelectedFeedData = useSelector(getUserSelectedFeed);
  const dispatch = useDispatch();
  const [currCSId, setCurrCSId] = useState(null);
  const [stCSLstArgs, setStCSLstArgs] = useState({
    page: 1, filter: { is_approved: 1 }
  });
  const { state: userPostState } = useLocation();
  //const [stUserPostData, setStUserPostData] = useState(userPostState?.userPostData || []);
  const [stUserPostData, setStUserPostData] = useState(userPostState || null);
  console.log('stUserPostData', stUserPostData);
  useEffect(() => {
    if(stUserPostData && window) {
      window?.history?.replaceState({}, '');
      //const history = useHistory();
      //history.replace(`${history.location.state}`, {});
    }
  }, []);

  //console.log('userSelectedFeedData', userSelectedFeedData);
  //const successPopupRef = useRef();
  //const [isAdmDDOpen, setIsAdmDDOpen] = useState(false);
  //const fHandleAdmmDdChange = (event) => {event.stopPropagation()};

  const [createContentSuggestion, { isSuccess: cSPSUbmitted }] =
    useContentSuggestionCreateMutation();

  const { data: cSApiData,
    refetch: fRefetchCSList,
    isLoading: cSListWait, isFetching: cSListWait2,
    isError: cSListErr,//isSuccess: cSSuccessApi,
  } = useContentSuggestionListQuery(
    stCSLstArgs, {refetchOnMountOrArgChange: true}
  );
  const fHandleChangeTab = (event, newVal) => {
    let newFVal;
    switch(newVal.toLowerCase()) {
      case 'pending': newFVal = null; break;
      case 'rejected': newFVal = 0; break;
      case 'approved': default:  newFVal = 1; break;
    };
    setStCSLstArgs(prevStat => {
      const newStat = {...prevStat};
      newStat['filter'] = {is_approved: newFVal};
      newStat['page'] = 1;
      return newStat;
    });
  };
  const fHandleChangePage = (event, pVal) => {
    setStCSLstArgs(prevStat => ({
        ...prevStat, ...prevStat.filter,
        ...prevStat.filter.is_approved,
        page: pVal
    }));
    const fBar = document.querySelector('#ctnCSPList');
    if(fBar) {
      fBar.scrollIntoView({behavior: 'smooth'});
    }
  };
  //---------------------------------------------------------

  let pEdText = stUserPostData?.feedData?.text || '';
  if(stUserPostData?.pInfUP?.pText2Hd?.length) {//pEdText?.length &&
    pEdText += "\n" + stUserPostData?.pInfUP?.pText2Src;
    pEdText += "\n" + stUserPostData?.pInfUP?.pText2Hd;
    pEdText += "\n" + stUserPostData?.pInfUP?.pText2 + "\n";
  }
  const defaultValues = {
    /*media: userSelectedFeedData?.media?.pictures || [],
    text: userSelectedFeedData?.text || '',
    url: userSelectedFeedData?.url || '',*/
    media: stUserPostData?.feedData?.media?.pictures || [],
    text: pEdText, //stUserPostData?.feedData?.text || '',
    url: stUserPostData?.feedData?.url || '',
  };console.log('defVals', defaultValues);

  const handleUploadChange = (event) => {
    const mediaList = [...getValues('media')];

    if(event.target.files.length + mediaList.length <= 5) {
      setValue('media', [...mediaList, ...event.target.files]);
    } else if (mediaList.length <= 5) {
      setValue('media', [...mediaList]);
    } else {
      setValue('media', []);
    }
  };

  const methods = useForm({
    resolver: yupResolver(ContentSuggestionSchema),
    defaultValues,
  });
  const {
    handleSubmit, setValue, watch, register, getValues, formState: { errors },
  } = methods;
  //---------------------------------------------------------

  const removeSelectedImage = (index) => {
    let mediaList = getValues('media');
    mediaList.splice(index, 1);
    setValue('media', mediaList);
  };

  const onSubmit = async (data) => {
    let formData = new FormData();
    let mediaData;
    if(userSelectedFeedData) {
      mediaData = data.media.map(async (media, index) => {
        if(typeof media === 'string') {
          return await downloadImage(media, index);
        } else { return media; }
      });
    } else if(data?.media.length) {
      mediaData = data.media;
    }
    mediaData = mediaData && await Promise.all(mediaData);

    data?.media.length &&
      mediaData.map((file, index) =>
        formData.append(`media${index + 1}`, file)
      );
    formData.append('text', data.text);
    formData.append('group_id', SA_DEF_ORG_GROUP_ID);
    //admin_approver_uids = [], SA_TEMP_DEF_ADMIN_ID
    //console.log('BformData', Object.fromEntries(formData.entries()));

    let cSCreateResp = await createContentSuggestion({ params: formData });
    cSCreateResp = cSCreateResp?.data?.contentsuggestion_id ?
    cSCreateResp.data.contentsuggestion_id : null;

    if(cSCreateResp) {
      //dispatch(userSelectedFeed(null));
      await fRefetchCSList();
      if(!getUserSubmittedFeeds) {
        const getUserSubmitted = [userSelectedFeedData];
        dispatch(userSubmittedFeed(getUserSubmitted));
      } else {
        const getUserSubmitted = [...getUserSubmittedFeeds, userSelectedFeedData];
        dispatch(userSubmittedFeed(getUserSubmitted));
      }
      setValue('media', []);
      setValue('text', '');
      setCurrCSId(cSCreateResp);
      setStUserPostData([]);
      //await fRefetchCSList();
    }
  };

  useEffect(() => {
    //if(cSPSUbmitted === true) {
    if(currCSId) {
      setTimeout(() => {
        setCurrCSId(null);
        //successPopupRef?.current.remove();
      }, 4000);
    }
  }, [currCSId, cSPSUbmitted]);

  //Once the user leave the page remove the user selected feed
  /*useEffect(() => {
    return () => {// console.log('Unmounting')
      dispatch(userSelectedFeed(null));
    };
  }, [dispatch]);*/
  //---------------------------------------------------------
  
  //useEffect(() => { fRefetchCSList(); }, [cSApiData]);
  /*const contentList = useMemo(() => {
    //return cSApiData?.feed.filter(
    //  (content) => content.is_approved === status
    //);
    return cSApiData?.feed;
  }, [cSApiData, status]);*/

  const DdIcnC = () => {
    const ddIcSx = {
      position: 'absolute', top: '0.5rem', right: '0.5rem',
      display: 'inline-block', alignItems:'right',
      width: '1rem', height: '1rem', pointerEvents: 'none'
    };
    return (
      <img src="/images/arrow.png" alt="select"
        width="1rem" height="auto" style={ddIcSx} />
    );
  };
  const txSmallSx = {
    fontStyle: 'italic',
    color: '#808080',//theme.palette.secondary.light,
    textDecoration: 'none',
    fontWeight: 400,
    size: 'xsmall'
  };
  //---------------------------------------------------------
  
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)} disabled>
      <Grid container spacing={3} justifyContent="center" alignItems="center">
        <StyledGrid item xs={6}>
          <Stack spacing={3} position="relative">
            {//cSPSUbmitted === true && (
              currCSId && (
                <SuccessBox>
                  <img src="/assets/images/success.png"
                    alt="" style={{ height: 350, }} />
                </SuccessBox>
              )
            }
            <StyledAnalyticsHeader>
              <Typography size="medium" fontWeight={600}
                sx={{ justifyContent: 'center', display: 'flex' }}
              >
                {title}
              </Typography>
            </StyledAnalyticsHeader>
            <StyledTextArea name="text" minRows={10}
              {...register('text')}
              placeholder="Content/Message(You can also include a link)"
            />
            {Boolean(errors.text) ? <Error>{errors.text.message}</Error> : null}
            <Grid container spacing={1}
              sx={{
                display: 'grid',
                gridTemplateColumns: { xs: '1fr 1fr', md: '1fr 1fr 1fr', },
              }}
            >
              {watch('media').length
                ? watch('media').map((media, index) => {
                    //console.log('media'+index, media);
                    let source;// = media;
                    if(media && typeof media === 'string') {
                      source = media;
                    } else if(media && typeof media === 'object') {
                      source = URL.createObjectURL(media);
                    }
                    if(source) {
                      return (
                        <Grid item xs={6} md={3.2} position="relative" key={index}>
                          <StyledCloseIconContainer>
                            <Close onClick={() => removeSelectedImage(index)}
                              sx={{width: '0.9rem', height: '0.9rem'}}/>
                          </StyledCloseIconContainer>
                          <img src={source} alt="Profile"
                            style={{ width: 150, height: 150, }} />
                        </Grid>
                      );
                    }
                  })
                : null}
            </Grid>
            {Boolean(errors?.media) ? (
              <Error>{errors.media?.message}</Error>
            ) : null}
            <Stack spacing={1} direction="row" //className="tBdr2"
              alignItems="end" //justifyContent={'justify'}
              //alignContent={'center'}
              display={'flex'}
            >
              <Box className="xbdr1">
                <input accept=".jpg,.png,.jpeg,.jiff,.jfif" multiple max={5} maxLength={5}
                  type="file" name="media" id="contained-button-file"
                  style={{ display: 'none' }} onChange={handleUploadChange}
                />
                <label htmlFor="contained-button-file">
                  <Box sx={{ position: 'relative' }}>
                    <StyledButton2 size="small">
                      Add Picture
                    </StyledButton2>
                  </Box>
                </label>
              </Box>
              {/*<Typography fontWeight={600}>
                (JPEG / PNG upto {formatBytes(MAX_FILE_UPLOAD_SIZE)}} only)
              </Typography>*/}
              <Box sx={{
                  display: 'flex',
                  width: {xs: '55%', md: '67%', },
                  justifyContent: 'flex-end', marginLeft: 'auto'
                }}
              >
                <StyledButton type="submit" //disabled={watch('text').length === 0}
                   //sx={{marginLeft:"auto"}}
                   //display={'flex'}
                   //justifyContent={'flex-end'}
                >Submit
                </StyledButton>
              </Box>
            </Stack>
            {/*<Stack spacing={0}>
              <Typography size="small" fontWeight={600}
                color_variant="lightGrey"
              >
                Select the admin you wish to submit this post to
              </Typography>
              <StyledStack justifyContent="space-between" gap="14px" >
                <Box sx={{ position: 'relative', marginTop: '5px',}}>
                  <StyledFormControl size="small" sx={{marginTop: '10px',}} >
                    <Select //fullWidth
                      defaultValue="value" color="secondary"
                      inputProps={{MenuProps: {disableScrollLock: true}}}
                      className="select-box" IconComponent={DdIcnC}
                      //onChange={fHandleAdmmDdChange} //open={isAdmDDOpen}
                    >
                      <MenuItem value="value" //SA_TEMP_DEF_ADMIN_ID
                        sx={{ fontWeight: 'bold', fontSize: '16px', color: 'grey',}}
                      >
                        {'Tony Trpkvovski' || 'Gaurav M.'}
                      </MenuItem>
                    </Select>
                  </StyledFormControl>
                </Box>
                <StyledButton type="submit" //disabled={watch('text').length === 0}
                >Submit
                </StyledButton>
              </StyledStack>
            </Stack>*/}
            <Stack spacing={1} id='ctnCSPList'>
              <br/><br/>
              <Tabs variant="fullWidth"
                TabIndicatorProps={{ style: { display: 'none' } }}
                onChange={fHandleChangeTab}
                //value={status} onChange={fHandleChangeTab}
                //value={stCSLstArgs?.filter?.is_approved}
                //value={'approved'}
                value={
                  stCSLstArgs?.filter?.is_approved === null ?
                    'pending' : (
                      stCSLstArgs?.filter?.is_approved === 0 ?
                        'rejected' : 'approved'
                    )
                }
              >
                <StyledTab label="Pending" value={'pending'} />
                <StyledTab label="Accepted" value={'approved'} />
                <StyledTab label="Rejected" value={'rejected'} />
              </Tabs>
              <Card>
                <CardContent className="custScroll"
                  sx={{ maxHeight: 500, overflowY: 'auto' }}
                >
                  {cSListWait || cSListWait2 ? (
                    <ContentSuggestionListSkeleton />
                  ) : cSApiData?.feed?.length ? (
                    cSApiData?.feed.map((data, index) => {
                      let txNotes, reviewerJsx,
                        cImg = data?.media?.attachments;
                      cImg = cImg.length && cImg[0]['url'] ? cImg[0]['url'] : null;
                      //console.log('csData', data);
                      if(cImg) { cImg = cImg.replace('/storage/storage/', '/storage/'); }

                      if(data?.is_approved === false) {
                        const reviewDate = data?.approver?.reviewed_at ?
                          moment(data.approver.reviewed_at).fromNow() : '';
                        reviewerJsx = (
                          <Typography display="inline" sx={txSmallSx}>
                            {//' (By '+data.approver.name+reviewDate+')'
                              'Reviewed '+reviewDate+' (By '+data.approver.name+')'
                            }
                          </Typography>
                        );
                        txNotes = data?.approver?.notes || 'NA';
                        txNotes = (
                          <Box mt={0.5}>
                            {'Reason'}<span className="txB">: </span>{txNotes}
                          </Box>
                        );

                        if(reviewerJsx || txNotes) {
                          txNotes = (
                            <>
                              {/*<Divider />*/}<br/>
                              <Box>
                                {reviewerJsx && reviewerJsx}
                                {txNotes && txNotes}
                              </Box>
                            </>
                          );
                        }
                      }

                      return (
                        <CardContent key={index}>
                          <Stack>
                            <Stack direction="row"
                              justifyContent="left" alignItems="top"
                            >
                              <StyledTypography> Content:</StyledTypography>
                              <Typography className="ctnCardText">
                                {((data?.text !== 'null' && data?.text !== null) &&
                                  data?.text?.length) ? data.text : ''
                                }
                              </Typography>
                            </Stack>
                            {cImg && 
                              (<Stack direction="row" mt={2}>
                                <StyledTypography>&nbsp;</StyledTypography>
                                <Typography>
                                  <img src={cImg} width="80%" height="auto"
                                    alt={data?.text.substring(0, 15)} />
                                </Typography>
                              </Stack>)
                            }
                            <Stack direction="row" mt={2}>
                              <StyledTypography>Created Date:</StyledTypography>
                              <Typography>
                                {moment(data.created_at).format(SA_DATE_DISP_FORM)}
                              </Typography>
                            </Stack>
                          </Stack>
                          {txNotes}
                          <Divider flexItem
                            sx={{marginTop: '0.8rem',
                              //bgcolor: "secondary.light"
                              //borderWidth: '1px'
                            }}
                          />
                        </CardContent>);
                    })
                  ) : (
                    <ListItemText primary="No data available" />
                  )}
                </CardContent>
                <Stack spacing={2} mt={2}>
                  <Pagination
                    count={cSApiData?.pagination?.page?.total}
                    page={stCSLstArgs.page}
                    onChange={fHandleChangePage}
                  />
                </Stack>
              </Card>
            </Stack>
          </Stack>
        </StyledGrid>
      </Grid>
    </FormProvider>
  );
}
