import ERRORS from "config/validation.config";
import * as yup from "yup";

const ForgotPasswordSchema = yup.object({
  email: yup
    .string()
    .email(ERRORS.EMAIL.invalid)
    .required(ERRORS.EMAIL.required),
  role: yup.string().required(ERRORS.ROLE.required),
});

export default ForgotPasswordSchema;
