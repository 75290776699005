import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { styled } from "@mui/material";
//import { SA_ENV } from 'config/app.config';
import { HOME_ROUTES } from 'config/routes.config';
//import { Footer } from 'core';
import { selectAuthToken } from 'services/auth/authSlice';
//---------------------------------------------------------

const StyledContainer = styled('div')(({ theme }) => ({
  display: 'flex', flexDirection: 'column',
  alignItems: 'center', justifyContent: 'center',
  maxWidth: '35vw', padding: '1rem 20rem',
  backgroundColor: theme.palette.background.paper,
  [theme.breakpoints.down('md')]: {
    padding: '2rem 0', maxWidth: '100vw', height: '100vh',
  },
}));

//const StyledImage = styled('img')(() => ({
//  height: '100%', width: '100%',
//}));

const StyledImageContainer = styled('div')(({ theme }) => ({
  width: '100vw', height: '99.5vh',
  backgroundImage: "url('/assets/images/auth.png')",
  position: 'relative', left: '-2px',
  [theme.breakpoints.down('md')]: { display: 'none', },
}));

//const FooterContainer = styled('div')(({ theme }) => ({
//  position: 'relative', bottom: 30, textAlign: 'center',
//  [theme.breakpoints.down('sm')]: { bottom: -15, },
//}));

const MainContainer = styled('div')(({ theme }) => ({
  display: 'flex', flexDirection: 'row',
  backgroundColor: '#7ad1ed',
  [theme.breakpoints.down('md')]: { display: 'block', },
}));
//---------------------------------------------------------

export default function PublicLayout() {
  const location = useLocation();
  //const { pathname } = location;//console.log("pathnamePubL", pathname);
  const userAuthToken = useSelector(selectAuthToken);
  //console.log("userAuthToken", (userAuthToken ? 'Yes': 'No')+' '+pathname);

  if(userAuthToken) {//SA_ENV === 'local'
    const navPath = HOME_ROUTES.Home.url;
    //const navPath = pathname && pathname!== '/home' ? pathname : HOME_ROUTES.Home.url;
    return <Navigate to={navPath} state={{ from: location }} replace />;
  }

  return (
    <MainContainer>
      <StyledContainer>
        <Outlet />
      </StyledContainer>
      <StyledImageContainer>
        {/*<StyledImage src="/assets/images/auth.png" alt={SA_APP_NAME} />*/}
      </StyledImageContainer>
    </MainContainer>
  );
}
