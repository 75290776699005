//console.log('AAA', process.env.NODE_ENV);
//console.log('BBB', process.env.REACT_APP_API_URL);
//console.log('CCC', process.env.PORT);
//const gEnvConf = process.env; console.log('gEnvConf', gEnvConf);
const appLoc = window.location;
if (appLoc.hostname.endsWith('/')) { appLoc.hostname = appLoc.hostname.slice(0, -1); }

export const HOME_BASE_URL = appLoc.origin;//process.env.
export const SA_APP_HOSTNAME = appLoc.hostname;
//console.log('appLoc', appLoc);
//----- ENV -----------------------------------------------

let sAEnv;
switch (SA_APP_HOSTNAME.toLowerCase()) {
  case 'localhost': sAEnv = 'alpha'; break;
  case 'sachapp.niusocial.online':
    sAEnv = 'local'; break;
  //case 'apps.niusocial.online': sAEnv = 'dev'; break;
  case 'appa.niusocial.online': sAEnv = 'alpha'; break;
  case 'appb.niusocial.online': sAEnv = 'beta'; break;
  case 'app.niusocial.online': default: sAEnv = 'live'; break;
}
export const SA_ENV = sAEnv;
//export const SA_ENV = 'local';
//export const SA_ENV = 'live';
//console.log({HOME_BASE_URL, SA_APP_HOSTNAME, sAEnv});

//export const SA_DEBUG = true;
export const FLG_SA_SITE_OFFLINE = false;
export const FLG_ENABLE_TWO_WAY_HANDSHAKE = true;//SA_ENV !== 'local'
export const FLG_DISABLE_AUTH_PAGES = SA_ENV !== 'local';//true;
export const FLG_ENABLE_AWS_AUTH = false;
export const FLG_SKIP_SA_TOKEN = false;//UseAwsAuthTokenInstead

//----- CONST ---------------------------------------------

export const SA_API_VERSION = '1.0';//process.env.SA_API_VERSION;
export const API_BASE_URL = 'https://api.niusocial.online/v' + SA_API_VERSION;
//export const API_BASE_URL = 'http://nius-api-local.com/v' + SA_API_VERSION;
//export const API_BASE_URL = 'http://127.0.0.1:8000/v' + SA_API_VERSION;
//export const API_BASE_URL = (SA_ENV === 'live' ?
// 'https://api.niusocial.online/v' : 'https://apia.niusocial.online/v') + SA_API_VERSION;

//export const NIU_HEALTH_BASE_URL = 'https://app-stg.niuhealth.com';
//export const NIU_HEALTH_BASE_URL = 'https://app.niuhealth.com';
export const NIU_HEALTH_BASE_URL = (SA_ENV === 'live' ?
  'https://app.niuhealth.com' : 'https://app.niuhealth.com');
//'https://app.niuhealth.com' : 'https://appa.niuhealth.site');
export const NIU_HEALTH_PRIVAY_POLICY_URL = 'https://niuhealth.com/privacy-policy';

//export const HIDE_AUTH_PAGE = true;
export const SA_APP_NAME = 'NIU-Social';//'NIU-Social';
export const SA_APP_VERSION = '1.0';
export const SA_DEF_SUBDOMAIN = 'NIU-Social';
export const SA_DEF_ORG_ID = 'Z0jZ5Y0L';
export const SA_DEF_ORG_GROUP_ID = 'q9k8Z4lA'; //rlbMZaQW
export const SA_DEF_TIMEZONE = 'America/Los_Angeles'; //America/New_York, Asia/Kolkata
export const SA_FULLDATE_DISP_FORM = 'M-D-Y H:i';
//export const SA_DATE_DISP_FORM = 'MM-DD-YYYY';//'M-D-Y';
//export const SA_DATE_DISP_FORM = 'MMM-DD-YYYY';//'M-D-Y';
export const SA_DATE_DISP_FORM = 'MMM DD YYYY';//'M-D-Y';
export const MAX_FILE_UPLOAD_SIZE = 2100000; //1024*1024*2 = 2097152;
export const SA_ASSETS_PATH = '/assets';
export const SA_IMG_BASE_URL = HOME_BASE_URL + '/assets/images';
export const SA_404_IMG = '';

//export const SA_DEV_EMAIL = '';
//export const SA_IMG_PATH = '';
//export const SA_DEFAULT_LANGUAGE_CODE = 'en';

export const SA_TEMP_DEF_ADMIN_EMAIL = 'niusadev@gmail.com';//'admin1@company3.com'
export const SA_TEMP_DEF_ADMIN_ID = 'Vryq0OYw';
export const SA_TEMP_ADMINS_FOR_FB = [
  'niusadev@gmail.com', 'dodoy13353@fandsend.com', 'p18283750@gmail.com',
  //'daltafispe@gufum.com',// 'wenobec610@ipniel.com',
];

export const MAX_SAUTH_WIN_OPEN_TIMEMS = 60000;
export const SMEDIA_CONNECT_RESP_HANDLER_PAGE = '/social-media-connect';
export const TPARTY_RESP_HANDLER_PAGE = '/tparty-comm-resp'; //third-party-resp
export const NIU_SOCIAL_TREATY_HANDLER_PAGE = '/social-treaty';
export const FB_APP_ID = '760322742396577'; // production 760322742396577 // local 743990014064002
//export const TAWKTO_PID = '644116e14247f20fefecca16';
//export const TAWKTO_TID = '1guf42p38';
export const COGNITO_REGION = 'us-west-1';//'us-east-1'
//export const COGNITO_USER_POOL_ID = COGNITO_REGION+'_vGsNPRcvG';
//export const COGNITO_CLIENT_ID = '1u1etua5dlb4jj9p1u13cpudsg';
export const COGNITO_USER_POOL_ID = COGNITO_REGION + '_xNsmPxMur';
export const COGNITO_CLIENT_ID = '3hu5d3btuvetk1avo9ob0i4r0a';

export const DEF_ENG_POINT = 1;//DEFAULT_ENG_POINTS={fb:{}}
export const ENG_SRC_SM = ['fb', 'tw', 'ln', 'ig', 'igb'];
export const ENG_DEST_SM = ['fb', 'tw', 'ln'];

export const REMEMBER_ME_CNAME = 'nSSqFMN';
export const REMEMBER_ME_CNAME_AWS = 'NsSqCogFMN';
export const REMEMBER_ME_CNAME_AWS2 = 'NsSqCogFMN2';
export const REMEMBER_ME_CNAME_AWS3 = 'NsSqCog3FMN';

//export const TEMP_HIDE_USER_POST_IDS = null;
export const TEMP_HIDE_USER_POST_IDS = [
  'a1R3BYmO_VO6BOZQM', 'a1R3BYmO_q9e4nAg2', 'a1R3BYmO_woOaJQdx',
  'a1R3BYmO_wrg47len', 'a1R3BYmO_V0NXn5bD', 'a1R3BYmO_VabxB3G8',
  'a1R3BYmO_qjANbPXm', 'a1R3BYmO_q9e4nnrO', 'a1R3BYmO_VkQx66e4',
  'a1R3BYmO_v8kennXa', 'a1R3BYmO_wdk199bp', 'a1R3BYmO_VkQx6no4',
  'a1R3BYmO_V3Npn7jQ', 'a1R3BYmO_qp97xoa5', 'a1R3BYmO_qZdaW7O8',
  'a1R3BYmO_VymkAx5x', 'a1R3BYmO_VymkAxKM', 'a1R3BYmO_VN6J7l2o',
  'a1R3BYmO_qXA6D8Gr', 'a1R3BYmO_wdk19WZr',
  'a1R3BYmO_vWYM1NAr', 'a1R3BYmO_qxmal1eP', 'a1R3BYmO_qlQeoJZb',
  'a1R3BYmO_wdk19y5z', 'a1R3BYmO_Vg0g333M', 'a1R3BYmO_vWYM115x',
  'Yjglz6Ry_qbyxRRbp', 'a1R3BYmO_V3NpnoyD', 'a1R3BYmO_qbyxR4op',
  'a1R3BYmO_VAoXnOm7', 'a1R3BYmO_V4NXnO0A', 'a1R3BYmO_q1GDnOkZ',
  'a1R3BYmO_qxmalpr7', 'a1R3BYmO_VabxBn19', 'a1R3BYmO_VkQx6m38',
  'MzR9v14E_VymkAQBX', 'MzR9v14E_VabxBLxm', 'a1R3BYmO_wBr9nEAk',
  'a1R3BYmO_q9e4nLyp', 'a1R3BYmO_VN6J7Map', 'a1R3BYmO_qDJDKYPY',
  'a1R3BYmO_qbyxR311', 'a1R3BYmO_VO6BkOWP', 'a1R3BYmO_VkQx6gnk',
  'a1R3BYmO_wzmD4j0a', 'a1R3BYmO_vLMYx5xB', 'a1R3BYmO_VkQx6g6k',
  'a1R3BYmO_Vg0g3m8b', 'a1R3BYmO_q1GDnQNl', 'MzR9v14E_qXA6DpgG',
  'a1R3BYmO_q9e4n8KQ', 'a1R3BYmO_VQ6EQlKp', 'a1R3BYmO_wBr9nJ7Y',
  'a1R3BYmO_vJQejdJE', 'MzR9v14E_qmMeg0zQ', 'MzR9v14E_qZdaWpBk',
  'a1R3BYmO_VkQx6KJ4',
  'MzR9v14E_VymkAgBx', 'MzR9v14E_v8keM6D3', 'MzR9v14E_weBpdj8D',

  'MzR9v14E_VO6BbJ4z', 'MzR9v14E_V2Pkaddr', 'MzR9v14E_wnMxY55e', 'MzR9v14E_vKBXZllX',
  'MzR9v14E_woOaz55r', 'a1R3BYmO_VymkAJyX', 'a1R3BYmO_qp97x6jX', 'a1R3BYmO_VQ6EQxWx',
  'a1R3BYmO_V0NXnlP8', 'a1R3BYmO_qMO2aoYB', 'a1R3BYmO_qlQeo30a', 'a1R3BYmO_VAoXnzDd',
  'a1R3BYmO_V4NXnDQX', 'a1R3BYmO_q1GDnA7r', 'a1R3BYmO_qDJDKX0B', 'a1R3BYmO_vLMYx2nn',
  'Yjglz6Ry_wrg472rZ', 'Yjglz6Ry_woOaJPY0', 'Yjglz6Ry_qMO2nJ4N', 'Yjglz6Ry_woOanyAW',
  'Yjglz6Ry_wrg4jD1a', 'Yjglz6Ry_qjANn5Y5', 'Yjglz6Ry_qDJDOMDx', 'Yjglz6Ry_vLMYODYJ',
  'Yjglz6Ry_vWYMneMx', 'Yjglz6Ry_wdk1nP1p', 'Yjglz6Ry_VN6JnbJK', 'Yjglz6Ry_v8keOKea',
  'Yjglz6Ry_weBpnypM', 'Yjglz6Ry_qmMenoen', 'Yjglz6Ry_qPENnYNp', 'Yjglz6Ry_wBr9KQ98',
  'Yjglz6Ry_qR9NnBN1', 'Yjglz6Ry_Vg0gnWge', 'Yjglz6Ry_vY6EnGEQ', 'Yjglz6Ry_wzmD0WDx',
  'Yjglz6Ry_qZdanga8', 'Yjglz6Ry_V3NpObpD', 'Yjglz6Ry_qXA6nBEJ', 'Yjglz6Ry_VymkQlYM',
  'Yjglz6Ry_qbyxnOAx', 'Yjglz6Ry_v6N6OzYY', 'Yjglz6Ry_v5eaOz0W', 'Yjglz6Ry_VExmOlX4',
  'Yjglz6Ry_vGPBODXP', 'Yjglz6Ry_VO6BnDx4', 'Yjglz6Ry_V2PkOzjd', 'Yjglz6Ry_wnMxKrLb',
  'Yjglz6Ry_vKBXnbo1', 'Yjglz6Ry_woOanjLW', 'Yjglz6Ry_wrg4jaxa', 'Yjglz6Ry_qjANnLy5',
  'Yjglz6Ry_vJQeOD6J', 'Yjglz6Ry_qxmapM3n', 'Yjglz6Ry_Vabxn8ym', 'Yjglz6Ry_v7kXOzlm',
  'Yjglz6Ry_VkQxnbe0', 'Yjglz6Ry_qp97oJ4a', 'Yjglz6Ry_VQ6EnDe2', 'Yjglz6Ry_V0NXOzoQ',
  'Yjglz6Ry_qMO2nejz', 'Yjglz6Ry_woOanjLE', 'Yjglz6Ry_wrg4jaxm', 'Yjglz6Ry_qjANnLyG',
  'Yjglz6Ry_vJQeOD67', 'Yjglz6Ry_qxmapM3O', 'Yjglz6Ry_Vabxn8yP', 'Yjglz6Ry_v7kXOzlj',
  'Yjglz6Ry_VkQxnbe1', 'Yjglz6Ry_qp97oJ4M', 'Yjglz6Ry_VQ6EnDeZ', 'Yjglz6Ry_V0NXOzoR',
  'Yjglz6Ry_qMO2nejn', 'Yjglz6Ry_qlQenZdo', 'Yjglz6Ry_VAoXOpjN', 'Yjglz6Ry_V4NXOb6m',
  'Yjglz6Ry_q1GDOzrk', 'Yjglz6Ry_qDJDOM2Y', 'Yjglz6Ry_vLMYODr2',

  'MzR9v14E_qMO2aE2N', 'Yjglz6Ry_qbyxR4pp', 'Yjglz6Ry_q9e4OopG', 'Yjglz6Ry_vWYMneEW',
  'MzR9v14E_Vg0g3mge', 'Yjglz6Ry_qXA6D9XK', 'Yjglz6Ry_v6N6nJXO', 'Yjglz6Ry_vWYMneEW',
  'Yjglz6Ry_wdk1nPrY', 'Yjglz6Ry_VN6JnbNm', 'Yjglz6Ry_v8keOKX8', 'Yjglz6Ry_weBpnylp',
  'Yjglz6Ry_qmMeno90', 'Yjglz6Ry_qPENnYXX', 'Yjglz6Ry_wBr9KQg2', 'Yjglz6Ry_qR9NnBY9',
  'Yjglz6Ry_Vg0gnWQ4', 'Yjglz6Ry_vY6EnG1b', 'Yjglz6Ry_wzmD0Wyl', 'Yjglz6Ry_qZdangbO',
  'Yjglz6Ry_V3NpObWL', 'Yjglz6Ry_qXA6nBmG', 'Yjglz6Ry_VymkQlel', 'Yjglz6Ry_qbyxnO90',
  'Yjglz6Ry_v6N6Oz8G', 'Yjglz6Ry_v5eaOzW8', 'Yjglz6Ry_VExmOl2k', 'Yjglz6Ry_vGPBOD95',

  'a1R3BYmO_qmMerX7Q', 'a1R3BYmO_weBpzPED', 'a1R3BYmO_v8kenNp3', 'a1R3BYmO_VN6J7rOj',
  'a1R3BYmO_wdk19mOz', 'a1R3BYmO_vWYM10GY', 'a1R3BYmO_qPENpJm8', 'a1R3BYmO_Vg0g3RKE',
  'a1R3BYmO_vY6ELlXW', 'a1R3BYmO_wzmD4xK3', 'a1R3BYmO_qZdaWANx', 'a1R3BYmO_V3Npn1d2',
  'a1R3BYmO_qXA6DM3r', 'a1R3BYmO_qbyxR85Z', 'a1R3BYmO_v6N6nWdx', 'a1R3BYmO_v5eanL6b',
  'a1R3BYmO_VQ6EQx7x', 'a1R3BYmO_qp97x6nX', 'a1R3BYmO_VkQx6ZdR', 'a1R3BYmO_v7kXndNR',
  'a1R3BYmO_VabxBNz1', 'a1R3BYmO_qxmaljyl', 'a1R3BYmO_vJQejG5O', 'a1R3BYmO_qjANbzkD',
  'a1R3BYmO_wrg47E6Z', 'a1R3BYmO_woOaJbe0', 'a1R3BYmO_q9e4n8zg', 'a1R3BYmO_vKBXJO5e',
  'a1R3BYmO_wnMxjylp', 'a1R3BYmO_V2Pkn965', 'a1R3BYmO_VO6Bkj2M', 'a1R3BYmO_vGPBn6YZ',
  'a1R3BYmO_VExmny4J', 'a1R3BYmO_VkQx6ZrR', 'MzR9v14E_VN6JylPG',
  'a1R3BYmO_qMO2aolB', 'a1R3BYmO_V0NXnl68', 'a1R3BYmO_wBr9nZ2Q', 'a1R3BYmO_qR9N0aEp',

];
