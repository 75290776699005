import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SA_APP_NAME, MAX_SAUTH_WIN_OPEN_TIMEMS } from "config/app.config";
import { compareJsonObj, decodeSNectConnResp, fInitSMAuth, fFetchSMediaAccData, fFetchSMediaAccProps } from 'lib/appFx';
import { selectAuthUserCredentials } from 'services/auth/authSlice';
import {getSMediaMgr, resetSMediaTokens, useGetSMediaAccountListQuery,
  useLazyGetSMediaAccountListQuery,
} from 'services/sMediaApi';
import { fDelCookie, fGetCookie, parseQStr } from 'lib/netFx';
import { socialMedia, sMediaIco } from "config/ui.config";
import { Grid, Stack, Box, styled } from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Button, Typography } from "core";
//---------------------------------------------------------

const CloseIcon = styled(HighlightOffIcon)(({ theme }) => ({
	position: "absolute",
	fontSize: "2rem",
	margin: "0.5rem",
	right: "18px",
	cursor: "pointer",
	[theme.breakpoints.down("sm")]: {
	  fontSize: "1.4rem",
	  margin: "6px",
	  right: "5px",
	},
}));
const StyledTextBoxMain = styled(Typography)(({ theme }) => ({
	border: '0px solid green',
	backgroundColor: "#B8E3E9",
	//top:'3rem',
	//width: "400px",
	//width: "75%",
	//height: "80px",
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	padding: "6px",
	textAlign: "center",
	fontWeight: 900,
	//position: "absolute",
	margin: "auto",
	//bottom: "20rem",
	//left: "7rem",
	flexDirection: "column",
	fontSize: "15px",
	[theme.breakpoints.down("sm")]: {
	  //bottom: "12.5rem",
	  //left: "1rem",
	  //width: "310px",
	  fontSize: "12px",
	  height: "30px",
	},
}));
const StyledStack = styled(Stack)(({ theme }) => ({
  border: '0px solid yellow',
  //padding: '30px',
  margin: '10px auto',
  columnGap: 47,
  //position: "absolute",
  //position: "relative",
  alignItems:"center",
  justifyContent: 'center',
  top: "8.7rem",
  //left: "6.63rem",//"2.63rem",
  [theme.breakpoints.down("sm")]: {
    top: "3.8rem",
    //left: "1.5rem",
    columnGap: 25,
  },
}));
const XSocialIconContainer = styled("div")(({ theme }) => ({
	backgroundColor: theme.palette.background.paper,
	width: "65px",
	height: "65px",
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	padding: "7px 5px 5px",
	cursor: "pointer",
	border: `0px solid  ${theme.palette.error.main} `,
	borderRadius: "100%",
	textAlign: "center",
	[theme.breakpoints.down("sm")]: {
	  width: "40px",
	  height: "40px",
	},
}));
const SocialIconContainer = styled('div')(({ theme, active, arrow }) => ({
	// backgroundColor: 'Background',
	// display: 'grid',
	// gridTemplateColumns:"auto".
	/* align-items: center; */
	/* column-gap: 4px; */
	// padding: '5px',
	// borderRadius: '100%',
	// border: `2px solid  ${
	//   active ? theme.palette.primary.main : theme.palette.error.main
	// } `,
	// alignContent: 'center',
	border: '0px solid green',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	//width: '100%',
	height: '100%',
	// display: 'grid !important',
	// gridTemplateColumns: 'auto auto !important',
	// rowGap: '15px ',
	boxShadow: theme.shadows[5],
	backgroundColor: 'rgba(0,0,0,0)',
	textAlign: 'center',
	cursor: arrow,
	borderRadius: '4px',
}));
const StyledTextBoxContent = styled(Typography)(({ theme }) => ({
	backgroundColor: "#86CADD",
	//width: "370px",
	width: "100%",
	height: "80px",
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	//padding: "px",
	textAlign: "center",
	fontWeight: 600,
	//position: "absolute",
	//margin: "auto",
	//bottom: "6rem",
	//left: "8rem",
	flexDirection: "column",
	[theme.breakpoints.down("sm")]: {
	  bottom: "4rem",
	  //left: "1rem",
	  width: "92%",
	  //width: "310px",
	  height: "35px",
	  fontSize: "12px",
	},
}));
//const StyledTextBox = styled(Typography)(({ theme }) => ({
const StyledTextBox = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.primary.lighter,
  //position: 'relative',
  //width: "370px",
  width: "100%",
  height: "80px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "6px",
  textAlign: "center",
  fontWeight: 600,
  //position: "absolute",
  margin: "auto",
  //bottom: "-9rem",
  //left: "8rem",
  flexDirection: "column",

  [theme.breakpoints.down("sm")]: {
    bottom: "0.5rem",
    //left: "3rem",
	//width: "280px"
	width: "100%",
    height: "45px",
  },
}));
//const StyledTypography = styled(Typography)(({ theme }) => ({
const StyledTypography = styled('div')(({ theme }) => ({
	fontSize: "0.9rem",
	[theme.breakpoints.down("sm")]: {
	  fontSize: "12px",
	},
}));

const social = [
  //{sMKey: 'fb', icon: socialMedia["facebook"]},
  {sMKey: 'fb', icon: sMediaIco['fb']},
  {sMKey: 'tw', icon: sMediaIco['tw']},
  //{sMKey: 'yt', icon: sMediaIco['yt']},
  {sMKey: 'ln', icon: sMediaIco['ln']},
  {sMKey: 'ig', icon: sMediaIco['ig']},
];//console.log('AAA', social);
const sMediaCIco = {
	'fb': {sMKey: 'fb'},
	'tw': {sMKey: 'tw'},
	//'yt': {sMKey: 'yt'},
	'ln': {sMKey: 'ln'},
	'ig': {sMKey: 'ig'},
};
//---------------------------------------------------------

export default function SocialNetworkCard2({
	onClose, uFeedFilter, setUFeedFilter, theme, //setOpen, setShownModal
}) {
	let theSMData, theSMList;
	const {authToken, isAdmin} = useSelector(selectAuthUserCredentials);
	const fIniSMAuth = fInitSMAuth;
	theSMData = useSelector(getSMediaMgr);console.log('theSMDataModal', theSMData);
	const dispatch = useDispatch();
	const {
		data: dDataAL,
		//error: dErrAL,
		//isLoading: dWaitAL,
		//isFetching: dFetchAL,
		//isSuccess: dOKAL,
		refetch: refetchSMAccList,
		isUninitialized: flgInitSMAccList
	} = useGetSMediaAccountListQuery();
  dDataAL && console.log('dDataAL_'+flgInitSMAccList, dDataAL);
  const [fLazyAccList] = useLazyGetSMediaAccountListQuery();

	const setUFSMFilterStatus = (sKey, uFeedFilter, fCB, status=true) => {
	  //if(typeof fCB === 'function')
	  const currUFeedFilterStat = JSON.parse(JSON.stringify(uFeedFilter));
	  Object.keys(currUFeedFilterStat).forEach((sK) => {
		  if(sK === sKey) { currUFeedFilterStat[sK]['status'] = status; }
	  });//console.log('uFeedFilterStat2 '+typeof(fCB), currUFeedFilterStat);
	  //setUFeedFilter && setUFeedFilter(currUFeedFilterStat);
	  if(!compareJsonObj(uFeedFilter, currUFeedFilterStat)) {
	  	fCB && fCB(currUFeedFilterStat);
	  }
	};

	//----- BelowFxs(fetchUserSMAcc&&fAuthSM)AreAlmostIdenticalI:
	//SocialNetworks.jsx, EditNetwork.jsx & SocialNetworkCard2.js
	//KeepItSame. IdealCandidateForACustomeHook
	const fetchUserSMAcc = useCallback(
		async (sKey=null, sMId=null, refFx=null, refArgs=null) => {
			//RefreshUserSMAccTokens
		//dOKAL && //
		//let { data: usrSMAcc } = await refetchSMAccList();
		let usrSMAcc = await fFetchSMediaAccData(authToken);
		dispatch(resetSMediaTokens(usrSMAcc));
		usrSMAcc?.tokens && console.log('usrSMAcc_'+sKey+' '+sMId, usrSMAcc.tokens);

		if(sKey && usrSMAcc?.tokens) {// &&sMId
			let isAnySubs = false;
			const selSMKeyToken = usrSMAcc?.tokens.find((item) => {
				return item.snetworkplatform_id === sMId;
			});
			//const selSMKeyToken = usrSMAcc?.tokens?.some((item) => {
				//return item.snetworkplatform_id === data.sMId;
			//});

			if(selSMKeyToken?.properties) {
				for(const pg of selSMKeyToken?.properties) {
						console.log('isAdmin', isAdmin, 'pg', pg);
					//if(!pg.is_subscribed) {
						//Disable LnCallForNonAdmin
						if(!pg.is_subscribed &&
							((isAdmin && pg.type === 'page') || (!isAdmin && pg.type === 'account' && sKey !== 'ln'))
						) {
						const subsPg = await fFetchSMediaAccProps(authToken, pg.id);
						isAnySubs = subsPg || isAnySubs;
						console.log('isSubscribed ', subsPg);
					}
				}

        let aList;
				if(0) {
        //flgInitSMAccList
        //let { data: usrSMAcc } = await refetchSMAccList();
        }// else {
          //console.log('AAA', flgInitSMAccList);

          /*if(!flgInitSMAccList) {
            console.log('BBB', flgInitSMAccList);
            let { data: usrSMAccL } = await refetchSMAccList();
            aList = usrSMAccL;
          } else {*/
            console.log('CCC', flgInitSMAccList);
            aList = await fLazyAccList().unwrap();
          //}
          console.log('aList', aList);
          usrSMAcc = aList;
        //}

				//if(isAnySubs) {//TheSMConnIsSuccessAnyways..
				//setTimeout(()=>{
					dispatch(resetSMediaTokens(usrSMAcc));//console.log('D');
					if(typeof refFx === 'function') {
						//setTimeout(()=>{
							refFx(sKey, refArgs[0], refArgs[1]);
						//}, 2000);
					}
					//dispatch(resetSMediaTokens(usrSMAcc));//console.log('D');
				//}, 500);
				//}
			}
		} else if(sKey) { console.log('Connected but no tokens issued'); }
		else {
			dispatch(resetSMediaTokens(usrSMAcc));
			//console.log('resetCalledEN');
		}
	},
	//[fSNetAcc, dispatch]);//resetSMediaTokens <- Don't
  [dispatch, resetSMediaTokens]);//refetchSMAccList

	const fAuthSM = useCallback((e, sKey, refFx=null, refArgs=null) => {
		//ForHeaven'sSake, DON'T put any state logic here.
		//TookMe3DaysToClearTheMess!!
		//This function is ONLY for initiating SMConn, wait for
		//response cookie & delegate state logics to fetchUserSMAcc. Period.
		e.preventDefault();
		//const sKey = e.target.getAttribute('data-sa-smkey');
		let sChk, theCk, cName = 'sMAT_Ukn', sMTokenForId;
		sMTokenForId = theSMData[sKey]?.sMId;
		sKey && fIniSMAuth(theSMData[sKey].authUrl, sKey);

		setTimeout(() => {
			sChk = setInterval(async() => {
			theCk = fGetCookie(cName);
			if(theCk) {//console.log('theSweetCookie_'+sKey, theCk);
				theCk = parseQStr(theCk); //if(theCk.startsWith('module=oauth&')) {
				if(theCk?.module === 'oauth' && theCk?.payload) {
				theCk.payload = decodeSNectConnResp(theCk.payload);
				console.log('theSweetCookie2_'+sKey, theCk);
				//console.log('theSMDataSS '+sMTokenForId+' '+
				//	theCk.payload?.snetworkplatform_id, theSMData);

				//if(theCk.payload?user_id === ) {snetworktoken_id
				if(sMTokenForId &&
					theCk.payload?.snetworkplatform_id === sMTokenForId
				) {
					fDelCookie(cName);
					clearInterval(sChk);
					await fetchUserSMAcc(sKey, theSMData[sKey]['sMId'], refFx, refArgs);
				}// else {console.log('Noooo');}
				}
				//fDelCookie(cName);
				//clearInterval(sChk);
			}
			}, 2000);
		}, 5000);

		setTimeout(() => sChk && clearInterval(sChk),
			MAX_SAUTH_WIN_OPEN_TIMEMS + 1000);
		setTimeout(()=>{onClose();}, 5000);
	}, [theSMData, fIniSMAuth, fetchUserSMAcc]);

	//const { refetch: refetchSMAccList } = useGetSMediaAccountListQuery();
	//const fRefetchSMAccList = async () => { return await refetchSMAccList(); };

	const fCheckSMConn = (e) => {
		e.preventDefault();
		let sMKey = e.target.getAttribute('data-smkey');
		if(!sMKey) { sMKey = e.currentTarget.getAttribute('data-smkey');}
		if(!sMKey) { sMKey = e.target.parentElement.getAttribute('data-smkey');}
		if(sMKey && theSMData && theSMData[sMKey] && !theSMData[sMKey]['activTokenId']) {
			const smConnEl = document.getElementById('lnkWidSMConn_'+sMKey);
			smConnEl && smConnEl.click();
			setTimeout(() => {
				let sMCheckCount = 0;
				const sMChkInterval = setInterval(()=> {
					setUFSMFilterStatus(sMKey, uFeedFilter, setUFeedFilter)
					if(sMCheckCount++ > 7) { clearInterval(sMChkInterval); }
				}, 3000);
			}, 4000);
		}
		setTimeout(()=>{onClose();}, 5000);
	};

	if(0) {
		theSMList = [];
		Object.keys(sMediaCIco).forEach((sM) => {
			theSMList.push	(
				<SocialIconContainer
					key={'sMConnMod_'+sM}
					data-smkey={sM}
					onClick={fCheckSMConn}
				>
					{sMediaIco(sM, fCheckSMConn)}
				</SocialIconContainer>)
		});
		theSMList = (<StyledStack direction="row">{theSMList}</StyledStack>);
	} else {
		if(theSMData) {
			theSMList = [];
			const cStyle = {fontSize: '14px'};

			for(let sK in theSMData) {
			  let dR = theSMData[sK];
			  const icon = `${sK}New`;
			  let allowConn = dR.hasOwnProperty('authUrl') && sK !== 'yt';
			  if(allowConn && ['ig', 'igb'].includes(sK)) {
				if(isAdmin) { allowConn = sK === 'igb' }
				else { allowConn = sK === 'ig' }
			  }
		
			  if(allowConn) {
				  let lnkStat = dR?.connStat === 'Connected' ? true : false;
				  let status = dR?.connStat === 'Connected' ? 'Disconnect' : 'Connect';
				  let disConnected = dR?.connStat === 'Connected' ? false : true;
				  let arrow = dR?.connStat === 'Connected' && 'pointer';
				  let sMUserName = dR?.sMTokens?.name?.username;
		
				  let expired =
					dR?.connStat === 'Connected' ? 'Expired in 21 days' : '';
		
				  theSMList.push(
					<Grid
					  item
					  key={dR.sMId + '_' + dR.sMKey}
					  alignItems="center"
					  justifyContent="center"
					  //sm={4}
					  //xs={4}
					  style={{margin:'8px', border: '0px solid red',
					  //width: '45%'
					  }}
					  width={{md: '45%', sm: '45%',  xs: '90%', }}
					  //sx={{height: '100%', }}
					>
					  <SocialIconContainer
						key={'sMConnMod_'+sK}
						data-smkey={sK}
						active={lnkStat}
						arrow={arrow}
						//onClick={fCheckSMConn}
						onClick={(e) => {
							fAuthSM(e, dR.sMKey, setUFSMFilterStatus,
								[uFeedFilter, setUFeedFilter])
						}}
					  >
						{socialMedia.NewIcons(icon, disConnected, sMUserName)}
					  </SocialIconContainer>
					</Grid>
				  );
			  }
			}
	
			theSMList = (
				<Grid container
					border="0px solid yellow"
					alignItems="center"
					justifyContent="center"
					spacing={'0.5'}
					padding='5px'
					columns={2}
					width={'60%'}
					rowSpacing={.5} columnSpacing={{ xs: .5, sm: .5, md: .5 }}>
					{theSMList}
				</Grid>
			);
		}
	}

	//---------------------------------------------------------
	return (//() => window.open(sM.link, "_blank")
		<Stack
			direction="column"
			justifyContent="center"
			alignItems="stretch"
			spacing={0}
			sx={{//height: '75vh',//'450px'
				backgroundColor: '#B8E3E9',
			}}
			height={{md: '75vh', sm: '70%',  xs: '70%', }}
		>
			<div onClick={onClose} style={{border:'0px solid red'}}>
				<CloseIcon onClose={onClose} />
			</div>

			<Stack
				sx={{
					padding: '0px',
					border: '0px solid blue',
					height: '100%',
					//backgroundImage: "url('/assets/images/social.png')",
				}}
			>
				<Stack
					sx={{
						border: '0px dashed green	',
						height: '10%'
					}}
				>&nbsp;</Stack>
				<Stack
					alignItems="center"
					justify="center"
				>
					<StyledTextBoxMain>
						IT TAKES BOTH SIDES TO BUILD A BRIDGE!
					</StyledTextBoxMain>
				</Stack>
				<Stack
						justifyContent="center"
						alignItems="stretch"
						sx={{
							border: '0px solid red',
							height: '100%'
						}}
				>
					{theSMList}
				</Stack>
				<Stack
					alignItems="center"
					justify="center"
					sx={{
						bottom: '0',
						backgroundColor: '#86CADD',
					}}
				>
					<StyledTextBoxContent>
						Connect your social networks NOW and get empowered<br/> to share more and
						earn more points!
					</StyledTextBoxContent>
				</Stack>
			</Stack>

			<Stack
				alignItems="stretch"
				justifyContent="center"
				sx={{
					border: '0px solid yellow'
				}}
			>
				<StyledTextBox>
					<StyledTypography fontWeight={900}>
						P.S. Your information is absolutely safe.
					</StyledTypography>
					<Typography fontSize="13px" fontWeight={600}>
						{SA_APP_NAME} will never post anything on your behalf!
					</Typography>
				</StyledTextBox>
			</Stack>
		</Stack>
	);
}