import { Box, } from "@mui/material";
import AdmGroupCreateForm from './AdmGroupCreateForm';
import { useTranslation } from "react-i18next";
//import AdmGroupAddMember from './AdmGroupAddMember';

//---------------------------------------------------------
export default function AdmGroupCreate({ theme, title }) {
  const { t: home } = useTranslation('home')
  //---------------------------------------------------------
  if (!title) title = `${home('create_groups')}`
  return (
    <>
      <Box className="">
        <h2 className='pgHd'>{title}</h2>
      </Box>
      <Box className="bgWh padL xtBdr2"
        sx={{ maxWidth: '98%' }}
      //sx={{maxWidth: '60%', display: 'flex', gap: '0.6rem',
      //flexDirection: {xs:'column',md:'row'},}}
      >
        <AdmGroupCreateForm />
      </Box>
    </>
  );
};