import { useState, useCallback } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { IconButton, InputAdornment, Stack, styled, useTheme } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { SignUpSchema } from 'schema';
import { Button, FormInputField, FormProvider, Typography } from 'core';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';
import HttpsOutlinedIcon from '@mui/icons-material/HttpsOutlined';
//import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { grey } from '@mui/material/colors';
import { HOME_BASE_URL, SA_DEF_ORG_ID, SA_DEF_ORG_GROUP_ID, SA_DEF_TIMEZONE, NIU_HEALTH_BASE_URL } from 'config/app.config';
import { AUTH_ROUTES } from 'config/routes.config';
import { fCognitoRegister } from 'lib/cogFx';
import { genNiuSAKey } from 'lib/appFx';
import { useRegisterMutation } from 'services/auth/authApi';
import Spinner2 from 'core/Spinner/Spinner2';
//---------------------------------------------------------

const StyledInputFieldContainer = styled(Stack)(({ theme }) => ({
  alignItems: 'center',
  flexDirection: 'row',
  minWidth: '25vw',
  [theme.breakpoints.down('md')]: {
    minWidth: '90vw',
  },
}));

const IconStyle = {
  color: grey[500],
  marginRight: '1rem',
  width: '2rem',
  height: '2rem',
};

const InputField = styled(FormInputField)(({ theme }) => ({
  outline: `1px solid ${theme.palette.primary.main}`,
}));
//---------------------------------------------------------

export default function SignUpForm() {
  const theme = useTheme();
  const [isProcessing, setIsProcessing] = useState(false);
  const [pageMsg, setPageMsg] = useState();
  const navigate = useNavigate();
  const [fRegisterUser, { isLoading: isRegistering }] = useRegisterMutation();
  //---------------------------

  const defaultValues = {
    first_name: '', //Niu
    last_name: '', //User
    email: '',
    password: '',
    //mobile_number: "",
    //city: "",
  };

  const methods = useForm({
    resolver: yupResolver(SignUpSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { errors },
  } = methods;
  //---------------------------

  const fRegisterHandler = useCallback(async (rData) => {
    let regResp,
      formData = new FormData();

    formData.append('first_name', rData.first_name);
    formData.append('last_name', rData.last_name);
    formData.append('email', rData.email);
    //formData.append("password", rData.password);
    formData.append('password', genNiuSAKey(rData.email));
    //formData.append("mobile_number", rData.mobile_number);
    //formData.append("city", rData.city);
    formData.append('external_uuid', rData?.cogUId);
    formData.append('company_id', SA_DEF_ORG_ID);

    formData.append('timezone', SA_DEF_TIMEZONE);
    formData.append('receive_email', '1');
    formData.append('receive_sms', '1');
    formData.append('receive_push', '1');
    formData.append('is_test_user', '1'); //?
    formData.append('post_verification_redirect_uri', HOME_BASE_URL);

    try {
      regResp = await fRegisterUser(formData).unwrap();
    } catch (err) {
      regResp = err;
    }
    return regResp;
  }, []);

  const onSubmit = useCallback(async (uData) => {
    //setIsProcessing(true);
    //if(1) { mailto:udata.email='sachinm8@socisquares.com'; }
    let cogRegResp, regResp;
    let pMsg,
      flgProceed = false;
    let newUserId;
    cogRegResp = await fCognitoRegister(uData);
    if (cogRegResp?.result === true && cogRegResp?.cogUserId) {
      flgProceed = true;
      uData['cogUId'] = cogRegResp.cogUserId;
      pMsg = 'cognitoRegOK';
    } else {
      pMsg = cogRegResp;
    }
    //---------------------------

    if (flgProceed) {
      flgProceed = false;
      regResp = await fRegisterHandler(uData);
      if (regResp?.user_id) {
        newUserId = regResp?.user_id;
        pMsg = 'regOK';
        flgProceed = true;
      } else {
        pMsg = 'Error registering user. Please try again later..';
      }
    }
    //---------------------------

    if (flgProceed) {
      navigate('/verify', { state: { regEMail: uData?.email, regUserId: newUserId } });
    } else {
      pMsg = pMsg ? pMsg : 'Error registering user!!';
    }
    //setIsProcessing(false);
    setPageMsg(pMsg);
  }, []);
  //---------------------------------------------------------

  const isError = (fieldName) => (errors[fieldName] ? theme.palette.error.light : theme.palette.primary.main);
  const [isPasswordVisible, setPasswordVisible] = useState(false);

  const changePasswordState = () => setPasswordVisible((prev) => !prev);
  const [isConfirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

  const changeConfirmPasswordState = () => setConfirmPasswordVisible((prev) => !prev);
  //---------------------------------------------------------

  /*Redirect the user to NIU Health login page before the form is visible*/
  window.location.href = NIU_HEALTH_BASE_URL;

  return (
    <></>
    // <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
    //   <Stack spacing={3} textAlign="left">
    //     <Stack spacing={1}>
    //       <StyledInputFieldContainer>
    //         <AccountBoxOutlinedIcon sx={{ ...IconStyle }} />
    //         <InputField
    //           name="first_name"
    //           type="text"
    //           placeholder="First Name*"
    //           fullWidth
    //           InputProps={{
    //             style: {
    //               backgroundColor: (theme) => theme.palette.background.paper,
    //               minWidth: '25vw',
    //             },
    //             endAdornment: (
    //               <InputAdornment position="end">
    //                 <IconButton size="small">
    //                   <VisibilityOffIcon sx={{ opacity: 0 }} />
    //                 </IconButton>
    //               </InputAdornment>
    //             ),
    //           }}
    //         />
    //       </StyledInputFieldContainer>
    //       <StyledInputFieldContainer>
    //         <AccountBoxOutlinedIcon
    //           sx={{
    //             ...IconStyle,
    //             opacity: '0',
    //           }}
    //         />
    //         <InputField
    //           name="last_name"
    //           type="text"
    //           placeholder="Last Name*"
    //           InputProps={{
    //             style: {
    //               backgroundColor: (theme) => theme.palette.background.paper,
    //               minWidth: '25vw',
    //             },
    //             endAdornment: (
    //               <InputAdornment position="end">
    //                 <IconButton size="small">
    //                   <VisibilityOffIcon sx={{ opacity: 0 }} />
    //                 </IconButton>
    //               </InputAdornment>
    //             ),
    //           }}
    //         />
    //       </StyledInputFieldContainer>
    //     </Stack>

    //     <StyledInputFieldContainer>
    //       <MailOutlineOutlinedIcon sx={{ ...IconStyle }} />
    //       <InputField
    //         name="email"
    //         type="email"
    //         placeholder="Email*"
    //         InputProps={{
    //           style: {
    //             backgroundColor: (theme) => theme.palette.background.paper,
    //             minWidth: '25vw',
    //           },
    //           endAdornment: (
    //             <InputAdornment position="end">
    //               <IconButton size="small">
    //                 <VisibilityOffIcon sx={{ opacity: 0 }} />
    //               </IconButton>
    //             </InputAdornment>
    //           ),
    //         }}
    //       />
    //     </StyledInputFieldContainer>

    //     <Stack spacing={1}>
    //       <StyledInputFieldContainer>
    //         <HttpsOutlinedIcon sx={{ ...IconStyle }} />
    //         <InputField
    //           name="password"
    //           placeholder="Password*"
    //           type={isPasswordVisible ? 'text' : 'password'}
    //           InputProps={{
    //             style: {
    //               backgroundColor: (theme) => theme.palette.background.paper,
    //               minWidth: '25vw',
    //             },
    //             endAdornment: (
    //               <InputAdornment position="end">
    //                 <IconButton size="small" onClick={changePasswordState}>
    //                   {!isPasswordVisible ? <VisibilityOffIcon color={isError('password')} /> : <VisibilityIcon color={isError('password')} />}
    //                 </IconButton>
    //               </InputAdornment>
    //             ),
    //           }}
    //         />
    //       </StyledInputFieldContainer>
    //       <StyledInputFieldContainer>
    //         <HttpsOutlinedIcon sx={{ ...IconStyle, opacity: 0 }} />
    //         <InputField
    //           name="confirm_password"
    //           placeholder="Confirm Password*"
    //           type={isConfirmPasswordVisible ? 'text' : 'password'}
    //           InputProps={{
    //             style: {
    //               backgroundColor: (theme) => theme.palette.background.paper,
    //               minWidth: '25vw',
    //             },
    //             endAdornment: (
    //               <InputAdornment position="end">
    //                 <IconButton size="small" onClick={changeConfirmPasswordState}>
    //                   {!isConfirmPasswordVisible ? <VisibilityOffIcon color={isError('password')} /> : <VisibilityIcon color={isError('password')} />}
    //                 </IconButton>
    //               </InputAdornment>
    //             ),
    //           }}
    //         />
    //       </StyledInputFieldContainer>
    //     </Stack>
    //     {/* <Stack spacing={1}>
    //       <StyledInputFieldContainer>
    //         <LocationOnOutlinedIcon sx={{ ...IconStyle }} />
    //         <InputField
    //           name="city"
    //           type="text"
    //           placeholder="City*"
    //           InputProps={{
    //             style: {
    //               backgroundColor: (theme) => theme.palette.background.paper,
    //               minWidth: "25vw",
    //             },
    //             endAdornment: (
    //               <InputAdornment position="end">
    //                 <IconButton size="small">
    //                   <VisibilityOffIcon sx={{ opacity: 0 }} />
    //                 </IconButton>
    //               </InputAdornment>
    //             ),
    //           }}
    //         />
    //       </StyledInputFieldContainer>
    //     </Stack> */}
    //     {/* <Stack spacing={2}>
    //       <StyledInputFieldContainer>
    //         <PhoneAndroidIcon sx={{ ...IconStyle }} />
    //         <InputField
    //           name="mobile_number"
    //           type="text"
    //           placeholder="Mobile Number*"
    //           InputProps={{
    //             style: {
    //               backgroundColor: (theme) => theme.palette.background.paper,
    //               minWidth: "25vw",
    //             },
    //             endAdornment: (
    //               <InputAdornment position="end">
    //                 <IconButton size="small">
    //                   <VisibilityOffIcon sx={{ opacity: 0 }} />
    //                 </IconButton>
    //               </InputAdornment>
    //             ),
    //           }}
    //         />
    //       </StyledInputFieldContainer>
    //     </Stack> */}

    //     {pageMsg && (
    //       <StyledInputFieldContainer>
    //         <PhoneAndroidIcon sx={{ ...IconStyle, opacity: 0 }} />
    //         <Typography style={{ color: theme.palette.error.main }}>{pageMsg}</Typography>
    //       </StyledInputFieldContainer>
    //     )}
    //     {isProcessing && <Spinner2 />}
    //     <StyledInputFieldContainer>
    //       <PhoneAndroidIcon sx={{ ...IconStyle, opacity: 0 }} />
    //       <Button variant="contained" type="submit" sx={{ color: (theme) => theme.palette.background.paper }} size="large">
    //         SIGN ME UP
    //       </Button>
    //     </StyledInputFieldContainer>
    //     <StyledInputFieldContainer>
    //       <HttpsOutlinedIcon sx={{ ...IconStyle, opacity: 0 }} />
    //       <Button
    //         size="large"
    //         component={Link}
    //         to={AUTH_ROUTES.SignIn.url}
    //         sx={{
    //           background: grey[300],
    //           '&:hover': {
    //             background: grey[400],

    //             boxShadow: 'none',
    //           },
    //           color: grey[600],
    //           fontWeight: 600,
    //           fontSize: '1rem',
    //           boxShadow: 'none',
    //           letterSpacing: '0.04rem',
    //         }}
    //       >
    //         Login
    //       </Button>
    //     </StyledInputFieldContainer>
    //   </Stack>
    // </FormProvider>
  );
}
