import { Box, Stack, styled } from "@mui/material";
import { Button, Typography } from "core";
import { NIU_HEALTH_BASE_URL } from 'config/app.config';
import { useTranslation } from "react-i18next";

const StyledAppointmentCard = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.background.section,
  height: "14.8rem",
  justifyContent: "center",
  padding: "1rem",
  [theme.breakpoints.down("lg")]: {
    maxWidth: "20rem",
    margin: "auto",
  },
  [theme.breakpoints.down("md")]: {
    maxWidth: "20rem",
    margin: "auto",
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  background: theme.palette.primary.lighter,
  color: theme.palette.background.paper,
  boxShadow: "none",
  fontSize: "18px",
  maxWidth: "200px",
  textTransform: "capitalize",
  "&:hover": {
    background: theme.palette.primary.lighter,
  },
}));
const LogoBox = styled(Box)(({ theme }) => ({
  background: "#545353d4",
  padding: "20px 30px",
  borderRadius: 5,
}));
export default function GetCareBanner() {
  const { t: home } = useTranslation('home')
  return (
    <StyledAppointmentCard spacing={3}>
      <Stack rowGap={2} alignItems="center" justifyContent="center">
        <LogoBox>
          <img
            //src="/assets/images/transperentLogo.png"
            src='/assets/images/niuHealthLogo.svg'
            alt="logo"
            style={{ height: 80, width: 'auto' }}
          />
        </LogoBox>
        <Typography fontWeight={600} size="large" color_variant="light">
          {home("get_care_24_7")}
        </Typography>
        <StyledButton
          disableRipple
          //href={NIU_HEALTH_BASE_URL+'/patient/ask-dr-coco'}
          href={NIU_HEALTH_BASE_URL + '/patient/virtualvisit'}
          variant="contained">
          {home("connect_now")}
        </StyledButton>
      </Stack>
    </StyledAppointmentCard>
  );
}
