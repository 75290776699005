import moment from "moment";

export function formatNumberToLocaleString(number) {
  return parseInt(number).toLocaleString("en-US");
}
export const actionObject = (current, dest, action) => {
  //console.log('actionObject', current+' '+dest+' '+action);
  const actionTypes = {
    "tw-tw-quote": "quote_tweet",
    "tw-tw-tweet": "tweet",
    "tw-tw-like": "like",
    "tw-tw-retweet": "retweet",
    "tw-fb-share": "share",
    "fb-fb-share": "share",
    "fb-tw-retweet": "share",
    "fb-tw-share": "share",
    "fb-fb-quote": "quote_share",
    "tw-fb-quote": "quote_share", 
    "fb-tw-quote": "quote_share",
    "fb-fb-like": "like",
    "fb-fb-comment": "comment",
   
  }
  return actionTypes[current + "-" + dest + "-" + action]
}
export function abbreviateNumber(value) {
  var newValue = value;
  if (value >= 1000) {
    var suffixes = ["", "k", "m", "b", "t"];
    var suffixNum = Math.floor(("" + value).length / 3);
    var shortValue = "";
    for (var precision = 2; precision >= 1; precision--) {
      shortValue = parseFloat(
        (suffixNum !== 0
          ? value / Math.pow(1000, suffixNum)
          : value
        ).toPrecision(precision)
      );
      var dotLessShortValue = (shortValue + "").replace(/[^a-zA-Z 0-9]+/g, "");
      if (dotLessShortValue.length <= 2) {
        break;
      }
    }
    if (shortValue % 1 !== 0) shortValue = shortValue.toFixed(1);
    newValue = shortValue + suffixes[suffixNum];
  }
  return newValue;
}
export const validateObj = (object) => {
  if (object !== null && typeof object !== "undefined") {
    return true;
  } else {
    return false;
  }
};
export function getYearsList() {
  const years = [];
  const dateStart = moment();
  const dateEnd = moment().add(10, "y");
  while (dateEnd.diff(dateStart, "years") >= 0) {
    years.push(dateStart.format("YYYY"));
    dateStart.add(1, "year");
  }
  return years;
}

export function getMonthsList() {
  const months = [];
  const dateStart = moment();
  const dateEnd = moment().add(12, "month");
  while (dateEnd.diff(dateStart, "months") >= 0) {
    months.push(dateStart.format("MMMM"));
    dateStart.add(1, "month");
  }
  return months;
}

export function getDaysList() {
  const days = [];
  const dateStart = moment();
  const dateEnd = moment().add(30, "days");
  while (dateEnd.diff(dateStart, "days") >= 0) {
    days.push(dateStart.format("D"));
    dateStart.add(1, "days");
  }
  return days;
}

export function formatBytes(bytes, decimals = 2) {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}
