import { useCallback, useEffect, useState, /*forwardRef, useRef,*/ } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Grid, styled, } from "@mui/material";
//import { TroubleshootRounded } from '@mui/icons-material';
import {
  getSMediaMgr, resetSMediaTokens,//getActiveTokenCount,
  //initSMediaMgr,// setAccount, setSMediaConnStat,
  useGetSMediaAccountListQuery, useSMediaLogoutMutation,
  //useGetSMediaDataQuery, useLazyGetSMediaAccountListQuery,
} from 'services/sMediaApi';
//import { initSMediaUserFeedFilters, getSMediaUserFeedFilters } from 'services/orgFeedApi';
import { API_BASE_URL, MAX_SAUTH_WIN_OPEN_TIMEMS } from 'config/app.config';
import { socialMedia } from 'config/ui.config';
import { decodeSNectConnResp, fInitSMAuth, //fFetchSMediaAccData,
  fFetchSMediaAccProps, compareJsonObj
} from 'lib/appFx'; //getConnStatDAttr
import { parseQStr, fGetCookie, fDelCookie } from 'lib/netFx';
import { Modal, Tooltip, Typography } from 'core';
import { selectAuthToken, selectAuthUserCredentials } from 'services/auth/authSlice';
//The best place to put the modal but this component is being included thrice!!!
//import SocialMediaConnectModal from 'sMediaWidget/SocialMediaConnectModal';
//And so I finally moved the modal to its best place..
import SocialMediaConnectModal from "sections/widgets/sMediaWidget/SocialMediaConnectModal";
//---------------------------------------------------------

const SocialIconContainer = styled('div')(({ theme, active, connected }) => ({
  backgroundColor: connected ? theme.palette.background.paper : '#d3d3d3',
  width: '40px', height: '40px', display: 'flex',
  alignItems: 'center', justifyContent: 'center',
  padding: '7px 5px 5px',
  boxShadow: theme.shadows[5],
  border: `2px solid  ${
    active && connected ? theme.palette.primary.main : theme.palette.error.main
  } `,
  borderRadius: '100%', textAlign: 'center',
}));

export default function SocialNetworks({
  theme,  //onInitSAuth, //sMediaData, // refetchA,
  isUserList = false, uFeedFilter, setUFeedFilter,
}) {
  const dispatch = useDispatch();
  let theSMData, theSMList; //= sMediaData;sMIdKeyMap
  //const fIniSMAuth = fInitSMAuth;
  //const authUserToken = useSelector(selectAuthToken);
  //const authUserCred = useSelector(selectAuthUserCredentials);
  const { authToken, isAdmin } = useSelector(selectAuthUserCredentials);
  //console.log('authTokenSN', authToken);

  theSMData = useSelector(getSMediaMgr);
  //theSMData && console.log('theSMDataSMW', theSMData);
  //sMIdKeyMap = useSelector(getSMIdKeyMap);
  //const [fSMediaLogout, { data, isLoading }] = useSMediaLogoutMutation();
  //const [fSNetAcc] = useLazyGetSMediaAccountListQuery();

  const {
    data: dDataAL,
    //error: dErrAL, isLoading: dWaitAL,
    //isFetching: dFetchAL, isSuccess: dOKAL,
    refetch: refetchSMAccList,
  } = useGetSMediaAccountListQuery();

  /*const {
    data: dDataA, error: dErrA,
    isLoading: dWaitA, isFetching: dFetchA,
    isSuccess: dOKA, refetch: refetchA,
  } = useGetSMediaDataQuery({isAdmin: isAdmin}, { skip: false });*/

  /*const fetchUserSMAcc = useCallback(async () => {
    let { data: usrSMAcc } = await refetchSMAccList();
    usrSMAcc?.tokens && dispatch(resetSMediaTokens(usrSMAcc));
  }, [fSNetAcc, dispatch]);*/

  //console.log('uFeedFilterT', uFeedFilter);
  const setUFSMFilterStatus = (sKey, uFeedFilter, fCB, status=true) => {
    //if(typeof fCB === 'function')
    const currUFeedFilterStat = JSON.parse(JSON.stringify(uFeedFilter));
    Object.keys(currUFeedFilterStat).forEach((sK) => {
      if(sK === sKey) { currUFeedFilterStat[sK]['status'] = status; }
    });//console.log('uFeedFilterStat '+typeof(fCB), currUFeedFilterStat);
    //setUFeedFilter && setUFeedFilter(currUFeedFilterStat);
    if(!compareJsonObj(uFeedFilter, currUFeedFilterStat)) {
      fCB && fCB(currUFeedFilterStat);
    }
  };
  const setUFXclusiveSMFilter = (sKey, uFeedFilter, fCB) => {
    //if(typeof fCB === 'function')
    const currUFeedFilterStat = JSON.parse(JSON.stringify(uFeedFilter));

    Object.keys(currUFeedFilterStat).forEach((sK) => {
      if(sK === sKey) { currUFeedFilterStat[sK]['status'] = true; }
      else { currUFeedFilterStat[sK]['status'] = false; }
    });//console.log('uFeedXFilterStat '+typeof(fCB), currUFeedFilterStat);
    //setUFeedFilter && setUFeedFilter(currUFeedFilterStat);
    if(!compareJsonObj(uFeedFilter, currUFeedFilterStat)) {
      fCB && fCB(currUFeedFilterStat);
    }
  };

  const fInitAuthSM = (e, sKey, flgcBackFilterFx) => {
    //const pramLen = arguments.length;
    //console.log('fInitAuthSM_'+sKey+'_'+pramLen+'_'+flgcBackFilterFx, arguments);
    //console.log('fInitAuthSM_'+sKey+'_'+flgcBackFilterFx, arguments);
    if(flgcBackFilterFx) {
      fAuthSM(e, sKey, setUFSMFilterStatus, [uFeedFilter, setUFeedFilter]);
    } else { fAuthSM(e, sKey); }
  };

  //----- BelowFxs(fetchUserSMAcc&&fAuthSM)AreAlmostIdentical:
  //SocialNetworks.jsx, EditNetwork.jsx & SocialNetworkCard2.js
  //KeepItSame. IdealCandidateForACustomeHook
  const fetchUserSMAcc = useCallback(
    async (sKey=null, sMId=null, refFx=null, refArgs=null) => {
      //RefreshUserSMAccTokens
      //dOKAL && //
      let { data: usrSMAcc } = await refetchSMAccList();
      //let usrSMAcc = await fFetchSMediaAccData(authToken);
      //dispatch(resetSMediaTokens(usrSMAcc));
      usrSMAcc?.tokens && console.log('usrSMAccFUSMA_'+sKey+' '+sMId, usrSMAcc);
  
      if(sKey && usrSMAcc?.tokens) {// &&sMId
        let isAnySubs = false;
        const selSMKeyToken = usrSMAcc?.tokens.find((item) => {
          return item.snetworkplatform_id === sMId;
        });
        //const selSMKeyToken = usrSMAcc?.tokens?.some((item) => {
          //return item.snetworkplatform_id === data.sMId;
        //});

        if(selSMKeyToken?.properties) {
          for(const pg of selSMKeyToken?.properties) {
            //console.log('isAdmin', isAdmin, 'pg', pg);
            //if(!pg.is_subscribed) {
              //Disable LnCallForNonAdmin
              if(!pg.is_subscribed &&
                ((isAdmin && (sKey !== 'fb' || (sKey === 'fb' && pg.type === 'page'))) ||
                  (!isAdmin && pg.type === 'account' && sKey !== 'ln')
                )
              ) {
                const subsPg = await fFetchSMediaAccProps(authToken, pg.id);
                isAnySubs = subsPg || isAnySubs;
                //console.log('isSubscribed ', subsPg);
            }
          }

          //if(!flgInitSMAccList) {
          let { data: usrSMAcc } = await refetchSMAccList();
          /*} else {
            console.log('CCC', flgInitSMAccList);
            aList = await fLazyAccList().unwrap();
          }*/
  
          //if(isAnySubs) {//TheSMConnIsSuccessAnyways..
          //setTimeout(()=>{
            dispatch(resetSMediaTokens(usrSMAcc));//console.log('D');
            if(typeof refFx === 'function') {
              //setTimeout(()=>{
                refFx(sKey, refArgs[0], refArgs[1]);
              //}, 2000);
            }
            //dispatch(resetSMediaTokens(usrSMAcc));//console.log('D');
          //}, 500);
          //}
        }
        } else if(sKey) { console.log('Connected but no tokens issued'); }
      else {
        dispatch(resetSMediaTokens(usrSMAcc));
        //console.log('resetCalledEN');
      }
      },
    //[fSNetAcc, dispatch]);//resetSMediaTokens <- Don't
    [dispatch, resetSMediaTokens, refetchSMAccList]);//resetSMediaTokens

  const fAuthSM = useCallback((e, sKey, refFx=null, refArgs=null) => {
    //ForHeaven'sSake, DON'T put any state logic here.
    //TookMe3DaysToClearTheMess!!
    //This function is ONLY for initiating SMConn, wait for
    //response cookie & delegate state logics to fetchUserSMAcc. Period.
    e.preventDefault();
    let maxWaitTime = MAX_SAUTH_WIN_OPEN_TIMEMS;
    if(isAdmin) { maxWaitTime *= 2; }
    //console.log('fAuthSMArgs_'+arguments.length, arguments);
    //const sKey = e.target.getAttribute('data-sa-smkey');
    let sChk, theCk, cName = 'sMAT_Ukn', sMTokenForId;
    sMTokenForId = theSMData[sKey]?.sMId;
    sKey && fInitSMAuth(theSMData[sKey].authUrl, sKey, isAdmin);

    setTimeout(() => {
      sChk = setInterval(async() => {
        theCk = fGetCookie(cName);
        if(theCk) {//console.log('theSweetCookie_'+sKey, theCk);
          theCk = parseQStr(theCk); //if(theCk.startsWith('module=oauth&')) {
          if(theCk?.module === 'oauth' && theCk?.payload) {
            theCk.payload = decodeSNectConnResp(theCk.payload);
            //console.log('theSweetCookie2_'+sKey, theCk);
            //console.log('theSMDataSS '+sMTokenForId+' '+
            //	theCk.payload?.snetworkplatform_id, theSMData);

            //if(theCk.payload?user_id === ) {snetworktoken_id
            if(sMTokenForId &&
                    theCk.payload?.snetworkplatform_id === sMTokenForId
            ) {
              fDelCookie(cName);
              //setTimeout(() => {
                clearInterval(sChk);
              //}, 2000);
              await fetchUserSMAcc(sKey, theSMData[sKey]['sMId'], refFx, refArgs);
            }// else {console.log('Noooo');}
          }
          //fDelCookie(cName);
          //clearInterval(sChk);
        }
      }, 2000);
    }, 5000);

    setTimeout(() => sChk && clearInterval(sChk),
      maxWaitTime + 1000);
  }, [theSMData, fInitSMAuth, fetchUserSMAcc]);

  const [open, setOpen] = useState(false);
  const [modalData, setModalData] = useState();

  /*const handleClose = async (status = false) => {
    if(status) {
      try {
        const dataMain = await fSMediaLogout(modalData?.sMTokens?.id);
        if(dataMain.data.status) {
          // const { data: dData } = await refetchA();
          // dispatch(initSMediaMgr(dData));
          await fetchUserSMAcc();
          setOpen(false);
        }
      } catch(err) {
        console.error(err, 'this is in SocialNetwork 112');
      }
    }
  };*/
  //let activTokenCount = useSelector(getActiveTokenCount)
  //if(notYetShown(checkCookie(Age10Hrs)) && !activTokenCount) {
  //	setOpen(true);
  //}
  const handleDisconnect = useCallback((data) => {
    setOpen(true);
    setModalData(data);
  });
  //----------------------

  //const uFeedFilters = useSelector(getSMediaUserFeedFilters);
  if(theSMData) {
    theSMList = [];
    let userFFData = {};
    for(let sK in theSMData) {
      let dR = theSMData[sK];
      let allowConn = dR.hasOwnProperty('authUrl') && !['igb', 'yt'].includes(sK);// && sK !== 'ln';
      /*if(allowConn && ['ig', 'igb'].includes(sK)) {
        if(isAdmin) { allowConn = sK === 'igb' }
        else { allowConn = sK === 'ig' }
      }*/

      if(allowConn) {
        // let paletteBGColor =
        //   dR?.connStat === 'Connected'
        //     ? theme.palette.primary.main
        //     : theme.palette.error.main;
        // let lnkStat = dR?.connStat === 'Connected' ? true : false;
        // let show = dR?.connStat === 'Connected' ? 'block' : 'none';
        // let arrow = dR?.authUrl && 'pointer';

        //const hasToken = dR?.activTokenId || false;
        const hasToken = dR?.sMTokens?.properties?.length > 0;
        let paletteBGColor = hasToken
          ? theme.palette.primary.main
          : theme.palette.error.main;
        let lnkStat = hasToken ? true : false;
        let show = hasToken ? 'block' : 'none';
        let arrow = dR?.authUrl && 'pointer';
        //dR.sMKey === 'fb' && console.log(dR.sMKey+'_hToken_'+lnkStat+'_'+hasToken+'_'+isUserList+'_'+paletteBGColor, uFeedFilter);

        theSMList.push(
          <Grid item key={dR.sMId + '_' + dR.sMKey} id={'sMWConnIcn_'+dR.sMKey}
            {...(dR.sMKey === 'ln' && { className: 'dNone' })}
          >
            <div
              sa-smkey={dR.sMKey} id={'lnkWidSMConn_'+dR.sMKey}
              // style={{ cursor: screen.width<900?"none":arrow }}
              style={{ cursor: arrow }}
              // sx={{cursor:{xs:"none",md:arrow}}}
              onClick={(e) => {
                isUserList
                  ? (lnkStat
                    ? setUFXclusiveSMFilter(sK, uFeedFilter, setUFeedFilter)
                    : fAuthSM(e, dR.sMKey, setUFSMFilterStatus,
                      [uFeedFilter, setUFeedFilter])
                  )
                  : dR.authUrl && !lnkStat
                    ? fAuthSM(e, dR.sMKey)
                    : dR.authUrl && lnkStat && dR?.activTokenId &&
                      handleDisconnect(dR);
              }}
              //title={sMDAttr.ttl} //sa-au={dR?.authUrl?.substring(0, 10)}
            >
              {isUserList && uFeedFilter ? (
                <SocialIconContainer active={uFeedFilter[dR.sMKey].status} connected={lnkStat}>
                    {true ?
                      lnkStat ? socialMedia[dR.dispTitle] : socialMedia['Disabled'+dR.title] :
                      socialMedia[dR?.dispTitle]}
                </SocialIconContainer>
              ) : (
                <Tooltip
                  title={
                    <Typography
                      sx={{
                        backgroundColor: paletteBGColor,
                        color: 'white',
                        padding: '5px 10px',
                        fontWeight: 600,
                        textTransform: 'capitalize',
                        display: show,
                      }}
                    >
                      {dR?.connStat === 'Connected' && 'Connected'}
                    </Typography>
                  }
                  placement="top"
                  active={lnkStat}
                >
                  <SocialIconContainer active={lnkStat}  connected={lnkStat}>
                    {lnkStat ? socialMedia[dR.dispTitle] : socialMedia['Disabled'+dR.title]}
                  </SocialIconContainer>
                </Tooltip>
              )}
            </div>
          </Grid>
        );

        userFFData = {
          ...userFFData,
          [sK]: {id: dR?.sMId, status: lnkStat},
        };
      }
    }

    if(!uFeedFilter) {//InitUFeedFilter
		//console.log('userFFData_uFeedFilter', userFFData, uFeedFilter);
		if(setUFeedFilter) {//setUFeedFilter(userFFData);
			setTimeout(()=> {setUFeedFilter(userFFData);}, 50);
		}
	}
  }

  /*const [disconnectSocialTitle, setdisconnectSocialTitle] = useState(null);
  useEffect(() => {
    if (modalData) {
      switch (modalData?.title) {
        case 'Instagramp':
          setdisconnectSocialTitle('Instagram');
          break;

        default:
          setdisconnectSocialTitle(modalData?.title);
          break;
      }
    }
  });*/
  //---------------------------------------------------------
  /*const sMediaConnectBtnRef = useRef(null);
  const initSMConnDialog = (smkey) => {
    sMediaConnectBtnRef.current().cl
  };*/
  //----------------------
  
  const [forceSMConnModal, setForceSMConnModal] = useState(false);
  const fFSMCOpen = () => { setForceSMConnModal(true); };
  const cBaitBtn = (
    <button id="btnFOpenSMCModal" onClick={fFSMCOpen}
      style={{
        display: "none", visibility: "hidden",
        fontSize: "0px", borderStyle: "none",
        cursor: "default",
        width: "0", height: "0", margin: "0",
        padding: "0", color: "#f0f0f0",
        backgroundColor: "#f0f0f0",
      }}
    >
      &#x2022;
    </button>
  );
  //----------------------
  //---------------------------------------------------------

  return (
    <>
      {/*<Dialog maxWidth="sm" fullWidth
        open={open} onClose={() => { setOpen(false); }}
      >
        <DialogTitle id="alert-dialog-title">
          <Typography variant="h5">
            Disconnect {disconnectSocialTitle}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure want to disconnect {disconnectSocialTitle} account ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { handleClose(true); }}>
            Disconnect
          </Button>
          <Button onClick={() => { setOpen(false); }}// autoFocus
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>*/}
      <Grid container
        columnGap={3} flexDirection="row"
        alignItems="center" justifyContent="center"
      >
        {theSMList}
      </Grid>
      
      <SocialMediaConnectModal
        sMediaMethods={{ fInitAuthSM }}
        uFeedFilter={uFeedFilter} setUFeedFilter={setUFeedFilter}
        forceOpen={forceSMConnModal} setForceOpen={setForceSMConnModal}
      />
      {cBaitBtn}
    </>
  );
}
