import { useState, useMemo } from "react";
import PropTypes from "prop-types";
import {
  Paper, Box, Avatar, IconButton, Toolbar, //Typography,
  Checkbox, //Menu, MenuItem, Divider, CardHeader, Tooltip,
  //FormControlLabel, Switch, Collapse,
} from '@mui/material';
import { checkboxClasses } from "@mui/material/Checkbox";
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import {
  TableContainer, Table, TableHead, TableBody, TableRow,
  TableCell, TablePagination, TableSortLabel,
} from '@mui/material';
//import { alpha } from '@mui/material/styles';
//import { visuallyHidden } from '@mui/utils';
//import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
//import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
//import FilterListIcon from '@mui/icons-material/FilterList';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Typography } from 'core';
import {
  fGetStrSlug, fGetStrAbbr, fStrToColor, //fGetNumAbbr,
  fGetTrimmedText, fStableSort, fGetComparator, // fSafeStringify,
} from 'lib/appFx';
import { useTranslation } from "react-i18next";
//---------------------------------------------------------

const idCol = 'id';
const defSortColKey = 'name';
//const dTableTitle = 'Group Members';
const dTableTitle = fGetStrSlug('Group Members');


const tHeadMeta = [//align:'center', disablePadding
  { colKey: 'name', colTitle: 'NAME', isNumeric: false, minWidth: '14rem' },
  { colKey: 'email', colTitle: 'EMAIL', isNumeric: false, minWidth: '12rem' },
  //{ colKey: 'points', colTitle: 'LEADERBOARD', isNumeric: true, align: 'left' },
  //{ colKey: 'actions', colTitle: 'ACTIONS',  isNumeric: false, disableColSort: true},
];
const colKeys = [];
const rowKeyPrefix = 'aGAMDR_r_';
tHeadMeta.forEach(c => colKeys.push(c.colKey));
const fEnsureAllKeys = (dRows, actionMethods = null) => {
  dRows.forEach((dR, idx) => {
    if (!(idCol in dR)) { dR[idCol] = idx; }
    //console.log('dRows', dRows);
    const data_r = {
      id: dR.id, name: dR.name, email: dR.email//, points: dR?.points
    };
    const rBG = fStrToColor(dR.name);
    dR['data-r'] = JSON.stringify(data_r);
    //const rowJsonData = structuredClone(dR);
    //dR['data-r'] = fSafeStringify(dR);//toString()

    colKeys.forEach(kN => {
      switch (kN) {
        case 'name':
          dR['jsx_' + kN] = (
            <Box //component="span"
              sx={{ display: 'flex', alignItems: 'center', maxHeight: '2.2rem' }}
            >
              {
                <Avatar className='dImg' sx={{ ...profilePicSx, bgcolor: rBG }}
                  alt={dR.name} title={dR.name}
                  src={dR?.profilePic}//</Box> sx={{bgcolor: rBG}}
                >{fGetStrAbbr(dR.name)}</Avatar>
              }
              <Typography ml={1}>{fGetTrimmedText(dR[kN])}</Typography>
            </Box>
          );
          //(<CardHeader avatar={ <Avatar alt={dR[kN]} /> } title={dR[kN]} />)
          break;
        case 'email': dR[kN] = fGetTrimmedText(dR[kN]); break;
        /*case 'points':
          if(!(kN in dR)) {//if(!dR.hasOwnProperty(kN)) {
            //dR[kN] = '-';
            dR[kN] = 0;
            dR['jsx_'+kN] = (<span>0</span>);
          }
          break;
        case 'actions': 
          if(!(kN in dR)) {
            dR[kN] = (
              <Box id={'dR_c_'+dR[idCol]} data_r={dR['data-r']}>
                {<IconButton>
                  <MoreVertIcon sx={actionIconsSx} />
                </IconButton>}
                <IconButton onClick={actionMethods.fCopyUserData}>
                  <ContentCopyIcon sx={actionIconsSx} size='small' />
                </IconButton>
                <IconButton onClick={actionMethods.fInitRemMem}>
                  <DeleteIcon sx={{...actionIconsSx, color: '#eb5a5a'}} />
                </IconButton>
              </Box>
            );
          }
          break;*/
        default:
          break;
      }
    });
  });
};

const flgLimitSelAllToCurrPage = true;
const flgAllowCheckRow = true;
const flgAllowRowClick = false;
//const flgAllowCollapseRow = false;
const pageSize = 10;
//const minRowHeight = 32;//'2rem';
const minRowHeight = 60;//'2.5rem';
const thRowSx = {
  height: '2rem', background: '#f3f2f7',
  //"&:last-child td, &:last-child th": { border: 0 },
};
const profilePicSx = {
  display: 'flex',
  flexShrink: 0,
  width: '1.8rem',
  height: '1.8rem',
  justifyContent: 'center',
  alignItems: 'center',
  bgcolor: '#bdbdbd',
  fontSize: '0.8rem',
};
const actionIconsSx = { width: '1.2rem', height: '1.2rem', color: '#9e9e9e' };
const thColSx = { fontSize: '0.75rem', fontWeight: 600, color: '#00406b' };
const chkBoxSx = {
  [`&, &.${checkboxClasses.checked}`]: { color: '#00406b', },
  ['&, &.' + checkboxClasses.indeterminate]: { color: '#00406b', },
};
//---------------------------------------------------------
//---------------------------------------------------------

export default function AdmGroupAddMemberDataTable({
  theme, addMemTableData, addMemTableMethods
}) {
  console.log('groupAddMemTableData', addMemTableData);
  const filterTerm = addMemTableData?.filterTerm || '';
  const allRowCount = addMemTableData?.memberGT || 0;
  const dataRows = addMemTableData?.memberData || [];
  const { t: home } = useTranslation('home');
  //console.log('dataRows', dataRows);
  //---------------------------------------------------------

  const fGetClickedRowData = (oE) => {
    let rData = oE?.currentTarget?.parentElement;
    rData = rData?.getAttribute('data_r');
    /*if(!rData) {
      rData = oE?.currentTarget?.parentElement?.parentElement;
      rData = rData?.getAttribute('data_r');
    }*/console.log('clRowRData', rData);
    rData = JSON.parse(rData);
    return rData && rData?.id ? rData : null;//undefined;
  };
  dataRows.length && fEnsureAllKeys(dataRows);
  //console.log('dataRowsEnsure', dataRows); 
  //---------------------------------------------------------

  const [stSortDir, setStSortDir] = useState('asc');
  const [stOrderByCol, setStOrderByCol] = useState(defSortColKey);
  const [stSelRowIds, setStSelRowIds] = useState([]);
  const [stPage, setStCurrPage] = useState(0);
  //const [rowsPerPage, setRowsPerPage] = useState(5);
  //const dataRowsLen = dataRows?.length;
  const dataRowsLen = allRowCount;
  const pageGT = dataRowsLen < pageSize ? 1 : Math.ceil(dataRowsLen / pageSize);
  const isLastPage = stPage + 1 === pageGT;

  /*const fSortByCol = (event, colName) => {
    const isAsc = stOrderByCol === colName && stSortDir === 'asc';
    setStSortDir(isAsc ? 'desc' : 'asc');
    setStOrderByCol(colName);
  };
  /*const currPageRows = useMemo(() =>
    fStableSort(dataRows, fGetComparator(stOrderByCol, stSortDir)).
      slice(stPage * pageSize, stPage * pageSize + pageSize,)
  , [stSortDir, stOrderByCol, stPage, pageSize]
  );*/
  const currPageRows = useMemo(() => {
    let pgRows = flgLimitSelAllToCurrPage ?
      dataRows.slice(stPage * pageSize, stPage * pageSize + pageSize) :
      dataRows.slice();
    pgRows = fStableSort(pgRows, fGetComparator(stOrderByCol, stSortDir));

    return flgLimitSelAllToCurrPage ?
      pgRows :
      pgRows.slice(stPage * pageSize, stPage * pageSize + pageSize);
  }, [dataRows, stOrderByCol, stSortDir, stPage, pageSize, flgLimitSelAllToCurrPage]);
  //console.log('currPageRowsP'+stPage+' orderBy:'+stOrderByCol+' '+stSortDir, currPageRows);

  const fSelAllRows = (event) => {
    if (event.target.checked) {
      //const newSelected = dataRows.map((n) => n[idCol]);
      const newSelected = (flgLimitSelAllToCurrPage) ?
        currPageRows.map((n) => n[idCol]) :
        dataRows.map((n) => n[idCol]);//console.log('newSelected', newSelected);
      setStSelRowIds(newSelected);
      return;
    }
    setStSelRowIds([]);
  };
  //const fSetCurrPage = (event, newPage) => { setStCurrPage(newPage); };
  const fSetCurrPage = (event, newPage) => {
    //setStCurrPage(newPage);
    //flgLimitSelAllToCurrPage && setStSelRowIds([]);
    addMemTableMethods.fSetCurrPage(newPage + 1);
  };
  /*const fSetPageSize = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setStCurrPage(0);
  };*/

  const handleSelRowClick = (event, rowId) => {
    const selectedIndex = stSelRowIds.indexOf(rowId);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(stSelRowIds, rowId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(stSelRowIds.slice(1));
    } else if (selectedIndex === stSelRowIds.length - 1) {
      newSelected = newSelected.concat(stSelRowIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        stSelRowIds.slice(0, selectedIndex),
        stSelRowIds.slice(selectedIndex + 1),
      );
    }
    setStSelRowIds(newSelected);
  };
  const fToggleMemCheck = (event, rowId) => {
    const isChecked = event.target.checked;
    console.log('fToggleMemCheck_' + event.target.checked, rowId);
    isChecked ? addMemTableMethods.fSelectMember(rowId) :
      addMemTableMethods.fDeselectMember(rowId)
  };

  const isRowSelected = (id) => stSelRowIds.indexOf(id) !== -1;
  const emptyRowCount = isLastPage ? // && stPage > 0 ?
    Math.max(0, (1 + stPage) * pageSize - dataRowsLen) : 0;
  //console.log('emptyRowCount:', {emptyRowCount, dataRowsLen, isLastPage, filterTerm});
  //---------------------------------------------------------
  const noDataJsx = (
    <Box className='xbdr1' sx={{ margin: 'auto', textAlign: 'center' }}>
      <Typography sx={{ fontSize: '1rem' }}>
        <br />{home('no_members_to_add')}
      </Typography>
    </Box>
  );
  const noSuchDataJsx = (
    <Box className='xbdr1' sx={{ margin: 'auto', textAlign: 'center' }}>
      <Typography sx={{ fontSize: '1rem' }}>
        {home('no_such_member_found')}
      </Typography>
    </Box>
  );
  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        {/*<DataTableMetaBar tblTitle={dTableTitle} totalSelRows={stSelRowIds.length} />*/}
        <TableContainer //component={Paper} //height: {md: '597px'}
        //sx={{padding: "0 0.7rem", paddingBottom: "1rem",}}
        >
          <Table
            //className='dataTbl'
            sx={{ minWidth: 300, maxWidth: 500 }}
            aria-labelledby={dTableTitle}
            size='small' //{/*stickyHeader*/}
          >
            {/*<DataTableHd
              tableHeadMeta={tHeadMeta}
              pageCount={pageGT}
              rowPerPage={pageSize}
              currPage={stPage}
              rowsGT={dataRowsLen}
              flgLimitSelAllToCurrPage={flgLimitSelAllToCurrPage}
              selRowCount={stSelRowIds.length}
              onSelAllRows={fSelAllRows}
              sortByCol={stOrderByCol}
              sortDir={stSortDir}
              onSortByCol={fSortByCol}
              dispCheckBox={flgAllowCheckRow}
              //dispCollapseRow={flgAllowCollapseRow}
            />*/}
            <TableBody>
              {currPageRows.map((row, index) => {
                const isTrSelected = isRowSelected(row[idCol]);
                const chkLabelId = `chkLabel-${index}`;

                return (
                  <TableRow
                    key={rowKeyPrefix + row[idCol]}
                    id={rowKeyPrefix + row[idCol]}
                    //minHeight={minRowHeight}
                    {...(flgAllowCheckRow ? {
                      selected: isTrSelected,
                      //onClick: (event) => handleSelRowClick(event, row[idCol]),
                      onClick: flgAllowRowClick ?
                        (event) => handleSelRowClick(event, row[idCol]) : null,
                      hover: true, role: "checkbox",
                      'aria-checked': isTrSelected, tabIndex: -1,
                    } : null)
                    }
                    sx={{
                      cursor: flgAllowRowClick ? 'pointer' : 'default',
                      minHeight: minRowHeight,
                      height: minRowHeight,
                    }}
                  //sx={{ cursor: 'pointer' }}
                  //selected={isTrSelected}
                  //onClick={(event) => handleSelRowClick(event, row[idCol])}
                  //hover role="checkbox" aria-checked={isTrSelected} tabIndex={-1}
                  //data-r={row['data-r']}
                  //inputProps={{'data-r': row['data-r']}}
                  >

                    {/*dispCollapseRow && (PENDING)*/}
                    {
                      colKeys.map((k, i) => {
                        //const colMeta = tHeadMeta.filter(c=>c.colKey === k);
                        const colMeta = tHeadMeta[i];
                        return (
                          <TableCell key={'tc_' + row[idCol] + '_' + k} size='small'
                            {...(i > 0 ? {
                              align: (colMeta?.align ? colMeta?.align :
                                colMeta?.isNumeric ? 'center' : 'left'),
                              //colMeta?.isNumeric ? 'right' : 'left'),
                              padding: 'none'
                            } : {
                              component: 'th', //padding: 'none',
                              id: chkLabelId, scope: 'row',
                            })}
                          //{...()}
                          //sx={{}}
                          >
                            {
                              row.hasOwnProperty('jsx_' + k) ? row['jsx_' + k] : (row[k])
                            }
                          </TableCell>
                        )
                      })
                    }
                    {flgAllowCheckRow && (
                      <TableCell padding="checkbox" size='small'>
                        <Checkbox
                          color="primary"
                          //checked={isTrSelected}
                          //onClick={(event) => handleSelRowClick(event, row[idCol])}
                          checked={addMemTableData?.selectedMemIds.includes(row[idCol])}
                          onClick={(event) => fToggleMemCheck(event, row[idCol])}
                          inputProps={{ 'aria-labelledby': chkLabelId, }}
                          sx={chkBoxSx}
                        />
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
              {emptyRowCount > 0 && (
                <TableRow style={{ height: minRowHeight * emptyRowCount, }}>
                  <TableCell className='vATp'
                    colSpan={flgAllowCheckRow ? tHeadMeta.length + 1 : tHeadMeta.length}
                  >
                    {dataRowsLen ? null : (
                      allRowCount > 0 || filterTerm.length > 0 ?
                        noSuchDataJsx : noDataJsx
                      //filterTerm.length > 0 ? 'No such member found': noDataJsx
                      //allRowCount > 0 ? 'No such member found': noDataJsx
                    )}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={dataRowsLen}
          rowsPerPage={pageSize}
          //page={stPage}
          //onPageChange={fSetCurrPage}
          page={addMemTableData.currPage - 1}
          onPageChange={fSetCurrPage}
          rowsPerPageOptions={[]}//{[5, 10, 25]}
        //onRowsPerPageChange={fSetPageSize}
        //labelRowsPerPage=''
        //ActionsComponent={()=> {}}
        />
      </Paper>
    </Box>
  );
};
//=========================================================

function DataTableMetaBar(props) {
  const { tblTitle, totalSelRows } = props;

  return (
    <Toolbar sx={{
      pl: { sm: 2 }, pr: { xs: 1, sm: 1 },
      ...(totalSelRows > 0 && { bgcolor: '#c1c1c1', }),
    }}
    >
      <Typography component="div" sx={{ flex: '1 1 100%' }} color="inherit">
        {totalSelRows || 'X'}
      </Typography>

      {/*totalSelRows > 0 ? (
        <Tooltip title="Delete"><IconButton><DeleteIcon /></IconButton></Tooltip>
      ) : (
        <Tooltip title="Filter data"><IconButton><FilterListIcon /></IconButton></Tooltip>
      )*/}
    </Toolbar>
  );
}
DataTableMetaBar.propTypes = {
  tblTitle: PropTypes.string, //.isRequired,
  totalSelRows: PropTypes.number.isRequired,
};
//=========================================================

function DataTableHd({
  tableHeadMeta, currPage, pageCount, rowsGT, selRowCount, onSelAllRows,
  sortByCol, sortDir, onSortByCol, dispCheckBox = true, rowPerPage, dispCollapseRow = false,
  flgLimitSelAllToCurrPage = true
}) {
  const fSetSortByCol = (colName) => (event) => { onSortByCol(event, colName); };
  const isLastPage = (currPage + 1 === pageCount);
  const currPageRowsCount = isLastPage ? rowsGT % rowPerPage : rowPerPage;
  //console.log('pg_'+currPage, {pageCount, rowPerPage, currPage, currPageRowsCount });

  return (
    <TableHead>
      <TableRow key={'gDTblRHd_' + dTableTitle} sx={thRowSx}>
        {dispCheckBox && (
          <TableCell padding="checkbox" sx={thColSx}>
            <Checkbox
              //indeterminate={selRowCount > 0 && selRowCount < rowsGT}
              //checked={rowsGT > 0 && selRowCount === rowsGT}
              indeterminate={flgLimitSelAllToCurrPage ?
                selRowCount > 0 && selRowCount < currPageRowsCount :
                selRowCount > 0 && selRowCount < rowsGT
              }
              checked={flgLimitSelAllToCurrPage ?
                currPageRowsCount > 0 && selRowCount === currPageRowsCount :
                rowsGT > 0 && selRowCount === rowsGT}
              onChange={onSelAllRows}
              inputProps={{ 'aria-label': 'Select all', }}
              title={selRowCount + ' selected'}
              //color="primary"
              //sx={{color: '#00406b'}}
              //labelStyle={{color: 'white'}}
              //iconStyle={{fill: 'red'}}
              sx={chkBoxSx}
            />
          </TableCell>
        )}
        {/*dispCollapseRow && (PENDING)*/}
        {tableHeadMeta.map((colM) => {//console.log('colM', colM);
          return (
            <TableCell
              key={colM.colKey}
              sortDirection={sortByCol === colM.colKey ? sortDir : false}
              //align={colM?.isNumeric ? 'right' : 'left'}
              align={'left'}// sx={thColSx}
              padding='none'//{colM.disablePadding ? 'none' : 'normal'}
              sx={{ ...thColSx, ...(colM?.minWidth ? { minWidth: colM.minWidth } : null) }}
            >
              {!colM?.disableColSort ? (
                <TableSortLabel
                  sx={thColSx}
                  active={sortByCol === colM.colKey}
                  direction={sortByCol === colM.colKey ? sortDir : 'asc'}
                  //hideSortIcon={false}
                  //IconComponent={}
                  onClick={fSetSortByCol(colM.colKey)}
                >
                  {colM?.colTitle || colM.colKey}
                  {/*sortByCol === colM.colKey ? (
                    <Box component="span" sx={visuallyHidden}>
                      {sortDir === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null*/}
                </TableSortLabel>
              ) : (
                <Box pl={1}>{colM?.colTitle || colM.colKey}</Box>
              )}
            </TableCell>
          )
        })}
      </TableRow>
    </TableHead>
  );
}
DataTableHd.propTypes = {//
  //tableHeadMeta: PropTypes.object.isRequired,
  tableHeadMeta: PropTypes.array.isRequired,
  /*tableHeadMeta: PropTypes.shape({
      colKey: PropTypes.string.isRequired,
      colTitle: PropTypes.string, //.isRequired
      isNumeric: PropTypes.bool, //.isRequired
    //disablePadding: PropTypes.bool,
  }),*/
  rowsGT: PropTypes.number.isRequired,
  selRowCount: PropTypes.number.isRequired,
  sortByCol: PropTypes.string.isRequired,
  sortDir: PropTypes.oneOf(['asc', 'desc']).isRequired,
  onSortByCol: PropTypes.func.isRequired,
  onSelAllRows: PropTypes.func.isRequired,
  //rowPerPage, dispCheckBox, flgLimitSelAllToCurrPage
};
//=========================================================