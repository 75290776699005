import { MAX_FILE_UPLOAD_SIZE } from 'config/app.config';
import ERRORS from 'config/validation.config';
import { formatBytes } from 'lib/utils';
import * as yup from 'yup';
//---------------------------------------------------------

const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];
const ContentSuggestionSchema = yup.object({
  text: yup
    .string()
    // .min(ERRORS.CONTENT_DESCRIPTION.min_chars, ERRORS.CONTENT_DESCRIPTION.min)
    .min(1, 'Please add some description')
    .max(512, 'Suggestion text can not be more than 512 characters')
    .required(ERRORS.CONTENT_DESCRIPTION.required),
  media: yup
    .mixed()
    .nullable()
    /*.test(
      `Maximum ${formatBytes(MAX_FILE_UPLOAD_SIZE)} allowed`,
      `Maximum ${formatBytes(MAX_FILE_UPLOAD_SIZE)} allowed`,
      (value) => !value?.map((image) => image.size > MAX_FILE_UPLOAD_SIZE)[0]
    )*/
    .test('fileLength', 'Maximum 5 files allowed', (value) => {
      return Boolean(value?.length <= 5);
    })
    // .test(
    //   'fileFormat',
    //   'File format not supported',
    //   (value) => !value?.map((image) => image.type.includes('image/*'))[0]
    // ),
}); 
export default ContentSuggestionSchema;
