import * as yup from "yup";
import ERRORS from "../config/validation.config";

const SignUpSchema = yup.object({
  first_name: yup
    .string()
    .matches(ERRORS.NAME.matches_regex,ERRORS.NAME.matches)
    .min(ERRORS.FIRST_NAME.min_chars, ERRORS.FIRST_NAME.min)
    .max(ERRORS.NAME.max_chars, ERRORS.NAME.max)
    .required(ERRORS.FIRST_NAME.required),
  last_name: yup
    .string()
    .matches(ERRORS.NAME.matches_regex,ERRORS.NAME.matches)
    .min(ERRORS.LAST_NAME.min_chars, ERRORS.LAST_NAME.min)
    .max(ERRORS.NAME.max_chars, ERRORS.NAME.max)
    .required(ERRORS.LAST_NAME.required),
  // city: yup.string().required(ERRORS.CITY.required),
  // mobile_number: yup
  //   .string()
  //   .required(ERRORS.MOBILE_NUMBER.required)
  //   .matches(ERRORS.MOBILE_NUMBER.regex, ERRORS.MOBILE_NUMBER.invalid),
  email: yup
    .string()
    .email(ERRORS.EMAIL.invalid)
    .required(ERRORS.EMAIL.required),
  password: yup
    .string()
    .min(ERRORS.PASSWORD.min_chars, ERRORS.PASSWORD.min)
    .matches(ERRORS.PASSWORD.regex, ERRORS.PASSWORD.invalid)
    .required(ERRORS.PASSWORD.required),
  confirm_password: yup
    .string()
    .min(ERRORS.PASSWORD.min_chars, ERRORS.PASSWORD.min)
    .matches(ERRORS.PASSWORD.regex, ERRORS.PASSWORD.invalid)
    .oneOf([yup.ref("password")], ERRORS.PASSWORD.not_matched)
    .required(ERRORS.PASSWORD.required),
});

export default SignUpSchema;
