import { Stack, Tooltip, styled } from '@mui/material';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import { SocialMediaShareTooltip, Typography } from 'core';
import { socialMedia, getSMediaIcons } from '../../../config/ui.config';
import SocialMediaDisabledTooltip from './SocialMediaDisabledTooltip';
//---------------------------------------------------------

const Display = styled('div')(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.down('sm')]: { display: 'block' },
}));

const SocialIconsContainer = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginTop: 1,
  backgroundColor: '#F5F7F8',
  padding: '0.7rem 2rem',
  // cursor: 'pointer',
  [theme.breakpoints.down('md')]: {
    justifyContent: 'space-between',
    padding: '0.7rem 0.5rem',
  },
}));
//---------------------------------------------------------

//CrossPlatformCmdBar
/*export default*/
// function PostCommandBarAllNew({
//   theme,
//   feedListMeta,
//   postCustomData: pInf,
//   feedData,
//   fFeedActionHandler,
//   defEngPoints,
//   commandBarSMs,

//   handleScheduleFeedForm,
//   handleFacebookFeed, //?
//   handleCreateFeedForm,
// }) {
//   const sMMeta = feedListMeta?.sMediaMgr;
//   const engSMs = [];
//   commandBarSMs.forEach((sK) => {
//     let sMEnabled = sMMeta[sK]?.activTokenId;
//     const dispTtl = sMMeta[sK].dispTitle;
//     const elKey = 'fCmd_' + sK + '_' + pInf.postId;
//     if (sK === 'em') {
//       engSMs.push(
//         <SocialMediaShareTooltip
//           key={elKey}
//           destKey={sK}
//           reward_points={50}
//           handleCreateFeedForm={handleCreateFeedForm}
//           fFeedActionHandler={fFeedActionHandler}
//         >
//           {socialMedia['Mail']}
//         </SocialMediaShareTooltip>
//       );
//     } else if (sK === 'sc') {
//       engSMs.push(
//         <AccessTimeOutlinedIcon
//           sa-destkey={'sc'}
//           onClick={handleScheduleFeedForm}
//           fFeedActionHandler={fFeedActionHandler}
//           sx={{
//             color: (theme) => theme.palette.secondary.dark,
//             height: 35,
//             width: 35,
//             cursor: 'pointer',
//           }}
//         />
//       );
//     } else if (sK === 'wa') {
//       engSMs.push(
//         <Display key={elKey}>
//           <SocialMediaShareTooltip
//             destKey={sK}
//             reward_points={50}
//             handleCreateFeedForm={handleCreateFeedForm}
//             fFeedActionHandler={fFeedActionHandler}
//           >
//             {socialMedia[dispTtl]}
//           </SocialMediaShareTooltip>
//         </Display>
//       );
//     } else if (sMEnabled) {
//       engSMs.push(
//         <SocialMediaShareTooltip
//           key={elKey}
//           destKey={sK}
//           reward_points={20}
//           handleFacebookFeed={sK === 'fb' ? handleFacebookFeed : null}
//           handleCreateFeedForm={handleCreateFeedForm}
//           fFeedActionHandler={fFeedActionHandler}
//         >
//           {socialMedia[dispTtl]}
//         </SocialMediaShareTooltip>
//       );
//     } else {
//       engSMs.push(socialMedia[dispTtl]);
//     }
//   });
//   return <SocialIconsContainer>{engSMs}</SocialIconsContainer>;
// }

export default function PostCommandBarAll({
  theme,
  feedListMeta,
  postCustomData: pInf,
  feedData,
  fFeedActionHandler,
  defEngPoints,
  commandBarSMs,
  setMessage,
  handleScheduleFeedForm,
  handleFacebookFeed,
  handleCreateFeedForm,
}) {
  const sMMeta = feedListMeta?.sMediaMgr;
  //console.log('pInfPCBA', pInf);
  return (
    <SocialIconsContainer>
      {sMMeta?.fb.activTokenId ? (
        <SocialMediaShareTooltip
          smKey={pInf.srcKey}
          setMessage={setMessage}
          pInf={pInf}
          destKey={'fb'}
          handleFacebookFeed={handleFacebookFeed}
          handleCreateFeedForm={handleCreateFeedForm}
          fFeedActionHandler={fFeedActionHandler}
          feedData={feedData}
        >
          {socialMedia['Facebook']}
        </SocialMediaShareTooltip>
      ) : (
        <SocialMediaDisabledTooltip
		 icon={socialMedia['DisabledFacebook'] }
		 sMKey={'fb'} name="facebook" />
      )}
      {sMMeta?.tw.activTokenId ? (
        <SocialMediaShareTooltip
          smKey={pInf.srcKey}
          setMessage={setMessage}
          pInf={pInf}
          destKey={'tw'}
          handleCreateFeedForm={handleCreateFeedForm}
          fFeedActionHandler={fFeedActionHandler}
          feedData={feedData}
        >
          {socialMedia['Twitter']}
        </SocialMediaShareTooltip>
      ) : (
        <SocialMediaDisabledTooltip icon={socialMedia['DisabledTwitter']}
		 sMKey={'tw'} name="twitter" />
      )}
      {sMMeta?.ln.activTokenId ? (
        <SocialMediaShareTooltip
          smKey={pInf.srcKey}
          setMessage={setMessage}
          pInf={pInf}
          destKey={'ln'}
          handleCreateFeedForm={handleCreateFeedForm}
          feedData={feedData}
          fFeedActionHandler={fFeedActionHandler}
        >
          {socialMedia['Linkedin']}
        </SocialMediaShareTooltip>
      ) : (
        <SocialMediaDisabledTooltip icon={socialMedia['DisabledLinkedin']}
		      sMKey={'ln'} name="linkedin" />
      )}
      {/* {sMMeta?.ln.activTokenId ? (
        <SocialMediaShareTooltip
          destKey={'em'}
          smKey={pInf.srcKey}
          setMessage={setMessage}
          reward_points={50}
          handleCreateFeedForm={handleCreateFeedForm}
          fFeedActionHandler={fFeedActionHandler}
        >
          {socialMedia['Mail']}
        </SocialMediaShareTooltip>
      ) : (
        socialMedia['Mail']
      )} */}
      {/* socialMedia['Mail'] */}
      {/* <AccessTimeOutlinedIcon
				sa-destkey={'sc'}
				setMessage={setMessage}

				onClick={handleScheduleFeedForm}
				sx={{
					color: (theme) => theme.palette.secondary.dark,
					height: 35,
					width: 35,
					cursor: "pointer",
				}}
			/> */}
      {/* <Display>
				<SocialMediaShareTooltip
					destKey={'wa'}
					setMessage={setMessage}
					smKey={pInf.srcKey}
					reward_points={50}
					handleCreateFeedForm={handleCreateFeedForm}
					fFeedActionHandler={fFeedActionHandler}
				>
					{socialMedia["Whatsapp"]}
				</SocialMediaShareTooltip>
			</Display> */}
    </SocialIconsContainer>
  );
}
