import { useState, useCallback } from "react";
//import ReactDOM from "react-dom/client";
import { Grid, Box, Pagination, PaginationItem, } from "@mui/material";
//import InfiniteScroll from 'react-infinite-scroller';
import { Spinner2, SpinnerBox, Flasher, PaginationList } from 'core';
import { SA_DEF_ORG_GROUP_ID, } from 'config/app.config';
import { fMoveElementToFirst } from 'lib/appFx';
import { useGroupListQuery } from 'services/admin/admGroupsApi';
import AdmGroupInfoCard from "./AdmGroupInfoCard";
import AdmGroupDelDialog from "./AdmGroupDelDialog";
//---------------------------------------------------------

const ctnGroupsSx = { display:'flex', flexWrap:'wrap', };//flexGrow: 1,
//---------------------------------------------------------

export default function AdmGroupList({ theme, }) {
  //const dispatch = useDispatch();
  //const [stOrgGroupOK, setStOrgGroupOK] = useState(false);
  const [stDelGroupData, setStDelGroupData] = useState(null);
  const [stAjaxStep, setStAjaxStep] = useState(0);//-1, 0, 1, 2
  const [stDPage, setStDPage] = useState(1);//Min: 1
  const gPageSize = 9;//3x3Grid
  //const fSetPage = (event, pg) => { setStDPage(pg); }
  //const [fDeleteGroupApi, {isLoading: isDeleting}] = useGroupDeleteMutation();

  //const orgId = useSelector(selectUserOrgId);
  let admGroupsData, delGroupModal, delGrpModalArgs;//, fetchGrpErr;
  //let fetchGrpErr, flgLoadingGrp, flgFetchingGrp, orgGroupsData;
  //admGroupsData = testOrgGroups;//useSelector(getOrgGroupsData, () => orgGroupOK);

  const {
    data: admGrpApiData, refetch: fRefetchGroupList,
    isLoading: admGrpListWait, isFetching: admGrpListWait2,
    isError: admGroupListErr, isSuccess: admGroupSuccessApi,
  } = useGroupListQuery(
    { page: stDPage, pageSize: gPageSize },
    //{ skip: false,}// refetchOnMountOrArgChange: true }//stOrgGroupOK
  );
  //admGroupSuccessApi && admGrpApiData && console.log('admGrpApiData', admGrpApiData);
  if(admGroupSuccessApi) {
    admGroupsData = admGrpApiData?.groups.slice();
    fMoveElementToFirst(admGroupsData, SA_DEF_ORG_GROUP_ID, 'id');
  }
  //console.log('admGroupsData', admGroupsData);

  /*const [stShowFrmCreateGroup, setStShowFrmCreateGroup] = useState({isOpen: false,});
  const loadMore = () => {
    if(isFetchingData && updated === 'filterEnded') {
      if(page + 1 <= paginationData?.page?.total && !dDataFetching) {
        //setStGroupArgs({ ...stGroupArgs, page: page + 1 });
        setPage(page + 1);
        setIsFetchingData(false);
      }
    }
  };*/
  //----------------------

  //const fEditGroupHandler = (gId) => {};
  //const fCloseDelGroupDialog = (e, reason) => {
  const fCloseDelGroupDialog = (flgRefetch=false) => {
    setStDelGroupData(null); //setStAjaxStep(0);
    flgRefetch && fRefetchGroupList();
  };
  const fInitDelGroupHandler = useCallback((gId) => {
    //console.log('fInitDelGroupHandler '+gId);
    //const theGroup = admGroupsData[gId];
    //const theGroup = admGroupsData.filter(grp => grp.id === gId);
    const theGroup = admGroupsData.find(grp => grp.id === gId);
    //console.log('admGroupsData', admGroupsData);
    console.log('fInitDelGroupHandler '+gId, theGroup);
    setStDelGroupData(theGroup);
  }, [admGroupsData, setStDelGroupData]);

  //let fDelGroupHandler;
  if(stDelGroupData) {
    let grpTitle = stDelGroupData?.name;
    delGrpModalArgs = {
      isOpen: stDelGroupData ? true : false,
      //title: 'Delete Group',
      groupData: {id: stDelGroupData.id, title: grpTitle},
      onDismiss: fCloseDelGroupDialog,
    };
    if(stDelGroupData.id === SA_DEF_ORG_GROUP_ID) {
      delGrpModalArgs['descText'] = 'Default group "' + grpTitle +
        '" can not be deleted';
      delGrpModalArgs['onConfirm'] = null;
      setTimeout(() => { setStDelGroupData(null); }, 4000);
    } else {
      delGrpModalArgs['descText'] = 'Are you sure want to delete group "' +
        grpTitle + '"?';
      //delGrpModalArgs['onConfirm'] = fDelGroupHandler;
      //delGrpModalArgs['isActing'] = stAjaxStep;
    }
    //delGroupModal = //fGetDelGroupModal(delGrpModalArgs);
    delGroupModal = <AdmGroupDelDialog modalArgs={delGrpModalArgs} />;
  }
  //---------------------------------------------------------

  let lstGroupCards = [];
  const flgUseGrid = 10;
  admGroupsData && admGroupsData.forEach((gData) => {
    lstGroupCards.push(
      <AdmGroupInfoCard
        key={'grp_' + gData.id}
        groupData={gData}
        groupMethods = {{
          //fEditGroup: fEditGroupHandler,
          fDeleteGroup: fInitDelGroupHandler
        }}
        flgUseGrid={flgUseGrid}
      />
    );
  });
  //---------------------------------------------------------

  return (
    <>
      {admGroupListErr ? (
        <Flasher delay={3000} resetFunction={() => { return;/*setStPgMsg('');*/ }}>
          Error fetching Groups! Please try again later..
        </Flasher>
      ) : (admGrpListWait || admGrpListWait2) ? (
        <SpinnerBox />
      ) : true ? (//admGroupSuccessApi ?
        <>
          {flgUseGrid ? (
            <Box sx={{flexGrow: 1, /*alignSelf: { xs: 'flex-start', sm: 'center' },*/}}>
              <Grid container spacing={3} //spacing={{xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 12 }} //flexDirection='row'
                //sx={{paddingRight: '1rem'}}
              >
                {lstGroupCards}
              </Grid>
            </Box>
          ) : (
            <Box sx={ctnGroupsSx}>{lstGroupCards}</Box>
          )}
          {/*<PaginationList pageArgs={{ onPageChange: fSetPage, currPage: stDPage,
            pageCount: admGrpApiData.meta.pageCount}} />*/}
          {delGroupModal}
        </>
      ) : null}
    </>
  )

  /*return (admGrpListWait || admGrpListWait2) ? (
    <SpinnerBox />
  ) : (
    admGroupListErr ? (
      <Flasher delay={3000} resetFunction={() => { return;/*setStPgMsg('');* / }}>
        Error fetching Groups! Please try again later..
      </Flasher>
    ) : ( true ? (//admGroupSuccessApi ?
        <>
          {flgUseGrid ? (
            <Box sx={{flexGrow: 1, /*alignSelf: { xs: 'flex-start', sm: 'center' },* /}}>
              <Grid container spacing={3} //spacing={{xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 12 }} //flexDirection='row'
                //sx={{paddingRight: '1rem'}}
              >
                {lstGroupCards}
              </Grid>
            </Box>
          ) : (
            <Box sx={ctnGroupsSx}>{lstGroupCards}</Box>
          )}
          <PaginationList pageArgs={{ onPageChange: fSetPage, currPage: stDPage,
            pageCount: admGrpApiData.meta.pageCount}} />
          {delGroupModal}
        </>
      ) : (null)//Err2
    )
  );*/
}