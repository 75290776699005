import { createContext, useMemo } from "react";
import { useNavigate } from "react-router";
import PropTypes from "prop-types";
import { FLG_DISABLE_AUTH_PAGES, NIU_HEALTH_BASE_URL } from 'config/app.config';
import { fClearLocalStorageSSq } from 'lib/appFx';
import { useNSSqStorage } from 'hooks';
//---------------------------------------------------------

const initialValues = {};
export const AuthContext = createContext(initialValues);

export default function AuthProvider({ children }) {
  const navigate = useNavigate();
  //const [user, setUser] = useNSSqStorage("user", { user: "SS" });
  const [user, setUser] = useNSSqStorage("user", null);

  function login(data) {
    setUser(data);
  }

  function logout() {
		fClearLocalStorageSSq();
		setUser(null);

		if(FLG_DISABLE_AUTH_PAGES) {
			//window.location.href = NIU_HEALTH_BASE_URL + '/login';
			navigate(NIU_HEALTH_BASE_URL + '/login', { replace: true });
		} else {
			navigate('/signin', { replace: true });
		}
  }

  //eslint-disable-next-line
  const value = useMemo(() => ({ user, login, logout }), [user]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

AuthContext.PropTypes = {
  children: PropTypes.node.isRequired,
};
