import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	Grid, Box, Button,// Stack, Link,
	Dialog, DialogActions, DialogContent,
	DialogContentText, DialogTitle,
} from "@mui/material";
import { styled } from "@mui/system";
import { MAX_SAUTH_WIN_OPEN_TIMEMS } from 'config/app.config';
import { socialMedia } from 'config/ui.config';
import { Typography } from 'core';
import Spinner2 from 'core/Spinner/Spinner2';
import {
	decodeSNectConnResp, fInitSMAuth,
	fFetchSMediaAccProps, //fFetchSMediaAccData,
} from 'lib/appFx';
import { fDelCookie, fGetCookie, parseQStr } from 'lib/netFx';
import { selectAuthUserCredentials } from 'services/auth/authSlice';
import {//getSMIdKeyMap, setSMediaConnStat,
	getSMediaMgr, resetSMediaTokens,
	useGetSMediaAccountListQuery,
	useSMediaLogoutMutation,
	useSMediaUnsubscribeMutation,
	useLazyGetSMediaAccountListQuery,
} from 'services/sMediaApi';
import { useTranslation } from "react-i18next";
//---------------------------------------------------------

/*const StyledGrid = styled(Grid)(({ theme }) => ({//btnSMConnSx
	display: 'flex', flexDirection: 'row',
	//flexDirection: {sm: 'column', md: 'row'},
	justifyContent: 'space-between', alignItems: 'baseline',
	//width: {xs: '98%', sm: '45%'},
	[theme.breakpoints.down('sm')]: {
		flexDirection: 'row',//'column',
		flexWrap: 'wrap', gap: '1rem',
		justifyContent: 'center', alignItems: 'center',
	},
}));
const StyledLink = styled(Link)(({ theme, active }) => ({
	color: active ? theme.palette.secondary.dark : theme.palette.error.main,
	fontWeight: '800', cursor: 'pointer',
	textTransform: 'uppercase ', textDecoration: 'underline',
}));*/
const PgHeadSx = {
	display: 'flex', fontWeight: 900,
	fontSize: 'medium', textTransform: 'capitalize ',
	justifyContent: 'center', alignItems: 'center',
};
const SocialIconContainer = styled('div')(({ theme, active, arrow }) => ({
	display: 'flex',//'grid',
	alignItems: 'center', justifyContent: 'center',
	height: '100%', //width: '100%',
	// gridTemplateColumns: 'auto auto !important',
	// rowGap: '1rem ',
	backgroundColor: 'rgba(0,0,0,0)',//'Background'
	textAlign: 'center', cursor: arrow,
	boxShadow: theme.shadows[5],
	borderRadius: '4px',
	//border: `2px solid  ${
	//  active ? theme.palette.primary.main : theme.palette.error.main
	//} `,
}));
//---------------------------------------------------------

export default function EditNetwork({ theme }) {
	const dispatch = useDispatch();
	let theSMData, theSMList; //= sMediaData;sMIdKeyMap
	//const fIniSMAuth = fInitSMAuth;
	theSMData = useSelector(getSMediaMgr);
	const { authToken, isAdmin } = useSelector(selectAuthUserCredentials);
	//console.log('authTokenEN', authToken);

	const [disconnectSocialTitle, setdisconnectSocialTitle] = useState(null);
	const [stOpenDisconnDlg, stSetOpenDisconnDlg] = useState(false);
	const [modalData, setModalData] = useState();
	const { t: home } = useTranslation('home')
	const [fSNetAcc] = useLazyGetSMediaAccountListQuery();
	//preferCacheValue=false,{refetch: refetchSMAcc}
	const [fSMediaLogout, {
		data, isLoading: isDisconnecting, status: sMediaLogoutStat,
		isSuccess: sMediaLogoutSuccess }
	] = useSMediaLogoutMutation();
	const [fSMediaUnsubscribe] = useSMediaUnsubscribeMutation();

	const ctnPgHeadSx = {
		backgroundColor: theme.palette.background.section,
		padding: '10px 1rem',
	}
	//----------------------

	const {
		data: dDataAL, error: dErrAL, isLoading: dWaitAL,
		isFetching: dFetchAL, isSuccess: dOKAL,
		refetch: refetchSMAccList,
	} = useGetSMediaAccountListQuery();

	//----- BelowFxs(fetchUserSMAcc&&fAuthSM)AreAlmostIdentical:
	//SocialNetworks.jsx, EditNetwork.jsx & SocialNetworkCard2.js
	//KeepItSame. IdealCandidateForACustomeHook
	const fetchUserSMAcc = useCallback(
		async (sKey = null, sMId = null, refFx = null, refArgs = null) => {
			//RefreshUserSMAccTokens
			//dOKAL && //
			let { data: usrSMAcc } = await refetchSMAccList();
			//let usrSMAcc = await fFetchSMediaAccData(authToken);
			//dispatch(resetSMediaTokens(usrSMAcc));
			usrSMAcc?.tokens && console.log('usrSMAcc_' + sKey + ' ' + sMId, usrSMAcc);

			if (sKey && usrSMAcc?.tokens) {// &&sMId
				let isAnySubs = false;
				const selSMKeyToken = usrSMAcc?.tokens.find((item) => {
					return item.snetworkplatform_id === sMId;
				});
				//const selSMKeyToken = usrSMAcc?.tokens?.some((item) => {
				//return item.snetworkplatform_id === data.sMId;
				//});

				if (selSMKeyToken?.properties) {
					for (const pg of selSMKeyToken?.properties) {
						//console.log('isAdmin', isAdmin, 'pg', pg);
						//if(!pg.is_subscribed) {
						//Disable LnCallForNonAdmin
						/*if(!pg.is_subscribed &&
							((isAdmin && pg.type === 'page') || (!isAdmin && pg.type === 'account' && sKey !== 'ln'))
						) {*/
						if (!pg.is_subscribed &&
							((isAdmin && (sKey !== 'fb' || (sKey === 'fb' && pg.type === 'page'))) ||
								(!isAdmin && pg.type === 'account' && sKey !== 'ln')
							)
						) {
							const subsPg = await fFetchSMediaAccProps(authToken, pg.id);
							isAnySubs = subsPg || isAnySubs;
							//console.log('isSubscribed ', subsPg);
						}
					}

					//if(!flgInitSMAccList) {
					let { data: usrSMAcc } = await refetchSMAccList();
					/*} else {
						console.log('CCC', flgInitSMAccList);
						aList = await fLazyAccList().unwrap();
					}*/

					//if(isAnySubs) {//TheSMConnIsSuccessAnyways..
					//setTimeout(()=>{
					dispatch(resetSMediaTokens(usrSMAcc));//console.log('D');
					if (typeof refFx === 'function') {
						//setTimeout(()=>{
						refFx(sKey, refArgs[0], refArgs[1]);
						//}, 2000);
					}
					//dispatch(resetSMediaTokens(usrSMAcc));//console.log('D');
					//}, 500);
					//}
				}
			} else if (sKey) {
				console.log('Connected but no tokens issued');
			} else {//console.log('resetCalledEN');
				dispatch(resetSMediaTokens(usrSMAcc));
			}
		},
		//[fSNetAcc, dispatch]);//resetSMediaTokens <- Don't
		[dispatch, resetSMediaTokens, refetchSMAccList]
	);//resetSMediaTokens

	const fAuthSM = useCallback((e, sKey, refFx = null, refArgs = null) => {
		//ForHeaven'sSake, DON'T put any state logic here.
		//TookMe3DaysToClearTheMess!!
		//This function is ONLY for initiating SMConn, wait for
		//response cookie & delegate state logics to fetchUserSMAcc. Period.
		e.preventDefault();
		let maxWaitTime = MAX_SAUTH_WIN_OPEN_TIMEMS;
		if (isAdmin) { maxWaitTime *= 2; }
		//const sKey = e.target.getAttribute('data-sa-smkey');
		let sChk, theCk, cName = 'sMAT_Ukn', sMTokenForId;
		sMTokenForId = theSMData[sKey]?.sMId;
		sKey && fInitSMAuth(theSMData[sKey].authUrl, sKey, isAdmin);

		setTimeout(() => {
			sChk = setInterval(async () => {
				theCk = fGetCookie(cName);
				if (theCk) {//console.log('theSweetCookie_'+sKey, theCk);
					theCk = parseQStr(theCk); //if(theCk.startsWith('module=oauth&')) {
					if (theCk?.module === 'oauth' && theCk?.payload) {
						theCk.payload = decodeSNectConnResp(theCk.payload);
						//console.log('theSweetCookie2_'+sKey, theCk);
						//console.log('theSMDataSS '+sMTokenForId+' '+
						//	theCk.payload?.snetworkplatform_id, theSMData);

						//if(theCk.payload?user_id === ) {snetworktoken_id
						if (sMTokenForId &&
							theCk.payload?.snetworkplatform_id === sMTokenForId
						) {
							fDelCookie(cName);
							//setTimeout(() => {
							clearInterval(sChk);
							//}, 2000);
							await fetchUserSMAcc(sKey, theSMData[sKey]['sMId'], refFx, refArgs);
						}// else {console.log('Noooo');}
					}
					//fDelCookie(cName);
					//clearInterval(sChk);
				}
			}, 2000);
		}, 5000);

		setTimeout(() => sChk && clearInterval(sChk),
			maxWaitTime + 1000);
	}, [theSMData, fInitSMAuth, fetchUserSMAcc]);

	const handleDisconnect = useCallback((data) => {
		stSetOpenDisconnDlg(true);
		setModalData(data);
	}, [modalData, stOpenDisconnDlg]);

	const handleClose = async (status = false) => {
		if (status && modalData?.sMTokens?.id) {
			try {
				const dataMain = await fSMediaLogout(modalData?.sMTokens?.id);

				if (dataMain?.data?.status) {
					//Http400: error: {message: "The selected snetworktoken id is invalid.",
					//  type: "FieldValidationException", code: 1051}
					//sMediaLogoutStat, isSuccess: sMediaLogoutSuccess
					if (isAdmin && modalData?.sMKey === 'fb') {
						modalData?.sMTokens?.properties.map(async (sNetProp) => {
							if (sNetProp?.is_subscribed) {
								const resUnsubs = await fSMediaUnsubscribe({
									'pagePropertyId': sNetProp?.id
								});
							}
						});
					}
					// const { data: dData } = await refetchA();
					// dispatch(initSMediaMgr(dData));
					await fetchUserSMAcc();
					stSetOpenDisconnDlg(false);
					//localStorage.removeItem("Insta-UserName");
				} else { await fetchUserSMAcc(); }
			} catch (err) {
				//{message: "The selected snetworktoken id is invalid.", type: "FieldValidationException", code: 1051}
				console.error(err, 'Err SocialNetwork Dis112');
			}
		} else { stSetOpenDisconnDlg(false); }
		setTimeout(() => { if (stOpenDisconnDlg) stSetOpenDisconnDlg(false); }, 5000);
	};

	if (theSMData) {
		theSMList = [];
		for (let sK in theSMData) {
			let dR = theSMData[sK];
			//const icon = `${dR.dispTitle}New`;
			const icon = `${sK}New`;
			let allowConn = dR.hasOwnProperty('authUrl') && !['igb', 'yt'].includes(sK);
			/*if(allowConn && ['ig', 'igb'].includes(sK)) {
				if(isAdmin) { allowConn = sK === 'igb' }
				else { allowConn = sK === 'ig' }
			}*/

			if (allowConn) {
				let lnkStat = dR?.connStat === 'Connected' ? true : false;
				//let status = lnkStat ? 'Disconnect' : 'Connect';
				//let disConnected = lnkStat ? false : true;
				let arrow = lnkStat && 'pointer';
				let sMUserName = dR?.sMTokens?.name?.username;

				//let expired = lnkStat ? 'Expired in 21 days' : '';
				theSMList.push(
					<Grid item key={dR.sMId + '_' + dR.sMKey}
						//display={'flex'}//display='grid !important'
						//flexWrap='wrap', flexShrink: '1', padding={2}
						//gridTemplateColumns: '1fr 1fr !important',
						//rowGap: '1rem', columnGap: '1rem',
						//maxWidth: {sm: '66.67', md: '75%', lg: '58.33%',},
						//xs={12} sm={12} md={6} lg={6} flexDirection='row'
						xs={6} sx={{ height: '100%', }}
					>
						<SocialIconContainer // active={lnkStat}
							arrow={arrow}
							onClick={(e) =>
								dR.authUrl && !lnkStat
									? fAuthSM(e, dR.sMKey)
									: handleDisconnect(dR)
							}
							sa-au={dR?.authUrl?.substring(0, 10)}
						>
							{socialMedia.NewIcons(icon, lnkStat, sMUserName)}
							{/* {socialMedia['twitter']} */}
						</SocialIconContainer>
						{/*<StyledLink active={lnkStat}
              {status}
            </StyledLink>
            <Typography color_variant="error" fontWeight={800}>
              {expired}
            </Typography> */}
						{/* </Stack> */}
					</Grid>
				);
			}
		}
	}
	//---------------------------------------------------------

	return (
		<>
			<Box //className='xdInFNoW'
				sx={{ margin: '0 auto', width: { sm: '100%', md: '57%' }, }}
			>
				<Box sx={ctnPgHeadSx}>
					<Typography color_variant="dark" sx={PgHeadSx}>
						{home('connect_your_social_networks')}
					</Typography>
				</Box>
				<Grid container id='ctnSMConnSett' //className='tBdr2'
					rowSpacing={2} columnSpacing={1.5} //spacing={6}
					columns={{ xs: 4, sm: 12, md: 12 }}
					justifyContent='center' //justify="space-around"
					alignItems={'top'} //disableEqualOverflow
					sx={{
						marginTop: '2.5rem',
						flexDirection: { xs: "column", sm: 'row', md: "row" }
					}}//direction={{xs: "column", md: "row"}}
				>
					{theSMList ?
						(theSMList) :
						(
							<Box justifyContent="center" alignItems="center">
								<Spinner2 />
							</Box>
						)
					}
				</Grid>
			</Box>
			<Dialog fullWidth maxWidth="sm"
				open={stOpenDisconnDlg} onClose={() => { stSetOpenDisconnDlg(false); }}
				disableScrollLock
			>
				<DialogTitle id="alert-dialog-title">
					<Typography variant="p" sx={{ fontWeight: 1200, fontSize: "22px", }}>
						{home('disconnect')} {disconnectSocialTitle}
					</Typography>
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						{home('are_you_sure_want_to_disconnect')} {disconnectSocialTitle}{home('account')} ?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => { handleClose(true); }}
						disabled={isDisconnecting ? true : false}
					>
						{home('disconnect')}
					</Button>
					<Button onClick={() => { stSetOpenDisconnDlg(false); }} // autoFocus
					>
						{home('cancel')}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}
