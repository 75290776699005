//import { useEffect } from 'react';
import { AuthProvider } from 'context';
import ThemeProvider from 'theme';
import { NavigationScroll } from 'layout';
import AppRouterN from 'routes/AppRouterN'; //AppRouter
import { FLG_SA_SITE_OFFLINE, SA_APP_NAME } from 'config/app.config';
//---------------------------------------------------------

const AppOffLine = () => {
  return (
    <div className='txCMT'>
      <h4>Thanks for visiting {SA_APP_NAME}</h4>
      <div>We are temporarily inaccessible due to planned
        system maintenance & will be back soon.</div>
      <h5> We appreciate your patience </h5>
    </div>
  );
};

function App() {
  //[appBootStrapted, setAppBootStrapted] = useState(false);
  //useEffect(() => { window.process = { ...window.process, }; }, []);

  return (FLG_SA_SITE_OFFLINE ? <AppOffLine /> : (
    <AuthProvider>
      <ThemeProvider>
        <NavigationScroll>
          <AppRouterN />
        </NavigationScroll>
      </ThemeProvider>
    </AuthProvider>
  ));
}

export default App;
