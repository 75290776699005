import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box, Grid, Icon, Stack, styled, Paper,// Select,
  ListItemIcon, ListItemText, MenuItem, Avatar, IconButton, Button,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { grey } from "@mui/material/colors";
import GroupsIcon from "@mui/icons-material/Groups";
import CachedOutlinedIcon from "@mui/icons-material/CachedOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
//import AutorenewIcon from "@material-ui/icons/Autorenew";
//import FilterAltIcon from "@mui/icons-material/FilterAlt";
//import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
//import dayjs from "dayjs";
//import moment from "moment";
import { Typography, SpinnerBox, PageMessage } from 'core';
import CalendarIcon from 'core/Icon/CalendarIcon';
import SelectDateRange from './SelectDateRange';
import { SA_DEF_ORG_GROUP_ID } from 'config/app.config';
//import { socialMedia } from 'config/ui.config';
import { fGetRefDates } from 'lib/appFx';
import {
  selectUserOrgId, checkIsAdmin, selectAuthUserId,
} from 'services/auth/authSlice';
import {
  useOrgGroupsQuery, useOrgLeaderboardQuery,
  useSelfLeaderboardQuery, //selectOrgId,
} from 'services/orgApi';
import {
  getFeedListMetaData, getOrgGroupsData, getSMFilterData,
  setSelectedGroupFilter, //initFeedListModifiers, setSelectedSMFilter,
} from 'services/orgFeedApi';
import { getSMediaMgr } from 'services/sMediaApi';
import { useTranslation } from "react-i18next";
//---------------------------------------------------------

const StyledAnalyticsHeader = styled(Stack)(({ theme }) => ({
  padding: '0.5rem', backgroundColor: '#dce4e7',
  borderBottom: `2px solid ${grey[400]}`,
}));
const StyledDateBox = styled(Stack)(({ theme }) => ({
  padding: '0.5rem', flexDirection: 'row', gap: 6,
  alignItems: 'center', justifyContent: 'center',
  backgroundColor: '#E5F6D4',
  [theme.breakpoints.down("sm")]: { padding: '0.5rem 0', gap: 1, },
}));
const StyledEventNoteIcon = styled(CalendarMonthOutlinedIcon)(({ theme }) => ({
  color: grey[600], fontSize: '2rem',
  [theme.breakpoints.down("sm")]: { fontSize: '1.5rem', },
}));
const StyledCachedIcon = styled(CachedOutlinedIcon)(({ theme }) => ({
  color: theme.palette.primary.light,//theme.palette.primary.lighter,
  cursor: 'pointer', fontSize: '2rem',
  [theme.breakpoints.down("sm")]: { fontSize: '1.5rem', },
}));
const StyledTypography = styled(Typography)(({ theme }) => ({
  fontSize: '0.94rem', fontWeight: '600', color: '#00406B',
  [theme.breakpoints.down("sm")]: { fontSize: '0.63rem', },
}));
const StyledGroupsIcon = styled(GroupsIcon)(({ theme }) => ({
  color: grey[500],
  [theme.breakpoints.down("sm")]: { fontSize: '1rem', },
}));
const StyledTab = styled(Stack)(({ theme, highlight }) => ({
  flexDirection: 'row', color: grey[500],
  justifyContent: 'left', alignItems: 'center',
  backgroundColor: highlight
    ? theme.palette.background.default
    : theme.palette.background.paper,
  padding: '8px 10px', gap: 2,
  borderRadius: '2px', border: '1px solid #e6e7e9',
}));
const StyledSelect = styled(Select)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  alignItems: 'center', // padding: '0.1rem 0',
  width: '100%', outline: 'none', // border: 'none',
  color: '#00406B', fontWeight: '600',
  border: '1px solid  #e6e7e9',
  "&:hover": {
    backgroundColor: theme.palette.background.paper,
  },
  [theme.breakpoints.down("sm")]: {
    minWidth: "4rem",
    "& .MuiSelect-select": { padding: '7.5px 4px ', },
    "& fieldset": { display: 'none !important', },
  },
}));
const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  minWidth: '2.2rem',
  [theme.breakpoints.down("sm")]: { minWidth: '0.3rem', },
}));
const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  fontSize: '1.1rem',
  [theme.breakpoints.down("sm")]: { fontSize: '0.6rem', },
}));
//---------------------------------------------------------

export default function LeaderBoardContent({ title, theme }) {
  const dispatch = useDispatch();
  //const isAdmin = useSelector(checkIsAdmin);
  const userAuthId = useSelector(selectAuthUserId);
  const orgId = useSelector(selectUserOrgId);
  const feedListMeta = useSelector(getFeedListMetaData);
  const sADateRefs = fGetRefDates();
  const { t: home } = useTranslation('home')

  const [stStartDate, setStStartDate] = useState(sADateRefs.dateAMonthAgo);
  const [stEndDate, setStEndDate] = useState(sADateRefs.dateToday);
  const [stSelGroupId, setStSelGroupId] = useState(SA_DEF_ORG_GROUP_ID);
  const [orgGroupOK, setOrgGroupOK] = useState(false);
  const [stSelSMKey, setStSelSMKey] = useState('all');
  //const feedListModifiers = feedListMeta?.feedListModifiers;
  const filterSM = useSelector(getSMediaMgr, () => orgGroupOK);
  let isUserLeader, oGroupOptions = [], filterSMOpt = [];

  const tabsData = [
    { label: `${home("jump_to_you")}`, },
    {
      label: `${home("sort_by_network")}`,
      //icon: <ArrowDropDownIcon sx={{ color: '#000' }} />,
      highlight: `${home("true")}`,
    },
    {
      label: `${home("sort_by_group")}`,
      //icon: <ArrowDropDownIcon sx={{ color: '#000' }} />,
    },
  ];
  let orgGroups;//oGSuccess,
  /*const { data: orgGroupsA, isSuccess: oGSuccessA } = useOrgGroupsQuery(
    { orgId: orgId },
    { skip: orgGroupOK }
  );
  //orgGroupsA && console.log('', orgGroupsA);
  oGSuccess = oGSuccessA;
  //orgGroups = oGSuccess && orgGroupsA ? orgGroupsA?.company : null;

  useEffect(() => {
    if(orgGroupsA) {
      //setOrgGroupOK(true);
      //dispatch(initFeedListModifiers(orgGroupsA));
      setStSelSMKey('All');
    }
  }, [orgGroupsA, setOrgGroupOK]);//dispatch,
  */
  orgGroups = useSelector(getOrgGroupsData);//, () => orgGroupOK);
  //console.log('orgGroupsLdr', orgGroups);
  //let filterSM = useSelector(getSMFilterData, () => orgGroupOK);

  const ldrQArgs = {
    orgId: orgId,
    group_id: stSelGroupId,
    startDate: stStartDate, endDate: stEndDate,
    /*filter_json: {//"source_snetworkplatform_id":"a1R3BYmO","destination_snetworkplatform_id":"a1R3BYmO"} */
  };
  if (stSelSMKey !== 'all') {
    ldrQArgs['filter_json'] = JSON.stringify({
      source_snetworkplatform_id: filterSM[stSelSMKey]['sMId'],
      destination_snetworkplatform_id: null
    });
  }
  const { data: ldrDataA, refetch: fRefetchLdrData, //isUninitialized,
    isLoading: ldrListWait, isFetching: ldrListWait2,
    isError: ldrListErr,
  } = useOrgLeaderboardQuery(ldrQArgs,
    { skip: !orgId, refetchOnMountOrArgChange: true }
  );
  //ldrDataA && console.log('ldrDataA', ldrDataA);
  if (ldrDataA) {
    isUserLeader = ldrDataA?.leaderboard.filter(ldr => ldr?.user?.id === userAuthId);
    isUserLeader = isUserLeader.length > 0;
  }//console.log('isUserLeader', isUserLeader);
  //---------------------------------------------------------

  const {
    data: selfLeadApiData, refetch: fRefetchSelfLead,
    isLoading: selfLeadWait, isFetching: selfLeadWait2,
    isError: selfLeadErr, isSuccess: selfLeadSuccessApi,
  } = useSelfLeaderboardQuery(
    {
      orgId: orgId,
      group_id: stSelGroupId,
      startDate: stStartDate, endDate: stEndDate,
      /*filter_json: {//"source_snetworkplatform_id":"a1R3BYmO","destination_snetworkplatform_id":"a1R3BYmO"} */
    }, { skip: !orgId, }//refetchOnMountOrArgChange: true }
  );
  //selfLeadApiData && console.log('selfLeadApiData', selfLeadApiData);
  //---------------------------------------------------------

  const fJumpToUser = () => {
    if (userAuthId) {
      const ldrSelf = document.getElementById('ldr_' + userAuthId);
      //const ldrSelf = document.getElementById('ldr_QlvWgOmw');
      if (ldrSelf) {
        ldrSelf.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };
  const fChangeOrgGroupHandler = (e) => {
    const { gval } = e.currentTarget.dataset;
    setStSelGroupId(gval);
    /*let Ids = [];
    Ids.push(gval);
    let selVal = [];
    selVal = Array.from(Ids).map((value) => value);
    dispatch(setSelectedGroupFilter({ selGroups: selVal }));*/
  };
  const fChangeSelSMFilterHandler = (e) => {//(event: SelectChangeEvent) => {
    const { sval } = e.currentTarget.dataset;
    setStSelSMKey(sval);
    // let Ids = [];
    // Ids.push(sval);
    // let selVal = [];
    // selVal = Array.from(Ids).map((value) => value);
    // dispatch(setSelectedSMFilter({ selSMs: sval }));
  };
  //---------------------------------------------------------

  orgGroups &&
    Object.keys(orgGroups).forEach((gId) => {
      oGroupOptions.push(
        //selected={orgGroups[gId].selected}
        <MenuItem key={'grp_' + gId} data-gval={gId}
          value={gId} //value={orgGroups[gId].name}
          onClick={fChangeOrgGroupHandler}
          sx={{ fontWeight: gId === stSelGroupId ? '800' : 'normal' }}
        >
          {orgGroups[gId].title}
        </MenuItem>
      );
    });

  filterSMOpt.push(
    <MenuItem key={'grp_' + 'all'} data-sval={'all'}
      value={'all'}
      onClick={(e) => fChangeSelSMFilterHandler(e)}
      sx={{ fontWeight: null === stSelSMKey ? '800' : 'normal' }}
    >
      {`${home("all")}`}
    </MenuItem>
  );
  filterSM && Object.values(filterSM) &&
    Object.values(filterSM).map((item) => {
      let title, isShow = true;
      switch (item.sMKey) {
        //case 'ig': isShow = !isAdmin; break;
        //case 'igb': isShow = isAdmin; break;
        case 'ig': case 'igb':
        case 'yt': case 'pl': case 'ts': case 'bc':
        case 'bl': case 'em': case 'wa':
          isShow = false; break;
        default: break;
      }
      if (isShow) {
        return filterSMOpt.push(
          <MenuItem key={'grp_' + item.sMKey} data-sval={item.sMKey}
            value={item.sMKey} //value={title ? title : item.title}
            onClick={(e) => fChangeSelSMFilterHandler(e)}
            sx={{ fontWeight: item.sMKey === stSelSMKey ? '800' : 'normal' }}
          >
            {item.title}
          </MenuItem>
        );
      }
      return null;
    });

  /*useMemo(() => {
    let debounceTimer;
    debounceTimer = setTimeout(() => {}, 1000);
    return () => { clearTimeout(debounceTimer); };
  }, [stSelSMKey]);
  useMemo(() => {
    let debounceTimer;
    debounceTimer = setTimeout(() => {
      //dispatch(setSelectedGroupFilter({selGroups: stSelGroupId}));
    }, 1000);
    return () => { clearTimeout(debounceTimer); };
  }, [stSelGroupId]);*/
  //---------------------------------------------------------

  let pgMsg, tblLeaderBoard;
  const tHSx = { padding: "1rem 0 1rem" };//, border:'1px solid green', };

  const tblLdrHead = (
    <TableHead>
      <TableRow key="ldrTHd">
        <TableCell align="center" sx={{ ...tHSx, width: '22%' }}>
          <Typography size="xsmall" fontWeight={600}
            style={{ fontSize: "1rem", letterSpacing: "0px" }}
          >
            {home("rank")}
          </Typography>
        </TableCell>
        <TableCell align="center" sx={{ ...tHSx, width: '55%' }}>&nbsp;</TableCell>
        <TableCell align="center" sx={tHSx}>
          <Typography size="xsmall"
            fontWeight={600} style={{ fontSize: "1rem" }}
          >
            {home("total_coin(s)")}
          </Typography>
        </TableCell>
        {/*<TableCell align="center" sx={tHSx}>
          <Typography size="xsmall"
            fontWeight={600} style={{ fontSize: "1rem" }}
          >
            Network Size
          </Typography>
        </TableCell>*/}
      </TableRow>
    </TableHead>
  );

  if (ldrListErr) {
    pgMsg = (
      <PageMessage size='M' isError={true}
        text={`${home('error_fetching_leaderboard')}`}
      />
    );
  } else if (ldrListWait || ldrListWait2) {
    pgMsg = (<SpinnerBox />);
  } else {//if(ldrDataA) {
    if (ldrDataA?.leaderboard.length) {
      const tblLeaderBodyTRs = (
        ldrDataA?.leaderboard
          .filter((data) => data !== null)?.map((row, index) => (
            <TableRow key={'ldr_' + row?.user?.id} id={'ldr_' + row?.user?.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell align="left"
                sx={{
                  justifyContent: "center",
                  display: "flex", borderBottom: "0",
                }}
              >
                <Avatar
                  sx={{
                    position: "relative", fontWeight: 800,
                    width: 60, height: 60,
                    color: theme.palette.secondary.dark,
                    backgroundColor: theme.palette.background.default,
                  }}
                >
                  {row?.rank <= 3 && (
                    <img alt=""
                      src={
                        row?.rank === 1
                          ? "/assets/images/golden.png"
                          : row?.rank === 2
                            ? "/assets/images/silver.png"
                            : row?.rank === 3
                              ? "/assets/images/bronze.png"
                              : "/assets/images/bronze.png"
                      }
                      style={{ width: 65, height: 65, }}
                    />
                  )}
                  <Typography size="large"
                    position="absolute"
                    fontWeight={600}
                  >
                    {index + 1}
                  </Typography>
                </Avatar>
              </TableCell>
              <TableCell align="left" sx={{ borderBottom: "0" }}>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Avatar
                    src={row?.user?.medias?.profile?.url}
                    alt={row?.user?.name}
                    sx={{ width: 60, height: 60, mr: '0.5rem' }}
                  />
                  <Stack direction="column">
                    <Typography fontWeight={600} size="medium">
                      {row?.user.name}
                    </Typography>
                    <Typography>{row.email}</Typography>
                    <Typography>{row.city}</Typography>
                  </Stack>
                </Stack>
              </TableCell>
              <TableCell align="center" sx={{ borderBottom: "0" }}>
                <Typography fontWeight={600} size="medium">
                  {row.points}
                </Typography>
              </TableCell>
              {/* <TableCell align="left" sx={{ borderBottom: '0' }}>
                      <Typography fontWeight={600} size="medium">
                        {row.engagements}
                      </Typography>
              </TableCell> */}
            </TableRow>
          ))
      );

      tblLeaderBoard = (
        <>
          {tblLdrHead}
          <TableBody>
            {tblLeaderBodyTRs}
          </TableBody>
        </>
      );
    } else {
      pgMsg = (
        <PageMessage size='M' text={`${home('no_leaderboard_data_at_present')}`} />
      );
    }
  }

  if (pgMsg) {
    pgMsg = (
      <>
        {tblLdrHead}
        <TableBody className="xtBdr2">
          <TableRow key={'ldr_PgMsg'} className="xtBdr2" width='100%'
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            <TableCell>&nbsp;</TableCell>
            <TableCell align='center' //colSpan={3}
              sx={{
                justifyContent: "center",
                display: "flex", borderBottom: "0",
              }}
              className="tBdr2"
            >
              {pgMsg}
            </TableCell>
            <TableCell>&nbsp;</TableCell>
          </TableRow>
        </TableBody>
      </>
    );
  }
  //---------------------------------------------------------

  return (
    <>
      <Box>
        <StyledAnalyticsHeader>
          <Typography size="large" fontWeight={600}>
            {title}
          </Typography>
        </StyledAnalyticsHeader>
        <StyledDateBox>
          <CalendarIcon />
          {/*<StyledEventNoteIcon /><img src="/assets/images/calendar2.png" height="35" width="35" />*/}
          <SelectDateRange theme={theme}
            startDate={stStartDate} endDate={stEndDate}
            setStartDate={setStStartDate} setEndDate={setStEndDate}
          />
          <IconButton aria-label="Refresh" sx={{ padding: '0', margin: '0' }}
            onClick={() => { fRefetchLdrData(); }}
          >
            <StyledCachedIcon />
          </IconButton>
        </StyledDateBox>
        <Stack direction="row" alignItems="center"
          style={{ margin: '15px 0px 15px' }}
          className="leaderBoardContentStack2"
        >
          {/* {tabsData.map((data, key) => (
            <Grid item xs={5} key={key}>
              <StyledTab highlight={data.highlight}>
                <Typography fontWeight={600} sx={{ fontSize: '1rem' }}>
                  {data.label}
                </Typography>
                <Icon>{data.icon}</Icon>
              </StyledTab>
            </Grid>
          ))} */}
          <Grid width={"100%"}>
            <StyledTab>
              <Typography fontWeight={600}
                className="select-data lnkPt" padding="5px"
                {...(isUserLeader ?
                  {
                    onClick: fJumpToUser,
                    style: { cursor: 'pointer' }
                  } :
                  { style: { color: '#5a5c61', cursor: 'no-drop' } }
                )}
              >
                {/*<Button disableElevation size="xsmall">
                  {tabsData[0].label}
                </Button>*/} {tabsData[0].label}
              </Typography>
              {/* <Icon>{data.icon}</Icon> */}
            </StyledTab>
          </Grid>
          <StyledSelect size="small" fullWidth displayEmpty //multiple
            id={'ldrSelSMKey'} name={'ldrSelSMKey'}
            className="select-facebook"
            value={stSelSMKey} defaultValue="all"
            inputProps={{ MenuProps: { disableScrollLock: true } }}
            onChange={(e) => { setStSelSMKey(e.target.value); }}
            renderValue={(selSMKey) => (
              <Box //sx={{ fontSize: "14px", width: '80%' }}
              >
                <Stack direction="row">
                  <StyledTypography color_variant="lightGrey"
                    className="facebook_SelecT"
                  >
                    {selSMKey === 'all' ? `${home("all")}` : filterSM[selSMKey]['title']}
                  </StyledTypography >
                </Stack >
              </Box >
            )
            }
          >
            {filterSMOpt}
          </StyledSelect >
          <StyledSelect size="small" fullWidth displayEmpty
            id={'ldrSelGrp'} name={'ldrSelGrp'}
            className="select-facebook"
            value={stSelGroupId} defaultValue={stSelGroupId}
            inputProps={{ MenuProps: { disableScrollLock: true } }}
            onChange={(e) => setStSelGroupId(e.target.value)}
            renderValue={(selGId) => {
              return (
                <Box>
                  <Stack direction="row">
                    <StyledTypography color_variant="lightGrey"
                      className="facebook_SelecT"
                    >
                      {orgGroups[selGId]['title']}
                    </StyledTypography>
                  </Stack>
                </Box>
              )
            }}
          >
            {oGroupOptions}
          </StyledSelect>
        </Stack >
      </Box >
      <TableContainer component={Paper}
        sx={{ //height: {md: '597px'} width: 'max-content'
          padding: "0 10px", paddingBottom: "1rem", width: '100%',
        }}
      >
        <Table sx={{ minWidth: 50, tableLayout: 'auto' }} aria-label="Leaderboard"
        //{/*stickyHeader*/}
        >
          {tblLeaderBoard ? tblLeaderBoard : (pgMsg ? pgMsg : null)}
        </Table>
      </TableContainer>
    </>
  );
}
