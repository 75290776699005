import { createSlice, current } from "@reduxjs/toolkit";
import { sSApi } from "./sSApi";
import {
  API_BASE_URL, HOME_BASE_URL, SA_TEMP_DEF_ADMIN_EMAIL,
} from 'config/app.config';
import { COMM_ROUTES } from 'config/routes.config';
import { getInitSMediaAttr } from 'lib/appFx';
//---------------------------------------------------------

export const sMediaApi = sSApi.injectEndpoints({
  endpoints: (builder) => ({
    getSMediaList: builder.query({
      //keepUnusedDataFor: 86400,
      query: () => "/platform",
    }),

    getSMediaAuthUrls: builder.query({
      //keepUnusedDataFor: 86400,
      query: (params) => {
        let forAdmin = '0';
        if (params?.forAdmin) {
          forAdmin = parseInt(params?.forAdmin) ? '1' : '0';
        }
        let aUrl = "/platform/urls?for_admin=" + forAdmin;
        return { url: aUrl };
      },
    }),

    getSMediaAccountList: builder.query({
      query: () => "/platform/accounts",
    }),

    getSMediaData: builder.query({
      async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
        let isAdmin = _arg?.isAdmin ?? false;
        let forAdmin = isAdmin ? '1' : '0';

        const dInf = await fetchWithBQ("/platform");
        if (dInf.error) throw dInf.error;
        let aUrl = API_BASE_URL + "/platform/urls?for_admin=" + forAdmin;
        aUrl += "&post_oauth_redirect_uri=";
        aUrl += encodeURI(HOME_BASE_URL + COMM_ROUTES.SMConnectResp.url);

        const dInf2 = await fetchWithBQ(aUrl);
        if (dInf2.error) throw dInf2.error;

        return dInf.data?.platforms && dInf2.data?.urls
          ? { data: getInitSMediaAttr([dInf.data, dInf2.data], isAdmin) }
          : (dInf.data && dInf2.data
            ? { data: [dInf.data, dInf2.data] }
            : { error: [dInf.error, dInf2.error] }
          );
      },
    }),

    sMediaLogout: builder.mutation({
      query: (params) => {
        return {
          url: `/platform/account?snetworktoken_id=${params}`,
          method: "DELETE",
        };
      },
      transformResponse: (response, meta) => {
        //{"result": {"success": true}}
        if (meta.response.status === 204 || meta.response.status === 200) {
          var responseData = { data: { status: true, }, };
        }
        return responseData.data;
      },
    }),
    sMediaSubscribe: builder.mutation({
      query: (params) => {
        return {
          url: '/platform/property',
          method: "POST",
          body: {
            snetworkproperty_id: params.id
          }
        };
      },
      transformResponse: (response, meta) => {
        //{"result": {"success": true}}
        if (meta.response.status === 204 || meta.response.status === 200) {
          var responseData = { data: { status: true, }, };
        }
        return responseData.data;
      },
    }),
    sMediaUnsubscribe: builder.mutation({
      query: (params) => {//console.log('unsubParams', params);
        return {
          url: '/platform/property',
          method: "DELETE",
          body: {
            snetworkproperty_id: params?.pagePropertyId
          }
        };
      },
    }),
  }),
});
//---------------------------------------------------------

export const {
  useGetSMediaDataQuery,
  useLazyGetSMediaAccountListQuery,
  useGetSMediaAccountListQuery,
  useSMediaLogoutMutation,
  useSMediaSubscribeMutation,
  useSMediaUnsubscribeMutation
} = sMediaApi;
//---------------------------------------------------------

const sMediaSlice = createSlice({
  name: "sMedia",
  initialState: {
    theSMgr: null,
    sMIdKeyMap: null,
    details: null,
    // accounts: null,
  },
  reducers: {
    initSMediaMgr: (state, action) => {
      state.theSMgr = {};
      state.theSMgr["media"] = action.payload; //.?details
      //console.log('sMApiPL', action.payload);
      const res = {};
      Object.keys(action.payload).forEach((s) => {
        action.payload[s].sMId !== null && (res[action.payload[s].sMId] = s);
      });
      state.sMIdKeyMap = res;
    },
    emptySMediaMgr: (state) => {
      if (state.theSMgr["media"]) {
        state.theSMgr["media"] = "loggged-out";
      }
    },
    setSMediaConnStat: (state, action) => {
      const sMKey = action.payload?.sMKey;
      if (
        sMKey &&
        state.theSMgr?.media &&
        state.theSMgr.media.hasOwnProperty(action.payload.sMKey)
      ) {
        state.theSMgr.media[sMKey].connStat = action.payload?.connStat;
        state.theSMgr.media[sMKey]["activTokenId"] = action.payload.tokenId;
      }
    },

    resetSMediaTokens: (state, action) => {
      //console.log('resetSMediaTokens', action?.payload);
      const theSMKeys = Object.keys(state.theSMgr.media);
      for (let k in theSMKeys) {
        const theSM = state.theSMgr.media[theSMKeys[k]];
        theSM["sMTokens"] = [];
        theSM["activTokenId"] = null;
        theSM.connStat = "";
      }
      action.payload?.tokens &&
        action.payload.tokens.forEach((sMT) => {
          for (let k in theSMKeys) {
            const theSM = state.theSMgr.media[theSMKeys[k]];
            if (theSM?.sMId === sMT?.snetworkplatform_id) {
              theSM["sMTokens"] = sMT;
              theSM["activTokenId"] = sMT.id;//ShouldBeTheOneWithIsSubscricedIsTrue?
              theSM["activTokenId"] && (theSM.connStat = "Connected");
              break;
            }
          }
        });//getState()
      //console.log('theSMgrUp', current(state.theSMgr.media));
    },

    new_resetSMediaTokens: (state, action) => {
      console.log('resetSMTPrev', { ...current(state) });
      console.log('resetSMTPL', action?.payload);
      //const newSt = {...current(state)};
      const newSt = structuredClone({ ...current(state) });
      //const newSt = {...state, };
      console.log('resetSMTNewSt ' + (state === newSt), newSt);
      const theSMKeys = Object.keys(newSt.theSMgr.media);
      for (let k in theSMKeys) {
        const theSM = newSt.theSMgr.media[theSMKeys[k]];
        //const theSM = structuredClone(newSt.theSMgr.media[theSMKeys[k]]);
        theSM["sMTokens"] = [];
        theSM["activTokenId"] = null;
        theSM.connStat = "";
      }
      action.payload?.tokens &&
        action.payload.tokens.forEach((sMT) => {
          for (let k in theSMKeys) {
            const theSM = newSt.theSMgr.media[theSMKeys[k]];
            if (theSM?.sMId === sMT?.snetworkplatform_id) {
              theSM["sMTokens"] = sMT;
              theSM["activTokenId"] = sMT.id;//ShouldBeTheOneWithIsSubscricedIsTrue?
              theSM["activTokenId"] && (theSM.connStat = "Connected");
              break;
            }
          }
        });//getState()
      //console.log('theSMgrUp', current(state.theSMgr.media));
      console.log('theSMgrUp', newSt.theSMgr.media);
      return newSt;
    },

    // setAccount: (state, action) => {
    //   state.accounts = action.payload.usrSMAcc;
    //
    // },
  },
});

export default sMediaSlice.reducer;
export const {
  initSMediaMgr,
  resetSMediaTokens,
  setSMediaConnStat,
  emptySMediaMgr,
  // setAccount,
} = sMediaSlice.actions;

//export const getSMediaMgr = (state) => state?.sMedia?.theSMgr?.media; // || {};
export const getSMediaMgr = (state) => {
  console.log('sMediaApi', state?.sMedia, state?.sMedia?.theSMgr?.media);
  return state?.sMedia?.theSMgr?.media; // || {};
};
// export const getSMediaAccounts = (state) => state?.sMedia?.accounts; // || {};
export const getSMIdKeyMap = (state) => state.sMedia.sMIdKeyMap;
export const getSMediaKeyIdMap = (state) => {
  const res = {};
  state.sMedia?.theSMgr?.media &&
    Object.keys(state.sMedia.theSMgr.media).forEach((s) => {
      res[s] = state.sMedia.theSMgr.media[s].sMId;
    });
  return res;
};
export const getSMediaTitleKeyMap = (state) => {
  const res = {};
  state.sMedia?.theSMgr?.media &&
    Object.keys(state.sMedia.theSMgr.media).forEach((s) => {
      const dTtl = state.sMedia.theSMgr.media[s].dispTitle;
      if (dTtl && s !== 'igb') { res[dTtl] = s; }
    });
  return res;
};
export const getActiveTokenCount = (state) => {
  let res = 0; //{};
  state.sMedia?.theSMgr?.media &&
    Object.keys(state.sMedia.theSMgr.media).forEach((s) => {
      state.sMedia.theSMgr?.media[s]?.activTokenId && res++;
    });
  return res;
};
//---------------------------------------------------------

