import { Box, Stack } from "@mui/material";
import { Card, Typography } from "core";
import React from "react";

export default function FollowOurClients({ theme }) {
  return (
    <Box sx={{ position: "relative" }}>
      <Card>
        <Box
          sx={{
            backgroundColor: theme.palette.secondary.dark,
            zIndex: 10,
            top: -10,
            position: "absolute",
            padding: "5px 3rem",
            borderRadius: "5px",
          }}
        >
          <Typography sx={{ color: "white", fontWeight: 900 }}>
            Follow Our Clients
          </Typography>
        </Box>
        <Stack spacing={2} marginTop="2rem"></Stack>
      </Card>
    </Box>
  );
}
