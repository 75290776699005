import { useState, useEffect } from "react";
import {
  Box, TextField, Button,
  InputAdornment,// Autocomplete,
} from "@mui/material";
//import Autocomplete, { createFilterOptions} from '@mui/material/Autocomplete';
import SearchIcon from '@mui/icons-material/Search';
import {
  SpinnerBox, Spinner2, Flasher,//FormProvider,
  PageMessage, Typography,
} from 'core';
import { SA_DEF_ORG_GROUP_ID } from 'config/app.config';
//import { fGetNumAbbr, fFormatNumberToLocaleString } from 'lib/appFx';
import { fLeadDebounce } from 'lib/appFx';
//import { useOrgUserListQuery, } from 'services/admin/admUsersApi';
import { useGroupMemberListQuery, useGroupAddMemberMutation, } from 'services/admin/admGroupsApi';
import AdmGroupAddMemberDataTable from './AdmGroupAddMemberDataTable';
import { useTranslation } from "react-i18next";
//---------------------------------------------------------

const txSearchAddMemSx = {
  m: 0, width: { xs: '100%', md: '22rem' },// height: '2.5rem',
};
//---------------------------------------------------------

export default function AdmGroupAddMemberForm({ theme, groupMethods, groupData = {}, }) {
  //console.log('AGDGrpAddMemData', groupData);
  const groupId = groupData.id;
  const pageSize = 10;
  const [stGAMCurrPage, setStGAMCurrPage] = useState(1);
  const [stAjaxStep, setStAjaxStep] = useState(0);//-1:Err, 0: Nop, 1:wait, 2: OK
  const [stPgMsg, setStPgMsg] = useState('');
  const [stSearchOrgMemTerm, setStSearchOrgMemTerm] = useState('');
  const [stSelMemIds, setStSelMemIds] = useState([]);
  const { t: home } = useTranslation('home')
  const [fAddGrpMemApi,
    { isLoading: isAddingMem }] = useGroupAddMemberMutation();
  //const [fGroupDetails] = useLazyGroupDetailsQuery({ groupId: null });
  //const [fGroupDetails] = useOrgUserListQuery();

  let admOrgMembers, admOrgMemGT = 0;
  //company/userApiHasNoDataSo..FetchingUSersFromDefaultGroup
  const orgMemQArgs = { groupId: SA_DEF_ORG_GROUP_ID, page: stGAMCurrPage };
  if (stSearchOrgMemTerm.length > 2) {
    orgMemQArgs['filter'] = {};
    //{"search":{"field":"email","value":"john@example.com"}}
    //filter={"search":{"field":"name","value":"John"}}
    orgMemQArgs['filter']['search'] = { field: 'name', value: stSearchOrgMemTerm };
  }/* else if(stSearchOrgMemTerm.length === 0) {
    delete orgMemQArgs['filter'];
  }*/
  const {
    data: admOrgMemApiData, refetch: fRefetchOrgMembers,
    isLoading: admOrgMemWait, isFetching: admOrgMemWait2,
    isError: admOrgMemErr, isSuccess: admOrgMemSuccessApi,
  } = useGroupMemberListQuery(orgMemQArgs,
    { refetchOnMountOrArgChange: true }
  );
  if (admOrgMemSuccessApi) {
    admOrgMembers = admOrgMemApiData?.group_members.slice();
    admOrgMembers = structuredClone(admOrgMembers);
    admOrgMemGT = admOrgMemApiData?.pagination?.records?.total || 0;
    if (admOrgMemApiData.hasOwnProperty('pagination')) {
      admOrgMemGT = admOrgMemApiData?.pagination?.records?.total || 0;
    } else {//if(admOrgMembers.length) {}
      admOrgMemGT = admOrgMembers?.length || 0;
    }
  }
  //---------------------------------------------------------

  const fRestoreAjax = () => {
    setStPgMsg(''); setStAjaxStep(0);
  };
  const fSetCurrPage = (pg) => {
    setStGAMCurrPage(pg);
  };

  const fSetSearchOrgMemTerm = (e) => {//fLeadDebounce
    const txEl = e.target;
    if (txEl) {
      const txVal = txEl.value;
      setStSearchOrgMemTerm(txVal);
      txEl.focus();
    }
  };

  const fSelectMember = (mId) => {
    if (mId && stSelMemIds.length < 30) {
      setStSelMemIds(prevSt => {
        prevSt.push(mId);
        return [...new Set(prevSt)];
      });
    }
  };
  const fDeselectMember = (mId) => {
    if (mId) {
      let selMIds = structuredClone(stSelMemIds);
      selMIds = selMIds.filter(itm => { return itm !== mId; });
      setStSelMemIds(selMIds);
    }
  };
  //---------------------------------------------------------

  //if(stSelMemIds.length) {
  //const fResetGroupForm = () => { frmReset(); };
  const fAddGroupMem = //useCallback(
    async () => {
      //const inData = stSelMemIds;// getValues();//e.preventDefault();
      //unique, if csv,Convert2Arr
      let userIds = stSelMemIds;

      if (userIds?.length) {//isArr
        setStAjaxStep(1);
        //setStPgMsg('Please wait. It may take a while..');
        const inParams = {
          groupId,
          user_ids: JSON.stringify(userIds),
        };

        try {
          let aResp;
          //aResp = await fAddGrpMemApi({ params: inParams }).unwrap();
          aResp = await fAddGrpMemApi(inParams).unwrap();
          //if(aResp?.result?.success) {
          if (aResp?.result?.success) {
            const txMsg = `${home("the_selected_members_are_successfully_added_to_group")}`;
            setStPgMsg(txMsg);
            setStSelMemIds([]);

            setTimeout(() => {
              groupMethods?.onDismiss();
              groupMethods?.fRefreshMemberList();
            }, 3500);
            //setTimeout(fRestoreAjax, 3000);
          } else if (aResp?.error) {
            setStPgMsg(aResp?.error?.message);
          } else {
            setStPgMsg(`${home("error_adding_members_to_group")}` + "\n" + `${home("please_try_again")}`);
            setStAjaxStep(-1);
          }
          //groupMethods?.onDismiss();
        } catch (err) {
          console.error(err, 'Error adding members to group');
          let eMsg;
          if (err?.status === 403) {
            eMsg = err?.data?.error?.message;
          }

          if (!eMsg) {
            eMsg = `${home("error_adding_members_to_group")}` + "\n" + `${home("please_try_again")}`;
          }
          setStPgMsg(eMsg);
          setStAjaxStep(-1);
        }
      } else {
        setStAjaxStep(-1);
        setStPgMsg('Please select some members');
      }
      setTimeout(fRestoreAjax, 2000);
    }
  //, [stSelMemIds]);
  //}

  useEffect(() => {
    const txEl = document.getElementById('txAddMemSearch');
    if (txEl?.value?.length) { txEl.focus(); }
  });
  //---------------------------------------------------------

  return (
    true ? (//ctnItmAC
      <Box id="ctnFrmAdmAddGrpMem" className="bgWh padL"
        sx={{ minHeight: '8rem', }} //component="form"
      //noValidate autoComplete="off" sx={{width: {xs:'100%', md:'55%'}}
      >
        <Box className='secHd'>
          <Typography className="secHd" id='hdAdmAddGrpMem'>
            Add Members</Typography>
        </Box>
        <Box className='dInFNoW wA'
          sx={{ margin: '0.8rem 0', display: { xs: 'block', sm: 'flex' } }}
        >
          <Box>
            <TextField id="txAddMemSearch" className="txIn"
              size="small" label="" hiddenLabel
              disabled={!admOrgMemGT && stSearchOrgMemTerm.length === 0}
              placeholder="Search by name"
              //variant="standard" //disableUnderline
              value={stSearchOrgMemTerm}//defaultValue=''
              sx={txSearchAddMemSx}
              InputProps={{
                startAdornment: <InputAdornment position="start">
                  <SearchIcon sx={{
                    color: '#cecece', flexShrink: 0,
                    fontSize: "1.25rem", marginLeft: 1
                  }} />
                </InputAdornment>,
              }}
              //onChange={fSetSearchOrgMemTerm} //onKeyUp={debounce}
              onChange={(e) => { fLeadDebounce(fSetSearchOrgMemTerm(e)) }}
            />
          </Box>
          {/*groupId !== SA_DEF_ORG_GROUP_ID &&
            <Box className='mLA' sx={{width: {xs: '100%', sm: 'auto'}}}>
              <Button className='cBtn cBtnDark' sx={{fontWeight: 700}}
                onClick={fAddGroupMem}>
                Add User</Button>
            </Box>
          */}
        </Box><br />
        <Box className='xbdr1' sx={{ margin: '0.8rem 0' }}>
          <AdmGroupAddMemberDataTable
            addMemTableData={{
              //groupData,
              selectedMemIds: stSelMemIds,
              memberData: admOrgMembers,
              memberGT: admOrgMemGT,
              pageSize: pageSize,
              currPage: stGAMCurrPage,
              filterTerm: stSearchOrgMemTerm
            }}
            addMemTableMethods={{
              fSelectMember: fSelectMember,
              fDeselectMember: fDeselectMember,
              fSetCurrPage: fSetCurrPage
            }}
          />
        </Box>
        <Box mt={'3rem'} sx={{}}>
          <Box sx={{ width: { xs: '100%', md: '55%' }, padding: '0.5rem', alignItems: 'center' }}>
            {stPgMsg && (
              <Flasher delay={3000} resetFunction={fRestoreAjax}>
                <PageMessage text={stPgMsg} isError={stAjaxStep === -1} />
              </Flasher>
            )}
          </Box>
          <Button variant="text" className="cBtn cBtnWh" sx={{ marginRight: '1rem' }}
            onClick={groupMethods.onDismiss}
          >Discard</Button>
          <Button variant="text" type="button" className="cBtn cBtnDark"
            disabled={stSelMemIds.length === 0 || stAjaxStep !== 0}
            sx={{ "&.Mui-disabled": { color: "#ffffff42" } }}
            onClick={fAddGroupMem}
          >
            {stAjaxStep === 1 ? <Spinner2 reverse={true} /> : `${home('save')}`}
          </Button>
        </Box><br />
      </Box>
    ) : <><SpinnerBox /></>
  );
};
