import { styled, useTheme, Box, Stack, } from "@mui/material";
import { Typography } from 'core';
import AdmCSuggestData from './AdmCSuggestData';
//---------------------------------------------------------

export default function AdmContentSuggestions({ title='Content Suggestions' }) {
	const theme = useTheme();
	//---------------------------------------------------------

  //---------------------------------------------------------

	return (
		<>
			{/*<Box className=""><h2 className='pgHd'>{title}</h2></Box>*/}
			<Box className="xpadL xtBdr2" sx={{
          marginTop: '2rem',
          display: 'flex', justifyContent:'center',
        }}
      >
        <Box className='xbdr1' sx={{ width: '80%', margin: '0 auto' }}>
          <AdmCSuggestData theme={theme} />
        </Box>
			</Box>
		</>
	);
};