import React, { useEffect, useRef } from 'react';

import {
  AppBar as MuiAppBar,
  Box,
  Divider,
  IconButton,
  InputAdornment,
  Stack,
  styled,
  TextField,
  useTheme,
} from '@mui/material';
import { Logo } from 'core';
import SearchIcon from '@mui/icons-material/Search';
import LeaderboardOutlinedIcon from '@mui/icons-material/LeaderboardOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import { Link, useLocation } from 'react-router-dom';
import { HOME_ROUTES } from 'config/routes.config';
import SettingsDropdownMenu from 'sections/settings/SettingsDropdownMenu';

const AppBar = styled(MuiAppBar)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 5,
  // padding: "0 15rem",
  backgroundColor: theme.palette.background.paper,
  // marginBottom: "1rem",
  [theme.breakpoints.down('md')]: {
    position: 'static',
  },
}));

const StyledStack = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-evenly',
  gap: '2rem',
}));

const Menubar = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-evenly',
  gap: '2rem',
  [theme.breakpoints.down('md')]: {
    gap: '1rem',
  },
  [theme.breakpoints.down('md')]: {
    gap: '0.8rem',
  },
}));

const SearchBar = styled(TextField)(({ theme }) => ({
  backgroundColor: theme.palette.background.section,
  width: '26vw',
  border: '0px',
  [theme.breakpoints.down('xl')]: {
    width: '42vw',
  },
  [theme.breakpoints.down('lg')]: {
    width: '35vw',
  },
  [theme.breakpoints.down('sm')]: {
    width: '65vw',
  },
}));

const HeaderContainer = styled(Stack)(({ theme }) => ({
  //maxWidth: '1280px',//1450px
  width: '100%',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  margin: 'auto',
  [theme.breakpoints.down('xl')]: {
    paddingLeft: '2rem',
    paddingRight: '2rem',
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

const IconStyle = {
  fontSize: '2rem',
};

export default function Header() {
  const theme = useTheme();
  const { pathname } = useLocation();

  function isActive(path) {
    return pathname !== undefined && path === pathname
      ? theme.palette.primary.main
      : theme.palette.secondary.dark;
  }

  const anchorRef = useRef(null);
  const [open, setOpen] = React.useState(false);

  const handleClose = (event) => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen((pre) => !pre);
  };
  const id = open ? 'simple-popper' : undefined;

  useEffect(() => {
    if(pathname) setOpen(false);
  }, [pathname]);

  return (
    <>
      {/* <AppBar elevation={2} position="sticky">
        <HeaderContainer>
          <StyledStack direction="row" alignItems="center">
            <Box paddingTop={1} component={Link} to={HOME_ROUTES.Home.url}>
              <Logo size="small" />
            </Box>
            <SearchBar
              name="search"
              size="small"
              placeholder="Search"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </StyledStack>
          <Menubar
            width="30vw"
            divider={<Divider orientation="vertical" flexItem />}
          >
            <IconButton>
              <PersonOutlineOutlinedIcon
                sx={{
                  color: (theme) => theme.palette.secondary.dark,
                  ...IconStyle,
                }}
              />
            </IconButton>
            <IconButton component={Link} to={HOME_ROUTES.Leaderboard.url}>
              <LeaderboardOutlinedIcon
                sx={{
                  color: isActive(HOME_ROUTES.Leaderboard.url),
                  ...IconStyle,
                }}
              />
            </IconButton>
            <IconButton component={Link} to={HOME_ROUTES.Submit.url}>
              <PaymentsOutlinedIcon
                sx={{
                  color: isActive(HOME_ROUTES.Submit.url),
                  ...IconStyle,
                }}
              />
            </IconButton>
            <IconButton
              aria-describedby={id}
              onClick={handleToggle}
              ref={anchorRef}
            >
              <SettingsOutlinedIcon
                sx={{
                  color: (theme) => theme.palette.secondary.dark,
                  ...IconStyle,
                }}
              />
            </IconButton>
            <IconButton>
              <NotificationsNoneOutlinedIcon
                sx={{
                  color: (theme) => theme.palette.secondary.dark,
                  ...IconStyle,
                }}
              />
            </IconButton>
          </Menubar>
        </HeaderContainer>
      </AppBar> */}
      {/* <SettingsDropdownMenu
        id={id}
        open={open}
        // anchorEl={anchorRef.current}
        handleClose={handleClose}
      /> */}
    </>
  );
}
