import { useState, useEffect, useCallback } from "react";
import {TransferList} from 'core';
//---------------------------------------------------------


//---------------------------------------------------------

export default function AdmManageFeedForm() {

  //---------------------------------------------------------

  return (//https://mui.com/material-ui/react-list/
    <TransferList listItemLeft={[0, 1, 2, 3]} listItemRight={[4, 5, 6, 7]}/>
  );
}
/**https://apia.niusocial.online/v1.0/platform/accounts
{
	"result": {
		"success": true,
		"data": {
			"tokens": [
				{
					"id": "RkPLa9Kl",
					"email": "ssqmsociodev@gmail.com",
					"name": {
						"last": "Socio",
						"name": "Devtest Socio",
						"first": "Devtest"
					},
					"display_picture": null,
					"snetworkplatform_id": "a1R3BYmO",
					"properties": [
						{
							"id": "qpVBoWj4",
							"name": {
								"last": "Socio",
								"name": "Devtest Socio",
								"first": "Devtest"
							},
							"type": "account",
							"url": null,
							"is_subscribed": false
						}
					],
					"updated_at": "2024-05-16T10:31:04"
				},
				{
					"id": "l7qpPw3n",
					"email": "demou345@gmail.com",
					"name": {
						"last": "VodafoneIdea",
						"name": "Jane At VodafoneIdea",
						"first": "Jane"
					},
					"display_picture": null,
					"snetworkplatform_id": "a1R3BYmO",
					"properties": [
						{
							"id": "qvBzoZ1O",
							"name": "NIU Health",
							"type": "page",
							"url": null,
							"is_subscribed": false
						},
						{
							"id": "yrgVoW1E",
							"name": {
								"last": "VodafoneIdea",
								"name": "Jane At VodafoneIdea",
								"first": "Jane"
							},
							"type": "account",
							"url": null,
							"is_subscribed": false
						}
					],
					"updated_at": "2024-05-16T10:28:08"
				},
				{
					"id": "n9BmoJgR",
					"email": null,
					"name": {
						"last": null,
						"first": "Crunch Hype"
					},
					"display_picture": null,
					"snetworkplatform_id": "NBmk0BRE",
					"properties": [
						{
							"id": "7ejEo4g3",
							"name": {
								"last": null,
								"first": "Crunch Hype"
							},
							"type": null,
							"url": "http:\/\/feeds.feedburner.com\/Techcrunch",
							"is_subscribed": false
						}
					],
					"updated_at": "2024-03-28T14:47:32"
				},
				{
					"id": "RkPL02Jl",
					"email": null,
					"name": {
						"last": null,
						"first": "NIU HEALTH"
					},
					"display_picture": null,
					"snetworkplatform_id": "NBmk0BRE",
					"properties": [
						{
							"id": "7ejEo4g4",
							"name": {
								"last": null,
								"first": "NIU HEALTH"
							},
							"type": null,
							"url": "https:\/\/www.youtube.com\/@niuhealth714",
							"is_subscribed": false
						}
					],
					"updated_at": "2023-01-27T15:43:09"
				},
				{
					"id": "nXYd7OPR",
					"email": null,
					"name": {
						"last": null,
						"first": null,
						"username": "niuhealthcare"
					},
					"display_picture": null,
					"snetworkplatform_id": "MzR9v14E",
					"properties": [
						{
							"id": "a5jOLRXl",
							"name": {
								"last": null,
								"first": null,
								"username": "niuhealthcare"
							},
							"type": "account",
							"url": null,
							"is_subscribed": true
						}
					],
					"updated_at": "2023-12-05T10:50:01"
				}
			]
		}
	}
} */