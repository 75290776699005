import { /*useMemo,*/ useEffect, useRef, useState } from 'react';
import { Box, styled, CardContent, //CardMedia,
} from '@mui/material';
import { AlbumPost, Typography } from 'core';
import { HOME_BASE_URL, SA_IMG_BASE_URL } from 'config/app.config';
//---------------------------------------------------------
//---------------------------------------------------------
export default function CardMediaWrapper({mediaContent, cardOption}) {
  //console.log('mediaContent', mediaContent);
  const flgForceFixHeight = cardOption?.forceFixHeight ?? true;
  const flgLazyLoad = cardOption?.lazyLoad ?? false;
  //const defPImgSrc = SA_IMG_BASE_URL + '/app/404_img.png';
  const defPImgSrc = SA_IMG_BASE_URL + '/app/404_img2.jpg';
  const fOnImageError = (e) => {
    const mEl = e.target;//console.log('mWrapperSrc', mEl.src);
    if(mEl?.src && mEl.src === defPImgSrc) {//mEl.src.endsWith(defPImgSrc)
      //mEl.src = null;
    } else { mEl.src = defPImgSrc; }
  };
  let mediaUrl, mJsx, mType = mediaContent?.type;

  switch(mType) {
    case 'album': mediaUrl = mediaContent.urls[0]; break;
    case 'img': case 'vdo': //default:
      mediaUrl = mediaContent.urls[0];break;
  }

  if(mType === 'img') {
    mJsx = (
      <img className='cardMediaImg' src={mediaUrl}
        onError={fOnImageError}
        //{flgLazyLoad && loading='lazy'}
        //{...(flgLazyLoad ? {loading: 'lazy'} : {})}
        {...(flgLazyLoad && {loading: 'lazy'})}
      />
    );
  } else if(mType === 'album') {
    mJsx = ( <AlbumPost imageList={mediaContent.urls} /> );
  } else if(mType === 'vdo') {
    mJsx = (
      <video style={{ width: '100%' }} controls>
        <source
          type="video/mp4" //mediaContent.mediaType
          src={require(mediaUrl)}
        />
      </video>
    );
  } else { mJsx = null; }
  //---------------------------------------------------------

  return (
    <Box
      mt={cardOption?.dispDir !== 'col' ? 0 : '1rem'}
      className={flgForceFixHeight ? 'ctnCardMedia custScroll' : ''}
    >
      {mJsx}
    </Box>
  );
};
