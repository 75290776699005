import { Box } from "@mui/material";
import { grey } from "@mui/material/colors";
import { Typography } from "core";
import React from "react";

function CardHighlight({ theme, title, icon }) {
  return (
    <>
      <Box
        sx={{
          backgroundColor: grey[500],
          top: "-38px",
          zIndex: 10,
          position: "absolute",
          right: 0,
          paddingY: "12px",
          width: "9rem",
          display: "flex",
          alignItems: "center",
          gap: theme.spacing(2),
          color: "#fff",
          justifyContent: "center",
          fontSize: "1rem",
          height: "37px",
        }}
      >
        {icon}
        <Typography
          sx={{
            fontWeight: 900,
            fontSize: "14px",
            letterSpacing: "1px",
            color: theme.palette.background.paper,
          }}
        >
          {title}
        </Typography>
      </Box>
      <Box
        sx={{
          backgroundColor: grey[500],
          top: "-38px",
          position: "absolute",
          right: "73px",
          width: "6rem",
          transform: "skew(322deg, 0deg)",
          height: "37px",
        }}
      ></Box>
    </>
  );
}

export default CardHighlight;
