import { useState, useCallback, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import moment from "moment";
import { Button, ButtonGroup, Grid } from "@mui/material";
import styled from "@emotion/styled";
import InfiniteScroll from "react-infinite-scroller";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  FB_APP_ID, SA_DEF_ORG_GROUP_ID,
  TEMP_HIDE_USER_POST_IDS,
} from "config/app.config";
import {
  useOrgFeedQuery,
  useFeedActionMutation,
  getFeedListMetaData,
  getSMFilterParams,
  useUserFeedListQuery,
  userSelectedFeed,
  getUserSelectedFeed,
  getUserSubmittedFeed,
  userSubmittedFeed,
} from "services/orgFeedApi";
import { useContentSuggestionCreateMutation } from "services/contentSuggestionApi";
import { getSMediaMgr } from "services/sMediaApi";
import { downloadImage, prepPostCustomData } from "lib/appFx";
import { actionObject, validateObj } from "lib/utils";
import { Typography } from "core";
import Spinner2 from "core/Spinner/Spinner2";
import { CongratsPostForm, FeedCard } from "sections";
import { useTranslation } from "react-i18next";
//---------------------------------------------------------

const StyledButton = styled(Button)(({ theme }) => ({
  background: theme.palette.primary.lighter,
  color: theme.palette.background.paper,
  boxShadow: "none",
  fontSize: "14px",
  width: "100%",
  textTransform: "capitalize",
  "&:hover": {
    background: theme.palette.primary.lighter,
  },
}));
//---------------------------------------------------------

export default function UsersFeedList({
  theme,
  // updated,
  // setUpdated,
  highlightPosts,
  setHighlightPosts,
  refetchLeaderboardUserData,
  getSMFilterParam,
  isUserList,
  uFeedFilter,
}) {
  const [feedQuery, setFeedQuery] = useState(skipToken);
  const [skipCall, setSkipCall] = useState(true);
  const [isFetchingData, setIsFetchingData] = useState(true);
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const userSelectedFeedData = useSelector(getUserSelectedFeed);
  const [message, setMessage] = useState("");
  const { t: home } = useTranslation('home')
  //const [currentSlide, setCurrentSlide] = useState(0);

  //console.log('userSelectedFeedData', userSelectedFeedData);
  // const {
  //   data: dDataA,
  //   error: dErrA,
  //   isLoading: dWaitA,
  //   isSuccess: dOKA,
  //   isFetching: dDataFetching,
  //   refetch: refetchFeedQuery,
  // } = useOrgFeedQuery(feedQuery, { refetchOnMountOrArgChange: true });
  //console.log('uFeedFilterUF', uFeedFilter);
  let isAnyUFeedFilter = false;
  if (uFeedFilter) {
    Object.keys(uFeedFilter).forEach((sK) => {
      isAnyUFeedFilter = isAnyUFeedFilter || uFeedFilter[sK]?.status;
    })
  }//console.log('uFeedFilter '+isAnyUFeedFilter, uFeedFilter);

  const {
    data: dDataA,
    error: dErrA,
    isLoading: dWaitA,
    isSuccess: dOKA,
    isFetching: dDataFetching,
    refetch: refetchFeedQuery,
  } = useUserFeedListQuery(
    { data: uFeedFilter },
    { skip: !isAnyUFeedFilter, refetchOnMountOrArgChange: true }//, pollingInterval: 3000}
  );//skip: !uFeedFilter
  //dOKA && console.log(dDataA);

  const [createContentSuggestion, { isSuccess }] =
    useContentSuggestionCreateMutation();
  // let isFetchingData = false;
  // const getSMFilterParam = useSelector(getSMFilterParams);

  const [showCongratsFeedForm, setShowCongratsFeedForm] = useState({
    isOpen: false,
  });
  const feedListMeta = useSelector(getFeedListMetaData);
  const getUserSubmittedFeeds = useSelector(getUserSubmittedFeed);

  /*useEffect(() => {
    if(updated === 'filterStarted') { setFeedData([]); }
    if(updated === 'filterLoading' && !dDataFetching) {
      setUpdated('filterEnded');
    }
  }, [dDataFetching]);
  const getSMediaTokens = useSelector(getSMediaMgr);

  const getSMediaTokensFunction = (actParams) => {
    return getSMediaTokens[actParams.destSMKey].sMTokens.properties[0].id
  }*/

  const {
    sMediaMgr: sMMeta,
    sMIdKeyMap: sMIdKeyMap,
    feedListModifiers,
    authUserData: authUsrData,
  } = useSelector(getFeedListMetaData);

  const [feedPageState, setFeedPageState] = useState(1);
  const fLoadMoreFeedHandler = () => {
    setFeedPageState(feedPageState + 1);
  };

  let dErr, dWait, dData;

  const sliderRef = useRef(null);
  const fResetSlider = () => { sliderRef.current?.slickGoTo(0); }
  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    speed: 500,
    arrows: true,
    //nextArrow: <div>Next</div>,//prevArrow:<div>Prev</div>,
    //prevArrow:"<button type='button' class='slick-prev pull-left'><i class='fa fa-angle-left' aria-hidden='true'></i></button>",
    //nextArrow:"<button type='button' class='slick-next pull-right'><i class='fa fa-angle-right' aria-hidden='true'></i></button>"
    afterChange: (data) => {
      dispatch(userSelectedFeed(feedData[data]));
      //setCurrentSlide(data);
      if (data === feedData.length - 1) {
        loadMore();
      }
    },
  };

  useEffect(() => {
    if (authUsrData && page && feedListModifiers)
      if (validateObj(feedListModifiers)) {
        const prepareParams = {
          page,
          orgId: authUsrData?.orgId,
          orgGroupIds: feedListModifiers?.selGroups || [],
          filterSMIds: getSMFilterParam,
        };

        if (feedListModifiers?.selGroupsfilterSMIds) {
          prepareParams["filterSMIds"] =
            feedListModifiers?.selGroupsfilterSMIds;
        }
        setFeedQuery(prepareParams);
        // if (updated === 'filterStarted') {
        //   setUpdated('filterLoading');
        // }
        // setSkipCall(false);
      }
  }, [authUsrData, feedListModifiers, page]);

  const [feedData, setFeedData] = useState([]);
  const [paginationData, setPaginationData] = useState({});

  useEffect(() => {
    try {
      if (dOKA && dDataA) {
        const profileInfo = {};
        dDataA.snetwork_properties.forEach(
          //(profile) => { profileInfo[profile.snetworkplatform_id] = profile }
          (profile) => (profileInfo[profile.id] = profile)
        );

        if (!dDataFetching) {
          if (validateObj(dDataA)) {
            if (feedData?.length !== dDataA.pagination?.total) {
              let dataObject = {};
              [...dDataA.feed].forEach((item) => {
                let objPost = {
                  ...item,
                  //profileInfo: profileInfo[item.snetworkplatform_id],
                  profileInfo: item.snetworkproperty_id && JSON.parse(JSON.stringify(
                    profileInfo[item.snetworkproperty_id])),
                  isUserFeed: true
                };
                dataObject[item.id] = objPost;
              });
              // test(feedData, dataObject);
              const uFeedDataCosnt = [
                //...feedData,///
                ...Object.values(dataObject),
              ]//.filter((data) => data.text);///
              setFeedData(uFeedDataCosnt);
              dispatch(userSelectedFeed(uFeedDataCosnt[0]));
              const pageMeta = dDataA?.pagination
                ? dDataA.pagination
                : {
                  "records": { "total": dDataA?.feed?.length },
                  "page": { "current": 1, "total": 1 }
                };
              //setPaginationData(dDataA.pagination);
              setPaginationData(pageMeta);
              // window.scrollTo({ top: 64, behavior: 'smooth' });

              // if (top === 64) {
              setIsFetchingData(true);
              setTimeout(() => { fResetSlider() }, 200);
            }
          } else { }
        }
      }
    } catch (e) { console.error("FeedList.jsx:135e:", e); }
  }, [dOKA, dDataA, uFeedFilter]);
  //dOKA  && console.log('userFeedN', dDataA);

  const loadMore = () => {
    if (isFetchingData) {
      if (page + 1 <= paginationData?.page?.total && !dDataFetching) {
        setFeedQuery({ ...feedQuery, page: page + 1 });
        setPage(page + 1);
        setIsFetchingData(false);
      }
    }
  };

  dErr = dErrA;
  dWait = dWaitA;
  dData = dOKA && dDataA ? dDataA : null;

  const [fFeedAct, { reset: fResetFA }] = useFeedActionMutation();

  function UserException(message) {
    this.code = 1234;
    this.message = message;
  }

  const onSubmit = async (e) => {
    let formData = new FormData();
    let mediaData;
    if (userSelectedFeedData) {
      mediaData = userSelectedFeedData?.media?.pictures.map(async (media, index) => {
        return await downloadImage(media, index);
      });
    }
    if (mediaData) {
      mediaData = await Promise.all(mediaData);
    }//console.log('mediaData', {mediaData, userSelectedFeedData});

    if (userSelectedFeedData?.media?.attachments.length) {
      for (let index = 0; index < 5; index++) {
        if (mediaData[index]) {
          formData.append(`media${index + 1}`, mediaData[index])
        } else { break; }
      }
    } else if (userSelectedFeedData?.media?.pictures.length) {
      for (let index = 0; index < 5; index++) {
        if (mediaData[index]) {
          formData.append(`media${index + 1}`, mediaData[index])
        } else { break; }
      }
    }
    //mediaData.map((file, index) => {});
    formData.append('text', userSelectedFeedData?.text);// || '');
    formData.append('feed_id', e.target.getAttribute("data-postid"));
    formData.append('group_id', feedListModifiers.selGroups[0] || SA_DEF_ORG_GROUP_ID);

    const response = await createContentSuggestion({ params: formData });
    if (response.data.contentsuggestion_id) {
      setMessage(home('post_shared_successfully'));
      e.target.style.background = "#d3d3d3";
      e.target.disabled = true;
      highlightPosts.unshift(e.target.getAttribute("data-postid"));///
      setHighlightPosts(highlightPosts);
      setTimeout(() => { setMessage("") }, 5000);
      setTimeout(() => { window.scrollTo({ top: 64, behavior: 'smooth' }); }, 4000);
    }
    // await refetchContentSuggestionList();
    let getUserSubmitted;
    if (!getUserSubmittedFeeds) {
      getUserSubmitted = [userSelectedFeedData];
      //dispatch(userSubmittedFeed(getUserSubmitted));
    } else {
      getUserSubmitted = [...getUserSubmittedFeeds, userSelectedFeedData];
      //dispatch(userSubmittedFeed(getUserSubmitted));
    }//console.log('getUserSubmitted', getUserSubmitted);
    dispatch(userSubmittedFeed(getUserSubmitted));
  };

  const fFeedActionHandler = async (actParams, fCallback, errCb) => {
    const postActionDone = actParams.feedData.engagements.user.map((data) => {
      return data.type;
    });
    const postPlatform =
      actParams.feedListMeta.sMIdKeyMap[actParams.postId.split("_")[0]];
    let faResp,
      defaultPointValue,
      actType = actParams.actType;
    const dest = actParams?.feedListMeta?.sMediaMgr[actParams.destSMKey]?.sMKey;
    const currGroupId = feedListModifiers.selGroups[0] || SA_DEF_ORG_GROUP_ID;

    try {
      const destSMId =
        actParams?.feedListMeta?.sMediaMgr[actParams.destSMKey]?.sMId;

      const sMAuthTokenId =
        actParams?.feedListMeta?.sMediaMgr[actParams.destSMKey]["activTokenId"];
      if (
        sMAuthTokenId ||
        actParams.destSMKey === "em" ||
        actParams.destSMKey === "wa"
      ) {
        let faParams;
        switch (dest) {
          case "tw":
            faParams = {
              feed_id: actParams.postId,
              snetworkplatform_id: destSMId,
              action: actionObject(postPlatform, dest, actType),
              snetworktoken_id: sMAuthTokenId,
              snetworkproperty_id:
                actParams.feedListMeta.sMediaMgr[actParams.destSMKey].sMTokens
                  .properties[0].id,
              group_id: currGroupId,
            };
            if (faParams.action === "retweet") {
              defaultPointValue = 50;
            } else {
              defaultPointValue = 100;
            }
            break;

          case "fb":
            faParams = {
              feed_id: actParams.postId,
              snetworkplatform_id: destSMId,
              action: actionObject(postPlatform, dest, actType),
              group_id: currGroupId,
            };
            defaultPointValue = 50;
            break;

          default:
            break;
        }

        if (faParams.action === "retweet") {
          if (actParams.feedData[faParams.action] === true) {
            throw new UserException("Tweet already retweeted");
          } else {
            postActionDone.forEach((data) => {
              if (data === "retweet") {
                throw new UserException("Tweet already retweeted");
              }
            });
          }
        }

        actParams?.pText && (faParams["text"] = actParams?.pText);
        actParams?.snetworkproperty_id &&
          actParams?.scheduleOn &&
          (faParams["execute_on"] = actParams?.scheduleOn);

        faResp = await fFeedAct(faParams).unwrap();

        if (faResp?.engagement_id) {
          if (faParams.action === "retweet") {
            actParams.feedData[faParams.action] = true;
          }

          // Object.assign(actParams.feedData.engagements.user, [...actParams.feedData.engagements.user,{type:"retweet"}])
          // actParams.feedData.engagements.user = [...actParams.feedData.engagements.user,{type:"retweet"}]
          if (fCallback) {
            let points = actParams?.feedData?.points?.find((fd) => {
              return (
                fd.snetworkplatform_id === faParams.snetworkplatform_id &&
                fd.engagement_type === faParams.action
              );
            });
            if (!points) {
              points = { points: defaultPointValue, };
            }
            await refetchLeaderboardUserData();
            await fCallback(faResp);
            setShowCongratsFeedForm({
              ...showCongratsFeedForm,
              isOpen: true,
              platform:
                actParams.feedListMeta.sMIdKeyMap[faParams.snetworkplatform_id],
              points,
              action: faParams.action,
            });
          }
        }
      }
    } catch (err) {
      if (err?.data?.result?.error?.code === 2505) {
        errCb(err?.data?.result?.error?.message);
      } else if (err.code === 1234) {
        errCb(err.message);
      } else {
        errCb(`an error occured in ${actType}`);
      }
      console.error("feedEngErr", err);
    }
    fResetFA();
  };

  let fList = dData?.feed;
  let sNetworkP = dData?.snetwork_properties;
  /*useEffect(() => {
    window.fbAsyncInit = () => {
      window.FB.init({
        appId: FB_APP_ID,
        autoLogAppEvents: true,
        xfbml: true,
        version: "v11.0",
      });
    };
    (function (d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if(d.getElementById(id)) { return; }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
    // }
  }, []);*/

  const navigate = useNavigate();
  const tempHidePostIds = TEMP_HIDE_USER_POST_IDS ?? [];
  //console.log('tempHidePostIds', tempHidePostIds);
  //paginationData && feedData && console.log('feedDataUF', feedData);

  return (
    <>
      {dWait ? (
        <Spinner2 />
      ) : dErr ? (
        "" //Error fetching feeds! Please try again later.  ."
      ) : (
        // <InfiniteScroll
        //   style={{width: '100%',}}
        //   initialLoad={true}
        //   loadMore={loadMore}
        //   threshold={10}
        //   useWindow={true}
        //   hasMore={isFetchingData}
        //   datalength={Object.keys(feedData).length}
        // >
        <Slider {...sliderSettings} className="slide-second" ref={sliderRef}>
          {paginationData ? (
            feedData
              .sort((a, b) => moment(b.created_at) - moment(a.created_at))
              .map((fd) => (
                //fd.id !== 'a1R3BYmO_v5ean8xx' && (
                !tempHidePostIds.includes(fd.id) && (
                  <Grid item xs={12} key={"fL_" + fd.id}
                  //className="tBdr2" sx={{position: 'relative'}}
                  >
                    <FeedCard
                      sNetworkP={sNetworkP}
                      theme={theme}
                      feedListMeta={feedListMeta}
                      feedData={fd}
                      onFeedEngagement={fFeedActionHandler}
                      showCongratsFeedForm={showCongratsFeedForm}
                      setShowCongratsFeedForm={setShowCongratsFeedForm}
                      defEngPoints=""
                      isUserList={true}
                    //handleSocialEngagementActions={handleSocialEngagementActions}
                    />
                    <ButtonGroup variant="contained"
                      sx={{
                        //position: 'absolute', marginTop: '-13px',
                        width: "100%", padding: '0 10px 5px 10px',//paddingBottom: '5px',
                        border: '0px solid yellow', boxShadow: '0'
                      }}
                    >
                      <StyledButton onClick={() => {
                        //dispatch(userSelectedFeed(feedData[currentSlide]));
                        //navigate("/submit");
                        navigate("/submit", {
                          state: {
                            feedData: fd,
                            pInfUP: prepPostCustomData({ feedListMeta, fd, sNetworkP })
                          }
                        });
                      }}>
                        {home('edit_and_share')}
                      </StyledButton>&nbsp;
                      <StyledButton onClick={onSubmit} data-postid={fd.id}>
                        {home('share_on_niu')}
                      </StyledButton>
                    </ButtonGroup>
                  </Grid>
                )
              ))
          ) : (
            <>
              <h3 style={{ textAlign: "center" }}>{home('no_user_post_available')}</h3>
            </>
          )}
        </Slider>
        // </InfiniteScroll>
      )}
      {message && <Typography align="center">{message}</Typography>}
    </>
  );
}
