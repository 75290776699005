import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fPrepEngPointsData } from 'lib/appFx';
import {
  useFeedEngPointsMultQuery, rInitSAEngPoints, //rGetRefEngPoints,
} from 'services/gameApi';
import { getSMediaMgr, getSMediaKeyIdMap } from 'services/sMediaApi';
//---------------------------------------------------------

export default function InitEngPts() {
  const fDispatchEngIniPoints = useDispatch();
  //const theSMData = useSelector(getSMediaMgr);
  const theSMKeyMap = useSelector(getSMediaKeyIdMap);
  //console.log('SM', {theSMKeyMap, theSMData});

  //---------------------------------------------------------
  const [stEngPointsOK, setStEngPointsOK] = useState(false);
  const {
    data: allEngPtsA, //error: allEngPtsErrA,
    //isLoading: allEngPtsWaitA, isSuccess: allEngPtsOKA,
  } = useFeedEngPointsMultQuery(null, { skip: (stEngPointsOK || !theSMKeyMap) });
  useEffect(() => {
    if (!stEngPointsOK && allEngPtsA) {
      if (theSMKeyMap && allEngPtsA) {
        const refEngPtsData = fPrepEngPointsData(allEngPtsA?.points, theSMKeyMap);
        if (Object.keys(refEngPtsData).length) {
          setStEngPointsOK(true);
          fDispatchEngIniPoints(rInitSAEngPoints(refEngPtsData));
        }
      }
    }
  }, [allEngPtsA, fDispatchEngIniPoints, stEngPointsOK]);
  //---------------------------------------------------------

  return null;
}