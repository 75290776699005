import { useState, useEffect } from "react";
import { useForm, //Controller, FormProvider, useFormContext
} from "react-hook-form";
import { Stack, Box, TextField, Button,
  //styled, Card, Divider, Portal,
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import moment from "moment";
import { Typography, Spinner2 } from 'core';
import { fGetNumAbbr, fStrToTitleCase, fLTrimChar, fRTrimChar,
  fGetArrayHashToCSString, fStrHashTagsToArray,
  fCampareArrayElement,
  //fGetRefDates, fFormatNumberToLocaleString,
} from 'lib/appFx';
import {useGroupDetailsQuery, useGroupMemberListQuery,
  useGroupUpdateMutation,
} from 'services/admin/admGroupsApi';
//import { selectAuthUserId, selectUserOrgId } from "services/auth/authSlice";
//---------------------------------------------------------

const txFNLabelSx = { fontSize: '0.9rem', color: '#9e9e9e' };
const txFVLabelSx = { fontSize: '1.25rem', color: '#0694c7', padding: '0.2rem 0' };
//---------------------------------------------------------

//AdmGroupDetailHead
export default function AdmGroupSummary({theme, groupData,}) {
  console.log('AGDSumGrpData', groupData);
  const groupId = groupData.id;
  let admGroupDetails, admGroupMembers, admGrpMemCount=0;
  const inElPrefix = '';//'txGrp_';
  const f1 = 'name', f2 = 'hash_filter';

  const [stGrpEditMode, setStGrpEditMode] = useState(false);
  const [fUpdateGroup, {isLoading: isUpdating}] = useGroupUpdateMutation();

  //groupList & groupDetailsHasSameData
  admGroupDetails = groupData;
  const {
    data: admGrpDApiData, refetch: fRefetchGroupDetails,
    isLoading: admGrpDWait, isFetching: admGrpDWait2,
    isError: admGroupDErr, isSuccess: admGroupDSuccessApi,
  } = useGroupDetailsQuery(
    { groupId: groupId },
    //{ skip: false,}// refetchOnMountOrArgChange: true }
  );
  if(admGroupDSuccessApi) {
    //admGroupDetails = admGrpDApiData?.groups.slice();
    admGroupDetails = admGrpDApiData?.groups[0];
  }

  const {
    data: admGrpMemApiData, //refetch: fRefetchGroupMembers,
    isLoading: admGrpMemWait, isFetching: admGrpMemWait2,
    isError: admGroupMemErr, isSuccess: admGroupMemSuccessApi,
  } = useGroupMemberListQuery(
    { groupId: groupId, page: 1 },
    //{ skip: false,}// refetchOnMountOrArgChange: true }
  );
  if(admGroupMemSuccessApi) {
    admGroupMembers = admGrpMemApiData?.group_members;
    admGrpMemCount = admGrpMemApiData?.pagination?.records?.total;
  }
  //console.log('admGroupDetails_'+groupId, admGroupDetails);
  //console.log('admGroupMemData_'+groupId+'_'+admGrpMemCount, admGroupMembers);
  //---------------------------------------------------------

  const fToggleEditGroup = () => {
    setStGrpEditMode(prevSt => ! prevSt);
    setTimeout(()=>{
      const elGrpTtl = document.getElementById(inElPrefix+f1);
      elGrpTtl && elGrpTtl.focus();
    }, 1000);
  };

  const fSaveGroupInfo = async() => {
    const inParams = {};
    const elGrpTtl = document.getElementById(inElPrefix+f1);

    if(elGrpTtl && elGrpTtl?.value.length > 3) {
      inParams['name'] = elGrpTtl.value.trim();
      const elGrpHTags = document.getElementById(inElPrefix+f2);
      if(elGrpHTags?.value.length) {
        inParams['hash_filter'] = fRTrimChar(elGrpHTags.value.trim(), ',').trim();
        inParams['hash_filter'] = fRTrimChar(elGrpHTags.value.trim(), ',').trim();
        inParams['hash_filter'] = fStrHashTagsToArray(inParams['hash_filter']);
      }//console.log('edGrpInParams', inParams);
      //console.log('admGroupDetails', admGroupDetails);

      if(inParams['name'] != admGroupDetails.name ||
        (inParams?.hash_filter && !fCampareArrayElement(
          inParams['hash_filter'], admGroupDetails.hash_filter)
        )
      ) {//console.log('dirtyFields');
        if(inParams?.hash_filter) {
          inParams.hash_filter = JSON.stringify(inParams.hash_filter);
        }
        console.log('edGrpInParamsDirty', inParams);
        try {
          let aResp;
          aResp = await fUpdateGroup({ groupId, params: inParams }).unwrap();
          console.log('admUpdateGrpResp', aResp);
          if(aResp?.result?.success) {
            setStGrpEditMode(prevSt => ! prevSt);
            fRefetchGroupDetails();
            const elPgHdGrpTtl = document.getElementById('ctnPgHdGrpTtl');
            //elPgHdGrpTtl && elPgHdGrpTtl.innerHTML = 'AA';
            if(elPgHdGrpTtl) {
              elPgHdGrpTtl.innerHTML = fStrToTitleCase(inParams['name']);
            }
          }
        } catch(err) {console.error(err, 'Error updating group');
          let eMsg;
          if(err?.status === 403) {
            eMsg = err?.data?.error?.message;
          }
          if(!eMsg) {
            eMsg = 'Error upating group.'+"\n"+'Please try again...';
          }
          //setStPgMsg(eMsg);//setStAjaxStep(-1);
          setStGrpEditMode(prevSt => ! prevSt);
        }
      } else {//console.log('clean');
        setStGrpEditMode(prevSt => ! prevSt);
      }
    } else {//fToggleEditGroup()
      setStGrpEditMode(prevSt => ! prevSt);
    }
  };
  //---------------------------------------------------------

  //const defGrpVals = {name: groupData.name, hash_filter: null};
  const frmSetGroupMethods = useForm({
    //defaultValues: defGrpVals,
  });
  let groupTitleInput, groupHTagInput, editButton;

  if(stGrpEditMode) {
    const {//handleSubmit, control, setValue, watch, resetField, clearErrors,
      register, getValues, reset: frmReset, formState:{errors: frmErrs},
    } = frmSetGroupMethods;

    groupTitleInput = (
      <Box className='xbdr1'
        sx={{width: {xs: '98%', md: '54%'}, height: '2.5rem'}}
      >
        <TextField //type="text"
          id={inElPrefix + f1}
          {
            ...register(inElPrefix + f1, {
              required: 'Group name is required',
              minLength: {value: 1, message: 'mustMinLenErr'},
              maxLength: 12, pattern: /^[A-Za-z]+$/i
            })
          }
          defaultValue={fStrToTitleCase(admGroupDetails.name)}
          required={true} fullWidth={true}
          tabIndex={1}
          aria-invalid={frmErrs[inElPrefix + f1] ? true : false}
          variant="filled" size='small'
          label='' //placeHolder={'name'}
          InputLabelProps={{ shrink: true, }}
        />
        <p>{frmErrs[inElPrefix + f1]?.message}</p>
      </Box>
    );
    groupHTagInput = (
      <Box className='xbdr1'
        sx={{width: {xs: '98%', md: '54%'}, height: '2.5rem'}}
      >
        <TextField //or use StyledTextArea from SubmitContent
          id={inElPrefix + f2}
          //multiline minRows={2} maxRows={2}
          {
            ...register(inElPrefix + f2, {
              //required: ' is required',
              //minLength: {value: 1, message: 'mustMinLenErr'},
              //maxLength: 12, pattern: /^[A-Za-z]+$/i
            })
          }
          defaultValue={fGetArrayHashToCSString(admGroupDetails?.hash_filter)}
          required={false} fullWidth={true}
          tabIndex={2}
          aria-invalid={frmErrs[inElPrefix + f2] ? true : false}
          variant="filled" size='small'
          label='' //placeHolder={'Comma separated hash tags'}
          InputLabelProps={{ shrink: true, }}
          //InputProps={{ classes: {input: cls1}, style: {} }}
        />
        {/*<p>{frmErrs[inElPrefix + f2]?.message}</p>*/}
      </Box>
    );
    editButton = (
      <Button className='cBtn cBtnDark'
        disabled={isUpdating} onClick={fSaveGroupInfo}
        sx={{minWidth: '6.25rem'}}// !important
      >
        {isUpdating ? <Spinner2 /> : 'Save'}
      </Button>
    );
  } else {
    editButton = (
      <Button className='cBtn cBtnWh' onClick={fToggleEditGroup}
        sx={{minWidth: '6.25rem'}}
      >
        Edit <EditIcon sx={{
          color:'#00406B', fontSize: "small",
          flexShrink: 0, marginLeft: 1}} />
      </Button>
    );
  }//EndIfEditMode
  //---------------------------------------------------------

  let lstGrpTags = [];
  if(admGroupDetails?.hash_filter?.length) {
    admGroupDetails.hash_filter.forEach((t, i) => {
      const tagStyle = i === 0 ? {} : {margin: 'inherit 0.5rem'};
      lstGrpTags.push(
        <Typography className='ctnTagTx' key={'gTg_'+i} style={tagStyle} >
          {'#'+fLTrimChar(t, '#')}
        </Typography>
      );
    });
  } else {
    lstGrpTags = (<Typography sx={{fontSize: '0.8rem', paddingTop: '0.1rem'}}>
      Not set yet</Typography>);
  }
  const maxRowHt = '4rem';
  //---------------------------------------------------------

  return (
    <Box id="ctnAGrpSum" className="bgWh padL"
      component="form" sx={{minHeight: '8rem', }}
    >
      <Stack className='xtBdr2'
        direction={{xs: 'column', md: 'row'}}
        spacing={{xs: 2, md: 5,}}
        minHeight={'inherit'}
      >
        <Box id='ctnAGSC1' sx={{minWidth: '15rem',}} //className='bdr1'
          //display: 'inline-grid', alignContent: 'space-between'
        >
          <Box sx={{maxHeight: maxRowHt}}>
            <Typography sx={txFNLabelSx}>Group Name</Typography>
            {stGrpEditMode ? groupTitleInput : (
              <Typography sx={txFVLabelSx}>{fStrToTitleCase(admGroupDetails.name)}</Typography>
            )}
          </Box>
          <Box marginTop={'1rem'}>
            <Typography sx={txFNLabelSx}>Last Active</Typography>
            <Typography sx={txFVLabelSx}>
              {moment(admGroupDetails?.updated_at).format('MMM DD YYYY, hh:mm A')}
            </Typography>
          </Box>
        </Box>
        {/* ================ */}

        <Box id='ctnAGSC2' sx={{width: '100%'}}>
          <Box display={{sm: 'block', md: 'flex'}}//'flex'
            //justifyContent='space-between'
            justifyContent={{sm: 'left', md: 'space-between',}}
          >
            <Box>
              <Typography sx={txFNLabelSx}>Members</Typography>
              <Typography sx={txFVLabelSx}>{admGrpMemCount || 0}</Typography>
            </Box>
            <Box>
              <Typography sx={txFNLabelSx}>Points</Typography>
              <Typography sx={txFVLabelSx} title={250 || 0}>
                {fGetNumAbbr((250 || 0), 2)}
              </Typography>
            </Box>
            <Box>{editButton}</Box>
          </Box>
          <Box marginTop={'1rem'}>
            <Typography sx={txFNLabelSx}>Content Topics</Typography>
            {stGrpEditMode ? groupHTagInput : (
              <Box sx={{wordBreak: 'break-all'}}
                //maxHeight: '5rem', overflowY: 'auto'
              >{lstGrpTags}</Box>
            )}
          </Box>
        </Box>
      </Stack>
    </Box>
  );
};
