import axios from "axios";
//import { string } from "prop-types";
import md5 from 'md5';
import moment from "moment";
import {
  API_BASE_URL, SA_DATE_DISP_FORM,//SA_IMG_BASE_URL,
  MAX_SAUTH_WIN_OPEN_TIMEMS,
  ENG_SRC_SM, ENG_DEST_SM, DEF_ENG_POINT,
  REMEMBER_ME_CNAME, REMEMBER_ME_CNAME_AWS,
  REMEMBER_ME_CNAME_AWS2, REMEMBER_ME_CNAME_AWS3
} from 'config/app.config'; //OnlyForTest
import { COMM_ROUTES } from 'config/routes.config'; //OnlyForTest
import { fGetCookie, fWriteCook, fDelCookie, isValidUrl } from "./netFx";
//const MD5 = require("crypto-js/md5");
//---------------------------------------------------------

export const fResetApiStat = () => {
  //appStore.dispatch(sSApi.util.resetApiState());
};
/*export const isAdminUser = (uEMail) => {
	return uEMail === SA_TEMP_DEF_ADMIN_EMAIL;
}*/

export const fCopyTextToClipboard = (txt) => {
  navigator.clipboard.writeText(txt);
};
export const fSafeStringify = (obj) => {
  let cache = [];
  let str = JSON.stringify(obj, function(key, value) {
    if (typeof value === "object" && value !== null) {
      if (cache.indexOf(value) !== -1) {
        // Circular reference found, discard key
        return;
      }
      // Store value in our collection
      cache.push(value);
    }
    return value;
  });
  cache = null; // reset the cache
  return str;
};

export const fInitSMAuth = (aUrl, sK, flgIsAdmin=false) => {
	let fTest=0, cName='sMAT_Ukn';//+sK;
  let maxWaitTime = MAX_SAUTH_WIN_OPEN_TIMEMS;
  if(flgIsAdmin) { maxWaitTime *= 2; }
	if(fTest) {
		aUrl = 'https://www.w3schools.com/jsref/prop_win_opener.asp';
	}//console.log('aUrl: ', sK+': '+aUrl);
	fDelCookie(cName);

	let sAWinAttr = 'height=500,width=800,toolbar=0,titlebar=1,location=0';
	sAWinAttr += ',menubar=0,status=1,scrollbars=1,resizable=1';
	sAWinAttr += ',directories=0,left=100,top=50,modal=yes,alwaysRaised=yes';
	//sAWinAttr +=',popup=0,noopener,noreferrer';
	let sAWin = window.open(aUrl, '_blank', sAWinAttr);//.focus(DON't);
	//sAWin.opener = null;//e.returnValue = 'onbeforeunload';
	////sAWin.addEventListner();
	//sAWin.onbeforeunload((e)=> {console.log('*')});

	if(fTest) {
		const testQ = fTest ? '?module=oauth&payload=Qfi8UbZJ0MSFTYiojIkl2XtJ3bmRXYsB3ay92d0VmbzJCLiIFZrdXMQdmbiojIkl2XuV2avR3ay92d0VmbzJCLicXWPBTc5JnViojIkl2XyV2c1Jye#_=_' : '';
		setTimeout(() => {
			sAWin.location.href = COMM_ROUTES.SMConnectResp.url+testQ;
		}, 4500);//console.log('sAWinLoc '+sAWin.location.href);
	}

	//DO NOT DELETE
	/*let aWin = setInterval(() => {
		//CheckCookieExists&close();
		//With Tw & Ln, sAWin href is not defined
		//console.log('111 ', sAWin.location+" <> "+window.location);
		console.log('111 ', sAWin.location.href+" <> "+window.location.href);
		console.log('AAA ', sAWin.location.host+" <> "+window.location.host);
		console.log('BBB', aWin);
		try{
			if(window.opener !== null && sAWin?.location?.host
				&& sAWin.location.host === window.location.host
			) {//console.log('Yessss');
				clearInterval(aWin);//alert('Hi2');
				setTimeout(()=>{sAWin?.close();}, 2500);
			}
		} catch(e) {}
	//}, 1500);
	setTimeout(()=> {clearInterval(aWin); sAWin?.close(); }, maxWaitTime);*/

	setTimeout(()=> { sAWin?.close(); }, maxWaitTime);
	fTest && setTimeout(() => {
		console.log('authC: '+cName+': '+fGetCookie(cName));
		//document.cookie = cName+'=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
	}, 20000);
	return sAWin;
};

export const XfInitSMAuth = (aUrl, sK, fetchUserSMAcc, data) => {
  let fTest = 0, cName = 'sMAT_Ukn'; //+sK;
  const maxWaitTime = MAX_SAUTH_WIN_OPEN_TIMEMS;

  let sAWinAttr = 'height=500,width=800,toolbar=0,titlebar=1,location=0';
  sAWinAttr += ',menubar=0,status=1,scrollbars=1,resizable=1';
  sAWinAttr += ',directories=0,left=100,top=50,modal=yes,alwaysRaised=yes';
  //sAWinAttr +=',popup=0,noopener,noreferrer';

  let sAWin = window.open(aUrl, '_blank', sAWinAttr); //.focus(DON't);
  //sAWin.opener = null;//e.returnValue = 'onbeforeunload';
  ////sAWin.addEventListner();
  //sAWin.onbeforeunload((e)=> {});
  // if (fTest) {
  //   const testQ = fTest
  //     ? '?module=oauth&payload=Qfi8UbZJ0MSFTYiojIkl2XtJ3bmRXYsB3ay92d0VmbzJCLiIFZrdXMQdmbiojIkl2XuV2avR3ay92d0VmbzJCLicXWPBTc5JnViojIkl2XyV2c1Jye#_=_'
  //     : '';
  //   setTimeout(() => {
  //     sAWin.location.href = COMM_ROUTES.SMConnectResp.url + testQ;
  //   }, 4500);
  // }
  const userToken = '';//userDetails.authToken;//??
  let aWin = setInterval(async () => {

    try {
      const response = await axios.get(
        API_BASE_URL+"/platform/accounts",
        {
          headers: {
            Authorization: `Bearer ${userToken?.authUser?.token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const isSubscribed = response?.data?.result?.data?.tokens.some((item) => {
        return item.snetworkplatform_id === data.sMId;
      });

      if (isSubscribed) {
        //clearInterval(aWin);

        setTimeout(() => {
		  fetchUserSMAcc(data.sMId, sAWin);
          sAWin.close();
          //window.close();
          //fetchUserSMAcc(data.sMId, sAWin);
        }, 200);
		clearInterval(aWin);
      }
      // if (sAWin.location.host === window.location.host) {
      //   clearInterval(aWin);

      //   setTimeout(() => {
      //     sAWin?.close();
      //     fetchUserSMAcc(data.sMId, sAWin);
      //   }, 2500);
      // }

      // if (sK === "ln" && sAWin.location.host !== aUrl) {
      //   clearInterval(aWin);
      //   setTimeout(() => {
      //     sAWin?.close();
      //     fetchUserSMAcc(data.sMId, sAWin);
      //   }, 50);
      // }
    } catch (e) {}
  }, 2500);

  setTimeout(() => {
    clearInterval(aWin);
    sAWin?.close();
  }, maxWaitTime);

  fTest &&
    setTimeout(() => {
      document.cookie = cName + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    }, 20000);
};

export const fFetchSMediaAccData = async (aToken) => {
  //NotUsed
	try{//console.log('aToken', aToken);
		const response = await axios.get(
		  API_BASE_URL+'/platform/accounts',
		  {
			headers: {
			  Authorization: `Bearer ${aToken}`,
			  'Content-Type': 'application/json',
			},
		  }
		);//console.log('accListResp', response?.data?.result);
		return response?.data?.result?.data;
	} catch(error) { console.log("accListErr", error); return null; }
}

export const fFetchSMediaAccProps = async (aToken, sMPId) => {
	try{//ShouldBeAServiceMutation
    //console.log('aTok_'+sMPId, aToken);
		const response = await axios.post(
		  API_BASE_URL+'/platform/property',
		  { snetworkproperty_id: sMPId },
		  {
        headers: {
          Authorization: `Bearer ${aToken}`,
          'Content-Type': 'application/json',
        },
		  }
		);console.log('propSubsResp', response?.data?.result);
		return response?.data?.result?.success;
		//return (response?.status).toString() === 201;
	} catch(error) { console.log("SubsErr", error); return null; }
}

export const fEncodeAwsToken = (token) => {
	//strReverse(md5((token)))
};
export const decodeSNectConnResp = (strAT) => {
  let res = strAT.split("").reverse().join("");
  return JSON.parse(atob(res));
};
export const fEncodeSSq = (sStr) => {
	let res;
	if(sStr) {
		//try{
			//if(typeof sStr !== 'string') { res = JSON.stringify(sStr) }
			res = btoa(sStr);
		//} catch {}
	}//console.log('enc '+sStr, res);
	return res;
};
export const fDecodeSSq = (strJ) => {
	let res;
	if(strJ) {
		//try{//res = JSON.parse(atob(strJ));
			res = atob(strJ);
		//} catch {}
	}
	return res;
};

export const fClearLocalStorageSSq = () => {
	localStorage.clear();
	sessionStorage.clear();
}
export const fClearFMNotCooks = () => {
	fDelCookie(REMEMBER_ME_CNAME);
	fDelCookie(REMEMBER_ME_CNAME_AWS);
	fDelCookie(REMEMBER_ME_CNAME_AWS2);
	fDelCookie(REMEMBER_ME_CNAME_AWS3);
};
export const fSetFMeNotSetts = (uCreds, uData=null, cogData=null) => {
	//{defCredVals{nSsqUid, nSsqUPSeK}, usrRedData}
	let res, cMaxAge = 2593000;//86400*30Days=2592K
	if(uCreds?.email && uCreds?.password) {
		let remMeSetts = {
			usrRedData: uData,
			defCredVals: {
				nSsqUid: fEncodeSSq(uCreds.email),
				nSsqUPSeK: fEncodeSSq(uCreds.password),
				fmn: uCreds.fmn
			}
		};
		fWriteCook(REMEMBER_ME_CNAME, fEncodeSSq(JSON.stringify(remMeSetts)), cMaxAge);
		if(cogData) {
			let cogData2, cogData3;
			if(cogData?.cognitoAuthData?.accessToken) {
				cogData2 = JSON.parse(JSON.stringify(cogData));
				delete cogData.cognitoAuthData.accessToken;
				delete cogData.cognitoAuthData.refreshToken;
				delete cogData.cognitoAuthData.clockDrift;
			}
			if(cogData2?.cognitoAuthData?.idToken) {
				delete cogData2.cognitoAuthData?.idToken;
				cogData3 = JSON.parse(JSON.stringify(cogData2));
				delete cogData2.cognitoAuthData.refreshToken;
				delete cogData2.cognitoAuthData?.clockDrift;
				delete cogData3.cognitoAuthData.accessToken;
			}
			//console.log('cogData', cogData);
			//cogData2 && console.log('cogData2', cogData2);
			//cogData3 && console.log('cogData3', cogData3);
			fWriteCook(REMEMBER_ME_CNAME_AWS,
				fEncodeSSq(JSON.stringify(cogData)), cMaxAge);
			if(cogData2?.cognitoAuthData?.accessToken) {
				fWriteCook(REMEMBER_ME_CNAME_AWS2,
					fEncodeSSq(JSON.stringify(cogData2)), cMaxAge);
			}
			if(cogData3?.cognitoAuthData?.refreshToken) {
				fWriteCook(REMEMBER_ME_CNAME_AWS3,
					fEncodeSSq(JSON.stringify(cogData3)), cMaxAge);
			}
		}
		res = true;
	}
	return res;
}
export const fGetFMeNotSetts = () => {
  let res = null, fMNAwsSetts, fMNSetts = fGetCookie(REMEMBER_ME_CNAME);
  fMNSetts = fMNSetts ? fDecodeSSq(fMNSetts) : null;
  if(fMNSetts) {
	fMNAwsSetts = fGetCookie(REMEMBER_ME_CNAME_AWS);
	fMNAwsSetts = fMNAwsSetts ? fDecodeSSq(fMNAwsSetts) : null;

	try{
		fMNSetts = JSON.parse(fMNSetts);
		if(fMNAwsSetts) {
			fMNAwsSetts = JSON.parse(fMNAwsSetts);
		}
		//console.log('fMNSettsC '+typeof fMNSetts, fMNSetts);
		//console.log('fMNAwsSettsC '+typeof fMNAwsSetts, fMNAwsSetts);
	} catch {}
	res = {
		usrRedData: fMNSetts?.usrRedData,
		defCredVals: {
			email: fDecodeSSq(fMNSetts?.defCredVals?.nSsqUid),
			password: fDecodeSSq(fMNSetts?.defCredVals?.nSsqUPSeK),
			remember_me: fMNSetts?.defCredVals?.fmn
		}
	};
	if(fMNAwsSetts) {
		let fMNAwsSetts2 = fGetCookie(REMEMBER_ME_CNAME_AWS2);
		let fMNAwsSetts3 = fGetCookie(REMEMBER_ME_CNAME_AWS3);
		if(fMNAwsSetts2?.cognitoAuthData?.accessToken) {
			fMNAwsSetts = {...fMNAwsSetts, ...fMNAwsSetts2};
		}
		if(fMNAwsSetts3?.cognitoAuthData?.refreshToken) {
			fMNAwsSetts = {...fMNAwsSetts, ...fMNAwsSetts3};
		}
		res.usrRedData = {...res.usrRedData, ...fMNAwsSetts};
	}
  } else {//defaultFMN
	res = {defCredVals: { email: '', password: '', remember_me: false }};
  }
  return res;
}
export const strReverse = (str) => {
  return (str === '') ? '' : str.split('').reverse().join('');
  //return (str === '') ? '' : reverseString(str.substr(1)) + str.charAt(0);
}
export const genNiuSAKey = (uId) => {
  switch(uId) {
    //case 'cehoramiro@gotgel.org':
    case 'admin1@company3.com':
    case 'niusadev@gmail.com':
    case 'cemixo9891@carpetra.com': return 'Demo@123';
    default: return (uId) ? strReverse(md5((uId))) : null;
  }
  //return (uId) ? strReverse(md5((uId))) : null;
}
export const setSelfClose = (timeoutMS) => {
  setTimeout(() => {
    window.close();
  }, timeoutMS);
};

export const getInitSMediaAttr = (sMCData = null, igIsBusiness = false) => {
  let res, lstSMP, authUrlsA, theSM = {
      fb: null, tw: null, yt: null, ln: null, ig: null, igb: null,
      em: null, wa: null, //bc: null, bl: null, ts: null,
      //pl: null, //"ev": null
  }; //connStat: 'Connected'|'Expired'|''|null
  //gEngType = ['like', 'share', 'click'];//fwd
  //flgs[enabled, readOnly, flgMultiShare, destOnly, srcOnly]
  const regSAuth = ["fb", "tw", "yt", "ln", "ig"];

  if(typeof sMCData === "object" && sMCData?.length > 1) {
    lstSMP = sMCData[0].platforms; //console.log('lstSMP', lstSMP);
    authUrlsA = sMCData[1].urls; //console.log('authUrlsA', authUrlsA);
  }

  if(1) {
    const genK = { sMId: null, sMKey: null, title: null, dispTitle: null,
      dispOrder: null, engPoints: {}, flgs: {}, selfEngTypes: {},
      engTypes: {},
    };
    for(let sK in theSM) {
      theSM[sK] = JSON.parse(JSON.stringify(genK));
      if(regSAuth.indexOf(sK) >= 0) {
        theSM[sK]["authUrl"] = null;
        theSM[sK]["connStat"] = null;
        theSM[sK]["activTokenId"] = null;
        theSM[sK]["sMTokens"] = [];
      }
    }
    for(let sK in theSM) {
      let ttlLC, sMO = JSON.parse(JSON.stringify(theSM[sK]));
      sMO.sMKey = sK;
      switch (sK) {
        case "fb":
          sMO.title = "Facebook";
          sMO.dispOrder = 1;
          sMO.dispTitle = "facebook";
          sMO.engTypes = ["share", "like", "comment"];
          sMO.selfEngTypes = [
            "event_attending", "event_declined", "event_maybe", ];
          break;
        case "tw":
          sMO.title = "Twitter";
          sMO.dispOrder = 2;
          sMO.dispTitle = "twitter";
          sMO.engTypes = ["share"]; //'like', 'comment'];
          sMO.selfEngTypes = [
            "favorite", "retweet", "qtweet", "reply", "mention",
          ];
          break;
        case "yt":
          sMO.title = "YouTube";
          sMO.dispOrder = 3;
          sMO.dispTitle = "youtube"; //youTube
          sMO.engTypes = ["like", "comment"];
          break;
        case "ln":
          sMO.title = "LinkedIn";
          sMO.dispOrder = 4;
          sMO.dispTitle = "linkedIn";
          sMO.engTypes = ["like", "comment"];
          break;
        case 'ig':
          sMO.dispOrder = 5;
          //sMO.title = igIsBusiness ? 'Instagramb' : 'Instagramp';
          //sMO.dispTitle = igIsBusiness ? 'instagramb' : 'instagramp';
          sMO.title = 'Instagram';
          sMO.dispTitle = 'instagram';
          sMO['cTitle'] = 'Instagramp';
          sMO.engTypes = ['share', 'like', 'comment'];
          sMO.selfEngTypes = ['repost'];
          break;
        case 'igb':
          sMO.dispOrder = 5;
          sMO.title = 'Instagram';
          sMO.dispTitle = 'instagram';
          sMO['cTitle'] = 'Instagramb';
          sMO.engTypes = ['share', 'like', 'comment'];
          sMO.selfEngTypes = ['repost'];
          break;
        case "em":
          sMO.title = sMO.dispTitle = "Email";
          sMO.dispOrder = 6;
          sMO.engTypes = ["share"]; //fwd
          sMO.flgs["destOnly"] = true;
          break;
        case "wa":
          sMO.title = "Whatsapp";
          sMO.dispOrder = 7;
          sMO.dispTitle = "whatsapp";
          sMO.engTypes = ["share"]; //fwd
          sMO.flgs["destOnly"] = true;
          break;
        case "bc":
          sMO.title = sMO.dispTitle = "Broadcast";
          sMO.engTypes = ["read", "like", "comment"];
          sMO.flgs["destOnly"] = true;
          break;
        case "bl":
          sMO.title = sMO.dispTitle = "Blog";
          sMO.flgs["srcOnly"] = true;
          break;
        case "ts":
          sMO.title = sMO.dispTitle = "Task";
          break;
        case "pl":
          sMO.title = sMO.dispTitle = "Platform";
          sMO.flgs["destOnly"] = true;
          break;
        default:
          break;
      }
      if(lstSMP) {
        for(let sMAK in lstSMP) {
          //ttlLC = sMO?.title?.toLowerCase();
          ttlLC = (sMO?.cTitle ? sMO?.cTitle : sMO?.title).toLowerCase();
          if(authUrlsA && ttlLC &&
			ttlLC === lstSMP[sMAK].name.toLowerCase()
		  ) {
            sMO.sMId = lstSMP[sMAK]?.id;
            if(authUrlsA.hasOwnProperty(ttlLC)) {
              sMO["authUrl"] = authUrlsA[ttlLC];
              sMO["connStat"] = "";
            }
            break;
          }
        }
      }
      theSM[sK] = sMO;
    }
    res = theSM;
  }//lstSMP && console.log('initSMediaAttr', res);
  return res;
};
export const fPrepEngPointsData = (engPtsData, sMKeyMap) => {
  const sMKeyMapRev = swapKeyVal(sMKeyMap);
  console.log('fPrepEngP', {engPtsData, sMKeyMap, sMKeyMapRev});
  const engPtsResp = {};
  let srcSMKey, destSMKey, engType;

  if(engPtsData && sMKeyMap) {
    const refSrcSM = ENG_SRC_SM;
    const refDestSM = ENG_DEST_SM;
    const ignoreETs = ['like', 'comment', 'reaction'];

    if(engPtsData.length) {
      engPtsData.forEach(ePR => {//console.log('ePR', ePR);
        srcSMKey = sMKeyMapRev[ePR.source_snetworkplatform_id];
        if(refSrcSM.includes(srcSMKey)) {
          destSMKey = sMKeyMapRev[ePR.destination_snetworkplatform_id];
          if(refDestSM.includes(destSMKey)) {
            engType = ePR.engagement_type;
            if(!ignoreETs.includes(engType)) {
              /*if(false) {
                switch(engType) {
                  case 'quote_share': case 'quote_tweet': engType = 'qShare'; break;
                  case 'retweet': engType = 'share'; break;
                  case 'tweet': engType = 'share'; break;
                  default: break;
                }
              }*/

              if(!engPtsResp.hasOwnProperty(srcSMKey)) { engPtsResp[srcSMKey] = {}; }
              if(!engPtsResp[srcSMKey].hasOwnProperty(destSMKey)) {
                engPtsResp[srcSMKey][destSMKey] = {};
              }
              //IgnoreTheDuplicates
              //if(!engPtsResp[srcSMKey][destSMKey].hasOwnProperty(engType)) {
                engPtsResp[srcSMKey][destSMKey][engType] = ePR.points;
              //}
              //console.log('aa_'+srcSMKey+'-'+destSMKey+'-'+engType,
              //  engPtsResp[srcSMKey][destSMKey]);
            }
          }
        }//E*R
      });//console.log('AA', engPtsResp);
    } else {//if(1) {
      refSrcSM.forEach(sK => {
        if(refDestSM.includes(sK)) {
          engPtsResp[sK] = {};
          refDestSM.forEach( dK => {
            const dET = fGetDefaultEngType(sK, dK);
            engPtsResp[sK][dK] = {};
            engPtsResp[sK][dK][dET] = DEF_ENG_POINT;
          });
        }
      });
    }

    //Ensure All of the refSrcSM && refDestSM data exists
    //Happens when partial SM data is available from Api
    refDestSM.forEach(sK => {//refSrcSM.forEach
      if(!engPtsResp.hasOwnProperty(sK)) {
        engPtsResp[sK] = {};
      }
      refDestSM.forEach(dK => {
        if(!engPtsResp[sK].hasOwnProperty(dK)) {
          engPtsResp[sK][dK] = {};
        }
        const dET  = fGetDefaultEngType(sK, dK);
        if(!engPtsResp[sK][dK].hasOwnProperty(dET)) {
          engPtsResp[sK][dK][dET] = DEF_ENG_POINT;
        }
      });
    });
  }console.log('fPrepEngPointsDataResp', engPtsResp);
  return engPtsResp;
};
export const fGetDefaultEngType = (sK, dK) => {
  //As the adminUI has not been detailed,
  // we have to consider an engType as refPoint
  let resp = 'share';
  switch(sK) {//AsPerCurrentApiResp
    case 'tw'://tweet, quote_tweet, retweet
      //resp = dK === 'tw' ? 'tweet' : 'share'; break;
      resp = dK === 'tw' ? 'retweet' : 'share'; break;
    case 'ln': 
      resp = dK === 'ln' ? 'quote_share' : 'share'; break;
      //resp = 'share'; break;
    case 'ig':
      resp = dK === 'tw' ? 'quote_share' : 'share'; break;
    case 'fb': default://share, quote_share
      resp = 'share'; break;
  }
  /*dK = sK;
  switch(rPK) {
    case 'fb': case 'ln'://share, quote_share
      refDefVal = rEngPtsRef[rPK][rPK]['share']; break;
      //refDefVal = rEngPtsRef ? (
      //  rEngPtsRef[rPK][rPK].hasOwnProperty('share') ?
      //  rEngPtsRef[rPK][rPK]['share'] : DEF_ENG_POINT
      //  ) : '';
      //  break;
    case 'tw'://tweet, quote_tweet, retweet
      refDefVal = rEngPtsRef[rPK][rPK]['retweet']; break;
      break;
  }*/
  return resp;
}
export const fGetSAShareEngTypes = (sK, dK, hasText) => {
  let resp = {};
  //like && comment is common for all
  //disabled checks (with 0 &&) is for engs NA from points Api
  sK=  sK.toLowerCase();
  dK=  dK.toLowerCase();
  resp[sK] = {};
  resp[sK][dK] = {};

  if(sK === 'fb') {
    switch(dK) {
      case 'fb': case 'tw': case 'ln':
        if(hasText) { resp[sK][dK]['quote_share'] = ''; }
        else { resp[sK][dK]['share'] = ''; }
        break;
      case 'wa': case 'em':
        resp[sK][dK]['share'] = '';
        break;
    }
  } else if(sK === 'tw') {
    switch(dK) {
      case 'fb':
        if(0 && hasText) { resp[sK][dK]['quote_share'] = ''; }
        else { resp[sK][dK]['share'] = ''; }
        break;
      case 'tw':
        if(hasText) { resp[sK][dK]['quote_tweet'] = ''; }
        else {
          resp[sK][dK]['tweet'] = '';
          resp[sK][dK]['retweet'] = '';
        }
        break;
      case 'ln':
        if(hasText) { resp[sK][dK]['quote_share'] = ''; }
        else { resp[sK][dK]['share'] = ''; }
        break;
    }
  } else if(sK === 'ln') {
    switch(dK) {
      case 'fb':
        if(0 && hasText) { resp[sK][dK]['quote_share'] = ''; }
        else { resp[sK][dK]['share'] = ''; }
        break;
      case 'tw':
        if(hasText) { resp[sK][dK]['quote_share'] = ''; }
        else { resp[sK][dK]['share'] = ''; }
        break;
      case 'ln':
        if(1 || hasText) { resp[sK][dK]['quote_share'] = ''; }
        //else { resp[sK][dK]['share'] = ''; }
        break;
    }
  } else if(sK === 'ig') {
    switch(dK) {
      case 'fb':
        if(0 && hasText) { resp[sK][dK]['quote_share'] = ''; }
        else { resp[sK][dK]['share'] = ''; }
        break;
      case 'tw':
        if(1 || hasText) { resp[sK][dK]['quote_share'] = ''; }
        //else { resp[sK][dK]['share'] = ''; }
        break;
      case 'ln':
        if(0 && hasText) { resp[sK][dK]['quote_share'] = ''; }
        else { resp[sK][dK]['share'] = ''; }
        break;
    }
  } else if(sK === 'igb') {
    switch(dK) {
      case 'fb':
        if(0 && hasText) { resp[sK][dK]['quote_share'] = ''; }
        else { resp[sK][dK]['share'] = ''; }
        break;
    }
  }
  //let dBugKey = hasText ? 'fSAShareEngTypesRespQ' : 'fSAShareEngTypesResp';
  //dBugKey += '_'+sK+'_'+dK; console.log(dBugKey, resp);
  return resp;
};
export const fGetPostEngPoints = (srcK, ptsInf) => {
  //const srcSMs = ENG_SRC_SM;
  const destSMs = ENG_DEST_SM;
  const resp = {};resp[srcK] = {};

  destSMs.forEach(dSK => {
    const defEngType = fGetDefaultEngType(srcK, dSK);
    if(!resp[srcK].hasOwnProperty(dSK)) {
      resp[srcK][dSK] = {};
    }
    if(!resp[srcK][dSK].hasOwnProperty('engPoints')) {
      resp[srcK][dSK]['engPoints'] = null;
    }

    if(ptsInf && ptsInf.hasOwnProperty(srcK) &&
      ptsInf[srcK].hasOwnProperty(dSK)
    ) {
      if(ptsInf[srcK][dSK].hasOwnProperty(defEngType)) {
        resp[srcK][dSK]['engPoints'] = ptsInf[srcK][dSK][defEngType];
      } else {//attemptToReadFromOtherAttr
        let aAttr;
        switch(defEngType) {
          case 'share': aAttr = 'quote_share'; break;
          case 'quote_share': aAttr = 'share'; break;
          case 'tweet': aAttr = 'retweet'; break;
          case 'retweet': aAttr = 'tweet'; break;
        }
        if(aAttr && ptsInf[srcK][dSK].hasOwnProperty(aAttr)) {
          resp[srcK][dSK]['engPoints'] = ptsInf[srcK][dSK][aAttr];
        } else {
          resp[srcK][dSK]['engPoints'] = DEF_ENG_POINT;
        }
      }
    } else {//postEngPtsNA
      resp[srcK][dSK]['engPoints'] = DEF_ENG_POINT;
    }
  });//console.log('fGetPostEngPointsResp', resp);
  return resp;
};

export const getConnStatDAttr = (sK, aUrl, cStat) => {
  let res;
  if(sK) {
    res = { ttl: null, elCss: "" };
    if(aUrl?.length) {
      switch(cStat) {
        case "Expired":
          res.ttl = "Reconnect";
          break;
        case "Connected":
          res.ttl = "Connected";
          break;
        default: //if(sK=='fb') {res.ttl='Reconnect';}
          res.ttl = "Connect";
          break;
      }
    }
  }
  return res;
};

export const checkAnySMConnected = (sMMeta) => {
	let anySMConnected = false;//NoneConnected
	if(sMMeta) {
	   Object.keys(sMMeta).forEach((sK) => {
		   if(sMMeta[sK]?.authUrl) {
			 anySMConnected = anySMConnected ||
			  (sMMeta[sK]?.activTokenId ? true : false);//connStat
		   }
	   });
	}
	return anySMConnected;
};

export const detectPostType = (sMKey, fData) => {
  //[video, link(withLinkPreview), linkText,
  //	album(withText), textImg, image, text ]
  //const vdoExt = ['mpg', 'mpeg', 'mp4'];
  //const imgExt = ['jpg', 'jpeg', 'jiff', 'png', 'gif', 'webp',];
  let res = null;
  if(sMKey === "yt" ||
    (Array.isArray(fData?.media?.videos) && fData.media.videos.length)
  ) {
    res = "video";
  } else if (fData?.text && isValidUrl(fData?.url)) {
    res = fData.media.pictures[0] ? "linkText" : "link";
  }

  if(!res) {
    if(0) {
      //res = 'album'
      //} else if(fData?.media?.pictures[0]) {
    } else if (
      Array.isArray(fData?.media?.pictures) &&
      fData.media.pictures?.length &&
      fData.media.pictures[0]
    ) {
      res = fData?.text ? "textImg" : "image";
    } else if (fData?.text) {
      res = "text";
    }
  }
  return res;
};

export const fParsePostEngPoints = (srcSMK, pointsData, sMIdKeyMap) => {
  const resp = {};
  if(srcSMK && sMIdKeyMap && pointsData && pointsData.length) {
    resp[srcSMK] = {};
    pointsData.forEach(dPtsInf => {
      const destSNK = sMIdKeyMap[dPtsInf?.snetworkplatform_id];
      if(!resp[srcSMK].hasOwnProperty(destSNK)) {
        resp[srcSMK][destSNK] = {}
      }
      resp[srcSMK][destSNK][dPtsInf?.engagement_type] =
        dPtsInf?.points || DEF_ENG_POINT;
    });//console.log('fParsePostEngPoints', resp);
  }
  return resp;
};

export const prepPostCustomData = ({ feedListMeta, feedData, sNetworkP }) => {
  let userD, profileInfo=feedData?.profileInfo || {};
  //console.log('feedData', feedData);
  //console.log('profileInfo', profileInfo);
  //Also Check media.attachments.type === 'photo'|video|album

  /*if(feedData.id === 'a1R3BYmO_woOaZoME') {
    console.log('fDataPInf_'+feedData.id, {feedData, feedListMeta, sNetworkP});
  }*/

  sNetworkP?.map((networkD) => {
    if(
      networkD?.id === feedData?.snetworkproperty_id &&
      networkD?.snetworkplatform_id === feedData?.snetworkplatform_id
    ) {
      userD = networkD;
    }
  });
  const sMMeta = feedListMeta?.sMediaMgr;
  const sMIdKeyMap = feedListMeta?.sMIdKeyMap;
  //const feedListModifiers = feedListMeta?.feedListModifiers;
  const authUserData = feedListMeta?.authUserData;

  let pText, pSrcDesc, pText2Src, pText2Hd, pText2, pText2Target, pPageName,
    pAlbumSrc, pVideoSrc, points, pMedia = [];
  const postId = feedData?.id;
  const srcSMId = feedData?.snetworkplatform_id;
  const srcKey = sMIdKeyMap?.[srcSMId];
  const postType = detectPostType(srcKey, feedData);

  const srcSMTitle = sMMeta?.srcKey?.title;
  const postDateTime = feedData?.created_at;

  const pUrl = feedData?.url;
  pText = feedData?.text;
  pSrcDesc = feedData?.media?.attachments?.[0]?.[0];
  if(pSrcDesc?.title?.length) { pText2Hd = pSrcDesc.title; }
  if(pSrcDesc?.target?.length) {
    pText2Target = pSrcDesc?.target;
    if(pText2Target.indexOf('u=https%3A%2F%2Fm.youtube.com') > -1 ||
      pText2Target.indexOf('u=https%3A%2F%2Fyoutube.com') > -1
    ) {
      pText2Src = 'YOUTUBE.COM';
    }
  }
  if(pSrcDesc?.description?.length) { pText2 = pSrcDesc.description; }

  const pLeadImg = feedData?.media?.pictures[0];
  let pImages = feedData?.media?.pictures || [];
  if(!pImages || !pImages?.length) {
    //TWPatch
    pImages = feedData?.media?.attachments?.[0]?.images || [];
    if(pImages.length) {
      pImages = [pImages[0]?.url];
    }
  }
  pLeadImg && pMedia?.push(feedData?.media); //.pictures);

  pPageName = sMMeta?.srcKey?.sMTokens?.name?.first;
  +" " + sMMeta?.srcKey?.sMTokens?.name?.last &&
    (pPageName += sMMeta?.srcKey?.sMTokens?.name?.last);
  if(pText && srcKey === 'tw') {
    if(pText.startsWith('RT @')) {
      pPageName = pText.substring(0, pText.indexOf(': ') + 1);
      pPageName = /RT @(.+)/.exec(pPageName)[1];
      pPageName =  pPageName.substring(0, pPageName.length-1);
    }
  }
  //const aUsrName = `${authUserData?.first_name} ${authUserData?.last_name}`;
  const aUsrName = authUserData?.first_name+' '+authUserData?.last_name;
  const aUserPic = authUserData?.medias?.profile?.url;

  // if (postType === 'album' && false) {
  //   pAlbumSrc = ''; //arrImgList
  // } else if (postType === 'video' && false) {
  //   pVideoSrc = ''; //'../../video/niu.mp4'
  // }

  //let engPts = JSON.parse(JSON.stringify(DEFAULT_ENG_POINTS));
  const fPoints = feedData?.points;
  //const postEngPts = {};
  const postEngPts = fParsePostEngPoints(srcKey, feedData?.points, sMIdKeyMap);

  /*if(sMIdKeyMap && sMMeta && fPoints && fPoints.length) {
    fParsePostEngPoints(srcKey, feedData?.points, sMIdKeyMap);
    postEngPts[srcKey] = {};
    fPoints.forEach(dPtsInf => {
      const destSNK = sMIdKeyMap[dPtsInf?.snetworkplatform_id];
      if(!postEngPts[srcKey].hasOwnProperty(destSNK)) {
        postEngPts[srcKey][destSNK] = {}
      }
      postEngPts[srcKey][destSNK][dPtsInf?.engagement_type] =
        dPtsInf?.points || DEF_ENG_POINT;
      //if(destSNK === 'igb' || destSNK === 'igp') {
      //  destSNK = 'ig';
      //}
      //if(engPts.hasOwnProperty(destSNK)) {
      //  switch(dPtsInf?.engagement_type) {
      //    case 'share':
      //      engPts[destSNK]['share'] = dPtsInf?.points;
      //      break;
      //    case 'quote_share':
      //      engPts[destSNK]['qShare'] = dPtsInf?.points;
      //      break;
      //    case 'comment':
      //      engPts[destSNK]['comment'] = dPtsInf?.points;
      //      break;
      //    case 'like':
      //      engPts[destSNK]['like'] = dPtsInf?.points;
      //      break;
      //    default:
      //      engPts[destSNK][dPtsInf?.engagement_type] = dPtsInf?.points;
      //  }
      //}
    });//console.log('postEngPts_'+feedData.id, postEngPts);
  }*/

  return {
    postId: postId,
    srcSMId: srcSMId,
    srcKey: srcKey,
	  isUserFeed: feedData?.isUserFeed ?? false,
    postType: postType,
    srcSMTitle: srcSMTitle,
    postDateTime: postDateTime,
    userD,
    pImages,
    pUrl: pUrl,
    pText: pText, pText2Src, pText2Hd, pText2, pText2Target,
    pLeadImg: pLeadImg || null,
    pPageName: pPageName,
    pAlbumSrc: pAlbumSrc,
    pVideoSrc: pVideoSrc,
    aUsrName: aUsrName,
    aUserPic: aUserPic,
    profileInfo: feedData?.profileInfo,
    //engPoints: engPts,
    engPoints: postEngPts,
    //xApi: feedData,
  };
};

export const prepNameComponent = (str1, str2) => {
  //fName > 20Char NotAllowed
  const nameComp = {'fName': null, 'lName': null};
  let fN, lN;
  str1 = (str1 || '').trim(); str2 = (str2 || '').trim();
  let nStr = str1.length ? str1+' '+str2 : str2;
  const arrStr = nStr.trim().split(/(\s+)/).
    filter( function(s) { return s.trim().length > 0; } );

  if(arrStr.length) {
    fN = arrStr.shift();
    if(fN.length > 20) {
      fN = fN.substring(0, 20);
      lN = fN.substring(21, 20) + ' ' + arrStr.join(' ');
    } else { lN = arrStr.join(' '); }
  }
  fN = fN || 'NIU';
  lN = lN || (fN === 'NIU' ? 'User' : 'Socio');
  lN = lN.substring(0, 20);
  nameComp.fName = fN;
  nameComp.lName = lN;
  return nameComp;
};
export const fTrimSlashes = (uriPath) => {
  return uriPath.replace(/^\/|\/$/g, '');
};
export const fLTrimChar = (origString, charToTrim) => {
  charToTrim = fEscapeRegExp(charToTrim);
  const regEx = new RegExp('^[' + charToTrim + ']+|[' + charToTrim + ']+$', 'g');
  return origString?.length ? origString.replace(regEx, '') : origString;
};
export const fRTrimChar = (origString, charToTrim) => {
  charToTrim = fEscapeRegExp(charToTrim);
  //  /~+$/
  const regEx = new RegExp('[' + charToTrim + ']+$', 'g');
  return origString?.length ? origString.replace(regEx, '') : origString;
};
export const fStrHashTagsToArray = (strCsv) => {
  let resp;
  if(strCsv.length) {
    let arrTag = strCsv.split(',');
    if(!arrTag.length) { strCsv.split("\n"); }
    if(arrTag.length) {//remBlank
      //arrTag = arrTag.filter(i => i);
      arrTag = arrTag.filter(i => {
        i = i.trim().replace(' ', '-');
        if(i != null && i !== false && i !== '') {
          return '#'+fLTrimChar(i, '#');
        } return null;
      });//console.log('arrTagClean', arrTag);
      if(arrTag.length) {
        arrTag = arrTag.map(i=>'#'+fLTrimChar(i.trim(), '#'));
        resp = arrTag;
      }
    }
  }//console.log('resp', resp);
  return resp;
};
export const fGetArrayHashToCSString = (arrHash) => {
  let resp = '';
  if(arrHash && arrHash.length) {
    //resp = arrHash.toString().replace(',', ', ').replace(' #', ' ');
    resp = arrHash.map(s=>fLTrimChar(s, '#')).toString().
      replace(',', ', ') + ', ';
  }
  return resp;
};
export const fGetSlugToTitle = strS => {
  return strS.split('/').shift().split('-').map(wd =>
    wd.charAt(0).toUpperCase() + wd.slice(1).
    toLowerCase()).join(' ');
};
export function fFormatNumberToLocaleString(number) {
  return parseInt(number).toLocaleString('en-US');
}
export const fGetNumAbbr = (num, decPrecision=2) => {
  //decPrecision = decPrecision ?? 2;
  let res, isMinus = false;
  if(num < 0) { isMinus = true; }
  num = Math.abs(num);
  const lookup = [
    { value: 1, symbol: '' },    //{ value: 1e0, symbol: 'C' },
    { value: 1e3, symbol: 'k' }, { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' }, { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },{ value: 1e18, symbol: 'E' }
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup.slice().reverse().find((item) => {
    return num >= item.value;
  });
  res = item ?
    (num / item.value).toFixed(decPrecision).replace(rx, "$1") + item.symbol :
    '0';
  if(isMinus) { res = '-' + res; }
  return res;
};
export const fGetNumAbbr2 = (num) => {
  let res, isMinus = false;
  if(num < 0) { isMinus = true; }
  num = Math.abs(num);
  if(num >= 1000000000) {
    res = (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G';
  } else if (num >= 1000000) {
    res =  (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
  } else if (num >= 1000) {
    res = (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
  } else if(num >= 100) {
    res = (num / 100).toFixed(1).replace(/\.0$/, '') + 'C';
  } else {
    res = num;
  }   
  if(isMinus) { res = '-' + res; }
  return res;

  /*return Math.abs(num) > 999 ?
    Math.sign(num)*((Math.abs(num)/1000).toFixed(1)) + 'k' :
    Math.sign(num)*Math.abs(num);*/
};
export const fEscapeRegExp = (strToEscape) => {
  return strToEscape.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
};
export const fGetStrAbbr = (str) => {
  return str.trim().split(' ').map((nC) => nC.charAt(0)).join('').toUpperCase();
  //return str.trim().split(/\s/). //OR .split(/(\s|^)([a-z])/gi).
  //  reduce((res, wd)=> res+=wd.slice(0, 1), '').toUpperCase();
};

export const fGetStrSlug = str => {
  return str.toLowerCase().trim().
    replace(/[^\w\s-]/g, '').
    replace(/[\s_-]+/g, '-').
    replace(/^-+|-+$/g, '');
}
export const fGetUrlFileName = (url) => {
  //new URL(url).pathname.split('/').pop();
  return url.split('#')[0].split('?')[0].split('/').pop();
};
export const fStrToColor = (str) => {
  let i, hash = 0, color = '#';

  for(i = 0; i < str.length; i += 1) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  for(i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  return color;
};
export const fStrToTitleCase = (str) => {
  return str.split(' ').map(wd =>
    wd.charAt(0).toUpperCase() + wd.slice(1).
    toLowerCase()).join(' ');
  //return str.replace( /\w\S*/g, (tx) => 
  //  tx.charAt(0).toUpperCase() + tx.slice(1).toLowerCase()
  //);
};
export const fGetTrimmedText = (txt, maxLen=30) => {//txt=''
  return txt?.length > maxLen ? txt.substr(0, maxLen-2) + '..' : txt
};
export const fNormalizeTextContent = (pContent, flgShowAll=false, maxDispChar=280) => {
  let res = pContent;//maxDispChar = (isUserList ? 50 : 280)
  if(!flgShowAll && pContent?.length > maxDispChar) {
    res = pContent.substr(0, maxDispChar) + '...';
  }
  var regMatch =
    /(\b(https?|):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
  var resHtml = res?.replace(regMatch, "<a href='$1' target='_blank'>$1</a>");
  var newRegMatch = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
  res = resHtml?.replace(newRegMatch,
    '$1<a	target="_blank" href="http://$2">$2</a>'
  );
  return res;
};

export const fGetRandomInt = (min=1, max=100) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};
export const fGetRandomStr = (sLen) => {
  //return Math.random().toString(36).slice(-sLen);
  return (+new Date()).toString(36).slice(-sLen);
 }
export const fGetRandomImgUrl = () => {
  let iW = fGetRandomInt(100, 800), iH=fGetRandomInt(100, 800);
  const iSrc = [
    'https://source.unsplash.com/random/?nature',
    'https://source.unsplash.com/random/?wildlife',
    'https://source.unsplash.com/random/?music',
    'https://source.unsplash.com/random/?user',
    'https://source.unsplash.com/random/?fractal',
    'https://picsum.photos/'+iW+'/'+iH,
    'https://picsum.photos/'+iW+'/'+iH+'/?nocache='+(Date.now()),
    'https://dummyimage.com/'+iW+'/09f/fff.jpg',
    'https://dummyimage.com/'+iW+'/09f/fff.jpg?text='+fGetRandomStr(4),
  ];
  return iSrc[fGetRandomInt(0, iSrc.length-1)];  
}

export const fLeadDebounce = (func, delay=300) => {
  //FirstCall &thenIgnorefurtherclicks
  let timer;
  return (...args) => {
    if(!timer) { func.apply(this, args); }
    clearTimeout(timer);
    timer = setTimeout(() => { timer = undefined; }, delay);
  };
};

export const fWaitPlease = (ms) => {
  return new Promise((resolve, reject) => setTimeout(resolve, ms));
  //EgCallFromWithin An Async someFx:
  //const someFx = async () => { const wait2Sec = await fWaitPlease(2000) }
};

export const fMoveElementToFirst = (hay, needle, needleKey=null) => {
  if(needleKey) {
    hay.some((el, idx) => 
      el[needleKey] === needle && hay.unshift(hay.splice(idx,1)[0]) 
    )
  } else {
    const idx = hay.findIndex(el => el === needle);
    hay.splice(idx, 1); hay.unshift(needle);
  }
};
export const fCheckJsonEmpty = (jObj) => {
  //return _.isEmpty(jObj);
  return jObj && Object.keys(jObj).length === 0 && jObj.constructor === Object;
};
export const compareJsonObj = function (obj1, obj2) {
  const obj1Keys = Object.keys(obj1);
  const obj2Keys = Object.keys(obj2);

  if(obj1Keys.length !== obj2Keys.length) { return false; }

  for(let objKey of obj1Keys) {
    if(obj1[objKey] !== obj2[objKey]) {
      if(typeof obj1[objKey] == "object" && typeof obj2[objKey] == "object") {
        if(!compareJsonObj(obj1[objKey], obj2[objKey])) {
          return false;
        }
      } else {
        return false;
      }
    }
  }
  return true;
};
export const fCampareArrayElement = (arr1, arr2) => {
  return arr1 && arr2 && arr1.length === arr2.length ?
    //JSON.stringify(arr1) === JSON.stringify(arr2) :
    arr1.toString() === arr2.toString() :
    false;
};
export const downloadImage = async (feed, ind) => {
  const response = await fetch(feed);
  const blob = await response.blob();
  const dataurl = await new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
  const arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = window.atob(arr[1]);
  let n = bstr.length;
  let u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  const result = new File([u8arr], Math.round(Math.random() * 10000000000000), {
    type: mime,
  });
  return result;
  // saveAs(feed, `${ind}.jpg`);
};

export const fOpenNewAppTab = async(url, arrHd) => {
	fetch(url, arrHd)
	.then((response) => response.blob())
	.then((blob) => {
		const _url = window.URL.createObjectURL(blob);
		window.open(_url, "_blank").focus();
	}).catch((err) => {
		console.log(err);
	});
};

const fDescendingComparator = (a, b, orderBy) => {
  if(b[orderBy] < a[orderBy]) { return -1; }
  if(b[orderBy] > a[orderBy]) { return 1; }
  return 0;
}

export const fGetComparator = (orderBy, order) => {
  return order === 'desc'
    ? (a, b) => fDescendingComparator(a, b, orderBy)
    : (a, b) => -fDescendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
export const fStableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if(order !== 0) { return order; }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
export const fArrUnique = (arr) => {//1DPlainArray
  //return new Set(arr);
  //return Array.from(new Set(arr));
  return [...new Set(arr)];
};
export const fRemoveDuplicateRows = (arrObj, keyCol='id') => {
  let resp = [];
  if(keyCol && arrObj.length) {
    //A
    let objKeys = {};
    for(let i in arrObj) {
      const cKey = arrObj[i][keyCol];
      objKeys[cKey] = arrObj[i];
    }
    for(let i in objKeys) {
      resp.push(objKeys[i]);
    }

    /*//B
    //const ids = arrObj.map(({ keyCol }) => objR[keyCol]);
    const ids = arrObj.map(objR => objR[keyCol]);
    resp = arrObj.filter((objR, index) =>
      !ids.includes(objR[keyCol], index + 1));

    //C
    const objJson = arrObj.map(JSON.stringify);
    const uniqueSet = new Set(objJson);
    resp = Array.from(uniqueSet).map(JSON.parse);*/
  } else {
    resp = arrObj;
  }
  return resp;
};
export const swapKeyVal = (jObj) => {
  /*return Object.keys(jObj).reduce((obj, key) => {
      obj[jObj[key]] = key;
      return obj;
  }, {});*/
  return Object.fromEntries(Object.entries(jObj).map(([k, v]) => [v, k]))
};
export const fGetRefDates = () => {
  //const sADateRefs = fGetRefDates();//tzConv?
  //const objMoment = moment(strDate, sADateRefs.sADispFormat);
  //const strReformDt = moment(strDate).fromNow()
  //moment.updateLocale('en', {}).calendar();
  const sADispFormat = 'MMM DD YYYY, hh:mm A';
  const dTNow = moment();
  const dtFormatApi = 'YYYY-MM-DD';
  return {
    momentDateTimeDispFormat: sADispFormat,
    momentDateDispFormat: SA_DATE_DISP_FORM,
    dateFormatApi: dtFormatApi,
    timeStamp: dTNow.unix(),
    //timeNow: dTNow.getTime(),
    timeNow: dTNow.format('HH:mm'),
    dayToday: dTNow.format('dddd'),
    dateToday: dTNow.format(dtFormatApi),
    //dateYesterday: dTNow.add(-1, 'days'),
    dateYesterday: dTNow.clone().subtract(1, 'days').format(dtFormatApi),
    dateTomorrow: dTNow.clone().add(1, 'days').format(dtFormatApi),
    dateAWeekAgo: dTNow.clone().subtract(6, 'days').format(dtFormatApi),
    dateAWeekAhead: dTNow.clone().add(6, 'days').format(dtFormatApi),
    dateWeekStart: dTNow.startOf('week').format(dtFormatApi),
    dateWeekEnd: dTNow.endOf('week').format(dtFormatApi),//.endOf('day')
    dateAMonthAgo: dTNow.clone().subtract(1, 'month').format(dtFormatApi),
    //dateAMonthAgo: dTNow.clone().subtract(30, 'days').format(dtFormatApi),
  };
};
export const fConvertDispDateToDbDate = (strD) => {
  return moment(strD).format('YYYY-MM-DD');
}