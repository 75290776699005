import { useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm, //Controller, FormProvider, useFormContext
} from "react-hook-form";
import * as yup from "yup";
//import { yupResolver } from "@hookform/resolvers/yup";
//import { AdmRewardPointsSchema } from "schema";
//import { Unstable_NumberInput as NumberInput } from '@mui/base/Unstable_NumberInput';
//import PropTypes from "prop-types";
//import moment from "moment";
import { Box, TextField, Button, } from "@mui/material";
import { FormProvider, SpinnerBox, Spinner2, Flasher,
  PageMessage, Typography,
} from 'core';
import { SA_ENV, ENG_SRC_SM, ENG_DEST_SM,
  SA_DEF_ORG_GROUP_ID, DEF_ENG_POINT
} from 'config/app.config';
import { socialMedia, getSMediaIcons } from 'config/ui.config';
import { fGetSAShareEngTypes, fGetDefaultEngType,
  fArrUnique, fWaitPlease,
} from 'lib/appFx';
import InitEngPts from './InitEngPts';
import { getSMediaMgr } from 'services/sMediaApi';
import { rGetRefEngPoints, rSetSAEngPointsDataMult,
  //useFeedEngPointsMultQuery, rInitSAEngPoints,
} from 'services/gameApi';
import { useEngagementPointUpdateMutation, //rSetSAEngPointsDataMult,
} from 'services/admin/admGamingApi';
//---------------------------------------------------------

const ctnInputSx = {
  display:'flex', flexWrap:'wrap', justifyContent: 'left',//'space-between'
  alignItems: 'center', margin: '1.5rem 0'
};
const ctnInLabelSx = {
  display: 'inline-flex', //flex: '1 0 auto', width: '100%',
  flexWrap: 'wrap', marginBottom: '0.5rem',
  justifyContent: 'left', alignItems: 'center',
  fontSize: '1.1rem', whiteSpace: 'nowrap',
  width: {xs: '98%', sm: '45%'}
};
//const inputTextSx = { width: '97%', "& fieldset": { border: 'none' }};
//---------------------------------------------------------

export default function AdmRewardPointsForm() {
  const fDispatchEngPoints = useDispatch();
  const theSMData = useSelector(getSMediaMgr);
  //const theSMKeyMap = useSelector(getSMediaKeyIdMap);
  const rEngPtsRef = useSelector(rGetRefEngPoints);
  //console.log ({rEngPtsRef, theSMData});//, theSMKeyMap});

  //const { refetch: fRefetchEngPoints, } = useFeedEngPointsMultQuery();

  const [
    fSetEngPoints
    //, { data: setEngPtsResp, isLoading: isRPtsUpdating, reset: fResetRPt }
  ] = useEngagementPointUpdateMutation();//{fixedCacheKey: 'setEngPtsMutation'});

  const [stAjaxStep, setStAjaxStep] = useState(0);//-1:Err, 0: Nop, 1:wait, 2: OK
  const [stPgMsg, setStPgMsg] = useState('');
  const fRestoreAjax = () => {
    setStPgMsg(''); setStAjaxStep(0); //modalArgs.onDismiss();
  };

  const refSrcSM = ENG_SRC_SM;
  const refDestSM = ENG_DEST_SM;
  const maxRPts = 1500;
  const inElPrefix = 'txRP_';
  const defRPVals = {};
  const objYupSchema = {};
  refDestSM.forEach((rPK)=> {
    objYupSchema[inElPrefix + rPK] = yup.number().positive().
      integer().min(0).max(maxRPts).required();
      //.pattern(//)
  });//console.log('objYupSchema', objYupSchema);
  //const rPtsSchema = AdmRewardPointsSchema;
  //const rPtsSchema = yup.object().shape(objYupSchema);
  //const rPtsSchema = yup.object(objYupSchema).required();
  const frmRPtsMethods = useForm({
    //resolver: yupResolver(rPtsSchema),
    defaultValues: defRPVals,//async()=>fetch('apiUrl');
    //values,
    //resetOptions: { keepDirtyValues: true, keepErrors: true, }
    //shouldUseNativeValidation: true,
  });
  const {//handleSubmit, control, setValue, watch, resetField, clearErrors,
    register, getValues, reset: frmReset, formState:{errors: frmErrs},
  } = frmRPtsMethods;
  //---------------------------------------------------------

  const fResetRPtsForm = () => { frmReset(); };
  const fSaveRPts = useCallback(async () => {
    const inData = getValues();//e.preventDefault();
    setStAjaxStep(1);
    //setStPgMsg('Please wait. It may take a while..');
    const aRespAll={}, errEngs={}, fDBug=(SA_ENV === 'local');
    //fDBug && 
    console.log('fSaveRPtsSubmittt', {inData, rEngPtsRef});
    let upOKGT = 0, upErrGT = 0;

    refSrcSM.forEach(async(srcSMKey)=> {
      const srcSMId = theSMData[srcSMKey]['sMId'];

      Object.keys(inData).forEach(async (rPKIn) => {
        const newEngPts = parseInt(inData[rPKIn]);
        const destSMKey = rPKIn.replace(inElPrefix, '');
        const destSMId = theSMData[destSMKey]['sMId'];

        const sAShareEngTypes = fGetSAShareEngTypes(srcSMKey, destSMKey, false);
        const sAQShareEngTypes = fGetSAShareEngTypes(srcSMKey, destSMKey, true);
        const sASharableEngs = fArrUnique(
          Object.keys(sAShareEngTypes[srcSMKey][destSMKey]).
          concat(Object.keys(sAQShareEngTypes[srcSMKey][destSMKey]))
        );
        //const defRefEngType = fGetDefaultEngType(srcSMKey, destSMKey);
        fDBug && console.log('sAShareETypeProbes_'+srcSMKey+'_'+destSMKey, {
          //sAShareEngTypes, sAQShareEngTypes,
          sASharableEngs, newEngPts//, defRefEngType
        });

        if(sASharableEngs.length) {
          sASharableEngs.forEach(async(eT) => {
            const inParams = {
              source_snetworkplatform_id: srcSMId,
              destination_snetworkplatform_id: destSMId,
              group_id: SA_DEF_ORG_GROUP_ID,//?
              //engagement_type: '',//engType,
              points: newEngPts,
            };
            const currEngPts = (
              rEngPtsRef && rEngPtsRef.hasOwnProperty(srcSMKey) &&
              rEngPtsRef[srcSMKey].hasOwnProperty(destSMKey) &&
              rEngPtsRef[srcSMKey][destSMKey].hasOwnProperty(eT)
            ) ?
              parseInt(rEngPtsRef[srcSMKey][destSMKey][eT]) :
              DEF_ENG_POINT;
            fDBug && console.log(srcSMKey+'_'+destSMKey+'_'+eT, {
              currEngPts, newEngPts
            });

            if(currEngPts !== newEngPts) {
              inParams.engagement_type = eT;

              if(!aRespAll.hasOwnProperty(srcSMKey)) { aRespAll[srcSMKey] = {}; }
              if(!aRespAll[srcSMKey].hasOwnProperty(destSMKey)) {
                aRespAll[srcSMKey][destSMKey] = {};
              }
              if(!aRespAll[srcSMKey][destSMKey].hasOwnProperty(eT)) {
                aRespAll[srcSMKey][destSMKey][eT] = {};
              }
              aRespAll[srcSMKey][destSMKey][eT]['currEngPoints'] = currEngPts;
              aRespAll[srcSMKey][destSMKey][eT]['newEngPoints'] = newEngPts;

              if(10) {
                try {//throw new Error();
                  let aResp;
                  if(0) {//if(fDBug) {
                    fWaitPlease(200);
                    aResp = {result: {success: true}};
                    //const aResp = {result: {success: false}};
                  } else {
                    aResp = await fSetEngPoints(inParams).unwrap();
                  }//console.log('aResp', aResp);                  
    
                  if(aResp?.result?.success) {
                    aRespAll[srcSMKey][destSMKey][eT]['resp'] = true;
                    aRespAll[srcSMKey][destSMKey][eT]['payload'] = inParams;
                    upOKGT++;
                  } else if(aResp?.error) {
                    aRespAll[srcSMKey][destSMKey][eT]['resp'] = false;
                  } else {
                    aRespAll[srcSMKey][destSMKey][eT]['resp'] = '?';
                  }
                } catch(err) {
                  upErrGT++;
                  aRespAll[srcSMKey][destSMKey][eT]['respEr'] = err;
                  //console.log('ErrSetRPts', {srcSMKey, srcSMId, destSMKey,
                  //  destSMId, eT, currEngPts, newEngPts});
                  //setStAjaxStep(-1);
                  //console.error(err, 'Error setting rewardPoints');
                }
              }//EIfTrue
            }//EIfNew!==Curr
            else {
              if (currEngPts === newEngPts) {//NoUpdateRequired
              } else {
                if(!errEngs.hasOwnProperty(srcSMKey)) {errEngs[srcSMKey] = {}; }
                if(!errEngs[srcSMKey].hasOwnProperty(destSMKey)) {
                  errEngs[srcSMKey][destSMKey] = {};
                }
                if(!errEngs[srcSMKey][destSMKey].hasOwnProperty(eT)) {
                  errEngs[srcSMKey][destSMKey][eT] = {};
                }
                errEngs[srcSMKey][destSMKey][eT]['eTErr'] = {
                  sASharableEngs, eT, currEngPts, newEngPts
                };
              }
            }
          });//E4*SharableET
        }
      });//E4*InFlds
      //console.log('================\n');
    });//E4*Src

    //WaitAWhile(7Sec)SoThatAllAsyncApiCallsFinishes..
    //TheIdealSolutionWouldHaveBeenUsing Promise.all();
    setTimeout(() => {
      //fDBug &&
      console.log('aRespUp', {upOKGT, upErrGT, aRespAll, errEngs});
      if(upOKGT > 0) {//setStPgMsg('rPtsUpOK');
        setStAjaxStep(2);
        fDispatchEngPoints(rSetSAEngPointsDataMult(aRespAll));
        setStPgMsg('Reward points updated successfully');
      } else if(Object.keys(aRespAll).length === 0) {
        setStPgMsg('No reward points are changed by you');
      } else { //if(upErrGT)setStPgMsg('rPtsUpErr');
        setStAjaxStep(-1);
        setStPgMsg('Error updating reward points.' + 
          "\nPlease try again later.."
        );
      }
      setTimeout(fRestoreAjax, 3000);
    }, 7000);
    //return false;
  }, [rEngPtsRef, refSrcSM]);

  /*const [fSetTest] = useEngagementPointUpdateMutation();
  const fSaveRPtsTest = async() => {
    const inParams = {
      source_snetworkplatform_id: 'BX4vPzmo', engagement_type: 'share',
      destination_snetworkplatform_id: 'a1R3BYmO', points: 115,
    }; const aResp = await fSetTest(inParams).unwrap();
    console.log('aResp', aResp);
  }*/
  /*const fSaveRPtsHandler = (inData) => {
    //let formData = new FormData();
    //console.log('fSaveRPtsSubmit', getValues());
    fSaveRPts(getValues());//fSaveRPts(inData);
    return false;
  };*/
  //---------------------------------------------------------

  let inElName, refDefVal, rPtsItems = [];
  //console.log('rEngPtsRef', rEngPtsRef);
  //rEngPtsRef && theSMData && refDestSM.forEach( rPK => {
  rEngPtsRef && refDestSM.forEach( rPK => {
    const rPItemTitle = theSMData && theSMData.hasOwnProperty[rPK] ?
      theSMData[rPK]['title'] : '';
    const sMIcon = getSMediaIcons(rPK, 'initCap');//socialMedia[rPK]['title'];
    //const sMIcon = socialMedia[[rPK]['title']];
    //const sMIcon = socialMedia.NewIcons(rPK+'New', true);//, 'initCap');//socialMedia[rPK]['title'];
    inElName = inElPrefix + rPK;
    const defRefEngType = fGetDefaultEngType(rPK, rPK);
    refDefVal = rEngPtsRef[rPK][rPK][defRefEngType];

    rPtsItems.push(//<NumberField
      <Box sx={ctnInputSx} key={'rPtsIn_'+rPK}>
        <Box className='xdInFNoW xbdr1' sx={ctnInLabelSx}>
          {sMIcon}<Box ml={1.5}>Share On { rPItemTitle }</Box>
        </Box>
        <Box className='xbdr1'
          sx={{width: {xs: '98%', md: '54%'}, height: '2.5rem'}}
        >
          {/*<NumberInput id value max min step required*/}
          <TextField type="number"
            id={inElName} //name={inElName}
            {
              ...register(inElName, {
                required: 'Reward point value for '+rPItemTitle+' is required',
                min: 0, max: maxRPts,
                //minLength: {value: 1, message: 'mustMinLenErr'},
                //maxLength: 12, pattern: /^[A-Za-z]+$/i
              })
            }
            defaultValue={refDefVal} //value={refDefVal} //onChange={fSetRPtVal}
            required={true} fullWidth={true}
            aria-invalid={frmErrs[inElName] ? true : false}
            variant="filled" size='small'
            label="Reward Points" //placeHolder={}
            InputLabelProps={{ shrink: true, }}
            InputProps={{ inputProps: {
              type: 'number', step: 5, min: 0, max: maxRPts, maxLength:5 }
            }}
          />
          <p>{frmErrs[inElName]?.message}</p>
        </Box>
      </Box>
    );
  });
  //---------------------------------------------------------

  return (
    rPtsItems.length ? (
      //<form onSubmit={handleSubmit(fSaveRPts)}>
      //<FormProvider methods={frmRPtsMethods} onSubmit={handleSubmit(fSaveRPtsHandler)} disabled>
      <FormProvider methods={frmRPtsMethods} disabled>
        <Box id="ctnFrmAdmRP">
          <Box className="xtBdr2" //component="form" noValidate autoComplete="off"
            sx={{width: {xs:'100%', md:'55%'},
            //'& .MuiTextField-root': { m: 1, width: '25ch' },
            }}
          >
            {rPtsItems}
            {stPgMsg && (
              <Flasher delay={3000} resetFunction={fRestoreAjax}>
                <PageMessage text={stPgMsg} isError={stAjaxStep === -1} />
              </Flasher>
            )}
          </Box><br/>
          <Box>
            <Button variant="text" className="cBtn cBtnWh" sx={{marginRight: '1rem'}}
              onClick={fResetRPtsForm}
            >Discard</Button>
            <Button variant="text" type="button" className="cBtn cBtnDark"
              disabled={stAjaxStep !== 0 || !rEngPtsRef}
              onClick={fSaveRPts} //onClick={fSaveRPtsTest}
            >
              {stAjaxStep === 1 ? <Spinner2 /> : 'Save'}
            </Button>
          </Box><br/>
        </Box>
      </FormProvider>
      //</form>
    ) : <><InitEngPts /><SpinnerBox /></>
  );
};
