import { Grid, styled, useTheme } from '@mui/material';
import { PageContainer } from 'core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { BonusPoints, TopScorers, YourBadges } from 'sections';
//import ProfileInfoCard from 'sections/home/ProfileInfoCard';
//import SMediaWidget from 'sections/widgets/sMediaWidget/SMediaWidget';
import LeaderBoardContent from 'sections/leaderboard/LeaderBoardContent';
import { selectAuthUserId } from 'services/auth/authSlice';
import { useUserDetailsByIdQuery } from 'services/userApi';

const StyledGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

export default function LeaderBoard({ title }) {
  const userAuthId = useSelector(selectAuthUserId);
  const { t: home } = useTranslation("home")
  //const { data: userData } = useUserDetailsByIdQuery({ userId: userAuthId });
  const theme = useTheme();

  return (
    <PageContainer title={title}>
      <Grid container spacing={2}>
        {/*<Grid item sm={12} md={4} lg={3}>
          <Grid container spacing={2}>           
            <Grid>
            </Grid>
            <Grid item xs={12} sx={{ marginTop:"0px" }} >
              <YourBadges theme={theme} network_size="20569" />
            </Grid>
          </Grid>
        </Grid>*/}

        {/* CENTER GRID */}
        {/*<StyledGrid item sm={12} md={7.5} lg={6.5}>*/}
        <StyledGrid item sm={12} md={12} lg={12}>
          <Grid container spacing={2} textAlign="center">
            {/* <Grid item xs={12}>
              <TopScorers theme={theme} title="Top Scorers" />
            </Grid>
            <Grid item xs={12} >
              <BonusPoints theme={theme} title="Bonus Coins" />
            </Grid> */}
            <Grid item xs={12} sx={{ paddingTop: '27px' }}>
              <LeaderBoardContent
                theme={theme}
                sx={{ paddingTop: '0px' }}
                title={`${home("leaderboard")}`}
              />
            </Grid>
          </Grid>
        </StyledGrid>
      </Grid>
    </PageContainer>
  );
}
