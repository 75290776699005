import { Button, Tooltip, Typography } from 'core';
import { Stack } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useSelector } from 'react-redux';
import { getFeedListMetaData, getOrgGroupsData } from 'services/orgFeedApi';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
//---------------------------------------------------------

export default function SocialMediaShareTooltip({ children, quoteButtonHandler, handleFacebookFeed, handleCreateFeedForm, fFeedActionHandler, destKey, pInf, smKey, setMessage, feedData }) {
  const feedListMeta = useSelector(getFeedListMetaData); //, shallowEqual);
  const [open, setOpen] = useState(false);

  const closeTooltTip = () => {
    setOpen(false);
  };

  const openToolTip = () => {
    setOpen(true);
  };

  const isDestTw = destKey === 'tw';
  const { t: home } = useTranslation('home');

  const handleTooltip = () => {
    setOpen(!open);
  };

  let engPts; //console.log('pInfShTooltip '+destKey, pInf);
  if (pInf.engPoints) {
    switch (destKey) {
      case 'fb':
        engPts = pInf?.engPoints?.fb?.share;
        break;
      case 'tw':
      case 'ln':
        engPts = pInf?.engPoints[destKey]?.qShare;
        break;
      default:
        engPts = pInf?.engPoints[destKey]['share'];
        break;
    }
  }

  // Function to handle the sharind logic
  const handleShare = () => {
    let params = {
      pInf: pInf, // Pass the post data to the share function
      postId: pInf?.postId,
      actType: 'share',
      destSMKey: destKey,
      feedListMeta,
      feedData,
    };
    params['pUrl'] = pInf?.pUrl;

    // Call the handler (you can modify this function to handle the share logic)
    fFeedActionHandler(
      params,
      () => {},
      (err) => {
        setMessage(err);
      }
    );

    closeTooltTip();
  };

  return (
    <Tooltip
      placement="top" //arrow
      open={open}
      onOpen={openToolTip}
      onClose={closeTooltTip}
      onClick={handleTooltip}
      title={
        <>
          <Stack backgroundColor="black">
            <Typography
              component={Button}
              sx={{
                backgroundColor: '#000',
                color: (theme) => theme.palette.background.paper,
                textTransform: 'capitalize',
                textAlign: 'left',
                padding: '7px 15px ',
                cursor: 'pointer',
                '&:hover': { backgroundColor: '#000' },
              }}
            >
              {engPts} {home('coin(s)')}
            </Typography>
          </Stack>

          {(pInf?.srcKey === 'tw' || destKey !== 'tw') && destKey !== 'ln' ? (
            <Typography
              component={Button}
              onClick={handleShare}
              sx={{
                textDecoration: 'none',
                backgroundColor: 'white',
                color: grey[700],
                textTransform: 'capitalize',
                textAlign: 'left',
                padding: '7px 15px ',
                cursor: 'pointer',
                // borderLeft: `2px solid ${grey[400]}`,
                // borderRight: `2px solid ${grey[400]}`,
                borderBottom: `1px solid ${grey[200]}`,
                '&:hover': { backgroundColor: 'white' },
              }}
            >
              {isDestTw ? home('retweet') : home('share')}
            </Typography>
          ) : null}

          {destKey === 'ln' || destKey === 'tw' ? (
            <Stack
              direction="row"
              alignItems="center"
              component={Button}
              onClick={() => {
                handleCreateFeedForm(destKey);
                closeTooltTip();
              }}
              sx={{
                // backgroundColor: pInf?.srcKey === "tw" ? grey[400] : "white",
                // color: pInf?.srcKey === "tw" ? "white" : grey[600],
                backgroundColor: 'white',
                color: grey[700],
                textTransform: 'capitalize',
                textAlign: 'left',
                padding: '7px 15px ',
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: pInf?.srcKey === 'tw' ? grey[400] : 'white',
                },
              }}
            >
              <Typography
                sx={{
                  color: grey[700],
                }}
              >
                {pInf?.srcKey === 'tw' ? home('quote_tweet') : home('quote_share')}
              </Typography>
              {/* {!isDestTw ? <ShareIcon sx={{ padding: '0 3px ' }} /> : null} */}
            </Stack>
          ) : null}
        </>
      }
    >
      {children}
    </Tooltip>
  );
}
