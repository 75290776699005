import { Box } from "@mui/material";
import React from "react";

import PropTypes from "prop-types";

const LOGO_SIZES = {//NewLogo w:H: = 4:1
  xsmall: {
    //url: "/assets/images/globalLogo.png",
    url: "/assets/images/niuHealthLogo.svg",
    //style: { width: "2rem", height: "2rem" },
    style: { width: "4rem", height: "2rem" },
  },
  small: {
    url: "/assets/images/niuHealthLogo.svg",
    //style: { width: "4rem", height: "4rem" },
    //style: { maxHeight: "100px", height: "auto", width: "100%", maxWidth: "170px" },
    //style: { width: "11rem", height: "2.7rem" },
    //style: { width: "170px", height: "43px", maxWidth: '170px' },
    style: { height: "43px", width: "auto",  maxWidth: 'auto' },
  },
  large: {
    url: "/assets/images/niuHealthLogo.svg",
    //style: { width: "5rem", height: "5rem" },
    //style: { width: "20rem", height: "5rem" },
    style: { height: "5rem", width: "auto",  },
  },
  xlarge: {
    url: "/assets/images/niuHealthLogo.svg",
    style: { height: "7rem", width: "auto" },
    //style: { width: "7rem", height: "7rem" },
    //style: { width: "28rem", height: "7rem" },
  },
  larger: {
    url: "/assets/images/niuHealthLogo.svg",
    //style: { width: "10rem", height: "10rem" },
    //style: { width: "40rem", height: "10rem" },
    style: { height: "10rem", width: "auto" },
  },
  Xlarger: {
    url: "/assets/images/niuHealthLogo.svg",
    //style: { width: "12rem", height: "12rem" },
    style: { height: "12rem", width: "auto" },
  },
};

function Logo({ size }) {
  return (
    <Box>
      <img
        src={LOGO_SIZES[size].url}
        alt="Niu-Social"
        style={LOGO_SIZES[size].style}
      />
    </Box>
  );
}

export default Logo;

Logo.propTypes = {
  size: PropTypes.string,
};
