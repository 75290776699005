import { useState, useEffect, useCallback } from "react";
import { Box, Dialog, DialogActions, DialogContent,//Grid, Fade,
  DialogContentText, DialogTitle, Divider, Button } from "@mui/material";
//import Modal from 'core/Modal';
import { SA_DEF_ORG_GROUP_ID, } from 'config/app.config';
import { fLeadDebounce } from 'lib/appFx';
import { Spinner2, Typography, Flasher } from 'core';
import { useGroupDeleteMutation } from 'services/admin/admGroupsApi';
//---------------------------------------------------------

export default function AdmGroupDelDialog({theme, modalArgs}) {
  //modalArgs: {isOpen, title, groupData, onDismiss, onConfirm, descText, isActing, }
  //console.log('fDelGrpModalArgs', modalArgs);
  const [fDeleteGroupApi, {isLoading: isDeleting}] = useGroupDeleteMutation();
  const [stAjaxStep, setStAjaxStep] = useState(0);//-1:Err, 0: Nop, 1:wait, 2: OK
  const [stPgMsg, setStPgMsg] = useState('');

  const fDelGroupHandler = async(gId) => {
    //console.log('fDelGroupHandler', stDelGroupData.id);
    let dGrpResp;

    if(gId !== SA_DEF_ORG_GROUP_ID) {
     //setStAjaxStep();
      try {
        dGrpResp = await fDeleteGroupApi({groupId: gId}).unwrap();
        console.log('dGrpResp', dGrpResp);//{"data": null}
        if(dGrpResp === null || dGrpResp?.result) {//204: NoContent
          dGrpResp = {'result': true, 'data': 'delGroupOK_' + gId};
        } else {
          dGrpResp = {'result': false,
            'error': {'code': 'errDelGrp', 'desc': 'errDelGrp'}};
        }
      } catch(err) {
        dGrpResp = {'result': false, 'error': {'code': 'eServer500', 'desc': err}};
      }
    } else {//throw new Error('Invalid inputs', {'delGroupErr': true});
      dGrpResp = {'result': false,
        'error': {'code': 'eServer403A', 'desc': 'delGroupErr'}};
    }
    return dGrpResp;
  };

  const fDeleteGroup = async () => {
    const gId = modalArgs?.groupData.id;//console.log('gId', gId);
    let resp, pMsg;
    if(gId && gId !== SA_DEF_ORG_GROUP_ID) {
      setStAjaxStep(1);
      //resp = await modalArgs.onConfirm(gId);
      resp = await fDelGroupHandler(gId);
      console.log('delGrpResp', resp);
      if(resp?.result) {
        //pMsg = resp?.data;
        //pMsg = 'Group deleted successfully';
        setStPgMsg('Group deleted successfully');
        setStAjaxStep(2);
        setTimeout(()=> {
          modalArgs.onDismiss(true);
          setStAjaxStep(0);
          setTimeout(()=> { setStPgMsg(''); }, 1000);
        }, 3000);
      } else if(resp?.error) {
        switch(resp?.error.code) {
          case 'errDelGrp':
          case 'eServer500':
          case 'eServer403A':
            //pMsg = resp?.error.desc;
            //pMsg = 'Error deleting group. Please try again..';
            setStPgMsg('Error deleting group. Please try again..');
        }
        setStAjaxStep(-1);

        setTimeout(()=> {
          modalArgs.onDismiss();
          setStAjaxStep(0);
          setTimeout(()=> { setStPgMsg(''); }, 1000);
        }, 3000);
      }
    }// else { pMsg = '?'; }
  };
  //---------------------------------------------------------

  let ctnPMsg;//=<></>;
  if(stPgMsg) {
    ctnPMsg = (
      <Typography align='center' sx={{
        fontSize: '0.8rem', textAlign: 'center', padding: '0.2rem',
        color: stAjaxStep === -1 ? '#e92425' : '#78c931',}}
      >{stPgMsg}</Typography>
    );
  }

  return (
    //keepMounted disableEnforceFocus hideBackdrop={true};
    //<Modal open={isOpen} onClose={onDismiss}>{content}</Modal>

    //if (reason && reason == "backdropClick")
    //if (reason && reason == "backdropClick" && "escapeKeyDown")
    <Dialog
      open={modalArgs.isOpen}
      onClose={modalArgs.onDismiss}
      //onBackdropClick={modalArgs.onDismiss}
      maxWidth="sm" //fullWidth
      disableScrollLock
    >
      <DialogTitle id="delGrpModalTitle" className='secHdErr'>
        {modalArgs?.title || 'Delete Group'}
      </DialogTitle>
      <Divider sx={{color: '#9e9e9e'}} />
      <DialogContent sx={{maxWidth: {sm: '80%', md: '25rem'}}}>
        <DialogContentText id="delGrpModalDesc" sx={{
            fontSize: '1rem', color: '#1c1c1c',
            lineHeight: '1.8rem', overflow: 'hidden',
          }}
        >
          {modalArgs.descText}
        </DialogContentText>
        {/*  {stPgMsg && (  * /}
          <Fade in={stPgMsg ? true : false} //exit={} timeout={1000}
          timeout={{appear: 1000, enter: 1000, exit: 3000}}
            //timeout={1000}
          //easing={{enter: 1000, exit: 3000}}
          >
            {ctnPMsg}
          </Fade>
        {/*  )}  */}
        {stPgMsg && (
          <Flasher delay={3000} resetFunction={() => { setStPgMsg(''); }}>
            {ctnPMsg}
          </Flasher>
        )}
      </DialogContent>
	  <DialogActions>
		{(() => {//console.log('stAjaxStep', stAjaxStep);
		  //if(modalArgs.onConfirm && !ctnPMsg) {// && [0, 1].includes(stAjaxStep)) {
		  if(!ctnPMsg) {// && [0, 1].includes(stAjaxStep)) {
        return (
          <Box sx={{marginBottom: '0.5rem'}}>
            <Button className='cBtn cBtnDark'
              onClick={modalArgs.onDismiss} autoFocus>
              Cancel
            </Button>&nbsp;
            <Button className="cBtn cBtnRed" sx={{marginLeft: '0.7rem'}}
              onClick={fLeadDebounce(fDeleteGroup, 3000)}
              disabled={stAjaxStep !== 0}
            >
              {/*modalArgs?.isActing ? <Spinner2 /> : 'Yes, Delete'*/}
              {stAjaxStep === 1 ? <Spinner2 /> : 'Yes, Delete'}
            </Button>&nbsp;
          </Box>
        );
		  } else {
        return (
          <Button className="cBtn cBtnWh" //sx={{margin: '0 1rem 0.5rem'}}
            onClick={modalArgs.onDismiss} autoFocus>
            OK
          </Button>
        );
		  }
		})()}
	  </DialogActions>
	</Dialog>
  );
};