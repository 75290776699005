import { useState, useEffect, useCallback } from "react";
import {
  Grid, Box, Dialog, DialogActions, DialogContent,
  DialogContentText, DialogTitle, Divider, Button, Fade
} from "@mui/material";
//import Modal from 'core/Modal';
import { SA_DEF_ORG_GROUP_ID, } from 'config/app.config';
//import { fLeadDebounce } from 'lib/appFx';
import { Spinner2, Typography, Flasher } from 'core';
import { useGroupDeleteMemberMutation } from 'services/admin/admGroupsApi';
import { useTranslation } from "react-i18next";
//---------------------------------------------------------

//const pMsgSx = { fontSize: '0.5rem', color: 'green'};

export default function AdmGroupDelMemberDialog({ theme, modalArgs }) {
  //modalArgs: {isOpen, title, groupData, onDismiss, onConfirm, descText, isActing, }
  //console.log('AdmGroupDelMemberModalArgs', modalArgs);
  const [fRemGrpMemApi,
    { isLoading: isRemovingMem }] = useGroupDeleteMemberMutation();
  const [stAjaxStep, setStAjaxStep] = useState(0);//-1:Err, 0: Nop, 1:wait, 2: OK
  const [stPgMsg, setStPgMsg] = useState('');
  const { t: home } = useTranslation('home')
  const fRemoveGrpMem = async (gId, arrMIds) => {
    let remGrpMemResp;

    if (gId !== SA_DEF_ORG_GROUP_ID) {
      //setStAjaxStep();
      try {
        remGrpMemResp = await fRemGrpMemApi(
          { groupId: gId, user_ids: JSON.stringify(arrMIds) });
        if (remGrpMemResp?.data?.result?.success) {
          remGrpMemResp = { 'result': true, 'data': 'remGrpMemOK' + gId };
          //fRefetchGroupMembers();//{page: stDPage}
        } else {
          remGrpMemResp = { 'result': false, 'error': { 'code': 'errRemGrpMem', 'desc': 'errRemGrpMem' } };
        }
      } catch (err) {
        remGrpMemResp = { 'result': false, 'error': { 'code': 'eServer500', 'desc': err } };
      }
    } else {//throw new Error('Invalid inputs', {'delGroupErr': true});
      remGrpMemResp = {
        'result': false,
        'error': { 'code': 'eServer403A', 'desc': 'remGrpMemErr' }
      };
    }
    return remGrpMemResp;
  };

  const fRemGroupMember = async () => {
    //const gId = modalArgs?.memberIds;
    const gId = modalArgs?.groupData?.id;
    const arrRemMemIds = modalArgs?.memberIds;
    let resp, pMsg;
    if (gId && arrRemMemIds.length) {
      if (gId !== SA_DEF_ORG_GROUP_ID) {
        setStAjaxStep(1);
        //resp = await modalArgs.onConfirm(gId, arrRemMemIds);
        resp = await fRemoveGrpMem(gId, arrRemMemIds);
        if (resp?.result) {
          //pMsg = resp?.data;
          setStPgMsg(`${"Group_members_removed_successfully"}`);
          setStAjaxStep(2);
          setTimeout(() => {
            modalArgs.onDismiss(true);
            setStAjaxStep(0);
            setTimeout(() => { setStPgMsg(''); }, 1000);
          }, 3000);
        } else if (resp?.error) {
          //pMsg = resp?.error.desc;
          setStPgMsg(`${"Error_removing_selected_member_from_the_group"}`);
          setStAjaxStep(-1);
          setTimeout(() => {
            modalArgs.onDismiss();
            setStAjaxStep(0);
            setTimeout(() => { setStPgMsg(''); }, 1000);
          }, 3000);
        }
      }
    }// else { pMsg = '?'; }
  };
  //---------------------------------------------------------

  let ctnPMsg;//=<></>;
  if (stPgMsg) {
    ctnPMsg = (
      <Typography align='center' sx={{
        fontSize: '0.8rem', textAlign: 'center', padding: '0.2rem',
        color: stAjaxStep === -1 ? '#e92425' : '#78c931',
      }}
      >{stPgMsg}</Typography>
    );
  }

  return (
    //keepMounted disableEnforceFocus hideBackdrop={true};
    //<Modal open={isOpen} onClose={onDismiss}>{content}</Modal>

    //if (reason && reason == "backdropClick")
    //if (reason && reason == "backdropClick" && "escapeKeyDown")
    <Dialog
      open={modalArgs.isOpen}
      onClose={modalArgs.onDismiss}
      //onBackdropClick={modalArgs.onDismiss}
      maxWidth="sm" //fullWidth
      disableScrollLock
    >
      <DialogTitle id="remGrpMemModalTitle" className='secHdErr'>
        {modalArgs?.title || 'Remove Group Member'}
      </DialogTitle>
      <Divider sx={{ color: '#9e9e9e' }} />
      <DialogContent sx={{ maxWidth: { sm: '80%', md: '25rem' } }}>
        <DialogContentText id="remGrpMemModalDesc" sx={{
          fontSize: '1rem', color: '#1c1c1c',
          lineHeight: '1.8rem', overflow: 'hidden',
        }}
        >
          {modalArgs.descText}
        </DialogContentText>
        {/*  {stPgMsg && (  * /}
        <Fade in={stPgMsg ? true : false} //exit={} timeout={1000}
        timeout={{appear: 1000, enter: 1000, exit: 3000}}
          //timeout={1000}
        //easing={{enter: 1000, exit: 3000}}
        >
          {ctnPMsg}
        </Fade>
      {/*  )}  */}
        {stPgMsg && (
          <Flasher delay={3000} resetFunction={() => { setStPgMsg(''); }}>
            {ctnPMsg}
          </Flasher>
        )}
      </DialogContent>
      <DialogActions>
        {(() => {
          //if(modalArgs.onConfirm) {
          if (!ctnPMsg) {
            return (
              <Box sx={{ marginBottom: '0.5rem' }}>
                <Button className='cBtn cBtnDark'
                  onClick={modalArgs.onDismiss} autoFocus>
                  {`${home('cancel')}`}
                </Button>&nbsp;
                <Button className="cBtn cBtnRed" sx={{ marginLeft: '0.7rem' }}
                  onClick={fRemGroupMember} disabled={stAjaxStep !== 0}
                >
                  {/*modalArgs?.isActing ? <Spinner2 /> : 'Yes, Delete'*/}
                  {/* {stAjaxStep === 1 ? <Spinner2 /> : 'Yes, Remove'} */}
                  {stAjaxStep === 1 ? <Spinner2 /> : `${home('yes_remove')}`}
                </Button>&nbsp;
              </Box>
            );
          } else {
            return (
              <Button className="cBtn cBtnWh" //sx={{margin: '0 1rem 0.5rem'}}
                onClick={modalArgs.onDismiss} autoFocus>
                {`${home('ok')}`}
              </Button>
            );
          }
        })()}
      </DialogActions>
    </Dialog>
  );
};