//ExtendedSliceApi
import { sSApi } from '../sSApi';
//---------------------------------------------------------

export const authApi = sSApi.injectEndpoints({
  endpoints: builder => ({
    login: builder.mutation({
      query: credentials => ({
        url: '/user/login',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    register: builder.mutation({
      query: (params) => {
        return {
          url: "/register",
          method: "POST",
          body: params,
        };
      },
    }),
    resetPassword: builder.mutation({
      query: (params) => {
        return {
          url: "/user/reset-password",
          method: "POST",
          body: params,
        };
      },
    }),
    forgotPassword: builder.mutation({
      query: (credentials) => {
        return {
          url: "/user/forgot-password",
          method: "POST",
          body: { ...credentials },
        };
      },
    }),
  }),
});

export const {
  useLoginMutation,
  useRegisterMutation,
  useResetPasswordMutation,
  useForgotPasswordMutation,
} = authApi;
//---------------------------------------------------------