import { LinearProgress, Stack } from "@mui/material";
import { Logo } from "core";
//---------------------------------------------------------

function LoaderPage(props) {
  return (//size="larger"//<Logo size="small" />
    <Stack className='xtBdr2' spacing={2} height="90vh"
      justifyContent="center" alignItems="center"
    >
      <Stack spacing={2}>
        <Logo size="large" />
        <LinearProgress color="primary" />
      </Stack>
      {props?.children}
    </Stack>
  );
}

export default LoaderPage;
