import { Outlet } from "react-router-dom";
import { /*useTheme,*/ Box, Stack, styled } from "@mui/material";
//import BootAdmApp from 'pages/admin/BootAdmApp';
import AppMenu from 'core/Header/AppMenu';
//import { AdminHeader } from 'components/admin/AdminHeader'; 
import genStyles from '../styles/genStyles.css';
//---------------------------------------------------------

//const PageContainer = styled(Stack)(({ theme }) => ({
const PageContainer = styled(Box)(({ theme }) => ({
  border: '0px solid red',
  verticalAlign: 'top',//overflow: 'visible',
	[theme.breakpoints.down('xl')]: { padding: '1.5rem 0.5rem 1rem 0', },//'2rem'
	[theme.breakpoints.down('md')]: { padding: '0.5rem',},
}));
//WinWidth
//---------------------------------------------------------

export default function AuthAdminLayout() {
	return (//<CssBaseline /><Container maxWidth="sm">
		<Box id="nSAdmPSc" className='ctnSAdmPSc'
      //sx={{backgroundColor: (theme) => theme.palette.background.default,}}
    >
      {/*<BootAdmApp />*/}
      <AppMenu />
      <Box id="ctnApp"
        sx={{ backgroundColor: (theme) => theme.palette.background.default,}}
      >
        <Box id="ctnLtP" className="inLB xbdr1"
          sx={{//width: '16rem', marginTop: '4.38rem',
            width: '20%',// background: '#d8eded',
            minHeight: '100vh',
          }}
        >&nbsp;</Box>
        <Box id="ctnMidP" className='inLB xbdr1'
          //pl={10}
          sx={{
            width: '79.8%',//'auto',
            minHeight: '100vh',
            //[theme.breakpoints.down('sm')]: { margin: 'auto', },
            //[theme.breakpoints.up('sm')]: { marginLeft: {smp: '30px', lg: '270px', } },
          }}
        >{/*BBBBBBBBBBBBBBBBBBBBBBBB<br/>24Times*/}
          <PageContainer id="ctnAppPg">
            <Outlet />
          </PageContainer>
        </Box>
      </Box>
    </Box>
	);
}