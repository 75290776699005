import { useRef, useState, /*useMemo,*/ useEffect, } from "react";
import { Box, IconButton, CardContent, //CardMedia,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { Typography } from 'core';
import { fNormalizeTextContent } from 'lib/appFx';
//---------------------------------------------------------

const icnSx = {
  cursor: 'pointer',
};
//---------------------------------------------------------

export default function CardTextWrapper({theme, textContent, cardOption}) {
  //console.log('cardOption', cardOption);
  const readMoreThreshold = cardOption?.toggleThreshold ?? 280;
  const flgEnableCollapse = cardOption?.flgEnableCollapse ?? true;

  const flgUseIcon = false;
  const collapseRefs = {
    showLessRef: useRef(null),
    showMoreRef: useRef(null),
    toggleViewRef: useRef(null),
  };

  const fToggleCollapse = () => {
    const lessRef = collapseRefs.showLessRef;
    const moreRef = collapseRefs.showMoreRef;
    const toggleRef = collapseRefs.toggleViewRef;

    if(lessRef !== null && moreRef !== null) {
      if(lessRef.current?.style.display === 'none') {
        moreRef.current.style.display = 'none';
        lessRef.current.style.display = 'block';
        toggleRef.current.innerHTML = flgUseIcon ?
          <ExpandMoreIcon sx={icnSx} /> : 'Read More';
      } else {
        moreRef.current.style.display = 'block';
        lessRef.current.style.display = 'none';
        toggleRef.current.innerText = flgUseIcon ?
          <ExpandLessIcon sx={icnSx} /> : 'Show Less';
      }
    }
  };

  const normalizedTextLess = fNormalizeTextContent(
    textContent, false, readMoreThreshold);
  const normalizedTextFull = fNormalizeTextContent(
    textContent, true, readMoreThreshold);
  const lnkToggleEl = flgUseIcon ? (
      <Box ref={collapseRefs.toggleViewRef} component={'span'}
      onClick={fToggleCollapse}
      >
        <ExpandMoreIcon sx={icnSx} /></Box>
    ) : (
      <Typography
        ref={collapseRefs.toggleViewRef}
        sx={{
          color: '#808080',//theme.palette.secondary.light,
          textDecoration: 'none',
          cursor: 'pointer',
          fontWeight: 600,
          size: 'xsmall'
        }}
        onClick={fToggleCollapse}
      >Read More</Typography>
  );
  const txJsx = flgEnableCollapse ? (
    <div>
      <div
        ref={collapseRefs.showLessRef}
        color_variant="light" className={''} style={{ display: 'block' }}
        dangerouslySetInnerHTML={{ __html: normalizedTextLess, }}
      />
      <div
        ref={collapseRefs.showMoreRef}
        color_variant="light" className={''} style={{ display: 'none' }}
        dangerouslySetInnerHTML={{ __html: normalizedTextFull, }}
      />
    </div>
  ) : (
    <div
      color_variant="light" className={''} style={{ display: 'block' }}
      dangerouslySetInnerHTML={{ __html: normalizedTextFull, }}
    />
  );
  //---------------------------------------------------------

  return (
    <Box mx='0.8rem' className='xctnCardText' sx={{
      //[theme.breakpoints.down("sm")]: { fontSize: '0.8rem',}
     }}
    >
      <Box className='ctnCardText'> {txJsx}</Box>
      {flgEnableCollapse && textContent?.length > readMoreThreshold && (lnkToggleEl)}
    </Box>
  );
};