import { Box, Grid, Stack } from "@mui/material";
import { Typography } from "core";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import React from "react";
import { grey } from "@mui/material/colors";
import { styled } from "@mui/system";
import SecurityIcon from "@mui/icons-material/Security";
import LinkIcon from "@mui/icons-material/Link";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import VpnKeyOutlinedIcon from "@mui/icons-material/VpnKeyOutlined";
import { useTranslation } from "react-i18next";

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: "10px 1rem",
  display: "flex",
  justifyContent: "left",
  border: `1px solid ${grey[400]}`,
  gap: 10,
}));

export default function Sidebar({ theme }) {
  const { t: home } = useTranslation('home')
  const sidebar = [
    {
      icon: (
        <AccountCircleOutlinedIcon sx={{ color: grey[600], fontSize: "25px" }} />
      ),
      text: `${home('general_profile_setting')}`,
    },
    {
      icon: <SecurityIcon sx={{ color: grey[600], fontSize: "25px" }} />,
      text: `${home('security_and_login')}`,
    },
    {
      icon: <LinkIcon sx={{ color: grey[600], fontSize: "25px" }} />,
      text: `${home('link_social_network')}`,
    },
    {
      icon: <EmailOutlinedIcon sx={{ color: grey[600], fontSize: "25px" }} />,
      text: `${home('notification_setting')}`,
    },
    {
      icon: <LanguageOutlinedIcon sx={{ color: grey[600], fontSize: "25px" }} />,
      text: `${home('language_setting')}`,
    },
    {
      icon: <VpnKeyOutlinedIcon sx={{ color: grey[600], fontSize: "25px" }} />,
      text: `${home('delegate_permission')}`,
    },
  ];
  return (
    <Grid container spacing={5} justifyContent="center">
      <Grid item xs={12}>
        <Stack spacing={0.4}>
          {sidebar.map((sidebarData) => (
            <StyledBox>
              {sidebarData.icon}
              <Typography
                color_variant="lightGrey"
                fontSize="1rem"
                fontWeight={900}
                sx={{
                  textTransform: "capitalize ",
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {sidebarData.text}
              </Typography>
            </StyledBox>
          ))}
        </Stack>
      </Grid>
    </Grid>
  );
}
