import ERRORS from "config/validation.config";
import * as yup from "yup";

const AccountSettingSchemaEditProfile = yup.object({
  // role: yup.string().required(ERRORS.ROLE.required),
  city: yup.string()
  .matches(ERRORS.NAME.matches_regex,ERRORS.NAME.matches)
  .required(ERRORS.CITY.required),
  first_name: yup
    .string()
    .matches(ERRORS.NAME.matches_regex,ERRORS.NAME.matches)
    .min(ERRORS.NAME.min_chars, ERRORS.NAME.min)
    .max(ERRORS.NAME.max_chars, ERRORS.NAME.max)
    .required(ERRORS.NAME.required),
  last_name: yup
    .string()
    .matches(ERRORS.NAME.matches_regex,ERRORS.NAME.matches)
    .max(ERRORS.NAME.max_chars, ERRORS.NAME.max)
    .required(ERRORS.NAME.required),
});

const AccountSettingSchemaEditPassword = yup.object({
  current_password: yup
    .string()
    .min(ERRORS.PASSWORD.min_chars, ERRORS.PASSWORD.min)
    .matches(ERRORS.PASSWORD.regex, ERRORS.PASSWORD.invalid)
    .required(ERRORS.PASSWORD.required),
  new_password: yup
    .string()
    .min(ERRORS.PASSWORD.min_chars, ERRORS.PASSWORD.min)
    .matches(ERRORS.PASSWORD.regex, ERRORS.PASSWORD.invalid)
    .required(ERRORS.PASSWORD.required),
  retype_password: yup
    .string()
    .min(ERRORS.PASSWORD.min_chars, ERRORS.PASSWORD.min)
    .matches(ERRORS.PASSWORD.regex, ERRORS.PASSWORD.invalid)
    .oneOf([yup.ref("new_password")], ERRORS.PASSWORD.not_matched)
    .required(ERRORS.PASSWORD.required),
});

export { AccountSettingSchemaEditProfile, AccountSettingSchemaEditPassword };
