import { useEffect } from "react";
import { useLocation } from "react-router-dom";
//import { useDispatch, useSelector } from 'react-redux';
//import { fGetFMeNotSetts } from '../lib/appFx';
//import { selectAuthToken, setUserCredentials } from "../services/auth/authSlice";
//---------------------------------------------------------

const NavigationScroll = ({ children }) => {
  const location = useLocation();
  let urlPg, { pathname } = location;
  /*if(pathname.length > 1) {
	urlPg = pathname.charAt(0) === '/' ? pathname.substring(1) : pathname;
  }

  const dispatch = useDispatch();
  const userAuthToken = useSelector(selectAuthToken);
  const guestUPaths = [
	'signin', 'signup', 'verify',
	 'forgot-password', 'reset-password',
	 'tparty-comm-resp', 'social-media-connect'
  ];

  if(0 && urlPg && !guestUPaths.includes(urlPg)) {
	if(!userAuthToken) {
		const fMeNotSetts = fGetFMeNotSetts();
		if(fMeNotSetts?.usrRedData) {
			setTimeout(() => {
				dispatch(setUserCredentials(fMeNotSetts.usrRedData));
			}, 50);
		}
	}
  }*/

  useEffect(() => {
    if(pathname) {
      window.scrollTo({top: 0, left: 0, behavior: "smooth"});
    }
  }, [pathname]);

  return children || null;
};

export default NavigationScroll;
