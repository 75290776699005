import { createSlice, current } from "@reduxjs/toolkit";
import { sSApi } from '../sSApi';
import { SA_DEF_ORG_ID, SA_DEF_ORG_GROUP_ID } from 'config/app.config';
//---------------------------------------------------------

export const admContentSuggestionsApi = sSApi.injectEndpoints({
  endpoints: (builder) => ({
    cSuggestList: builder.query({
      query: ({ page, filter }) => {
        page = page || 1;
        filter = filter || {is_approved: null};//= filter || null;
        //let aUrl = '/contentsuggestion?page='+page;
        let aUrl = '/company/'+SA_DEF_ORG_ID+'/contentsuggestions?page='+page;
        if(filter !== null) {
          aUrl += '&filter='+JSON.stringify(filter);
        }

        return { url: aUrl, };
      },
      //keepUnusedDataFor: 60,
      //transformErrorResponse: (res, meta, arg) => {},
      /*transformResponse: (response, _, arg) => {
        //console.log(response);//, _, arg);
        const respData = fTransformAdmCSListData(response, _, arg);
        //respData = {data: { data: respData, meta: dMeta } };
        console.log('cSuggestListData', respData);
        //return respData.data;
        return respData;
			},*/
      //providesTags: ['AdmCSuggestCard'],
    }),
    cSuggestListMerge: builder.query({
      query: ({ page, filter }) => {
        page = page || 1;
        filter = filter || {is_approved: null};//= filter || null;
        //let aUrl = '/contentsuggestion?page='+page;
        let aUrl = '/company/'+SA_DEF_ORG_ID+'/contentsuggestions?page='+page;
        if(filter !== null) {
          aUrl += '&filter='+JSON.stringify(filter);
        }
        return { url: aUrl, };
      },
      //keepUnusedDataFor: 60, //providesTags: ['AdmCSuggestCard'],
      /*serializeQueryArgs: ({ endpointName }) => {
        return endpointName
      },
      / *serializeQueryArgs: ({ queryArgs }) => {
        console.log("serializeQueryArgs");
        const { page, ...otherArgs } = queryArgs;
        return otherArgs;
      },*/
      merge: (currentCache, newItems) => {
        currentCache.push(...newItems)
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg
      },
    }),
    cSuggestListMult: builder.query({
      keepUnusedDataFor: 20,
      async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
        let page = 1, pgGT = 0, apiMultResp;//console.log('_arg', _arg);
        const loopMax = 5;//arg?.maxPage || 5;
        const filter = _arg?.filter || null;
        const allRec = [];
        const _fetchData = async(page=1, argFilter=null) => {
          //let fDResp, aUrl = '/contentsuggestion?page='+page;
          let fDResp, aUrl = '/company/'+SA_DEF_ORG_ID+'/contentsuggestions?page='+page;
          if(argFilter !== null) {
            aUrl += '&filter='+JSON.stringify(argFilter);
          }
          let fResp = await fetchWithBQ(aUrl);
          //await _queryApi.dispatch(api.endpoints.feedEngPoints.initiate(page));
          if(fResp?.error) throw fResp.error;
          fResp = fResp?.data;

          if(fResp?.feed) {
            fDResp = { feed: fResp?.feed };
            if(fResp?.pagination) {
              fDResp['pagination'] = fResp?.pagination;
            }
          }//console.log('fDResp', fDResp);
          return fDResp;
        };

        const dApiResp = await _fetchData(page, filter);
        if(dApiResp?.feed.length) {
          allRec.push(...dApiResp?.feed);
          //const currPage = dApiResp?.pagination?.page?.current;
          pgGT = dApiResp?.pagination?.page?.total;

          if(page < pgGT) {
            page++; let dApiNext;
            for(let cnt=page; cnt<=loopMax && cnt<=pgGT; cnt++) {
              dApiNext = await _fetchData(cnt, filter);
              if(dApiNext?.feed.length) {
                allRec.push(...dApiNext?.feed);
              } else { break; }
            }
          }
        }

        if(dApiResp?.feed) {
          apiMultResp = { feed: allRec };
          if(dApiResp?.pagination) {
            apiMultResp['pagination'] = dApiResp?.pagination;
          }
        }//console.log('apiAcsMultResp '+pgGT, apiMultResp);
        return {data: apiMultResp};
      },
    }),
    cSuggestDetails: builder.query({
      query: (params) => {
        return {
          url: '/contentsuggestion/' + params.contentSuggestionId,
        };
      },
    }),
    cSuggestSetApproval: builder.mutation({
      query: (params) => {//query: ({ params }) => {
        //is_approved: -> 1: approved, 0: rejected
        //console.log('ACSPApprovalParams', params);
        const cSPId = params.cSPostId;
        delete params.cSPostId;
        return {
          url: '/contentsuggestion/'+cSPId+'/approval',
          method: 'POST',
          body: params,
        };
      },
    }),
  }),
});

export const {
  useCSuggestListQuery,
  useCSuggestListMultQuery,
  //useCSuggestListMergeQuery,
  //useCSuggestDetailsQuery,
  //useCSuggestCreateMutation,
  useCSuggestSetApprovalMutation,
  useCSuggestUpdateMutation,
  //useCSuggestDeleteMutation,
  //useCSuggestDeleteMediaMutation,
} = admContentSuggestionsApi;
//---------------------------------------------------------

const iniAdmCSuggestStat = {
  filterOpt: {//null: pending, 1: approved, 0: rejected
    page: 1,
    //selGroupId: SA_DEF_ORG_GROUP_ID, selSMKey: '',
    dateFrom: '', dateTo: '', status: 'pending'
  }
};

const admCSuggestSlice = createSlice({
	name: 'admCSuggest',
	initialState: iniAdmCSuggestStat,
	reducers: {
    rSetCSFilter: (state, action) => {
      const dKey = action.payload?.key;
      const dVal = action.payload?.val;
      //console.log('rSetCSFilterArgs', action.payload, dKey,
      //  dVal, Object.keys(iniAdmCSuggestStat.filterOpt));
      if(Object.keys(iniAdmCSuggestStat.filterOpt).includes(dKey)) {
        if(state.filterOpt[dKey] !== dVal) {
          const newSt = {...state};
          newSt.filterOpt = {...newSt.filterOpt}
          newSt.filterOpt[dKey] = dVal;
          if(dKey !== 'page') {
            newSt.filterOpt.page = 1;
          }
          return newSt;
          //return {...state, ...filterOpt, filterOpt[dKey]: dVal};
        } else { return state; }
      }
      console.log('InvalidACSArgs');//throwErr
      return state;
    },
    rResetCSFilter: (state, action) => {
      return {...state, filterOpt: iniAdmCSuggestStat};
    },
	},
});

export default admCSuggestSlice.reducer;
export const { rSetCSFilter, rResetCSFilter } = admCSuggestSlice.actions;
export const rGetCSFilterData  = state => state.admCSuggest.filterOpt;
//---------------------------------------------------------

const iniAdmCSStat = {
  summaryData: {
    approvedCount: 0, rejectedCount: 0, pendingCount: 0, //pointsGT: 0,
  }
};

const admCSuggestStatSlice = createSlice({
	name: 'admCSuggestStat',
	initialState: iniAdmCSStat,
	reducers: {
    rInitAcsSummary: (state, action) => {
      //console.log('rInitAcsSummaryAct', action?.payload);
      if(action?.payload?.summaryData) {
        state.summaryData = action.payload.summaryData;
        return state;
      }//else { console.log('rInitAcsSummaryNoUps'); }
    },
    rSetAcsSummary: (state, action) => {
      //console.log('rSetAcsSummaryArgs', action);
      const dKey = action.payload?.key;
      const dVal = action.payload?.val;
      const newSt = {...current(state)};
      if(newSt.summaryData.hasOwnProperty(dKey) && dKey && dVal) {
        if(state.summaryData[dKey] !== dVal) {
          //const newSt = {...state};
          newSt.summaryData = {...newSt.summaryData};
          newSt.summaryData[dKey] = dVal;
          //console.log('rSetAcsSummaryUp', newSt);
          return newSt;
          //return {...state, ...summaryData, summaryData[dKey]: dVal};
        }// else { return state; }
      }//return state;
    },
    rIncrementAcsSummaryData: (state, action) => {
      const dKey = action.payload?.key;
      const dVal = action.payload?.val || 1;
      const newSt = {...current(state)};
      console.log('rIncAcsSummaryArgs', {args: action.payload, dKey, dVal, newSt});
      if(newSt.summaryData.hasOwnProperty(dKey) && dKey && dVal) {
        if(newSt.summaryData[dKey] !== dVal) {
          newSt.summaryData = {...newSt.summaryData};
          if(['approvedCount', 'rejectedCount'].includes(dKey)) {
            newSt.summaryData[dKey] += dVal;
            if(newSt.summaryData.pendingCount > 0) {
              newSt.summaryData.pendingCount -= dVal;
            }
          } else { newSt.summaryData[dKey] += dVal; }
          //console.log('acsSumNewSt', newSt);
          return newSt;
        }// else { return state; }
      }//return state;// newSt;
    },
    rResetAcsSummary: (state, action) => {
      return {...state, summaryData: iniAdmCSStat.summaryData};
    },
	},
});

//const { actions, reducer } = admCSuggestStatSlice;
//export const { rSetAcsSummary, rResetAcsSummary } = actions;
//export default reducer;

export const admCSuggestStatReducer = admCSuggestStatSlice.reducer;
//export default admCSuggestStatSlice.reducer;
export const {
  rInitAcsSummary, rSetAcsSummary,
  rIncrementAcsSummaryData, rResetAcsSummary,
} = admCSuggestStatSlice.actions;
export const rGetAcsSummary  = state => state.admCSuggestStat.summaryData;
//---------------------------------------------------------


/*const fTransformAdmCSListData = (res, meta, arg) => {
  //console.log('transArgs', {res, meta, arg});
  let resp;

  if(res?.feed) {
    let transData=[];
    res.feed.forEach(csP => {
      let postData = {};
      transData.push(postData);
    });

    resp = {'result': true, 'data': transData, meta: { page: res.pagination}};

  } else {
    resp = {'result': false, 'error': {'code': 'eAdsmCsList01', 'desc': ''}}
  }

  return resp;
};*/