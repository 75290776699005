import { Outlet } from "react-router-dom";
import { Box, Stack, styled } from "@mui/material";
//---------------------------------------------------------

const Container = styled(Stack)(({ theme }) => ({
  margin: 'auto', maxWidth: '1280px',
  [theme.breakpoints.down('xl')]: {
    paddingRight: '2rem',
    paddingLeft: '2rem',
  },
  [theme.breakpoints.down('md')]: {
    paddingLeft: '0.5rem',
    paddingRight: '0.5rem',
  },
}));
//---------------------------------------------------------

export default function AppCommLayout() {
  return (
    <Box id='ctnAComm' className='xtBdr2'
      sx={{
        backgroundColor: (theme) => theme.palette.background.default,
        minHeight: '70vw', margin: 'auto', //marginBottom: '2rem',
      }}
    >
       <Box
          sx={{
            margin: 'auto', padding: '10px',
            height: '100%', minHeight: '100%',
          }}
        >
          <Container className='xtBdr2'>
            <Outlet />
          </Container>
        </Box>
    </Box>
  );
}
