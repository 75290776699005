import { Stack, styled } from "@mui/material";
import GaugeChart from "react-gauge-chart";
import React from "react";
import { Typography } from "core";

const StyledTopPost = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.background.section,
  padding: "2rem",
}));
export default function TopPost() {
  return (
    <StyledTopPost spacing={3}>
      <Stack textAlign="center">
        <GaugeChart
          id="gauge-chart2"
          nrOfLevels={3}
          colors={["#A0E23A", "#72CB9D", "#5C9FBC"]}
          percent={0.86}
          arcWidth={0.3}
          cornerRadius={0}
          arcPadding={0.02}
          hideText
          animate={true}
        />
        <Typography fontWeight={800} color_variant="lightGrey" size="xsmall">
          YOUR ENGAGEMENT LEVEL:
        </Typography>
        <Typography fontWeight={900} sx={{ color: "#000" }}>
          GOOD
        </Typography>
      </Stack>
    </StyledTopPost>
  );
}
