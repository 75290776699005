import loader2 from './loader2.svg';
import loaderWh from './loaderWh.svg';
//---------------------------------------------------------

export default function Spinner2(props) {
	const sW = 'auto'; const sH = '20px';//1.25remNotWorking
  const flgReverse = props?.reverse || false;

	return (
		<img title={props.title ?? '...'}
			alt={props.title ?? '...'} src={flgReverse ? loaderWh : loader2}
			height={sH} width={sW} style={{maxHeight: sH}} 
		/>
	);
}