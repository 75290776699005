import { Link } from "react-router-dom";
import { Box, Button } from "@mui/material";
import AdmGroupList from './AdmGroupList';
//---------------------------------------------------------

export default function AdmGroups({ theme, title='Groups' }) {

	//const fInitCreateNewGroup = () => {//opeModal};
	//---------------------------------------------------------

	//<Route path="admin/group-details" element={<AdmGroupDetails title="Group Details" />} />

	return (
		<>
			<Box className="">
				<h2 className='pgHd'>{title}</h2>
			</Box>
			<Box className="xpadL xtBdr2" sx={{marginTop: '-3.5rem'}}>
				<Box display="flex" justifyContent="flex-end" flexShrink={0}>
					<Button variant="text" type="button"
						className="cBtn cBtnDark" size="small"
						component={Link} to={'/admin/create-group'}
						//onClick={fInitCreateNewGroup}
					>Create New</Button>
				</Box><br/>
				<AdmGroupList />
			</Box>
		</>
	);
};