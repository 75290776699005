import { FormControlLabel, Radio } from "@mui/material";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import PropTypes from "prop-types";

export default function FormRadioButton({ name, label, ...rest }) {
  const { control } = useFormContext();

  return (
    <FormControlLabel
      label={label}
      control={
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <Radio {...field} value={field.value} label={label} {...rest} />
          )}
        />
      }
      {...rest}
    />
  );
}

FormRadioButton.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
};
