import { Box, Divider } from "@mui/material";
import AvatarGroup from "@mui/material/AvatarGroup";
import Avatar from "@mui/material/Avatar";
import { Typography } from 'core';
import { fGetStrAbbr, fGetNumAbbr, fLTrimChar, fStrToColor } from 'lib/appFx';
import AdmGroupCardHead from './AdmGroupCardHead';
import { useTranslation } from "react-i18next";
//---------------------------------------------------------

const grpMoreTagsSx = {
  display: 'flex', //justifyContent: 'flex-end',
  padding: '0.6rem', marginLeft: 'auto',
  color: '#9e9e9e', fontSize: '0.7rem'
};
const grpMemberAvSx = { width: '1.8rem', height: '1.8rem', fontSize: '0.7rem' };
const grpMemberSurplusAvSx = {
  marginLeft: '0.5rem', width: '1.5rem', height: '1.5rem',
  fontSize: '0.5rem', border: '2px solid #00406b',
  bgcolor: '#fff', color: '#00406b',
};
const secDevider = (<Divider sx={{ borderWidth: 1, color: '#9e9e9e' }} />);
//---------------------------------------------------------

export default function AdmGroupInfo({ theme, groupData, groupMethods }) {
  let grpTags, lstGrpTags, grpMemAvs, surplusMemAvtar;
  const { t: home } = useTranslation('home')
  if (groupData?.hash_filter?.length) {
    lstGrpTags = [];
    const maxTagDisp = 3;
    const lstGrpLen = groupData.hash_filter.length;

    //lstGrpLen && groupData.hash_filter.sort();
    for (let i = 0; i < lstGrpLen && i < maxTagDisp; i++) {
      if (i <= lstGrpLen) {
        lstGrpTags.push(
          <Typography className='ctnTagTx' key={'gTg_' + i}>
            {'#' + fLTrimChar(groupData.hash_filter[i], '#')}
          </Typography>
        );
      }
    }
    if (lstGrpLen > maxTagDisp) {
      lstGrpTags.push(
        <Typography sx={grpMoreTagsSx} key={'gTgMore'}>
          {'+' + (lstGrpLen - maxTagDisp) + ' more'}
        </Typography>
      );
    }
  } else {
    lstGrpTags = (<Typography sx={{ fontSize: '0.8rem', paddingTop: '0.1rem' }}>
      Not set yet</Typography>);
  }
  //----------------------

  if (groupData?.users?.length) {
    grpMemAvs = []; //const bgColors = [];
    let rBG = '#bdbdbd';
    //groupData.grpMemAvs.sort();
    groupData.users.forEach((gU, i) => {
      //const rBG = bgColors[Math.floor(Math.random() * bgColors.length)];
      //rBG = fStrToColor(gU?.name);
      grpMemAvs.push(
        gU?.profilePic ?//variant='rounded | square'
          <Avatar key={'gMem_' + i} alt={gU?.name} src={gU.profilePic} /> :
          <Avatar sx={{ bgcolor: rBG }} key={'gMem_' + i}
            alt={gU?.name} title={gU?.name}>{fGetStrAbbr(gU.name)}</Avatar>
      );
    });

    surplusMemAvtar = (
      <Avatar sx={grpMemberSurplusAvSx}>
        <span>{fGetNumAbbr(21000)}</span>
      </Avatar>
    );

    grpMemAvs = (
      <Box>
        <AvatarGroup sx={{ '& .MuiAvatar-root': grpMemberAvSx, }}
        //max={5}
        //spacing='medium' cascadeDirection
        //renderSurplus={(surplus) => <span>+{surplus.toString()[0]}k</span>}
        //renderSurplus={(surplus) => <p>+{surplus.toString()[0]}k</p>}
        //total={groupData.users.length}
        >
          {grpMemAvs}
        </AvatarGroup>
        {/*surplusMemAvtar*/}
      </Box>);
  } else {
    grpMemAvs = (
      <Typography sx={{ fontSize: '0.8rem', paddingTop: '0.1rem' }}>
        {home('no_users_yet')}
      </Typography>);
  }
  //---------------------------------------------------------


  return (
    <Box className='xbdr1' height='100%'>
      <Box className='xbdr' sx={{
        height: { md: '28%' },
        maxHeight: { md: '4.5rem' },
        //padding: '1rem 1rem 0.3rem 1.3rem'
      }}>
        <AdmGroupCardHead groupData={groupData} groupMethods={groupMethods} />
      </Box>{secDevider}
      <Box className='xbdr' sx={{
        height: { md: '55%' }, maxHeight: { md: '7.9rem' }
      }}
      >
        <Typography sx={{ paddingTop: '1rem', fontSize: '0.9rem', color: '#00406B' }}>
          {home('content_topic')}
        </Typography>
        <Box sx={{
          display: 'flex', flexWrap: 'wrap', flexGrow: 1,
          justifyContent: 'left',
          padding: '0.1rem',
          wordBreak: 'break-all',
          //alignContent: 'space-between',
        }}
        >
          {lstGrpTags}
        </Box>
      </Box>{/*secDevider}
      <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '0.5rem'}}>
        <Box sx={{marginRight: 'auto', display: 'flex', alignItems: 'center' }}>
          {grpMemAvs}
        </Box>
        <Box>
          <Typography sx={{
              color: '#00406b', fontSize: '1rem', fontWeight: '600', textAlign: 'center',
              //maxWidth: {sm: '9rem'}, maxHeight: {sm: '2rem'},
	            //overflow: 'clip', //overFlowY: 'clip',
            }}>
            {250} Points
          </Typography>
        </Box>
      </Box>*/}
    </Box>
  );
};