//PostImageZoomModal
import { /*useMemo,*/ useEffect, useRef, useState } from "react";
import {
  Grid, styled, //Stack, Box,
  //IconButton, CardContent, //CardMedia,
} from "@mui/material";
import Slider from "react-slick";
import Backdrop from 'core/BackDrop';
import { FeedView } from 'config/ui.config';
import '../style.css';
//---------------------------------------------------------

const StyledPostMedia = styled('img')(({ theme }) => ({
  cursor: 'pointer',
  //maxHeight: "20rem",
  // aspectRatio:"16/9",
  // maxWidth: "100%",
  // objectFit: "contain",
}));
const onImageError = (e) => {
  e.target.src = '/assets/images/no-image-available.webp';
};
const gWinWidth = (typeof window !== 'undefined') ? window.innerWidth : null;
//---------------------------------------------------------

export default function PostMedia({ theme, postCustomData: pInf, isUserList }) {
  const defPImgSrc = '/assets/images/no-image-available.webp';
  const [open, setOpen] = useState(false);
  const [image, setImage] = useState('');
  //const sliderRef = useRef(null);
  //pInf && pInf?.postId === 'Yjglz6Ry_wdk1dm3a' && console.log('pInfPM', pInf);

  const handleClose = () => { setOpen(false); };
  const handleToggle = (data) => {
    setImage(data);
    setOpen(!open);
  };
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false
    //lazyLoad: 'ondemand',
    /*autoplay: false,
    fade: true,
    pauseOnFocus: false,
    pauseOnHover: false,
    lazyLoad: 'anticipated'|ondemand|progressive*/
  };
  let pImgs;
  //(pInf.postId === 'a1R3BYmO_wrg47Q0m' ||pInf.postId === 'a1R3BYmO_v7kXnRmj' ) && console.log('img '+pInf.postId+' '+pInf?.pImages.length, pInf?.pImages);
  const iRef = useRef(null);
  if(10 || pInf?.pImages.length > 1) {
    //console.log('pI '+pInf.postId, pInf?.pImages);
    pImgs = [];
    pInf?.pImages.forEach((iSrc, i) => {
      //console.log('pII '+pInf.postId, iSrc);
      pImgs.push(
        <StyledPostMedia
          key={'pImg_'+i}
          //src={iSrc+'?'+(Math.random().toString(36).slice(2, 7))}
          //src={require(iSrc).default}
          src={iSrc || defPImgSrc}
          ref={iRef}
          onError={onImageError}
          onClick={() => handleToggle(iSrc)}
          className={isUserList ? 'slider-img' : ''}
        />
      );
    });
    pImgs = (
      <Slider {...sliderSettings}
        className="slide-second MyPostMediaSlider"
        //key={'pImSl_'+pInf.postId}
        //id={'pImgSl_'+pInf.postId}
        //ref={sliderRef}
      >
        {pImgs}
        </Slider>
    );
  } else {
    const iSrc = pInf?.pImages[0];
    /*pImgs = (
      <img src={iSrc}
        style={{ width: '100%', display: 'inline-block', position: 'relative'}}
      />
    );*/
    pImgs = (
      <div style={{alignItems: 'center',
        display: 'flex', flexDirection: 'row',
        justifyContent: 'center', position: 'relative',
        border: '1px solid green',
        width: '100%', height: '100%',
        overflow: 'hidden', objectFit: 'cover'}}
      >
        <StyledPostMedia key={'pImg_'+1}
          src={iSrc}
          onError={onImageError}
          onClick={() => handleToggle(iSrc)}
          className={isUserList ? 'slider-img' : ''}
          style={{ width: '100%', display: 'inline-block', position: 'relative'}}
        />
      </div>
    );
  }
  //if(pInf.postId === 'a1R3BYmO_qmMerxl0') {
	//console.log('iRef2', iRef?.current?.parentElement?.parentElement);
	//console.log('sliderRef', sliderRef.parent);
  //}
  useEffect(() => {
    setTimeout(() => {
        //sliderRef.current?.slick({lazyLoad: 'ondemand',});
      //sliderRef && sliderRef?.current?.slick('refresh');
      //sliderRef && sliderRef?.current?.slickGoTo(5);
      //sliderRef && sliderRef?.current?.slickPrev();
      //const el = document.getElementsByClassName('')
      //$('.slick__slider', context)[0].slick.refresh()
      const iPPEl = iRef?.current?.parentElement?.parentElement;
      if(iPPEl) {
        iPPEl.style.width = '100%';
      }
    }, 1000);
  }, [iRef]);

  const popImgCss = gWinWidth && gWinWidth < 450 ?
    {//maxHeight: '550px',
      height: 'auto', width: '95%', objectFit: 'contain',
    } : {
      maxHeight: '550px', height: '95%',
      width: 'auto', objectFit: 'contain',
    }
  //---------------------------------------------------------

  return (
    <Grid item
      xs={12} sm={12} md={FeedView['full'].feed_size}
      marginY={FeedView['full'].feed_margin}
      justifyContent={!pInf.pText && 'center'}
      //padding={!pInf.pText && '1rem 0'}
      padding={isUserList ? '0' : (!pInf.pText && '1rem 0')}
      display={!pInf.pText && 'flex'}
    >
	    { pImgs }
      {!isUserList && (
        <Backdrop open={open} onclick={handleClose} onClose={handleClose}>
          <img
            src={image}
            onError={onImageError}
            alt=""
			      onClick={handleClose}
            className='imDisp2'
            style={popImgCss}
          />
        </Backdrop>
      )}
    </Grid>
  );
}
