import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled, Box, Grid, Stack, } from "@mui/material";
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
//import { grey, red } from "@mui/material/colors";
import { Typography } from 'core';
import { fGetRefDates, fFormatNumberToLocaleString, fGetNumAbbr } from 'lib/appFx';
import { useCSuggestListMultQuery, rGetAcsSummary, rInitAcsSummary,
} from 'services/admin/admContentSuggestionsApi';
//---------------------------------------------------------

const StyledPageHeader = styled(Stack)(({ theme }) => ({
  padding: "0.5rem",
  backgroundColor: theme.palette.primary.lighter,
}));
const StyledSumCard = styled(Box)(({ theme, highlight }) => ({
  backgroundColor: highlight ? "#f5f5f5" : theme.palette.background.paper,
  height: '9rem', maxHeight: '9rem', border: "1px solid #e6e7e9",
  //border: highlight && '3px solid '+theme.palette.background.default,
  // boxShadow: theme.shadows[1],
}));
const SumCardData = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  [theme.breakpoints.down("md")]: { fontSize: '1.25rem', },
}));

const xfPrepAcsSummaryData = (acsData) => {
  const acsSummaryResp = {
    summaryData: {
      approvedCount: 0, rejectedCount: 0, pendingCount: 0,
    }
  };//console.log('acsSummaryData', acsData);

  if(acsData && acsData.length) {
    acsData.forEach(acsP => {
      const postStatus = acsP?.is_approved;
      if(postStatus === true) {//BasssedOn postUpdateDateTime
        acsSummaryResp.summaryData.approvedCount++;
      } else if(postStatus === false) {//BasssedOn postUpdateDateTime
        acsSummaryResp.summaryData.rejectedCount++;
      } else if(postStatus === null) {//BasedOn postCreateDateTime
        acsSummaryResp.summaryData.pendingCount++;
      }// else {}
    });
  }//console.log('fPrepAcsWeekStatDataResp', acsSummaryResp);
  return acsSummaryResp;
};
const fPrepAcsSummaryData = (acsData, acsDataRDate) => {
  const acsSummaryResp = {
    summaryData: {
      approvedCount: 0, rejectedCount: 0, pendingCount: 0,
    }
  };//console.log('acsSummaryData', acsData);

  if(acsData && acsData.length) {
    acsSummaryResp.summaryData.pendingCount =
      acsData.filter(acsR => acsR.is_approved === null
    ).length;
  }
  if(acsDataRDate && acsDataRDate.length) {
    acsSummaryResp.summaryData.approvedCount =
      acsDataRDate.filter(acsR =>
        acsR?.approver?.reviewed_at && [1, true].includes(acsR.is_approved)
    ).length;
    acsSummaryResp.summaryData.rejectedCount =
      acsDataRDate.filter(acsR =>
        acsR?.approver?.reviewed_at &&  [0, false].includes(acsR.is_approved)
    ).length;
  }//console.log('fPrepAcsWeekStatDataResp', acsSummaryResp);
  return acsSummaryResp;
}
//---------------------------------------------------------

export default function AdmCSuggestSummary({ theme, cSumData }) {
  const sADateRefs = fGetRefDates();
  const admCSFeedApiArgs = {//NoApproveStatusFilter->AllStatus
    filter: {
      date: {
        start: sADateRefs.dateWeekStart,
        end: sADateRefs.dateWeekEnd
      }
    }
  };
  const fDispatchAcsSummary = useDispatch();
  //---------------------------------------------------------

  const [stAcsStatOK, setStAcsStatOK] = useState(false);
  const { data: admCSApiData } = useCSuggestListMultQuery(
    {filter: admCSFeedApiArgs.filter},
    //{ skip: stAcsStatOK/*, refetchOnMountOrArgChange: true*/ }
    { skip: stAcsStatOK, refetchOnMountOrArgChange: true }
  );
  //admCSApiData && console.log('admCSApiSumData', admCSApiData);
  //console.log('sss'+ stAcsStatOK, admCSApiData);
  /*useEffect(() => {
    if(!stAcsStatOK && admCSApiData) {
      const acsSummaryData = fPrepAcsSummaryData(admCSApiData?.feed);
      //console.log('acsInitSummaryData', acsSummaryData);
      if(Object.keys(acsSummaryData).length) {
        setStAcsStatOK(true);
        fDispatchAcsSummary(rInitAcsSummary(acsSummaryData));
      }
    }
  }, [admCSApiData, fDispatchAcsSummary, stAcsStatOK]);*/
  //---------------------------------------------------------

  //---------------------------------------------------------
  //---------------------------------------------------------
  const admCSFeedApiArgsRDate = JSON.parse(JSON.stringify(admCSFeedApiArgs));
  admCSFeedApiArgsRDate.filter.date.type = 'reviewed_at';
  const { data: admCSApiDataRDate } = useCSuggestListMultQuery(
    {filter: admCSFeedApiArgsRDate.filter},
    { skip: stAcsStatOK, refetchOnMountOrArgChange: true }
  );
  //admCSApiDataRDate && console.log('admCSApiDataRDate', admCSApiDataRDate);
  useEffect(() => {
    if(!stAcsStatOK && admCSApiData && admCSApiDataRDate) {
      const acsSummaryData = fPrepAcsSummaryData(admCSApiData?.feed,
        admCSApiDataRDate?.feed);
      //console.log('acsInitSummaryData', acsSummaryData);
      if(Object.keys(acsSummaryData).length) {
        setStAcsStatOK(true);
        fDispatchAcsSummary(rInitAcsSummary(acsSummaryData));
      }
    }
  }, [admCSApiData, admCSApiDataRDate, fDispatchAcsSummary, stAcsStatOK]);
  //---------------------------------------------------------
  //---------------------------------------------------------

  let acsWeekStat = useSelector(rGetAcsSummary);
  console.log('acsWeekStatSlc', acsWeekStat);

  return (
    <>
       <Grid container id='ctnAcsSum' className='xtBdr2'
        rowSpacing={0}
        columnSpacing={0}//{{ xs: 1, sm: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
        justifyContent={'center'}
        alignItems={'top'} //disableEqualOverflow
      >
        <Grid item xs={12} className='xbdr1'>
          <StyledPageHeader className='flxMid'>
            <Typography size="medium" color_variant="white">
              Content Suggestions
            </Typography>
          </StyledPageHeader>
        </Grid>
        <Grid item xs={4} className='xbdr1'>
          <StyledSumCard className='sumCard'>
            <ThumbUpOffAltIcon className='sumCardIcn' />
            <SumCardData size="xlarge">
              {
                //acsWeekStat?.approvedCount >= 0 ?
                Number.isInteger(acsWeekStat?.approvedCount) ?
                  fFormatNumberToLocaleString(acsWeekStat.approvedCount) :
                  '-'
              }
            </SumCardData>
            <Typography size="small" color_variant="lightGrey">
              Approved this week
            </Typography>
          </StyledSumCard>
        </Grid>
        <Grid item xs={4} className='xbdr1'>
          <StyledSumCard className='sumCard'>
            <ThumbDownOffAltIcon className='sumCardIcn' />
            <SumCardData size="xlarge">
              {
                Number.isInteger(acsWeekStat?.rejectedCount) ?
                  fFormatNumberToLocaleString(acsWeekStat.rejectedCount) :
                  '-'
              }
            </SumCardData>
            <Typography size="small" color_variant="light">
              Rejected this week
            </Typography>
          </StyledSumCard>
        </Grid>
        <Grid item xs={4} className='xbdr1'>
          <StyledSumCard className='sumCard'>
            <MoreHorizIcon className='sumCardIcn'
              sx={{border: '3px solid #00406B', borderRadius: '50%'}}
            />
            <SumCardData size="xlarge">
              {
                Number.isInteger(acsWeekStat?.pendingCount) ?
                  fFormatNumberToLocaleString(acsWeekStat.pendingCount) :
                  '-'
              }
            </SumCardData>
            <Typography size="small" color_variant="light">
              Pending this week
            </Typography>
          </StyledSumCard>
        </Grid>
      </Grid>
    </>
  );
};
