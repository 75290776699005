//import { useEffect } from "react";
//import ReactDOMServer from 'react-dom/server';
import { Link, Navigate, useNavigate, useLocation } from "react-router-dom";
import { Box, Stack } from "@mui/material";
import AdmGroupSummary from './AdmGroupSummary';
import AdmGroupFeedForm from './AdmGroupFeedForm';
import AdmGroupMemberList from './AdmGroupMemberList';
import { SA_DEF_ORG_GROUP_ID } from 'config/app.config';
import { ERROR_ROUTES } from 'config/routes.config';
import { fStrToTitleCase } from 'lib/appFx';
import { Typography } from "core";
//---------------------------------------------------------

export default function AdmGroupDetails({ theme, title = 'Group Details' }) {
  const navigate = useNavigate();
  const { state: groupInfo } = useLocation();
  //console.log('groupInfo', groupInfo);

  //---------------------------------------------------------
  const groupId = groupInfo?.groupId;
  //useEffect(() => {
  if (!groupId) { navigate(ERROR_ROUTES.Page404.url); }
  //}, [groupInfo]);
  const grpTitle = fStrToTitleCase(groupInfo.groupData.name);
  const grpTtlJsx = grpTitle ? (//ReactDOMServer.renderToStaticMarkup(
    <>
      {': '}
      <Typography component='span' id='ctnPgHdGrpTtl' className="pgHd"
        style={
          groupInfo.groupData.id === SA_DEF_ORG_GROUP_ID ?
            { color: '#c5353a' } : null
        }
      >{grpTitle}</Typography>
    </>
  ) : null;
  //---------------------------------------------------------

  return (
    groupInfo ? (
      <>
        <Box className="">
          {/*<h2 className='pgHd'>{title + (grpTitle ? ': '+grpTitle : '')}</h2>*/}
          <h2 className='pgHd'>{title}{grpTtlJsx}</h2>
        </Box>
        <Box className="xpadL xtBdr2" sx={{ marginTop: '2rem' }}>
          <Box sx={{ flexGrow: 1, /*alignSelf: { xs: 'flex-start', sm: 'center' },*/ }}>
            <Stack className="xbgWh xbdr1" direction='column'
              spacing={1.5} alignItems='strech'
            //justifyContent='space-evenly'
            >
              <AdmGroupSummary groupData={groupInfo.groupData} />
              <AdmGroupFeedForm groupData={groupInfo.groupData} />
              <AdmGroupMemberList groupData={groupInfo.groupData} />
            </Stack>
          </Box>
        </Box>
      </>
    ) : (
      <>
        {/*<Redirect to={{pathname: ERROR_ROUTES.Page404.url
             state: { from: location }}} /> */}
        {
          setTimeout(() => { navigate(ERROR_ROUTES.Page404.url) }, 1000)
        }
      </>
    )

  );
};