import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { Edit } from "@mui/icons-material";
import { Box, Grid, InputAdornment, Stack } from "@mui/material";
import { grey } from "@mui/material/colors";
import { styled } from "@mui/system";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import { useForm } from "react-hook-form";
import { AccountSettingSchemaEditProfile } from "schema";
import { Button, FormInputField, FormProvider, Typography } from "core";
import { useUserUpdateMutation } from "services/userApi";
import { selectAuthUserId } from "services/auth/authSlice";
//---------------------------------------------------------

const IconStyle = {
  color: grey[500],
  marginRight: "1rem",
  width: "2rem",
  height: "2rem",
};

const StyledBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  bottom: 5,
  background: "#020238",
  padding: "5px",
  opacity: "0.65",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  cursor: "pointer",
}));
const StyledButton = styled(Button)(({ theme }) => ({
  maxWidth: "150px",
  borderRadius: "2px ",
  border: `3px solid ${grey[400]}`,
  boxShadow: "none",
  textTransform: "none",
  color: theme.palette.secondary.dark,
  fontWeight: 900,
  backgroundColor: theme.palette.background.paper,
  "&:hover": {
    backgroundColor: theme.palette.background.paper,
    boxShadow: "none",
  },
}));

const StyledInputFieldContainer = styled(Stack)(() => ({
  alignItems: "center",
  flexDirection: "row",
}));

export default function EditProfileForm({
  theme,
  first_name,
  last_name,
  city,
  email,
  role,
  profileImage,
  refetchUserData,
}) {
  const [firstNameDisable, setFirstNameDisable] = useState(true);
  const [lastNameDisable, setLastNameDisable] = useState(true);
  const [roleDisable, setRoleDisable] = useState(true);
  const [cityDisable, setCityDisable] = useState(true);
  const [msg, setMsg] = useState();
  const [updateUser] = useUserUpdateMutation();
  const userAuthId = useSelector(selectAuthUserId);

  const defaultValues = {
    profile: "/assets/images/avtar.png",
    email: "",
    role: "",
    city: "",
    first_name: "",
    last_name: "",
  };
  const methods = useForm({
    resolver: yupResolver(AccountSettingSchemaEditProfile),
    defaultValues,
  });

  const { handleSubmit, setValue, watch } = methods;
  const handleUploadClick = async (event) => {
    setValue("profile", URL.createObjectURL(event.target.files[0]));
    let formData = new FormData();

    formData.append("profile", event.target.files[0]);
    const result = await updateUser({ userId: userAuthId, params: formData });
	  if(result?.data === 201) { await refetchUserData(); }
  };
  useEffect(() => {
    if(profileImage) { setValue("profile", profileImage); }
    if(email) { setValue("email", email); }
    if(role) { setValue("role", role); }
    if(last_name) { setValue("last_name", last_name); }
    if(first_name) { setValue("first_name", first_name); }
    if(city) { setValue("city", city); }
  }, [first_name, last_name, role, email, profileImage, city]);

  const onSubmit = async (data) => {
    if(!firstNameDisable || !lastNameDisable || !roleDisable || !cityDisable) {
      let formData = new FormData();

      formData.append("first_name", data.first_name);
      formData.append("last_name", data.last_name);
      formData.append("role", data.role);
      formData.append("city", data.city);
      try {
        const result = await updateUser({
          userId: userAuthId,
          params: formData,
        }).unwrap();
        if (result === 201) {
          setFirstNameDisable(true);
          setLastNameDisable(true);
          setRoleDisable(true);
          setCityDisable(true);
          setMsg("User details updated successfully");
          await refetchUserData();
          setTimeout(() => {
            setMsg(null);
          }, 4000);
        }
      } catch (e) {
      }
    } /* else {
       setMsg("Nothing to update");
      await refetchUserData();
      setTimeout(() => { setMsg(null); }, 4000);
    }*/
  };
  //---------------------------------------------------------

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid
        container
        spacing={3}
        justifyContent="center"
        alignItems="center"
        direction="row"
      >
        <Grid
          item
          xs={12}
          sm={8}
          md={9}
          lg={7}
          justifyContent="center"
          display="flex"
        >
          <Box sx={{ paddingY: "3rem" }}>
            <input
              accept="image/*"
              style={{ display: "none" }}
              id="contained-button-file"
              multiple
              type="file"
              onChange={handleUploadClick}
            />
            <label htmlFor="contained-button-file">
              <Box sx={{ position: "relative" }}>
                <img
                  src={watch("profile")}
                  alt="Profile"
                  name="profile"
                  style={{
                    width: 200,
                    height: 200,
                    cursor: "pointer",
                  }}
                />
                <StyledBox>
                  <CameraAltOutlinedIcon
                    sx={{ color: "#fff", paddingRight: "3px" }}
                  />
                  <Typography color_variant="white">
                    Upload profile picture
                  </Typography>
                </StyledBox>
              </Box>
            </label>
          </Box>
        </Grid>
        <Grid item xs={12} sm={8} md={9} lg={7}>
          <Stack spacing={3}>
            <FormInputField
              color="secondary"
              variant="standard"
              name="first_name"
              type="text"
              disabled={firstNameDisable}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Typography fontWeight={600}>First Name*</Typography>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <Edit
                      onClick={() => setFirstNameDisable(false)}
                      sx={{ cursor: "pointer" }}
                    />
                  </InputAdornment>
                ),
              }}
            />
            <FormInputField
              color="secondary"
              variant="standard"
              name="last_name"
              type="text"
              disabled={lastNameDisable}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Typography fontWeight={600}>Last Name*</Typography>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <Edit
                      onClick={() => setLastNameDisable(false)}
                      sx={{ cursor: "pointer" }}
                    />
                  </InputAdornment>
                ),
              }}
            />
            {/*<FormInputField
              color="secondary"
              variant="standard"
              name="role"
              type="text"
              disabled={roleDisable}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Typography fontWeight={600}>Role*</Typography>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <Edit onClick={() => setRoleDisable(false)} />
                  </InputAdornment>
                ),
              }}
            />*/}

            <FormInputField
              color="secondary"
              variant="standard"
              name="city"
              type="text"
              disabled={cityDisable}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Typography fontWeight={600}>City*</Typography>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <Edit
                      onClick={() => setCityDisable(false)}
                      sx={{ cursor: "pointer" }}
                    />
                  </InputAdornment>
                ),
              }}
            />

            <FormInputField
              name="email"
              type="email"
              color="success"
              variant="standard"
              fullWidth
              disabled
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Typography fontWeight={600}>Email*</Typography>
                  </InputAdornment>
                ),
              }}
            />
            {msg && (
              <Stack justifyContent="flex-start" direction="row">
                <StyledInputFieldContainer>
                  <Typography color={theme.palette.error.main}>
                    {msg}
                  </Typography>
                </StyledInputFieldContainer>
              </Stack>
            )}

            <Stack justifyContent="flex-end" direction="row">
              <StyledButton type="submit" disabled={firstNameDisable && lastNameDisable && roleDisable && cityDisable}>Save changes</StyledButton>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
