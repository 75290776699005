import styled from '@emotion/styled';
import { Typography } from 'core';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
//import twXIcon as twN from 'http://localhost:3000/assets/images/sMedia/twX.svg';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { getInitSMediaAttr } from 'lib/appFx';
//---------------------------------------------------------

export const FeedView = {
  full: {
    feed_direction: 'column',
    feed_size: 12,
    feed_margin: 0,
    justifyContent: 'center',
  },
  half: {
    feed_direction: 'row-reverse',
    feed_size: 5.5,
    feed_margin: '15px',
    justifyContent: 'space-between',
  },
};
export const badges = {
  likes: (
    <img
      src="/assets/images/badges/500-LIKES.png"
      alt="likes"
      style={{ width: 190, height: 190 }}
    />
  ),
  butterfly: (
    <img
      src="/assets/images/badges/BUTTERFLY.png"
      alt="butterfly"
      style={{ width: 190, height: 190 }}
    />
  ),
  influencer: (
    <img
      src="/assets/images/badges/INFLUENCER.png"
      alt="influencer"
      style={{ width: 190, height: 190 }}
    />
  ),
  netNavigator: (
    <img
      src="/assets/images/badges/NET-NAVIGATOR.png"
      alt="netNavigator"
      style={{ width: 190, height: 190 }}
    />
  ),
  ringBearer: (
    <img
      src="/assets/images/badges/RING-BEARER.png"
      alt="ringBearer"
      style={{ width: 190, height: 190 }}
    />
  ),
  socialNinja: (
    <img
      src="/assets/images/badges/SOCIAL-NINJA.png"
      alt="socialNinja"
      style={{ width: 190, height: 190 }}
    />
  ),
  socialSanta: (
    <img
      src="/assets/images/badges/SOCIAL-SANTA.png"
      alt="socialSanta"
      style={{ width: 190, height: 190 }}
    />
  ),
  socialStar: (
    <img
      src="/assets/images/badges/SOCIAL-STAR.png"
      alt="socialStar"
      style={{ width: 190, height: 190 }}
    />
  ),
  twitterati: (
    <img
      src="/assets/images/badges/TWITTERATI.png"
      alt="twitterati"
      style={{ width: 190, height: 190 }}
    />
  ),
  tyro: (
    <img
      src="/assets/images/badges/TYRO.png"
      alt="tyro"
      style={{ width: 190, height: 190 }}
    />
  ),
  veteran: (
    <img
      src="/assets/images/badges/VETERAN.png"
      alt="veteran"
      style={{ width: 190, height: 190 }}
    />
  ),
};

//export const FILE_UPLOAD_LIMIT = 1024 * 1024 * 2; //2mb max

const StyledIcon = styled(Typography)(({ theme, background, color }) => ({
  fontSize: '2rem',//'1.6rem',//'1.3rem',
  fontWeight: 600,
  height: 35,
  width: 35,
  borderRadius: 100,
  background: background,
  color: color,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const BlogIconContainer = styled(Typography)(
  ({ theme, background, color }) => ({
    fontSize: '1.3rem',
    fontWeight: 600,
    height: 20,
    width: 20,
    borderRadius: 100,
    background: background,
    color: color,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  })
);

const FacebookNew = (isConnected) => (
  <button className="icnBtn Facebook-btn">
    <FacebookIcon />
    Continue with Facebook
  </button>
);
const InstagramNew = (isConnected) => (
  <button className="icnBtn Instagram-btn">
    <InstagramIcon /> Continue with Instagram
  </button>
);
const LinkedinNew = (isConnected) => (
  <button className="icnBtn Linkedin-btn">
    <LinkedInIcon />
    Connect Linkedin
  </button>
);

const TwitterNew = (isConnected) => (
  <button className="icnBtn Twitter-btn">
    <TwitterIcon />
    Connect Twitter
  </button>
);
export const sMediaIco = (k = null, cFx = null) => {
  let res;
  switch (k) {//onClick={cFx}
    case 'fb':
      res = (
        <Typography data-smkey={k} className='dLnk'
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="27"
            height="27"
            viewBox="0,0,256,256"
            style={{ fill: '#000' }}
          >
            <g
              fill="#3b5998"
              fillRule="nonzero"
              stroke="none"
              strokeWidth="1"
              strokeLinecap="butt"
              strokeLinejoin="miter"
              strokeMiterlimit="10"
              strokeDasharray=""
              strokeDashoffset="0"
              style={{ mixBlendMode: 'normal' }}
            >
              <g transform="scale(8,8)">
                <path d="M19.25391,2c-3.942,0 -6.25391,2.08217 -6.25391,6.82617v4.17383h-5v5h5v12h5v-12h4l1,-5h-5v-3.32812c0,-1.787 0.58277,-2.67187 2.25977,-2.67187h2.74023v-4.79492c-0.474,-0.064 -1.85509,-0.20508 -3.74609,-0.20508z"></path>
              </g>
            </g>
          </svg>
        </Typography>
      );
      break;
    case 'tw':
      res = (
        <div data-smkey={k}>
          <TwitterIcon data-smkey={k} className='dLnk'
            sx={{ color: '#00acee', height: 27, width: 27 }} />
        </div>);
      break;
    case 'ig': case 'igp': case 'igb':
      res = (
        <div data-smkey={k}>
          <InstagramIcon data-smkey={k} className='dLnk'
            sx={{ color: '#8a3ab9', height: 26, width: 26 }} />
        </div>);
      break;
    case 'ln':
      res = (<Typography data-smkey={k} className='dLnk'
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          width="27"
          height="27"
          viewBox="0,0,256,256"
          style={{ fill: '#000' }}
        >
          <g
            fill="#5b5b5b"
            fillRule="nonzero"
            stroke="none"
            strokeWidth="1"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeMiterlimit="10"
            strokeDasharray=""
            strokeDashoffset="0"
            style={{ mixBlendMode: 'normal' }}
          >
            <g transform="scale(8.53333,8.53333)">
              <path d="M9,25h-5v-15h5zM6.501,8c-1.383,0 -2.501,-1.121 -2.501,-2.501c0,-1.38 1.12,-2.499 2.501,-2.499c1.378,0 2.499,1.121 2.499,2.499c0,1.38 -1.121,2.501 -2.499,2.501zM27,25h-4.807v-7.3c0,-1.741 -0.033,-3.98 -2.499,-3.98c-2.503,0 -2.888,1.896 -2.888,3.854v7.426h-4.806v-15.011h4.614v2.051h0.065c0.642,-1.18 2.211,-2.424 4.551,-2.424c4.87,0 5.77,3.109 5.77,7.151c0,0 0,8.233 0,8.233z"></path>
            </g>
          </g>
        </svg>
      </Typography>);
      break;
  };
  return res;
};

const translations = {
  "en": {
    "connect": "Connect",
    "connected": "Connected",
    "disconnect": "Disconnect",
  },
  "es": {
    "connect": "Conectar",
    "connected": "Conectado",
    "disconnect": "Desconectar",
  },
  "ko": {
    "connect": "연결",
    "connected": "연결됨",
    "disconnect": "연결 끊기",
  },
  "zh": {
    "connect": "连接",
    "connected": "已连接",
    "disconnect": "断开连接",
  }
};

// Translation Function
function translate(key, language = "en") {
  if (translations[language] && translations[language][key]) {
    return translations[language][key];
  } else {
    console.warn(`Translation for "${key}" not found in ${language} language.`);
    return key; // Fallback to the original key
  }
}

export const socialMedia = {
  NewIcons: (name, connStat, username, showDisconnect = true) => {
    const lang_code = localStorage.getItem('language_code') || 'en';
    const defBGColor = connStat ? 'grey' : null;
    let btnText = '';

    switch (name) {
      case 'fbNew':// case 'facebookNew':
        const fColor = connStat ? 'grey' : '#2374f2';
        /*btnText = !connStat ?
          'Continue with ' :
          showDisconnect ? 'Disconnect ' : '';
        btnText += username ? username : 'Facebook';*/
        btnText = !connStat ? `${translate("connect", lang_code)} ` :
          showDisconnect ? `${translate("disconnect", lang_code)} ` : `${translate("connected", lang_code)} `;
        let fbIco = connStat ? 'fbDisonnect3.png' : 'fbConnect3.png';
        fbIco = '/assets/images/sMedia/' + fbIco;
        return (/*//<FacebookIcon />
          <img src={fbIco} style={{height: '35px'}} />
          <StyledIcon background="#fff"
            color={fColor}
            style={{fontSize:'34px', height: '35px', width: '35px',
            color:fColor}}
          >
            <span style={{position: 'relative', bottom: '-7px', fontWeight: '750'}}>	f</span>	
          </StyledIcon>*/
          /*<button
            className="icnBtn Facebook-btn"
            style={{backgroundColor: fColor, fontSize: '14px'}}
          >
            <img src={fbIco} style={{height: '35px', width: '35px'}} />&nbsp;
              {btnText}Facebook
          </button>
          */
          <button
            className="icnBtn Facebook-btn"
            style={{ backgroundColor: fColor, fontSize: '14px', }}
          >
            <StyledIcon style={{
              background: "#fff", color: fColor,
              //backgroundImage: fbIco
              backgroundImage: `url(${fbIco})`,
            }}>
              &nbsp;
            </StyledIcon>&nbsp;
            {btnText}Facebook
          </button>
        );
      case 'twNewO':// case 'twitterNew':
        const tColor = connStat ? 'grey' : '#1d9bf0';
        btnText = !connStat ? `${translate("connect", lang_code)} ` :
          showDisconnect ? `${translate("disconnect", lang_code)} ` : `${translate("connected", lang_code)} `;
        return (
          <button
            className="icnBtn Twitter-btn"
            style={{ backgroundColor: tColor, fontSize: '14px' }}
          >
            <StyledIcon background="#fff" color="#2374f2">
              <TwitterIcon sx={{ color: tColor, position: 'relative', width: 'auto', height: 'auto' }} />
            </StyledIcon>&nbsp;
            {btnText}X
          </button>
        );
      case 'twNeww':// case 'twitterNew':
        const tColorN = connStat ? 'grey' : '#0f1419';//'#1d9bf0';
        //const tColorN = !connStat ? 'grey' : '#0f1419';//'#1d9bf0';
        btnText = !connStat ? `${translate("connect", lang_code)} ` :
          showDisconnect ? `${translate("disconnect", lang_code)} ` : `${translate("connected", lang_code)} `;
        return (
          <button
            className="icnBtn Twitter-btn"
            style={{ backgroundColor: tColorN, fontSize: '14px' }}
          >
            <StyledIcon background="#fff" color="#2374f2">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.9027 8.46429L19.3482 0H17.5838L11.119 7.34942L5.95547 0H0L7.8082 11.1136L0 19.9897H1.76443L8.59152 12.2285L14.0445 19.9897H20L11.9023 8.46429H11.9027ZM9.48608 11.2115L8.69495 10.1049L2.40018 1.29901H5.11025L10.1902 8.40562L10.9813 9.51229L17.5847 18.7498H14.8746L9.48608 11.212V11.2115Z" fill="#0F1419" />
              </svg>
            </StyledIcon>&nbsp;
            {btnText}X
          </button>
        );
      case 'twNew':// case 'twitterNew':
        const tColorNn = connStat ? 'grey' : '#0f1419';//'#1d9bf0';
        //const tColorNn = !connStat ? 'grey' : '#0f1419';//'#1d9bf0';
        btnText = !connStat ? `${translate("connect", lang_code)} ` :
          showDisconnect ? `${translate("disconnect", lang_code)} ` : `${translate("connected", lang_code)} `;
        return (
          <button
            className="icnBtn Twitter-btn"
            style={{ backgroundColor: tColorNn, fontSize: '14px' }}
          >
            <StyledIcon background="#fff" color="#2374f2">
              <svg width="19" height="19" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.4256 0H20.8185L13.4062 8.4718L22.1262 20H15.2985L9.95077 13.0082L3.83179 20H0.436923L8.36513 10.9385L0 0H7.00103L11.8349 6.39077L17.4256 0ZM16.2349 17.9692H18.1149L5.97949 1.9241H3.96205L16.2349 17.9692Z" fill="#0F1419" />
              </svg>
            </StyledIcon>&nbsp;
            {btnText}X
          </button>
        );
      case 'lnNew':// case 'linkedInNew':
        btnText = !connStat ? `${translate("connect", lang_code)} ` :
          showDisconnect ? `${translate("disconnect", lang_code)} ` : `${translate("connected", lang_code)} `;
        return (//<LinkedInIcon />//2374f2
          <button
            className="icnBtn Linkedin-btn"
            style={{ backgroundColor: defBGColor, fontSize: '14px' }}
          >
            <StyledIcon background="#fff" color="#0a66c2">
              <span style={{ color: defBGColor, position: 'relative', fontSize: '1.6rem' }}>in</span>
            </StyledIcon>&nbsp;
            {btnText}LinkedIn
          </button>
        );
        // case 'igNew':// case 'instagramNew'://case 'igpNew':// case 'instagrampNew':
        // case 'igbNew':// case 'instagrambNew'://<InstagramIcon />&nbsp;
        btnText = !connStat ?
          'Continue with ' :
          showDisconnect ? 'Disconnect ' : '';
        btnText += username ? username : 'Instagram';
        // {connStat ? "Continue with " :  "Disconnect "} {username === "undefined" || !username ? "Instagram" : username}
        /*{!connStat ? "Continue with " :  showDisconnect? 'Connected' : "Disconnect "} {username === "undefined" || !username ? "Instagram" : username}*/
        return (
          <button
            className="icnBtn Instagram-btn"
            style={{ backgroundColor: defBGColor, fontSize: '14px' }}
          >
            <StyledIcon background="#fff" color="#2374f2">
              <span style={{ color: defBGColor, position: 'relative', }}>
                <InstagramIcon sx={{ color: defBGColor, position: 'relative', bottom: '-4px' }} />
              </span>
            </StyledIcon>&nbsp;
            {/* {!connStat ? "Continue with " :  "Disconnect "}Instagram */}
            {btnText}
          </button>
        );
      default:
        break;
    }
  },
  all: (
    <Typography
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="27"
        height="27"
        viewBox="0 0 512.000000 512.000000"
        preserveAspectRatio="xMidYMid meet"
      >
        <g
          transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
          fill="#000000"
          stroke="none"
        >
          <path
            d="M2315 5109 c-597 -61 -1141 -318 -1563 -739 -1002 -1000 -1002 -2620
              0 -3620 406 -405 901 -648 1493 -732 119 -17 512 -16 635 1 579 80 1090 331
              1489 732 406 408 649 903 733 1494 17 118 17 512 0 630 -85 593 -327 1088
              -734 1495 -393 394 -891 643 -1453 726 -122 18 -477 26 -600 13z m575 -314
              c897 -138 1632 -798 1854 -1666 55 -216 70 -336 70 -569 0 -302 -47 -559 -150
              -820 -302 -760 -969 -1291 -1784 -1416 -140 -22 -471 -25 -605 -6 -359 51
              -689 176 -974 370 -532 360 -878 909 -977 1552 -25 159 -25 481 0 640 99 643
              445 1192 977 1552 322 219 661 337 1094 382 81 8 393 -4 495 -19z"
          />
          <path
            d="M1535 3296 c-146 -46 -249 -143 -304 -285 l-26 -66 -3 -567 -3 -568
                155 0 156 0 0 225 0 225 150 0 150 0 0 -225 0 -225 150 0 150 0 0 539 c0 324
                -4 560 -10 592 -31 164 -153 301 -311 350 -70 22 -193 24 -254 5z m195 -308
                c71 -49 75 -61 78 -254 l4 -174 -151 0 -151 0 0 173 c0 154 2 175 20 205 43
                70 136 93 200 50z"
          />
          <path
            d="M2410 2560 l0 -750 375 0 375 0 0 150 0 150 -225 0 -225 0 0 600 0
                600 -150 0 -150 0 0 -750z"
          />
          <path
            d="M3460 2560 l0 -750 380 0 380 0 0 150 0 150 -230 0 -230 0 0 600 0
                600 -150 0 -150 0 0 -750z"
          />
        </g>
      </svg>
    </Typography>
  ),
  ShareIcon: (
    <Typography
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <svg
        version="1.1"
        id="Layer_1"
        x="0px"
        y="0px"
        height="60px"
        width="60px"
        viewBox="0 0 457 546"
      /* enable-background="new 0 0 457 546" */
      >
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M253.989868,142.843094
	C257.649109,135.825775 261.308319,128.808472 265.391754,121.332413
	C266.748077,118.606346 268.204376,116.409569 268.520691,114.059349
	C269.822235,104.389137 270.777374,94.672295 272.148621,84.985298
	C272.629364,85.000015 272.821381,85.002045 273.415497,85.003014
	C279.507843,85.646530 280.362213,81.204315 282.002594,77.407570
	C283.110901,74.842247 284.649933,72.462990 285.988983,70.393143
	C284.291565,79.350777 283.148438,88.073486 280.738800,96.431030
	C278.801788,103.149361 275.258423,109.395546 272.575989,115.912209
	C271.447662,118.653336 270.676453,121.543793 269.784637,124.379501
	C266.684906,134.235931 260.420898,143.225464 262.847229,154.550308
	C264.021606,160.031708 266.043671,162.921341 271.813507,162.981339
	C279.207855,163.058258 286.603638,163.000793 293.951904,163.364029
	C292.072998,164.819611 290.150848,165.788940 288.435150,167.041168
	C286.842224,168.203827 285.469635,169.668396 283.748901,171.269836
	C270.339996,175.948196 255.887344,176.969498 244.713181,186.587402
	C242.523209,188.472366 239.425400,189.383606 237.453186,191.426407
	C229.282257,199.889771 219.855682,207.352493 214.919510,218.518997
	C214.795853,218.798721 214.363327,218.936523 214.223145,219.215134
	C212.431610,222.775681 210.667221,226.349884 208.622742,230.109070
	C207.232193,232.197754 206.114822,234.097961 204.643158,236.006561
	C203.617065,236.843857 202.790756,237.590622 202.299133,238.515320
	C199.664520,243.471085 197.113327,248.471222 194.533295,253.456009
	C194.007233,253.278961 193.481171,253.101929 192.955109,252.924881
	C191.970276,250.949921 190.985443,248.974960 190.009125,246.607452
	C190.895584,236.440460 198.656357,230.809433 203.937683,223.821899
	C208.774963,217.421890 216.692184,212.588287 211.041321,202.816849
	C210.894196,202.562485 211.136902,202.049774 210.969040,201.845703
	C209.924438,200.575531 208.870697,198.348404 207.717361,198.284286
	C198.254959,197.758194 188.620010,196.486832 179.331329,197.697189
	C168.654373,199.088470 158.844330,196.556061 148.672623,194.877548
	C138.291718,193.164474 127.788490,191.787628 117.291656,191.318253
	C109.749634,190.980972 102.138802,192.533401 94.542686,192.954987
	C87.113937,193.367294 80.654739,195.569733 75.339828,201.055252
	C72.509346,203.976593 69.131622,206.367722 66.039597,208.644547
	C67.972603,205.466080 69.809258,202.600250 71.778969,199.828979
	C73.438431,197.494202 75.252525,195.269333 76.997513,192.745651
	C76.995392,192.328690 76.993942,192.161896 76.996246,191.997559
	C77.000000,192.000000 76.995682,191.992798 77.316559,191.967468
	C78.424347,191.294357 79.211266,190.646561 80.370071,189.996506
	C82.155563,189.656769 83.569160,189.319290 85.369568,188.980759
	C88.506294,187.656433 91.256218,186.333176 94.338356,185.005585
	C95.113258,185.000397 95.555946,184.999512 96.243271,185.265610
	C103.117058,185.688110 109.746696,185.970230 116.375145,185.944595
	C119.584251,185.932159 122.791122,185.342148 126.373123,185.007858
	C127.495338,185.006134 128.243439,185.010193 129.093842,185.403961
	C133.813095,186.177643 138.426453,186.636398 143.051193,186.857040
	C143.674194,186.886749 144.354401,185.717545 145.411926,185.077240
	C146.889984,185.136063 147.964264,185.221115 149.049438,185.698517
	C149.692459,186.710815 150.270660,187.797882 150.965118,187.878036
	C157.471451,188.629105 164.034027,189.922409 170.505783,189.593216
	C175.069351,189.361069 179.517441,186.858856 184.454605,185.264648
	C187.570602,184.951401 190.249084,184.743683 193.324249,184.616882
	C195.011536,184.464813 196.293198,184.141373 197.594116,184.013016
	C209.534729,182.834717 220.548523,179.032730 230.040680,171.658508
	C233.060364,169.312592 235.150345,165.769943 237.975555,162.637451
	C240.506821,159.641235 242.728271,156.782471 244.949738,153.923706
	C244.949738,153.923706 244.986465,153.989441 245.274338,154.010452
	C245.699768,153.659348 245.837326,153.287231 245.974884,152.915115
	C245.974884,152.915115 245.985397,152.985703 246.311310,153.040771
	C247.740051,151.371994 248.842865,149.648132 249.945679,147.924286
	C249.945663,147.924271 249.988449,147.993622 250.317291,148.062943
	C251.411743,146.729630 252.177338,145.326996 252.942932,143.924362
	C252.942932,143.924362 252.986923,143.989166 253.276764,144.004776
	C253.707703,143.627960 253.848801,143.235519 253.989868,142.843094
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M269.244293,418.354095
	C269.165955,416.529327 269.087616,414.704590 269.323486,412.164978
	C269.556946,406.929993 270.335358,402.189423 269.242523,397.928864
	C265.135040,381.915405 254.580872,369.712677 244.061111,357.601715
	C236.663040,349.084595 227.182053,343.508118 216.963715,338.713013
	C216.287720,338.284241 215.643997,338.143646 214.650116,337.999176
	C213.200027,337.662323 212.100067,337.329315 210.938568,336.659058
	C209.584503,336.215637 208.291977,336.109497 206.655060,335.967773
	C205.207504,335.622467 204.104355,335.312744 202.755066,334.729095
	C192.668289,332.650421 182.864944,329.665009 172.977097,329.352478
	C161.687637,328.995575 150.320511,330.991302 138.992249,332.020905
	C134.723328,332.408936 130.479309,333.111633 126.205650,333.399384
	C111.011978,334.422302 95.569641,338.075073 81.323776,328.980988
	C75.607674,325.332031 68.781868,323.080322 66.344788,316.038300
	C67.864151,316.955566 69.160530,317.711487 70.193367,318.733643
	C76.554146,325.028595 84.748802,327.561066 93.268333,330.275146
	C95.357964,330.700623 97.178947,330.850922 99.256332,331.271851
	C101.008278,331.694611 102.503838,331.846710 104.332626,331.999390
	C105.110176,332.000000 105.554474,332.000000 106.466766,332.000427
	C116.193535,331.532959 125.474487,331.322906 134.705246,330.529083
	C145.220154,329.624908 155.664612,327.680725 166.187729,327.160553
	C175.089981,326.720459 184.060760,327.667084 193.294571,328.127808
	C194.392151,328.503326 195.196381,328.751068 196.395309,329.050842
	C202.290070,330.087952 207.764267,331.281738 213.301331,331.968414
	C215.999420,332.303009 218.814896,331.691315 221.577225,331.507935
	C220.384598,329.005280 219.191971,326.502655 217.999603,323.624939
	C217.999680,322.499817 217.999481,321.749695 218.112778,320.784302
	C218.426956,320.300629 218.685287,320.110535 219.356400,320.038361
	C225.155350,324.566193 230.597824,329.056030 236.043793,333.541626
	C237.567139,334.796326 238.960159,336.287018 240.653122,337.245087
	C250.061188,342.569397 259.543243,347.762939 269.005432,353.367188
	C271.957886,358.859070 274.899963,363.987854 277.866272,369.102570
	C278.017426,369.363159 278.350464,369.608185 278.643921,369.682709
	C282.546600,370.673462 283.044312,373.670868 282.604156,377.000916
	C277.922791,377.004822 278.342407,380.228119 278.197906,382.972443
	C278.097260,384.883606 278.182892,386.806915 278.236328,388.723389
	C278.354218,392.952332 278.166962,397.225098 278.720093,401.398346
	C279.559937,407.734375 281.076752,413.978149 282.020569,420.303680
	C282.968231,426.654755 283.022217,433.182556 284.447296,439.408722
	C286.500397,448.378723 289.438416,457.146118 291.655029,465.941772
	C288.872833,460.745178 286.005188,455.758148 284.123932,450.423279
	C282.444153,445.659607 282.255005,440.380524 280.680389,435.566895
	C278.738861,429.631653 278.432800,429.760010 274.002350,433.678558
	C273.591949,434.041504 272.731598,433.895721 271.906189,433.967743
	C271.732178,433.949310 271.382202,433.950806 271.288513,433.495453
	C270.544617,428.144775 269.894440,423.249420 269.244293,418.354095
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M203.001190,335.003052
	C204.104355,335.312744 205.207504,335.622467 206.893982,336.231995
	C208.651550,336.686676 209.825836,336.841492 211.000107,336.996338
	C212.100067,337.329315 213.200027,337.662323 214.822556,338.238129
	C215.895432,338.654358 216.445709,338.827789 216.996002,339.001221
	C227.182053,343.508118 236.663040,349.084595 244.061111,357.601715
	C254.580872,369.712677 265.135040,381.915405 269.242523,397.928864
	C270.335358,402.189423 269.556946,406.929993 269.383209,411.711792
	C267.076385,405.884094 265.313934,399.675415 262.872345,393.746399
	C261.173431,389.620880 258.541901,385.879395 256.283936,381.274475
	C255.464905,379.732971 254.688980,378.883331 253.913040,378.033722
	C253.913040,378.033722 253.987015,378.007843 254.093140,377.712402
	C253.771088,376.945312 253.342911,376.473633 252.914734,376.001923
	C252.914734,376.001923 252.988480,376.007355 252.981842,375.729187
	C252.621307,375.311859 252.267426,375.172729 251.913544,375.033630
	C251.913544,375.033630 251.984619,375.014862 252.019943,374.710205
	C251.675507,374.286530 251.295731,374.167542 250.915970,374.048523
	C250.915970,374.048523 250.999695,374.007690 251.149292,373.723328
	C250.837921,372.959473 250.376953,372.480042 249.915985,372.000610
	C249.915985,372.000610 249.990051,372.006348 249.977356,371.734985
	C249.615204,371.317291 249.265762,371.170959 248.916321,371.024628
	C248.916321,371.024628 248.986328,371.013519 248.985306,370.733124
	C248.627533,370.312103 248.270782,370.171448 247.914032,370.030823
	C247.914017,370.030823 247.984451,370.015350 247.998383,369.726929
	C247.646332,369.304291 247.280350,369.170044 246.914368,369.035828
	C246.914368,369.035828 246.984985,369.014893 247.013672,368.716583
	C246.666824,368.293060 246.291275,368.167847 245.915726,368.042633
	C245.915726,368.042633 245.987045,368.012970 246.033203,367.708069
	C245.691895,367.283905 245.304428,367.164673 244.916946,367.045441
	C244.916946,367.045441 244.988358,367.011658 245.040543,366.705841
	C244.700882,366.281769 244.309036,366.163513 243.917191,366.045288
	C243.917191,366.045288 243.988403,366.011566 244.040131,365.706055
	C243.700348,365.281982 243.308823,365.163422 242.917297,365.044861
	C242.917297,365.044861 242.988373,365.011627 243.039413,364.706482
	C242.699402,364.282349 242.308350,364.163422 241.917297,364.044464
	C241.917297,364.044464 241.988266,364.011719 242.038452,363.706757
	C241.697937,363.283020 241.307220,363.164246 240.916489,363.045471
	C240.916489,363.045502 240.987991,363.011993 241.037781,362.709229
	C240.696762,362.285858 240.305969,362.165222 239.915192,362.044586
	C239.915192,362.044586 239.986481,362.013458 240.025330,361.718750
	C239.681091,361.295013 239.298004,361.165955 238.914902,361.036896
	C238.914902,361.036896 238.984833,361.015564 238.996582,360.732300
	C238.643738,360.309174 238.279144,360.169373 237.914566,360.029541
	C237.914566,360.029541 237.984802,360.015320 237.988129,359.736847
	C237.633438,359.313507 237.275421,359.168610 236.917389,359.023743
	C236.917389,359.023743 236.987747,359.014618 236.974792,358.686707
	C235.949402,357.924622 234.936996,357.490387 233.924591,357.056152
	C233.924591,357.056152 233.989822,357.011322 234.001526,356.731628
	C233.648026,356.309326 233.282791,356.166656 232.917572,356.023987
	C232.917572,356.023987 232.987778,356.014618 232.978424,355.687622
	C231.954193,354.923218 230.939331,354.485809 229.924454,354.048431
	C229.924454,354.048431 229.991119,354.014191 230.003357,353.682953
	C228.985382,352.918823 227.955185,352.485931 226.924988,352.053040
	C226.924988,352.053040 226.992752,352.012360 227.027313,351.677307
	C226.016296,350.912476 224.970718,350.482727 223.925156,350.052979
	C223.925156,350.052979 223.992355,350.013000 224.017334,349.685974
	C222.336807,348.588623 220.631317,347.818268 218.925827,347.047943
	C218.925827,347.047943 218.990646,347.018372 218.907532,346.718384
	C217.928635,346.274017 217.032867,346.129608 216.137085,345.985199
	C215.567093,345.660431 214.997116,345.335663 214.023224,344.581299
	C212.042999,343.365753 210.542572,342.181274 208.876968,341.863983
	C202.267700,340.604950 195.606812,339.617035 188.609192,338.355194
	C182.179199,338.285278 176.105057,338.388763 169.583496,338.589233
	C166.095306,338.729431 163.054550,338.772675 159.978455,338.513489
	C159.630325,337.464813 159.317520,336.718597 159.266205,335.695984
	C168.706543,335.119629 177.883621,334.704773 187.064911,334.563721
	C192.373505,334.482117 197.688873,334.841919 203.001190,335.003052
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M202.860626,272.100281
	C203.582062,274.098816 204.303497,276.097321 204.978729,278.850555
	C206.649246,282.390198 208.365967,285.175171 210.082687,287.960114
	C210.082687,287.960114 210.009766,287.995117 209.881332,288.297333
	C210.199142,289.060577 210.645386,289.521606 211.091644,289.982635
	C211.091644,289.982635 211.018402,289.989044 210.898895,290.313263
	C211.548203,291.411255 212.317001,292.184998 213.085800,292.958771
	C213.085800,292.958771 213.010773,292.994049 212.880692,293.311371
	C213.529053,294.406006 214.307510,295.183289 215.085968,295.960602
	C215.085968,295.960602 215.015961,295.986389 214.976639,296.291412
	C215.319443,296.716064 215.701569,296.835693 216.083710,296.955292
	C216.083710,296.955292 216.011932,296.988007 215.948883,297.301941
	C216.284637,297.727783 216.683456,297.839722 217.082275,297.951630
	C217.082275,297.951630 217.000931,297.993561 216.832184,298.299866
	C217.470947,299.391846 218.278473,300.177551 219.085999,300.963257
	C219.085999,300.963257 219.016296,300.985840 218.986542,301.280579
	C219.333099,301.704681 219.709412,301.834076 220.085724,301.963440
	C220.085724,301.963440 220.015152,301.984955 219.981018,302.284973
	C220.325958,302.710175 220.705048,302.835388 221.084122,302.960571
	C221.084122,302.960571 221.013672,302.986176 220.970459,303.290100
	C221.312454,303.715759 221.697678,303.837494 222.082916,303.959229
	C222.082916,303.959229 222.012405,303.987640 221.955124,304.301147
	C222.292374,304.727448 222.686890,304.840240 223.081421,304.953064
	C223.081421,304.953064 223.009888,304.990021 222.935455,305.307220
	C223.267700,305.733459 223.674377,305.842499 224.081055,305.951538
	C224.081055,305.951538 224.009323,305.990631 223.932526,306.308838
	C224.264130,306.734894 224.672531,306.842743 225.080917,306.950592
	C225.080917,306.950592 225.000244,306.994019 224.827133,307.300415
	C226.458878,309.049103 228.263748,310.491364 230.068619,311.933624
	C230.068619,311.933624 230.006027,311.997925 229.937256,312.308533
	C230.272873,312.730438 230.677246,312.841675 231.081619,312.952942
	C231.081619,312.952942 231.010483,312.989563 230.944092,313.302124
	C231.279449,313.727264 231.681198,313.839874 232.082947,313.952484
	C232.082947,313.952484 232.010941,313.989136 231.943207,314.302490
	C232.278214,314.727448 232.680954,314.839081 233.083694,314.950714
	C233.083694,314.950714 233.011292,314.988770 232.950378,315.290131
	C233.287857,315.715271 233.686234,315.839020 234.084625,315.962769
	C234.084625,315.962769 234.014908,315.984558 233.995300,316.271118
	C234.345169,316.696228 234.714676,316.834747 235.084183,316.973267
	C235.084183,316.973267 235.013824,316.984497 234.998352,317.328308
	C236.011154,318.090546 237.039413,318.508942 238.067673,318.927307
	C238.067673,318.927338 238.006561,319.000854 237.931580,319.308594
	C238.265793,319.727875 238.675003,319.839417 239.084198,319.950928
	C239.084198,319.950928 239.011642,319.988373 238.952087,320.291260
	C239.289993,320.716125 239.687469,320.838135 240.084961,320.960144
	C240.084961,320.960144 240.014938,320.984619 239.991211,321.270264
	C240.340408,321.694916 240.713333,321.833923 241.086258,321.972931
	C241.086258,321.972931 241.014160,321.982605 241.058899,322.331360
	C241.757004,322.759888 242.410370,322.839691 243.063721,322.919464
	C243.063721,322.919464 243.008484,323.001221 242.920593,323.307068
	C243.249924,323.726898 243.667175,323.840851 244.084412,323.954803
	C244.084412,323.954803 244.013489,323.986542 243.970001,324.282104
	C244.312393,324.707672 244.698288,324.837708 245.084183,324.967743
	C245.084183,324.967743 245.013168,324.981781 245.069366,325.317657
	C245.772491,325.744476 246.419418,325.835419 247.066345,325.926392
	C247.066345,325.926392 247.007126,326.001312 246.935883,326.304108
	C247.271011,326.723724 247.677383,326.840546 248.083771,326.957336
	C248.083771,326.957336 248.006210,326.988342 247.876831,327.300659
	C247.811890,327.856781 247.876343,328.100616 247.964752,328.693176
	C249.223404,330.472809 250.212601,332.290192 251.739960,333.260193
	C255.900162,335.902252 260.274261,338.210541 264.588348,340.606628
	C272.166351,344.815582 278.927002,350.672119 287.994659,351.881226
	C290.383331,352.199768 292.672089,353.267365 294.759613,354.259155
	C292.750610,354.344910 290.948242,354.341034 289.233337,353.951782
	C276.010681,350.950500 262.926697,347.934235 251.329010,340.051147
	C237.817429,330.867218 227.405380,318.629059 216.616730,306.776886
	C208.988571,298.396820 203.502914,288.572632 201.986298,276.584320
	C201.990021,274.790955 201.990234,273.411774 202.206879,272.063782
	C202.423325,272.094910 202.860626,272.100281 202.860626,272.100281
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M208.895905,229.920624
	C210.667221,226.349884 212.431610,222.775681 214.223145,219.215134
	C214.363327,218.936523 214.795853,218.798721 214.919510,218.518997
	C219.855682,207.352493 229.282257,199.889771 237.453186,191.426407
	C239.425400,189.383606 242.523209,188.472366 244.713181,186.587402
	C255.887344,176.969498 270.339996,175.948196 284.177124,171.270111
	C286.569824,170.999969 288.283173,170.999374 290.129333,171.301392
	C290.512543,172.440140 290.762939,173.276306 290.680023,174.293549
	C286.911469,175.596970 283.476257,176.719299 280.041016,177.841644
	C270.618286,182.489700 261.195557,187.137772 251.128525,191.928711
	C250.335022,192.410004 250.185822,192.748428 250.036652,193.086853
	C250.036652,193.086853 250.009674,193.009979 249.690918,192.880005
	C248.594833,193.528534 247.817505,194.307037 247.040161,195.085541
	C247.040161,195.085541 247.006042,195.010818 246.692200,194.881592
	C245.597153,195.530273 244.815964,196.308197 244.034790,197.086121
	C244.034790,197.086121 244.014206,197.016296 243.710709,196.986206
	C243.286804,197.332199 243.166397,197.708267 243.045990,198.084335
	C243.045990,198.084335 243.007324,198.003265 242.701508,197.848969
	C241.608109,198.491791 240.820511,199.288925 240.032913,200.086060
	C240.032913,200.086060 240.013931,200.016205 239.710251,199.984543
	C239.285980,200.329910 239.165390,200.706940 239.044800,201.083969
	C239.044800,201.083969 239.006989,201.004700 238.703278,200.859680
	C237.607834,201.504974 236.816071,202.295303 236.024323,203.085632
	C236.024338,203.085632 236.013565,203.015213 235.733154,203.011932
	C235.310608,203.367691 235.168488,203.726730 235.026367,204.085754
	C235.026367,204.085754 235.014954,204.015228 234.725952,204.003876
	C234.302917,204.357056 234.168884,204.721603 234.034882,205.086151
	C234.034882,205.086151 234.015274,205.015472 233.716278,204.983093
	C233.292007,205.329086 233.166718,205.707474 233.041412,206.085846
	C233.041412,206.085846 233.014221,206.014267 232.702850,205.965179
	C232.278122,206.305527 232.164764,206.694962 232.051392,207.084396
	C232.051392,207.084396 232.010635,207.010529 231.694824,206.940094
	C231.270554,207.274399 231.162079,207.679138 231.053619,208.083878
	C231.053619,208.083878 231.009445,208.009445 230.698685,207.935181
	C230.273697,208.268219 230.159454,208.675537 230.045227,209.082855
	C230.045212,209.082855 230.012329,209.012222 229.710114,208.962997
	C229.284271,209.303162 229.160675,209.692551 229.037079,210.081940
	C229.037079,210.081940 229.012375,210.012482 228.701050,209.962219
	C228.274841,210.301682 228.159958,210.691376 228.045059,211.081085
	C228.045059,211.081085 228.006042,211.002243 227.707825,210.839218
	C223.354538,214.253128 219.132721,217.664886 215.336060,221.497803
	C214.031128,222.815216 213.788177,225.184555 213.060089,227.073349
	C213.060089,227.073349 213.007416,227.007248 212.713745,226.961304
	C212.290573,227.304901 212.161087,227.694427 212.031616,228.083954
	C212.031616,228.083954 212.015778,228.013702 211.729553,227.984436
	C210.634323,228.640244 209.825302,229.325302 208.986313,229.987762
	C208.956345,229.965164 208.895920,229.920624 208.895905,229.920624
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M408.049805,477.171753
	C407.585022,477.736389 407.120239,478.301056 406.007507,478.947510
	C403.706329,479.717133 401.855133,480.126221 400.431763,481.137512
	C390.369812,488.286163 380.361755,495.512543 370.439301,502.853119
	C369.290619,503.702911 368.772614,505.405273 367.516479,506.806274
	C356.368286,507.007477 345.665802,507.114716 334.917358,506.853760
	C334.125336,505.979431 333.377869,505.475464 332.633575,504.966827
	C328.753265,502.315094 324.873718,499.662231 321.350830,497.045898
	C322.619873,497.471161 323.650940,497.699768 324.426086,498.273865
	C333.820770,505.231995 344.499634,506.152161 354.984344,502.755432
	C363.709778,499.928619 372.016876,495.439026 380.009125,490.820496
	C388.830444,485.722931 397.663727,480.488770 403.883545,471.829559
	C406.583862,468.070129 410.585663,465.245514 414.334717,461.999146
	C415.124237,462.009827 415.575317,462.017731 415.979767,462.375183
	C415.415253,463.531067 414.839050,464.306305 414.389496,465.149139
	C412.257355,469.146606 410.159729,473.162476 408.049805,477.171753
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M320.993896,497.009766
	C324.873718,499.662231 328.753265,502.315094 332.633575,504.966827
	C333.377869,505.475464 334.125336,505.979431 334.480988,506.866699
	C330.738373,506.795013 327.386169,506.342194 323.976929,505.557465
	C318.469147,500.620758 313.206177,495.759064 307.459747,491.558807
	C305.599182,490.198944 302.470795,490.573608 299.589294,490.071533
	C298.811249,490.008301 298.366333,490.028381 297.921387,490.048462
	C297.921387,490.048431 297.986206,490.011383 297.984711,489.735229
	C297.627563,489.316101 297.271912,489.173096 296.916260,489.030121
	C296.916260,489.030121 296.985840,489.014313 296.983429,488.737183
	C296.626160,488.316559 296.271301,488.173096 295.916443,488.029663
	C295.916443,488.029663 295.985992,488.014130 295.982849,487.737244
	C295.625305,487.316742 295.270874,487.173187 294.916443,487.029633
	C294.916443,487.029633 294.985962,487.014160 294.982666,486.736633
	C294.624756,486.316681 294.270111,486.174194 293.915466,486.031708
	C293.915466,486.031708 293.985321,486.014679 293.988892,485.732056
	C293.633118,485.311890 293.273773,485.174316 292.914459,485.036774
	C292.914459,485.036774 292.985077,485.014832 293.001160,484.725769
	C292.649780,484.304901 292.282288,484.173096 291.914795,484.041290
	C291.914795,484.041290 291.994019,484.008636 292.117767,483.693726
	C291.466522,482.594818 290.691528,481.810852 289.916534,481.026886
	C289.916534,481.026886 289.985626,481.012054 289.980469,480.735046
	C289.622040,480.316101 289.268768,480.174133 288.915497,480.032135
	C288.915497,480.032135 288.985352,480.014771 288.987335,479.732819
	C288.630920,479.313049 288.272491,479.175201 287.914093,479.037354
	C287.914093,479.037354 287.985443,479.014496 288.011414,478.718994
	C287.663330,478.297760 287.289246,478.172089 286.915161,478.046417
	C286.915161,478.046417 286.996674,478.007996 287.125824,477.695007
	C286.475342,476.597870 285.695709,475.813751 284.916077,475.029633
	C284.916077,475.029633 284.985107,475.012817 284.981293,474.734497
	C284.623138,474.315796 284.268829,474.175385 283.914490,474.034973
	C283.914490,474.034973 283.990326,474.009583 284.090210,473.712708
	C283.764893,472.945709 283.339691,472.475647 282.914490,472.005585
	C282.914490,472.005585 282.986633,472.009888 282.978210,471.734009
	C282.618073,471.316895 282.266388,471.175659 281.914673,471.034393
	C281.914673,471.034393 281.989868,471.009888 282.096039,470.683868
	C281.439941,469.585724 280.677643,468.813599 279.915375,468.041473
	C279.915375,468.041473 279.990234,468.004852 280.099182,467.705750
	C279.775513,466.939606 279.342957,466.472565 278.910370,466.005524
	C278.910370,466.005524 278.983246,466.009735 278.999756,465.689331
	C278.070648,463.946991 277.125000,462.525055 276.179382,461.103119
	C274.771545,457.336609 273.363708,453.570099 272.183136,449.153961
	C272.067688,443.653168 271.724945,438.801971 271.382202,433.950806
	C271.382202,433.950806 271.732178,433.949310 271.930359,434.339172
	C274.106049,439.402710 276.050018,444.090881 278.069946,448.746124
	C280.370758,454.048706 282.320831,459.559570 285.161011,464.560974
	C292.213898,476.980804 302.946259,485.729279 315.048401,493.345062
	C316.736664,494.784363 318.368958,495.890961 320.249390,496.999634
	C320.497589,497.001740 320.993896,497.009766 320.993896,497.009766
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M422.828796,66.964111
	C422.890778,67.736092 422.952728,68.508064 423.080017,69.984772
	C423.436554,71.457977 423.727753,72.226456 424.025696,73.373405
	C424.005249,74.504929 423.978058,75.257980 423.577026,76.007843
	C421.962555,75.375046 420.244598,75.067551 419.564545,74.059700
	C416.717041,69.839600 414.044769,65.485664 411.556946,61.041569
	C405.135986,49.571587 397.289703,39.527439 385.528442,32.967529
	C376.837158,28.119898 368.387054,22.841156 359.727234,17.934956
	C356.920074,16.344566 354.674713,15.065507 357.522827,11.630539
	C357.976257,11.083686 357.840546,10.048338 358.366455,9.164832
	C359.811829,9.116261 360.867889,9.141686 362.058533,9.413580
	C362.790100,10.073294 363.387146,10.486540 363.991211,11.280852
	C366.107300,16.809698 369.781586,20.259777 375.399902,21.950077
	C376.132507,22.318029 376.579773,22.646742 377.072021,23.320274
	C378.075806,24.103682 379.034637,24.542269 379.998596,24.986420
	C380.003754,24.991982 380.020050,25.000544 380.068298,25.342766
	C381.272980,26.551334 382.409943,27.445271 383.589294,28.279179
	C388.299774,31.609926 393.692444,34.286800 397.587036,38.388390
	C403.577240,44.697025 408.390198,52.106987 414.010803,58.790539
	C416.570496,61.834332 419.866699,64.258751 422.828796,66.964111
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M45.152184,306.921692
	C44.494884,305.855774 43.837582,304.789886 43.111607,303.005554
	C42.861828,300.172699 42.680721,298.058258 42.791531,296.047363
	C45.371361,298.740906 47.651173,301.338135 49.948673,303.919647
	C57.815865,312.759399 64.629372,322.762451 75.285400,328.633331
	C80.598587,331.560638 86.401474,333.599060 92.013657,336.353760
	C92.359520,337.444458 92.674568,338.221008 92.596817,338.991486
	C85.891792,336.969879 79.579575,334.954346 72.869926,332.884155
	C71.957840,332.906708 71.443176,332.983887 70.928513,333.061066
	C70.928513,333.061066 70.996506,333.008545 71.023148,332.672668
	C70.009109,331.911987 68.968437,331.487183 67.927765,331.062347
	C67.927765,331.062347 67.991096,331.006104 67.993988,330.730835
	C67.636795,330.314941 67.276703,330.174377 66.916611,330.033813
	C66.916611,330.033813 66.987869,330.015015 66.999344,329.678162
	C65.982979,328.914276 64.955132,328.487213 63.927292,328.060150
	C63.927292,328.060150 63.990871,328.007294 63.989323,327.733337
	C63.630852,327.317230 63.273926,327.175140 62.917007,327.033020
	C62.917011,327.033020 62.989986,327.015656 63.033302,326.699219
	C59.024082,322.603973 54.971546,318.825134 50.919010,315.046295
	C50.919010,315.046295 50.985600,315.010193 50.982597,314.734253
	C50.624931,314.317780 50.270260,314.177246 49.915588,314.036713
	C49.915585,314.036713 49.992249,314.008759 50.083611,313.711609
	C49.755119,312.945282 49.335266,312.476135 48.915413,312.006958
	C48.915413,312.006958 48.987988,312.013489 48.974373,311.741272
	C48.612873,311.323364 48.264992,311.177704 47.917107,311.032013
	C47.917110,311.032013 47.986988,311.013000 47.983246,310.734222
	C47.624714,310.316620 47.269932,310.177765 46.915142,310.038940
	C46.915138,310.038940 46.995163,310.008942 47.109818,309.707642
	C46.533710,308.578156 45.842945,307.749908 45.152184,306.921692
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M92.989624,338.997559
	C92.674568,338.221008 92.359520,337.444458 92.426102,336.341644
	C96.027191,336.674438 99.265297,337.973358 102.462814,337.880157
	C115.255295,337.507172 128.033234,336.658936 140.820847,336.081512
	C144.872040,335.898621 148.936600,336.011505 153.372116,335.993103
	C154.503586,335.992004 155.257965,335.986847 156.386307,335.982910
	C157.508408,335.980194 158.256561,335.976288 159.004715,335.972351
	C159.317520,336.718597 159.630325,337.464813 159.562805,338.612701
	C155.027252,339.983673 150.872040,340.952972 146.716812,341.922302
	C146.716812,341.922302 146.589264,342.063080 146.066223,341.889771
	C144.032318,341.856995 142.521484,341.997498 140.737183,341.821655
	C135.246857,341.669006 130.029846,341.828979 124.813095,341.997223
	C114.078140,342.343445 103.394829,342.147278 92.989624,338.997559
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M422.874969,402.124451
	C423.579041,404.474121 424.283112,406.823761 424.649170,409.550537
	C424.131989,409.920441 423.952850,409.913239 423.531311,409.693665
	C421.469971,407.274902 419.507446,405.170197 417.859070,402.843018
	C412.973907,395.946381 408.721100,388.524811 403.240875,382.151276
	C398.914703,377.119995 393.138306,373.335693 388.002991,368.998779
	C388.000000,369.000000 388.000732,368.994293 387.850464,368.688721
	C385.466583,366.261536 383.232971,364.139954 381.363464,362.008850
	C383.475220,362.071259 385.222870,362.143097 387.335449,362.253601
	C388.156097,362.172180 388.611755,362.052094 389.067444,361.931976
	C389.067444,361.931976 389.008881,361.994385 388.993408,362.273895
	C389.347168,362.692383 389.716431,362.831390 390.085693,362.970367
	C390.085693,362.970367 390.013977,362.981812 390.070068,363.310547
	C390.774139,363.737213 391.422119,363.835144 392.070068,363.933044
	C392.070068,363.933044 392.007660,363.995361 391.971497,364.289307
	C392.318726,364.708160 392.702118,364.833069 393.085541,364.957947
	C393.085541,364.957947 393.014709,364.985138 392.985931,365.273438
	C393.333313,365.697235 393.709473,365.832764 394.085632,365.968262
	C394.085632,365.968262 394.015442,365.984161 394.014282,366.263123
	C394.370697,366.685089 394.728302,366.828094 395.085907,366.971130
	C395.085907,366.971130 395.014862,366.983154 395.003479,367.282166
	C395.690460,368.047302 396.388855,368.513367 397.087280,368.979431
	C397.087280,368.979431 397.013947,368.985168 397.091370,369.301117
	C397.802032,369.720123 398.435242,369.823181 399.068481,369.926208
	C399.068481,369.926208 399.010406,370.001801 398.977478,370.290955
	C399.324371,370.703247 399.704224,370.826447 400.084076,370.949615
	C400.084076,370.949615 400.011139,370.988983 399.981354,371.281189
	C400.329193,371.699768 400.706818,371.826141 401.084442,371.952515
	C401.084442,371.952515 401.012238,371.987823 400.988281,372.278809
	C401.337738,372.697479 401.711151,372.825226 402.084564,372.952942
	C402.084564,372.952942 402.012329,372.987671 401.988159,373.278870
	C402.337494,373.697662 402.710999,373.825287 403.084503,373.952881
	C403.084503,373.952881 403.011932,373.987946 402.983948,374.283661
	C403.331818,374.702576 403.707703,374.825775 404.083557,374.948975
	C404.083557,374.948975 404.009979,374.989777 403.972412,375.287598
	C404.317627,375.706451 404.700409,375.827515 405.083191,375.948547
	C405.083191,375.948547 404.998566,375.991241 404.858765,376.304199
	C406.173248,378.061523 407.627594,379.505890 409.081909,380.950256
	C409.081909,380.950256 409.015015,380.989136 409.012299,381.268555
	C409.368134,381.685211 409.726654,381.822449 410.085205,381.959686
	C410.085205,381.959686 410.013977,381.985901 410.000641,382.272919
	C410.353058,382.692017 410.718842,382.824066 411.084595,382.956146
	C411.084595,382.956146 411.004028,382.991180 410.884094,383.311279
	C411.537720,384.408112 412.311249,385.184845 413.084778,385.961578
	C413.084778,385.961578 413.015961,385.986786 413.017944,386.266479
	C413.375244,386.683563 413.730621,386.820984 414.085968,386.958405
	C414.085968,386.958405 414.013794,386.985962 413.993591,387.278107
	C414.343811,387.697205 414.714264,387.824127 415.084717,387.951080
	C415.084717,387.951080 415.000397,387.990845 414.866425,388.311707
	C415.516388,389.406433 416.300354,390.180298 417.084290,390.954132
	C417.084290,390.954132 417.009552,390.996094 416.907257,391.319794
	C417.564758,392.414459 418.324554,393.185455 419.084351,393.956421
	C419.084351,393.956421 419.010406,393.996033 418.910034,394.322144
	C420.164795,397.140350 421.519897,399.632385 422.874969,402.124451
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M295.006744,353.990967
	C292.672089,353.267365 290.383331,352.199768 287.994659,351.881226
	C278.927002,350.672119 272.166351,344.815582 264.588348,340.606628
	C260.274261,338.210541 255.900162,335.902252 251.739960,333.260193
	C250.212601,332.290192 249.223404,330.472809 248.336243,328.677979
	C249.144196,328.185516 249.604630,328.057037 250.065063,327.928558
	C250.065063,327.928558 250.001831,327.997681 249.932617,328.332245
	C250.933380,329.092316 252.003372,329.517792 253.073349,329.943298
	C253.073349,329.943298 253.005005,329.989716 252.967819,330.315735
	C254.645279,331.412476 256.359924,332.183228 258.074585,332.953949
	C258.074585,332.953949 258.009247,332.983490 258.056946,333.281219
	C260.357483,334.401001 262.610321,335.223053 264.863159,336.045105
	C267.002533,337.019653 269.141937,337.994232 271.813782,339.373566
	C273.253632,339.831360 274.161041,339.884369 275.068481,339.937378
	C275.068481,339.937378 275.007507,339.983887 275.042725,340.319885
	C278.645050,341.916595 282.212219,343.177307 285.913452,344.732086
	C293.270477,351.207397 302.134491,352.654602 311.543518,353.778595
	C313.625793,353.925171 315.294952,354.014618 316.973206,354.336700
	C316.982330,354.569336 316.996246,355.034760 316.697845,355.313110
	C309.268555,355.057953 302.137634,354.524475 295.006744,353.990967
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M92.596817,338.991486
	C103.394829,342.147278 114.078140,342.343445 124.813095,341.997223
	C130.029846,341.828979 135.246857,341.669006 140.473419,342.072998
	C125.348885,346.430481 110.202667,348.195465 94.935616,343.238647
	C92.004951,342.287140 88.932045,341.773712 85.924759,341.058197
	C85.924759,341.058197 85.984779,341.014069 85.909149,340.712524
	C85.196045,340.293030 84.558563,340.175140 83.921082,340.057251
	C83.921082,340.057220 83.981247,340.016266 83.883286,339.724304
	C82.901833,339.267487 82.018341,339.102631 81.134857,338.937744
	C78.525131,337.596619 75.915413,336.255493 73.236603,334.264221
	C73.200798,333.389008 73.234077,333.163910 73.267357,332.938812
	C79.579575,334.954346 85.891792,336.969879 92.596817,338.991486
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M422.897461,66.580215
	C419.866699,64.258751 416.570496,61.834332 414.010803,58.790539
	C408.390198,52.106987 403.577240,44.697025 397.587036,38.388390
	C393.692444,34.286800 388.299774,31.609926 383.589294,28.279179
	C382.409943,27.445271 381.272980,26.551334 380.435089,25.373482
	C384.165070,26.084761 387.576508,27.107544 391.345276,28.179461
	C392.158325,28.132034 392.614014,28.035473 393.069733,27.938910
	C393.069733,27.938908 393.000092,27.993277 392.918213,28.278259
	C393.540833,29.406710 394.245331,30.250177 395.060913,31.306625
	C395.378723,31.784559 395.646851,31.950001 396.331879,32.096085
	C397.148743,32.096992 397.610138,32.017742 398.071503,31.938492
	C398.071503,31.938492 398.008759,31.990892 397.996033,32.274944
	C398.350922,32.694553 398.718536,32.830112 399.086151,32.965675
	C399.086151,32.965672 399.015808,32.983921 399.005951,33.266151
	C399.359283,33.689190 399.722504,33.829994 400.085693,33.970802
	C400.085693,33.970806 400.015320,33.984505 400.014313,34.263554
	C400.370636,34.685661 400.727997,34.828724 401.085327,34.971786
	C401.085327,34.971783 401.015045,34.984795 401.014771,35.262547
	C401.371216,35.684753 401.727905,35.829208 402.084595,35.973663
	C402.084595,35.973660 402.013794,35.985355 401.967224,36.307816
	C402.974518,37.406734 404.028381,38.183197 405.082245,38.959663
	C405.082245,38.959667 405.014740,38.985817 405.015076,39.263893
	C405.372162,39.685196 405.728882,39.828423 406.085602,39.971649
	C406.085602,39.971649 406.015198,39.984676 406.014374,40.265167
	C406.371216,40.686787 406.728912,40.827911 407.086578,40.969032
	C407.086578,40.969028 407.015991,40.984138 407.006439,41.268360
	C407.360138,41.690800 407.723358,41.829014 408.086609,41.967224
	C408.086609,41.967224 408.012085,41.990036 407.902283,42.310860
	C409.222778,44.074760 410.653107,45.517838 412.083435,46.960915
	C412.083435,46.960915 412.015259,46.985634 412.011902,47.268539
	C412.367859,47.689774 412.727112,47.828102 413.086395,47.966431
	C413.086395,47.966431 413.009460,47.991119 412.881531,48.285728
	C413.456085,49.394962 414.158569,50.209587 414.861023,51.024216
	C417.562714,56.081585 420.264435,61.138954 422.897461,66.580215
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M94.918800,177.964920
	C99.600471,176.972290 104.315292,175.006241 108.957787,175.164307
	C124.679962,175.699585 140.374222,177.745956 156.083450,177.793686
	C175.434006,177.852448 194.986908,180.323242 214.106323,175.000092
	C214.415787,174.913925 214.768784,174.984070 215.395660,175.273743
	C211.455154,177.052017 207.220444,178.537796 202.576691,180.012878
	C199.769974,180.265579 196.638962,178.970520 195.646790,182.928284
	C194.882523,182.942154 194.445679,182.944305 193.915161,182.574707
	C191.536270,181.814468 189.251038,181.425995 186.504883,181.033997
	C174.999161,180.416153 163.954407,179.801804 152.479828,179.149628
	C146.660568,178.540634 141.271133,177.969498 135.437637,177.307861
	C122.320152,176.025986 109.656349,175.278000 96.710831,178.016434
	C95.874908,177.983353 95.396851,177.974136 94.918800,177.964920
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M76.998169,192.995804
	C75.252525,195.269333 73.438431,197.494202 71.778969,199.828979
	C69.809258,202.600250 67.972603,205.466080 65.789215,208.645660
	C65.333221,209.000870 65.166306,209.001358 64.713379,209.146545
	C63.951889,210.193771 63.476410,211.096268 62.736767,212.122803
	C61.981586,212.831772 61.490562,213.416687 60.697739,214.153076
	C58.661835,216.458679 56.389130,218.371384 55.294846,220.812378
	C52.041496,228.069427 51.002789,228.987701 43.125717,228.667343
	C43.007908,227.876587 42.952923,227.423096 42.897942,226.969589
	C42.933895,226.802856 42.969849,226.636108 43.429741,226.177734
	C44.565941,224.590149 45.278206,223.294189 45.995113,221.998840
	C45.999756,221.999451 45.995190,221.990158 46.342838,221.953369
	C50.103756,217.986923 53.485867,214.029633 56.937248,210.133728
	C62.823353,203.489502 68.455254,196.535080 76.998169,192.995804
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M367.962219,506.712372
	C368.772614,505.405273 369.290619,503.702911 370.439301,502.853119
	C380.361755,495.512543 390.369812,488.286163 400.431763,481.137512
	C401.855133,480.126221 403.706329,479.717133 405.741760,479.034607
	C406.002686,479.623932 405.881348,480.208008 405.123260,480.977356
	C404.346161,481.799194 404.205811,482.435791 404.065430,483.072388
	C404.065430,483.072388 404.004272,483.007690 403.717712,482.986572
	C403.302795,483.338409 403.174438,483.711395 403.046082,484.084381
	C403.046112,484.084381 403.012543,484.012573 402.722717,483.990784
	C402.303741,484.340790 402.174622,484.712616 402.045502,485.084412
	C402.045502,485.084412 402.012939,485.012970 401.727173,484.995483
	C401.308044,485.346893 401.174652,485.715790 401.041260,486.084717
	C401.041260,486.084717 401.014130,486.014191 400.731812,486.006836
	C400.312866,486.361267 400.176208,486.723053 400.039581,487.084839
	C400.039581,487.084839 400.014404,487.014435 399.733459,487.009460
	C399.314453,487.364563 399.176361,487.724640 399.038269,488.084717
	C399.038239,488.084717 399.014404,488.014404 398.733765,488.010620
	C398.314758,488.366058 398.176392,488.725342 398.038025,489.084625
	C398.038025,489.084625 398.010071,489.010010 397.664276,488.927216
	C396.564606,489.921631 395.810760,490.998871 395.056885,492.076111
	C395.056854,492.076080 395.008942,492.009644 394.727295,492.002014
	C394.310944,492.357910 394.176208,492.721436 394.041443,493.084991
	C394.041443,493.084991 394.013947,493.013916 393.727356,493.000488
	C393.308380,493.352966 393.175964,493.718933 393.043579,494.084900
	C393.043579,494.084900 393.013397,494.013367 392.724915,493.995361
	C392.305908,494.346497 392.175385,494.715637 392.044861,495.084778
	C392.044861,495.084778 392.008514,495.004181 391.691345,494.888428
	C390.260101,495.878479 389.145996,496.984283 388.031921,498.090088
	C388.031921,498.090118 388.013763,498.020264 387.743469,498.031433
	C387.324982,498.389435 387.176727,498.736237 387.028503,499.083038
	C387.028503,499.083038 387.013763,499.013641 386.739624,499.024780
	C386.321564,499.385132 386.177673,499.734283 386.033752,500.083435
	C386.033752,500.083435 386.014008,500.013916 385.734772,500.015411
	C385.316406,500.372833 385.177277,500.728760 385.038147,501.084686
	C385.038147,501.084686 385.009277,501.007355 384.685852,500.900635
	C383.587555,501.557404 382.812653,502.320892 382.037781,503.084351
	C382.037811,503.084381 382.006165,503.012207 381.664337,502.922882
	C376.869110,504.126495 372.415680,505.419464 367.962219,506.712372
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M285.779358,344.437988
	C282.212219,343.177307 278.645050,341.916595 275.048462,340.281982
	C281.787628,341.296448 288.519135,342.916016 295.334808,344.011841
	C303.017334,345.247101 310.753540,346.520477 318.506409,346.858032
	C329.611969,347.341553 340.754059,347.038635 351.880127,346.983154
	C360.832550,346.938507 368.849426,350.018250 376.599915,354.090759
	C377.992798,354.822662 379.400146,355.526978 380.916321,356.606018
	C381.031799,356.968018 380.984589,357.026062 380.617676,357.079956
	C378.837555,356.752258 377.424377,356.370636 375.802002,355.709778
	C368.673553,353.292389 361.846588,349.776550 354.815582,349.305389
	C340.586761,348.351898 326.247345,349.049255 311.584381,348.901184
	C302.736603,347.303711 294.257996,345.870850 285.779358,344.437988
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M280.462769,177.914261
	C283.476257,176.719299 286.911469,175.596970 291.076447,174.303543
	C293.882690,173.716827 295.959229,173.301208 298.372345,172.923706
	C299.536957,172.671494 300.364960,172.381180 301.581360,172.096222
	C303.985504,171.792526 306.001190,171.483490 308.478638,171.203369
	C312.941833,171.033524 316.943298,170.834747 321.321350,170.723236
	C323.145020,170.568970 324.592102,170.327423 326.505493,170.092041
	C335.617218,169.904602 344.262634,169.710999 353.310242,169.609161
	C359.813049,168.457352 365.913635,167.213776 372.363464,165.918304
	C375.174530,164.646057 377.636322,163.425735 380.442108,162.119766
	C384.190735,160.356277 387.595367,158.678436 391.020996,157.019592
	C391.041992,157.038605 391.083374,157.077255 391.083374,157.077255
	C381.245544,165.518555 369.813110,170.241470 356.919434,170.932083
	C344.875671,171.577179 332.740417,171.033783 320.759583,172.192917
	C308.229156,173.405243 295.835999,176.031586 283.377930,178.008835
	C282.568359,178.137314 281.716644,178.000366 280.462769,177.914261
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M202.985748,180.023575
	C207.220444,178.537796 211.455154,177.052017 215.809387,175.216858
	C215.928940,174.867493 215.940933,174.942642 216.234161,174.958221
	C217.013412,174.595200 217.499405,174.216614 217.985413,173.838043
	C217.985428,173.838043 217.978271,173.948547 218.284668,173.987106
	C219.393768,173.627335 220.196472,173.229004 220.999191,172.830688
	C220.999191,172.830688 220.987122,172.957092 221.247238,172.960388
	C221.993118,172.603836 222.478867,172.243973 222.964630,171.884109
	C224.736404,170.673248 226.508194,169.462387 228.839294,168.000565
	C229.581879,167.446518 229.765182,167.143433 229.948486,166.840347
	C229.948486,166.840347 229.949295,166.932907 230.229813,166.939606
	C230.998672,166.576965 231.487015,166.207626 231.975372,165.838257
	C231.975357,165.838257 231.979736,165.967865 232.171356,165.859940
	C232.560150,165.450241 232.757339,165.148468 232.954498,164.846710
	C232.954498,164.846695 232.961395,164.962967 233.163269,164.860992
	C233.561264,164.455597 233.757355,164.152191 233.953445,163.848770
	C233.953445,163.848770 233.950836,163.936844 234.231964,163.940979
	C234.999405,163.574646 235.485748,163.204163 235.972076,162.833679
	C235.948318,162.469406 236.096420,162.199448 236.750198,162.136414
	C237.225555,162.482239 237.419479,162.657516 237.665741,162.774902
	C235.150345,165.769943 233.060364,169.312592 230.040680,171.658508
	C220.548523,179.032730 209.534729,182.834717 197.594116,184.013016
	C196.293198,184.141373 195.011536,184.464813 193.378174,184.375366
	C193.359894,183.684128 193.684372,183.315292 194.008850,182.946457
	C194.445679,182.944305 194.882523,182.942154 196.041840,182.954254
	C198.838135,181.986862 200.911942,181.005219 202.985748,180.023575
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M367.516510,506.806335
	C372.415680,505.419464 376.869110,504.126495 381.698181,502.943085
	C376.540985,509.110840 368.934845,508.569977 361.864044,508.901428
	C351.771088,509.374603 341.631256,509.284027 331.527832,508.945404
	C324.611023,508.713593 317.906952,507.079498 312.071350,502.400452
	C311.961670,502.038910 312.017761,501.980225 312.393707,501.918701
	C316.524414,503.201233 320.279175,504.545288 324.033936,505.889343
	C327.386169,506.342194 330.738373,506.795013 334.526978,507.234924
	C345.665802,507.114716 356.368286,507.007477 367.516510,506.806335
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M86.059311,341.405029
	C88.932045,341.773712 92.004951,342.287140 94.935616,343.238647
	C110.202667,348.195465 125.348885,346.430481 140.746857,342.389343
	C142.521484,341.997498 144.032318,341.856995 145.990036,341.833069
	C139.810074,343.640472 133.225143,345.529449 126.539734,346.943359
	C122.886841,347.715912 119.068954,347.764862 115.316696,348.000214
	C105.033424,348.645142 95.411819,346.117950 86.059311,341.405029
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M271.859863,84.972603
	C270.777374,94.672295 269.822235,104.389137 268.520691,114.059349
	C268.204376,116.409569 266.748077,118.606346 265.478149,120.963745
	C266.475128,113.202843 268.096649,105.386574 269.052307,97.489723
	C269.791962,91.377747 269.360138,85.124825 270.084961,79.009933
	C270.637726,74.346863 272.187286,69.801956 273.669006,65.386940
	C274.076172,66.038704 274.107727,66.508011 274.000000,67.362061
	C273.193817,73.488739 272.526855,79.230667 271.859863,84.972603
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M423.773682,409.906036
	C423.952850,409.913239 424.131989,409.920441 424.579926,409.930817
	C425.087372,413.321777 425.378357,416.706818 425.555328,420.097809
	C426.176025,431.993042 426.717407,443.926819 423.898895,455.610443
	C423.235199,458.361694 420.790436,460.683289 418.879333,462.893799
	C420.288940,456.724121 421.984283,450.863190 423.771454,444.539612
	C423.834534,434.723694 423.805786,425.370453 423.842834,415.586609
	C423.863678,413.406006 423.818665,411.656006 423.773682,409.906036
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M319.924103,16.024561
	C320.026245,11.216206 324.415192,11.062973 327.639587,9.512017
	C327.994629,10.174049 327.988098,10.576537 327.622070,10.994416
	C326.278656,11.947658 324.327942,13.071836 324.466980,13.793241
	C325.397461,18.620808 321.482483,20.629004 318.788940,22.205250
	C313.854279,25.093014 308.198334,26.748276 302.917480,28.583645
	C302.977783,27.808117 302.963776,27.366390 302.949768,26.924662
	C302.949768,26.924662 302.988953,26.988796 303.266632,26.984400
	C303.684357,26.624931 303.824402,26.269859 303.964417,25.914791
	C303.964447,25.914795 303.990936,25.990955 304.316284,26.104248
	C305.414795,25.450638 306.187958,24.683735 306.961151,23.916832
	C306.961151,23.916832 306.993866,23.989630 307.322754,24.092739
	C308.420471,23.436190 309.189240,22.676533 309.958008,21.916870
	C309.958008,21.916866 309.995331,21.990120 310.321167,22.097975
	C311.418152,21.442873 312.189301,20.679918 312.960449,19.916958
	C312.960449,19.916956 312.994202,19.989315 313.318970,20.088690
	C314.417664,19.430792 315.191589,18.673519 315.965515,17.916252
	C315.965515,17.916254 315.987122,17.984653 316.265015,17.981026
	C316.683746,17.623268 316.824554,17.269136 316.965363,16.915009
	C316.965363,16.915009 316.992157,16.992277 317.278809,17.109497
	C318.351685,16.825996 319.137909,16.425280 319.924103,16.024561
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M327.981567,10.979025
	C327.988098,10.576537 327.994629,10.174049 327.996399,9.471111
	C328.391388,9.117293 328.791168,9.063925 329.703857,9.313070
	C332.163269,9.576619 334.109741,9.537654 336.042877,9.845587
	C336.015778,11.126740 336.002075,12.060996 335.721375,13.197184
	C331.951477,20.809359 325.131165,23.614721 318.056335,25.639767
	C322.120239,22.064407 326.120544,18.827909 329.937134,15.740048
	C329.085358,13.666321 328.533447,12.322673 327.981567,10.979025
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M42.464279,226.987305
	C42.952923,227.423096 43.007908,227.876587 43.062508,229.097961
	C42.935802,234.255386 42.809479,238.644943 42.542206,243.478714
	C42.401028,258.610596 42.400795,273.298248 42.296902,288.395782
	C42.283489,290.201141 42.373734,291.596619 42.365860,293.355347
	C42.345028,294.460327 42.422325,295.202087 42.499619,295.943848
	C42.680721,298.058258 42.861828,300.172699 43.011986,302.695374
	C42.316635,301.393280 41.083622,299.686188 41.073830,297.972137
	C40.944145,275.272308 40.973625,252.571457 41.029808,229.871033
	C41.032173,228.914871 41.682270,227.960327 42.464279,226.987305
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M423.679626,445.002289
	C421.984283,450.863190 420.288940,456.724121 418.583435,463.072510
	C418.573334,463.559998 418.578796,463.571198 418.288422,463.294067
	C417.340851,462.686493 416.683624,462.356079 416.026428,462.025665
	C415.575317,462.017731 415.124237,462.009827 414.336060,461.612396
	C414.332672,459.481506 414.666351,457.740143 415.307312,455.842194
	C418.302917,452.124512 420.991302,448.563385 423.679626,445.002289
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M274.139252,66.977318
	C274.107727,66.508011 274.076172,66.038704 274.018677,65.216827
	C276.722107,59.342293 279.451477,53.820332 282.845978,48.046875
	C283.657532,47.171288 283.803925,46.547184 283.950348,45.923084
	C283.950348,45.923084 283.988098,45.993771 284.289215,46.052822
	C285.775696,45.097443 286.961090,44.083012 288.363159,43.282272
	C283.766327,51.323082 278.952789,59.150200 274.139252,66.977318
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M202.920776,271.623230
	C202.860626,272.100281 202.423325,272.094910 202.213104,272.049072
	C202.002884,272.003204 201.974518,271.999786 201.920288,271.741699
	C201.594284,271.268311 201.322510,271.053040 201.041260,270.378693
	C200.580170,258.868591 201.036453,247.937210 205.553360,237.405029
	C206.146790,237.190018 206.488693,237.077606 206.830612,236.965195
	C206.229462,239.247543 205.296051,241.499771 205.085800,243.817581
	C204.260437,252.915878 203.663010,262.034851 202.920776,271.623230
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M271.288513,433.495453
	C271.724945,438.801971 272.067688,443.653168 272.145935,448.754883
	C271.243744,445.924225 270.259430,442.867523 270.039246,439.756775
	C269.554321,432.906067 269.455078,426.028046 269.221375,418.757629
	C269.894440,423.249420 270.544617,428.144775 271.288513,433.495453
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M423.950897,76.011032
	C423.978058,75.257980 424.005249,74.504929 424.289398,73.346321
	C424.721619,72.917969 424.896912,72.895172 425.072174,72.872375
	C425.378571,74.377701 425.942139,75.881821 425.949188,77.388557
	C425.991730,86.468781 425.906769,95.549599 425.299133,104.764923
	C424.733765,104.899635 424.353851,104.910065 424.314423,104.506165
	C424.218964,97.075645 424.162933,90.049019 424.175537,82.645302
	C424.156799,81.511887 424.069366,80.755569 424.043488,79.602753
	C424.053680,78.141174 424.002289,77.076103 423.950897,76.011032
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M170.030930,338.492249
	C176.105057,338.388763 182.179199,338.285278 188.584656,338.691589
	C188.918686,339.425049 188.921402,339.648682 188.924103,339.872314
	C182.954346,339.929321 176.984604,339.986267 170.558350,339.612549
	C170.078217,338.951965 170.054565,338.722107 170.030930,338.492249
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M189.361847,339.934265
	C188.921402,339.648682 188.918686,339.425049 188.940521,338.865021
	C195.606812,339.617035 202.267700,340.604950 208.876968,341.863983
	C210.542572,342.181274 212.042999,343.365753 213.683975,344.475830
	C205.765610,343.198700 197.782608,341.597443 189.361847,339.934265
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M213.388107,226.961349
	C213.788177,225.184555 214.031128,222.815216 215.336060,221.497803
	C219.132721,217.664886 223.354538,214.253128 227.738373,210.870911
	C223.283463,216.326874 218.499802,221.588120 213.388107,226.961349
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M94.514351,177.978516
	C95.396851,177.974136 95.874908,177.983353 96.432640,178.241867
	C90.310516,181.289215 84.108704,184.087280 77.894882,186.890289
	C77.882874,186.895218 77.881500,186.866547 77.598564,186.892532
	C76.863777,187.232590 76.411919,187.546677 75.589142,187.937927
	C74.483177,188.015686 73.748131,188.016251 72.985504,187.988373
	C72.957924,187.959930 72.899040,187.906906 72.899040,187.906906
	C79.969330,184.601974 87.039619,181.297043 94.514351,177.978516
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M415.979767,462.375183
	C416.683624,462.356079 417.340851,462.686493 418.252197,463.316071
	C415.227661,468.030853 411.948975,472.446472 408.360046,477.016937
	C410.159729,473.162476 412.257355,469.146606 414.389496,465.149139
	C414.839050,464.306305 415.415253,463.531067 415.979767,462.375183
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M169.583496,338.589233
	C170.054565,338.722107 170.078217,338.951965 170.103500,339.528229
	C162.625427,340.582886 155.145706,341.291138 147.191406,341.960846
	C150.872040,340.952972 155.027252,339.983673 159.598145,338.915131
	C163.054550,338.772675 166.095306,338.729431 169.583496,338.589233
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M336.056244,9.498689
	C334.109741,9.537654 332.163269,9.576619 330.008423,9.310068
	C331.289520,8.661381 332.776428,8.034915 334.268860,8.021531
	C343.206451,7.941371 352.145355,8.002315 361.533997,8.344493
	C361.984192,8.673033 361.923950,9.167112 361.923950,9.167112
	C360.867889,9.141686 359.811829,9.116261 357.904236,9.092534
	C350.053864,9.229053 343.055054,9.363871 336.056244,9.498689
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M50.944664,315.399780
	C54.971546,318.825134 59.024082,322.603973 63.016335,326.732666
	C58.960812,323.306122 54.965569,319.529694 50.944664,315.399780
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M424.353851,104.910065
	C424.353851,104.910065 424.733765,104.899635 424.910583,104.969238
	C425.734253,111.246269 424.682617,117.143097 421.209534,122.861893
	C420.355438,123.002136 420.146881,122.995697 419.998535,122.620186
	C421.258301,117.495277 422.457825,112.739433 423.749695,107.599037
	C424.012665,106.446350 424.183258,105.678207 424.353851,104.910065
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M362.058533,9.413580
	C361.923950,9.167112 361.984192,8.673033 361.997223,8.424505
	C367.031494,7.264880 371.628418,8.266726 375.909180,11.602039
	C376.028503,11.971870 375.979919,12.026758 375.614685,12.060081
	C373.855621,11.703368 372.461823,11.313332 370.678802,10.922605
	C368.187805,10.914540 366.085968,10.907166 363.984161,10.899789
	C363.387146,10.486540 362.790100,10.073294 362.058533,9.413580
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M50.904320,206.912750
	C53.675304,203.932480 56.446293,200.952225 59.590996,197.967773
	C59.964710,197.963593 60.009655,198.008759 60.044502,198.356216
	C57.056957,201.472748 54.034569,204.241837 50.985207,206.986389
	C50.958229,206.961868 50.904324,206.912750 50.904320,206.912750
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M237.975555,162.637451
	C237.419479,162.657516 237.225555,162.482239 236.985046,162.076569
	C239.360962,159.312836 241.835785,156.721588 244.630173,154.027023
	C242.728271,156.782471 240.506821,159.641235 237.975555,162.637451
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M380.074829,15.918161
	C382.664185,18.377033 385.253571,20.835915 387.934692,23.634304
	C388.026398,23.973812 387.977173,24.025061 387.613281,24.053934
	C384.823853,21.395300 382.398254,18.707792 379.999634,15.996185
	C380.026611,15.972087 380.074799,15.918169 380.074829,15.918161
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M419.066650,126.059982
	C417.288055,128.987762 415.509460,131.915543 413.386139,134.942032
	C413.041412,135.040741 413.009857,135.008514 412.936218,134.646698
	C414.911621,131.525894 416.960632,128.766891 419.023743,126.021095
	C419.037811,126.034294 419.066650,126.059975 419.066650,126.059982
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M206.987320,236.666702
	C206.488693,237.077606 206.146790,237.190018 205.458405,237.030075
	C205.073776,236.504532 205.035614,236.251358 204.997437,235.998169
	C206.114822,234.097961 207.232193,232.197754 208.622742,230.109070
	C208.895920,229.920624 208.956345,229.965164 209.032318,230.269806
	C209.397141,230.804825 209.685974,231.035202 209.974792,231.265579
	C209.031204,232.966446 208.087616,234.667328 206.987320,236.666702
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M210.050171,287.589294
	C208.365967,285.175171 206.649246,282.390198 204.967545,279.220398
	C206.674271,281.629883 208.345963,284.424164 210.050171,287.589294
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M422.946014,401.733002
	C421.519897,399.632385 420.164795,397.140350 418.933929,394.287659
	C420.377808,396.398560 421.697418,398.870056 422.946014,401.733002
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M264.847992,335.672485
	C262.610321,335.223053 260.357483,334.401001 258.061066,333.245605
	C260.289276,333.708160 262.561035,334.503998 264.847992,335.672485
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M229.968903,311.621094
	C228.263748,310.491364 226.458878,309.049103 224.861420,307.269897
	C226.668930,308.391510 228.269058,309.850037 229.968903,311.621094
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M43.176929,222.989334
	C43.448692,221.587402 43.720451,220.185455 44.357574,218.384430
	C45.094387,217.886124 45.465836,217.786926 45.889160,218.120483
	C45.959087,219.698883 45.977139,220.844513 45.995190,221.990158
	C45.995190,221.990158 45.999756,221.999451 45.718239,222.118439
	C44.683456,222.488052 43.930191,222.738693 43.176929,222.989334
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M409.057373,380.606323
	C407.627594,379.505890 406.173248,378.061523 404.895905,376.274414
	C406.392883,377.375244 407.712891,378.818817 409.057373,380.606323
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M412.046875,46.618118
	C410.653107,45.517838 409.222778,44.074760 407.923706,42.278313
	C409.373413,43.375069 410.691864,44.825191 412.046875,46.618118
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M249.622437,148.026337
	C248.842865,149.648132 247.740051,151.371994 246.277039,153.030853
	C247.044312,151.353378 248.171768,149.740891 249.622437,148.026337
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M288.146454,43.068581
	C286.961090,44.083012 285.775696,45.097443 284.253967,46.033779
	C285.040253,44.355137 286.162933,42.754593 287.619751,41.052299
	C287.953857,40.950554 287.989075,40.991714 288.200653,41.298717
	C288.323639,42.093338 288.235046,42.580959 288.146454,43.068581
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M392.960571,27.621265
	C392.614014,28.035473 392.158325,28.132034 391.191315,27.881891
	C389.779083,26.365145 388.878143,25.195099 387.977173,24.025057
	C387.977173,24.025061 388.026398,23.973812 388.051147,23.948318
	C389.667755,25.049757 391.259583,26.176689 392.960571,27.621265
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M257.966553,332.620361
	C256.359924,332.183228 254.645279,331.412476 252.986359,330.278809
	C254.647583,330.706177 256.253082,331.496460 257.966553,332.620361
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M219.034515,347.381073
	C220.631317,347.818268 222.336807,348.588623 224.003357,349.722473
	C222.357361,349.295410 220.750290,348.504822 219.034515,347.381073
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M394.949829,31.093636
	C394.245331,30.250177 393.540833,29.406710 392.943695,28.242126
	C394.651978,29.047554 396.252838,30.174095 397.962616,31.619564
	C397.610138,32.017742 397.148743,32.096992 396.240326,31.865772
	C395.588440,31.262032 395.307312,31.108139 394.949829,31.093636
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M276.145142,461.444153
	C277.125000,462.525055 278.070648,463.946991 278.994995,465.725525
	C278.019440,464.649841 277.065155,463.217529 276.145142,461.444153
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M43.105476,223.360229
	C43.930191,222.738693 44.683456,222.488052 45.713600,222.117828
	C45.278206,223.294189 44.565941,224.590149 43.511536,225.935303
	C43.124271,225.233398 43.079147,224.482269 43.105476,223.360229
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M306.916962,498.089172
	C305.657532,497.300415 304.398132,496.511688 303.048523,495.382080
	C302.958282,495.041168 303.016815,494.978821 303.373413,494.900085
	C304.825958,495.875458 305.921844,496.929596 306.992065,498.009613
	C306.966370,498.035522 306.916962,498.089142 306.916962,498.089172
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M388.371429,498.055969
	C389.145996,496.984283 390.260101,495.878479 391.723022,494.919312
	C390.951569,496.051239 389.831268,497.036560 388.371429,498.055969
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M311.933411,502.068024
	C310.650116,501.304352 309.395081,500.511597 308.049988,499.379150
	C307.959900,499.039490 308.016571,498.979584 308.373474,498.916870
	C309.826202,499.896149 310.921967,500.938202 312.017761,501.980225
	C312.017761,501.980225 311.961670,502.038910 311.933411,502.068024
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M409.062927,140.058533
	C408.281464,141.324173 407.500000,142.589813 406.380859,143.949951
	C406.043213,144.044449 405.992401,143.993546 405.867188,143.647476
	C406.831635,142.204727 407.921265,141.108047 409.023621,140.022095
	C409.036285,140.032822 409.062958,140.058548 409.062927,140.058533
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M252.621048,144.026520
	C252.177338,145.326996 251.411743,146.729630 250.280762,148.044922
	C250.709961,146.681290 251.504562,145.404984 252.621048,144.026520
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M65.911766,192.918762
	C67.019020,191.934311 68.126274,190.949860 69.595642,189.960480
	C69.957764,189.955566 70.012566,190.015121 70.062210,190.371948
	C68.744614,191.489929 67.377365,192.251083 65.986122,192.988251
	C65.962120,192.964249 65.911766,192.918762 65.911766,192.918762
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M405.045776,38.605324
	C404.028381,38.183197 402.974518,37.406734 401.979034,36.273937
	C403.028076,36.695396 404.018707,37.473190 405.045776,38.605324
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M302.635437,27.030516
	C302.963776,27.366390 302.977783,27.808117 302.601349,28.730442
	C301.142670,29.476606 300.074432,29.742170 298.983795,29.985779
	C298.961395,29.963823 298.914825,29.921764 298.914825,29.921764
	C300.050262,28.993298 301.185669,28.064835 302.635437,27.030516
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M292.921143,35.926170
	C294.052216,34.661896 295.183289,33.397629 296.637817,32.046581
	C296.961273,31.959803 297.001648,32.001362 297.106812,32.328518
	C296.478149,33.779156 295.744324,34.902637 294.663788,36.105125
	C293.873840,36.117371 293.430603,36.050610 292.969574,35.971031
	C292.951752,35.958218 292.921143,35.926174 292.921143,35.926170
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M395.360443,491.986969
	C395.810760,490.998871 396.564606,489.921631 397.697449,488.949341
	C397.272247,490.002106 396.468140,490.949982 395.360443,491.986969
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M215.057526,295.627136
	C214.307510,295.183289 213.529053,294.406006 212.904373,293.277344
	C213.715118,293.715210 214.372101,294.504456 215.057526,295.627136
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M413.057007,385.628662
	C412.311249,385.184845 411.537720,384.408112 410.915039,383.280090
	C411.720337,383.717743 412.374817,384.506744 413.057007,385.628662
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M213.056091,292.625244
	C212.317001,292.184998 211.548203,291.411255 210.916504,290.281006
	C211.711197,290.713593 212.368790,291.502655 213.056091,292.625244
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M417.059082,390.621765
	C416.300354,390.180298 415.516388,389.406433 414.901794,388.281219
	C415.725372,388.716339 416.379608,389.502869 417.059082,390.621765
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M414.923401,50.690605
	C414.158569,50.209587 413.456085,49.394962 412.907166,48.254524
	C413.702423,48.738140 414.344086,49.547569 414.923401,50.690605
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M425.057373,72.556091
	C424.896912,72.895172 424.721619,72.917969 424.282654,72.967850
	C423.727753,72.226456 423.436554,71.457977 423.131409,70.370277
	C423.759155,70.780640 424.400879,71.510231 425.057373,72.556091
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M419.938293,122.989243
	C420.146881,122.995697 420.355438,123.002136 420.877136,123.021980
	C420.694000,123.977081 420.197693,124.918793 419.384033,125.960236
	C419.066650,126.059975 419.037811,126.034294 418.933685,125.695488
	C419.199127,124.567535 419.568695,123.778389 419.938293,122.989243
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M306.629700,23.950306
	C306.187958,24.683735 305.414795,25.450638 304.284515,26.079391
	C304.717682,25.288754 305.507935,24.636267 306.629700,23.950306
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M419.057312,393.623657
	C418.324554,393.185455 417.564758,392.414459 416.932312,391.285034
	C417.716522,391.714691 418.373383,392.502777 419.057312,393.623657
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M253.943558,378.383514
	C254.688980,378.883331 255.464905,379.732971 256.146637,380.974365
	C255.359634,380.488525 254.666855,379.610901 253.943558,378.383514
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M219.055115,300.631256
	C218.278473,300.177551 217.470947,299.391846 216.866150,298.269104
	C217.720642,298.721100 218.372437,299.510193 219.055115,300.631256
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M45.071991,307.259705
	C45.842945,307.749908 46.533710,308.578156 47.080013,309.738281
	C46.287632,309.246063 45.639713,308.421906 45.071991,307.259705
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M234.024948,357.375549
	C234.936996,357.490387 235.949402,357.924622 236.969955,358.721863
	C236.027161,358.621582 235.076233,358.158264 234.024948,357.375549
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M230.024979,354.367950
	C230.939331,354.485809 231.954193,354.923218 232.973129,355.722626
	C232.026627,355.618927 231.076065,355.153198 230.024979,354.367950
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M227.025345,352.371765
	C227.955185,352.485931 228.985382,352.918823 229.992310,353.718750
	C229.021271,353.620697 228.073486,353.155579 227.025345,352.371765
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M224.023651,350.372223
	C224.970718,350.482727 226.016296,350.912476 227.012115,351.713776
	C226.015625,351.620697 225.068893,351.156097 224.023651,350.372223
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M399.092590,151.085541
	C398.300232,151.728470 397.507874,152.371414 396.372498,153.022888
	C396.029480,153.031448 396.008636,153.008453 396.021790,152.653244
	C397.020782,151.862350 398.006622,151.426682 399.017059,151.015106
	C399.041656,151.039200 399.092590,151.085541 399.092590,151.085541
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M237.966248,318.610840
	C237.039413,318.508942 236.011154,318.090546 235.005310,317.293396
	C235.973434,317.374512 236.919113,317.834442 237.966248,318.610840
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M68.027069,331.380005
	C68.968437,331.487183 70.009109,331.911987 71.003288,332.710785
	C70.013329,332.622375 69.069855,332.160034 68.027069,331.380005
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M252.973236,329.626221
	C252.003372,329.517792 250.933380,329.092316 249.957062,328.292297
	C250.991516,328.381592 251.932312,328.845398 252.973236,329.626221
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M64.025391,328.379150
	C64.955132,328.487213 65.982979,328.914276 66.987167,329.712952
	C66.016830,329.622406 65.070160,329.160278 64.025391,328.379150
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M244.367981,197.055954
	C244.815964,196.308197 245.597153,195.530273 246.725967,194.904968
	C246.282791,195.713638 245.491989,196.369705 244.367981,197.055954
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M61.899979,195.907379
	C62.692265,195.264526 63.484550,194.621689 64.625137,193.975586
	C64.973427,193.972336 65.009491,194.009323 64.984474,194.357635
	C63.976879,195.141983 62.994305,195.578033 61.984428,195.986725
	C61.957134,195.959381 61.899979,195.907379 61.899979,195.907379
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M382.370728,503.053894
	C382.812653,502.320892 383.587555,501.557404 384.717438,500.927246
	C384.282837,501.714844 383.493256,502.369141 382.370728,503.053894
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M247.372955,195.054138
	C247.817505,194.307037 248.594833,193.528534 249.723236,192.904785
	C249.284790,193.713959 248.495270,194.368347 247.372955,195.054138
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M240.365509,200.053268
	C240.820511,199.288925 241.608109,198.491791 242.733093,197.880920
	C242.279694,198.718292 241.488907,199.369385 240.365509,200.053268
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M236.357513,203.052322
	C236.816071,202.295303 237.607834,201.504974 238.735413,200.889633
	C238.277725,201.716080 237.484207,202.367554 236.357513,203.052322
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M289.949951,481.360565
	C290.691528,481.810852 291.466522,482.594818 292.088898,483.725403
	C291.285309,483.279419 290.634338,482.486847 289.949951,481.360565
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M284.946747,475.363464
	C285.695709,475.813751 286.475342,476.597870 287.093933,477.726807
	C286.281036,477.280182 285.629242,476.488739 284.946747,475.363464
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M48.907295,209.915283
	C49.367596,208.988281 49.827900,208.061295 50.596264,207.023529
	C50.904324,206.912750 50.958229,206.961868 51.083595,207.336533
	C50.475605,208.476654 49.742249,209.242111 48.983444,209.984558
	C48.958000,209.961533 48.907291,209.915283 48.907295,209.915283
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M279.943420,468.375153
	C280.677643,468.813599 281.439941,469.585724 282.072876,470.715912
	C281.286163,470.285583 280.628815,469.497223 279.943420,468.375153
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M46.904816,212.914749
	C47.366047,211.987137 47.827278,211.059509 48.597900,210.023590
	C48.907291,209.915283 48.958000,209.961533 49.080917,210.335571
	C48.473099,211.476273 47.742363,212.242950 46.984818,212.986023
	C46.958004,212.962433 46.904816,212.914749 46.904816,212.914749
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M210.287399,231.106750
	C209.685974,231.035202 209.397141,230.804825 209.062286,230.292404
	C209.825302,229.325302 210.634323,228.640244 211.764252,227.994110
	C211.590118,229.004669 211.095047,229.976303 210.287399,231.106750
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M413.057617,135.056427
	C412.616852,136.003738 412.159882,136.935364 411.372833,137.955933
	C411.042755,138.044891 411.003418,138.002914 410.885681,137.657944
	C411.515228,136.544815 412.262543,135.776672 413.009857,135.008514
	C413.009857,135.008514 413.041412,135.040741 413.057617,135.056427
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M309.626953,21.950806
	C309.189240,22.676533 308.420471,23.436190 307.289307,24.070957
	C307.716614,23.292290 308.506287,22.638514 309.626953,21.950806
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M312.629303,19.950703
	C312.189301,20.679918 311.418152,21.442873 310.287140,22.074873
	C310.717590,21.290760 311.507874,20.637604 312.629303,19.950703
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M45.837284,217.687744
	C45.465836,217.786926 45.094387,217.886124 44.436684,218.018341
	C44.529274,217.082275 44.908123,216.113220 45.624596,215.053635
	C45.962219,214.963120 45.991364,214.990448 46.081409,215.324249
	C46.060066,216.334610 45.948677,217.011169 45.837284,217.687744
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M315.633362,17.949783
	C315.191589,18.673519 314.417664,19.430792 313.285278,20.067289
	C313.718262,19.292116 314.509735,18.637718 315.633362,17.949783
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M297.947388,490.402649
	C298.366333,490.028381 298.811249,490.008301 299.632935,490.376404
	C300.013275,491.171021 300.016876,491.577484 299.993958,492.009155
	C299.967377,492.034363 299.918060,492.088562 299.918060,492.088562
	C299.269836,491.644653 298.621643,491.200745 297.947388,490.402649
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M377.075226,12.917952
	C377.720459,13.354694 378.365662,13.791436 379.019043,14.600803
	C379.027191,14.973430 378.978729,15.025652 378.651459,15.015718
	C377.874420,14.344213 377.424652,13.682642 377.000916,12.996228
	C377.026978,12.971385 377.075226,12.917950 377.075226,12.917952
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M388.962982,361.621124
	C388.611755,362.052094 388.156097,362.172180 387.230408,361.968384
	C386.500549,361.105133 386.240692,360.565704 386.003448,359.998535
	C386.026093,359.970795 386.073517,359.917023 386.073517,359.917023
	C387.001862,360.381439 387.930176,360.845856 388.962982,361.621124
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M319.575806,16.044685
	C319.137909,16.425280 318.351685,16.825996 317.248230,17.082367
	C317.696442,16.646948 318.461975,16.355879 319.575806,16.044685
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M397.048859,368.621033
	C396.388855,368.513367 395.690460,368.047302 395.011627,367.247101
	C395.690948,367.362885 396.350677,367.812775 397.048859,368.621033
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M220.645325,172.904999
	C220.196472,173.229004 219.393768,173.627335 218.230255,173.976730
	C218.676804,173.611633 219.484131,173.295456 220.645325,172.904999
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M81.151398,339.301636
	C82.018341,339.102631 82.901833,339.267487 83.886948,339.760529
	C83.048363,339.947632 82.108147,339.806549 81.151398,339.301636
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M274.937134,339.605988
	C274.161041,339.884369 273.253632,339.831360 272.174683,339.457886
	C272.937378,339.183136 273.871582,339.228851 274.937134,339.605988
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M216.176407,346.339417
	C217.032867,346.129608 217.928635,346.274017 218.909454,346.753967
	C218.068222,346.957550 217.141983,346.825592 216.176407,346.339417
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M222.645233,171.934937
	C222.478867,172.243973 221.993118,172.603836 221.184784,172.949036
	C221.350067,172.618179 221.837952,172.301971 222.645233,171.934937
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M278.946960,466.329773
	C279.342957,466.472565 279.775513,466.939606 280.074646,467.739441
	C279.622009,467.599518 279.302765,467.126801 278.946960,466.329773
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M231.654907,165.900116
	C231.487015,166.207626 230.998672,166.576965 230.184692,166.929352
	C230.350830,166.595581 230.842636,166.278778 231.654907,165.900116
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M72.869926,332.884155
	C73.234077,333.163910 73.200798,333.389008 73.097496,333.949036
	C72.396729,334.088165 71.765984,333.892395 71.031876,333.378845
	C71.443176,332.983887 71.957840,332.906708 72.869926,332.884155
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M394.095886,155.088547
	C393.627502,155.396378 393.159149,155.704224 392.361908,156.023010
	C392.033051,156.033966 392.007507,156.009247 392.087708,155.676956
	C392.775574,155.225891 393.383179,155.107117 394.016418,155.014069
	C394.042053,155.039795 394.095886,155.088547 394.095886,155.088547
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M235.654694,162.904205
	C235.485748,163.204163 234.999405,163.574646 234.189423,163.929550
	C234.356277,163.600891 234.846786,163.287796 235.654694,162.904205
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M84.019409,340.367310
	C84.558563,340.175140 85.196045,340.293030 85.907356,340.749695
	C85.360031,340.951447 84.738884,340.814423 84.019409,340.367310
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M252.955505,376.325928
	C253.342911,376.473633 253.771088,376.945312 254.072174,377.745544
	C253.628815,377.599365 253.312546,377.124664 252.955505,376.325928
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M282.951416,472.330414
	C283.339691,472.475647 283.764893,472.945709 284.066162,473.744629
	C283.624268,473.600739 283.306335,473.127991 282.951416,472.330414
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M249.953461,372.323547
	C250.376953,372.480042 250.837921,372.959473 251.113678,373.753479
	C250.615967,373.594177 250.303436,373.120331 249.953461,372.323547
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M404.377258,482.970856
	C404.205811,482.435791 404.346161,481.799194 404.839447,481.080536
	C405.024597,481.622101 404.856812,482.245697 404.377258,482.970856
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M398.970428,369.617737
	C398.435242,369.823181 397.802032,369.720123 397.091125,369.264343
	C397.633118,369.044189 398.252747,369.176758 398.970428,369.617737
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M217.666672,173.909424
	C217.499405,174.216614 217.013412,174.595200 216.196472,174.953461
	C216.359680,174.615692 216.853806,174.298248 217.666672,173.909424
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M381.058105,356.941467
	C381.678986,357.053375 382.273529,357.191833 382.950684,357.648682
	C383.033325,357.967010 382.981934,358.024841 382.668610,358.075134
	C381.898376,357.759003 381.441498,357.392517 380.984589,357.026062
	C380.984589,357.026062 381.031799,356.968018 381.058105,356.941467
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M384.075775,358.918030
	C384.671661,359.055145 385.267517,359.192261 385.968445,359.623199
	C386.073517,359.917023 386.026093,359.970795 385.692963,360.024933
	C384.900696,359.726562 384.441559,359.374054 384.005341,358.995270
	C384.028259,358.968994 384.075775,358.917999 384.075775,358.918030
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M391.965393,363.628906
	C391.422119,363.835144 390.774139,363.737213 390.070374,363.274261
	C390.629944,363.047729 391.245331,363.186279 391.965393,363.628906
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M249.969940,327.625854
	C249.604630,328.057037 249.144196,328.185516 248.312271,328.329193
	C247.876343,328.100616 247.811890,327.856781 247.903107,327.268188
	C248.664139,327.056671 249.269485,327.189911 249.969940,327.625854
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M48.951077,312.331421
	C49.335266,312.476135 49.755119,312.945282 50.058311,313.743652
	C49.623348,313.600494 49.305042,313.128204 48.951077,312.331421
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M69.929962,189.926147
	C70.369148,189.591980 70.836128,189.287231 71.636520,188.975342
	C71.969933,188.968201 72.006447,189.008087 71.916168,189.332458
	C71.221451,189.776260 70.617004,189.895691 70.012566,190.015121
	C70.012566,190.015121 69.957764,189.955566 69.929962,189.926147
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M246.966949,325.619293
	C246.419418,325.835419 245.772491,325.744476 245.069061,325.281464
	C245.630875,325.043671 246.249222,325.177948 246.966949,325.619293
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M242.965668,322.612427
	C242.410370,322.839691 241.757004,322.759888 241.059357,322.294983
	C241.632584,322.041687 242.250092,322.173523 242.965668,322.612427
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M45.948059,214.948929
	C46.059433,214.331848 46.184975,213.728973 46.607666,213.020416
	C46.904816,212.914749 46.958004,212.962433 47.095280,213.345139
	C47.193626,214.226761 47.154694,214.725693 46.823746,215.218506
	C46.351604,215.138412 46.171482,215.064423 45.991364,214.990448
	C45.991364,214.990448 45.962219,214.963120 45.948059,214.948929
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M283.640991,46.029427
	C283.803925,46.547184 283.657532,47.171288 283.179077,47.899429
	C283.008545,47.380905 283.170074,46.758339 283.640991,46.029427
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M211.055176,289.660980
	C210.645386,289.521606 210.199142,289.060577 209.904144,288.263916
	C210.376495,288.398651 210.697617,288.868988 211.055176,289.660980
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M281.979431,471.302734
	C282.266388,471.175659 282.618073,471.316895 282.972900,471.769775
	C282.665436,471.911255 282.354828,471.741180 281.979431,471.302734
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M316.698914,16.980972
	C316.824554,17.269136 316.683746,17.623268 316.229858,17.974051
	C316.088654,17.662781 316.260559,17.354858 316.698914,16.980972
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M396.041962,153.044434
	C395.895660,153.352158 395.736877,153.646881 395.307983,153.990631
	C395.037933,154.039642 395.004547,154.004715 394.997284,153.710968
	C395.329559,153.280960 395.669098,153.144714 396.008636,153.008453
	C396.008636,153.008453 396.029480,153.031448 396.041962,153.044434
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M229.685928,166.935150
	C229.765182,167.143433 229.581879,167.446518 229.151031,167.859436
	C229.076782,167.656174 229.250076,167.343063 229.685928,166.935150
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M410.073029,139.067703
	C409.906921,139.361206 409.740814,139.654724 409.318848,140.003387
	C409.062958,140.058548 409.036285,140.032822 409.024750,139.727066
	C409.344635,139.283340 409.676025,139.145355 410.023804,139.022125
	C410.040192,139.036865 410.073029,139.067703 410.073029,139.067703
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M228.307922,211.015259
	C228.159958,210.691376 228.274841,210.301682 228.735565,209.975159
	C228.911194,210.342041 228.740997,210.645721 228.307922,211.015259
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M229.300842,210.017212
	C229.160675,209.692551 229.284271,209.303162 229.745850,208.978485
	C229.910736,209.346298 229.737671,209.649399 229.300842,210.017212
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M212.299240,228.023239
	C212.161087,227.694427 212.290573,227.304901 212.752075,226.979065
	C212.911697,227.349350 212.739288,227.655930 212.299240,228.023239
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M230.311737,209.026154
	C230.159454,208.675537 230.273697,208.268219 230.735825,207.957397
	C230.915237,208.359070 230.746750,208.664261 230.311737,209.026154
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M231.318619,208.025070
	C231.162079,207.679138 231.270554,207.274399 231.731598,206.960785
	C231.917313,207.356705 231.750473,207.661484 231.318619,208.025070
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M283.978821,474.303009
	C284.268829,474.175385 284.623138,474.315796 284.975037,474.769470
	C284.662811,474.912201 284.352997,474.741608 283.978821,474.303009
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M232.317886,207.024536
	C232.164764,206.694962 232.278122,206.305527 232.738327,205.979584
	C232.918243,206.350281 232.751297,206.657486 232.317886,207.024536
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M286.975250,478.313629
	C287.289246,478.172089 287.663330,478.297760 287.998047,478.753967
	C287.650879,478.916565 287.343109,478.748688 286.975250,478.313629
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M287.977844,479.304993
	C288.272491,479.175201 288.630920,479.313049 288.978668,479.767761
	C288.659210,479.914001 288.350403,479.743317 287.977844,479.304993
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M411.062653,138.065674
	C410.913300,138.374023 410.744080,138.661560 410.323914,139.008408
	C410.073029,139.067703 410.040192,139.036865 410.009399,138.716888
	C410.320221,138.265564 410.661804,138.134247 411.003418,138.002914
	C411.003418,138.002914 411.042755,138.044891 411.062653,138.065674
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M64.955719,193.953522
	C65.096184,193.641510 65.254349,193.348312 65.662140,192.986938
	C65.911766,192.918762 65.962120,192.964249 65.988770,193.278152
	C65.680107,193.731155 65.344803,193.870239 65.009491,194.009323
	C65.009491,194.009323 64.973427,193.972336 64.955719,193.953522
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M250.308868,193.020050
	C250.185822,192.748428 250.335022,192.410004 250.802780,192.041412
	C250.941254,192.325241 250.761169,192.639236 250.308868,193.020050
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M288.979126,480.300964
	C289.268768,480.174133 289.622040,480.316101 289.974701,480.769928
	C289.663666,480.911102 289.353241,480.740417 288.979126,480.300964
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M71.951157,188.948730
	C72.089790,188.637085 72.247215,188.344894 72.651840,187.979797
	C72.899040,187.906906 72.957924,187.959930 73.008392,188.288361
	C72.708046,188.747238 72.357246,188.877655 72.006447,189.008087
	C72.006447,189.008087 71.969933,188.968201 71.951157,188.948730
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M413.016479,47.701935
	C412.727112,47.828102 412.367859,47.689774 412.018738,47.232925
	C412.334747,47.088749 412.640656,47.263092 413.016479,47.701935
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M415.024384,387.684967
	C414.714264,387.824127 414.343811,387.697205 414.009033,387.242676
	C414.351166,387.083038 414.657623,387.250946 415.024384,387.684967
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M414.023254,386.690735
	C413.730621,386.820984 413.375244,386.683563 413.026917,386.230957
	C413.342834,386.084839 413.651703,386.253937 414.023254,386.690735
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M411.023590,382.689514
	C410.718842,382.824066 410.353058,382.692017 410.014587,382.237610
	C410.348785,382.084503 410.655670,382.253662 411.023590,382.689514
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M410.023590,381.692078
	C409.726654,381.822449 409.368134,381.685211 409.023438,381.231506
	C409.345520,381.084839 409.653748,381.254639 410.023590,381.692078
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M216.020264,296.689880
	C215.701569,296.835693 215.319443,296.716064 214.987732,296.255798
	C215.344376,296.084930 215.650589,296.254669 216.020264,296.689880
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M217.019760,297.687958
	C216.683456,297.839722 216.284637,297.727783 215.965973,297.266418
	C216.349838,297.086060 216.653534,297.255188 217.019760,297.687958
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M220.023193,301.695435
	C219.709412,301.834076 219.333099,301.704681 218.995758,301.245117
	C219.343384,301.085724 219.652039,301.256531 220.023193,301.695435
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M405.023407,375.684143
	C404.700409,375.827515 404.317627,375.706451 403.993225,375.251038
	C404.355652,375.084381 404.659637,375.252075 405.023407,375.684143
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M251.980286,375.301758
	C252.267426,375.172729 252.621307,375.311859 252.977386,375.765869
	C252.668716,375.910461 252.357864,375.740173 251.980286,375.301758
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M233.688431,163.939301
	C233.757355,164.152191 233.561264,164.455597 233.105133,164.857620
	C233.037888,164.647446 233.230652,164.338638 233.688431,163.939301
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M376.055359,11.946941
	C376.369110,12.088748 376.656006,12.255484 377.009064,12.670084
	C377.075226,12.917950 377.026978,12.971385 376.743744,12.941746
	C376.300323,12.616994 376.140106,12.321882 375.979919,12.026764
	C375.979919,12.026758 376.028503,11.971870 376.055359,11.946941
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M300.910065,493.096191
	C300.622986,492.926361 300.335907,492.756561 299.983429,492.337646
	C299.918060,492.088562 299.967377,492.034363 300.270782,492.037781
	C300.721832,492.354980 300.869507,492.668793 300.990234,493.010834
	C300.963318,493.039093 300.910065,493.096191 300.910065,493.096191
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M394.308411,493.023560
	C394.176208,492.721436 394.310944,492.357910 394.765259,492.017456
	C394.915009,492.347748 394.745209,492.654968 394.308411,493.023560
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M301.907227,494.099396
	C301.620575,493.929962 301.333923,493.760559 300.978668,493.343658
	C300.910065,493.096191 300.963318,493.039093 301.273346,493.027832
	C301.728058,493.338470 301.872711,493.660431 301.989685,494.011475
	C301.961975,494.040588 301.907227,494.099396 301.907227,494.099396
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M232.688751,164.938324
	C232.757339,165.148468 232.560150,165.450241 232.106567,165.857422
	C232.041092,165.651855 232.232040,165.340897 232.688751,164.938324
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M393.310242,494.023804
	C393.175964,493.718933 393.308380,493.352966 393.762756,493.014526
	C393.915466,493.348938 393.746185,493.655792 393.310242,494.023804
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M302.927002,495.070312
	C302.611969,494.929596 302.328247,494.759766 301.975891,494.344666
	C301.907227,494.099396 301.961975,494.040588 302.276123,494.023071
	C302.732422,494.329987 302.874603,494.654419 303.016785,494.978821
	C303.016815,494.978821 302.958282,495.041168 302.927002,495.070312
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M392.311279,495.024139
	C392.175385,494.715637 392.305908,494.346497 392.760590,494.010742
	C392.915710,494.350586 392.746704,494.657043 392.311279,495.024139
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M253.721924,142.934113
	C253.848801,143.235519 253.707703,143.627960 253.238983,143.994476
	C253.092224,143.654083 253.273102,143.339600 253.721924,142.934113
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M395.054626,154.057220
	C394.911438,154.365921 394.751526,154.657043 394.343750,155.018372
	C394.095886,155.088547 394.042053,155.039795 393.976868,154.702972
	C394.275940,154.245682 394.640259,154.125198 395.004547,154.004715
	C395.004547,154.004715 395.037933,154.039642 395.054626,154.057220
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M307.929718,499.067627
	C307.614166,498.925690 307.328766,498.755646 306.980164,498.337341
	C306.916962,498.089142 306.966370,498.035522 307.273590,498.030396
	C307.726074,498.343353 307.871307,498.661469 308.016571,498.979584
	C308.016571,498.979584 307.959900,499.039490 307.929718,499.067627
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M387.296112,499.017426
	C387.176727,498.736237 387.324982,498.389435 387.779419,498.035034
	C387.911652,498.335541 387.737701,498.643677 387.296112,499.017426
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M243.311310,198.019531
	C243.166397,197.708267 243.286804,197.332199 243.746002,196.995178
	C243.915390,197.341064 243.746017,197.647888 243.311310,198.019531
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M386.300415,500.017853
	C386.177673,499.734283 386.321564,499.385132 386.774353,499.032043
	C386.911163,499.336212 386.739136,499.644226 386.300415,500.017853
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M385.305847,501.022278
	C385.177277,500.728760 385.316406,500.372833 385.769562,500.025269
	C385.913574,500.342377 385.743561,500.651123 385.305847,501.022278
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M59.942833,197.940430
	C60.084801,197.627228 60.248650,197.337173 60.687157,197.003738
	C60.961815,196.960358 61.010056,197.010132 61.021294,197.307251
	C60.691570,197.739151 60.350613,197.873962 60.009655,198.008759
	C60.009655,198.008759 59.964710,197.963593 59.942833,197.940430
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M60.937927,196.935257
	C61.078773,196.622986 61.243511,196.335800 61.654114,195.977997
	C61.899979,195.907379 61.957134,195.959381 62.004822,196.289307
	C61.705029,196.749542 61.357544,196.879837 61.010056,197.010132
	C61.010056,197.010132 60.961815,196.960358 60.937927,196.935257
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M293.980072,486.299408
	C294.270111,486.174194 294.624756,486.316681 294.975159,486.771454
	C294.662170,486.911560 294.353424,486.739349 293.980072,486.299408
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M403.024506,373.686951
	C402.710999,373.825287 402.337494,373.697662 402.005554,373.242737
	C402.352875,373.083954 402.658691,373.252441 403.024506,373.686951
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M233.309113,206.024017
	C233.166718,205.707474 233.292007,205.329086 233.751373,204.993713
	C233.915909,205.345215 233.746353,205.653702 233.309113,206.024017
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M234.304230,205.023193
	C234.168884,204.721603 234.302917,204.357056 234.761078,204.010223
	C234.914688,204.338730 234.744156,204.649490 234.304230,205.023193
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M403.311981,484.024048
	C403.174438,483.711395 403.302795,483.338409 403.757751,483.005951
	C403.915527,483.352203 403.746704,483.657928 403.311981,484.024048
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M291.976379,484.308685
	C292.282288,484.173096 292.649780,484.304901 292.989471,484.760864
	C292.653748,484.915375 292.345825,484.745728 291.976379,484.308685
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M402.311462,485.023895
	C402.174622,484.712616 402.303741,484.340790 402.758667,484.007416
	C402.915466,484.351685 402.746460,484.657532 402.311462,485.023895
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M292.976685,485.305481
	C293.273773,485.174316 293.633118,485.311890 293.980164,485.766968
	C293.658203,485.914368 293.348541,485.744263 292.976685,485.305481
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M401.308044,486.024261
	C401.174652,485.715790 401.308044,485.346893 401.763123,485.011230
	C401.914825,485.350922 401.744812,485.657349 401.308044,486.024261
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M379.053223,14.949108
	C379.366913,15.090486 379.654572,15.256186 380.008545,15.670027
	C380.074799,15.918169 380.026611,15.972087 379.743774,15.940066
	C379.300232,15.613915 379.139465,15.319784 378.978729,15.025652
	C378.978729,15.025652 379.027191,14.973430 379.053223,14.949108
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M400.306946,487.023621
	C400.176208,486.723053 400.312866,486.361267 400.767334,486.019775
	C400.914856,486.347473 400.744568,486.654938 400.306946,487.023621
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M294.980347,487.297791
	C295.270874,487.173187 295.625305,487.316742 295.975281,487.772064
	C295.661957,487.911163 295.353088,487.738556 294.980347,487.297791
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M399.305573,488.022949
	C399.176361,487.724640 399.314453,487.364563 399.768799,487.021667
	C399.914337,487.346313 399.743591,487.653748 399.305573,488.022949
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M295.980347,488.297729
	C296.271301,488.173096 296.626160,488.316559 296.975708,488.771973
	C296.661682,488.911224 296.352966,488.738525 295.980347,488.297729
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M398.305298,489.022583
	C398.176392,488.725342 398.314758,488.366058 398.768982,488.022369
	C398.914062,488.345459 398.743317,488.652985 398.305298,489.022583
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M296.980133,489.298096
	C297.271912,489.173096 297.627563,489.316101 297.976440,489.771637
	C297.661102,489.911469 297.352570,489.738770 296.980133,489.298096
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M235.296417,204.021362
	C235.168488,203.726730 235.310608,203.367691 235.768524,203.016205
	C235.911667,203.334808 235.739059,203.645889 235.296417,204.021362
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M239.310425,201.019470
	C239.165390,200.706940 239.285980,200.329910 239.745361,199.993317
	C239.914764,200.340836 239.745392,200.647919 239.310425,201.019470
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M235.019592,316.705688
	C234.714676,316.834747 234.345169,316.696228 234.004425,316.235779
	C234.340439,316.088623 234.647720,316.263367 235.019592,316.705688
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M240.025421,320.693359
	C239.687469,320.838135 239.289993,320.716125 238.970398,320.254791
	C239.354172,320.085815 239.660034,320.256195 240.025421,320.693359
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M408.018494,41.701820
	C407.723358,41.829014 407.360138,41.690800 407.014343,41.233139
	C407.337982,41.087933 407.644196,41.262173 408.018494,41.701820
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M291.921082,36.926987
	C292.087280,36.636719 292.253479,36.346451 292.670410,35.991180
	C292.921143,35.926174 292.951752,35.958218 293.039001,36.302128
	C292.746216,36.759396 292.366119,36.872753 291.969604,36.971825
	C291.953186,36.957535 291.921082,36.926983 291.921082,36.926987
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M402.016418,35.707478
	C401.727905,35.829208 401.371216,35.684753 401.021118,35.227371
	C401.334534,35.090057 401.641388,35.265675 402.016418,35.707478
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M401.017212,34.705696
	C400.727997,34.828724 400.370636,34.685661 400.020813,34.228302
	C400.335266,34.089203 400.642151,34.264404 401.017212,34.705696
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M400.018372,33.704758
	C399.722504,33.829994 399.359283,33.689190 399.014343,33.230789
	C399.338776,33.088367 399.644928,33.263542 400.018372,33.704758
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M399.018738,32.700653
	C398.718536,32.830112 398.350922,32.694553 398.009094,32.236217
	C398.340363,32.087505 398.645844,32.261570 399.018738,32.700653
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M239.023865,319.686615
	C238.675003,319.839417 238.265793,319.727875 237.954453,319.266846
	C238.356049,319.085663 238.659790,319.253967 239.023865,319.686615
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M241.022339,321.704865
	C240.713333,321.833923 240.340408,321.694916 240.001572,321.234680
	C240.343246,321.087860 240.650848,321.262329 241.022339,321.704865
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M234.025177,315.695801
	C233.686234,315.839020 233.287857,315.715271 232.968964,315.253693
	C233.354202,315.086853 233.659958,315.257843 234.025177,315.695801
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M404.089386,146.083603
	C403.918365,146.371429 403.747314,146.659256 403.308014,146.994324
	C403.039734,147.041580 403.004364,147.004364 402.976379,146.693481
	C403.298950,146.255280 403.649475,146.127945 404.022522,146.021149
	C404.045044,146.041718 404.089386,146.083603 404.089386,146.083603
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M233.022705,314.686096
	C232.680954,314.839081 232.278214,314.727448 231.961655,314.266541
	C232.352463,314.085327 232.657104,314.253387 233.022705,314.686096
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M245.707764,152.981689
	C245.837326,153.287231 245.699768,153.659348 245.237976,154.000702
	C245.089371,153.662720 245.264999,153.355499 245.707764,152.981689
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M49.978615,314.304138
	C50.270260,314.177246 50.624931,314.317780 50.973122,314.770935
	C50.658314,314.912872 50.349979,314.742188 49.978615,314.304138
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M296.941345,31.938766
	C297.087830,31.629772 297.254242,31.341816 297.691467,31.007338
	C297.962311,30.960815 298.005951,31.005938 298.049500,31.321449
	C297.729248,31.758429 297.365448,31.879892 297.001648,32.001358
	C297.001648,32.001362 296.961273,31.959803 296.941345,31.938766
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M232.019089,313.689606
	C231.681198,313.839874 231.279449,313.727264 230.962036,313.266357
	C231.349335,313.087616 231.652283,313.257172 232.019089,313.689606
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M407.017426,40.703369
	C406.728912,40.827911 406.371216,40.686787 406.020813,40.229961
	C406.334808,40.088745 406.641541,40.263222 407.017426,40.703369
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M403.057434,147.060181
	C402.909424,147.367874 402.743683,147.656952 402.308777,147.993713
	C402.039612,148.041428 402.003204,148.003204 401.976135,147.695160
	C402.300812,147.259537 402.652588,147.131943 403.004364,147.004364
	C403.004364,147.004364 403.039734,147.041580 403.057434,147.060181
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M62.978226,327.301025
	C63.273926,327.175140 63.630852,327.317230 63.975212,327.772217
	C63.654915,327.913025 63.347183,327.740997 62.978226,327.301025
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M402.057800,148.060547
	C401.910400,148.368469 401.744781,148.657272 401.309540,148.993835
	C401.039917,149.041611 401.001160,149.001129 400.973328,148.692291
	C401.298096,148.256699 401.650635,148.129944 402.003204,148.003204
	C402.003204,148.003204 402.039612,148.041428 402.057800,148.060547
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M66.976288,330.302734
	C67.276703,330.174377 67.636795,330.314941 67.978714,330.770325
	C67.652359,330.913940 67.344170,330.742798 66.976288,330.302734
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M401.059235,149.061905
	C400.913300,149.370911 400.748047,149.659622 400.310730,149.994263
	C400.038696,150.040207 399.993530,149.993484 399.958130,149.681229
	C400.282227,149.246353 400.641693,149.123734 401.001160,149.001129
	C401.001160,149.001129 401.039917,149.041611 401.059235,149.061905
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M248.023804,326.692078
	C247.677383,326.840546 247.271011,326.723724 246.957977,326.262054
	C247.355484,326.087097 247.659653,326.256989 248.023804,326.692078
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M400.061035,150.063782
	C399.917511,150.374313 399.751648,150.661270 399.339172,151.016876
	C399.092590,151.085541 399.041656,151.039200 398.978790,150.700623
	C399.275116,150.239182 399.634308,150.116333 399.993530,149.993469
	C399.993530,149.993484 400.038696,150.040207 400.061035,150.063782
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M245.023590,324.699921
	C244.698288,324.837708 244.312393,324.707672 243.984589,324.246094
	C244.349442,324.087036 244.656219,324.259552 245.023590,324.699921
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M232.978821,356.293427
	C233.282791,356.166656 233.648026,356.309326 233.989258,356.769287
	C233.656860,356.912018 233.348465,356.737457 232.978821,356.293427
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M287.934601,40.931507
	C288.083801,40.625408 288.252289,40.338356 288.687469,40.001678
	C288.954193,39.952057 288.987885,39.987930 289.072235,40.330097
	C288.767426,40.778748 288.378235,40.885227 287.989075,40.991711
	C287.989075,40.991714 287.953857,40.950554 287.934601,40.931507
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M392.045898,156.048035
	C391.901062,156.355865 391.743408,156.649643 391.334564,157.010345
	C391.083374,157.077255 391.041992,157.038605 391.003693,156.720078
	C391.312775,156.270782 391.660126,156.140015 392.007507,156.009247
	C392.007507,156.009247 392.033051,156.033966 392.045898,156.048035
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M406.016724,39.705872
	C405.728882,39.828423 405.372162,39.685196 405.021423,39.228256
	C405.334259,39.089725 405.641052,39.264912 406.016724,39.705872
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M288.937103,39.934330
	C289.086975,39.628483 289.253906,39.340359 289.687683,39.002281
	C289.954559,38.952328 289.987396,38.987480 290.069702,39.324280
	C289.763977,39.770027 289.375916,39.878979 288.987885,39.987930
	C288.987885,39.987930 288.954193,39.952057 288.937103,39.934330
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M289.937805,38.934978
	C290.088379,38.629284 290.255707,38.340939 290.689575,38.003178
	C290.956085,37.953762 290.986267,37.986324 291.059021,38.320877
	C290.750305,38.766117 290.368835,38.876797 289.987396,38.987480
	C289.987396,38.987480 289.954559,38.952328 289.937805,38.934978
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M244.025726,323.689941
	C243.667175,323.840851 243.249924,323.726898 242.944702,323.265656
	C243.360168,323.087250 243.663605,323.256165 244.025726,323.689941
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M290.940460,37.937576
	C291.090424,37.632519 291.255981,37.343651 291.671326,36.990883
	C291.921082,36.926983 291.953186,36.957535 292.040100,37.302986
	C291.746796,37.761066 291.366516,37.873695 290.986267,37.986324
	C290.986267,37.986324 290.956085,37.953762 290.940460,37.937576
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M303.698181,25.980820
	C303.824402,26.269859 303.684357,26.624931 303.230286,26.974873
	C303.088165,26.662111 303.260071,26.354481 303.698181,25.980820
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M242.979874,365.309082
	C243.308823,365.163422 243.700348,365.281982 244.023285,365.741669
	C243.650604,365.912964 243.346527,365.743134 242.979874,365.309082
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M394.019714,365.703125
	C393.709473,365.832764 393.333313,365.697235 392.998657,365.237732
	C393.344696,365.088440 393.649261,365.263214 394.019714,365.703125
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M243.979492,366.309631
	C244.309036,366.163513 244.700882,366.281769 245.023529,366.741486
	C244.650146,366.913269 244.345978,366.743622 243.979492,366.309631
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M395.017395,366.705353
	C394.728302,366.828094 394.370697,366.685089 394.021057,366.227783
	C394.335663,366.088867 394.642273,366.264221 395.017395,366.705353
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M244.979248,367.310181
	C245.304428,367.164673 245.691895,367.283905 246.017731,367.743439
	C245.651245,367.914124 245.346405,367.744537 244.979248,367.310181
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M223.017242,304.689880
	C222.686890,304.840240 222.292374,304.727448 221.970078,304.266174
	C222.345901,304.087402 222.649475,304.257050 223.017242,304.689880
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M245.978180,368.309143
	C246.291275,368.167847 246.666824,368.293060 247.002258,368.751587
	C246.654999,368.915161 246.347824,368.745392 245.978180,368.309143
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M246.977463,369.303986
	C247.280350,369.170044 247.646332,369.304291 247.989899,369.761993
	C247.658508,369.914368 247.349533,369.743256 246.977463,369.303986
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M393.018433,364.695312
	C392.702118,364.833069 392.318726,364.708160 391.989136,364.248932
	C392.345764,364.087280 392.648560,364.259949 393.018433,364.695312
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M222.018524,303.694641
	C221.697678,303.837494 221.312454,303.715759 220.983459,303.254974
	C221.344513,303.087311 221.649323,303.258698 222.018524,303.694641
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M247.978210,370.300354
	C248.270782,370.171448 248.627533,370.312103 248.979813,370.768127
	C248.664352,370.912354 248.353363,370.741150 247.978210,370.300354
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M400.024475,370.684509
	C399.704224,370.826447 399.324371,370.703247 398.997559,370.247986
	C399.355316,370.083710 399.660095,370.251556 400.024475,370.684509
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M248.984375,371.292236
	C249.265762,371.170959 249.615204,371.317291 249.972977,371.771881
	C249.671677,371.906738 249.362045,371.733307 248.984375,371.292236
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M401.024811,371.686890
	C400.706818,371.826141 400.329193,371.699768 400.000610,371.244507
	C400.354828,371.084137 400.660004,371.252686 401.024811,371.686890
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M221.022095,302.693390
	C220.705048,302.835388 220.325958,302.710175 219.992126,302.249786
	C220.344925,302.085114 220.652496,302.255646 221.022095,302.693390
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M402.024536,372.686951
	C401.711151,372.825226 401.337738,372.697479 401.005676,372.242554
	C401.352905,372.083893 401.658722,372.252411 402.024536,372.686951
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M250.976791,374.315308
	C251.295731,374.167542 251.675507,374.286530 252.008072,374.744995
	C251.653122,374.916992 251.345367,374.749512 250.976791,374.315308
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M225.018097,306.688232
	C224.672531,306.842743 224.264130,306.734894 223.952271,306.273071
	C224.350952,306.088013 224.653107,306.256927 225.018097,306.688232
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M231.018677,312.689972
	C230.677246,312.841675 230.272873,312.730438 229.958008,312.268799
	C230.350266,312.087982 230.652985,312.257477 231.018677,312.689972
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M297.940735,30.938019
	C298.086182,30.628744 298.253204,30.342268 298.667542,29.988777
	C298.914825,29.921764 298.961395,29.963823 299.030151,30.304327
	C298.734619,30.765202 298.370270,30.885572 298.005951,31.005939
	C298.005951,31.005938 297.962311,30.960815 297.940735,30.938019
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M47.982681,311.299438
	C48.264992,311.177704 48.612873,311.323364 48.967148,311.776947
	C48.665115,311.912201 48.356682,311.739532 47.982681,311.299438
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M46.976715,310.307434
	C47.269932,310.177765 47.624714,310.316620 47.973721,310.769165
	C47.658054,310.913910 47.348171,310.744934 46.976715,310.307434
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M383.061768,357.940765
	C383.375183,358.084106 383.660156,358.253662 384.010437,358.670593
	C384.075775,358.917999 384.028259,358.968994 383.744995,358.941132
	C383.301819,358.617126 383.141876,358.320984 382.981934,358.024841
	C382.981934,358.024841 383.033325,357.967010 383.061768,357.940765
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M236.979431,359.293762
	C237.275421,359.168610 237.633438,359.313507 237.981384,359.772034
	C237.661346,359.911713 237.351410,359.737732 236.979431,359.293762
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M405.095062,145.088531
	C404.925629,145.376007 404.756226,145.663467 404.338074,146.017273
	C404.089386,146.083603 404.045044,146.041718 403.977417,145.703339
	C404.271820,145.241928 404.633850,145.118912 405.020630,145.019073
	C405.045410,145.042267 405.095062,145.088531 405.095062,145.088531
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M237.978210,360.297791
	C238.279144,360.169373 238.643738,360.309174 238.987503,360.767700
	C238.658401,360.912964 238.350128,360.739502 237.978210,360.297791
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M404.023438,374.683899
	C403.707703,374.825775 403.331818,374.702576 403.001953,374.247620
	C403.353088,374.083527 403.658203,374.251190 404.023438,374.683899
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M238.975220,361.304504
	C239.298004,361.165955 239.681091,361.295013 240.010330,361.754639
	C239.649490,361.914185 239.342499,361.743134 238.975220,361.304504
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M239.976364,362.310333
	C240.305969,362.165222 240.696762,362.285858 241.021072,362.744995
	C240.648911,362.914368 240.343231,362.745209 239.976364,362.310333
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M406.067871,144.070648
	C405.924042,144.381943 405.755585,144.667007 405.341095,145.020294
	C405.095062,145.088531 405.045410,145.042267 404.972778,144.699677
	C405.264252,144.235886 405.628326,144.114700 405.992401,143.993530
	C405.992401,143.993546 406.043213,144.044449 406.067871,144.070648
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M390.018860,362.704834
	C389.716431,362.831390 389.347168,362.692383 389.007080,362.233337
	C389.341492,362.088623 389.646759,362.263947 390.018860,362.704834
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M240.980072,363.309326
	C241.307220,363.164246 241.697937,363.283020 242.022095,363.742249
	C241.651566,363.912872 241.347610,363.743011 240.980072,363.309326
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M224.017807,305.689117
	C223.674377,305.842499 223.267700,305.733459 222.954422,305.271729
	C223.350067,305.088226 223.652313,305.257477 224.017807,305.689117
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M241.980194,364.308594
	C242.308350,364.163422 242.699402,364.282349 243.022797,364.742004
	C242.651123,364.912689 242.347107,364.742706 241.980194,364.308594
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M293.998779,163.001221
	C286.603638,163.000793 279.207855,163.058258 271.813507,162.981339
	C266.043671,162.921341 264.021606,160.031708 262.847229,154.550308
	C260.420898,143.225464 266.684906,134.235931 269.784637,124.379501
	C270.676453,121.543793 271.447662,118.653336 272.575989,115.912209
	C275.258423,109.395546 278.801788,103.149361 280.738800,96.431030
	C283.148438,88.073486 284.291565,79.350777 286.138550,70.064209
	C286.883667,67.986923 287.556213,66.660919 288.046570,65.270683
	C291.623322,55.130089 298.078705,47.045700 306.382751,39.951027
	C315.046173,37.022808 323.454559,34.455601 331.573975,31.173618
	C339.911560,27.803457 348.284485,25.843889 357.234528,27.602448
	C371.401215,30.386013 382.852631,38.007313 393.225403,47.680031
	C406.666931,60.214371 409.429535,76.790741 410.928070,93.822762
	C411.274597,97.761192 411.390991,101.946632 410.448883,105.728310
	C407.834930,116.220589 404.104614,126.403656 396.607880,134.510910
	C391.148224,140.415192 385.294556,146.067352 376.794037,147.726776
	C374.259583,143.778809 371.931091,140.102310 368.815247,135.182648
	C368.639832,137.923172 368.662476,139.416595 368.421387,140.866150
	C368.122894,142.660736 367.806702,144.512650 367.051880,146.131744
	C366.844299,146.576965 364.282104,146.639206 364.103760,146.261597
	C363.256866,144.468582 362.169189,142.356430 362.446442,140.554749
	C363.037170,136.715607 364.419556,132.998291 365.477509,129.231049
	C366.013397,129.571640 366.549255,129.912231 367.085114,130.252823
	C368.434296,128.839767 370.483154,127.643082 370.999695,125.972580
	C372.472137,121.210503 373.288452,116.247887 374.440460,111.382530
	C374.689087,110.332375 375.262665,109.359169 375.727905,108.252090
	C382.351532,113.791870 382.607330,113.526115 385.294128,104.985352
	C386.283325,101.840836 388.747925,99.175781 390.408691,96.218544
	C390.960449,95.236046 391.352325,94.029686 391.377747,92.915657
	C391.420776,91.028473 391.212006,89.118500 390.927765,87.245621
	C390.634949,85.316414 390.379333,83.317833 389.659180,81.530220
	C388.054871,77.548065 386.318176,73.602684 384.351715,69.788582
	C382.929901,67.030952 381.347504,64.245399 379.302521,61.947704
	C376.713226,59.038372 374.991425,59.750751 374.044373,63.613792
	C373.612915,65.373680 373.023438,67.094818 372.505615,68.833527
	C369.675201,66.379395 368.095398,63.898548 366.688171,61.323399
	C364.920227,58.088203 363.371307,58.563828 361.809662,61.433590
	C360.779297,63.327141 359.748657,65.220543 358.580933,67.366089
	C351.639191,60.350525 351.641113,60.351460 347.449432,69.039871
	C347.161072,69.637634 346.947540,70.496918 346.460632,70.724800
	C345.549561,71.151184 344.261932,71.672607 343.481842,71.337547
	C336.946991,68.530724 337.015717,68.489716 334.813812,75.050758
	C333.667145,78.467430 332.072449,80.452103 328.393494,77.181236
	C327.712646,76.575897 324.792389,77.139114 324.162048,78.041039
	C319.173920,85.178123 316.456635,93.348045 315.079773,101.830269
	C314.129486,107.684700 314.518188,113.744812 313.810333,119.653336
	C313.006012,126.366959 314.547882,136.775955 317.299530,142.989319
	C318.163422,144.940079 317.714935,147.472061 317.952637,151.010895
	C315.774811,149.481049 314.957306,149.008774 314.263519,148.396347
	C313.123779,147.390305 312.064301,146.293350 310.970978,145.234741
	C310.003448,146.681625 308.290619,148.083649 308.200348,149.583298
	C307.851685,155.378891 304.729828,157.486877 299.344971,158.322678
	C297.368744,158.629410 295.769806,161.366989 293.999390,162.997864
	C294.000000,163.000000 293.998779,163.001221 293.998779,163.001221
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M64.999390,209.001831
	C65.166306,209.001358 65.333221,209.000870 65.750519,208.999268
	C69.131622,206.367722 72.509346,203.976593 75.339828,201.055252
	C80.654739,195.569733 87.113937,193.367294 94.542686,192.954987
	C102.138802,192.533401 109.749634,190.980972 117.291656,191.318253
	C127.788490,191.787628 138.291718,193.164474 148.672623,194.877548
	C158.844330,196.556061 168.654373,199.088470 179.331329,197.697189
	C188.620010,196.486832 198.254959,197.758194 207.717361,198.284286
	C208.870697,198.348404 209.924438,200.575531 210.969040,201.845703
	C211.136902,202.049774 210.894196,202.562485 211.041321,202.816849
	C216.692184,212.588287 208.774963,217.421890 203.937683,223.821899
	C198.656357,230.809433 190.895584,236.440460 189.616989,246.706253
	C188.819534,247.867645 188.036850,248.566284 188.084061,249.203415
	C188.755493,258.262909 188.351425,267.619324 190.580811,276.294006
	C193.740921,288.590240 199.221085,300.216217 207.697372,310.032898
	C210.972351,313.825745 214.556183,317.351929 217.999298,320.999603
	C217.999481,321.749695 217.999680,322.499817 217.655853,323.587036
	C213.662888,320.827209 209.902481,317.851074 206.386765,314.609589
	C197.413269,306.335999 189.233459,297.467072 186.536865,284.992249
	C185.945435,282.256165 185.862595,279.413727 185.355042,276.655334
	C182.194046,259.475647 180.096329,242.475784 192.811478,227.721344
	C192.920074,227.595322 193.035141,227.468826 193.114624,227.324600
	C196.051422,221.994125 195.900497,215.306534 191.966446,212.661880
	C186.100281,208.718307 179.904785,205.377716 172.174927,207.345886
	C167.366852,208.570114 162.165039,211.790802 157.589737,206.479095
	C157.284637,206.124908 156.342117,206.272583 155.695374,206.266998
	C146.914429,206.191254 138.130981,206.227249 129.353577,206.013306
	C126.150963,205.935257 123.436226,206.003616 122.103523,209.637543
	C121.263550,211.927917 119.699097,212.583557 117.424133,210.926880
	C112.085503,207.039154 112.069160,207.114487 108.196037,212.160858
	C107.637894,212.888062 106.709129,213.330795 106.047295,213.832794
	C99.104645,209.576187 99.104645,209.576187 94.300690,215.428070
	C89.497337,221.279175 85.718315,221.875916 81.385384,217.297272
	C80.710732,218.019409 80.118187,218.825668 79.362389,219.426620
	C77.821777,220.651596 76.152672,222.702927 74.613342,222.636368
	C73.326653,222.580750 72.123459,220.014465 70.929535,218.522995
	C68.934288,216.030457 66.975922,213.508408 65.001358,210.665955
	C65.000244,209.889359 64.999817,209.445587 64.999390,209.001831
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M218.112778,320.784302
	C214.556183,317.351929 210.972351,313.825745 207.697372,310.032898
	C199.221085,300.216217 193.740921,288.590240 190.580811,276.294006
	C188.351425,267.619324 188.755493,258.262909 188.084061,249.203415
	C188.036850,248.566284 188.819534,247.867645 189.608459,247.098816
	C190.985443,248.974960 191.970276,250.949921 192.955109,252.924881
	C193.481171,253.101929 194.007233,253.278961 194.533295,253.456009
	C197.113327,248.471222 199.664520,243.471085 202.299133,238.515320
	C202.790756,237.590622 203.617065,236.843857 204.643158,236.006561
	C205.035614,236.251358 205.073776,236.504532 205.206879,237.132675
	C201.036453,247.937210 200.580170,258.868591 201.121292,270.624756
	C201.465378,271.553162 201.719955,271.776489 201.974518,271.999786
	C201.974518,271.999786 202.002884,272.003204 201.996658,272.017914
	C201.990234,273.411774 201.990021,274.790955 201.644745,276.520630
	C199.935623,274.979034 198.571594,273.086945 196.414444,270.094696
	C197.222290,285.355255 202.138306,297.313171 210.460449,308.008636
	C213.471130,311.877930 216.163574,315.994843 219.001221,319.998779
	C218.685287,320.110535 218.426956,320.300629 218.112778,320.784302
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M306.000824,39.998215
	C298.078705,47.045700 291.623322,55.130089 288.046570,65.270683
	C287.556213,66.660919 286.883667,67.986923 286.145905,69.671585
	C284.649933,72.462990 283.110901,74.842247 282.002594,77.407570
	C280.362213,81.204315 279.507843,85.646530 273.461914,84.611473
	C273.701965,79.577209 275.494720,75.534241 278.385895,71.774559
	C280.260162,69.337280 281.706696,66.383942 282.634094,63.438072
	C286.707672,50.498623 294.555847,40.876175 306.606750,34.554344
	C309.303345,33.139713 311.544922,30.857706 314.324646,28.941956
	C315.766449,27.932135 316.879608,26.955383 317.992798,25.978632
	C325.131165,23.614721 331.951477,20.809359 336.027649,13.351090
	C338.728516,14.723071 340.856140,16.143076 343.910858,18.181812
	C341.306915,20.120659 339.507172,21.638372 337.533936,22.880367
	C335.529541,24.141941 333.339020,25.104246 331.258362,26.249172
	C329.681549,27.116806 328.192505,28.150927 326.588654,28.960928
	C322.710022,30.919773 318.797150,32.811832 314.877472,34.687851
	C313.115204,35.531288 311.222595,36.131687 309.540771,37.104500
	C308.238556,37.857735 307.172089,39.018570 306.000824,39.998215
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M289.996552,170.998779
	C288.283173,170.999374 286.569824,170.999969 284.428223,171.000290
	C285.469635,169.668396 286.842224,168.203827 288.435150,167.041168
	C290.150848,165.788940 292.072998,164.819611 293.951904,163.364029
	C293.998779,163.001221 294.000000,163.000000 294.432709,162.999146
	C296.664429,162.800232 298.464874,162.614670 300.262177,162.402130
	C315.470856,160.603714 330.685730,158.855225 345.884277,156.974869
	C353.235016,156.065430 360.565247,154.972443 367.882507,153.821030
	C370.290985,153.442032 372.629486,152.618454 375.200470,152.264191
	C377.438995,152.337341 379.543304,152.399475 381.499512,151.891510
	C384.389069,151.141235 387.173340,149.985565 389.957642,149.357986
	C388.704071,150.735458 387.645599,152.074356 386.263062,152.729980
	C373.585449,158.742218 359.992004,160.825531 346.168274,162.019058
	C332.042053,163.238693 317.898163,164.399292 303.842651,166.211334
	C299.100372,166.822708 294.604858,169.348129 289.996552,170.998779
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M313.996002,28.975025
	C311.544922,30.857706 309.303345,33.139713 306.606750,34.554344
	C294.555847,40.876175 286.707672,50.498623 282.634094,63.438072
	C281.706696,66.383942 280.260162,69.337280 278.385895,71.774559
	C275.494720,75.534241 273.701965,79.577209 273.059875,84.612526
	C272.821381,85.002045 272.629364,85.000015 272.148590,84.985298
	C272.526855,79.230667 273.193817,73.488739 274.000000,67.362061
	C278.952789,59.150200 283.766327,51.323082 288.363159,43.282272
	C288.235046,42.580959 288.323639,42.093338 288.200653,41.298714
	C288.378235,40.885227 288.767426,40.778748 289.072235,40.330097
	C289.375916,39.878979 289.763977,39.770027 290.069702,39.324276
	C290.368835,38.876797 290.750305,38.766117 291.059021,38.320877
	C291.366516,37.873695 291.746796,37.761066 292.056549,37.317272
	C292.366119,36.872753 292.746216,36.759396 293.056824,36.314941
	C293.430603,36.050610 293.873840,36.117371 294.865692,36.328392
	C300.137451,34.059547 304.810547,31.538622 309.617950,29.307135
	C310.860901,28.730198 312.525238,29.061131 313.996002,28.975025
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M95.998627,184.998627
	C95.555946,184.999512 95.113258,185.000397 93.941803,185.010223
	C91.132790,185.337494 89.052551,185.655792 87.051628,185.620087
	C95.564461,180.474869 104.836052,178.754807 114.332161,178.009827
	C122.171265,177.394836 128.965118,180.177170 134.621216,184.954834
	C132.456329,184.986755 130.723938,185.000504 128.991547,185.014252
	C128.243439,185.010193 127.495338,185.006134 126.128937,184.735352
	C115.600349,180.583694 105.733383,179.110123 95.998627,184.998627
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M61.021294,197.307251
	C61.357544,196.879837 61.705029,196.749542 62.032120,196.316666
	C62.994305,195.578033 63.976879,195.141983 64.984474,194.357635
	C65.344803,193.870239 65.680107,193.731155 66.012772,193.302155
	C67.377365,192.251083 68.744614,191.489929 70.062210,190.371948
	C70.617004,189.895691 71.221451,189.776260 71.916168,189.332458
	C72.357246,188.877655 72.708046,188.747238 73.035965,188.316803
	C73.748131,188.016251 74.483177,188.015686 75.757500,188.181015
	C76.825012,187.853470 77.353256,187.360001 77.881500,186.866547
	C77.881500,186.866547 77.882874,186.895218 78.300980,186.928925
	C80.817200,186.646759 82.915321,186.330887 85.010895,186.386078
	C84.999825,187.498703 84.991287,188.240250 84.982758,188.981812
	C83.569160,189.319290 82.155563,189.656769 80.018776,190.002686
	C78.528954,190.671677 77.762321,191.332245 76.995682,191.992798
	C76.995682,191.992798 77.000000,192.000000 76.607529,191.998154
	C74.235588,192.722855 71.797264,192.946228 70.354843,194.261658
	C63.526482,200.488846 56.993114,207.038483 50.288410,213.403473
	C49.429066,214.219284 48.182793,214.627548 47.115761,215.224609
	C47.154694,214.725693 47.193626,214.226761 47.122093,213.368744
	C47.742363,212.242950 48.473099,211.476273 49.106361,210.358582
	C49.742249,209.242111 50.475605,208.476654 51.110573,207.361053
	C54.034569,204.241837 57.056957,201.472748 60.044502,198.356216
	C60.350613,197.873962 60.691570,197.739151 61.021294,197.307251
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M46.823742,215.218506
	C48.182793,214.627548 49.429066,214.219284 50.288410,213.403473
	C56.993114,207.038483 63.526482,200.488846 70.354843,194.261658
	C71.797264,192.946228 74.235588,192.722855 76.603775,191.995712
	C76.993942,192.161896 76.995392,192.328690 76.997513,192.745651
	C68.455254,196.535080 62.823353,203.489502 56.937248,210.133728
	C53.485867,214.029633 50.103756,217.986923 46.342838,221.953369
	C45.977139,220.844513 45.959087,219.698883 45.889160,218.120483
	C45.948677,217.011169 46.060066,216.334610 46.081409,215.324249
	C46.171482,215.064423 46.351604,215.138412 46.823742,215.218506
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M184.017090,185.370178
	C179.517441,186.858856 175.069351,189.361069 170.505783,189.593216
	C164.034027,189.922409 157.471451,188.629105 150.965118,187.878036
	C150.270660,187.797882 149.692459,186.710815 149.045105,185.550110
	C149.029861,185.009384 148.978897,184.721512 149.437256,184.800491
	C153.613037,184.873367 157.330460,184.867279 161.475647,184.922241
	C163.911652,185.089203 165.919922,185.195099 168.210480,185.548157
	C170.664093,185.550003 172.835419,185.304688 175.456696,185.090912
	C178.610138,185.205032 181.313614,185.287598 184.017090,185.370178
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M96.243271,185.265625
	C105.733383,179.110123 115.600349,180.583694 125.754814,184.741150
	C122.791122,185.342148 119.584251,185.932159 116.375145,185.944595
	C109.746696,185.970230 103.117058,185.688110 96.243271,185.265625
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M135.881699,177.398361
	C141.271133,177.969498 146.660568,178.540634 152.489685,179.504929
	C151.612549,181.505905 150.295731,183.113708 148.978912,184.721512
	C148.978897,184.721512 149.029861,185.009384 149.034195,185.157776
	C147.964264,185.221115 146.889984,185.136063 145.053558,185.017426
	C143.567642,184.926743 142.843872,184.869614 142.027008,184.495438
	C139.916504,181.918365 137.899109,179.658371 135.881699,177.398361
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M184.454605,185.264648
	C181.313614,185.287598 178.610138,185.205032 175.192871,184.808380
	C172.295456,184.763199 170.111832,185.032089 167.928192,185.300995
	C165.919922,185.195099 163.911652,185.089203 161.578110,184.545410
	C162.760300,183.738663 164.261215,183.086182 165.776367,183.051270
	C171.542938,182.918381 177.318420,183.101395 183.080322,182.888870
	C184.396454,182.840317 185.671738,181.683990 186.965820,181.037521
	C189.251038,181.425995 191.536270,181.814468 193.915161,182.574707
	C193.684372,183.315292 193.359894,183.684128 192.981491,184.294464
	C190.249084,184.743683 187.570602,184.951401 184.454605,185.264648
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M142.120102,184.812500
	C142.843872,184.869614 143.567642,184.926743 144.649765,185.043686
	C144.354401,185.717545 143.674194,186.886749 143.051193,186.857040
	C138.426453,186.636398 133.813095,186.177643 129.093842,185.403946
	C130.723938,185.000504 132.456329,184.986755 135.057495,184.964752
	C137.990875,184.908493 140.055481,184.860504 142.120102,184.812500
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M85.369568,188.980743
	C84.991287,188.240250 84.999825,187.498703 85.337357,186.379120
	C86.101685,185.992096 86.536995,185.983109 86.972313,185.974121
	C89.052551,185.655792 91.132790,185.337494 93.609581,185.014557
	C91.256218,186.333176 88.506294,187.656433 85.369568,188.980743
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M77.316559,191.967468
	C77.762321,191.332245 78.528954,190.671677 79.646889,190.004944
	C79.211266,190.646561 78.424347,191.294357 77.316559,191.967468
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M291.998779,466.000000
	C289.438416,457.146118 286.500397,448.378723 284.447296,439.408722
	C283.022217,433.182556 282.968231,426.654755 282.020569,420.303680
	C281.076752,413.978149 279.559937,407.734375 278.720093,401.398346
	C278.166962,397.225098 278.354218,392.952332 278.236328,388.723389
	C278.182892,386.806915 278.097260,384.883606 278.197906,382.972443
	C278.342407,380.228119 277.922791,377.004822 282.856873,377.270050
	C286.562653,376.710297 289.584503,375.705963 292.683533,375.089478
	C302.698761,373.097137 312.756226,372.206512 322.890778,374.382324
	C323.734161,374.563354 324.720306,373.931244 325.640015,373.931580
	C328.977417,373.932861 332.317200,373.989685 335.650787,374.141418
	C336.757202,374.191772 338.223694,375.171539 338.887329,374.741486
	C345.535004,370.433746 352.653931,373.882538 359.052765,374.460999
	C381.722931,376.510406 397.180420,390.139862 404.016022,411.039703
	C408.997284,426.269897 410.276276,442.811401 405.998169,459.336670
	C405.663025,460.448883 405.330902,461.224426 404.618713,462.007019
	C400.646210,462.393799 398.515320,464.052338 398.873596,468.293854
	C398.496918,469.391602 398.247864,470.195801 397.660370,471.038147
	C396.548370,472.050476 395.774780,473.024628 394.703186,474.148041
	C386.996429,479.508179 380.056091,485.622559 372.087524,489.753540
	C351.497314,500.427734 330.427887,502.551941 311.055573,487.300140
	C303.683167,481.495850 298.292603,473.174286 291.998779,466.000000
M372.001373,448.220612
	C372.601074,446.558075 372.763336,444.488190 373.871033,443.298737
	C381.415619,435.197327 382.731354,428.226929 378.027588,418.154510
	C376.462708,414.803528 375.502472,410.733734 370.379456,410.680084
	C369.558838,410.671478 368.612518,408.568207 367.978302,407.305695
	C365.285645,401.945435 358.607147,401.609741 355.166962,397.135895
	C354.832489,396.700928 352.991058,397.358276 351.870819,397.606384
	C348.344910,398.387238 344.849609,399.321503 341.304138,399.994537
	C337.677917,400.682953 334.411835,401.324585 334.885406,406.341980
	C335.049652,408.082092 333.979218,409.938751 333.472687,411.742157
	C333.113403,411.745117 332.754120,411.748077 332.394836,411.751068
	C331.176666,408.836578 329.958466,405.922089 328.740265,403.007629
	C328.203674,403.117035 327.667084,403.226471 327.130493,403.335876
	C323.406952,411.045135 330.575073,420.364532 323.837585,428.772705
	C322.127960,422.383850 320.661560,416.903992 318.766571,409.822388
	C317.482422,412.066223 316.290924,413.284943 316.099548,414.644440
	C315.523926,418.733521 315.337982,422.877472 314.462250,427.511078
	C313.798370,429.934875 313.134460,432.358643 312.470581,434.782440
	C311.786072,434.883301 311.101562,434.984192 310.417084,435.085083
	C308.533936,429.417145 306.650818,423.749237 304.565186,417.471832
	C303.791962,418.620667 303.196960,419.101776 303.158051,419.624298
	C302.722595,425.473724 301.808655,431.360138 302.124542,437.176819
	C302.678131,447.369873 304.220337,457.505890 304.928955,467.694946
	C305.474091,475.533783 310.661407,483.280243 318.006104,486.152374
	C319.550659,486.756409 321.054718,487.497986 322.500702,488.312408
	C324.838409,489.629028 343.396423,493.383667 345.943939,492.970703
	C346.272034,492.917511 346.618835,492.926544 346.928955,492.822968
	C352.778442,490.868744 358.999725,489.611694 364.377502,486.763214
	C371.581818,482.947235 378.590637,478.220032 381.164459,469.718018
	C382.927826,463.893158 383.835052,457.808136 385.617706,451.990417
	C386.684387,448.509216 384.313049,447.218506 382.373016,446.195557
	C381.198975,445.576508 379.058380,446.651306 377.399200,447.128754
	C375.571320,447.654755 373.797424,448.368225 372.001373,448.220612
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M65.002029,210.998779
	C66.975922,213.508408 68.934288,216.030457 70.929535,218.522995
	C72.123459,220.014465 73.326653,222.580750 74.613342,222.636368
	C76.152672,222.702927 77.821777,220.651596 79.362389,219.426620
	C80.118187,218.825668 80.710732,218.019409 81.385384,217.297272
	C85.718315,221.875916 89.497337,221.279175 94.300690,215.428070
	C99.104645,209.576187 99.104645,209.576187 106.047295,213.832794
	C106.709129,213.330795 107.637894,212.888062 108.196037,212.160858
	C112.069160,207.114487 112.085503,207.039154 117.424133,210.926880
	C119.699097,212.583557 121.263550,211.927917 122.103523,209.637543
	C123.436226,206.003616 126.150963,205.935257 129.353577,206.013306
	C138.130981,206.227249 146.914429,206.191254 155.695374,206.266998
	C156.342117,206.272583 157.284637,206.124908 157.589737,206.479095
	C162.165039,211.790802 167.366852,208.570114 172.174927,207.345886
	C179.904785,205.377716 186.100281,208.718307 191.966446,212.661880
	C195.900497,215.306534 196.051422,221.994125 193.114624,227.324600
	C193.035141,227.468826 192.920074,227.595322 192.811478,227.721344
	C180.096329,242.475784 182.194046,259.475647 185.355042,276.655334
	C185.862595,279.413727 185.945435,282.256165 186.536865,284.992249
	C189.233459,297.467072 197.413269,306.335999 206.386765,314.609589
	C209.902481,317.851074 213.662888,320.827209 217.655594,323.962097
	C219.191971,326.502655 220.384598,329.005280 221.577225,331.507935
	C218.814896,331.691315 215.999420,332.303009 213.301331,331.968414
	C207.764267,331.281738 202.290070,330.087952 196.395111,328.605530
	C195.971695,324.357544 197.291946,319.804108 195.636108,317.022034
	C192.101593,311.083496 187.033951,306.055267 182.557999,300.684448
	C182.277740,300.348145 181.673447,300.084106 181.236069,300.106201
	C174.860275,300.428528 174.355347,295.220398 174.174942,291.329315
	C173.406448,274.753662 171.725891,258.362823 167.756760,242.204849
	C167.295715,240.327911 168.568298,237.333389 170.030045,235.809265
	C173.963806,231.707520 173.350388,223.013626 168.279495,220.224808
	C164.462982,218.125870 160.401428,216.472473 157.802567,215.254913
	C154.045898,216.215057 150.634369,218.292343 148.654739,217.352768
	C143.910431,215.100952 140.731430,217.910416 137.262115,219.541824
	C136.276031,220.005524 135.511246,223.153061 136.101547,224.353668
	C138.118759,228.456375 135.357819,228.746536 132.519043,229.925919
	C132.297592,228.528854 132.308975,227.536636 131.970169,226.683487
	C131.253448,224.878693 130.654144,221.918701 129.469543,221.629028
	C126.620506,220.932327 125.338860,223.133011 124.737465,226.001404
	C124.113533,228.977341 122.748474,231.797897 121.357033,235.659058
	C119.342369,232.934921 117.763069,231.243179 116.706985,229.271210
	C114.967476,226.023102 112.931152,226.885895 111.276871,228.894928
	C109.845627,230.633087 108.900391,232.841965 108.045006,234.965515
	C107.024750,237.498337 106.329140,240.161926 105.149048,243.840775
	C102.734261,240.657837 101.023277,238.402603 99.317810,236.154633
	C92.743713,239.017700 96.379028,245.519073 93.429367,251.285873
	C91.920029,248.283569 90.966232,246.386322 89.983063,244.430634
	C80.026688,246.032608 71.874748,249.642624 70.919403,261.421234
	C70.854881,262.216766 70.579353,263.161285 70.062752,263.726471
	C67.231689,266.823822 68.096519,270.054749 69.217270,273.531586
	C69.836777,275.453430 69.649567,277.635315 69.826004,279.699982
	C69.080414,279.820648 68.334824,279.941315 67.589233,280.061981
	C67.060158,278.208771 66.315338,276.386078 66.047569,274.495819
	C65.514069,270.729767 65.427902,266.892792 64.748360,263.159515
	C64.548698,262.062622 62.973656,261.216064 62.028969,260.254791
	C61.360138,261.274261 60.429764,262.211243 60.076870,263.330353
	C59.339783,265.667725 58.563683,268.080902 58.420467,270.499451
	C58.106445,275.802643 58.082973,281.130157 58.175644,286.443695
	C58.192368,287.402496 59.116554,288.684631 59.989025,289.230621
	C63.748756,291.583435 67.645256,293.717682 72.039177,296.246429
	C72.754028,295.031586 73.861305,293.149841 75.146873,290.965088
	C78.460007,294.695618 78.004250,297.961456 75.217087,300.897614
	C75.950844,302.425385 76.578300,303.587250 77.079971,304.801117
	C79.412010,310.443817 81.713440,315.901062 86.996857,319.822113
	C90.114098,322.135529 93.815575,324.971588 92.999695,330.000000
	C84.748802,327.561066 76.554146,325.028595 70.193367,318.733643
	C69.160530,317.711487 67.864151,316.955566 66.231720,315.823181
	C65.573677,315.301514 65.315826,315.111603 64.960754,314.651794
	C60.091198,307.445007 55.028454,300.694305 55.350449,291.644196
	C55.396770,290.342255 55.357407,288.948700 54.948067,287.735779
	C53.709789,284.066681 52.270012,280.465546 50.908760,276.837952
	C50.293770,276.956573 49.678780,277.075165 49.063793,277.193787
	C48.376411,279.132233 47.989368,281.259460 46.921268,282.958557
	C45.739384,284.838654 43.934258,286.326965 42.400558,287.985901
	C42.400795,273.298248 42.401028,258.610596 42.856644,243.443085
	C46.076862,239.601685 49.327553,236.524170 51.496330,232.814178
	C55.035282,226.760330 57.868538,220.293945 60.999542,214.001617
	C61.490562,213.416687 61.981586,212.831772 63.032639,212.098999
	C64.062454,211.633713 64.532242,211.316254 65.002029,210.998779
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M193.001221,328.000000
	C184.060760,327.667084 175.089981,326.720459 166.187729,327.160553
	C155.664612,327.680725 145.220154,329.624908 134.705246,330.529083
	C125.474487,331.322906 116.193535,331.532959 106.467102,331.594940
	C105.252632,328.885590 104.921211,326.321930 103.674492,324.331573
	C100.878166,319.867218 101.043686,315.625427 104.533928,311.799713
	C105.157295,311.116425 105.624222,309.424255 105.225784,308.860138
	C104.460838,307.777008 103.081169,307.032135 101.809021,306.498627
	C101.599670,306.410828 100.519745,308.057526 100.061356,309.008240
	C99.663734,309.832916 99.576744,310.807312 99.314484,311.869324
	C94.781479,311.566528 94.563889,307.870911 94.416801,305.318054
	C93.991524,297.936523 90.184258,294.458282 83.160812,293.763428
	C82.589211,293.706879 81.707863,293.194397 81.584526,292.730499
	C79.673965,285.544495 77.763062,278.353851 76.167488,271.095032
	C75.977730,270.231812 77.637741,268.961975 78.440331,267.880615
	C79.844551,270.824341 79.698135,273.397644 80.529442,275.602325
	C81.132851,277.202606 83.030373,278.314911 84.350861,279.644775
	C85.501114,277.762085 86.693726,275.902710 87.757286,273.972229
	C87.922386,273.672546 87.384560,273.052979 87.362495,272.572540
	C87.110413,267.083679 86.887314,261.593353 86.704285,256.101837
	C86.697968,255.912338 87.191284,255.706161 87.120811,255.759842
	C89.579460,256.560547 91.789429,257.280273 94.046936,258.381836
	C94.094475,264.797150 94.094475,270.830597 94.094475,276.803131
	C97.561462,278.823822 101.909645,280.434845 104.950546,283.390747
	C107.976585,286.332184 109.564400,291.182190 113.956413,284.602600
	C115.810707,286.918823 117.224998,288.936859 118.911934,290.692963
	C119.840111,291.659241 121.166031,292.663696 122.417999,292.823029
	C126.902374,293.393738 131.469467,293.357056 135.935318,294.020782
	C142.774307,295.037231 144.050980,294.319305 146.099426,287.562012
	C146.582306,285.969116 147.636826,284.549500 148.673187,282.586456
	C150.733505,284.790985 152.230957,286.393219 154.554962,288.879883
	C155.913071,285.646576 156.913620,283.315979 157.873062,280.968597
	C159.433868,277.149872 160.975769,273.323334 162.510483,269.494019
	C163.685715,266.561676 164.917282,263.647980 165.966263,260.670532
	C166.492203,259.177582 166.666611,257.560791 167.272888,256.257996
	C168.697189,262.982452 170.244965,269.409210 170.897202,275.925598
	C171.669647,283.642792 171.189667,291.497681 172.156342,299.179169
	C172.934143,305.359589 175.851608,310.719849 182.317169,313.257812
	C188.997955,315.880249 192.995621,321.550995 193.001221,328.000000
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M93.999390,258.000000
	C91.789429,257.280273 89.579460,256.560547 87.120811,255.759842
	C87.191284,255.706161 86.697968,255.912338 86.704285,256.101837
	C86.887314,261.593353 87.110413,267.083679 87.362495,272.572540
	C87.384560,273.052979 87.922386,273.672546 87.757286,273.972229
	C86.693726,275.902710 85.501114,277.762085 84.350861,279.644775
	C83.030373,278.314911 81.132851,277.202606 80.529442,275.602325
	C79.698135,273.397644 79.844551,270.824341 78.440331,267.880615
	C77.637741,268.961975 75.977730,270.231812 76.167488,271.095032
	C77.763062,278.353851 79.673965,285.544495 81.584526,292.730499
	C81.707863,293.194397 82.589211,293.706879 83.160812,293.763428
	C90.184258,294.458282 93.991524,297.936523 94.416801,305.318054
	C94.563889,307.870911 94.781479,311.566528 99.314484,311.869324
	C99.576744,310.807312 99.663734,309.832916 100.061356,309.008240
	C100.519745,308.057526 101.599670,306.410828 101.809021,306.498627
	C103.081169,307.032135 104.460838,307.777008 105.225784,308.860138
	C105.624222,309.424255 105.157295,311.116425 104.533928,311.799713
	C101.043686,315.625427 100.878166,319.867218 103.674492,324.331573
	C104.921211,326.321930 105.252632,328.885590 105.999123,331.594543
	C105.554474,332.000000 105.110176,332.000000 104.331894,331.609497
	C103.324623,329.887482 102.651329,328.555969 101.978027,327.224487
	C100.985336,328.483398 99.992638,329.742310 98.999939,331.001221
	C97.178947,330.850922 95.357964,330.700623 93.268333,330.275146
	C93.815575,324.971588 90.114098,322.135529 86.996857,319.822113
	C81.713440,315.901062 79.412010,310.443817 77.079971,304.801117
	C76.578300,303.587250 75.950844,302.425385 75.217087,300.897614
	C78.004250,297.961456 78.460007,294.695618 75.146873,290.965088
	C73.861305,293.149841 72.754028,295.031586 72.039177,296.246429
	C67.645256,293.717682 63.748756,291.583435 59.989025,289.230621
	C59.116554,288.684631 58.192368,287.402496 58.175644,286.443695
	C58.082973,281.130157 58.106445,275.802643 58.420467,270.499451
	C58.563683,268.080902 59.339783,265.667725 60.076870,263.330353
	C60.429764,262.211243 61.360138,261.274261 62.028969,260.254791
	C62.973656,261.216064 64.548698,262.062622 64.748360,263.159515
	C65.427902,266.892792 65.514069,270.729767 66.047569,274.495819
	C66.315338,276.386078 67.060158,278.208771 67.589233,280.061981
	C68.334824,279.941315 69.080414,279.820648 69.826004,279.699982
	C69.649567,277.635315 69.836777,275.453430 69.217270,273.531586
	C68.096519,270.054749 67.231689,266.823822 70.062752,263.726471
	C70.579353,263.161285 70.854881,262.216766 70.919403,261.421234
	C71.874748,249.642624 80.026688,246.032608 89.983063,244.430634
	C90.966232,246.386322 91.920029,248.283569 93.429367,251.285873
	C96.379028,245.519073 92.743713,239.017700 99.317810,236.154633
	C101.023277,238.402603 102.734261,240.657837 105.149048,243.840775
	C106.329140,240.161926 107.024750,237.498337 108.045006,234.965515
	C108.900391,232.841965 109.845627,230.633087 111.276871,228.894928
	C112.931152,226.885895 114.967476,226.023102 116.706985,229.271210
	C117.763069,231.243179 119.342369,232.934921 121.357033,235.659058
	C122.748474,231.797897 124.113533,228.977341 124.737465,226.001404
	C125.338860,223.133011 126.620506,220.932327 129.469543,221.629028
	C130.654144,221.918701 131.253448,224.878693 131.970169,226.683487
	C132.308975,227.536636 132.297592,228.528854 132.519043,229.925919
	C135.357819,228.746536 138.118759,228.456375 136.101547,224.353668
	C135.511246,223.153061 136.276031,220.005524 137.262115,219.541824
	C140.731430,217.910416 143.910431,215.100952 148.654739,217.352768
	C150.634369,218.292343 154.045898,216.215057 157.802567,215.254913
	C160.401428,216.472473 164.462982,218.125870 168.279495,220.224808
	C173.350388,223.013626 173.963806,231.707520 170.030045,235.809265
	C168.568298,237.333389 167.295715,240.327911 167.756760,242.204849
	C171.725891,258.362823 173.406448,274.753662 174.174942,291.329315
	C174.355347,295.220398 174.860275,300.428528 181.236069,300.106201
	C181.673447,300.084106 182.277740,300.348145 182.557999,300.684448
	C187.033951,306.055267 192.101593,311.083496 195.636108,317.022034
	C197.291946,319.804108 195.971695,324.357544 196.000397,328.553497
	C195.196381,328.751068 194.392151,328.503326 193.294571,328.127808
	C192.995621,321.550995 188.997955,315.880249 182.317169,313.257812
	C175.851608,310.719849 172.934143,305.359589 172.156342,299.179169
	C171.189667,291.497681 171.669647,283.642792 170.897202,275.925598
	C170.244965,269.409210 168.697189,262.982452 167.264160,255.863434
	C166.655029,253.140549 166.327515,251.070282 165.951279,248.614731
	C164.012741,242.267181 162.206879,236.275970 160.168503,230.364914
	C159.712906,229.043762 158.441483,228.003967 157.545670,226.834641
	C157.030441,227.058899 156.515228,227.283142 156.000000,227.507401
	C156.000000,233.338058 156.000000,239.168732 155.651855,244.957123
	C153.869156,242.943039 152.434586,240.971207 150.959381,238.640839
	C150.469467,237.238708 150.257477,235.963135 149.516281,235.204926
	C148.350861,234.012848 146.828979,233.169250 145.458038,232.178085
	C144.638702,233.451859 143.819351,234.725616 142.940048,236.346832
	C138.363770,237.916489 133.847961,239.140656 129.326462,240.343384
	C129.253326,240.362839 129.110382,240.119888 128.998596,239.613708
	C128.434128,238.219772 127.871071,237.211487 127.308029,236.203217
	C126.538788,237.468613 125.769547,238.733994 124.720421,240.220062
	C121.876015,245.619186 118.158554,247.959442 115.445435,245.352676
	C110.903404,240.988724 109.471016,243.863403 107.697090,247.571564
	C106.315384,250.459793 104.830368,253.073532 102.015015,248.478470
	C101.700348,247.964905 98.800621,248.093857 98.437637,248.711334
	C96.705040,251.658707 95.434349,254.877640 93.999390,258.000000
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M406.001221,459.000000
	C410.276276,442.811401 408.997284,426.269897 404.016022,411.039703
	C397.180420,390.139862 381.722931,376.510406 359.052765,374.460999
	C352.653931,373.882538 345.535004,370.433746 338.887329,374.741486
	C338.223694,375.171539 336.757202,374.191772 335.650787,374.141418
	C332.317200,373.989685 328.977417,373.932861 325.640015,373.931580
	C324.720306,373.931244 323.734161,374.563354 322.890778,374.382324
	C312.756226,372.206512 302.698761,373.097137 292.683533,375.089478
	C289.584503,375.705963 286.562653,376.710297 283.252747,377.267914
	C283.044312,373.670868 282.546600,370.673462 278.643921,369.682709
	C278.350464,369.608185 278.017426,369.363159 277.866272,369.102570
	C274.899963,363.987854 271.957886,358.859070 269.390015,353.374268
	C277.156830,354.676880 284.472260,356.884460 291.948914,357.873749
	C303.584778,359.413391 315.342865,360.003571 327.025360,361.223907
	C337.544373,362.322662 348.013916,364.556946 358.537140,364.778961
	C370.583984,365.033112 380.046753,371.008118 389.801697,376.463623
	C398.262543,381.195435 402.418884,389.916779 407.188263,397.839355
	C414.189728,409.469788 419.434937,421.873077 418.820587,435.731628
	C418.578186,441.199738 416.332336,446.579041 414.649109,452.039856
	C413.068207,453.317993 411.920197,454.655396 410.583008,455.764771
	C409.148468,456.954956 407.536041,457.930725 406.001221,459.000000
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M414.998779,451.998779
	C416.332336,446.579041 418.578186,441.199738 418.820587,435.731628
	C419.434937,421.873077 414.189728,409.469788 407.188263,397.839355
	C402.418884,389.916779 398.262543,381.195435 389.801697,376.463623
	C380.046753,371.008118 370.583984,365.033112 358.537140,364.778961
	C348.013916,364.556946 337.544373,362.322662 327.025360,361.223907
	C315.342865,360.003571 303.584778,359.413391 291.948914,357.873749
	C284.472260,356.884460 277.156830,354.676880 269.384583,353.008301
	C259.543243,347.762939 250.061188,342.569397 240.653122,337.245087
	C238.960159,336.287018 237.567139,334.796326 236.043793,333.541626
	C230.597824,329.056030 225.155350,324.566193 219.356415,320.038361
	C216.163574,315.994843 213.471130,311.877930 210.460449,308.008636
	C202.138306,297.313171 197.222290,285.355255 196.414444,270.094696
	C198.571594,273.086945 199.935623,274.979034 201.641220,276.934814
	C203.502914,288.572632 208.988571,298.396820 216.616730,306.776886
	C227.405380,318.629059 237.817429,330.867218 251.329010,340.051147
	C262.926697,347.934235 276.010681,350.950500 289.233337,353.951782
	C290.948242,354.341034 292.750610,354.344910 294.759613,354.259155
	C302.137634,354.524475 309.268555,355.057953 317.109131,355.296356
	C318.908447,354.893494 319.998016,354.785706 321.514221,354.742554
	C323.958008,354.893005 325.975189,354.978790 328.075073,355.429688
	C329.753815,356.516754 331.304993,357.721222 332.953918,357.874512
	C339.022980,358.438629 345.179199,358.258545 351.201111,359.092041
	C360.741669,360.412598 370.602692,360.460693 379.337860,365.588043
	C381.981171,367.139648 385.099823,367.881531 388.000732,368.994293
	C388.000732,368.994293 388.000000,369.000000 388.038696,369.345093
	C389.173950,370.859406 390.253632,372.045044 391.370300,373.194763
	C394.941711,376.871918 399.192383,380.095184 401.971802,384.293976
	C408.252319,393.781982 413.741730,403.790924 419.744354,413.469208
	C420.498199,414.684692 422.404633,415.185333 423.777039,416.017212
	C423.805786,425.370453 423.834534,434.723694 423.771484,444.539612
	C420.991302,448.563385 418.302917,452.124512 415.307281,455.442261
	C414.999573,454.132202 414.999176,453.065491 414.998779,451.998779
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M414.649109,452.039856
	C414.999176,453.065491 414.999573,454.132202 415.000000,455.598846
	C414.666351,457.740143 414.332672,459.481506 413.997681,461.609619
	C410.585663,465.245514 406.583862,468.070129 403.883545,471.829559
	C397.663727,480.488770 388.830444,485.722931 380.009125,490.820496
	C372.016876,495.439026 363.709778,499.928619 354.984344,502.755432
	C344.499634,506.152161 333.820770,505.231995 324.426086,498.273865
	C323.650940,497.699768 322.619873,497.471161 321.350830,497.045898
	C320.993896,497.009766 320.497589,497.001740 320.207458,496.646881
	C318.275696,495.198792 316.634064,494.105560 314.992401,493.012329
	C302.946259,485.729279 292.213898,476.980804 285.161011,464.560974
	C282.320831,459.559570 280.370758,454.048706 278.069946,448.746124
	C276.050018,444.090881 274.106049,439.402710 272.104340,434.357605
	C272.731598,433.895721 273.591949,434.041504 274.002350,433.678558
	C278.432800,429.760010 278.738861,429.631653 280.680389,435.566895
	C282.255005,440.380524 282.444153,445.659607 284.123932,450.423279
	C286.005188,455.758148 288.872833,460.745178 291.655029,465.941772
	C298.292603,473.174286 303.683167,481.495850 311.055573,487.300140
	C330.427887,502.551941 351.497314,500.427734 372.087524,489.753540
	C380.056091,485.622559 386.996429,479.508179 395.041656,474.110260
	C396.451660,472.948792 397.225220,471.974396 397.998779,471.000000
	C398.247864,470.195801 398.496918,469.391602 399.215393,468.255981
	C401.456116,465.949707 403.227448,463.974854 404.998779,462.000000
	C405.330902,461.224426 405.663025,460.448883 405.998169,459.336670
	C407.536041,457.930725 409.148468,456.954956 410.583008,455.764771
	C411.920197,454.655396 413.068207,453.317993 414.649109,452.039856
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M65.000305,315.000000
	C65.315826,315.111603 65.573677,315.301514 65.886932,315.784851
	C68.781868,323.080322 75.607674,325.332031 81.323776,328.980988
	C95.569641,338.075073 111.011978,334.422302 126.205650,333.399384
	C130.479309,333.111633 134.723328,332.408936 138.992249,332.020905
	C150.320511,330.991302 161.687637,328.995575 172.977097,329.352478
	C182.864944,329.665009 192.668289,332.650421 202.755066,334.729095
	C197.688873,334.841919 192.373505,334.482117 187.064911,334.563721
	C177.883621,334.704773 168.706543,335.119629 159.266205,335.695984
	C158.256561,335.976288 157.508408,335.980194 156.327942,335.655609
	C154.928757,335.547729 153.961899,335.768372 152.995041,335.989014
	C148.936600,336.011505 144.872040,335.898621 140.820847,336.081512
	C128.033234,336.658936 115.255295,337.507172 102.462814,337.880157
	C99.265297,337.973358 96.027191,336.674438 92.395294,336.027466
	C86.401474,333.599060 80.598587,331.560638 75.285400,328.633331
	C64.629372,322.762451 57.815865,312.759399 49.948673,303.919647
	C47.651173,301.338135 45.371361,298.740906 42.791534,296.047363
	C42.422325,295.202087 42.345028,294.460327 42.734596,293.355530
	C47.235401,292.578125 49.650654,294.797882 51.405468,298.060425
	C52.785923,300.626923 53.751831,303.533447 55.587563,305.710876
	C58.417000,309.067047 61.833073,311.928619 65.000305,315.000000
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M99.256332,331.271851
	C99.992638,329.742310 100.985336,328.483398 101.978027,327.224487
	C102.651329,328.555969 103.324623,329.887482 103.998657,331.608887
	C102.503838,331.846710 101.008278,331.694611 99.256332,331.271851
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M210.938568,336.659027
	C209.825836,336.841492 208.651550,336.686676 207.238373,336.267578
	C208.291977,336.109497 209.584503,336.215637 210.938568,336.659027
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M216.963715,338.713013
	C216.445709,338.827789 215.895432,338.654358 215.172699,338.241974
	C215.643997,338.143646 216.287720,338.284241 216.963715,338.713013
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M290.129333,171.301361
	C294.604858,169.348129 299.100372,166.822708 303.842651,166.211334
	C317.898163,164.399292 332.042053,163.238693 346.168274,162.019058
	C359.992004,160.825531 373.585449,158.742218 386.263062,152.729980
	C387.645599,152.074356 388.704071,150.735458 390.263000,149.202759
	C399.418762,143.303345 405.023071,135.145645 410.356689,126.584244
	C414.378967,120.127800 419.194763,114.165726 423.657349,107.983597
	C422.457825,112.739433 421.258301,117.495277 419.998535,122.620178
	C419.568695,123.778389 419.199127,124.567535 418.919617,125.682289
	C416.960632,128.766891 414.911621,131.525894 412.936218,134.646713
	C412.262543,135.776672 411.515228,136.544815 410.885681,137.657944
	C410.661804,138.134247 410.320221,138.265564 409.993042,138.702148
	C409.676025,139.145355 409.344635,139.283340 409.012085,139.716339
	C407.921265,141.108047 406.831635,142.204727 405.867188,143.647461
	C405.628326,144.114700 405.264252,144.235886 404.947998,144.676483
	C404.633850,145.118912 404.271820,145.241928 403.954895,145.682770
	C403.649475,146.127945 403.298950,146.255280 402.976379,146.693481
	C402.652588,147.131943 402.300812,147.259537 401.976135,147.695160
	C401.650635,148.129944 401.298096,148.256699 400.973328,148.692276
	C400.641693,149.123734 400.282227,149.246353 399.958130,149.681213
	C399.634308,150.116333 399.275116,150.239182 398.954193,150.676529
	C398.006622,151.426682 397.020782,151.862350 396.021790,152.653244
	C395.669098,153.144714 395.329559,153.280960 394.997284,153.710968
	C394.640259,154.125198 394.275940,154.245682 393.951233,154.677246
	C393.383179,155.107117 392.775574,155.225891 392.087708,155.676941
	C391.660126,156.140015 391.312775,156.270782 390.982697,156.701080
	C387.595367,158.678436 384.190735,160.356277 380.162140,161.796539
	C374.708801,162.024353 369.884460,162.551880 365.049072,162.943161
	C352.087799,163.991959 339.118988,164.947845 326.159424,166.016815
	C322.216492,166.342056 318.209320,166.569641 314.393921,167.504486
	C312.105865,168.065109 310.131989,169.907913 308.016907,171.174454
	C306.001190,171.483490 303.985504,171.792526 301.232452,172.064789
	C299.675323,172.313889 298.855530,172.599731 298.035736,172.885590
	C295.959229,173.301208 293.882690,173.716827 291.409760,174.122467
	C290.762939,173.276306 290.512543,172.440140 290.129333,171.301361
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M308.373474,498.916870
	C307.871307,498.661469 307.726074,498.343353 307.299286,498.004456
	C305.921844,496.929596 304.825958,495.875458 303.373413,494.900085
	C302.874603,494.654419 302.732422,494.329987 302.303833,493.993958
	C301.872711,493.660431 301.728058,493.338470 301.300262,492.999573
	C300.869507,492.668793 300.721832,492.354980 300.297363,492.012573
	C300.016876,491.577484 300.013275,491.171021 299.966003,490.459717
	C302.470795,490.573608 305.599182,490.198944 307.459747,491.558807
	C313.206177,495.759064 318.469147,500.620758 323.976929,505.557465
	C320.279175,504.545288 316.524414,503.201233 312.393707,501.918701
	C310.921967,500.938202 309.826202,499.896149 308.373474,498.916870
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M315.048401,493.345062
	C316.634064,494.105560 318.275696,495.198792 319.959290,496.644775
	C318.368958,495.890961 316.736664,494.784363 315.048401,493.345062
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M423.749695,107.599045
	C419.194763,114.165726 414.378967,120.127800 410.356689,126.584244
	C405.023071,135.145645 399.418762,143.303345 390.307800,148.847290
	C387.173340,149.985565 384.389069,151.141235 381.499512,151.891510
	C379.543304,152.399475 377.438995,152.337341 375.251556,151.937408
	C375.734772,150.229156 376.367371,149.113678 377.000000,147.998215
	C385.294556,146.067352 391.148224,140.415192 396.607880,134.510910
	C404.104614,126.403656 407.834930,116.220589 410.448883,105.728310
	C411.390991,101.946632 411.274597,97.761192 410.928070,93.822762
	C409.429535,76.790741 406.666931,60.214371 393.225403,47.680031
	C382.852631,38.007313 371.401215,30.386013 357.234528,27.602448
	C348.284485,25.843889 339.911560,27.803457 331.573975,31.173618
	C323.454559,34.455601 315.046173,37.022808 306.382751,39.951027
	C307.172089,39.018570 308.238556,37.857735 309.540771,37.104500
	C311.222595,36.131687 313.115204,35.531288 314.877472,34.687851
	C318.797150,32.811832 322.710022,30.919773 326.588654,28.960928
	C328.192505,28.150927 329.681549,27.116806 331.258362,26.249172
	C333.339020,25.104246 335.529541,24.141941 337.533936,22.880367
	C339.507172,21.638372 341.306915,20.120659 343.910858,18.181812
	C340.856140,16.143076 338.728516,14.723071 336.294617,13.149158
	C336.002075,12.060996 336.015778,11.126740 336.042877,9.845587
	C343.055054,9.363871 350.053864,9.229053 357.514923,9.166532
	C357.840546,10.048338 357.976257,11.083686 357.522827,11.630539
	C354.674713,15.065507 356.920074,16.344566 359.727234,17.934956
	C368.387054,22.841156 376.837158,28.119898 385.528442,32.967529
	C397.289703,39.527439 405.135986,49.571587 411.556946,61.041569
	C414.044769,65.485664 416.717041,69.839600 419.564545,74.059700
	C420.244598,75.067551 421.962555,75.375046 423.577026,76.007843
	C424.002289,77.076103 424.053680,78.141174 423.800598,79.798767
	C423.699707,81.268326 423.903320,82.145355 424.106934,83.022392
	C424.162933,90.049019 424.218964,97.075645 424.314423,104.506165
	C424.183258,105.678207 424.012665,106.446350 423.749695,107.599045
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M378.651459,15.015718
	C379.139465,15.319784 379.300232,15.613915 379.716797,15.964165
	C382.398254,18.707792 384.823853,21.395300 387.613281,24.053930
	C388.878143,25.195099 389.779083,26.365145 390.833984,27.832758
	C387.576508,27.107544 384.165070,26.084761 380.386841,25.031261
	C380.020050,25.000544 380.003754,24.991982 379.865723,24.696499
	C378.827454,23.925827 377.927216,23.450638 377.027008,22.975452
	C376.579773,22.646742 376.132507,22.318029 375.335938,21.611774
	C373.678101,17.798134 367.834625,16.085531 371.067993,10.923294
	C372.461823,11.313332 373.855621,11.703368 375.614685,12.060087
	C376.140106,12.321882 376.300323,12.616994 376.717712,12.966589
	C377.424652,13.682642 377.874420,14.344213 378.651459,15.015718
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M370.678802,10.922604
	C367.834625,16.085531 373.678101,17.798134 375.050598,21.572533
	C369.781586,20.259777 366.107300,16.809698 363.991211,11.280855
	C366.085968,10.907166 368.187805,10.914540 370.678802,10.922604
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M377.072021,23.320271
	C377.927216,23.450638 378.827454,23.925827 379.860535,24.690937
	C379.034637,24.542269 378.075806,24.103682 377.072021,23.320271
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M153.372116,335.993103
	C153.961899,335.768372 154.928757,335.547729 155.953979,335.654388
	C155.257965,335.986847 154.503586,335.992004 153.372116,335.993103
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M387.850464,368.688721
	C385.099823,367.881531 381.981171,367.139648 379.337860,365.588043
	C370.602692,360.460693 360.741669,360.412598 351.201111,359.092041
	C345.179199,358.258545 339.022980,358.438629 332.953918,357.874512
	C331.304993,357.721222 329.753815,356.516754 328.540955,355.364563
	C339.601013,355.104706 350.277863,355.275116 361.025513,355.689850
	C361.731079,355.953369 362.365906,355.972565 363.334351,356.001343
	C364.100342,356.112854 364.532684,356.214813 365.023621,356.573486
	C365.723938,356.882263 366.365631,356.934357 367.393951,356.995819
	C369.771362,357.433319 371.762177,357.861420 373.969269,358.551697
	C376.456848,359.881989 378.728088,360.950165 380.999359,362.018341
	C383.232971,364.139954 385.466583,366.261536 387.850464,368.688721
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M423.842834,415.586609
	C422.404633,415.185333 420.498199,414.684692 419.744354,413.469208
	C413.741730,403.790924 408.252319,393.781982 401.971802,384.293976
	C399.192383,380.095184 394.941711,376.871918 391.370300,373.194763
	C390.253632,372.045044 389.173950,370.859406 388.041687,369.343872
	C393.138306,373.335693 398.914703,377.119995 403.240875,382.151276
	C408.721100,388.524811 412.973907,395.946381 417.859070,402.843018
	C419.507446,405.170197 421.469971,407.274902 423.531311,409.693665
	C423.818665,411.656006 423.863678,413.406006 423.842834,415.586609
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M381.363464,362.008850
	C378.728088,360.950165 376.456848,359.881989 374.307678,358.427734
	C374.956940,357.357422 375.484039,356.673187 376.011169,355.988983
	C377.424377,356.370636 378.837555,356.752258 380.617676,357.079956
	C381.441498,357.392517 381.898376,357.759003 382.668610,358.075134
	C383.141876,358.320984 383.301819,358.617126 383.722107,358.967407
	C384.441559,359.374054 384.900696,359.726562 385.670319,360.052673
	C386.240692,360.565704 386.500549,361.105133 386.865479,361.929749
	C385.222870,362.143097 383.475220,362.071259 381.363464,362.008850
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M375.802002,355.709778
	C375.484039,356.673187 374.956940,357.357422 374.091370,358.165588
	C371.762177,357.861420 369.771362,357.433319 367.204163,356.775818
	C366.073486,356.469879 365.519226,356.393341 364.964996,356.316772
	C364.532684,356.214813 364.100342,356.112854 363.143158,355.782928
	C362.063782,355.518463 361.509247,355.481964 360.954712,355.445496
	C350.277863,355.275116 339.601013,355.104706 328.458252,354.999451
	C325.975189,354.978790 323.958008,354.893005 321.402222,354.446198
	C319.563721,354.091492 318.263916,354.097778 316.964081,354.104065
	C315.294952,354.014618 313.625793,353.925171 311.507690,353.443848
	C311.356995,351.723236 311.655243,350.394531 311.953522,349.065857
	C326.247345,349.049255 340.586761,348.351898 354.815582,349.305389
	C361.846588,349.776550 368.673553,353.292389 375.802002,355.709778
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M311.584381,348.901184
	C311.655243,350.394531 311.356995,351.723236 311.094543,353.386658
	C302.134491,352.654602 293.270477,351.207397 285.913452,344.732086
	C294.257996,345.870850 302.736603,347.303711 311.584381,348.901184
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M316.973206,354.336700
	C318.263916,354.097778 319.563721,354.091492 320.975586,354.381592
	C319.998016,354.785706 318.908447,354.893494 317.407532,355.018005
	C316.996246,355.034760 316.982330,354.569336 316.973206,354.336700
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M395.060944,31.306618
	C395.307312,31.108139 395.588440,31.262032 395.884827,31.785618
	C395.646851,31.950001 395.378723,31.784559 395.060944,31.306618
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M87.051628,185.620102
	C86.536995,185.983109 86.101685,185.992096 85.339905,186.008057
	C82.915321,186.330887 80.817200,186.646759 78.312988,186.923996
	C84.108704,184.087280 90.310516,181.289215 96.790512,178.265717
	C109.656349,175.278000 122.320152,176.025986 135.437637,177.307861
	C137.899109,179.658371 139.916504,181.918365 142.027008,184.495438
	C140.055481,184.860504 137.990875,184.908493 135.489990,184.946564
	C128.965118,180.177170 122.171265,177.394836 114.332161,178.009827
	C104.836052,178.754807 95.564461,180.474869 87.051628,185.620102
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M186.504883,181.034012
	C185.671738,181.683990 184.396454,182.840317 183.080322,182.888870
	C177.318420,183.101395 171.542938,182.918381 165.776367,183.051270
	C164.261215,183.086182 162.760300,183.738663 161.150360,184.484360
	C157.330460,184.867279 153.613037,184.873367 149.437256,184.800491
	C150.295731,183.113708 151.612549,181.505905 152.919495,179.542786
	C163.954407,179.801804 174.999161,180.416153 186.504883,181.034012
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M202.576691,180.012878
	C200.911942,181.005219 198.838135,181.986862 196.369293,182.942535
	C196.638962,178.970520 199.769974,180.265579 202.576691,180.012878
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M60.697739,214.153076
	C57.868538,220.293945 55.035282,226.760330 51.496330,232.814178
	C49.327553,236.524170 46.076862,239.601685 42.997593,242.998871
	C42.809479,238.644943 42.935802,234.255386 43.125332,229.435211
	C51.002789,228.987701 52.041496,228.069427 55.294846,220.812378
	C56.389130,218.371384 58.661835,216.458679 60.697739,214.153076
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M65.001358,210.665955
	C64.532242,211.316254 64.062454,211.633713 63.296799,211.974976
	C63.476410,211.096268 63.951889,210.193771 64.713379,209.146545
	C64.999817,209.445587 65.000244,209.889359 65.001358,210.665955
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M308.478638,171.203369
	C310.131989,169.907913 312.105865,168.065109 314.393921,167.504486
	C318.209320,166.569641 322.216492,166.342056 326.159424,166.016815
	C339.118988,164.947845 352.087799,163.991959 365.049072,162.943161
	C369.884460,162.551880 374.708801,162.024353 379.818146,161.882187
	C377.636322,163.425735 375.174530,164.646057 372.293823,165.583298
	C366.012451,165.896286 360.142181,166.431625 354.300293,167.186905
	C353.745056,167.258713 353.367889,168.707748 352.908051,169.517395
	C344.262634,169.710999 335.617218,169.904602 326.276306,169.817932
	C324.035461,169.903748 322.490112,170.269867 320.944763,170.635971
	C316.943298,170.834747 312.941833,171.033524 308.478638,171.203369
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M353.310242,169.609161
	C353.367889,168.707748 353.745056,167.258713 354.300293,167.186905
	C360.142181,166.431625 366.012451,165.896286 371.944580,165.635223
	C365.913635,167.213776 359.813049,168.457352 353.310242,169.609161
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M321.321350,170.723236
	C322.490112,170.269867 324.035461,169.903748 325.809998,169.811768
	C324.592102,170.327423 323.145020,170.568970 321.321350,170.723236
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M298.372345,172.923691
	C298.855530,172.599731 299.675323,172.313889 300.844055,172.059448
	C300.364960,172.381180 299.536957,172.671494 298.372345,172.923691
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M314.324646,28.941956
	C312.525238,29.061131 310.860901,28.730198 309.617950,29.307135
	C304.810547,31.538622 300.137451,34.059547 295.212433,36.249390
	C295.744324,34.902637 296.478149,33.779156 297.106812,32.328514
	C297.365448,31.879892 297.729248,31.758429 298.049500,31.321453
	C298.370270,30.885572 298.734619,30.765202 299.052551,30.326283
	C300.074432,29.742170 301.142670,29.476606 302.527039,29.064245
	C308.198334,26.748276 313.854279,25.093014 318.788940,22.205250
	C321.482483,20.629004 325.397461,18.620808 324.466980,13.793241
	C324.327942,13.071836 326.278656,11.947658 327.622070,10.994417
	C328.533447,12.322673 329.085358,13.666321 329.937134,15.740048
	C326.120544,18.827909 322.120239,22.064407 318.056335,25.639771
	C316.879608,26.955383 315.766449,27.932135 314.324646,28.941956
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M64.960754,314.651794
	C61.833073,311.928619 58.417000,309.067047 55.587563,305.710876
	C53.751831,303.533447 52.785923,300.626923 51.405468,298.060425
	C49.650654,294.797882 47.235401,292.578125 42.832718,292.992310
	C42.373734,291.596619 42.283489,290.201141 42.296902,288.395782
	C43.934258,286.326965 45.739384,284.838654 46.921268,282.958557
	C47.989368,281.259460 48.376411,279.132233 49.063793,277.193787
	C49.678780,277.075165 50.293770,276.956573 50.908760,276.837952
	C52.270012,280.465546 53.709789,284.066681 54.948067,287.735779
	C55.357407,288.948700 55.396770,290.342255 55.350449,291.644196
	C55.028454,300.694305 60.091198,307.445007 64.960754,314.651794
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M201.920288,271.741699
	C201.719955,271.776489 201.465378,271.553162 201.130768,271.083801
	C201.322510,271.053040 201.594284,271.268311 201.920288,271.741699
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M424.175568,82.645302
	C423.903320,82.145355 423.699707,81.268326 423.739014,80.195274
	C424.069366,80.755569 424.156799,81.511887 424.175568,82.645302
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M77.598564,186.892532
	C77.353256,187.360001 76.825012,187.853470 76.128418,188.103851
	C76.411919,187.546677 76.863777,187.232590 77.598564,186.892532
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M376.794037,147.726776
	C376.367371,149.113678 375.734772,150.229156 375.051086,151.671417
	C372.629486,152.618454 370.290985,153.442032 367.882507,153.821030
	C360.565247,154.972443 353.235016,156.065430 345.884277,156.974869
	C330.685730,158.855225 315.470856,160.603714 300.262177,162.402130
	C298.464874,162.614670 296.664429,162.800232 294.432098,162.997009
	C295.769806,161.366989 297.368744,158.629410 299.344971,158.322678
	C304.729828,157.486877 307.851685,155.378891 308.200348,149.583298
	C308.290619,148.083649 310.003448,146.681625 310.970978,145.234741
	C312.064301,146.293350 313.123779,147.390305 314.263519,148.396347
	C314.957306,149.008774 315.774811,149.481049 317.952637,151.010895
	C317.714935,147.472061 318.163422,144.940079 317.299530,142.989319
	C314.547882,136.775955 313.006012,126.366959 313.810333,119.653336
	C314.518188,113.744812 314.129486,107.684700 315.079773,101.830269
	C316.456635,93.348045 319.173920,85.178123 324.162048,78.041039
	C324.792389,77.139114 327.712646,76.575897 328.393494,77.181236
	C332.072449,80.452103 333.667145,78.467430 334.813812,75.050758
	C337.015717,68.489716 336.946991,68.530724 343.481842,71.337547
	C344.261932,71.672607 345.549561,71.151184 346.460632,70.724800
	C346.947540,70.496918 347.161072,69.637634 347.449432,69.039871
	C351.641113,60.351460 351.639191,60.350525 358.580933,67.366089
	C359.748657,65.220543 360.779297,63.327141 361.809662,61.433590
	C363.371307,58.563828 364.920227,58.088203 366.688171,61.323399
	C368.095398,63.898548 369.675201,66.379395 372.505615,68.833527
	C373.023438,67.094818 373.612915,65.373680 374.044373,63.613792
	C374.991425,59.750751 376.713226,59.038372 379.302521,61.947704
	C381.347504,64.245399 382.929901,67.030952 384.351715,69.788582
	C386.318176,73.602684 388.054871,77.548065 389.659180,81.530220
	C390.379333,83.317833 390.634949,85.316414 390.927765,87.245621
	C391.212006,89.118500 391.420776,91.028473 391.377747,92.915657
	C391.352325,94.029686 390.960449,95.236046 390.408691,96.218544
	C388.747925,99.175781 386.283325,101.840836 385.294128,104.985352
	C382.607330,113.526115 382.351532,113.791870 375.727905,108.252090
	C375.262665,109.359169 374.689087,110.332375 374.440460,111.382530
	C373.288452,116.247887 372.472137,121.210503 370.999695,125.972580
	C370.483154,127.643082 368.434296,128.839767 367.085114,130.252823
	C366.549255,129.912231 366.013397,129.571640 365.477509,129.231049
	C364.419556,132.998291 363.037170,136.715607 362.446442,140.554749
	C362.169189,142.356430 363.256866,144.468582 364.103760,146.261597
	C364.282104,146.639206 366.844299,146.576965 367.051880,146.131744
	C367.806702,144.512650 368.122894,142.660736 368.421387,140.866150
	C368.662476,139.416595 368.639832,137.923172 368.815247,135.182648
	C371.931091,140.102310 374.259583,143.778809 376.794037,147.726776
M370.193878,77.436905
	C369.649689,76.604256 369.055298,75.799744 368.570007,74.934090
	C366.449097,71.150528 364.303619,67.688423 359.725098,72.448463
	C359.271515,72.919991 357.894104,73.164146 357.401154,72.833817
	C352.712128,69.691528 350.477112,73.431084 347.423706,75.857887
	C345.613342,77.296745 342.859009,77.563072 340.512787,78.304878
	C339.621582,78.586647 338.360321,78.413994 337.822296,78.977737
	C334.890320,82.049843 331.443634,84.908485 329.444794,88.540024
	C323.963013,98.499466 323.847900,109.183632 327.261627,119.846924
	C327.659027,121.088264 330.953369,122.778603 331.544678,122.356331
	C335.166168,119.770195 337.279205,121.688568 339.673584,124.119377
	C343.708160,128.215332 344.383240,127.875618 347.040283,122.900421
	C347.944733,121.206886 350.236115,119.285408 351.981415,119.196938
	C356.630096,118.961311 359.083405,116.723160 361.251465,112.877693
	C362.435974,110.776718 365.233734,109.608765 367.232178,107.934372
	C368.427002,106.933304 370.363770,105.829750 370.447327,104.663353
	C370.788971,99.895897 373.643494,96.902130 376.645721,93.696892
	C379.456207,90.696335 378.169342,86.683617 374.947296,84.277962
	C373.015839,82.835892 371.971954,80.205025 370.193878,77.436905
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M168.210480,185.548157
	C170.111832,185.032089 172.295456,184.763199 174.742920,184.776840
	C172.835419,185.304688 170.664093,185.550003 168.210480,185.548157
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M372.000000,449.000000
	C373.797424,448.368225 375.571320,447.654755 377.399200,447.128754
	C379.058380,446.651306 381.198975,445.576508 382.373016,446.195557
	C384.313049,447.218506 386.684387,448.509216 385.617706,451.990417
	C383.835052,457.808136 382.927826,463.893158 381.164459,469.718018
	C378.590637,478.220032 371.581818,482.947235 364.377502,486.763214
	C358.999725,489.611694 352.778442,490.868744 346.928955,492.822968
	C346.618835,492.926544 346.272034,492.917511 345.943939,492.970703
	C343.396423,493.383667 324.838409,489.629028 322.500702,488.312408
	C321.054718,487.497986 319.550659,486.756409 318.006104,486.152374
	C310.661407,483.280243 305.474091,475.533783 304.928955,467.694946
	C304.220337,457.505890 302.678131,447.369873 302.124542,437.176819
	C301.808655,431.360138 302.722595,425.473724 303.158051,419.624298
	C303.196960,419.101776 303.791962,418.620667 304.565186,417.471832
	C306.650818,423.749237 308.533936,429.417145 310.417084,435.085083
	C311.101562,434.984192 311.786072,434.883301 312.470581,434.782440
	C313.134460,432.358643 313.798370,429.934875 314.999481,427.508423
	C317.112885,430.857178 317.807343,435.560760 320.446655,437.281799
	C326.150574,441.001190 328.047729,447.072205 331.919922,451.842651
	C333.315125,453.561554 335.380188,454.926788 337.409760,455.874969
	C338.802551,456.525635 340.789001,455.812164 342.292206,456.334930
	C353.828827,460.347198 363.560822,457.368530 372.000000,449.000000
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M372.000671,448.610291
	C363.560822,457.368530 353.828827,460.347198 342.292206,456.334930
	C340.789001,455.812164 338.802551,456.525635 337.409760,455.874969
	C335.380188,454.926788 333.315125,453.561554 331.919922,451.842651
	C328.047729,447.072205 326.150574,441.001190 320.446655,437.281799
	C317.807343,435.560760 317.112885,430.857178 315.268372,427.252899
	C315.337982,422.877472 315.523926,418.733521 316.099548,414.644440
	C316.290924,413.284943 317.482422,412.066223 318.766571,409.822388
	C320.661560,416.903992 322.127960,422.383850 323.837585,428.772705
	C330.575073,420.364532 323.406952,411.045135 327.130493,403.335876
	C327.667084,403.226471 328.203674,403.117035 328.740265,403.007629
	C329.958466,405.922089 331.176666,408.836578 332.394836,411.751068
	C332.754120,411.748077 333.113403,411.745117 333.472687,411.742157
	C333.979218,409.938751 335.049652,408.082092 334.885406,406.341980
	C334.411835,401.324585 337.677917,400.682953 341.304138,399.994537
	C344.849609,399.321503 348.344910,398.387238 351.870819,397.606384
	C352.991058,397.358276 354.832489,396.700928 355.166962,397.135895
	C358.607147,401.609741 365.285645,401.945435 367.978302,407.305695
	C368.612518,408.568207 369.558838,410.671478 370.379456,410.680084
	C375.502472,410.733734 376.462708,414.803528 378.027588,418.154510
	C382.731354,428.226929 381.415619,435.197327 373.871033,443.298737
	C372.763336,444.488190 372.601074,446.558075 372.000671,448.610291
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M404.618713,462.007019
	C403.227448,463.974854 401.456116,465.949707 399.343018,467.962463
	C398.515320,464.052338 400.646210,462.393799 404.618713,462.007019
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M397.660339,471.038147
	C397.225220,471.974396 396.451660,472.948792 395.339661,473.960968
	C395.774780,473.024628 396.548370,472.050476 397.660339,471.038147
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M166.000000,249.000000
	C166.327515,251.070282 166.655029,253.140549 166.991272,255.605408
	C166.666611,257.560791 166.492203,259.177582 165.966263,260.670532
	C164.917282,263.647980 163.685715,266.561676 162.510483,269.494019
	C160.975769,273.323334 159.433868,277.149872 157.873062,280.968597
	C156.913620,283.315979 155.913071,285.646576 154.554962,288.879883
	C152.230957,286.393219 150.733505,284.790985 148.673187,282.586456
	C147.636826,284.549500 146.582306,285.969116 146.099426,287.562012
	C144.050980,294.319305 142.774307,295.037231 135.935318,294.020782
	C131.469467,293.357056 126.902374,293.393738 122.417999,292.823029
	C121.166031,292.663696 119.840111,291.659241 118.911934,290.692963
	C117.224998,288.936859 115.810707,286.918823 113.956413,284.602600
	C109.564400,291.182190 107.976585,286.332184 104.950546,283.390747
	C101.909645,280.434845 97.561462,278.823822 94.094475,276.803131
	C94.094475,270.830597 94.094475,264.797150 94.046928,258.381836
	C95.434349,254.877640 96.705040,251.658707 98.437637,248.711334
	C98.800621,248.093857 101.700348,247.964905 102.015015,248.478470
	C104.830368,253.073532 106.315384,250.459793 107.697090,247.571564
	C109.471016,243.863403 110.903404,240.988724 115.445435,245.352676
	C118.158554,247.959442 121.876015,245.619186 125.120377,240.220291
	C126.866814,239.999710 127.933403,239.999557 129.000000,239.999390
	C129.110382,240.119888 129.253326,240.362839 129.326462,240.343384
	C133.847961,239.140656 138.363770,237.916489 143.308563,236.352905
	C146.158035,237.007477 148.579025,238.003433 151.000000,238.999390
	C152.434586,240.971207 153.869156,242.943039 155.993011,244.897247
	C157.736588,243.621384 158.790848,242.363159 160.078781,240.826065
	C162.241837,243.812042 164.120926,246.406021 166.000000,249.000000
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M165.951279,248.614731
	C164.120926,246.406021 162.241837,243.812042 160.078781,240.826065
	C158.790848,242.363159 157.736588,243.621384 156.341156,244.939499
	C156.000000,239.168732 156.000000,233.338058 156.000000,227.507401
	C156.515228,227.283142 157.030441,227.058899 157.545670,226.834641
	C158.441483,228.003967 159.712906,229.043762 160.168503,230.364914
	C162.206879,236.275970 164.012741,242.267181 165.951279,248.614731
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M150.959381,238.640839
	C148.579025,238.003433 146.158035,237.007477 143.368530,236.005463
	C143.819351,234.725616 144.638702,233.451859 145.458038,232.178085
	C146.828979,233.169250 148.350861,234.012848 149.516281,235.204926
	C150.257477,235.963135 150.469467,237.238708 150.959381,238.640839
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M128.998596,239.613708
	C127.933403,239.999557 126.866814,239.999710 125.400261,239.999634
	C125.769547,238.733994 126.538788,237.468613 127.308029,236.203217
	C127.871071,237.211487 128.434128,238.219772 128.998596,239.613708
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M365.023621,356.573486
	C365.519226,356.393341 366.073486,356.469879 366.817505,356.766418
	C366.365631,356.934357 365.723938,356.882263 365.023621,356.573486
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M361.025513,355.689819
	C361.509247,355.481964 362.063782,355.518463 362.809509,355.773376
	C362.365906,355.972565 361.731079,355.953369 361.025513,355.689819
z"
        />
        <path
          fill="#8BDA41"
          opacity="1.000000"
          stroke="none"
          d="
M370.361511,77.772690
	C371.971954,80.205025 373.015839,82.835892 374.947296,84.277962
	C378.169342,86.683617 379.456207,90.696335 376.645721,93.696892
	C373.643494,96.902130 370.788971,99.895897 370.447327,104.663353
	C370.363770,105.829750 368.427002,106.933304 367.232178,107.934372
	C365.233734,109.608765 362.435974,110.776718 361.251465,112.877693
	C359.083405,116.723160 356.630096,118.961311 351.981415,119.196938
	C350.236115,119.285408 347.944733,121.206886 347.040283,122.900421
	C344.383240,127.875618 343.708160,128.215332 339.673584,124.119377
	C337.279205,121.688568 335.166168,119.770195 331.544678,122.356331
	C330.953369,122.778603 327.659027,121.088264 327.261627,119.846924
	C323.847900,109.183632 323.963013,98.499466 329.444794,88.540024
	C331.443634,84.908485 334.890320,82.049843 337.822296,78.977737
	C338.360321,78.413994 339.621582,78.586647 340.512787,78.304878
	C342.859009,77.563072 345.613342,77.296745 347.423706,75.857887
	C350.477112,73.431084 352.712128,69.691528 357.401154,72.833817
	C357.894104,73.164146 359.271515,72.919991 359.725098,72.448463
	C364.303619,67.688423 366.449097,71.150528 368.570007,74.934090
	C369.055298,75.799744 369.649689,76.604256 370.361511,77.772690
z"
        />
      </svg>
    </Typography>
  ),

  facebook: (
    <Typography
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="27"
        height="27"
        viewBox="0,0,256,256"
        style={{ fill: '#000' }}
      >
        <g
          fill="#3b5998"
          fillRule="nonzero"
          stroke="none"
          strokeWidth="1"
          strokeLinecap="butt"
          strokeLinejoin="miter"
          strokeMiterlimit="10"
          strokeDasharray=""
          strokeDashoffset="0"
          style={{ mixBlendMode: 'normal' }}
        >
          <g transform="scale(8,8)">
            <path d="M19.25391,2c-3.942,0 -6.25391,2.08217 -6.25391,6.82617v4.17383h-5v5h5v12h5v-12h4l1,-5h-5v-3.32812c0,-1.787 0.58277,-2.67187 2.25977,-2.67187h2.74023v-4.79492c-0.474,-0.064 -1.85509,-0.20508 -3.74609,-0.20508z"></path>
          </g>
        </g>
      </svg>
    </Typography>
  ),
  twitter: //<TwitterIcon sx={{ color: '#00acee', height: 27, width: 27 }} />,
    //<img src="/assets/images/sMedia/twConnect.png" />,
    /*<path d="M11.9027 8.46429L19.3482 0H17.5838L11.119 7.34942L5.95547 0H0L7.8082 11.1136L0 19.9897H1.76443L8.59152 12.2285L14.0445 19.9897H20L11.9023 8.46429H11.9027ZM9.48608 11.2115L8.69495 10.1049L2.40018 1.29901H5.11025L10.1902 8.40562L10.9813 9.51229L17.5847 18.7498H14.8746L9.48608 11.212V11.2115Z" fill="#0F1419"/>*/
    (<Typography
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center', height: '27px', width: '27px'
      }}
    >
      <svg width="19" height="19" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.4256 0H20.8185L13.4062 8.4718L22.1262 20H15.2985L9.95077 13.0082L3.83179 20H0.436923L8.36513 10.9385L0 0H7.00103L11.8349 6.39077L17.4256 0ZM16.2349 17.9692H18.1149L5.97949 1.9241H3.96205L16.2349 17.9692Z" fill="#0F1419" />
      </svg>
    </Typography>),

  instagram: <InstagramIcon sx={{ color: '#8a3ab9', height: 26, width: 26 }} />,
  instagramp: (
    <InstagramIcon sx={{ color: '#8a3ab9', height: 26, width: 26 }} />
  ),
  instagramb: (
    <InstagramIcon sx={{ color: '#8a3ab9', height: 26, width: 26 }} />
  ),
  linkedIn: (
    <Typography
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',//g fillOld-'#5b5b5b'
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="27"
        height="27"
        viewBox="0,0,256,256"
        style={{ fill: '#000' }}
      >
        <g
          fill="#0a66c2"
          fillRule="nonzero"
          stroke="none"
          strokeWidth="1"
          strokeLinecap="butt"
          strokeLinejoin="miter"
          strokeMiterlimit="10"
          strokeDasharray=""
          strokeDashoffset="0"
          style={{ mixBlendMode: 'normal' }}
        >
          <g transform="scale(8.53333,8.53333)">
            <path d="M9,25h-5v-15h5zM6.501,8c-1.383,0 -2.501,-1.121 -2.501,-2.501c0,-1.38 1.12,-2.499 2.501,-2.499c1.378,0 2.499,1.121 2.499,2.499c0,1.38 -1.121,2.501 -2.499,2.501zM27,25h-4.807v-7.3c0,-1.741 -0.033,-3.98 -2.499,-3.98c-2.503,0 -2.888,1.896 -2.888,3.854v7.426h-4.806v-15.011h4.614v2.051h0.065c0.642,-1.18 2.211,-2.424 4.551,-2.424c4.87,0 5.77,3.109 5.77,7.151c0,0 0,8.233 0,8.233z"></path>
          </g>
        </g>
      </svg>
    </Typography>
  ),
  youtube:
    <YouTubeIcon sx={{ color: '#FA3B00', height: 30, width: 30 }} viewBox='0 0 24 22' />,
  youTube:
    <YouTubeIcon sx={{ color: '#FA3B00', height: 30, width: 30 }} viewBox='0 0 24 22' />,
  blog: (
    <BlogIconContainer background="#FF6F00">
      <img
        src="/assets/images/blog.svg"
        alt=""
        style={{ height: 25, width: 25 }}
      />
    </BlogIconContainer>
  ),

  facebook_xs: (
    <Typography
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="15"
        height="15"
        viewBox="0,0,256,256"
        style={{ fill: '#000' }}
      >
        <g
          fill="#3b5998"
          fillRule="nonzero"
          stroke="none"
          strokeWidth="1"
          strokeLinecap="butt"
          strokeLinejoin="miter"
          strokeMiterlimit="10"
          strokeDasharray=""
          strokeDashoffset="0"
          style={{ mixBlendMode: 'normal' }}
        >
          <g transform="scale(8,8)">
            <path d="M19.25391,2c-3.942,0 -6.25391,2.08217 -6.25391,6.82617v4.17383h-5v5h5v12h5v-12h4l1,-5h-5v-3.32812c0,-1.787 0.58277,-2.67187 2.25977,-2.67187h2.74023v-4.79492c-0.474,-0.064 -1.85509,-0.20508 -3.74609,-0.20508z"></path>
          </g>
        </g>
      </svg>
    </Typography>
  ),
  twitter_xs: <TwitterIcon sx={{ color: '#00acee', height: 15, width: 15 }} />,
  instagram_xs: (
    <InstagramIcon sx={{ color: '#8a3ab9', height: 15, width: 15 }} />
  ),
  linkedIn_xs: (
    <Typography
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="15"
        height="15"
        viewBox="0,0,256,256"
        style={{ fill: '#000' }}
      >
        <g
          fill="#5b5b5b"
          fillRule="nonzero"
          stroke="none"
          strokeWidth="1"
          strokeLinecap="butt"
          strokeLinejoin="miter"
          strokeMiterlimit="10"
          strokeDasharray=""
          strokeDashoffset="0"
          style={{ mixBlendMode: 'normal' }}
        >
          <g transform="scale(8.53333,8.53333)">
            <path d="M9,25h-5v-15h5zM6.501,8c-1.383,0 -2.501,-1.121 -2.501,-2.501c0,-1.38 1.12,-2.499 2.501,-2.499c1.378,0 2.499,1.121 2.499,2.499c0,1.38 -1.121,2.501 -2.499,2.501zM27,25h-4.807v-7.3c0,-1.741 -0.033,-3.98 -2.499,-3.98c-2.503,0 -2.888,1.896 -2.888,3.854v7.426h-4.806v-15.011h4.614v2.051h0.065c0.642,-1.18 2.211,-2.424 4.551,-2.424c4.87,0 5.77,3.109 5.77,7.151c0,0 0,8.233 0,8.233z"></path>
          </g>
        </g>
      </svg>
    </Typography>
  ),
  youtube_xs: <YouTubeIcon sx={{ color: '#FA3B00', height: 15, width: 15 }} />,

  Whatsapp: (
    <StyledIcon background="#1BD741" color="#fff">
      <WhatsAppIcon sx={{ height: 25, width: 25 }} />
    </StyledIcon>
  ),
  Facebook: (
    <StyledIcon background="#0694C7" color="#fff">
      f
    </StyledIcon>
  ),
  DisabledFacebook: (
    <StyledIcon background="#D3D3D3" color="#fff">
      f
    </StyledIcon>
  ),
  Twitter: (//<StyledIcon background="#44CAE9" color="#fff"><TwitterIcon sx={{ height: 25, width: 25 }} />
    /*<path d="M11.9027 8.46429L19.3482 0H17.5838L11.119 7.34942L5.95547 0H0L7.8082 11.1136L0 19.9897H1.76443L8.59152 12.2285L14.0445 19.9897H20L11.9023 8.46429H11.9027ZM9.48608 11.2115L8.69495 10.1049L2.40018 1.29901H5.11025L10.1902 8.40562L10.9813 9.51229L17.5847 18.7498H14.8746L9.48608 11.212V11.2115Z" fill="white"/>*/
    <StyledIcon background="#0f1419" color="#fff">
      <svg width="19" height="19" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.4256 0H20.8185L13.4062 8.4718L22.1262 20H15.2985L9.95077 13.0082L3.83179 20H0.436923L8.36513 10.9385L0 0H7.00103L11.8349 6.39077L17.4256 0ZM16.2349 17.9692H18.1149L5.97949 1.9241H3.96205L16.2349 17.9692Z" fill="white" />
      </svg>
    </StyledIcon>
  ),
  DisabledTwitter: (
    /*<path d="M11.9027 8.46429L19.3482 0H17.5838L11.119 7.34942L5.95547 0H0L7.8082 11.1136L0 19.9897H1.76443L8.59152 12.2285L14.0445 19.9897H20L11.9023 8.46429H11.9027ZM9.48608 11.2115L8.69495 10.1049L2.40018 1.29901H5.11025L10.1902 8.40562L10.9813 9.51229L17.5847 18.7498H14.8746L9.48608 11.212V11.2115Z" fill="white"/>*/
    <StyledIcon background="#D3D3D3" color="#fff">
      <svg width="19" height="19" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.4256 0H20.8185L13.4062 8.4718L22.1262 20H15.2985L9.95077 13.0082L3.83179 20H0.436923L8.36513 10.9385L0 0H7.00103L11.8349 6.39077L17.4256 0ZM16.2349 17.9692H18.1149L5.97949 1.9241H3.96205L16.2349 17.9692Z" fill="white" />
      </svg>
    </StyledIcon>
  ),
  Instagram: (
    <StyledIcon background="#bc2a8d" color="#fff">
      <InstagramIcon sx={{ height: 25, width: 25 }} />
    </StyledIcon>
  ),
  Instagramp: (
    <StyledIcon background="#bc2a8d" color="#fff">
      <InstagramIcon sx={{ height: 25, width: 25 }} />
    </StyledIcon>
  ),
  Instagramb: (
    <StyledIcon background="#bc2a8d" color="#fff">
      <InstagramIcon sx={{ height: 25, width: 25 }} />
    </StyledIcon>
  ),
  DisabledInstagram: (
    <StyledIcon background="#D3D3D3" color="#fff">
      <InstagramIcon sx={{ height: 25, width: 25 }} />
    </StyledIcon>
  ),
  DisabledInstagramp: (
    <StyledIcon background="#D3D3D3" color="#fff">
      <InstagramIcon sx={{ height: 25, width: 25 }} />
    </StyledIcon>
  ),
  DisabledLinkedin: (
    <StyledIcon background="#D3D3D3" color="#fff">
      in
    </StyledIcon>
  ),
  DisabledLinkedIn: (
    <StyledIcon background="#D3D3D3" color="#fff">
      in
    </StyledIcon>
  ),
  Linkedin: (
    <StyledIcon background="#0a66c2" color="#fff">
      in
    </StyledIcon>
  ),
  LinkedIn: (
    <StyledIcon background="#0a66c2" color="#fff">
      in
    </StyledIcon>
  ),
  Mail: (
    <StyledIcon background="#9A9192" color="#fff">
      <MailOutlineIcon sx={{ height: 25, width: 25 }} />
    </StyledIcon>
  ),
  Blog: (
    <StyledIcon background="#FF6F00">
      <img
        src="/assets/images/blog.svg"
        alt=""
        style={{ height: 25, width: 25 }}
      />
    </StyledIcon>
  ),
};

/*export const socialMediaColors = {
  facebook: '#3b5998',
  twitter: '#00acee',
  instagram: '#bc2a8d',
  linkedIn: '#0e76a8',//0a66c2
};*/

export const getSMediaIcons = (sK, type) => {
  let resp = null;
  if (sK) {
    const sMMeta = getInitSMediaAttr(null);
    const refSM = sMMeta[sK];
    // console.log("type", sMMeta, refSM, sK)
    switch (type) {
      case 'xs':
        resp = socialMedia[refSM.title + '_xs'];
        break;
      case 'initCap':
        resp = socialMedia[refSM.title];
        break;
      case 'lCase':
      default:
        resp = socialMedia[refSM.dispTitle];
        break;
    }
  }
  return resp;
};
