import { Box, Grid, styled, useTheme } from "@mui/material";
import { PageContainer } from "core";
import { useSelector } from "react-redux";
import {
  BadgesHolder,
  BadgesList,
  Leaderboard,
  ReferAndEarnCard,
} from "sections";
import ProfileInfoCard from "sections/home/ProfileInfoCard";
import SMediaWidget from "sections/widgets/sMediaWidget/SMediaWidget";
import TopPost from "sections/home/TopPost";
import { selectAuthUserId } from "services/auth/authSlice";
import { useUserDetailsByIdQuery } from "services/userApi";

const StyledGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));
const StyledLeftGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

export default function Badges({ title }) {
  const theme = useTheme();
  const userAuthId = useSelector(selectAuthUserId);

  const { data: userData } = useUserDetailsByIdQuery({ userId: userAuthId });

  return (
    <PageContainer title={title}>
      <Grid container spacing={2}>
        <StyledLeftGrid item sm={12} md={4} lg={3}>
          <Box className="user_name">
            <BadgesList
              theme={theme}
              title={`Hello ${userData?.users[0].first_name}! Here's your hall of frame`}
            />
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ProfileInfoCard
                theme={theme}
                name={`${userData?.users[0].first_name} ${userData?.users[0].last_name}`}
                profile={userData?.users[0]?.medias?.profile?.url}
              />
            </Grid>
            <Grid item xs={12} className="social_media" >
              <SMediaWidget theme={theme} network_size="1052" />
            </Grid>
            <Grid item xs={12}>
              <Leaderboard theme={theme} />
            </Grid>
            <Grid item xs={12}>
              <TopPost theme={theme} />
            </Grid>
            <Grid item xs={12}>
              <ReferAndEarnCard theme={theme} />
            </Grid>
          </Grid>
        </StyledLeftGrid>

        {/* CENTER GRID */}
        <Grid item sm={12} md={7.5} lg={6.3} className="badge_list">
          <Grid container spacing={2} textAlign="center">
            <Grid item xs={12}>
              <BadgesList
                theme={theme}
                title={`Hello ${userData?.users[0].first_name}! Here's your hall of frame`}
              />
            </Grid>
          </Grid>
        </Grid>

        <StyledGrid item sm={12} md={4} lg={2.7}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <BadgesHolder theme={theme} />
            </Grid>
          </Grid>
        </StyledGrid>
      </Grid>
    </PageContainer>
  );
}
