import SignInForm from "./auth/SignInForm";
import SignUpForm from "./auth/SignUpForm";
import ResetPasswordForm from "./auth/ResetPasswordForm";
import ForgotPasswordForm from "./auth/ForgotPasswordForm";
import FeedMenuList from "./home/feeds/FeedMenuList";
import FeedList from "./home/feeds/FeedList";
import Leaderboard from "./home/Leaderboard";
import AnalyticsCard from "./home/AnalyticsCard";
import SubmitIdeaCard from "./home/SubmitIdeaCard";
import WhoToFollowCard from "./home/WhoToFollowCard";
import ReferAndEarnCard from "./home/ReferAndEarnCard";
import BadgeCard from "./home/BadgeCard";
import FeedCard from "./home/feeds/FeedCard";
import SchedulePostForm from "./home/feeds/SchedulePostForm";
import CongratsPostForm from "./home/feeds/CongratsPostForm";
import PointsBreakupCard from "./home/feeds/PointsBreakupCard";
import FollowOurClients from "./home/FollowOurClients";
import GetCareBanner from "./home/GetCareBanner";
import ChangeProfilePicturePopUp from "./home/ChangeProfilePicturePopUp";
import BookAppointmentBanner from "./home/BookAppointmentBanner";
import ReferralCard from "./home/ReferralCard";
//import SocialNetworkCard from "./widgets/sMediaWidget/SocialNetworkCard";
import SocialNetworkCard2 from "./widgets/sMediaWidget/SocialNetworkCard2";
import TeleHealthAndPersonCare from "./home/TeleHealthAndPersonCare";
import NotificationSettings from "./settings/NotificationSettings";
import LanguageSettings from "./settings/LanguageSettings";
import EditProfileForm from "./settings/EditProfileForm";
import EditPasswordForm from "./settings/EditPasswordForm";
import DelegatePermission from "./settings/DelegatePermission";
import Sidebar from "./settings/Sidebar";
import BadgesHolder from "./badges/BadgesHolder";
import BadgesList from "./badges/BadgesList";
import Help from "./help/HelpContent";
import YourBadges from "./leaderboard/YourBadges";
import BonusPoints from "./leaderboard/BonusPoints";
import TopScorers from "./leaderboard/TopScorers";
import LeaderBoardContent from "./leaderboard/LeaderBoardContent";
import SelectDateRange from "./leaderboard/SelectDateRange";
import FaqQus from "./faq/FaqQus";
import ImageAndSearch from "./faq/ImageAndSearch";
import SubmitIdea from "./submit/SubmitIdea";
import SubmitContent from "./submit/SubmitContent";
import DiscoverdContent from "./discoverd/DiscoverdContent";

export {
  SignInForm, SignUpForm,
  ResetPasswordForm, ForgotPasswordForm,
  EditProfileForm, EditPasswordForm,
  FeedMenuList, FeedList,
  Leaderboard, LeaderBoardContent,
  AnalyticsCard, SubmitIdeaCard,
  WhoToFollowCard, ReferAndEarnCard, ReferralCard,
  GetCareBanner, BookAppointmentBanner, TeleHealthAndPersonCare,
  BadgeCard, FeedCard,
  SocialNetworkCard2,//SocialNetworkCard
  FollowOurClients,
  SchedulePostForm, CongratsPostForm,
  NotificationSettings, LanguageSettings,
  Sidebar, DelegatePermission,
  BadgesHolder, BadgesList, YourBadges,
  Help, FaqQus,
  PointsBreakupCard, BonusPoints, TopScorers,
  SelectDateRange, ImageAndSearch,
  SubmitIdea, SubmitContent,
  DiscoverdContent,
  ChangeProfilePicturePopUp,
};
