import { createSlice, current, original } from "@reduxjs/toolkit";
import { sSApi } from '../sSApi';
//---------------------------------------------------------
//EngagementPoints&BonusPoints

export const admGamingApi = sSApi.injectEndpoints({
  endpoints: (builder) => ({
    engagementPointList: builder.query({
      //query: ({ page }) => {
      query: () => {
        return {
          url: '/points',//?company_id=
        };
      },
    }),
    engagementPointUpdate: builder.mutation({
      //company_id, source_id (postId), source_snetworkplatform_id,
      //destination_snetworkplatform_id, engagement_type, points
      query: (params) => ({
        //return {
          url: '/points',
          method: 'POST',
          body: params,
        //};
      }),
    }),
    //---------------------------------------------------------

    bonusPointList: builder.query({
      query: (params) => {
        return {
          url: '/bonuspoints',
        };
      },
    }),
    bonusPointDetails: builder.query({
      query: (params) => {
        return {
          url: '/bonuspoints/' + params.bonusPointId,
        };
      },
    }),
    bonusPointUpdate: builder.mutation({
      query: (params) => {
        return {//
          //description
          //points_earned
          url: '/bonuspoints/' + params.bonusPointId,
        };
      },
    }),
    bonusPointAssign: builder.mutation({
      query: (params) => {
        return {
          //user_id, description, points_earned
          url: '/bonuspoints',
        };
      },
    }),
    bonusPointDelete: builder.query({
      query: (params) => {
        return {
          url: '/bonuspoints/' + params.bonusPointId,
          method: 'DELETE',
        };
      },
    }),
    //
  }),
});
//---------------------------------------------------------

export const {
  useEngagementPointListQuery,
  //useLazyEngagementPointListQuery,
  useEngagementPointUpdateMutation,

  //useBonusPointListQuery,
  //useBonusPointDetailsQuery,
  //useBonusPointUpdateMutation,
  //useBonusPointAssignMutation,
  //useBonusPointDeleteMutation,
} = admGamingApi;
//---------------------------------------------------------

const admGamingSlice = createSlice({
  name: "admGaming",
  //initialState: { engPointsRef: {test: 'test'}, },
  initialState: { engPointsRef: null },
  reducers: {
    rInitEngPointConf: (state, action) => {
      console.log('rInitEngPointConfAct', action.payload);
      //if(state.engPointsRef && Object.keys(state.engPointsRef).length) { return state; }
      state.engPointsRef = action.payload;
      //console.log('engPointsRefUp', current(state));
      return state;
    },
    rSetSMEngPoints: (state, action) => {
      const dSrcSMKey = action.payload?.srcSMKey;
      const dDestSMKey = action.payload?.destSMKey;
      const engType = action.payload?.engType;
      const dVal = action.payload?.points;
      console.log('rSetSMEngPointsArgs', action.payload, dSrcSMKey,
        dDestSMKey, engType, dVal);
      if(state.engPointsRef.hasOwnProperty(dSrcSMKey) &&
        state.engPointsRef[dSrcSMKey].hasOwnProperty(dDestSMKey) &&
        state.engPointsRef[dSrcSMKey][dDestSMKey].hasOwnProperty(engType)
      ) {
        if(state.engPointsRef[dSrcSMKey][dDestSMKey][engType] !== dVal) {
          const newSt = {...state};
          newSt.engPointsRef = {...newSt.engPointsRef};
          newSt.engPointsRef[dSrcSMKey][dDestSMKey][engType] = dVal;
          return newSt;
        } else { return state; }
      }
      console.log('rInvalidEngPtsArgs');//throwErr
      return state;
    },
    rSetSAEngPointsDataMult: (state, action) => {//PENDING
      const ePtsInData = action?.payload;
      const rCnt=0, upCnt=0, errM={};
      const srcSMKs = Object.keys(ePtsInData);
      //console.log('AA', );
      /*console.log('rSetSAEngPointsDataMultArgs', ePtsInData);
      console.log('engPointsRefrrr', current(state));//.engPointsRef);
      console.log('engPointsRefRRR', original(state));//.engPointsRef);
      console.log('engPointsRefrrr222', JSON.parse(JSON.stringify(state.engPointsRef)));*/

      const newSt = {};//{...state, ...state.engPointsRef};
      //console.log('f1',{state, newSt, srcSMKs});
      //console.log('f2',current(state));
      //console.log('f2',original(state));
      //console.log('f3',state);
      if(0 && srcSMKs?.length) {
        const newSt = {...state, ...state.engPointsRef};
        //newSt.engPointsRef = {...newSt.engPointsRef};
        console.log({state, newSt, srcSMKs});
        srcSMKs.forEach(sK=> {
          Object.keys(ePtsInData[sK]).forEach(dK=> {
            //if(newSt.engPointsRef)
          });
        });
      }
      return state;
    },
  },
});

export default admGamingSlice.reducer;
export const {
  rInitEngPointConf,
  rSetSMEngPoints,
  //rSetSAEngPointsDataMult,
} = admGamingSlice.actions;

export const rGetRefEngPoints = (state) => state?.admGaming?.engPointsRef; // || {};
//--------------------------------------------------------- 