import { sSApi } from "./sSApi";
import { createSlice, current } from "@reduxjs/toolkit";
//import * as appConf from '../config/app.config';
//---------------------------------------------------------

export const orgApi = sSApi.injectEndpoints({
  endpoints: (builder) => ({
    orgSearch: builder.query({
      query: (params) => "/company/search?type=subdomain&text=" + params.text,
    }),
    orgList: builder.query({
      query: () => "/company",
    }),
    orgDetails: builder.query({
      query: (params) => "/company/" + params.orgId,
    }),
    orgUsers: builder.query({
      query: (params) => "/company/" + params.orgId + "/users",
    }),
    orgGroups: builder.query({
      query: (params) => {
        let aUrl = "/company/" + params.orgId + "/groups";
        aUrl += params?.page ? "?page=" + params?.page : "";
        return { url: aUrl };
      },
    }),
    orgLeaderboard: builder.query({
      query: (params) => {
        console.log('ldrQParams', params);
        //let fCallArgs = JSON.stringify(params?.fCallArgs);
        let fCallArgs = params?.fCallArgs;
        let aUrl =
          "/company/" + params.orgId  + "/leaderboard?group_id=" + params.group_id +
          "&" + "from_date=" + params.startDate +
          "&" + "to_date=" + params.endDate +
          "&" + "limit=100";// +
        if(params.hasOwnProperty('filter_json')) {
          //aUrl += '&filter_json='+ JSON.stringify(params?.fCallArgs);
          aUrl += '&filter_json='+ params?.filter_json;
        }
        /*if(fCallArgs) {
          aUrl += '&filter_json='+ JSON.stringify(params?.fCallArgs);
        }*/
        return { url: aUrl };
      },
    }),
    selfLeaderboard: builder.query({
      keepUnusedDataFor: 10,
      query: (params) => {
        console.log('selfLdrQParams', params);
        let aUrl =
          '/company/' + params.orgId  + '/leaderboard/self'+ //?group_id=" + params.group_id +
          '?from_date=' + params.startDate +
          '&to_date=' + params.endDate +
          '&limit=100';// +
        /*if(params.hasOwnProperty('filter_json')) {
          //aUrl += '&filter_json='+ JSON.stringify(params?.fCallArgs);
          aUrl += '&filter_json='+ params?.filter_json;
        }*/
        /*if(fCallArgs) {
          aUrl += '&filter_json='+ JSON.stringify(params?.fCallArgs);
        }*/
        return { url: aUrl };
      },
    }),
  }),
});
//---------------------------------------------------------

export const {
  useOrgSearchQuery,
  useOrgListQuery,
  useOrgDetailsQuery,
  useOrgUsersQuery,
  useOrgGroupsQuery,
  useOrgLeaderboardQuery,
  useSelfLeaderboardQuery,
} = orgApi;
//---------------------------------------------------------

const orgSlice = createSlice({
  name: "org",
  initialState: { orgId: null, title: null, details: null },
  reducers: {
    setOrgInfo: (state, action) => {
      const { id, name } = action.payload;
      state.orgId = id;
      state.title = name;
      //state.details = action.payload?.details;
    },
  },
});

export default orgSlice.reducer;
export const { setOrgInfo } = orgSlice.actions;
export const selectOrgId = (state) => state.org.orgId;
export const selectOrgData = (state) => state.org;
//---------------------------------------------------------

const iniLdrStat = { refetchCount: 0 };

const leaderboardSlice = createSlice({
	name: 'leaderboard',
	initialState: iniLdrStat,
	reducers: {
    rIncrementRefetchCount: (state, action) => {
      const dVal = action.payload?.val || 1;
      const newSt = {...current(state)};
      console.log('rIncrementRefetchCountArgs', {args: action.payload, dVal, newSt});
      newSt.refetchCount += dVal;
      return newSt;
    },
    rResetRefetchCount: (state, action) => {
      return {...state, refetchCount: 0};
    },
	},
});

export const  leaderboardReducer = leaderboardSlice.reducer;
//export default leaderboardSlice.reducer;
export const {
  rIncrementRefetchCount, rResetRefetchCount,
} = leaderboardSlice.actions;
export const rGetRefetchCount  = state => state.leaderboard.refetchCount;
//---------------------------------------------------------
