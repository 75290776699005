import { Box, Grid, IconButton, Stack, styled } from "@mui/material";
import { Card, Typography } from "core";
import React from "react";
import Remove from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { useState } from "react";
import { useContentDiscoveryCategoryListQuery } from "services/categoryApi";
import { useTranslation } from "react-i18next";

const ContentCard = styled(Card)(({ background, paddingBottom }) => ({
  background: background,
  width: "300px",
  height: "150px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between ",
  paddingBottom: paddingBottom,
}));
const StyledTextBox = styled(Box)(({ theme }) => ({
  width: "370px",
  height: "80px",
  gap: 1,
  padding: "6px",
  textAlign: "center",
  margin: "auto",
  [theme.breakpoints.down("sm")]: {
    height: "160px",
  },
}));

const StyledGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    maxWidth: "100%",
  },
}));


export default function DiscoverdContent() {
  const [selectedCategory, setSelectedCategory] = useState(["tech", "music"]);
  const { t: home } = useTranslation('home')
  function handleSelectCategory(category) {
    if (selectedCategory.includes(category)) {
      const index = selectedCategory.indexOf(category);
      const categories = [...selectedCategory];
      categories.splice(index, 1);
      setSelectedCategory(categories);
    } else {
      setSelectedCategory((prev) => [...prev, category]);
    }
  }
  const data = [
    {
      image: "/assets/images/tech.png",
      text: `${home('tech')}`,
      background: "#88a4cb",
    },
    {
      image: "/assets/images/branding.png",
      text: `${home('branding')}`,
      background: "#90D6F0",
    },
    {
      image: "/assets/images/sports.png",
      text: `${home('sports')}`,
      background: "#E3E748",
    },
    {
      image: "/assets/images/music.png",
      text: `${home('music')}`,
      background: "#7CC142",
    },
    {
      image: "/assets/images/fashion.png",
      text: `${home('fashion')}`,
      background: "#F48F9D",
    },
    {
      image: "/assets/images/accounting.png",
      text: `${home('accounting')}`,
      background: "#B791C2",
      paddingBottom: 0,
    },
  ];
  return (
    <Grid
      container
      spacing={3}
      justifyContent="center"
      alignItems="center"
      direction="row"
    >
      <StyledGrid item xs={6}>
        <Stack spacing={4} justifyContent="center">
          <Card>
            <Stack direction="row">
              <img
                src="/assets/images/discoverd.png"
                alt="content"
                style={{ width: "150px" }}
              />


              <StyledTextBox>
                <Typography
                  size="medium"
                  fontWeight={900}
                  marginBottom="0.5rem"
                >

                  {home('discoverd_great_content')}
                </Typography>
                <Typography
                  size="small"
                  fontWeight={600}
                  sx={{ maxWidth: "300px", display: "flex", margin: "auto" }}
                >
                  {home('add_from_the_below')}
                </Typography>
              </StyledTextBox>
            </Stack>
          </Card>
          <Grid
            container
            rowGap={2.5}
            columnGap={3.5}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            {data.map((obj) => (
              <Grid item spacing={5} sx={{ background: "#ddd" }}>
                <ContentCard
                  background={obj.background}
                  paddingBottom={obj.paddingBottom}
                >
                  <img
                    src={obj.image}
                    alt="content"
                    style={{ width: "100px" }}
                  />
                  <Stack
                    direction="row"
                    spacing={1}
                    alignItems="end"
                    sx={{
                      textTransform: "uppercase",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                        paddingBottom: `${obj.text === `${home('accounting')}` && "15px"}`,
                      }}
                    >
                      <Typography fontWeight={900} size="medium">
                        {obj.text}
                      </Typography>

                      <IconButton
                        onClick={() => handleSelectCategory(obj.text)}
                      >
                        {selectedCategory.includes(obj.text) ? (
                          <Remove
                            sx={{
                              fontSize: "1.5rem",
                              padding: "3px",
                              border: "2px solid",
                              background: "#fff",
                            }}
                          />
                        ) : (
                          <AddIcon
                            sx={{
                              fontSize: "1.5rem",
                              padding: "3px",
                              border: "2px solid",
                            }}
                          />
                        )}
                      </IconButton>
                    </Box>
                  </Stack>
                </ContentCard>
              </Grid>
            ))}
          </Grid>
          {/* <Card
              sx={{
                width: "300px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between ",
              }}
            >
              <img src="" alt="content" style={{ width: "110px" }} />
              <Stack
                direction="row"
                spacing={1}
                alignItems="end"
                sx={{
                  textTransform: "uppercase",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Typography fontWeight={900} size="medium">
                    branding
                  </Typography>

                  <Remove
                    sx={{
                      fontSize: "1.5rem",
                      padding: "3px",
                      border: "2px solid",
                      background: "#fff",
                    }}
                  />
                </Box>
              </Stack>
            </Card>
          <Stack spacing={5} direction="row">
            <Card
              sx={{
                width: "300px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between ",
              }}
            >
              <img
                src="/assets/images/sports.png"
                alt="content"
                style={{ width: "110px" }}
              />
              <Stack
                direction="row"
                spacing={1}
                alignItems="end"
                sx={{
                  textTransform: "uppercase",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Typography fontWeight={900} size="medium">
                    sports
                  </Typography>

                  <Remove
                    sx={{
                      fontSize: "1.5rem",
                      padding: "3px",
                      border: "2px solid",
                      background: "#fff",
                    }}
                  />
                </Box>
              </Stack>
            </Card>
            <Card
              sx={{
                width: "300px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between ",
              }}
            >
              <img
                src="/assets/images/music.png"
                alt="content"
                style={{ width: "110px" }}
              />
              <Stack
                direction="row"
                spacing={1}
                alignItems="end"
                sx={{
                  textTransform: "uppercase",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Typography fontWeight={900} size="medium">
                    music
                  </Typography>

                  <Remove
                    sx={{
                      fontSize: "1.5rem",
                      padding: "3px",
                      border: "2px solid",
                      background: "#fff",
                    }}
                  />
                </Box>
              </Stack>
            </Card>
          </Stack>
          <Stack spacing={5} direction="row">
            <Card
              sx={{
                width: "300px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between ",
              }}
            >
              <img
                src="/assets/images/fashion.png"
                alt="content"
                style={{ width: "110px" }}
              />
              <Stack
                direction="row"
                spacing={1}
                alignItems="end"
                sx={{
                  textTransform: "uppercase",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Typography fontWeight={900} size="medium">
                    fashion
                  </Typography>

                  <Remove
                    sx={{
                      fontSize: "1.5rem",
                      padding: "3px",
                      border: "2px solid",
                      background: "#fff",
                    }}
                  />
                </Box>
              </Stack>
            </Card>
            <Card
              sx={{
                width: "300px",
                paddingBottom: "0",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between ",
              }}
            >
              <img
                src="/assets/images/accounting.png"
                alt="content"
                style={{ width: "100px" }}
              />
              <Stack
                direction="row"
                spacing={1}
                alignItems="end"
                sx={{
                  textTransform: "uppercase",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    paddingBottom: "15px",
                  }}
                >
                  <Typography fontWeight={900} size="medium">
                    accounting
                  </Typography>

                  <Remove
                    sx={{
                      fontSize: "1.5rem",
                      padding: "3px",
                      border: "2px solid",
                      background: "#fff",
                    }}
                  />
                </Box>
              </Stack>
            </Card>
          </Stack> */}
        </Stack>
      </StyledGrid>
    </Grid>
  );
}
