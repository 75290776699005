import { styled } from "@mui/material";
import { Typography } from "core";
import { useTranslation } from "react-i18next";

const FooterContainer = styled("div")(({ theme }) => ({
  textAlign: "center",
  //border: '2px solid red'
}));
export default function Footer() {
  const { t: home } = useTranslation('home')

  return (
    <FooterContainer>
      <Typography
        variant="caption"
        color_variant="light"
        size="xsmall"
        display="flex"
        alignItems="center"
      >
        {home('terms_of_use')} |{home('privacy_policy') + home('copyright')}
        <span className="xcopyright-symbol">&nbsp;&copy;&nbsp;</span>{home('2023_niu_health')}
      </Typography>
    </FooterContainer>
  );
}
