import { Avatar, Grid, Skeleton, Stack } from '@mui/material';
import { Card, Typography } from 'core';
import React, { useState } from 'react';
import ChangeProfilePicturePopUp from './ChangeProfilePicturePopUp';
//---------------------------------------------------------

// const StyledRoleDropdown = styled("select")(({ theme }) => ({
//   padding: "0.7rem 0.2rem",
//   color: grey[600],
//   outline: "none",
//   border: `1px solid ${grey[500]}`,
//   backgroundColor: theme.palette.background.paper,
//   textAlign: "center",
//   fontSize: "12px",
//   letterSpacing: "1px",
// }));

export default function ProfileInfoCard({
  theme,
  name,
  profile,
  refetchProfileDetails,
  isProfileDetailsLoading,
}) {
  const [showEditProfileCard, setShowEditProfileCard] = useState(false);
  function handleEditProfileCard() {
    setShowEditProfileCard((prev) => !prev);
  }
  let dispName = name;
  if(dispName.length > 18) {// && dispName.indexOf(' ') < 0) {
    // !(/\s/).test(dispName);
    dispName = dispName.substring(0, 16) + '..';
  }

  return (
    <Card
      sx={{
        paddingY: '1.1rem',
        height: '185px',
        display: 'block',
        boxShadow: 'none',
      }}
    >
      <Grid
        container
        rowGap={'7px'}
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        {/* PROFILE INFO */}

        <Grid item justifyContent="center" display="flex">
          {isProfileDetailsLoading ? (
            <Skeleton variant="circular" width={120} height={120} />
          ) : (
            <Avatar
              src={profile}
              alt={name}
              onClick={handleEditProfileCard}
              sx={{
                width: 120,
                height: 120,
                cursor: 'pointer',
              }}
              imgProps={{
                sx: {
                  objectFit: 'contain',
                },
              }}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <Stack spacing={0.5}>
            {isProfileDetailsLoading ? (
              <Skeleton variant="rectangular" width={200} height={20} />
            ) : (
              <Typography fontWeight={600} size="large">
                {dispName}
              </Typography>
            )}
          </Stack>
        </Grid>
      </Grid>
      <ChangeProfilePicturePopUp
        showEditProfileCard={showEditProfileCard}
        onCloseEditProfileCard={handleEditProfileCard}
        profile={profile}
        refetchProfileDetails={refetchProfileDetails}
      />
    </Card>
  );
}
