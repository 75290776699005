import React, { useState } from 'react';
import './style.css';
import leftArrow from './icons/left-arrow.svg';
import rightArrow from './icons/right-arrow.svg';
import Backdrop from 'core/BackDrop';

function BtnSlider({ direction, moveSlide }) {
  return (
    <button
      onClick={moveSlide}
      className={direction === 'next' ? 'btn-slide next' : 'btn-slide prev'}
    >
      <img src={direction === 'next' ? rightArrow : leftArrow} alt="arrow" />
    </button>
  );
}

function AlbumPost({ imageList, isUserList=false }) {
  const [slideIndex, setSlideIndex] = useState(1);

  const nextSlide = () => {
    if (slideIndex !== imageList.length) {
      setSlideIndex(slideIndex + 1);
    } else if (slideIndex === imageList.length) {
      setSlideIndex(1);
    }
  };

  const prevSlide = () => {
    if (slideIndex !== 1) {
      setSlideIndex(slideIndex - 1);
    } else if (slideIndex === 1) {
      setSlideIndex(imageList.length);
    }
  };

  const moveDot = (index) => {
    setSlideIndex(index);
  };
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  return (
    <div className="container-slider">
      {imageList.map((obj, index) => {
        return (
          <div
            key={obj.id}
            className={slideIndex === index + 1 ? 'slide active-anim' : 'slide'}
          >
            <img
              src={`/images/img${index + 1}.jpg`}
              alt="img"
              onClick={handleToggle}
            />
          </div>
        );
      })}
      {!isUserList && (
        <Backdrop open={open} onClose={handleClose}>
          {imageList.map((obj, index) => {
            return (
              <div
                key={obj.id}
                className={
                  slideIndex === index + 1 ? 'slide active-anim' : 'slide'
                }
              >
                <img
                  src={`/images/img${index + 1}.jpg`}
                  alt="img"
                  onClick={handleToggle}
                />
              </div>
            );
          })}
        </Backdrop>
      )}

      <BtnSlider moveSlide={nextSlide} direction={'next'} />
      <BtnSlider moveSlide={prevSlide} direction={'prev'} />

      <div className="container-dots">
        {Array.from({ length: 5 }).map((item, index) => (
          <div
            key={index}
            onClick={() => moveDot(index + 1)}
            className={slideIndex === index + 1 ? 'dot active' : 'dot'}
          ></div>
        ))}
      </div>
    </div>
  );
}

export default AlbumPost;
