const ERRORS = {
  EMAIL: {
    required: "Email is required",
    invalid: "Email is invalid",
  },
  ROLE: {
    required: "Role is required",
  },
  OTP: {
    required: "OTP is required",
  },
  PASSWORD: {
    required: "Password is required",
    invalid:
      "Password must include uppercase, lowercase, number and special symbol",
    min: "Min 8 characters required",
    min_chars: 8,
    regex: /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,15}$/,
    not_matched: "Passwords does not match",
  },
  ADDRESS: {
    required: "Address is required",
  },
  CITY: {
    required: "City is required",
  },
  MOBILE_NUMBER: {
    regex:
      /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
    invalid: "Mobile number is invalid",
    required: "Mobile number is required",
  },
  FIRST_NAME: {
    required: "First name is required",
    min: "Min 3 characters is required",
    min_chars: 3,
   
  },
  LAST_NAME: {
    required: "Last name is required",
    min: "Min 3 characters is required",
    min_chars: 3,
  },
  NAME: {
    required: "Last name is required",
    min_chars: 3,
    min: "Min 3 characters is required",
    max_chars: 30,
    max: "Max 30 characters are allowed",
    matches:"Only characters  are required",
    matches_regex:/^[A-Za-z]*$/
  },
  CONTENT_DESCRIPTION: {
    required: "Text is required",
    min: "Min 25 characters is required",
    min_chars: 25,
  },
};

export default ERRORS;
