import { Paper } from "@mui/material";
import { grey } from "@mui/material/colors";

export default function CalendarIcon({theme}) {
	return(
		<Paper variant="outlined"
			sx={{color: grey[600], backgroundColor: 'transparent', opacity:'1', border: '0',
				fontSize: "2rem",
				//[theme.breakpoints.down("sm")]: { fontSize: "1.5rem", },
			}}
		>
			<img src="/assets/images/calendar2.png" height="35" width="35" />
		</Paper>
	);
};