import { useState } from "react";
import { func } from "prop-types";
import {
  Box, Stack, Grid, styled, Button,
  useTheme, TextField,//, LoadingButton
} from "@mui/material";
//import Textarea from "@mui/joy/Textarea";
import {
  Spinner2, Flasher,//SpinnerBox,
  PageMessage, Typography,
} from 'core';
import { useTranslation } from "react-i18next";
//---------------------------------------------------------

const StyledCmdCard = styled(Box)(({ theme, highlight }) => ({
  //display: "flex", flexDirection: "column", flexGrow: "1",
  //padding: 0, margin: 0, gap: '0.5rem',
  //justifyContent: 'center', alignItems: 'flex-start', width: '100%',
  //alignItems: 'center',
  backgroundColor: highlight ? "#f5f5f5" : theme.palette.background.paper,
  border: "1px solid #e6e7e9",
  //border: highlight && `3px solid ${theme.palette.background.default}`,
  // boxShadow: theme.shadows[1],
  height: '3rem', maxHeight: '3rem',
}));
const txCmdSx = {
  textAlign: 'center', size: 'small',
  fontSize: '1.35rem', fontWeight: 400,
  textTransform: 'none',
  color: '#9e9e9e', //color_variant: "lightGrey",
};
/*const tBtnMuiSx = {
  fontWeight: 700, backgroundColor: '#fff',//'#f0f0f0',
  borderRadius: '2.5px',
  boxShadow: '0px 0px 15.328px 0px rgba(0, 0, 0, 0.25)',
}
const tBtnSx = {
  fontSize: '1.35rem', fontWeight: 400, textTransform : 'none',
  height: '98%', color: '#9e9e9e', //color_variant: "lightGrey",
  size: 'small', //fullWidth: true,
  //"&.Mui-selected, &.Mui-selected:hover": {...tBtnMuiSx, color: "#71b845",}
};
const tBtnASx = {...tBtnSx,
  "&.Mui-selected, &.Mui-selected:hover": {...tBtnMuiSx,
    color: "#71b845", border: '1px solid #71b845',}
};
const tBtnRSx = {...tBtnSx,
  "&.Mui-selected, &.Mui-selected:hover": {...tBtnMuiSx,
    color: "#fe1313", border: '1px solid #fe1313',}
};
const tBtnPSx = {...tBtnSx,
  "&.Mui-selected, &.Mui-selected:hover": {...tBtnMuiSx,
    //color: "#71b845", border: '1px solid #71b845',
  }
};*/
const testBtnSx = {
  width: '100%',
  "& .MuiTouchRipple-root &.MuiTouchRipple-child": {
    backgroundColor: '#fe1313 !important',
  }
}
//---------------------------------------------------------

export default function AdmCSuggestCommandBar({ cSPData, acsPMethods }) {
  //console.log('cSPDataCmdB', cSPData);
  const [stPgMsg, setStPgMsg] = useState('');
  const [stAjaxStep, setStAjaxStep] = useState(0);//-1:Err, 0: Nop, 1:wait, 2: OK
  const [stTxNotes, setSTxNotes] = useState('');
  const [stShowNotesField, setStShowNotesField] = useState(false);
  const fRestoreAjax = () => {
    setStPgMsg(''); setStAjaxStep(0); //modalArgs.onDismiss();
    if (stTxNotes.length) { setSTxNotes(''); }
  };
  const { t: home } = useTranslation('home')
  //const [fSetACSPostStatus, { data, isLoading: isRPtsUpdating, reset: fResetRPt }] = useCSuggestSetApprovalMutation();

  const fRemoveFeedPost = (ctnId) => {
    const elPost = document.getElementById(ctnId);
    if (elPost) {
      setTimeout(() => {
        elPost.style.display = 'none';
        elPost.style.transition = 'ease-in-out';
        if (stShowNotesField === true) { setStShowNotesField(false); }
      }, 3000);
    }
  }

  const fApproveACSPost = async (e) => {
    const postId = e.currentTarget.value; //console.log(postId);
    setStAjaxStep(1);

    if (postId) {
      const inParams = {
        cSPostId: postId, is_approved: 1, //approvalStatus: 'approve',
        points: 100
      };
      const aCSPApproveResp = await acsPMethods.fSetCSPApproval(inParams);
      //console.log('aCSPApproveResp', {inParams, aCSPApproveResp});
      setStAjaxStep(2);
      if (aCSPApproveResp.result === true) {
        setStPgMsg(`${home('the_content_suggestion_has_been_rejected_successfully')}`);
        fRemoveFeedPost('ctnAcsP_' + postId);
      } else {// if(aCSPApproveResp.error) {switch(aCSPApproveResp.error.code)
        setStPgMsg(`${home('error_approving_the_content_suggestion')}` +
          "\n" + `${home('please_try_again..')}`);
        setStAjaxStep(-1);
      }
      setTimeout(fRestoreAjax, 3000);
    }
  };
  const fInitRejectACSPost = () => { setStShowNotesField(true); }
  const fCancelReject = () => { setStShowNotesField(false); }
  const fRejectACSPost = async (e) => {
    const postId = e.currentTarget.value;//console.log(postId);
    setStAjaxStep(1);

    if (postId) {
      //const txReason = document.getElementById('txNotes_'+postId).innerText;
      let txReason = stTxNotes;
      txReason = txReason.length ? txReason : 'NA';
      //console.log(postId, txReason);
      const inParams = {
        cSPostId: postId, is_approved: 0,//approvalStatus: 'reject',
        points: 0, notes: txReason
      };
      try {
        const aCSPRejectResp = await acsPMethods.fSetCSPApproval(inParams);
        //console.log('aCSPRejectResp', {inParams, aCSPRejectResp});
        setStAjaxStep(2);
        if (aCSPRejectResp.result === true) {
          setStPgMsg(`${home('the_content_suggestion_has_been_approved_successfully')}`);
          fRemoveFeedPost('ctnAcsP_' + postId);
        } else {// if() {
          setStPgMsg(`${home('error_rejecting_the_content_suggestion')}< br />${home('please_try_again..')}`);
          setStAjaxStep(-1);
        }
        //setTimeout(fRestoreAjax, 2000);
      } catch (err) {
        setStAjaxStep(-1);
      }
      setTimeout(fRestoreAjax, 3000);
    }
  }
  //---------------------------------------------------------

  return (
    <>
      {stShowNotesField && (
        <Box sx={{ width: '100%' }} className="xtBdr2">
          <TextField multiline rows="3" fullWidth
            autoFocus={true} label="Reason"
            placeholder={`${home("please_provide_reason_for_rejection")}`}
            id={'txNotes_' + cSPData.postId}
            onChange={(event) => { setSTxNotes(event.target.value) }}
          /><br />
          <Box>
            <Button variant="text" type="button" className="cBtn cBtnWh"
              sx={{ flexShrink: 0, marginTop: '0.3rem', marginLeft: 1, marginRight: '1rem' }} //sx={{margin: '0.3rem 1rem', flexShrink: 0,}}
              onClick={fCancelReject}
            >Discard</Button>
            <Button variant="text" type="button" className="cBtn cBtnDark"
              sx={{ flexShrink: 0, marginTop: '0.3rem', marginLeft: 1 }}
              onClick={fRejectACSPost} value={cSPData.postId}
              disabled={stAjaxStep !== 0}
            >Save</Button>
          </Box>
        </Box>
      )}
      {stPgMsg && (
        <Flasher /*delay={3000}*/ resetFunction={fRestoreAjax}>
          <PageMessage text={stPgMsg} isError={stAjaxStep === -1} />
        </Flasher>
      )}
      <Grid container id={'ctnAcsCmdB_' + cSPData.postId} className='xtBdr2'
        rowSpacing={0}
        columnSpacing={0}//{{ xs: 1, sm: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
        justifyContent={'center'}
        alignItems={'top'}//disableEqualOverflow
        sx={{ marginTop: '1.5rem' }}
      >
        {/*<Grid item xs={1} className='xbdr1'>
          <StyledCmdCard className='flxRWA' style={{ alignItems: 'center'}}>
            <ListIcon sx={{alignItems: 'center'}} />
          </StyledCmdCard>
        </Grid>*/}
        <Grid item xs={6} className='xbdr1 xtBdr2'>
          <StyledCmdCard className='flxRWA'>
            <Button variant="text" sx={{ width: '100%' }}
              onClick={fApproveACSPost} value={cSPData.postId}
            >
              <Typography sx={txCmdSx}>{home("approve")}</Typography>
            </Button>
          </StyledCmdCard>
        </Grid>
        <Grid item xs={6} className='xbdr1'>
          <StyledCmdCard className='flxRWA'>
            <Button variant="text" sx={testBtnSx} color="error"
              onClick={fInitRejectACSPost} value={cSPData.postId}
              disabled={stShowNotesField === true}
            >
              <Typography sx={txCmdSx}>{home("reject")}</Typography>
            </Button>
          </StyledCmdCard>
        </Grid>
        {/*<Grid item xs={4} className='xbdr1'>
          <StyledCmdCard className='flxRWA'>
            <Typography sx={txCmdSx}>
              Ask for Modification
            </Typography>
          </StyledCmdCard>
        </Grid>*/}
      </Grid>
    </>
  );
};