import { useState, useCallback, useEffect, forwardRef } from "react";
import { func } from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Box, Stack, Grid, styled, } from "@mui/material";
import { Typography } from 'core';
import AdmCSuggestSummary from './AdmCSuggestSummary';
import AdmCSuggestFilterBar from './AdmCSuggestFilterBar';
import AdmCSuggestList from './AdmCSuggestList';
//---------------------------------------------------------

export default function AdmCSuggestData({theme}) {
  //---------------------------------------------------------

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AdmCSuggestSummary theme={theme} />
      <AdmCSuggestFilterBar />
      <AdmCSuggestList />
    </Box>
  );
};
//---------------------------------------------------------
