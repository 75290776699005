import { useSelector } from "react-redux";
import { Grid, styled, useTheme } from "@mui/material";
import { PageContainer } from 'core';
//import { DelegatePermission, LanguageSettings, NotificationSettings,
//} from "sections";
import EditNetwork from 'sections/settings/EditNetwork';
//import EditPasswordForm from "sections/settings/EditPasswordForm";
//import EditProfileForm from "sections/settings/EditProfileForm";
import { selectAuthUserId } from 'services/auth/authSlice';
import { useUserDetailsByIdQuery } from 'services/userApi';
//---------------------------------------------------------

const StyledGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: { width: "100%", },
}));
//---------------------------------------------------------

export default function Settings({ title }) {
  const theme = useTheme();
  const userAuthId = useSelector(selectAuthUserId);

  const { data: userData, refetch: refetchUserData } = useUserDetailsByIdQuery({
    userId: userAuthId,
  });

  return (
    <PageContainer title={title}>
      <Grid container spacing={8} textAlign="center">
        {/*<Grid item sm={12} lg={12}>
          <EditProfileForm
            theme={theme}
            profileImage={userData?.users[0]?.medias?.profile?.url}
            first_name={userData?.users[0].first_name}
            last_name={userData?.users[0].last_name}
            city={userData?.users[0]?.city}
            email={userData?.users[0]?.email}
            refetchUserData={refetchUserData}
          />
        </Grid>
        <Grid item sm={12} lg={12}>
          <EditPasswordForm theme={theme} />
        </Grid>*/}

        <StyledGrid item sm={12} lg={12}>
          <EditNetwork theme={theme} />
        </StyledGrid>

        {/* <Grid item sm={12} lg={12}>
          <NotificationSettings theme={theme} />
        </Grid>
        <Grid item sm={12} lg={12}>
          <LanguageSettings theme={theme} />
        </Grid>
        <Grid item sm={12} lg={12}>
          <DelegatePermission theme={theme} />
        </Grid> */}
      </Grid>
    </PageContainer>
  );
}
