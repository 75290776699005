import { Grid, useTheme } from "@mui/material";
import { PageContainer } from "core";
import React from "react";
import { SubmitContent, SubmitIdea } from "sections";
//---------------------------------------------------------

export default function Submit({ title }) {
  const theme = useTheme();
  return (
    <PageContainer title={title}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <SubmitIdea theme={theme} />
        </Grid>
        <Grid item xs={12}>
          <SubmitContent theme={theme} title="Suggest a Post" />
        </Grid>
      </Grid>
    </PageContainer>
  );
}
