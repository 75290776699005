//import { useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
//import { func } from "prop-types";
import { Grid, Box, Stack, styled, Select, useTheme, } from "@mui/material";
import { grey } from "@mui/material/colors";
//import { Typography } from 'core';
//import {  } from 'config/app.config';
//import IconButton from '@mui/material/IconButton';
//import ListIcon from '@mui/icons-material/List';
//import { SpeedDial, SpeedDialIcon, SpeedDialAction
//} from '@mui/material';
//import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import { rGetCSFilterData, rSetCSFilter} from 'services/admin/admContentSuggestionsApi';
//---------------------------------------------------------

const StyledCmdCard = styled(Box)(({ theme, highlight }) => ({
  //display: "flex", flexDirection: "column", flexGrow: "1",
  //padding: 0, margin: 0, gap: '0.5rem',
  //justifyContent: 'center', alignItems: 'flex-start', width: '100%',
  backgroundColor: highlight ? "#f5f5f5" : theme.palette.background.paper,
  border: "1px solid #e6e7e9",
  //border: highlight && `3px solid ${theme.palette.background.default}`,
  // boxShadow: theme.shadows[1],
  height: '3rem', maxHeight: '3rem',
}));
const txCmdSx = {
  paddingLeft: '1rem', size: "small",
  fontSize: '1.35rem', fontWeight: 400,
  color: '#9e9e9e', //color_variant: "lightGrey",
};
const tBtnMuiSx = {
  fontWeight: 700, backgroundColor: '#fff',//'#f0f0f0',
  borderRadius: '2.5px',
  boxShadow: '0px 0px 15.328px 0px rgba(0, 0, 0, 0.25)',
}
const tBtnSx = {
  fontSize: '1.35rem', fontWeight: 400, textTransform : 'none',
  height: '98%', color: '#9e9e9e', //color_variant: "lightGrey",
  size: 'small', //fullWidth: true,
  //"&.Mui-selected, &.Mui-selected:hover": {...tBtnMuiSx, color: "#71b845",}
};
const tBtnASx = {...tBtnSx,
  "&.Mui-selected, &.Mui-selected:hover": {...tBtnMuiSx,
    color: "#71b845", border: '1px solid #71b845',}
};
const tBtnRSx = {...tBtnSx,
  "&.Mui-selected, &.Mui-selected:hover": {...tBtnMuiSx,
    color: "#fe1313", border: '1px solid #fe1313',}
};
const tBtnPSx = {...tBtnSx,
  "&.Mui-selected, &.Mui-selected:hover": {...tBtnMuiSx,
    //color: "#71b845", border: '1px solid #71b845',
  }
};
const StyledSelect = styled(Select)(({ theme }) => ({
  alignItems: 'center', width: '100%', outline: 'none',
  backgroundColor: theme.palette.background.paper,
  padding: '0.1rem 0', border: 'none', color: grey[500],
  "&:hover": {
    backgroundColor: theme.palette.background.paper,
  },
  [theme.breakpoints.down("sm")]: {
    minWidth: '4rem',
    "& .MuiSelect-select": { padding: '0.5rem 0.25rem', },
  },
}));

//---------------------------------------------------------

export default function AdmCSuggestFilterBar() {
  const fDispatchCSFeedStatus = useDispatch();
  const admCSFilterVars = useSelector(rGetCSFilterData);
  //console.log('admCSFilterVars', admCSFilterVars);
  /*const defAcsFilterOpt = {
    //pStat: {approved: true},
    pStat: 'approved',
    fromDate: '',
    toDate: '',
    selGroup: '',
    page: 1,
  };*/
  /*const [stAcsArgs, setStAcsArgs] = useState(defAcsFilterOpt);
  *const fUpdateAcsArgOpt = (aKey, aVal) => {
    console.log(aKey, aVal);
    setStAcsArgs({...stAcsArgs, [aKey]: aVal});
    //setStAcsArgs((prevA) => {
    //  return {...prevA, [aKey]: aVal}
    //});
  };*/
  const fSetAcsStat = (event, newVal) => {
    //console.log(event.target.value);
    fDispatchCSFeedStatus(rSetCSFilter({key: 'status', val: newVal}));
  };
  //---------------------------------------------------------

  /*let tglStatusVal;
  switch(admCSFilterVars.status) {
    case 0: tglStatusVal = 'rejected'; break;
    case 1: tglStatusVal = 'approved'; break;
    default: tglStatusVal = 'pending'; break;
  }*/

  return (
    <>
    {/*<ToggleButtonGroup exclusive
      value={tglStatusVal}
      onChange={fSetAcsStat}
      orientation="horizontal"
      aria-label="feedStatus"
      sx={{width: '100%'}}
    >*/}
      <Grid container id='ctnAcsFilterStat' className='xtBdr2'
        rowSpacing={0}
        columnSpacing={0}//{{ xs: 1, sm: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
        justifyContent={'center'}
        alignItems={'top'}//disableEqualOverflow
        sx={{marginTop: '1.5rem'}}
      >
        {/*<Grid item xs={1} className='xbdr1'>
          <StyledCmdCard className='sumFilter' style={{ alignItems: 'center'}}>
            <IconButton aria-label="filterCS">
              <ListIcon sx={{alignItems: 'center'}} />
            </IconButton>
          </StyledCmdCard>
        </Grid>*/}
        <Grid item xs={4} className='xbdr1'>
          <StyledCmdCard className='sumFilter'>
            <ToggleButton value="pending" sx={tBtnPSx}
              fullWidth aria-label="FilterPending"//exclusive
              selected={admCSFilterVars.status === 'pending'}
              onChange={fSetAcsStat}
            >Pending</ToggleButton>
          </StyledCmdCard>
        </Grid>
        <Grid item xs={4} className='xbdr1'>
          <StyledCmdCard className='sumFilter'>
            <ToggleButton value="approved" sx={tBtnASx}
              fullWidth aria-label="FilterApproved"//exclusive
              selected={admCSFilterVars.status === 'approved'}
              onChange={fSetAcsStat}
            >{/*<Typography sx={txCmdSx}>Approved</Typography>*/}
              Approved
            </ToggleButton>
          </StyledCmdCard>
        </Grid>
        <Grid item xs={4} className='xbdr1'>
          <StyledCmdCard className='sumFilter'>
            <ToggleButton value="rejected" sx={tBtnRSx}
              fullWidth aria-label="FilterRejected"//exclusive
              selected={admCSFilterVars.status === 'rejected'}
              onChange={fSetAcsStat}
            >Rejected</ToggleButton>
          </StyledCmdCard>
        </Grid>
      </Grid>
    </>
  );
};