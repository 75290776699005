import { useState, useCallback, useEffect } from "react";
import { useSelector, useDispatch, /*usePrefetch*/ } from "react-redux";
import { Box, Grid, Switch } from "@mui/material";
//import InfiniteScroll from "react-infinite-scroller";
import { SpinnerBox, PaginationList, PageMessage } from 'core';
import AdmCSuggestCard from './AdmCSuggestCard';
//import { fGetRandomImgUrl } from 'lib/appFx';
import {
  useCSuggestListQuery,// useCSuggestListMultQuery,
  useCSuggestSetApprovalMutation,
  rGetCSFilterData, rSetCSFilter, rIncrementAcsSummaryData,
} from 'services/admin/admContentSuggestionsApi';
//---------------------------------------------------------

const detectCSPostType = (pData, sMKey='') => {
  //PENDING
  //[video, link(withLinkPreview), linkText,
  //	album(withText), textImg, image, text ]
  const vdoExt = ['mpg', 'mpeg', 'mp4'];
  const imgExt = ['jpg', 'jpeg', 'jiff', 'png', 'gif', 'webp',];
  let res = null;
  if(sMKey === 'yt' ||
    (Array.isArray(pData?.media?.videos) && pData.media.videos.length)
  ) {
    res = 'video';
  } /*else if (pData?.text && isValidUrl(pData?.url)) {
    res = pData.media.pictures[0] ? "linkText" : "link";
  }*/

  if(!res) {
    if(0) {
      //res = 'album'
      //} else if(pData?.media?.pictures[0]) {
    } else if (
      Array.isArray(pData?.media?.pictures) &&
      pData.media.pictures?.length &&
      pData.media.pictures[0]
    ) {
      res = pData?.text ? "textImg" : "image";
    } else if (pData?.text) {
      res = "text";
    }
  }
  return res;
};

const prepCSuggestPostCustomData = (cSPostData, status='pending', feedMeta=null) => {
  const pUser = {
    id: cSPostData?.suggester?.id,
    dispName: cSPostData?.suggester?.name || 'NiuSUser',
    //profilePic: cSPostData?.suggester?.media?.profile?.url,
    profilePic: Array.isArray(cSPostData?.suggester?.media?.profile) ?
      cSPostData?.suggester?.media?.profile[0]?.url :
      cSPostData?.suggester?.media?.profile?.url,
  };

  const postType = detectCSPostType(cSPostData);
  let pText = cSPostData?.text || '';
  if(pText.length && ['undefined', 'null'].includes(pText)) {
    pText = '';
  }
  let hasMedia = cSPostData?.media?.attachments;//?.url?.length;
  hasMedia = hasMedia.length && hasMedia[0]['url']?.length ? true : false;

  let pLeadImg, pLeadImgTitle;
  if(hasMedia) {
    pLeadImg = cSPostData?.media?.attachments[0]['url'];
    pLeadImg = pLeadImg.replace('/storage/storage/', '/storage/');
    pLeadImgTitle = cSPostData?.media?.attachments[0]['name'];
  } else {
    pLeadImg = pLeadImgTitle = '';
  }
  const pMedia = [];

  //const pStatus = cSPostData?.is_approved;
  const pStatus = status;
  const pApprover = cSPostData?.approver;//{id, dispName, approvalDateTime}
  const postReviewDateTime = pApprover?.reviewed_at ?
    pApprover.reviewed_at.replace('T', ' ') : null;
  const engPoints = 100;

  const custResp = {//pAlbumSrc, pVideoSrc,
    postId: cSPostData?.id,
    //groupId: cSPostData?.groupId,
    //srcSMId, srcKey,
    //pPageData: null,//feed?{title, dp}
    postDateTime: cSPostData?.created_at?.replace('T', ' '),//moment().format();
    postUpdateDateTime: cSPostData?.updated_at?.replace('T', ' '),
    postReviewDateTime, pApprover,
    pUser, postType, pText, hasMedia, pLeadImg, pMedia,
    pUrl: cSPostData?.url, engPoints,
    pStatus: pStatus,
    xApiData: cSPostData,
  };
  //if(cSPostData?.id === '97w6BYM1') { console.log('custRespSel', custResp); }
  return custResp;
}
const fGetCSApiFilterStatusVal = (dKey) => {
  let tglStatusVal;
  switch(dKey) {
    case 'rejected': tglStatusVal = '0'; break;
    case 'approved': tglStatusVal = '1'; break;
    case 'pending':
    default: tglStatusVal = null; break;
  }
  return tglStatusVal;
}
//---------------------------------------------------------

export default function AdmCSuggectList({ theme, }) {
  const fDispatchAcsSumUp = useDispatch();
  const fDispatchAcsLFilter = useDispatch();
  const [fSetACSPostStatus] = useCSuggestSetApprovalMutation();
  //{ data, isLoading: isRPtsUpdating, reset: fResetRPt }]

  const cSFilterStat = useSelector(rGetCSFilterData);
  const currPage = cSFilterStat.page;
  //const fPrefetchNextPage = usePrefetch('acsNextPage');
  const paginationMode = 'manual';//'auto'
  //console.log('cSFilterStat', cSFilterStat);

  /*const [stAcsDataOK, setStAcsDataOK] = useState(false);
  //const [stSelCspData, setStSelCspData] = useState(null);
  const [stAjaxStep, setStAjaxStep] = useState(0);//-1, 0, 1, 2
  const [stPageArgs, setStPageArgs] = useState({
    page: 1, filter: {status: 'pending', startDate: '', endDate: ''},
  });
  //const [stCSFeedPage, setStCSFeedPage] = useState(1);*/
  //const [stPage, setStPage] = useState(cSFilterStat?.page || 1);

  const fChangePageHandler = (event, value) => {
    fDispatchAcsLFilter(rSetCSFilter({key: 'page', val: value}));
    //myRef.current.scrollIntoView()
    //setTimeout(() => {
      //const fBar = document.getElementById('ctnAcsFilterStat');
      const fBar = document.getElementById('ctnAcsSum');
      if(fBar) {
        fBar.scrollIntoView({behavior: 'smooth'});
        //current.scrollIntoView({behavior: 'smooth'})
      }
    //}, 1000);
  };
  const admCSFeedApiArgs = { page: currPage, };
  admCSFeedApiArgs['filter'] = {};
  admCSFeedApiArgs['filter']['is_approved'] =
    parseInt(fGetCSApiFilterStatusVal(cSFilterStat.status));
  const { data: admCSApiData, //refetch: fRefetchAcsList,
    isLoading: admCSListWait, isFetching: admCSListWait2,
    isError: admCSListErr,//isSuccess: admCSSuccessApi,
  } = useCSuggestListQuery(//useCSuggestListMultQuery
    admCSFeedApiArgs, {refetchOnMountOrArgChange: true}
  );
  //admCSApiData && console.log('admCSApiData', admCSApiData);

  let contentSData;//, fetchErr;
  contentSData = admCSApiData?.feed; //cSDataTest;
  //----------------------

  const fSetCSPApprovalHandler = useCallback(async(actParams, fCallback, errCb) => {
    //console.log('actApprovalParams', actParams);
    let aCSPApproveResp;
    try {
      aCSPApproveResp = await fSetACSPostStatus(actParams);
      //aCSPApproveResp = {'result': true};
      //console.log('aCSPApproveResp', {actParams, aCSPApproveResp});

      //if(aCSPApproveResp.result === true) {
      if(aCSPApproveResp?.data?.result?.success === true) {
        aCSPApproveResp = {'result': true};
        //if(!aCSPApproveResp.hasOwnProperty('data')) {
        //  aCSPApproveResp['data'] = aCSPApproveResp;
        //}

        const upDataKey = actParams?.is_approved === 1 ?
          'approvedCount' : 'rejectedCount';
        fDispatchAcsSumUp(rIncrementAcsSummaryData({key: upDataKey}));
        //fRefetchAcsList();
      } else {
        aCSPApproveResp = { result: false, error: {code: 'acsApprovalFail', desc: ''}};
      }
    } catch(err) {
      console.log('ErrSetAcsApproval', {actParams, err});
      aCSPApproveResp = { result: false, error: {code: 'acsApprovalFail2', desc: err}};
    }
    return aCSPApproveResp;
  }, []);
  //----------------------
  //---------------------------------------------------------

  let oAcsCards = []; //const flgUseGrid = 10;
  contentSData && contentSData.forEach((pData) => {
    let flgShow = cSFilterStat.status === 'pending' ?
      (pData.is_approved === null ? true : false) : true;
    const cSPData = prepCSuggestPostCustomData(pData, cSFilterStat?.status);
    //if(pData?.id === '9PYGK1Z3') { console.log('pDataSel '+flgShow, {cSPData, pData});}

    flgShow && oAcsCards.push(
      <Grid item key={'acsP_'+cSPData.postId} className='xtBdr2'
        id={'ctnAcsP_'+cSPData.postId} xs={12 /*4*/}
      >
        <AdmCSuggestCard
          cSPData={cSPData}
          dDir={cSFilterStat?.status === 'pending' ? 'col' : 'xcol'}
          acsPMethods = {{ fSetCSPApproval: fSetCSPApprovalHandler, }}
        />
      </Grid>
    );
  });
  //console.log('AA', stCSFeedPage, oAcsCards.length);
  //---------------------------------------------------------
  /*<InfiniteScroll
    style={{ width: '100%', }}
    initialLoad={true}
    loadMore={loadMore}
    threshold={10}
    useWindow={true}
    hasMore={isFetchingData}
    datalength={Object.keys(feedData).length}
    loader={<SpinnerBox />}
  >*/

  let pageContent, pageGT = admCSApiData?.pagination?.page?.total || 1;
  if(admCSListWait || admCSListWait2) {
    pageContent = <SpinnerBox />;
  } else if(admCSListErr) {
    pageContent = (
      <PageMessage size='M' isError={true}
        text={'Error fetching User Submissions! Please try again later..'}
      />
    );
  } else if(contentSData) {
    let pageFoot;
    if(currPage === 1 && oAcsCards.length === 0) {
      pageFoot = (
        <Box mt={2}>
          <PageMessage size='M'
            text={'No ' + cSFilterStat.status + ' user submissions found'} />
        </Box>
      );
    } else if(pageGT > 1) {//paginationMode === 'manual'
      pageFoot = (
        <Box className='xtBdr2'>
          <PaginationList pageArgs={{
            pageCount: pageGT, currPage: currPage,
            onPageChange: fChangePageHandler,
          }}/>
          {/*<Pagination count={pageGT} page={currPage}
            onChange={fChangePageHandler}
          />*/}
        </Box>
      );
    } else if(currPage >= pageGT) {
      pageFoot = (
        <Box mt={4}>
          <PageMessage text={'That\'s all we have got'} size='M'/>
        </Box>
      );
    }

    pageContent = (
      <>
        <Grid container //spacing={3} //spacing={{xs: 2, md: 3 }}
          //flexDirection='row' flexDirection='column'
          rowSpacing={1.5}
          columnSpacing={1}//{{ xs: 1, sm: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
          justifyContent={'left'}//'center'
          alignItems={'top'}//'center'
        >
          {oAcsCards}
        </Grid>
        {pageFoot}
      </>
    );
  } else {
    pageContent = <SpinnerBox />;
  }
  //---------------------------------------------------------

  return (
    <Box id='ctnAcsLst' className='xflxMid xbdr1'
      sx={{//alignSelf: { xs: 'flex-start', sm: 'center' },
        flexGrow: 1, marginTop: '1rem',
      }}
    >
      {pageContent}
    </Box>
  )
};
//---------------------------------------------------------

/*const uData = {id: 1, name:'niu User1', profilePic:''};
const cSDataTest = [
  {id: 1, user: uData, smKey:'fb', pText: 'text ', pImg:fGetRandomImgUrl()},
  {id: 2, user: uData, smKey:'tw', pText: 'text ', pImg:fGetRandomImgUrl()},
  {id: 3, user: uData, smKey:'ln', pText: 'text ', pImg:fGetRandomImgUrl()},
  {id: 4, user: uData, smKey:'fb', pText: 'text ', pImg:''},
  {id: 5, user: uData, smKey:'ig', pText: 'text ', pImg:fGetRandomImgUrl()},
  {id: 6, user: uData, smKey:'fb', pText: 'text ', pImg:''},
  {id: 7, user: uData, smKey:'fb', pText: 'text ', pImg:fGetRandomImgUrl()},
  {id: 8, user: uData, smKey:'fb', pText: 'text ', pImg:''},
];*/