import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { IconButton, InputAdornment, Stack, styled } from '@mui/material';
//import { grey } from '@mui/material/colors';
//import VisibilityIcon from '@mui/icons-material/Visibility';
//import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Button, FormInputField, FormProvider, Typography } from 'core';
import { ResetPasswordSchema } from 'schema';
import { useResetPasswordMutation } from 'services/auth/authApi';
import { NIU_HEALTH_BASE_URL } from 'config/app.config';
//---------------------------------------------------------

const InputField = styled(FormInputField)(({ theme }) => ({
  outline: `1px solid ${theme.palette.primary.main}`,
}));

const Label = styled(Typography)(({ theme }) => ({
  maxWidth: '25vw',
  [theme.breakpoints.down('md')]: {
    fontSize: '18px',
    maxWidth: '90vw',
  },
}));

const FormContainer = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(6),
  textAlign: 'left',
  [theme.breakpoints.down('md')]: {
    gap: theme.spacing(4),
  },
}));
//---------------------------------------------------------

export default function ResetPasswordForm({ theme }) {
  const [resetPassword] = useResetPasswordMutation();
  const defaultValues = {
    email: '',
    password: '',
  };

  const methods = useForm({
    resolver: yupResolver(ResetPasswordSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmit = async (data) => {
    let formData = new FormData();
    formData.append('email', data.email);
    formData.append('password', data.password);
    await resetPassword(formData);
  };
  const isError = (fieldName) => (errors[fieldName] ? theme.palette.error.light : theme.palette.primary.main);
  const [isPasswordVisible, setPasswordVisible] = useState(false);

  const changePasswordState = () => setPasswordVisible((prev) => !prev);
  const [isConfirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

  const changeConfirmPasswordState = () => setConfirmPasswordVisible((prev) => !prev);

  /*Redirect the user to NIU Health login page before the form is visible*/
  window.location.href = NIU_HEALTH_BASE_URL;

  return (
    <></>
    // <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
    //   <FormContainer>
    //     <Stack spacing={2}>
    //       <Label size="medium" color_variant="lightGrey" maxWidth="25vw">
    //         Please enter the ONE TIME PASSWORD send to your registered email address*
    //       </Label>
    //       <InputField
    //         name="email"
    //         type="email"
    //         InputProps={{
    //           style: {
    //             backgroundColor: (theme) => theme.palette.background.paper,
    //             minWidth: '25vw',
    //           },
    //         }}
    //       />
    //     </Stack>

    //     <Stack spacing={2} textAlign="left">
    //       <Label size="medium" color_variant="lightGrey" maxWidth="25vw">
    //         Enter new password*
    //       </Label>
    //       <InputField
    //         name="password"
    //         type={isPasswordVisible ? 'text' : 'password'}
    //         InputProps={{
    //           style: {
    //             backgroundColor: (theme) => theme.palette.background.paper,
    //             minWidth: '25vw',
    //           },
    //           endAdornment: (
    //             <InputAdornment position="end">
    //               <IconButton size="small" onClick={changePasswordState}>
    //                 {!isPasswordVisible ? <VisibilityOffIcon color={isError('password')} /> : <VisibilityIcon color={isError('password')} />}
    //               </IconButton>
    //             </InputAdornment>
    //           ),
    //         }}
    //       />
    //     </Stack>
    //     <Stack spacing={2} textAlign="left">
    //       <Label size="medium" color_variant="lightGrey" maxWidth="25vw">
    //         Confirm new password*
    //       </Label>
    //       <InputField
    //         name="confirm_password"
    //         type={isConfirmPasswordVisible ? 'text' : 'password'}
    //         InputProps={{
    //           style: {
    //             backgroundColor: (theme) => theme.palette.background.paper,
    //             minWidth: '25vw',
    //           },
    //           endAdornment: (
    //             <InputAdornment position="end">
    //               <IconButton size="small" onClick={changeConfirmPasswordState}>
    //                 {!isConfirmPasswordVisible ? <VisibilityOffIcon color={isError('password')} /> : <VisibilityIcon color={isError('password')} />}
    //               </IconButton>
    //             </InputAdornment>
    //           ),
    //         }}
    //       />
    //     </Stack>

    //     <Stack spacing={3}>
    //       <Button variant="contained" type="submit" size="large" sx={{ color: (theme) => theme.palette.background.paper }}>
    //         RESET PASSWORD
    //       </Button>
    //       <Button
    //         variant="contained"
    //         type="submit"
    //         size="large"
    //         sx={{
    //           background: '#DCE4E7',
    //           '&:hover': { background: grey[300] },
    //           color: grey[600],
    //         }}
    //       >
    //         CANCEL
    //       </Button>
    //     </Stack>
    //   </FormContainer>
    // </FormProvider>
  );
}
