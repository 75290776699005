import { Link, useLocation } from "react-router-dom";
import { Box, Avatar, } from "@mui/material";
import moment from "moment";
import { SA_DEF_ORG_GROUP_ID, } from 'config/app.config';
import { fStrToTitleCase, fStrToColor, fGetStrAbbr } from 'lib/appFx';
import { Typography } from 'core';
import AdmGroupCardHeadMenu from './AdmGroupCardHeadMenu';
//---------------------------------------------------------

const grpIconSx = {
  display: 'flex', justifyContent: 'center', alignItems: 'center',
  //textAlign: 'center', verticalAlign: 'middle'
  borderRadius: '50%', height: '2rem', width: '2rem',
  background: '#e9e9e9', color: '#00406b',
};
const grpTitleSx = {
  paddingTop: '0.1rem', fontSize: '1.3rem', fontWeight: '600',
  color: '#00406b',
};
const defGrpTitleSx = {...grpTitleSx, color: '#c5353a'};
const grpDescSx = {
	paddingTop: '0.5rem', fontSize: '0.8rem', color: '#9e9e9e',
	maxWidth: {xs: '6rem', vsm: '8rem', sm: '9rem', md: '7rem', lg: '11rem'},
	maxHeight: '1.7rem', overflow: 'clip', //overFlowY: 'clip',	  
};
//---------------------------------------------------------

export default function AdmGroupCardHead({theme, groupData, groupMethods}) {
  //console.log('groupDataCHd', groupData);
	const fEditGroup=() => {}
  const rBG = fStrToColor(groupData.name);
	//---------------------------------------------------------
  
	return (
	  <Box sx={{display:'flex', flexWrap: 'wrap', flexGrow: 1, alignItems: 'top'}}>
      <Box sx={{display: 'flex', marginRight: 'auto'}}>
        {/*<Typography sx={grpIconSx} className='xfFam'>&nbsp;</Typography>*/}
        <Typography sx={grpIconSx} className='xfFam' component='div'>
          <Avatar className='dImg' sx={{bgcolor: rBG}}
            alt={groupData.name} title={groupData.name}
            src={groupData?.banner1}
          >{fGetStrAbbr(groupData.name)}</Avatar>
        </Typography>
        <Box sx={{marginLeft: '1rem'}}>
          <Typography className='lnkPt xfFam' onClick={fEditGroup}
            sx={groupData.id === SA_DEF_ORG_GROUP_ID ? defGrpTitleSx : grpTitleSx}
          >
            {fStrToTitleCase(groupData.name)}
          </Typography>
          <Typography sx={grpDescSx}>
            Last Active: {moment().format('MMM DD, h:mm A')}
          </Typography>
        </Box>
      </Box>
      <Box sx={{display: 'flex', }}>
        <AdmGroupCardHeadMenu groupData={groupData} groupMethods={groupMethods} />
      </Box>
	  </Box>
	);
  
	/*return (//<Box sx={{}}>{groupData.id+': '+groupData.name}</Box>
	  <Grid container spacing={3}
		//direction='row' columns={12} justify='space-between'
		flexDirection='row'
		alignItems='top'
		wrap='nowrap'
	  >
		<Grid item>{groupData.name}</Grid>
		<Grid item sx={{marginLeft: 'auto'}}>Menu</Grid>
	  </Grid>
	);*/
  };