import { /*useMemo,*/ useEffect, useState } from 'react';
import {
  Stack, //Box, IconButton,
  Grid,
  styled,
  Avatar,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import '../style.css';
import CreatePostForm from './CreatePostForm'; //4QuoteShare
import SchedulePostForm from './SchedulePostForm';
import PostCommandBarSelf from './PostCommandBarSelf';
import PostCommandBarAll from './PostCommandBarAll';
import { Typography } from 'core';
import Expire from 'core/Expire';
//---------------------------------------------------------

const StyledInputComment = styled('input')(({ theme }) => ({
  padding: '0.7rem',
  width: '100%',
  outlineColor: grey[400],
  border: `1px solid ${grey[400]}`,
  '&::placeholder': { color: theme.palette.secondary.dark, fontWeight: 600 },
}));
function CommentsSection({ profile, commentBoxId }) {
  return (
    <Stack rowGap={2}>
      <Stack direction="row" spacing={2} alignItems="center">
        <Avatar src={profile} alt="" />
        <StyledInputComment
          placeholder="Leave a comment..."
          id={commentBoxId}
        />
      </Stack>
    </Stack>
  );
}
//---------------------------------------------------------

export default function PostCommandBar({
  theme,
  feedListMeta,
  postCustomData: pInf,
  feedData,
  fFeedActionHandler, //onFeedEngagement,
  defEngPoints,
  showCongratsFeedForm,
  setShowCongratsFeedForm,
}) {
  //const sMMeta = feedListMeta?.sMediaMgr;
  //const sMIdKeyMap = feedListMeta?.sMIdKeyMap;
  //const feedListModifiers = feedListMeta?.feedListModifiers;
  const authUserData = feedListMeta?.authUserData;
  const commandBarSMs = ['fb', 'tw', 'ln', 'em', 'sc', 'wa'];
  const commentBoxId = 'txComment_' + pInf.srcKey + '_' + pInf.postId;
  const { comments } = feedData;
  //----------------------

  const [showCreateFeedForm, setShowCreateFeedForm] = useState(false);
  const [showScheduleFeedForm, setShowScheduleFeedForm] = useState(false);
  const [showFacebookFeed, setShowFacebookFeed] = useState(false);
  const [currentSm, setCurrentSm] = useState();
  function handleFacebookFeed() {
    setShowFacebookFeed((prev) => !prev);
  }

  function handleCreateFeedForm(e) {
    setShowCreateFeedForm((prev) => !prev);
    setCurrentSm(e);
  }

  function handleScheduleFeedForm() {
    setShowScheduleFeedForm((prev) => !prev);
  }
  const [message, setMessage] = useState();
  // useEffect(() => {
  // 	let debounceTimer;
  // 	const txCommentEl = document.getElementById(commentBoxId);
  // 	txCommentEl.addEventListener("keydown", (e) => {
  // 		debounceTimer = setTimeout(() => {
  // 			if(e.key === "Enter") {
  // 				handleSocialEngagementActions({
  // 					action: "comment",
  // 					pText: e.target.value,
  // 				});
  // 			}
  // 		}, 1000);
  // 	});
  // 	return () => { clearTimeout(debounceTimer); };
  // }, []);
  //---------------------------------------------------------

  return (
    <>
      {/*SelfCmdBar*/}
      <PostCommandBarSelf
        pInff={pInf}
        setMessage={setMessage}
        theme={theme}
        feedListMeta={feedListMeta}
        postCustomData={pInf}
        feedData={feedData}
        //fFeedActionHandler={fFeedActionHandler}
        defEngPoints={defEngPoints}
      />
      {/*EndSelfCmdBar*/}
      {message && (
        <Expire
          resetFunction={() => {
            setMessage(null);
          }}
          delay={3000}
        >
          <Typography align="center">{message}</Typography>
        </Expire>
      )}

      {/*CrossPlatformCmdBar*/}
      <Grid container alignItems="center">
        <Grid item xs={12}>
          <PostCommandBarAll
            setMessage={setMessage}
            theme={theme}
            feedListMeta={feedListMeta}
            postCustomData={pInf}
            feedData={feedData}
            fFeedActionHandler={fFeedActionHandler}
            defEngPoints={defEngPoints}
            commandBarSMs={commandBarSMs}
            handleScheduleFeedForm={handleScheduleFeedForm}
            handleFacebookFeed={handleFacebookFeed}
            handleCreateFeedForm={handleCreateFeedForm}
          />
        </Grid>
      </Grid>
      {/*EndCrossPlatformCmdBar*/}

      {/*CommentInput*/}
      {/* <Grid container rowGap={2}>
				<Grid item xs={12}></Grid>
				<Grid item xs={12}>
					<CommentsSection
						comments={comments}
						profile={pInf.aUserPic}
						commentBoxId={commentBoxId}
					/>
				</Grid>
			</Grid> */}
      {/*EndCommentInput*/}

      {/*FeedActionModals*/}
      <CreatePostForm
        showCongratsFeedForm={showCongratsFeedForm}
        setShowCongratsFeedForm={setShowCongratsFeedForm}
        currentSm={currentSm}
        showCreateFeedForm={showCreateFeedForm}
        onCloseCreateFeedForm={handleCreateFeedForm}
        feedData={feedData}
        shareOn={pInf.srcSMTitle}
        srcSMKey={pInf.srcKey}
        pInff={pInf}
        // destSMKey={srcKey}
        fFeedAct={fFeedActionHandler}
        authUser={authUserData}
        setMessage={setMessage}
      />
      <SchedulePostForm
        key={pInf.postId}
        theme={theme}
        showScheduleFeedForm={showScheduleFeedForm}
        onCloseScheduleFeedForm={handleScheduleFeedForm}
        post={feedData}
        shareOn={pInf.srcSMTitle}
        srcSMKey={pInf.srcKey}
        fFeedAct={fFeedActionHandler}
      />
      {/*EndFeedActionModals*/}
    </>
  );
}
