import { HOME_BASE_URL, SA_APP_NAME, NIU_HEALTH_PRIVAY_POLICY_URL,
} from 'config/app.config';
import { fTrimSlashes, fGetSlugToTitle, } from 'lib/appFx';
//---------------------------------------------------------

const ROOT = '';//HOME_BASE_URL
const ADM_ROOT = '/admin';//HOME_BASE_URL+'/admin'
function path(root, sublink) { return `${root}${sublink}`; }
//----------------------

export const COMM_ROUTES = {///ex-party-resp //ExPRespHandler
  SMConnectResp: {url: '/social-media-connect', title: ''},
  AwsAuth: {url: '/aws-auth', title: ''},
  NiuHandshake: {url: '/social-treaty', title: ''},
  ExPartyResp: {url: '/tparty-comm-resp', title: ''},
  //ExPartyResp: {url: '/third-party-resp', title: ''},
};
//----------------------

export const AUTH_ROUTES = {
  Root: { url: ROOT, title: '' },
  SignIn: { url: path(ROOT, '/signin'), title: 'Sign In' },
  SignUp: { url: path(ROOT, '/signup'), title: 'Sign Up' },
  Verify: { url: path(ROOT, '/verify'), title: 'Verify-account' },
  ForgotPassword: {
    url: path(ROOT, '/forgot-password'),
    title: 'Forgot Password',
  },
  ResetPassword: {
    url: path(ROOT, '/reset-password'),
    title: 'Reset Password',
  },//['tparty-comm-resp', 'social-media-connect']
};
//----------------------

export const HOME_ROUTES = {//USER_ROUTES
  Root: ROOT,
  Home: { url: path(ROOT, '/home'), title: 'Home' },
  Settings: { url: path(ROOT, '/settings'), title: 'Settings' },
  Badges: { url: path(ROOT, '/badges'), title: 'Badges' },
  Help: { url: path(ROOT, '/help'), title: 'Help' },
  Leaderboard: { url: path(ROOT, '/leaderboard'), title: 'Leaderboard' },
  Faq: { url: path(ROOT, '/faq'), title: 'Faq' },
  Submit: { url: path(ROOT, '/submit'), title: 'Submit' },
  Discoverd: { url: path(ROOT, '/discoverd'), title: 'Discoverd' },
  Notification: { url: path(ROOT, '/notification'), title: 'Notification' },
  Profile: { url: path(ROOT, '/profile'), title: 'Profile' },
  PrivacyPolicy: { url: NIU_HEALTH_PRIVAY_POLICY_URL, title: 'Privacy Policy' },
};
//----------------------

const admRoutes = {
  Root: ADM_ROOT,
  Home: { url: '/home', title: 'Admin Home' },
  ContentSuggestions: { url: '/content-suggestions', pageComp: '' },
  Groups: { url: '/groups', pageComp: '' },
  GroupDetails: { url: '/group-details/:title', pageComp: '' },
  GroupCreate: { url: '/create-group', pageComp: '' },
  ManageFeeds: { url: '/manage-feeds', pageComp: '' },
  RewardPoints: { url: '/reward-points', pageComp: '' },
  Users: { url: '/users', pageComp: '' },
  Broadcasts: { url: '/broadcasts', pageComp: '' },
};
Object.keys(admRoutes).forEach((rK) => {
  if(rK !== 'Root') {
    let pEl = admRoutes[rK];
    pEl['title'] = pEl?.title ?? fGetSlugToTitle(fTrimSlashes(pEl.url));
    pEl['isLazy'] = pEl?.isLazy ?? (!['Root', 'Home'].includes(rK));
    pEl['url'] = ADM_ROOT + pEl.url;
  }
});//console.log('adminRoutes', admRoutes);
export const ADMIN_ROUTES = admRoutes;
//----------------------

export const ERROR_ROUTES = {
  Page403: { url: ROOT+'/403', title: 'Err-403', pageComp: '', isLazy: true },
  Page404: { url: ROOT+'/404', title: 'Err-404', pageComp: '', isLazy: true },
  Page500: { url: ROOT+'/500', title: 'Err-500', pageComp: '', isLazy: true },
};

