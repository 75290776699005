import { sSApi } from './sSApi';
//---------------------------------------------------------

export const userApi = sSApi.injectEndpoints({
	endpoints: builder => ({
		// register: builder.mutation({
		// 	query: (params) => {
		// 		return {
		// 			url: '/user/register',
		// 			method: 'POST',
		// 			body: params,
		// 		};
		// 	},
		// }),
		resetPassword: builder.mutation({
			query: (params) => {
				return {
					url: '/user/update',
					method: 'POST', //PUT/PATCH
					body: params,
				};
			},
		}),
		userDetailsById: builder.query({
			query: (params) => '/user/' + params.userId,

			transformResponse: (response, _, arg) => {
				if (0) {//TheRightWay
					response?.users && (response = response.users[0]);
					arg?.orgId && (response['orgId'] = arg.orgId);
				} else {
					arg?.orgId && (response.users[0]['orgId'] = arg.orgId);
				}
				return response;
			},
		}),
		userUpdate: builder.mutation({
			query: ({ userId, params }) => {
				return {
					url: `/user/${userId}`,
					method: 'POST',
					body: params,
				};
			},
			transformResponse: (response, meta, arg) => {
				return meta?.response?.status
			}
		}),
		deleteUser: builder.query({
			query: (params) => {
				return {
					url: '/user/' + params.userId,
					method: 'DELETE',
				};
			},
		}),
		addGroup: builder.mutation({
			query: (params) => {
				let aGQ = {
					url: '/group/' + params.group_id + "/member",
					method: 'POST',
					body: params.body,
					//headers: { Authorization: '' }
				}
				if(params?.authToken) {
					/*aGQ.prepareHeaders = (headers) => {
						headers.set('Authorization', `Bearer ${params?.authToken}`);
						return headers
					}*/
					aGQ.headers = { Authorization: `Bearer ${params?.authToken}` }
				}
				return aGQ;
			},
		}),
	}),
});
//---------------------------------------------------------

export const {
	useRegisterMutation,
	useResetPasswordMutation,
	useUserDetailsByIdQuery,
	useLazyUserDetailsByIdQuery,
	useUserUpdateMutation,
	useDeleteUserMutation,
	useAddGroupMutation
} = userApi;

