import { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
//import { useSelector, useDispatch } from "react-redux";
import {
  useForm, //Controller, FormProvider, useFormContext
} from "react-hook-form";
//import * as yup from "yup";
import { styled, Box, TextField, Button, } from "@mui/material";
import {
  FormProvider, SpinnerBox, Spinner2, Flasher,
  PageMessage, //Typography,
} from 'core';
//import { fGetNumAbbr, fFormatNumberToLocaleString } from 'lib/appFx';
import { fStrHashTagsToArray, fGetStrSlug, } from 'lib/appFx';
import {
  useGroupCreateMutation, useLazyGroupDetailsQuery,
} from 'services/admin/admGroupsApi';
import { useTranslation } from "react-i18next";
//---------------------------------------------------------

const ctnInputSx = {
  display: 'flex', flexWrap: 'wrap', justifyContent: 'left',//'space-between'
  alignItems: 'center', margin: '1.5rem 0'
};
const ctnInLabelSx = {
  display: 'inline-flex', //flex: '1 0 auto', width: '100%',
  flexWrap: 'wrap', marginBottom: '0.5rem',
  justifyContent: 'left', alignItems: 'center',
  fontSize: '1.1rem', whiteSpace: 'nowrap',
  width: { xs: '98%', sm: '45%' }
};
//const inputTextSx = { width: '97%', "& fieldset": { border: 'none' }};
/*const StyledTextArea = styled('textarea')(({ theme }) => ({
  height: 300, border: 'none', padding: '1rem',
  fontSize: '15px', letterSpacing: '0.5px',
  fontFamily: "'Roboto','Helvetica','Arial',sans-serif",
  '&:focus::placeholder': { color: 'transparent', },
}));*/
//---------------------------------------------------------

export default function AdmGroupCreateForm({ theme, groupData = {}, }) {
  //---------------------------------------------------------
  //console.log('AGDSumGrpData', groupData);
  //const frmSetGroupMode = groupData?.id ? 'edit' : 'add';
  const navigate = useNavigate();
  const { t: home } = useTranslation('home')
  const [stAjaxStep, setStAjaxStep] = useState(0);//-1:Err, 0: Nop, 1:wait, 2: OK
  const [stPgMsg, setStPgMsg] = useState('');
  const [fCreateGroup] = useGroupCreateMutation();
  /*const [
    fUpdateGroup
    //, { data: setEngPtsResp, isLoading: isRPtsUpdating, reset: fResetRPt }
  ] = useGroupUpdateMutation();*/
  const [fGroupDetails] = useLazyGroupDetailsQuery({ groupId: null });

  const fRestoreAjax = () => {
    setStPgMsg(''); setStAjaxStep(0);
  };
  const inElPrefix = '';//'txGrp_';
  const defGrpVals = {};
  const objYupSchema = {};
  let refDestSM = [];
  /*refDestSM.forEach((rPK)=> {
    objYupSchema[inElPrefix + rPK] = yup.number().positive().
      integer().min(0).max(maxRPts).required();
      //.pattern(//)
  });*///console.log('objYupSchema', objYupSchema);
  //const groupSchema = AdmGroupSchema;
  //const AdmGroupSchema = yup.object().shape(objYupSchema);
  //const AdmGroupSchema = yup.object(objYupSchema).required();
  const frmSetGroupMethods = useForm({
    //resolver: yupResolver(rPtsSchema),
    defaultValues: defGrpVals,//async()=>fetch('apiUrl');
    //values,
    //resetOptions: { keepDirtyValues: true, keepErrors: true, }
    //shouldUseNativeValidation: true,
  });
  const {//handleSubmit, control, setValue, watch, resetField, clearErrors,
    register, getValues, reset: frmReset, formState: { errors: frmErrs },
  } = frmSetGroupMethods;
  //---------------------------------------------------------

  const fResetGroupForm = () => { frmReset(); };
  const fSaveGroup = //useCallback(
    async () => {
      const inData = getValues();//e.preventDefault();

      if (inData?.name.length > 2) {
        let arrHashFilter;
        setStAjaxStep(1);
        //setStPgMsg('Please wait. It may take a while..');
        const inParams = {
          name: inData?.name, //hash_filter: arrHashFilter,//banner1: '',
        };
        if (inData?.hash_filter.length) {
          arrHashFilter = fStrHashTagsToArray(inData?.hash_filter);
          inParams['hash_filter'] = JSON.stringify(arrHashFilter);
        }

        try {
          let aResp;
          aResp = await fCreateGroup({ params: inParams }).unwrap();

          //if(aResp?.result?.success) {
          if (aResp?.group_id.length) {
            const txMsg = 'The group \'' + inData.name +
              '\' created successfully.';
            //'<br/>you can now add members to the group..'
            setStPgMsg(txMsg);
            setTimeout(fRestoreAjax, 3000);
            let groupDetail = await fGroupDetails({
              groupId: aResp.group_id,
              //orgId: SA_DEF_ORG_ID,
            }).unwrap();
            //console.log('newGroupDetail', groupDetail);
            if (groupDetail) {
              groupDetail = groupDetail?.groups[0];
              navigate('/admin/group-details/' + fGetStrSlug(groupDetail.name),
                { state: { groupId: groupDetail.id, groupData: groupDetail } });
            } else {
              navigate('admin/groups');
            }
          } else if (aResp?.error) {
            setStPgMsg(aResp?.error?.message);
          } else {
            setStPgMsg(`${home("error_creating_group..")}` + "\n" + `${home("please_try_again..")}`);
            setStAjaxStep(-1);
          }
        } catch (err) {
          console.error(err, 'Error creating group');
          let eMsg;
          if (err?.status === 403) {
            eMsg = err?.data?.error?.message;
          }
          if (!eMsg) {
            eMsg = `${home("error_creating_group..")}` + "\n" + `${home("please_try_again..")}`;
          }
          setStPgMsg(eMsg);
          setStAjaxStep(-1);
        }

        setTimeout(fRestoreAjax, 3000);
      }
    }
  //, [rEngPtsRef, refSrcSM]);
  //---------------------------------------------------------

  const f1 = 'name', f2 = 'hash_filter';//banner1
  const frmSetGroupJsx = (
    <Box className='xtBdr2'>
      <Box sx={ctnInputSx}>
        <Box className='xdInFNoW xbdr1' sx={ctnInLabelSx}>
          {home('name')}
        </Box>
        <Box className='xbdr1'
          sx={{ width: { xs: '98%', md: '54%' }, height: '2.5rem' }}
        >
          <TextField //type="text"
            id={inElPrefix + f1}
            {
            ...register(inElPrefix + f1, {
              required: ` ${home("group_name_is_required")}`,
              minLength: { value: 1, message: 'mustMinLenErr' },
              maxLength: 12, pattern: /^[A-Za-z]+$/i
            })
            }
            //defaultValue={refDefVal} //value={refDefVal} //onChange={fSetRPtVal}
            required={true} fullWidth={true}
            aria-invalid={frmErrs[inElPrefix + f1] ? true : false}
            variant="filled" size='small'
            label='' //placeHolder={'name'}
            InputLabelProps={{ shrink: true, }}
          />
          <p>{frmErrs[inElPrefix + f1]?.message}</p>
        </Box>
      </Box>

      <Box sx={ctnInputSx}>
        <Box className='xdInFNoW xbdr1' sx={ctnInLabelSx}>
          {home('content_topic')}
        </Box>
        <Box className='xbdr1'
          sx={{ width: { xs: '98%', md: '54%' }, height: '2.5rem' }}
        >
          <TextField //or use StyledTextArea from SubmitContent
            id={inElPrefix + f2}
            multiline minRows={3} maxRows={3}
            {
            ...register(inElPrefix + f2, {
              //required: ' is required',
              //minLength: {value: 1, message: 'mustMinLenErr'},
              //maxLength: 12, pattern: /^[A-Za-z]+$/i
            })
            }
            //defaultValue={refDefVal} //value={refDefVal} //onChange={fSetRPtVal}
            required={false} fullWidth={true}
            aria-invalid={frmErrs[inElPrefix + f2] ? true : false}
            variant="filled" size='small'
            label='' //placeHolder={'Comma separated hash tags'}
            InputLabelProps={{ shrink: true, }}
          //InputProps={{ classes: {input: cls1}, style: {} }}
          />
          <p>{frmErrs[inElPrefix + f2]?.message}</p>
        </Box>
      </Box>
    </Box>
  );
  //---------------------------------------------------------

  return (
    true ? (
      <FormProvider methods={frmSetGroupMethods} disabled>
        <Box id="ctnFrmAdmGrp">
          <Box className="xtBdr2" //component="form" noValidate autoComplete="off"
            sx={{
              width: { xs: '100%', md: '55%' },
              //'& .MuiTextField-root': { m: 1, width: '25ch' },
            }}
          >
            {frmSetGroupJsx}
          </Box><br />
          <Box mt={'3rem'} sx={{}}>
            <Box sx={{ width: { xs: '100%', md: '55%' }, padding: '0.5rem', alignItems: 'center' }}>
              {stPgMsg && (
                <Flasher delay={3000} resetFunction={fRestoreAjax}>
                  <PageMessage text={stPgMsg} isError={stAjaxStep === -1} />
                </Flasher>
              )}
            </Box>
            <Button variant="text" className="cBtn cBtnWh" sx={{ marginRight: '1rem' }}
              onClick={fResetGroupForm}
            >Discard</Button>
            <Button variant="text" type="button" className="cBtn cBtnDark"
              disabled={stAjaxStep !== 0} onClick={fSaveGroup}
            >
              {stAjaxStep === 1 ? <Spinner2 /> : `${home('save')}`}
            </Button>
          </Box><br />
        </Box>
      </FormProvider>
    ) : <><SpinnerBox /></>
  );
};
