import { configureStore } from "@reduxjs/toolkit";
//import { setupListeners } from "@reduxjs/toolkit/query";
//import { persistReducer } from "redux-persist";
//import storage from "redux-persist/lib/storage";
import { sSApi } from 'services/sSApi';
import orgReducer, { orgApi } from 'services/orgApi';
import authReducer from 'services/auth/authSlice';
import { userApi } from 'services/userApi';
import sMediaReducer, { sMediaApi } from 'services/sMediaApi';
import orgFeedReducer, { orgFeedApi } from 'services/orgFeedApi';
import gameReducer, { gameApi } from 'services/gameApi';
import admGamingReducer, { admGamingApi } from 'services/admin/admGamingApi';
import { badgesApi } from 'services/badgesApi';
import { categoryApi } from 'services/categoryApi';
import { contentSuggestionApi } from 'services/contentSuggestionApi';
import { admGroupsApi } from 'services/admin/admGroupsApi';
import admCSuggestReducer, { admContentSuggestionsApi, admCSuggestStatReducer,
} from 'services/admin/admContentSuggestionsApi';
//---------------------------------------------------------

//const appPersistConfig = { key: 'root', storage, whitelist: ['orgReducer', 'authReducer'] }
//const persistedReducer = persistReducer(appPersistConfig, orgReducer, authReducer);

const appStore = configureStore({
	//reducer: persistedReducer,
	//middleware: [thunk]

	reducer: {
    [sSApi.reducerPath]: sSApi.reducer,
    [orgApi.reducerPath]: orgApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [sMediaApi.reducerPath]: sMediaApi.reducer,
    [orgFeedApi.reducerPath]: orgFeedApi.reducer,
    [badgesApi.reducerPath]: badgesApi.reducer,
    [admGroupsApi.reducerPath]: admGroupsApi.reducer,
    [categoryApi.reducerPath]: categoryApi.reducer,
    [contentSuggestionApi.reducerPath]: contentSuggestionApi.reducer,
    [admGamingReducer.reducerPath]: admGamingApi.reducer,
    org: orgReducer,
    auth: authReducer,
    sMedia: sMediaReducer,
    orgFeed: orgFeedReducer,
    game: gameReducer,
    admCSuggest: admCSuggestReducer,
    admGaming:admGamingReducer,
    admCSuggestStat: admCSuggestStatReducer,
	},

	middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false })
		.concat([
			sSApi.middleware,
			orgApi.middleware,
			userApi.middleware,
			sMediaApi.middleware,
			orgFeedApi.middleware,
			gameApi.middleware,
			badgesApi.middleware,
			admGroupsApi.middleware,
			categoryApi.middleware,
			contentSuggestionApi.middleware,
			admContentSuggestionsApi.middleware,
			admGamingApi.middleware,
		]),
});

export default appStore;
//export const persistor = persistStore(appStore);
