import { Grid, useTheme } from "@mui/material";
import { PageContainer } from "core";
import React, { useState } from "react";
import ImageAndSearch from "sections/faq/ImageAndSearch";
import FaqQus from "sections/faq/FaqQus";
import { NIU_HEALTH_PRIVAY_POLICY_URL } from "config/app.config";
import { useTranslation } from "react-i18next";



export default function Faq({ title }) {
  const theme = useTheme();
  const { t: home } = useTranslation('home')
  const faqData = [
    {
      sectionTitle: `${home('registration_and_login')}`,
      qas: [
        {
          question: `${home('how_do_i_create_an_account')}`,
          answer: `${home('create_an_account_answer')}`,
        },
        {
          question: `${home('trouble_logging_in')}`,
          answer: `${home('trouble_logging_in_answer')}`,
        },
      ],
    },
    {
      sectionTitle: `${home('social_network_connections')}`,
      qas: [
        {
          question: `${home('how_do_i_link_my_social_media_accounts')}`,
          answer: `${home('link_social_media_accounts_answer')}`,
        },
        {
          question: `${home('can_i_unlink_my_social_media_account_later')}`,
          answer: `${home('unlink_social_media_account_later_answer')}`,
        },
      ],
    },
    {
      sectionTitle: `${home('engagement_related_faqs')}`,
      qas: [
        {
          question: `${home('how_can_i_increase_my_engagement')}`,
          answer: `${home('increase_engagement_answer')}`,
        },
        {
          question: `${home('what_types_of_content_get_the_most_engagement')}`,
          answer: `${home('content_for_engagement_answer')}`,
        },
      ],
    },
    {
      sectionTitle: `${home('coins_related_faqs')}`,
      qas: [
        {
          question: `${home('how_do_i_earn_coins')}`,
          answer: `${home('earn_coins_answer')}`,
        },
        {
          question: `${home('can_i_use_coins_for_rewards')}`,
          answer: `${home('use_coins_for_rewards_answer')}`,
        },
      ],
    },
    {
      sectionTitle: `${home('other_faqs')}`,
      qas: [
        {
          question: `${home('how_do_i_delete_my_account')}`,
          answer: `${home('delete_account_answer')}`,
        },
        {
          question: `${home('where_can_i_find_the_terms_and_conditions')}`,
          answer: `${home('terms_and_conditions_answer')}`,
        },
        {
          question: `${home('how_do_i_contact_customer_support')}`,
          answer: `${home('contact_customer_support_answer')}`,
        },
      ],
    },
  ];

  const [filteredFaqData, setFilteredFaqData] = useState(faqData);

  return (
    <PageContainer title={title}>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <ImageAndSearch theme={theme} faqData={faqData} setFilteredFaqData={setFilteredFaqData} />
        </Grid>
        <Grid item xs={12}>
          <FaqQus faqData={filteredFaqData} />
        </Grid>
      </Grid>
    </PageContainer>
  );
}
