//import { useLocation, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectAuthUserCredentials } from 'services/auth/authSlice';
import AuthAdminLayout from 'layout/AuthAdminLayout';
import { FLG_DISABLE_AUTH_PAGES, NIU_HEALTH_BASE_URL } from 'config/app.config'
//---------------------------------------------------------

const RequireAdminAuth = () => {
  //const location = useLocation();
  let adminAuthToken = useSelector(selectAuthUserCredentials);
  //console.log('uATA', adminAuthToken);
  adminAuthToken = adminAuthToken?.authToken && adminAuthToken?.isAdmin ?
    adminAuthToken?.authToken : null;

  /*if(!adminAuthToken) {
    try {
      const usrAuthData = JSON.parse(localStorage.getItem('usrAuthData'));
      adminAuthToken = usrAuthData?.token;
    } catch(err) { }
  }*/

  const handleRedirect = () => {
    //window.location.href = "/signin";
    if(FLG_DISABLE_AUTH_PAGES) {
      window.location.href = NIU_HEALTH_BASE_URL + '/login';
    } else {
      window.location.href = "/signin";
    }
  };

  if(adminAuthToken) {
    return <AuthAdminLayout />;
  } else handleRedirect();

  /*return adminAuthToken ? (
    <AuthAdminLayout />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );*/
};
export default RequireAdminAuth;
