import { /*useMemo,*/ useEffect, useRef, useState } from 'react';
import {Link} from "react-router-dom";
import {
  Box,
  Grid,
  styled,
  CardContent, //CardMedia,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { AlbumPost, Typography } from 'core';
import { FeedView } from 'config/ui.config';
import PostMedia from './PostMedia';
import '../style.css';
//---------------------------------------------------------

const StyledCardContent = styled(CardContent)(({ theme, post_media }) => ({
  padding: !post_media && 0,
  [theme.breakpoints.down('sm')]: { padding: 0 },
}));
const StyledPostContent = styled(Box)(({ theme, post_media }) => ({
  color: grey[600],
  fontSize: 16,
  textAlign: 'left',
  lineHeight: post_media ? '25px' : '25px',
  wordBreak: 'break-all',
  //[theme.breakpoints.down("sm")]: {
  //	fontSize: "13px",
  //},
}));
const StyledPostMediaCtn = styled(Box)(({ theme }) => ({
  //borderRadius: 10,
  //width: '200px', 
  width: '100%', 
  height: '200px',
  overflowY: 'auto',
  border: '0px solid green',
  padding: '0px',
  //justify: 'center',
  //alignContent: 'center',
  //alignItems: 'center'
}));
//ReplaceBelowFxWith fNormalizeTextContent
const getPostContent = (content, showAllContent = false, isUserList) => {
  let finalContent = content;
  if (!showAllContent && content?.length > (isUserList ? 50 : 280)) {
    finalContent = content.substr(0, isUserList ? 50 : 280) + '...';
  }
  var exp_match =
    /(\b(https?|):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
  var element_content = finalContent?.replace(exp_match, "<a href='$1' target='_blank'>$1</a>");
  var new_exp_match = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
  finalContent = element_content?.replace(
    new_exp_match,
    '$1<a	target="_blank" href="http://$2">$2</a>'
  );
  return finalContent;
};

const lnkPostUrlSx = {
  padding: '0 5px',
  textDecoration: 'none',
  '&:hover': { color: '#757575', textDecoration: 'underline', }
};
//---------------------------------------------------------

export default function PostContent({
  theme,
  postCustomData: pInf,
  feedData,
  isUserList,
}) {
  const readMoreThreshold = isUserList ? 50 : 280;
  const showLessContentRef = useRef(null);
  const showFullContentRef = useRef(null);
  const readMoreTextRef = useRef(null);

  const onShowContent = (id) => {
    //OutW/elRef?
    if (showLessContentRef !== null && showFullContentRef !== null) {
      if (showLessContentRef.current?.style.display === 'none') {
        showFullContentRef.current.style.display = 'none';
        showLessContentRef.current.style.display = 'block';
        readMoreTextRef.current.innerText = 'Read More';
      } else {
        showFullContentRef.current.style.display = 'block';
        showLessContentRef.current.style.display = 'none';
        readMoreTextRef.current.innerText = 'Show Less';
      }
    }
  };

  let postMediaComp = (
    <PostMedia theme={theme} postCustomData={pInf} isUserList={isUserList} />
  );
  if(isUserList) {
    postMediaComp = (
      <StyledPostMediaCtn className='custScroll'>
        {postMediaComp}
      </StyledPostMediaCtn>
    );
  }

  let postDescComp;//console.log('SSSSSSSSSS', pInf);
  if(pInf?.pText2Hd) {
    //pText2Src, pText2Hd, pText2, pText2Target
    postDescComp = (
      <>
        <StyledPostContent>{pInf?.pText2Src}</StyledPostContent>
        <StyledPostContent className='txB'>{pInf?.pText2Hd}</StyledPostContent>
        <StyledPostContent>{pInf?.pText2}</StyledPostContent><br/>
      </>
    );
    if(pInf?.pText2Target) {
      postDescComp = (
        <Box component={Link} to={pInf.pText2Target}
          target='_blank' rel='noopener noreferrer'
          sx={lnkPostUrlSx}
        > {postDescComp} </Box>
      );
    } else {
      postDescComp = (
        <Box sx={{padding: '0 5px',}}> {postDescComp} </Box> 
      );
    }
  }
  //---------------------------------------------------------

  return (
    <Box sx={{padding: '0px!important', maxWidth: "100%"}} id={(isUserList ? 'u_' : '')+'pCtnt_'+pInf.postId}>
      {pInf.pText ? (
        <Grid item xs={12} sm={12} md={FeedView['full'].feed_size + 1}>
          <StyledCardContent
            post_media={pInf.pLeadImg}
            className={isUserList ? 'post-text-main' : ''}
          >
            <StyledPostContent post_media={pInf.pLeadImg}>
              <div
                color_variant="light"
                ref={showLessContentRef}
                style={{ display: 'block' }}
                dangerouslySetInnerHTML={{
                  __html: getPostContent(pInf.pText, false, isUserList),
                }}
                className={isUserList ? 'post-text custScroll' : ''}
              />
              <div
                color_variant="light"
                ref={showFullContentRef}
                style={{ display: 'none' }}
                dangerouslySetInnerHTML={{
                  __html: getPostContent(pInf.pText, true, isUserList),
                }}
                className={isUserList ? 'post-text' : ''}
              />
            </StyledPostContent>
            {pInf.pText?.length > readMoreThreshold && (
              <Typography
                fontWeight={600}
                size="xsmall"
                ref={readMoreTextRef}
                sx={{
                  color: theme.palette.secondary.light,
                  textDecoration: 'none',
                  cursor: 'pointer',
                }}
                onClick={onShowContent}
              >
                Read More
              </Typography>
            )}
          </StyledCardContent>
        </Grid>
      ) : null}
      {/*<PostMedia theme={theme} postCustomData={pInf} isUserList={isUserList} />*/}
      { postMediaComp }
      {pInf.postType === 'album' && pInf.pAlbumSrc ? (
        <Grid item xs={12} sm={12} md={12} marginY="15px">
          <AlbumPost imageList={pInf.pAlbumSrc} />
        </Grid>
      ) : null}

      {pInf.postType === 'video' && pInf.pVideoSrc ? (
        <Grid item xs={12} sm={12} md={12} marginY="15px">
          <video style={{ width: '100%' }} controls>
            <source
              type="video/mp4"
              src={require(pInf.pVideoSrc)}
              isUserList={isUserList}
            />
          </video>
        </Grid>
      ) : null}
      {postDescComp}
    </Box>
  );
}
