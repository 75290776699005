import { useState, useEffect, useCallback } from 'react';
import { useSearchParams, useNavigate, redirect } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { FLG_ENABLE_TWO_WAY_HANDSHAKE, FLG_ENABLE_AWS_AUTH,
  NIU_HEALTH_BASE_URL, SA_DEF_ORG_ID, //SA_DEF_TIMEZONE, HOME_BASE_URL
} from 'config/app.config';
//import { fVerifyCogJToken, /*fGetJwtUserData, fGetCogUserData*/ } from 'lib/cogFx';
import { /*genNiuSAKey, prepNameComponent,*/ fClearLocalStorageSSq, } from 'lib/appFx';
//fSetFMeNotSetts, fGetFMeNotSetts, fClearFMNotCooks
import { selectAuthUserCredentials, selectAuthUserDetails,
  //selectAuthUserAwsData
} from 'services/auth/authSlice';
//import { useLoginMutation,  useRegisterMutation } from 'services/auth/authApi';
import { useLazyUserDetailsByIdQuery, useUserUpdateMutation } from 'services/userApi';
import { setUserCredentials, invalidateUserCredentials } from 'services/auth/authSlice';
import LoaderPage from 'pages/LoaderPage';
//http://localhost:3000/social-treaty?email={}&userId={}&niuhcauth={jwToken}
//https://appb.niusocial.online/social-treaty?email={}&userId={}&niuhcauth={jwToken}
//Header: x-niu-cauth
//---------------------------------------------------------

export default function NSocialHandshake () {
  const pNameToken = 'niuhcauth';
  const pNameEMail = 'email';
  const pNameUId = 'userId';
  const callerAppUrl = NIU_HEALTH_BASE_URL;
  let sACredToken, sAUsrDetails;

  //const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [niuHSError, setNiuHSError] = useState(null);
  //stateVars: flgVerifyingCofToken, cToken, userEId, flgTryReg
  //const {authToken, isAdmin} = useSelector(selectAuthUserCredentials);
  sACredToken  = useSelector(selectAuthUserCredentials);
  sAUsrDetails = useSelector(selectAuthUserDetails);
  sACredToken  = sACredToken?.authToken;
  //sACredToken && console.log('sACredTokenRtk', sACredToken);
  if(!sACredToken) {
    const lStUsrData = JSON.parse(localStorage.getItem('usrAuthData'));
    //sACredToken = lStUsrData?.cognitoAuthData;
    sACredToken  = lStUsrData?.token;
    sAUsrDetails = lStUsrData?.details;
    //console.log('sACredTokenLWeb', sACredToken);
  }//console.log('sACredTokenddddd', sACredToken);

	const [qParams] = useSearchParams();
	const inParams = Object.fromEntries([...qParams]);
  //console.log('inParams', inParams);
  const [fUserDetails] = useLazyUserDetailsByIdQuery({userId: null});

  const fRedirectBack = () => {
    setTimeout(() => { redirect(callerAppUrl); }, 3000);
  };

  const fAttemptAutoLogin =  useCallback(//FLG_ENABLE_AWS_AUTH
    async () => { //console.log('gParams', inParams);
      if(pNameToken in inParams && inParams[pNameToken] &&
        pNameEMail in inParams && inParams[pNameEMail] &&
        pNameUId in inParams && inParams[pNameUId]
       ) {
        //let sACToken = sACredToken?.accessToken ?? null;
        let sACToken = sACredToken;
        const usrStatus = true;//sACredToken?.eMailVerified;
        //sAAwsCred?.idToken?.payload?.email_verified;//.auth_time, .email
        //console.log('sACToken '+usrStatus, sACToken);

        //CheckIfAlreadyLoggedIn
        if((sACToken && sACToken !== inParams[pNameToken]) ||
          (sAUsrDetails?.email && sAUsrDetails?.email !== inParams[pNameEMail]) ||
          (sAUsrDetails?.id && sAUsrDetails?.id !== inParams[pNameUId])
        ) {
          fClearLocalStorageSSq();
          sACToken = null;//InvalidateTag/Logout
          dispatch(invalidateUserCredentials);
          //setNiuHSError('Invalid Auth Token. Please login again..');
        } else if(sACToken && sACToken === inParams[pNameToken] &&
          sAUsrDetails?.email === inParams[pNameEMail] &&
          sAUsrDetails?.id === inParams[pNameUId]
        ) {//console.log('AlreadyLoggedIn');
          if(usrStatus) {
              navigate('/home');
          } else {
            setNiuHSError('Pending user verification');
            setTimeout(() => { redirect(callerAppUrl); }, 3000);
          }
        }

        if(!sACToken) { //console.log('NeedVerification');
          fClearLocalStorageSSq();
          dispatch(invalidateUserCredentials);

          const uLoginData = {
            company_id: SA_DEF_ORG_ID,
            user_id: inParams[pNameUId],
            token: inParams[pNameToken],
            custom_data: { aws_token: 'xxxx' }
          }
          dispatch(setUserCredentials(uLoginData));
          //fWaitPlease(2000);
          if(10) {//userDetailsShallAlreadyBeCalledByHomePage?
            try {
              let userDetail = await fUserDetails({
                userId: uLoginData?.user_id,
                orgId: uLoginData?.company_id,
              }).unwrap();
              userDetail = userDetail?.users[0];
              //console.log('userDetailO', userDetail);
              //---------------------

              if(userDetail) {
                const usrAuthData = { ...uLoginData, details: userDetail,
                  cognitoAuthData: {} };
                dispatch(setUserCredentials(usrAuthData));
                localStorage.setItem('usrAuthData', JSON.stringify(usrAuthData));
                navigate('/home');
              } else {
                setNiuHSError('Error fetching user details');
                setTimeout(() => { redirect(callerAppUrl); }, 3000);
              }
            } catch(err) {//console.log('uDErr', err);
              if(err?.status === 401) {
                setNiuHSError('Unauthorized');
                setTimeout(() => { redirect(callerAppUrl); }, 3000);
              }
            }
          }
        } else {
          setNiuHSError('Something went wrong!! Please try again...');
        }
      } else {
        setNiuHSError('Invalid request');
        setTimeout(() => { redirect(callerAppUrl); }, 3000);
      }
    }
  , []);

  useEffect(() => {
    if(FLG_ENABLE_TWO_WAY_HANDSHAKE) {
      //(() => fAttemptAutoLogin())();
      (() => {
        //if(sAAwsCred?.accessToken) { navigate('/home'); }
        //else { fAttemptAutoLogin(); }
        fAttemptAutoLogin();//AttemptHandshake
      })();
    } else {
      setNiuHSError('Unauthorized: Niu-Social Handshake not allowed');
      setTimeout(() => {//redirect(callerAppUrl);
				window.location.replace(callerAppUrl);
			}, 3000);
    }
  }, []);
	//---------------------------------------------------------

	return (
		<div style={{margin: 'auto', height:'100%', minHeight: '100%'}}>
			<LoaderPage className='xtBdr2'>
				<div style={{textAlign: 'center'}}>
					{niuHSError ? niuHSError : 'Please wait...'}
				</div>
			</LoaderPage>
		</div>
	);
};