import { useState } from "react";
import { useSelector } from "react-redux";
import {Link} from "react-router-dom";
//import { yupResolver } from "@hookform/resolvers/yup";
//import { useForm } from "react-hook-form";
import { Box, Avatar, IconButton, CardMedia,
  Stack, styled, //Grid, makeStyles,
} from "@mui/material"; //CardMedia,
import { Close } from '@mui/icons-material';
import { grey } from "@mui/material/colors";
import { socialMedia } from 'config/ui.config';
import { Button, Modal, Typography } from 'core'; //FormProvider,
//import CongratsPostForm from './CongratsPostForm';
import { getFeedListMetaData } from 'services/orgFeedApi';
//---------------------------------------------------------

const StyledPostMedia = styled(CardMedia)(({ theme }) => ({
	borderRadius: 10,
  //width: '400px',
  width: '98%',
  height: 'auto',
  //overflowY: 'auto',
  justify: 'center',
  alignContent: 'center',
  alignItems: 'center'
}));
const StyledPostMediaCtn = styled(Box)(({ theme }) => ({
    borderRadius: 10,
    width: '400px', 
    height: '200px',
    overflowY: 'auto',
    justify: 'center',
    alignContent: 'center',
    alignItems: 'center'
}));

const PostEditorContainer = styled(Stack)(({ theme, avatar }) => ({
  border: `1px solid ${grey[400]}`,
  padding: '1rem',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexDirection: 'row',
}));
const StyledPostContent = styled(Box)(({ theme, post_media }) => ({
  color: grey[600],
  fontSize: 16,
  textAlign: 'left',
  lineHeight: post_media ? '25px' : '25px',
  wordBreak: 'break-all',
  //[theme.breakpoints.down("sm")]: {
  //	fontSize: "13px",
  //},
}));
const StyledQShareButton = styled(Button)(({ theme }) => ({
  background: theme.palette.background.paper,
  color: grey[600],
  border: '2px solid grey',
  boxShadow: 'none',
  fontSize: '1rem',
  maxWidth: '40%',
  // display: "flex",
  // justifyContent: "center",
  // alignSelf: "center",
  // textAlign: "center",
  '&:hover': { background: theme.palette.background.paper },
}));
const PostInputField = styled('textarea')(({ theme }) => ({
  border: 'none', minWidth: '80%', resize: 'none',
  fontSize: '15px',
  '&:focus': { outline: 'none' },
  '&:focus::placeholder': { color: 'transparent' },
  '&::placeholder': { fontWeight: 900, fontSize: '15px', },
}));
//---------------------------------------------------------

function PostEditorArea({ fOnChange, pInf, userInf }) {
  console.log('userInf', {pInf, userInf});
  //ShouldBe display_picture from connected && is_subscribed Token
  //userInf={{dispName: connUserName, profilePic: connUserDP}}
  let connUsrProfilePic = userInf?.profilePic;

  if(!connUsrProfilePic?.length) {
    connUsrProfilePic = pInf?.aUserPic;
  }
  if(!connUsrProfilePic?.length) {
    connUsrProfilePic = pInf?.profileInfo?.media?.pictures[0];
  }
  if(!connUsrProfilePic?.length) {
    connUsrProfilePic = pInf?.userD?.media?.pictures[0];
  }
  if(!connUsrProfilePic || !connUsrProfilePic.length) {
    connUsrProfilePic = '/assets/images/avtar1.png';//'/images/noUser.jpg'
  }
  return (
    // onChange={fOnChange()}
    <PostEditorContainer>
      <Avatar
        variant='square'
        alt={userInf?.dispName || ''}
        title={userInf?.dispName || ''}
        src={connUsrProfilePic}
        sx={{ width: '50px', height: '50px' }}
      />
      <PostInputField
        placeholder="Say something about this post"
        required={true}
        onChange={(e) => {
          fOnChange(e.target.value);
        }}
      />
    </PostEditorContainer>
  );
}
//---------------------------------------------------------

export default function CreatePostForm({
  theme,
  feedData,
  //shareOn,
  srcSMKey,
  pInff,
  //postCustomData: pInf,
  destSMKey,
  fFeedAct,
  authUser,
  isProfileDetailsLoading,
  showCreateFeedForm,
  onCloseCreateFeedForm,
  setMessage,
  _handleCongratsFeedForm,
  currentSm,
  showCongratsFeedForm,
  setShowCongratsFeedForm,
}) {
  const feedListMeta = useSelector(getFeedListMetaData); //, shallowEqual);
  //const { post_content, post_media, social_media } = feedData;
  //const shareOn = sMMeta[destSMKey].title];
  const shareOn = currentSm && feedListMeta && feedListMeta?.sMediaMgr[currentSm]?.title;
  const [quoteText, setQuoteText] = useState(null);
  const [qTextErr, setQTextErr] = useState(null);
  //console.log('createPostF '+shareOn+' '+ currentSm, pInff);
  let connUserName='', connUserDP='', connUserTokenData;
  if(currentSm) {
    connUserTokenData = feedListMeta?.sMediaMgr[currentSm]?.sMTokens;
    //console.log("connUserTokenData ", connUserTokenData);
    //properties[0]
    //sMTokens
    if(connUserTokenData)
    //connUserName = ((connUserTokenData?.properties[0]?.name?.first || '') +
    //  ' ' + (connUserTokenData?.properties[0]?.name?.last || '')).trim();
    connUserName = ((connUserTokenData?.name?.first || '') +
      ' ' + (connUserTokenData?.name?.last || '')).trim();
    //connUserDP = 'FromTokens..';//connUserTokenData?.properties[0]
    connUserDP = connUserTokenData?.display_picture || '';
  }
  //console.log('currentSm '+currentSm, connUserName);

  //function setAText()
  function fFeeedEngagementCallback(resp) {
    onCloseCreateFeedForm();
    resp && setShowCongratsFeedForm((prev) => !prev);
    setQuoteText(null);
  }
  function handleCongratsFeedForm() {
    if(quoteText?.length) {
      fFeedAct(
        {
          pInf: pInff,
          pUrl: pInff.pUrl,
          postId: feedData.id,
          destSMKey: currentSm,
          actType: 'quote',
          snetworkplatform_id: feedData.snetworkplatform_id,
          pText: quoteText, //'TestQPost'//***DM,
          feedListMeta,
          feedData,
        },
        fFeeedEngagementCallback,
        (err) => {
          setMessage(err);
          fFeeedEngagementCallback(false);
        }
      );
    } else {
      setQTextErr('Quote text can not be empty');
      return false;
      //setErrPENDING
    }
    //onCloseCreateFeedForm();
    //setShowCongratsFeedForm((prev) => !prev);
  }

  const lnkPostUrlSx = {
    padding: '0 5px',
    textDecoration: 'none',
    '&:hover': { color: '#757575', textDecoration: 'underline', }
  };
  let postDescComp;
  if(pInff?.pText2Hd) {
    //pText2Src, pText2Hd, pText2, pText2Target
    postDescComp = (
      <>
        <StyledPostContent>{pInff?.pText2Src}</StyledPostContent>
        <StyledPostContent className='txB'>{pInff?.pText2Hd}</StyledPostContent>
        <StyledPostContent>{pInff?.pText2}</StyledPostContent><br/>
      </>
    );
    if(pInff?.pText2Target) {
      postDescComp = (
        <Box component={Link} to={pInff.pText2Target}
          target='_blank' rel='noopener noreferrer'
          sx={lnkPostUrlSx}
        > {postDescComp} </Box>
      );
    } else {
      postDescComp = (
        <Box sx={{padding: '0 5px',}}> {postDescComp} </Box> 
      );
    }
  }
  //---------------------------------------------------------

  return (
    <>
      <Modal open={showCreateFeedForm} onClose={onCloseCreateFeedForm}>
        <Stack spacing={2}>
          <Stack spacing={2} direction="row" justifyContent="space-between">
            <Stack spacing={1} direction="row" alignItems="center">
              <Typography variant="h6" size="medium" className="share-data">
                Sharing on
              </Typography>
              {socialMedia[shareOn]}
            </Stack>
            <IconButton onClick={onCloseCreateFeedForm}>
              <Close color="primary" />
            </IconButton>
          </Stack>
          <PostEditorArea fOnChange={setQuoteText} pInf={pInff}
            userInf={{dispName: connUserName, profilePic: connUserDP}} />
          {feedData.text &&
            <Typography
              fontSize="16px"
              color_variant="light"
              textAlign="left"
              marginTop="20px !important"
              height="60px"
              maxHeight="60px"
              overflow="auto"
              className='custScroll'
            >
              {feedData.text}
            </Typography>
          }
          {
            pInff?.pImages && pInff?.pImages.length && pInff?.pImages[0] &&
              <div style={{display: 'flex', justifyContent: 'center'}}>
                <StyledPostMediaCtn className='custScroll'>
                  <StyledPostMedia component="img" src={feedData.media.pictures[0]} />
                </StyledPostMediaCtn>
              </div>
          }
          {postDescComp}
          <Box textAlign="center" marginTop="30px !important">
            {/*qTextErr && {<p className="">{qTextErr}</p>}*/}
            <StyledQShareButton onClick={handleCongratsFeedForm}>
              Post
            </StyledQShareButton>
          </Box>
        </Stack>
      </Modal>

      {/* <CongratsPostForm
        theme={theme}
        destSMKey={destSMKey}
        feedData={feedData}
        shareOn={shareOn}
        authUser={authUser}
        isProfileDetailsLoading={isProfileDetailsLoading}
        showCongratsFeedForm={showCongratsFeedForm}
        onCloseCongratsFeedForm={() => {
          setShowCongratsFeedForm({ ...showCongratsFeedForm, isOpen: false });
        }}
      /> */}
    </>
  );
}
