import PropTypes from "prop-types";
import {Box, Pagination, PaginationItem, styled } from "@mui/material";
//---------------------------------------------------------

const pgBoxSx = { mt: 2, px:3,
  display: 'flex', alignItems: 'center', justifyContent:'flex-end'
};

export default function PaginationList({theme, pageArgs }) {
  //pageArgs: currPage, pageCount onPageChange
  return (
    <Box className='xbdr1' sx={pgBoxSx}>
      <Pagination size="small" boundaryCount={2} color="primary"
        //hidePrevButton hideNextButton showFirstButton showLastButton
        //defaultPage={4} siblingCount={0}
        page={pageArgs.currPage} count={pageArgs.pageCount} 
        onChange={pageArgs.onPageChange}
        renderItem={(item) => {//console.log('item', item);
          if(item.type === 'page' && item.selected) {
            item.size='large';
            //item.className='';
          }
          return ( <PaginationItem {...item} /> );
        }}
      />
    </Box>
  );
};
//PaginationList.defaultProps = { pageArgs:  };
PaginationList.propTypes = {//PropTypes.element
  pageArgs: PropTypes.shape({//PropTypes.numberBetween()
    currPage: PropTypes.number.isRequired,
    pageCount: PropTypes.number.isRequired,
    onPageChange:PropTypes.func.isRequired,
  }).isRequired
};