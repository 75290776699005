import * as React from 'react';
import MuiModal from '@mui/material/Modal';
import { Card } from 'core';
import { styled } from '@mui/system';

const StyledModalCard = styled(Card)(({ theme, maxwidth }) => ({
  display: 'flex', justifyContent: 'center',
  position: 'absolute', padding: '20px',
  top: '50%', left: '50%',
  //width: maxwidth ? 450 : 600,
  width: maxwidth ? maxwidth : 600,
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  [theme.breakpoints.down('sm')]: {
    width: 350,
    padding: '20px',
  },
}));

export default function Modal({
  children, open, onClose,
  maxwidth, bgColor, height,
}) {
  return (
    <div>
      <MuiModal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableScrollLock
      >
        <StyledModalCard maxwidth={maxwidth}>{children}</StyledModalCard>
      </MuiModal>
    </div>
  );
}
