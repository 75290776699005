import { useRef } from 'react';
import { SA_IMG_BASE_URL } from 'config/app.config';
import { getSMediaIcons } from 'config/ui.config';
import { Stack, Box, Grid, styled, IconButton } from '@mui/material';
import { Typography } from 'core';
import { ProfileInfoSkeleton } from 'core/Skeletons';
import moment from 'moment';
import '../style.css';
//import profilePic from 'public/images/noUser.jpg'
//import { getInitSMediaAttr } from 'lib/appFx';
//---------------------------------------------------------

const StyledFeedPageProfile = styled('img')(({ theme }) => ({
  height: 60, //StyledPostUserProfile
  width: 60,
}));

const StyledFeedPageProfileInfoContainer = styled('div')(({ theme }) => ({
  display: 'flex', //StyledUserProfileInfoContainer
  alignItems: 'center',
  gap: theme.spacing(2),
  flexDirection: 'row',
  cursor: 'pointer',
}));

const StyledPostHeader = styled(Grid)(({ theme }) => ({
  alignItems: 'center',
  justifyContent: 'space-between',
  spacing: theme.spacing(2), // marginTop: theme.spacing(4),
}));
//---------------------------------------------------------

export default function PostHeader({
  theme,
  postCustomData: pInf,
  handleCreateFeedForm,
}) {
  //const sMMeta = getInitSMediaAttr(null);
  const postTopRtRef = useRef(pInf.srcKey);//Why?
  const timeAgo = moment(pInf.postDateTime).fromNow();
  const isProfileDetailsLoading = false;
  const returnName = (name, pgName) => {
    let res; //pgName && console.log('pgName', pgName);
    if (typeof name === 'string') { res = name; }
    else {
      res = ((name?.first || '') + ' ' + (name?.last || '')).trim();
      if (!res.length && name?.username) {
        res = '@' + name?.username;
      }
    }
    if (!res) { res = 'NIU'; }//'NIU Health';
    if (pgName) { res += ': ' + pgName; }
    return res;
  };

  if (0 && pInf?.srcKey && pInf.postId === 'MzR9v14E_VymkAQBX') {

  }
  let pImg;//console.log('profileInfo', pInf);
  if (pInf?.profileInfo?.media?.pictures[0]) {
    pImg = pInf.profileInfo.media.pictures[0] + '?nSSq';
  }
  if (!pImg) {
    if (pInf?.profileInfo?.type === 'page' && pInf?.profileInfo?.name === 'NIU Health') {
      //pImg = SA_IMG_BASE_URL + '/globalLogo.png';
      pImg = SA_IMG_BASE_URL + '/niuHealthLogo.svg';
    } else { pImg = '/images/noUser.jpg'; }
  }
  let accName;
  if (10) {
    accName = pInf?.profileInfo?.name;
    //accName = accName?.first ?? accName;
    accName = accName?.first ? accName.first :
      (accName?.username ? accName.username : accName);
    accName = accName?.toString().toLowerCase() || '';
    if (pInf.srcKey === 'fb' && accName.startsWith('eric')) {
      pImg = 'https://scontent.fbom3-2.fna.fbcdn.net/v/t39.30808-1/355630175_134302119679379_8524886290202653382_n.jpg?stp=c25.0.320.320a_dst-jpg_p320x320&_nc_cat=103&ccb=1-7&_nc_sid=7206a8&_nc_ohc=IJtESS33F6gAX-7QALB&_nc_ht=scontent.fbom3-2.fna&oh=00_AfA8jlEW_e7tiqB4DQnaC6aavr0l34aJt89K_2u3G98NHA&oe=649FF870';
      pImg = 'https://apia.niusocial.online/storage/media/f2vJ0TXzHQB9QH8ftHwMgsKlwHMofLr3eXUlx2t3.png';
    } else if (pInf.srcKey === 'ig' &&
      (accName.startsWith('ericroy01') || accName.startsWith('bhavinsen7'))) {
      //pImg = 'https://instagram.fbom3-2.fna.fbcdn.net/v/t51.2885-19/356218815_237769075678300_6952192833466867742_n.jpg?stp=dst-jpg_s150x150&_nc_ht=instagram.fbom3-2.fna.fbcdn.net&_nc_cat=104&_nc_ohc=zXl5m2JWTgwAX-TR1Mg&edm=AEF8tYYBAAAA&ccb=7-5&oh=00_AfBC21F9eBoSGv1Jozd8v0LqxqU1NNfTR94UFpX7UvhX1A&oe=649EF6CE&_nc_sid=1e20d2';
      pImg = 'https://scontent.fbom3-2.fna.fbcdn.net/v/t39.30808-1/355630175_134302119679379_8524886290202653382_n.jpg?stp=c25.0.320.320a_dst-jpg_p320x320&_nc_cat=103&ccb=1-7&_nc_sid=7206a8&_nc_ohc=IJtESS33F6gAX-7QALB&_nc_ht=scontent.fbom3-2.fna&oh=00_AfA8jlEW_e7tiqB4DQnaC6aavr0l34aJt89K_2u3G98NHA&oe=649FF870';
      pImg = 'https://apia.niusocial.online/storage/media/f2vJ0TXzHQB9QH8ftHwMgsKlwHMofLr3eXUlx2t3.png';
    }

    //accName = accName.startsWith('rupel') ? accName : null;
    //accName = ' - '+pInf.srcKey+' '+pInf.postId+' '+accName+' '+JSON.stringify(pInf?.profileInfo?.name);
    //console.log('accName', accName);
  }
  //---------------------------------------------------------

  return (
    <Grid item xs={12} sm={12} md={12}>
      <StyledPostHeader container>
        <Grid item>
          <StyledFeedPageProfileInfoContainer>
            <StyledFeedPageProfile
              src={pImg}
              alt={returnName(pInf?.profileInfo?.name)}
              sx={{ height: 50, width: 'auto' }}
              data-fid={pInf.postId}
            />
            <Box>
              <Typography
                fontWeight={600}
                size="small"
                color_variant="lightGrey"
              >
                {returnName(pInf?.profileInfo?.name, pInf?.pPageName)
                  //returnName(pInf?.userD?.name) || 'NIU Social'
                }
              </Typography>
              <Typography color_variant="lightGrey">
                {timeAgo}{0 ? accName : null}
              </Typography>
            </Box>
          </StyledFeedPageProfileInfoContainer>
        </Grid>
        <Grid item>
          <Stack alignItems="center" direction="row">
            <IconButton
              sx={{ cursor: 'default' }}
              onClick={handleCreateFeedForm}
              fontSize="1rem"
              sa-destkey={pInf.srcKey}
              ref={postTopRtRef}
            //data-fd={JSON.stringify(pInf)}
            >
              {getSMediaIcons(pInf.srcKey, 'initCap') /*||
                (pInf.srcSMId === 'MzR9v14E' || pInf.srcSMId === '9lR72vmN'  &&  //MzR9v14E instgramp && 9lR72vmN instgramb 
                  getSMediaIcons('ig', 'initCap'))*/}
            </IconButton>
          </Stack>
        </Grid>
      </StyledPostHeader>
    </Grid>
  );
}
