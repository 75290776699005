import { useState, /*useEffect, useCallback, useMemo*/ } from "react";
import { Outlet } from 'react-router-dom';
import { /*useTheme,*/ Box, Stack, styled } from '@mui/material';
//import { Header } from 'core';
//import { Sidebar } from 'core';
import AppMenu from 'core/Header/AppMenu';
//import BootAppAuthUser from '../BootAppAuthUser';
//Though working perfectly with whatever incomplete functionality,
//disabled temporarily as testing is not yet done.
//---------------------------------------------------------

const PageContainer = styled(Stack)(({ theme }) => ({
  margin: 'auto',
  //maxWidth: '1280px',
  [theme.breakpoints.down('xl')]: {
    paddingRight: '2rem',
    //paddingLeft: '0.6rem',//'2rem'
  },
  [theme.breakpoints.down('md')]: {
    paddingLeft: '0.5rem',
    paddingRight: '0.5rem',
  },
}));
//---------------------------------------------------------

export default function ProtectedLayout() {
  //const [stAppAdmBootStrapped, setStAppAdmBootStrapped] = useState(false);
  //console.log('stAppAdmBootStrapped', stAppAdmBootStrapped);

  return (//
    //stAppAdmBootStrapped ? (
    <Box
      sx={{
        //border: '0px solid green',
        backgroundColor: (theme) => theme.palette.background.default,
        //minHeight: '100vw',//'70vw',
        minHeight: '70vw',
        //maxWidth: '100vw',
        marginBottom: '1rem',
      }}
    > {/*<Sidebar />*/}
      {/*<BootAppAuthUser />*/}
      <AppMenu />
      <Box id="ctnMidPanel"
        sx={{
          //marginLeft: { lg: '270px' },
          //position: 'relative',
          margin: 'auto',
            marginLeft: { lg: '270px' },
          /*[theme.breakpoints.down('sm')]: { margin: 'auto', },
            [theme.breakpoints.up('sm')]: {
              marginLeft: {smp: '30px', lg: '270px', }
            },*/
          }}
        >
          <PageContainer>
            <Outlet />
          </PageContainer>
        </Box>
    </Box>
    //) : <BootAppAuthUser fSetBootStrapped={setStAppAdmBootStrapped} />
  );
}
