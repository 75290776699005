import { styled } from '@mui/material';

const RawButton = styled('button')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  borderColor: theme.palette.secondary.dark,
  borderRadius: '14rem',
  padding: '0.2rem 2rem',
  margin: '1rem 0',
  '&:hover': {
    boxShadow: theme.shadows[1],
    cursor: 'pointer',
  },
}));

export default RawButton;
