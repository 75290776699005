import { styled } from "@mui/material";
import { Typography } from "core";

const FooterContainer = styled("div")(({ theme }) => ({
  textAlign: "center",
  //border: '2px solid red'
}));
export default function Footer() {
  return (
    <FooterContainer>
      <Typography
        variant="caption"
        color_variant="light"
        size="xsmall"
        display="flex"
        alignItems="center"
      >
        Terms of use | Privacy Policy Copyright
        <span className="xcopyright-symbol">&nbsp;&copy;&nbsp;</span>2023, NIU Health
      </Typography>
    </FooterContainer>
  );
}
