import { createSlice } from '@reduxjs/toolkit';
import { SA_TEMP_ADMINS_FOR_FB, SA_TEMP_DEF_ADMIN_EMAIL } from 'config/app.config';
//import { fResetApiStat } from 'lib/appFx';
//---------------------------------------------------------

const authSlice = createSlice({
	name: 'auth',
	initialState: {userId: null, email: null, authToken: null, orgId: null,
		details: null, awsAuthData: null},
	reducers: {
		setUserCredentials:(state, action) => {
			const { company_id, user_id, token } = action.payload;
			state.orgId = company_id;
			state.userId = user_id;
			state.authToken = token;
			state.details = action.payload?.details;
			state.email = action.payload?.details?.email;
			state.awsAuthData = action.payload?.cognitoAuthData;
		},
		logOut: (state, action) => {
			state.userId = state.email = state.authToken = state.orgId = state.details = state.awsAuthData = null;
			//fResetApiStat();
		},
		invalidateUserCredentials:(state, action) => {
			state.userId = null;
			state.email = null;
			state.authToken = null;
			state.orgId = null;
			state.details = null;
			state.awsAuthData = null;
		}
	},
});

export default authSlice.reducer;
export const { setUserCredentials, logOut, invalidateUserCredentials } = authSlice.actions;
export const selectAuthUserId = state => state.auth.userId;
//export const selectAuthUserEmail = state => state.auth.email;
export const selectAuthToken  = state => state.auth.authToken;

export const selectUserOrgId  = state => state.auth.orgId;
export const selectAuthUserCredentials = state => {
	return {
		orgId: state.auth.orgId,
		userId: state.auth.userId,
		emailId: state.auth.email,
		isAdmin: SA_TEMP_ADMINS_FOR_FB.includes(state.auth.email),// === SA_TEMP_DEF_ADMIN_EMAIL,
		authToken: state.auth.authToken,
		awsAuthToken: state.auth.awsAuthData,
	};
}
//export const checkIsAdmin  = state => state.auth.email === SA_TEMP_DEF_ADMIN_EMAIL;
export const checkIsAdmin  = state => SA_TEMP_ADMINS_FOR_FB.includes(state.auth.email);
export const selectAuthUserDetails = state => state.auth.details;
export const selectAuthUserAwsData = state => state.auth.awsAuthData;
