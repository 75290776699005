import { Box } from "@mui/material";
import AdmRewardPointsForm from './AdmRewardPointsForm';
import { useTranslation } from "react-i18next";
//---------------------------------------------------------

export default function AdmRewardPoints({ theme, title }) {
	const { t: home } = useTranslation('home')
	/*const theme = useTheme();
	const content = (
		<section className=''>
			<h3>Reward Coins</h3>
			<main><p>Coming Soon..</p></main>
		</section>
	);*/
	//---------------------------------------------------------
	if (!title) {
		title = `${home('reward_coins')}`
	}
	return (//<div>{ content }</div>);
		<>
			<Box className="">
				<h2 className='pgHd'>{title}</h2>
			</Box>
			<Box className="bgWh padL xtBdr2"
				sx={{ maxWidth: '98%' }}
			//sx={{maxWidth: '60%', display: 'flex', gap: '0.6rem',
			//flexDirection: {xs:'column',md:'row'},}}
			>
				<AdmRewardPointsForm />
			</Box>
		</>
	);
}; 