import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Menu, MenuItem, IconButton, Divider } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Typography } from 'core';
import { fGetStrSlug } from 'lib/appFx';
import { useTranslation } from "react-i18next";
//---------------------------------------------------------

const grpMenuIconSx = {
  padding: '0.1rem', fontSize: '1.5rem', borderRadius: '50%',
  background: '#e9e9e9', color: '#00406B',
};
const menuSx = {
  fontSize: '0.8rem', color: '#1c1c1c',
  "&:hover": { backgroundColor: "#e4e4e4 !important" }
};
//---------------------------------------------------------

export default function AdmGroupCardHeadMenu({ groupData, groupMethods }) {
  const navigate = useNavigate();
  const [stMAnchorEl, setStMAnchorEl] = useState(null);
  const isMenuOpen = Boolean(stMAnchorEl);
  const fOpenMenu = (event) => {
    //event.preventDefault();
    setStMAnchorEl(event.currentTarget);
  };
  const fCloseMenu = () => { setStMAnchorEl(null); };
  const menuId = 'aGrpPopMenu';
  const { t: home } = useTranslation('home')
  const fEditGroup = (e) => {//groupMethods.fEditGroup
    //const grpId = e.currentTarget.getAttribute('groupId');
    e.preventDefault();
    fCloseMenu();
    navigate('/admin/group-details/' + fGetStrSlug(groupData.name),
      //{ state: { groupId: groupData.id, groupData: groupData }});
      { state: { groupId: groupData.id, groupData } });
  };
  const fDelGroup = (e) => {
    const grpId = groupData.id;
    //const grpId = e.currentTarget.getAttribute('data-group-id');
    e.preventDefault();
    fCloseMenu();
    groupMethods.fDeleteGroup(grpId);
  };
  //---------------------------------------------------------

  return (
    <div>
      <IconButton
        aria-label="more"
        id={menuId}
        aria-controls={isMenuOpen ? 'aGrpPop-menu' : undefined}
        aria-expanded={isMenuOpen ? 'true' : undefined}
        aria-haspopup="true"
        onClick={fOpenMenu}
        sx={{ padding: '2px' }}
      //disableScrollLock={true}
      //disableFocusRipple
      //disableRipple={true}
      //inputProps={{PaperProps: {disableScrollLock: true}}}
      //classes={{ paper: {marginTop: '0.3rem'} }}
      >
        {stMAnchorEl ?
          <MoreVertIcon sx={grpMenuIconSx} /> :
          <MoreHorizIcon sx={grpMenuIconSx} />
        }
      </IconButton>
      <Menu
        id={menuId}
        MenuListProps={{ 'aria-labelledby': 'aGrpPop-button', }}
        anchorEl={stMAnchorEl} //anchorPosition={{top: '0.5rem'}}
        open={isMenuOpen}
        onClose={fCloseMenu}
        PaperProps={{/*elevation: 0,*/
          style: { maxHeight: '7rem', width: '5rem', marginTop: '0.3rem' },
        }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        sx={{ menuSx }}
        //disablePortal
        disableScrollLock={true}
      >
        <MenuItem onClick={fEditGroup} /*data-group-id={groupData.id}*/>
          <Typography sx={{ fontSize: '0.8rem', color: '#1c1c1c' }}>
            {home('edit')}</Typography>
        </MenuItem>
        <MenuItem onClick={fDelGroup}>
          <Typography sx={{ fontSize: '0.8rem', color: '#c5353a' }}>
            {home('delete')}</Typography>
        </MenuItem>
      </Menu>
    </div>
  );
}