import { Paper, styled } from "@mui/material";

const Card = styled(Paper)(() => ({
  display: "flex",
  flexDirection: "column",
  backgroundColor: (theme) => theme.palette.background.paper,
  padding: "1rem",
  // paddingLeft: "1rem",
}));

export default Card;
