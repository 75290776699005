import { Close } from '@mui/icons-material';
import { Box, IconButton, Stack, styled } from '@mui/material';
import { Button, Modal, Typography } from 'core';
import React, { useRef, useState } from 'react';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import { grey } from '@mui/material/colors';
import { useSelector } from 'react-redux';
import { selectAuthUserId } from 'services/auth/authSlice';
import { useUserUpdateMutation } from 'services/userApi';
import { Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import { useTranslation } from 'react-i18next';

const StyledBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: 5,
  background: '#020238',
  padding: '5px',
  opacity: '0.65',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  cursor: 'pointer',
}));
const StyledViewAllButton = styled(Button)(({ theme }) => ({
  background: theme.palette.background.paper,
  color: grey[600],
  border: '2px solid grey',
  boxShadow: 'none',
  fontSize: '0.8rem',
  maxWidth: 250,
  display: 'flex',
  justifyContent: 'center',
  alignSelf: 'center',
  textAlign: 'center',
  '&:hover': {
    background: theme.palette.background.paper,
  },
}));
function ChangeProfilePicturePopUp({
  showEditProfileCard,
  onCloseEditProfileCard,
  profile,
  refetchProfileDetails,
}) {
  const [updateUser, { isError }] = useUserUpdateMutation();
  const userAuthId = useSelector(selectAuthUserId);
  const { t: home } = useTranslation('home')
  const [selectedFile, setSelectedFile] = useState('');

  const handleUploadChange = async (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const onChangeImage = async () => {
    let formData = new FormData();

    formData.append('profile', fileList[0].originFileObj);
    await updateUser({ userId: userAuthId, params: formData });
    if (isError === false) {
      refetchProfileDetails();
      onCloseEditProfileCard();
    }
  };

  const [fileList, setFileList] = useState([]);

  const onChange = ({ fileList: newFileList }) => {
    // onCloseEditProfileCard();
    setFileList(newFileList);
  };
  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onerror = reject;
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  return (
    <>
      <Modal open={showEditProfileCard} maxwidth="true">
        <Stack spacing={2}>
          <Stack spacing={2} direction="row" justifyContent="space-between">
            <Stack justifyContent="center" alignItems="center">
              <Typography size="medium">{home('edit_profile_picture')}</Typography>
            </Stack>
            <IconButton onClick={onCloseEditProfileCard}>
              <Close color="primary" />
            </IconButton>
          </Stack>
          <Box
            width="100%"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '40px !important',
            }}
          >
            <Box maxWidth="250px">
              {/* <input
                accept="image/*"
                style={{ display: 'none' }}
                id="contained-button-file"
                type="file"
                onChange={handleUploadChange}
              /> */}
              {/* <label htmlFor="contained-button-file"> */}
              <Box sx={{ position: 'relative', width: '250px' }}>
                {/* {selectedFile ? (
                    <img
                      src={
                        selectedFile
                          ? URL.createObjectURL(selectedFile)
                          : profile
                      }
                      alt="Profile"
                      style={{
                        width: 250,
                        objectFit: 'contain',
                      }}
                    />
                  ) : null}
                  <StyledBox>
                    <CameraAltOutlinedIcon
                      sx={{ color: '#fff', paddingRight: '3px' }}
                    />
                    <Typography color_variant="white">
                      Upload profile picture
                    </Typography>
                  </StyledBox> */}

                <ImgCrop>
                  <Upload
                    // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                    listType="picture-card"
                    fileList={fileList}
                    onChange={onChange}
                    maxCount={1}
                    multiple={false}
                  // status={"success"}
                  // beforeUpload= {() => {
                  //   return "success";
                  // }}
                  // onPreview={onPreview}
                  >
                    <CameraAltOutlinedIcon
                      sx={{ color: '#fff', paddingRight: '3px' }}
                    />
                    <Typography color_variant="white">
                      {home('upload_profile_picture')}
                    </Typography>
                    {/* {fileList.length < 1 && ''} */}
                  </Upload>
                </ImgCrop>
              </Box>
              {/* </label> */}
            </Box>
          </Box>
          <StyledViewAllButton onClick={onChangeImage}>
            {home('change_profile_picture')}
          </StyledViewAllButton>
        </Stack>
      </Modal>
      {/* <ImgCrop></ImgCrop> */}
    </>
  );
}

export default ChangeProfilePicturePopUp;
