import { sSApi } from './sSApi';
//---------------------------------------------------------

export const categoryApi = sSApi.injectEndpoints({
  endpoints: (builder) => ({
    contentDiscoveryCategoryList: builder.query({
      query: () => {
        return {
          url: "/contentdiscovery/category?page=1",
        };
      },
    }),
    contentDiscoveryCategoryUpdate: builder.query({
      query: (params) => {
        return {
          url:
            "/contentdiscovery/category/" + params.contentDiscoveryCategoryId,
        };
      },
    }),
    contentDiscoveryCategoryDetails: builder.query({
      query: (params) => {
        return {
          url:
            "/contentdiscovery/category/" + params.contentDiscoveryCategoryId,
        };
      },
    }),
    deleteContentDiscoveryCategory: builder.query({
      query: (params) => {
        return {
          url:
            "/contentdiscovery/category/" + params.contentDiscoveryCategoryId,
          method: "DELETE",
        };
      },
    }),
    createcontentDiscoveryCategory: builder.query({
      query: (params) => {
        return {
          url: "/contentdiscovery/category",
          method: "POST",
          body: params,
        };
      },
    }),
  }),
});
//---------------------------------------------------------

export const {
  useContentDiscoveryCategoryListQuery,
  useContentDiscoveryCategoryUpdateQuery,
  useContentDiscoveryCategoryDetailsQuery,
  useContentDiscoveryCategoryCreatesQuery,
  useDeleteContentDiscoveryCategoryMutation,
} = categoryApi;
