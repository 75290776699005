import {
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  styled,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { Button, Typography } from 'core';
import React from 'react';
//import {TAWKTO_PID, TAWKTO_TID} from "../../config/app.config";
//import TawkTo from 'tawkto-react';

const StyledViewAllButton = styled(Button)(({ theme }) => ({
  background: theme.palette.background.paper,
  color: theme.palette.secondary.dark,
  boxShadow: 'none',
  fontSize: '14px',
  maxWidth: '150px',
  fontWeight: 600,
  border: `3px solid ${grey[400]}`,
  '&:hover': {
    background: theme.palette.background.paper,
  },
}));
const StyledGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    maxWidth: '100%',
    flexBasis: '100%',
  },
}));
const StyledTextArea = styled('textarea')(({ theme }) => ({
  height: 300,
  border: 'none',
  fontSize: '15px',
  padding: '1rem',
  fontFamily: "'Roboto','Helvetica','Arial',sans-serif",
  letterSpacing: '0.5px',
  '&:focus::placeholder': {
    color: 'transparent',
  },
}));
const StyledTypography = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    fontSize: 12,
  },
}));
export default function HelpContent({ theme }) {
   //var tawk = new TawkTo(TAWKTO_PID, TAWKTO_TID);
  // tawk.hideWidget();
  return (
    <Grid
      container
      spacing={3}
      justifyContent="center"
      alignItems="center"
      direction="row"
    >
      <StyledGrid item xs={6}>
        <Stack spacing={3}>
          <img src="/assets/images/helpPageImage.png" alt="help" />
          <StyledTextArea
            minRows={10}
            placeholder="Please write a brief description of the issue you are facing"
          />
          <RadioGroup defaultValue="never" fontWeight="800" size="medium" row>
            <Stack spacing={3} direction="row">
              <Stack direction="row" alignItems="center">
                <FormControlLabel
                  value="high priority"
                  control={<Radio />}
                  sx={{
                    color: theme.palette.secondary.dark,
                    marginRight: 0,
                  }}
                />
                <StyledTypography fontWeight={600} size="small">
                  High Priority
                </StyledTypography>
              </Stack>
              <Stack direction="row" alignItems="center">
                <FormControlLabel
                  value="medium priority"
                  control={<Radio />}
                  sx={{
                    color: theme.palette.secondary.dark,
                    marginRight: 0,
                  }}
                />
                <StyledTypography fontWeight={600} size="small">
                  Medium Priority
                </StyledTypography>
              </Stack>
              <Stack direction="row" alignItems="center">
                <FormControlLabel
                  value="low priority"
                  control={<Radio />}
                  sx={{
                    color: theme.palette.secondary.dark,
                    marginRight: 0,
                  }}
                />
                <StyledTypography fontWeight={600} size="small">
                  Low Priority
                </StyledTypography>
              </Stack>
            </Stack>
          </RadioGroup>
          <StyledViewAllButton size="small">Submit </StyledViewAllButton>
        </Stack>
      </StyledGrid>
    </Grid>
  );
}
