import { Grid, Tooltip } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { styled } from '@mui/system';
//import { fInitSMAuth } from 'lib/appFx';
//import { useDispatch, useSelector } from 'react-redux';
//---------------------------------------------------------

const SocialIconContainer = styled('div')(({ theme, active, arrow }) => ({
  // backgroundColor: 'Background',
  // display: 'grid',
  // gridTemplateColumns:"auto".
  /* align-items: center; */
  /* column-gap: 4px; */
  // padding: '5px',
  // borderRadius: '100%',
  // border: `2px solid  ${
  //   active ? theme.palette.primary.main : theme.palette.error.main
  // } `,
  // alignContent: 'center',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  // display: 'grid !important',
  // gridTemplateColumns: 'auto auto !important',
  // rowGap: '15px ',
  boxShadow: theme.shadows[5],
  backgroundColor: 'rgba(0,0,0,0)',
  textAlign: 'center',
  cursor: arrow,
  borderRadius: '4px',
}));

function SocialMediaDisabledTooltip({icon, sMKey, name}) {
  //const dispatch = useDispatch()
  const [show, setShow] = useState(false);
  //const [drdemo1,setdrdemo1] = useState('')
  const [open, setOpen] = useState(false);
  const [modalData, setModalData] = useState();
  const handleDisconnect = useCallback((data) => {
    setOpen(true);
    setModalData(data);
  });

  const fCheckSMConn = (e) => {
  	e.preventDefault();
	//if(theSMData && theSMData[sMKey] && !theSMData[sMKey]['activTokenId']) {
		//if(true) {
			const bEl = document.getElementById('btnFOpenSMCModal');
			//console.log('Hi '+sMKey+' '+typeof(bEl), bEl);
			bEl && bEl.click();
		//} else {
		//	const smConnEl = document.getElementById('lnkWidSMConn_'+sMKey);
		//	smConnEl && smConnEl.click();
		//}
	//}
  };
  //---------------------------------------------------------
  
  return (
    <Tooltip placement="top" arrow
      title={`Please connect ${name} to enable sharing`}
      open={show} onOpen={() => {setShow(true);}}
      onClose={() => {setShow(false);}}
      onClick={(e) => {        
        // dR.authUrl && disConnected
        // ? fAuthSM(e, dR.sMKey)
        // : handleDisconnect(dR);
        setShow(!show);
      }}
    >
      <span onClick={fCheckSMConn} style={{cursor: 'pointer'}}>{icon}</span>
    </Tooltip>
  );
}

export default SocialMediaDisabledTooltip;