import { Typography } from 'core';
//---------------------------------------------------------

export default function PageMessage({text, isError, size}) {
  isError = isError ?? false;
  size = (size ?? 'S').toUpperCase();
  switch(size) {
    case 'L': size = '1.2rem'; break;
    case 'M': size = '1rem'; break;
    case 'S': default: size = '0.8rem'; break;
  }
  return (
    <Typography align='center' sx={{
        fontSize: size, textAlign: 'center', padding: '0.2rem',
        //color: stAjaxStep === -1 ? '#e92425' : '#78c931',
        color: isError ? '#e92425' : '#78c931',
      }}
    >{text}</Typography>
  );
}