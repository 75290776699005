import { Grid, Box, } from '@mui/material'; //Stack, styled, IconButton
//import { Card } from 'core';
import AdmGroupInfo from './AdmGroupInfo';
//---------------------------------------------------------

let ctnCardSx = {height: {md:'16rem'}, width: '100%', maxWidth:'23rem', padding: '1.2rem'};
//ctnCardSx = {height: '14rem', width: '100%', maxWidth:'23rem', padding: '1.2rem'};
//FigHt: 16rem(0.88%)/actualRatioHt: 14rem
//ctnCardSx = {height: '16rem', width: '23rem', maxWidth:'23rem', minWidth: '23rem', padding: '1.2rem'};
const cardShadowCss = '0px 2px 4px rgba(0, 0, 0, 0.15)';
const cardShadowClipCss = 'inset(0px -10px -10px -10px)';
const ctnCardBoxSx = {
  display: 'flex', //alignItems:'left', //justifyContent: 'space-between',
  boxShadow: cardShadowCss,
  clipPath: cardShadowClipCss,
  margin: {xs: '0.5rem', sm: '0.5rem', md: '0 1.2rem 1.2rem 0',},//lg: ''},
  width: {xs: '100%', sm:'44%', smp: '46%', md: '30.7%', lg: '31.3%'},
};
//---------------------------------------------------------

export default function AdmGroupInfoCard ({
  theme, groupData, groupMethods, flgUseGrid=false
}) {

  //----------------------

  //---------------------------------------------------------

  return flgUseGrid ? (
    <Grid item xs={12} sm={4} md={4} //gap='1rem' rowGap='2rem'
      //gridTemplateColumns={'1fr 1fr !important'}
      id={'ctnAdmGrpCard_'+ groupData?.id}
    >
      <Box className='bgWh xbdr1' sx={{ ...ctnCardSx, boxShadow: cardShadowCss, clipPath: cardShadowClipCss}}>
        <AdmGroupInfo groupData={groupData} groupMethods={groupMethods} />
      </Box>
    </Grid>
  ) : (
    <Box sx={ctnCardBoxSx} id={'ctnAdmGrpCard_'+ groupData?.id}>
      <Box className='bgWh' sx={ctnCardSx}>
        <AdmGroupInfo groupData={groupData} groupMethods={groupMethods} />
      </Box>
    </Box>
  );

  /*return (
    <Grid container key={groupData.id}
      alignItems="center" justifyContent="center"
    >
      <Grid item xs={12} className='' sx={{border:'0px solid blue'}}>
        <Card className=''>
          <Grid container
            sx={{border:'0px solid blue', padding: '0'}}
            //flexDirection={FeedView['full'].feed_direction}
            justifyContent='space-between'
          >
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );*/
};
