import { useState, useEffect, useCallback, useMemo } from "react";
import { Box,  TextField, Button, InputAdornment
  //Grid, Paper, Card, Divider,
  //Avatar, Icon, ListItemIcon, ListItemText, MenuItem, Select,
} from "@mui/material";
//import { DataGrid } from '@mui/x-data-grid';
//import { TableVirtuoso } from 'react-virtuoso';
import SearchIcon from '@mui/icons-material/Search';
import { Typography, SpinnerBox, PageMessage } from 'core';
import { SA_DEF_ORG_GROUP_ID } from 'config/app.config';
import { fLeadDebounce, fRemoveDuplicateRows } from 'lib/appFx';
//AdmMemberAddRewardPointsDialog
import AdmGroupMemberDataTable from './AdmGroupMemberDataTable';
import { useGroupMemberListQuery, //useGroupDeleteMemberMutation
} from 'services/admin/admGroupsApi';
//import AdmGroupAddMemberModal from "./AdmGroupAddMemberModal";
import AdmGroupDelMemberDialog from './AdmGroupDelMemberDialog';
import AdmGroupAddMemberForm from './AdmGroupAddMemberForm';
//---------------------------------------------------------

const txSearchMemSx = {
  m: 0, width: {xs: '100%', md: '22rem'},// height: '2.5rem',
  //display: {xs: 'block', md: 'inline'},

  //"& .MuiOutlinedInput-input:focused": { border: '2px solid #9e9e9e  !important' },
  //"& .MuiOutlinedInput-root": {"& .Mui-focused fieldset": {borderColor: "#9e9e9e !important"} },
  //"& .MuiOutlinedInput-root": {"& .Mui-focused": {borderColor: "#9e9e9e !important"} },
  //"& .MuiTextField-root": {"& .Mui-focused": {borderColor: "#9e9e9e !important"} },
  //"& .Mui-focused": {borderColor: "#9e9e9e !important"},
  //fieldset: { borderColor: "red !important" },
  //"&:active fieldset": { borderColor: "red !important" },
};
//---------------------------------------------------------

export default function AdmGroupMemberList({theme, groupData}) {
  const groupId = groupData.id;
  const pageSize = 10;
  const [stCurrPage, setStCurrPage] = useState(1);
  const [stShowAddGrpMemForm, setStShowAddGrpMemForm] = useState(false);
  const [stRemGrpMemData, setStRemGrpMemData] = useState(null);//[]
  const [stAjaxStep, setStAjaxStep] = useState(0);//-1, 0, 1, 2`
  const [stSearchGrpMemTerm, setStSearchGrpMemTerm] = useState('');

  let admGroupMembers, admGrpMemCount=0, addGrpMemForm,
    remGrpMemModal, remGrpMemModalArgs, pgMsg;
  //console.log('groupDataGrpMems', groupData);

  const grpMemQArgs = { groupId: groupId, page: stCurrPage };
  if(stSearchGrpMemTerm.length > 2) {
    grpMemQArgs['filter'] = {};
    //{"search":{"field":"email","value":"john@example.com"}}
    //filter={"search":{"field":"name","value":"John"}}
    grpMemQArgs['filter']['search'] = {field: 'name', value: stSearchGrpMemTerm};
  }/* else if(stSearchGrpMemTerm.length === 0) {
    delete grpMemQArgs['filter'];
  }*/
  const {
    data: admGrpMemApiData, refetch: fRefetchGroupMembers,
    isLoading: admGrpMemWait, isFetching: admGrpMemWait2,
    isError: admGroupMemErr, isSuccess: admGroupMemSuccessApi,
  } = useGroupMemberListQuery(grpMemQArgs, { refetchOnMountOrArgChange: true } );
  if(admGroupMemSuccessApi) {
    admGroupMembers = admGrpMemApiData?.group_members.slice();
    admGroupMembers = structuredClone(admGroupMembers);
    //admGroupMembers = fRemoveDuplicateRows(admGroupMembers, 'id');
    if(admGrpMemApiData.hasOwnProperty('pagination')) {
      admGrpMemCount = admGrpMemApiData?.pagination?.records?.total || 0;
    } else {//if(admGroupMembers.length) {}
      admGrpMemCount = admGroupMembers?.length || 0;
    }
  }
  //console.log('admGroupMemData_'+groupId+'_'+admGrpMemCount, admGroupMembers);
  //---------------------------------------------------------

  const fSetCurrPage = (pg) => { setStCurrPage(pg); };
  const fSetSearchMemTerm = (e) => {//fLeadDebounce
    const txEl = e.target;
    if(txEl) {
      const txVal = txEl.value;console.log('fSetSearchMemTerm ', txVal);
      //txVal.length > 2 && setStSearchGrpMemTerm(txVal);
      //if(txVal.length > 2) {
        setStSearchGrpMemTerm(txVal);
        txEl.focus();
        //groupData.users = groupData.users.filter((usr) => usr.name.includes(txVal));
        //console.log(groupData.users);
      //}
    }
  }
  const fShowAddGroupMemberForm = () => {
    setStShowAddGrpMemForm(true);

    setTimeout(()=> {
      //const pgTopEl = document.getElementById('ctnFrmAdmAddGrpMem');
      const pgTopEl = document.getElementById('hdAdmAddGrpMem');
      //pgTopEl && pgTopEl.scrollIntoView({block: "start", behavior: 'smooth'});
      pgTopEl && pgTopEl.scrollIntoView({behavior: 'smooth'});
      const txEl = document.getElementById('txAddMemSearch');
      //txEl && setTimeout(()=>txEl.focus(), 1500);
      //console.log('els', {pgTopEl, txEl});
    }, 800);
  };
  const fCloseAddMemberForm = () => {
    setStShowAddGrpMemForm(false);
    //const pgTopEl = document.getElementById('txGrpMemSearch');
    const pgTopEl = document.getElementById('ctnFrmAdmAddGrpMem');
    pgTopEl && pgTopEl.scrollIntoView({behavior: 'smooth'});
  };
  if(stShowAddGrpMemForm && groupId !== SA_DEF_ORG_GROUP_ID) {
    /*const addGrpMemFormArgs = {
      isOpen: stShowAddGrpMemForm,
      //title: 'Add User',
      //groupData: {id: groupData.id, title: grpTitle},
      onDismiss: fCloseAddMemberForm,
    };
    addGrpMemForm = <AdmGroupAddMemberModal modalArgs={addGrpMemFormArgs}
      groupData={groupData} />*/
    addGrpMemForm = <AdmGroupAddMemberForm groupData={groupData}
      groupMethods={{
        onDismiss: fCloseAddMemberForm,
        fRefreshMemberList: fRefetchGroupMembers,
      }}
    />
  }
  //---------------------------------------------------------

  //const fCloseRemMemberDialog = (e, reason) => {
  const fCloseRemMemberDialog = (flgRefetch=false) => {
    setStRemGrpMemData(null); //setStAjaxStep(0);
    flgRefetch && fRefetchGroupMembers();
    const pgTopEl = document.getElementById('ctnFrmAdmAddGrpMem');
    pgTopEl && pgTopEl.scrollIntoView({behavior: 'smooth'});
  };

  const fInitRemoveGroupMemberHandler = useCallback((arrMemIds) => {
    console.log('fInitRemGrpMembHandler_'+groupId, arrMemIds);
    //if(groupId === SA_DEF_ORG_GROUP_ID) {
    setStRemGrpMemData(arrMemIds);
  }, [setStRemGrpMemData]);

  if(stRemGrpMemData) {
    let grpTitle = groupData?.name;
    remGrpMemModalArgs = {
      groupData,
      memberIds: stRemGrpMemData,
      isOpen: stRemGrpMemData ? true : false,
      //title: 'Remove Group Members',
      //groupData: {id: stDelGroupData.id, title: grpTitle},
      onDismiss: fCloseRemMemberDialog,
    };
    if(groupId === SA_DEF_ORG_GROUP_ID) {
      remGrpMemModalArgs['descText'] = 'Removing members from default group "' +
        grpTitle + '" is not allowed';
        remGrpMemModalArgs['onConfirm'] = null;
      setTimeout(() => { setStRemGrpMemData(null); }, 4000);
    } else {
      let selMemName = admGroupMembers.find(grpM => grpM.id === stRemGrpMemData[0]);
      selMemName = selMemName?.name || 'selected member';
      remGrpMemModalArgs['descText'] = 'Are you sure want to remove ' +
        selMemName + ' from  group "' + grpTitle + '"?';
        //remGrpMemModalArgs['onConfirm'] = fRemoveGrpMem;
        remGrpMemModalArgs['isActing'] = stAjaxStep;
    }
    remGrpMemModal = <AdmGroupDelMemberDialog modalArgs={remGrpMemModalArgs} />;
  }
  //---------------------------------------------------------
  
  /*const allRowCount = useMemo(()=> {
    return admGroupMembers?.length || 0;
  }, [groupData]);
  const currGroupMem = useMemo(()=> {
    //if(admGroupMembers?.length) {
      if(stSearchGrpMemTerm.length && admGroupMembers?.length) {
        let grpUsers = admGroupMembers;//.slice();
        //groupData.users = grpUsers.filter(
          grpUsers = grpUsers.filter(
          (usr) => usr.name.includes(stSearchGrpMemTerm) ||
            usr.email.includes(stSearchGrpMemTerm)
        );
        //return {...admGroupMembers};
        return grpUsers;
      }
    //}
    //return {...admGroupMembers};
    return admGroupMembers;
    //return admGroupMembers.slice();
  }, [admGrpMemApiData, stSearchGrpMemTerm, admGroupMembers]);
  console.log('currGroupMem_Pg'+stCurrPage, currGroupMem);*/
  //---------------------------------------------------------

  if(admGroupMemErr) {
    pgMsg = (
      <PageMessage size='M' isError={true}
        text={'Error fetching group members! Please try again later..'}
      />
    );
    //if(stSearchGrpMemTerm.length) { setStSearchGrpMemTerm(''); }
  } else if(admGrpMemWait || admGrpMemWait2) {
    pgMsg = (<SpinnerBox />);
  }
  useEffect(()=> {
    const txEl = document.getElementById('txGrpMemSearch');
    if(txEl?.value?.length) { txEl.focus(); }
  });
  //---------------------------------------------------------

  return (<>
    <Box className='xtBdr2 xctnItmAC'>
      <Box sx={{width: '80%'}} className='xbdr1' >
        {addGrpMemForm}
      </Box>
    </Box>
    <Box className="bgWh xbdr1 padL"
	  	sx={{minHeight: '12rem'}}
		>
      <Box className='secHd'>
        <Typography className="secHd">Members</Typography>
      </Box>
      <Box className='dInFNoW wA' sx={{margin: '0.8rem 0', display: {xs: 'block', sm: 'flex'} }}>
        <Box>
          <TextField id="txGrpMemSearch" className="txIn"
            size="small" label="" hiddenLabel
            disabled={!admGrpMemCount && stSearchGrpMemTerm.length === 0}
            placeholder="Search by name"
            //variant="standard" //disableUnderline
            value={stSearchGrpMemTerm}//defaultValue=''
            sx={txSearchMemSx}
            InputProps={{
              startAdornment: <InputAdornment position="start">
                <SearchIcon sx={{color:'#cecece', flexShrink: 0,
                  fontSize: "1.25rem", marginLeft: 1}}/>
              </InputAdornment>,
            }}
            //onChange={fSetSearchMemTerm} //onKeyUp={debounce}
            onChange={(e) => {fLeadDebounce(fSetSearchMemTerm(e))}}
          />
        </Box>
        {groupId !== SA_DEF_ORG_GROUP_ID &&
          <Box className='mLA' sx={{width: {xs: '100%', sm: 'auto'}}}>
            <Button className='cBtn cBtnDark' sx={{fontWeight: 700}}
              onClick={fShowAddGroupMemberForm}>
              Add User</Button>
          </Box>
        }
      </Box>
      <Box className='xbdr1' sx={{margin: '0.8rem 0'}}>
        {admGroupMembers ? (
          <AdmGroupMemberDataTable
            memTableData={{
              //members: admGroupMembers,
              //members: currGroupMem,
              memberData: admGroupMembers,
              memberGT: admGrpMemCount,
              pageSize: pageSize,
              currPage: stCurrPage,
              filterTerm: stSearchGrpMemTerm
            }}
            memTableMethods={{
              fRemoveGroupMember: fInitRemoveGroupMemberHandler,
              fSetCurrPage: fSetCurrPage
            }}
          />
        )  : (pgMsg ? pgMsg : null)}
        {/*addGrpMemForm*/}
        {remGrpMemModal}
      </Box>
    </Box>
    </>
  );
};
