import { styled, Typography as MuiTypography } from "@mui/material";
import { grey, orange, red } from "@mui/material/colors";

const FONT_SIZES = {
  xsmall: "12px",
  small: "15px",
  medium: "18px",
  large: "22px",
  xlarge: "25px",
};

const getColors = (theme, color_variant) => {
  const colors = {
    dark: theme.palette.secondary.dark,
    light: grey[600],
    lightGrey: grey[500],
    orange: orange[500],
    error: theme.palette.error.main,
    white: "#fff",
  };
  return colors[color_variant];
};

const Typography = styled(MuiTypography)(
  ({ theme, size, sx, color_variant }) => ({
    color: getColors(theme, color_variant) || theme.palette.secondary.dark,
    fontSize: FONT_SIZES[size],
    // letterSpacing: "1px",
    ...sx,
  })
);

export default Typography;
