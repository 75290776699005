import { useState } from "react";
import PropTypes from "prop-types";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
//---------------------------------------------------------

function fNot(arrA, arrB) {//items from a not in b
  return arrA.filter(val => arrB.indexOf(val) === -1);
}

function fIntersection(arrA, arrB) {
  return arrA.filter(val => arrB.indexOf(val) !== -1);
}
//---------------------------------------------------------

export default function TransferList({listItemLeft, listItemRight}) {
  const [stChecked, setStChecked] = useState([]);
  const [stLeft, setStLeft] = useState(listItemLeft);
  const [stRight, setStRight] = useState(listItemRight);

  const leftChecked = fIntersection(stChecked, stLeft);
  const rightChecked = fIntersection(stChecked, stRight);

  const fHandleToggle = (value) => () => {
    const currIndex = stChecked.indexOf(value);
    const newChecked = [...stChecked];

    if(currIndex === -1) { newChecked.push(value); }
    else { newChecked.splice(currIndex, 1); }

    setStChecked(newChecked);
  };

  const fHandleAllRight = () => {
    setStRight(stRight.concat(stLeft));
    setStLeft([]);
  };

  const fHandleCheckedRight = () => {
    setStRight(stRight.concat(leftChecked));
    setStLeft(fNot(stLeft, leftChecked));
    setStChecked(fNot(stChecked, leftChecked));
  };

  const fHandleCheckedLeft = () => {
    setStLeft(stLeft.concat(rightChecked));
    setStRight(fNot(stRight, rightChecked));
    setStChecked(fNot(stChecked, rightChecked));
  };

  const fHandleAllLeft = () => {
    setStLeft(stLeft.concat(stRight));
    setStRight([]);
  };

  const ctnListSx = { width: 200, height: 230, overflow: 'auto' };
  const fGetItemList = (items) => (
    <Paper sx={ctnListSx}>
      <List dense component="div" role="list">
        {items.map((value) => {
          const labelId = 'lblTransferListItem_'+value;

          return (
            <ListItemButton key={value}
              role="listitem" onClick={fHandleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox disableRipple
                  checked={stChecked.indexOf(value) !== -1}
                  tabIndex={-1}
                  inputProps={{ 'aria-labelledby': labelId, }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={'List item '+value + 1} />
            </ListItemButton>
          );
        })}
      </List>
    </Paper>
  );
  //---------------------------------------------------------

  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid item>{fGetItemList(stLeft)}</Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button variant="outlined" size="small"
            sx={{ my: 0.5 }}            
            onClick={fHandleAllRight}
            disabled={stLeft.length === 0}
            aria-label="Move All Right"
          >
            ≫
          </Button>
          <Button variant="outlined" size="small"
            sx={{ my: 0.5 }}
            onClick={fHandleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="Move Right"
          >
            &gt;
          </Button>
          <Button variant="outlined" size="small"
            sx={{ my: 0.5 }}
            onClick={fHandleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="Move Left"
          >
            &lt;
          </Button>
          <Button variant="outlined" size="small"
            sx={{ my: 0.5 }}
            onClick={fHandleAllLeft}
            disabled={stRight.length === 0}
            aria-label="Move All Left"
          >
            ≪
          </Button>
        </Grid>
      </Grid>
      <Grid item>{fGetItemList(stRight)}</Grid>
    </Grid>
  );
}
//---------------------------------------------------------

TransferList.propTypes = {
  listItemLeft: PropTypes.array.isRequired,
  listItemRight: PropTypes.array.isRequired,
};