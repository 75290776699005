import * as React from "react";
import MuiBackdrop from "@mui/material/Backdrop";
import { Box, styled } from "@mui/material";
import rightArrow from "../AlbumPost/icons/right-arrow.svg";
import leftArrow from "../AlbumPost/icons/left-arrow.svg";
import "./style.css";
import { Close } from "@mui/icons-material";

const CloseIconContainer = styled(Box)(({ theme, albumpost }) => ({
  backgroundColor: "#000",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "4px",
  width: "36px",
  height: "36px",
  zIndex: 90,
  cursor: "pointer",
  position: "absolute",
  right: albumpost ? "3%" : "1.5%",
  top: albumpost ? "8%" : "7.5%",
  [theme.breakpoints.down("sm")]: {
    top: albumpost ? "2%" : "1%",
    right: albumpost ? "2%" : "1%",
  },
}));
const AlbumContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100%",
  marginTop: "4rem",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));
const Container = styled(Box)(({ theme }) => ({
  maxWidth: "100%",
  maxHeight: "100%",
  marginTop: "4rem",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));
function BtnSlider({ direction, moveSlide }) {
  return (
    <button
      onClick={moveSlide}
      className={
        direction === "next"
          ? "backdrop-btn-slide backdrop-next"
          : "backdrop-btn-slide backdrop-prev"
      }
    >
      <img src={direction === "next" ? rightArrow : leftArrow} alt="arrow" />
    </button>
  );
}

export default function Backdrop({ children, onClose, open }) {
  const [slideIndex, setSlideIndex] = React.useState(1);

  const nextSlide = () => {
    if (slideIndex !== children.length) {
      setSlideIndex(slideIndex + 1);
    } else if (slideIndex === children.length) {
      setSlideIndex(1);
    }
  };

  const prevSlide = () => {
    if (slideIndex !== 1) {
      setSlideIndex(slideIndex - 1);
    } else if (slideIndex === 1) {
      setSlideIndex(children.length);
    }
  };

  const moveDot = (index) => {
    setSlideIndex(index);
  };

  return (
    <div>
      <MuiBackdrop
        sx={{ color: "#fff", zIndex:99999 }}
        open={open}
      >
        {children.length > 1 ? (
          <AlbumContainer>
            <CloseIconContainer albumpost="true">
              <Close onClick={onClose} />
            </CloseIconContainer>
            <div className="backdrop-container-slider">
              {children.map((obj, index) => {
                return (
                  <div
                    key={index}
                    className={
                      slideIndex === index + 1
                        ? "backdrop-slide backdrop-active-anim"
                        : "backdrop-slide"
                    }
                  >
                    <img
                      src={obj.props.children.props.src}
                      alt={obj.props.children.props.alt}
                    />
                  </div>
                );
              })}

              <BtnSlider moveSlide={nextSlide} direction={"next"} />
              <BtnSlider moveSlide={prevSlide} direction={"prev"} />

              <div className="backdrop-container-dots">
                {Array.from({ length: 5 }).map((item, index) => (
                  <div
                    key={index}
                    onClick={() => moveDot(index + 1)}
                    className={
                      slideIndex === index + 1
                        ? "backdrop-dot active"
                        : "backdrop-dot"
                    }
                  ></div>
                ))}
              </div>
            </div>
          </AlbumContainer>
        ) : (
          <Container>
            <CloseIconContainer>
              <Close onClick={onClose} />
            </CloseIconContainer>
            {children}
          </Container>
        )}
      </MuiBackdrop>
    </div>
  );
}
