//import { useLocation, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectAuthToken } from 'services/auth/authSlice';
import { ProtectedLayout } from 'layout';
import { FLG_DISABLE_AUTH_PAGES, NIU_HEALTH_BASE_URL } from 'config/app.config'
//---------------------------------------------------------

const RequireUserAuth = () => {
  //const location = useLocation();
  //const userAuthToken = useSelector(selectAuthToken);
  let userAuthToken = useSelector(selectAuthToken);
  //console.log('uAT', userAuthToken);

  //let userAuthTokenLS;

  if(!userAuthToken) {
    try {
      const usrAuthData = JSON.parse(localStorage.getItem('usrAuthData'));
      userAuthToken = usrAuthData?.token;
    } catch(err) {
      //userAuthToken = userAuthToken
    }
  }

  const handleRedirect = () => {
    //window.location.href = "signin";
    //window.location.href = NIU_HEALTH_BASE_URL + '/login';
    if(FLG_DISABLE_AUTH_PAGES) {
      window.location.href = NIU_HEALTH_BASE_URL + '/login';
    } else {
      window.location.href = '/signin';
    }
  };
  //  userAuthToken ? return (
  //   <ProtectedLayout />
  // ) :
  // // (
  // //   <Navigate to="/signin" state={{ from: location }} replace={true} />
  // // )
  // {
  //   handleRedirect()
  // }
  // ;
  if(userAuthToken) {//userAuthTokenLS
    return <ProtectedLayout />;
  } else handleRedirect();
};
export default RequireUserAuth;
