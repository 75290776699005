import { SA_APP_HOSTNAME } from '../config/app.config';
//---------------------------------------------------------

export const parseHttpHeaders = (httpHeaders) => {
	//parseHttpHeaders(new XmlHttpRequest.getAllResponseHeaders());
    return httpHeaders.split("\n")
     .map(x=>x.split(/: */,2))
     .filter(x=>x[0])
     .reduce((ac, x)=>{ac[x[0]] = x[1];return ac;}, {});
}

export const parseQStr = (str) => {
	let res={}, theKV = str.substring(0, 1) === '?' ?// str.startsWith('?')
	 	str.slice(1).split('&') : str.split('&');
	theKV.forEach(sKV => {
		let tKV = sKV.split('=');
		res[tKV[0]] = decodeURIComponent(tKV[1] || '');
	});
	return res;
}

export const j2q = (jKV) => {//jKV[k]!==null
	return Object.keys(jKV).map(k => k+'='+jKV[k]).join('&');
}

export const fWriteCook = (cName, cVal, maxAge=1200)=> {
	//const fTest=1;
	//maxAge = maxAge ?? fTest ? 1200 : 120;
	const cDt = new Date();
	cDt.setSeconds(cDt.getSeconds() + maxAge);
	let cStr = cName + '=' + encodeURIComponent(cVal);
	cStr += ';expires=' + cDt + ';max-age='+(maxAge*1)+';samesite:strict';
	cStr += ';domain=' + SA_APP_HOSTNAME;
	cStr += ';path=/;Secure';//HttpOnly
	document.cookie = cStr;
	return true;
}

export const fGetCookie = (cName) => {
    let match = document.cookie.match(RegExp('(?:^|;\\s*)' + cName + '=([^;]*)'));
    return match ? decodeURIComponent(match[1]) : null;
}

export const fDelCookie = (cName) => {
	document.cookie = cName + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;domain=' + SA_APP_HOSTNAME+';path=/';
}

export const getFileExtension = fName => {
	fName = fName && fName.split('?')[0];
	return fName.split('.').pop();
}

export const isValidUrl = urlString=> {
	var urlPattern = new RegExp('^(https?:\\/\\/)?' +
	'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
	'((\\d{1,3}\\.){3}\\d{1,3}))' +
	'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
	'(\\?[;&a-z\\d%_.~+=-]*)?' +
	'(\\#[-a-z\\d_]*)?$','i');
	return urlPattern.test(urlString);
}
