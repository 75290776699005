import { styled } from "@mui/material";
import MuiTooltip, { tooltipClasses } from "@mui/material/Tooltip";

const Tooltip = styled(({ theme, className, ...props }) => (
  <MuiTooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "transparent",
    fontSize: theme.typography.pxToRem(12),
    // padding: "1rem",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.secondary.dark,
  },
}));

export default Tooltip;
