import { Close } from "@mui/icons-material";
import { IconButton, Stack } from "@mui/material";
import { DEF_ENG_POINT } from 'config/app.config';
import { socialMedia } from 'config/ui.config';
import { fGetPostEngPoints } from 'lib/appFx';
import { Modal, Typography } from 'core';
import Home from "pages/home/Home";
import { useTranslation } from "react-i18next";
//---------------------------------------------------------

export default function PointsBreakupCard({
  //points,
  engPoints,
  pInff,
  theme,
  showPointsBreakupFeedForm,
  onClosePointsBreakupFeedForm,
}) {//console.log('engPointsPBU', engPoints);
  const sSK = pInff.srcKey;
  const pEngPtsInf = fGetPostEngPoints(sSK, pInff?.engPoints);
  const { t: home } = useTranslation('home')
  const SocialIcons = [
    {
      id: "a1R3BYmO",
      icon: socialMedia["facebook"],
      //points: engPoints['fb']['share'],//0
      points: pEngPtsInf[sSK]['fb']['engPoints'],
    },
    {
      id: "Yjglz6Ry",
      icon: socialMedia["twitter"],
      //points: engPoints['tw']['share'],
      points: pEngPtsInf[sSK]['tw']['engPoints'],

    },
    {
      id: "BX4vPzmo",
      icon: socialMedia["linkedIn"],
      //points: engPoints['ln']['qShare'],
      points: pEngPtsInf[sSK]['ln']['engPoints'],
    },
    /*{
      id:"MzR9v14E",
      icon: socialMedia["instagram"],
      points: engPoints['ig']['share'],
    },
    {
      id:"",
      icon: socialMedia["youtube"],
      points: engPoints['yt']['share'],
    },*/
  ];



  // const mainPoints =  points?.reduce((obj,value) => {

  //   	if(obj[value?.snetworkplatform_id]?.id===value?.snetworkplatform_id){

  //     	obj[value?.snetworkplatform_id]?.value = obj[value?.snetworkplatform_id]?.value + value?.points
  //   	}
  //   	else{
  //     	obj[value?.snetworkplatform_id]?.id=value?.snetworkplatform_id
  //     	obj[value?.snetworkplatform_id]?.value =  value?.points
  //   	}
  // 	},new Object()
  // )

  return (
    <Modal open={showPointsBreakupFeedForm}>
      <Stack spacing={2}>
        <Stack spacing={2} direction="row" justifyContent="space-between">
          <Typography size="large">{home('coins_breakup')}</Typography>

          <IconButton onClick={onClosePointsBreakupFeedForm}>
            <Close color="primary" />
          </IconButton>
        </Stack>

        {SocialIcons.map((pointsBreakUp, index) => (
          <Stack direction="row" spacing={1} alignItems="end" key={index}>
            <Typography size="medium">{home('share_on')}</Typography>
            {pointsBreakUp.icon}
            <Typography size="medium">: {pointsBreakUp.points}</Typography>
          </Stack>
        ))}
      </Stack>
    </Modal>
  );
}
