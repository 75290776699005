import { Grid, styled } from "@mui/material";
import { PageContainer } from "core";
import React from "react";
import { DiscoverdContent } from "sections";

const StyledGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    maxWidth: "100%",
  },
}));
export default function Discoverd({ title, theme }) {
  return (
    <PageContainer title={title}>
      <Grid container spacing={5}>
        <StyledGrid item xs={12}>
          <DiscoverdContent theme={theme} />
        </StyledGrid>
      </Grid>
    </PageContainer>
  );
}
