import { CssBaseline } from "@mui/material";
import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider as MuiThemeProvider,
  useTheme,
} from "@mui/material/styles";
import breakpoints from "./breakpoints";
//---------------------------------------------------------

function ThemeProvider({ children }) {
  const defaultTheme = useTheme();

  const theme = createTheme({
    ...defaultTheme,
    breakpoints,
    shape: { borderRadius: 2 },
    palette: {
      mode: "light",
      background: {
        paper: "#fff",
        default: '#edf0f5',//"#F0F0F0",
        // default: "#dae0e6",
        section: "#DDE4E8",
      },
      primary: {
        dark: "#49AE41",
        main: "#5BD951",
        light: "#7CE174",
        lighter: "#8BDA41",
      },
      secondary: {
        dark: "#00406B",
        main: "#006DB7",
        light: "#0089E5",
        lighter: "#39A0CD",
      },
      success: {
        main: "#49AE41",
      },
      warning: {
        main: "#F7DC2F",
      },
      error: {
        main: "#F15946",
      },
    },
    typography: {
      // fontFamily: "Montserrat",
      h6: {
        fontWeight: 500,
        fontSize: "0.75rem",
      },
      h5: {
        fontSize: "24px",
        lineHeight: "32px",
        fontWeight: 500,
      },
      h4: {
        fontSize: "32px",
        lineHeight: "40px",
        fontWeight: 600,
      },
      h3: {
        fontSize: "48px",
        lineHeight: "56px",
        fontWeight: 600,
      },
      h2: {
        fontSize: "56px",
        lineHeight: "64px",
        fontWeight: 700,
      },
      h1: {
        fontSize: "64px",
        lineHeight: "72px",
        fontWeight: 700,
      },
      subtitle1: {
        fontSize: "0.875rem",
        fontWeight: 500,
      },
      subtitle2: {
        fontSize: "0.75rem",
        fontWeight: 400,
      },
      caption: {
        fontSize: "0.75rem",
        fontWeight: 400,
      },
      body1: {
        fontSize: "0.9rem",
        fontWeight: 400,
        lineHeight: "1.334em",
      },
      body2: {
        letterSpacing: "0em",
        fontWeight: 400,

        lineHeight: "1.5em",
      },
    },
    components: {
      // MuiButton: {
      //   styleOverrides: {
      //     root: {
      //       borderRadius: "10rem",
      //     },
      //   },
      // },
      // MuiInputBase: {
      //   styleOverrides: {
      //     root: {
      //       background: theme.palette.background.paper,
      //       marginTop: "0.5rem",
      //       marginBottom: "0.5rem",
      //     },
      //   },
      // },
      MuiInputBase: {
        input: {
          '&:-webkit-autofill': {
            transitionDelay: '9999s',
            transitionProperty: 'background-color, color',
          },
        }
      },
    },
  });

  return (
    <StyledEngineProvider injectFirst>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MuiThemeProvider>
    </StyledEngineProvider>
  );
}

export default ThemeProvider;
