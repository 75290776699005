import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  styled,
} from "@mui/material";
import { Button, FormInputField, FormProvider, Typography } from "core";
import React from "react";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { grey } from "@mui/material/colors";
import { AUTH_ROUTES } from "config/routes.config";
import { Link } from "react-router-dom";
import { useUserUpdateMutation } from "services/userApi";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { AccountSettingSchemaEditPassword } from "schema";
import NoEncryptionOutlinedIcon from "@mui/icons-material/NoEncryptionOutlined";
import { useSelector } from "react-redux";
import { selectAuthUserId } from "services/auth/authSlice";
import { useTranslation } from "react-i18next";

const StyledButton = styled(Button)(({ theme }) => ({
  maxWidth: "150px",
  borderRadius: "2px ",
  border: `3px solid ${grey[400]}`,
  boxShadow: "none",
  textTransform: "none",
  color: theme.palette.secondary.dark,
  fontWeight: 900,
  backgroundColor: theme.palette.background.paper,
  "&:hover": {
    backgroundColor: theme.palette.background.paper,
    boxShadow: "none",
  },
}));

const StyledStack = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(6),
  [theme.breakpoints.down("sm")]: {
    gap: theme.spacing(2),
  },
}));
const StyledLink = styled(Typography)(({ theme }) => ({
  textDecoration: "underline",
  cursor: "pointer",

  [theme.breakpoints.down("sm")]: {
    fontSize: "13px",
  },
}));
export default function EditPasswordForm({ theme }) {
  const { t: home } = useTranslation('home')
  const [
    updateUser,
    { isError: isUpdatePasswordError, refetch: refetchProfileDetails },
  ] = useUserUpdateMutation();

  const defaultValues = {
    current_password: "",
    new_password: "",
    retype_password: "",
  };

  const methods = useForm({
    resolver: yupResolver(AccountSettingSchemaEditPassword),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { errors },
  } = methods;

  const isError = (fieldName) =>
    errors[fieldName] ? theme.palette.error.light : theme.palette.primary.main;

  const [isNewPasswordVisible, setNewPasswordVisible] = React.useState(false);

  const changeNewPasswordState = () => setNewPasswordVisible((prev) => !prev);

  const [isRetypePasswordVisible, setRetypePasswordVisible] =
    React.useState(false);

  const changeRetypePasswordState = () =>
    setRetypePasswordVisible((prev) => !prev);

  const [isCurrentPasswordVisible, setCurrentPasswordVisible] =
    React.useState(false);

  const changeCurrentPasswordState = () =>
    setCurrentPasswordVisible((prev) => !prev);
  const userAuthId = useSelector(selectAuthUserId);

  const onSubmit = async (data) => {
    let formData = new FormData();

    formData.append("password", data.new_password);

    await updateUser({ userId: userAuthId, params: formData });
    if (isUpdatePasswordError === false) {
      refetchProfileDetails();
    }
  };
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={5} justifyContent="center">
        <Grid item xs={12} sm={8} md={9} lg={7}>
          <Box
            sx={{
              backgroundColor: theme.palette.background.section,
              padding: "10px 1rem",
            }}
          >

            <Typography
              color_variant="dark"
              fontSize="medium"
              sx={{
                fontWeight: 900,
                textTransform: "capitalize ",
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
              }}
            >
              {home('change_password')}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={8} md={9} lg={7}>
          <Stack spacing={1}>
            <Stack spacing={2} direction="row">
              <Grid item xs={4} display="flex" alignItems="center">
                <Typography
                  color_variant="lightGrey"
                  fontWeight={600}
                  fontSize="1rem"
                  textAlign="start"
                >
                  {home('current_password')}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <FormInputField
                  type={!isCurrentPasswordVisible ? "text" : "password"}
                  name="current_password"
                  color="secondary"
                  fullWidth
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& > fieldset": { borderColor: "white" },
                    },
                  }}
                  InputProps={{
                    style: {
                      backgroundColor: "Background",
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          onClick={changeCurrentPasswordState}
                        >
                          {!isCurrentPasswordVisible ? (
                            <LockOutlinedIcon
                              color={isError("retype_password")}
                            />
                          ) : (
                            <NoEncryptionOutlinedIcon
                              color={isError("retype_password")}
                            />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Stack>
            <Stack spacing={2} direction="row">
              <Grid item xs={4} display="flex" alignItems="center">
                <Typography
                  color_variant="lightGrey"
                  fontWeight={600}
                  fontSize="1rem"
                  textAlign="start"
                >
                  {home('new_password')}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <FormInputField
                  name="new_password"
                  type={!isNewPasswordVisible ? "text" : "password"}
                  color="secondary"
                  fullWidth
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& > fieldset": { borderColor: "white" },
                    },
                  }}
                  InputProps={{
                    style: { backgroundColor: "Background" },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          onClick={changeNewPasswordState}
                        >
                          {!isNewPasswordVisible ? (
                            <LockOutlinedIcon color={isError("new_password")} />
                          ) : (
                            <NoEncryptionOutlinedIcon
                              color={isError("new_password")}
                            />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Stack>

            <Stack spacing={2} direction="row">
              <Grid item xs={4} display="flex" alignItems="center">
                <Typography
                  color_variant="lightGrey"
                  fontWeight={600}
                  fontSize="1rem"
                  textAlign="start"
                >
                  {home('retype_new_password')}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <FormInputField
                  type={!isRetypePasswordVisible ? "text" : "password"}
                  name="retype_password"
                  color="secondary"
                  fullWidth
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& > fieldset": { borderColor: "white" },
                    },
                  }}
                  InputProps={{
                    style: { backgroundColor: "Background" },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          onClick={changeRetypePasswordState}
                        >
                          {!isRetypePasswordVisible ? (
                            <LockOutlinedIcon
                              color={isError("retype_password")}
                            />
                          ) : (
                            <NoEncryptionOutlinedIcon
                              color={isError("retype_password")}
                            />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Stack>

            <Grid item xs={12} md={12}>
              <StyledStack
                direction="row"
                justifyContent="right"
                alignItems="center"
              >
                {/*<StyledLink
                  size="small"
                  fontWeight={800}
                  color_variant="dark"
                  component={Link}
                  to={AUTH_ROUTES.ForgotPassword.url}
                >
                  Forgot your password?
                </StyledLink>*/}
                <StyledButton type="submit">{home('save_changes')}</StyledButton>
              </StyledStack>
            </Grid>
          </Stack>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
