import {
  Box,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Stack,
  styled,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { Button, Typography } from "core";
import React from "react";
import { useTranslation } from "react-i18next";

const StyledButton = styled(Button)(({ theme }) => ({
  maxWidth: "150px",
  borderRadius: "2px ",
  border: `3px solid ${grey[400]}`,
  boxShadow: "none",
  textTransform: "none",
  color: theme.palette.secondary.dark,
  fontWeight: 900,
  backgroundColor: theme.palette.background.paper,
  "&:hover": {
    backgroundColor: theme.palette.background.paper,
    boxShadow: "none",
  },
}));
export default function DelegatePermission({ theme }) {
  const { t: home } = useTranslation('home')
  return (
    <Grid container spacing={5} justifyContent="center">
      <Grid item xs={12} sm={8} md={9} lg={7}>
        <Box
          sx={{
            backgroundColor: theme.palette.background.section,
            padding: "10px 1rem",
          }}
        >
          <Typography
            fontSize="medium"
            color_variant="dark"
            sx={{
              fontWeight: 900,
              textTransform: "capitalize ",
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
            }}
          >
            {home('delegate_permission')}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent="center" alignItems="center">
          <Grid
            item
            xs={12}
            sm={7.8}
            md={8.8}
            lg={6.8}
            justifyContent="start"
            display="flex"
          >
            <Stack spacing={4}>
              <Typography
                size="medium"
                fontWeight="600"
                color_variant="lightGrey"
                sx={{ textAlign: "start" }}
              >
                {home('delegate_permission_to_an_admin_access_your_account')}
              </Typography>
              <FormControl
                id="demo-simple-select-readonly-label"
                sx={{
                  m: 1,
                  minWidth: 120,
                  background: theme.palette.background.paper,
                  maxWidth: 300,
                  ".MuiOutlinedInput-notchedOutline": {
                    border: 0,
                    outline: 0,
                  },
                }}
                size="small"
              >
                <Select
                  color="secondary"
                  defaultValue={"Gaurav M."}
                  id="demo-simple-select-readonly-label"
                >
                  <MenuItem value={"Gaurav M."}>Gaurav M.</MenuItem>
                </Select>
              </FormControl>
              <StyledButton>{home('save_changes')}</StyledButton>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
