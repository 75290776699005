import {
  Box,
  Grid,
  LinearProgress,
  linearProgressClasses,
  Stack,
  styled,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { Typography } from "core";
import Spinner2 from "core/Spinner/Spinner2";
import moment from "moment";
import React from "react";
import { useBadgeListQuery } from "services/badgesApi";

const StyledBadgesCard = styled(Box)(({ theme, highlight }) => ({
  flexDirection: "column",
  display: "flex",
  alignItems: "center",
  flexGrow: "1",
  justifyContent: "center",
  backgroundColor: highlight ? "#F5F5F5" : theme.palette.background.paper,
  height: "21rem",
  gap: "0.2rem",
  borderBottom: highlight && "3px solid #e6e7e9",
  borderTop: highlight && "3px solid #e6e7e9",
  border: !highlight && "3px solid #e6e7e9",
  position: "relative",
}));

const StyledBadgesHeader = styled(Stack)(({ theme, highlight }) => ({
  padding: "0.5rem",
  backgroundColor: theme.palette.primary.lighter,
}));
const StyledBadgesBox = styled(Stack)(({ theme, highlight }) => ({
  borderBottom: `4px solid ${theme.palette.background.default}`,
  width: "100%",
  height: "14rem",
  padding: "1rem 0 1.5rem ",
  alignItems: "center",
}));

const StyledBox = styled(Box)(({ completed }) => ({
  width: "100%",
  height: "15rem",
  background: "#00000069",
  position: "absolute",
  top: "-3px",
  display: typeof completed !== "number" && "none",
}));

const StyledGridContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));
const StyledGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    maxWidth: "100%",
  },
}));

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 8,
  borderRadius: 5,
  width: "10rem",
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.primary.lighter,
  },
}));

export default function BadgesList({ title, theme }) {
  const { data: badgeData, isFetching } = useBadgeListQuery();

  const badge = badgeData?.result?.data?.badges;

  return (
    <>
      <StyledBadgesHeader>
        <Typography size="large" color_variant="white">
          {title}
        </Typography>
      </StyledBadgesHeader>
      {isFetching ? (
        <Spinner2 />
      ) : (
        <StyledGridContainer container direction="row" rowGap={4}>
          {badge &&
            badge.map((data, index) => (
              <StyledGrid item xs={4} key={index}>
                <StyledBadgesCard highlight={data.highlight}>
                  <StyledBadgesBox>
                    <img
                      src={data?.media?.pictures?.url}
                      alt=""
                      style={{ height: "12rem" }}
                    />
                  </StyledBadgesBox>

                  <StyledBox completed={data.completed}>
                    <Box sx={{ height: "13.8rem" }}>
                      <Typography
                        color_variant="white"
                        fontSize="30px"
                        fontWeight={900}
                        sx={{ letterSpacing: "3px", padding: "10px 0" }}
                      >
                        LOCKED
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <BorderLinearProgress
                        variant="determinate"
                        value={data.completed || 0}
                      />
                    </Box>
                  </StyledBox>
                  <Stack
                    spacing={0.5}
                    justifyContent="center"
                    alignItems="center"
                    sx={{ height: "10rem" }}
                  >
                    <Stack>
                      <Typography
                        size="small"
                        color_variant="lightGrey"
                        fontWeight={800}
                      >
                        {typeof data.completed === "number"
                          ? `${data.completed}% COMPLETED`
                          : data.completed}
                      </Typography>
                      <Typography
                        size="small"
                        // color_variant="lightGrey"
                        fontWeight={600}
                        sx={{ color: grey[400] }}
                      >
                        {data.description}
                      </Typography>
                    </Stack>
                    <Typography
                      size="small"
                      color_variant={
                        typeof data.completed === "number"
                          ? "dark"
                          : "lightGrey"
                      }
                      sx={{ maxWidth: "195px" }}
                    >
                      Unlocked on:{" "}
                      {moment(data.created_at).format("DD/MM/YYYY")}
                    </Typography>
                  </Stack>
                </StyledBadgesCard>
              </StyledGrid>
            ))}
        </StyledGridContainer>
      )}
    </>
  );
}
