import { Skeleton, Stack } from "@mui/material";
import React from "react";

function ContentSuggestionListSkeleton() {
  return (
    <Stack spacing={3}>
      <Stack spacing={1}>
        <Skeleton variant="rounded" width={200} height={10} />
        <Skeleton variant="rounded" width={100} height={10} />
      </Stack>
      <Stack spacing={1}>
        <Skeleton variant="rounded" width={200} height={10} />
        <Skeleton variant="rounded" width={100} height={10} />
      </Stack>
      <Stack spacing={1}>
        <Skeleton variant="rounded" width={200} height={10} />
        <Skeleton variant="rounded" width={100} height={10} />
      </Stack>
    </Stack>
  );
}

export default ContentSuggestionListSkeleton;
