import { Avatar, Box, Divider, Stack, styled } from "@mui/material";
import { Button, Typography } from "core";
import Slider from "react-slick";
import React from "react";
import { grey } from "@mui/material/colors";
import { useTranslation } from "react-i18next";

const StyledAnalyticsHeader = styled(Stack)(({ theme, highlight }) => ({
  padding: "0.5rem 1rem",
  backgroundColor: theme.palette.secondary.dark,
}));

const StyledWhoFollowYou = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.background.section,
  padding: "2rem",
}));
const StyledFollowButton = styled(Button)(({ theme }) => ({
  background: theme.palette.background.paper,
  color: grey[600],
  boxShadow: "none",
  fontSize: "13px",
  maxWidth: "120px",
  "&:hover": {
    background: theme.palette.background.paper,
  },
}));
const users = [
  {
    name: "Manish",
    profile: "/assets/images/profile.png",
    role: "Enterpreneur and Digital Marketer",
    position: "Founder at sociosuqares",
  },
  {
    name: "Jason",
    profile: "/assets/images/profile.png",
    role: "Enterpreneur and Digital Marketer",
    position: "Founder at sociosuqares",
  },
  {
    name: "Jason",
    profile: "/assets/images/profile.png",
    role: "Enterpreneur and Digital Marketer",
    position: "Founder at sociosuqares",
  },
  {
    name: "Smith",
    profile: "/assets/images/profile.png",
    role: "Enterpreneur and Digital Marketer",
    position: "Founder at sociosuqares",
  },
  {
    name: "Jhon",
    profile: "/assets/images/profile.png",
    role: "Enterpreneur and Digital Marketer",
    position: "Founder at sociosuqares",
  },
];

export default function WhoToFollowCard({ theme }) {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
  };
  const { t: home } = useTranslation('home')
  return (
    < Stack >
      <StyledAnalyticsHeader>
        <Typography size="large" color_variant="white" textAlign="center">
          {home('who_to_follow')}
        </Typography>
      </StyledAnalyticsHeader>
      <StyledWhoFollowYou>
        <Slider {...settings}>
          {users.map(({ role, name, position, profile }) => (
            <Box>
              <Stack
                spacing={4}
                direction="row"
                alignItems="center"
              // divider={<Divider orientation="vertical" flexItem />}
              >
                <Avatar
                  src={profile}
                  alt={name}
                  sx={{ height: 70, width: 70 }}
                />
                <Stack spacing={1}>
                  <Box>
                    <Typography sx={{ color: "#000" }} size="large">
                      {name}
                    </Typography>

                    <Typography sx={{ color: "#000" }} size="small">
                      {role}
                    </Typography>
                    <Typography sx={{ color: "#000" }} size="small">
                      {position}
                    </Typography>
                  </Box>
                  <StyledFollowButton size="small">{home('follow')}</StyledFollowButton>
                </Stack>
                <Divider orientation="vertical" flexItem />
              </Stack>
            </Box>
          ))}
        </Slider>
      </StyledWhoFollowYou>
    </Stack >
  );
}
