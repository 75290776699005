import { useState, useEffect, /*useMemo*/ } from "react";
import { useSelector } from "react-redux";
import {
  Paper, Grid, Box, Divider, Button,
  List, ListItemButton, ListItemIcon,
  ListItemText, Checkbox
} from "@mui/material";
import {
  Spinner2, Flasher,//SpinnerBox,
  PageMessage, Typography,
} from 'core';
import { getSMediaIcons } from 'config/ui.config';
import { fCheckJsonEmpty } from 'lib/appFx';
import { getSMediaMgr, getSMediaTitleKeyMap, } from 'services/sMediaApi';
import { useGroupUpdateMutation } from 'services/admin/admGroupsApi';
import { useTranslation } from "react-i18next";
//---------------------------------------------------------

const ctnLItemCardSx = { width: '90%', minHeight: 230, overflow: 'auto' };
const ctnSMTitleSx = {
  //display: 'inline-flex', //flex: '1 0 auto', width: '100%',
  //flexWrap: 'wrap', marginBottom: '0.5rem',
  justifyContent: 'left', alignItems: 'center',
  fontSize: '1.1rem', whiteSpace: 'nowrap',
  width: { xs: '98%', sm: '45%' },
  padding: '0.5rem'
};
//---------------------------------------------------------

const fPrepSavedFSubsList = (objFilter, sMTtlKeyMap) => {
  let resp = {};
  //const selFFSM = Object.keys(objFilter);
  if (objFilter && !fCheckJsonEmpty(objFilter)) {//selFFSM.length
    Object.keys(objFilter).forEach(sFSMT => {
      if (sMTtlKeyMap && sMTtlKeyMap?.hasOwnProperty(sFSMT)) {
        const sK = sMTtlKeyMap[sFSMT];
        const sFPages = [];
        resp[sK] = {};
        resp[sK]['sMKey'] = sK;
        resp[sK]['title'] = sFSMT;

        if (objFilter[sFSMT] && objFilter[sFSMT].length) {
          objFilter[sFSMT].forEach(pgInf => {
            sFPages.push({
              id: pgInf,
              name: '', //type: 'account',
              url: null,
              is_subscribed: true,//?
              isSaved: true,
              isChecked: true,
            });
          });
        }
        resp[sK]['feedPages'] = sFPages;
      }
    });
  }

  return resp;
};
const fPrepCurrentTokenList = (sMMgrData, arrSMK) => {
  let resp = {};

  arrSMK.forEach(sMK => {
    resp[sMK] = {};
    resp[sMK]['sMKey'] = sMK;
    resp[sMK]['title'] = sMMgrData[sMK]['dispTitle'];
    //let smPg;
    if (sMMgrData[sMK].hasOwnProperty('sMTokens') &&
      sMMgrData[sMK]['sMTokens']?.hasOwnProperty('properties') &&
      sMMgrData[sMK]['sMTokens']['properties'].length
    ) {
      resp[sMK]['feedPages'] = sMMgrData[sMK]['sMTokens']['properties'];
      //.filter(sPg=>sPg?.type==='page'//&& sPg?.is_subscribed)
    } else {
      resp[sMK]['feedPages'] = [];
    }
  });

  return resp;
};
const fMergeSavedSubsWithToken = (sMConnTokens, apiDFFilter = null) => {
  //let resp = structuredClone(sMConnTokens);
  let resp = sMConnTokens;
  //console.log('fMergeSSubsToken', {sMConnTokens, apiDFFilter});

  if (apiDFFilter && !fCheckJsonEmpty(apiDFFilter)) {
    Object.keys(apiDFFilter).forEach(sMK => {
      if (apiDFFilter[sMK].hasOwnProperty('feedPages') &&
        apiDFFilter[sMK]['feedPages'].length
      ) {
        apiDFFilter[sMK]['feedPages'].forEach(subsPg => {
          if (sMConnTokens[sMK]['feedPages'].length) {
            const commonPg = sMConnTokens[sMK]['feedPages'].
              find(el => el.id === subsPg.id);
            if (commonPg && !fCheckJsonEmpty(commonPg)) {
              for (const i in sMConnTokens[sMK]['feedPages']) {
                if (sMConnTokens[sMK]['feedPages'][i].id === subsPg.id) {
                  sMConnTokens[sMK]['feedPages'][i]['isSaved'] = true;
                  //sMConnTokens[sMK]['feedPages'][i]['is_subscribed'] = true;
                  sMConnTokens[sMK]['feedPages'][i]['isChecked'] = true;
                  break;
                }
              }
            } else {
              subsPg['isSaved'] = true;
              subsPg['isChecked'] = true;
              sMConnTokens[sMK]['feedPages'].push(subsPg);
            }
          } else {
            subsPg['isSaved'] = true;
            subsPg['isChecked'] = true;
            sMConnTokens[sMK]['feedPages'].push(subsPg);
          }
        });
      }
    });

    Object.keys(sMConnTokens).forEach(sK => {
      sMConnTokens[sK]['feedPages'].forEach(fP => {
        if (!fP.hasOwnProperty('isSaved')) {
          fP['isSaved'] = false;
          fP['isChecked'] = false;
        }
      });
    });
    resp = sMConnTokens;
  }

  return resp;
};
const fPrepFeedFilterInput = (objFPInputs) => {
  let resp = null;
  //{"twitter": ["ACCOUNT_ID_1", "ACCOUNT_ID_2"], "facebook": ["PAGE_ID_1", "PAGE_ID_2"]}

  if (!fCheckJsonEmpty(objFPInputs)) {
    const selFP = {};
    Object.keys(objFPInputs).forEach(sK => {
      if (objFPInputs[sK]['feedPages'].length) {
        const sMTtl = objFPInputs[sK]['title'];
        objFPInputs[sK]['feedPages'].forEach(fP => {
          if (fP.isChecked) {
            //if(!selFP.hasOwnProperty(sK)) { selFP[sK] = []; }
            //selFP[sK].push(fP.id);
            if (!selFP.hasOwnProperty(sMTtl)) { selFP[sMTtl] = []; }
            selFP[sMTtl].push(fP.id);
          }
        });
      }
    }); console.log('selFP', selFP);
    if (!fCheckJsonEmpty(selFP)) { resp = selFP; }
  }
  return resp;
};
const fGetFeedPageTitle = (fPInf, sMKey = '') => {
  //return fPInf?.id+' **** '+fPInf?.id;
  let respT = '';//, nameEl = fPInf?.name;
  switch (sMKey) {
    case 'fb':
    default:
      respT = fPInf?.name?.username ? fPInf.name.username :
        fPInf?.name?.name ? fPInf.name.name :
          fPInf?.name?.first ? fPInf.name.first :
            fPInf?.name ? fPInf.name :
              fPInf.id;
      break;
  }
  return respT;
}
//---------------------------------------------------------

export default function AdmGroupFeedForm({ theme, groupData, }) {
  //console.log('groupDataFF', groupData);
  const groupId = groupData.id;
  const selFFilter = groupData.feed_filter;
  const { t: home } = useTranslation('home')
  const [fSaveFeedFilter, { isLoading: isUpdatingFF }] = useGroupUpdateMutation();
  const [stSMFPages, setStSMFPages] = useState(null);
  const [stAjaxStep, setStAjaxStep] = useState(0);//-1:Err, 0: Nop, 1:wait, 2: OK
  const [stPgMsg, setStPgMsg] = useState('');

  const theSMData = useSelector(getSMediaMgr);
  const theSMDTtlKeyMap = useSelector(getSMediaTitleKeyMap);
  //console.log('theSMDataAdmGrpFF', {theSMData, theSMDTtlKeyMap});
  const grpFeedSrcSM = ['fb', 'tw', 'ln', 'ig'];//igb

  useEffect(() => {
    if (theSMData && stSMFPages === null) {
      let objSavedFeed, objCurrUserFeed, objGrpFeedPages;
      //useMemo
      //if(theSMData) {
      objSavedFeed = fPrepSavedFSubsList(selFFilter, theSMDTtlKeyMap);
      objCurrUserFeed = fPrepCurrentTokenList(structuredClone(theSMData), grpFeedSrcSM);
      //}
      objGrpFeedPages = fMergeSavedSubsWithToken(objCurrUserFeed, objSavedFeed);
      setStSMFPages(objGrpFeedPages);
      //console.log('objGFPages', {selFFilter, objSavedFeed, objCurrUserFeed, objGrpFeedPages});
    }
  }, [theSMData, stSMFPages, setStSMFPages]);
  //console.log('stSMFPages', {stSMFPages, theSMData});
  //---------------------------------------------------------

  const fHandleFPClick = (e) => {
    const selChkInf = e.target.value;//.split('_');
    //console.log('chkClick', selChkInf);
    if (selChkInf.length) {
      setStSMFPages(prevSt => {
        const newSt = structuredClone(prevSt);
        //const sK = selChkInf[0]; const pgId = selChkInf[1];
        const sK = selChkInf.slice(0, 2);
        const pgId = selChkInf.slice(3);
        //console.log('elChkInf', {selChkInf, sK, pgId});
        //console.log('prevSt_'+sK+'_'+pgId, prevSt);
        if (newSt.hasOwnProperty(sK)) {
          newSt[sK]['feedPages'].map(fP => {
            if (fP.id === pgId) {
              fP.isChecked = !fP.isChecked;
            }
          });//console.log('newSt_'+sK+'_'+pgId, newSt);
          return newSt;
        }
        return prevSt;
      });
    }
  };
  const fRestoreAjax = () => {
    setStPgMsg(''); setStAjaxStep(0);
  };

  const fSaveGroupFeedPages = async () => {

    let aResp;
    setStAjaxStep(1);
    const inParams = {};
    inParams['feed_filter'] = fPrepFeedFilterInput(stSMFPages);
    if (inParams['feed_filter']) {
      inParams['feed_filter'] = JSON.stringify(inParams['feed_filter']);
    }

    try {
      aResp = await fSaveFeedFilter({ groupId, params: inParams }).unwrap();
      if (aResp?.result?.success) {
        const txMsg = `${home('feed_subscription_saved_successfully')}`;
        setStPgMsg(txMsg);
        setTimeout(fRestoreAjax, 3000);
      } else {
        setStAjaxStep(-1);
        setStPgMsg(`${home('error_saving_feed_subscription')}` + "\n" + `${home('please_try_again_feed_subscription')}`);
      }
    } catch (err) {
      console.error(err, 'Error saving group FF');
      setStAjaxStep(-1);
      let eMsg;
      if (err?.status === 403) { eMsg = err?.data?.error?.message; }
      if (!eMsg) {
        eMsg = `${home('error_saving_feed_subscription')}` + "\n" + `${home('please_try_again_feed_subscription')}`;
      }
      setStPgMsg(eMsg);
      setStAjaxStep(-1);
    }

    setTimeout(fRestoreAjax, 3000);
  };
  //---------------------------------------------------------

  let lstGroupFeedSMs;
  if (stSMFPages) {
    lstGroupFeedSMs = [];

    grpFeedSrcSM.forEach(sMK => {
      const sMIcon = getSMediaIcons(sMK, 'initCap');
      lstGroupFeedSMs.push(
        <Grid item //className="bdr1"
          //gridTemplateColumns={'1fr 1fr !important'}
          id={'ctnAdmGrpSMediaFeed_' + sMK}
          key={'ctnAdmGrpSMediaFeed_' + sMK}
          xs={12} md={6} lg={6}
          //justifyContent='center' alignItems='flex-start'
          sx={{ padding: '0.5rem' }}
        >
          <Box sx={{ margin: '0.8rem 0' }}>
            <Paper sx={ctnLItemCardSx}>
              <Box className='xdInFNoW xbdr1' sx={ctnSMTitleSx}>
                {sMIcon}
              </Box>
              <Divider />
              <List dense component="div" role="list">
                {
                  (stSMFPages.hasOwnProperty(sMK) &&
                    stSMFPages[sMK].hasOwnProperty('feedPages') &&
                    stSMFPages[sMK]['feedPages'].length
                  ) ? (
                    stSMFPages[sMK]['feedPages'].map(gFP => {
                      const idSuffix = sMK + '_' + gFP.id;
                      const smPgTitle = fGetFeedPageTitle(gFP, sMK);
                      if (!gFP.hasOwnProperty('isChecked')) {
                        //gFP['isChecked'] = false;
                      }
                      let isChecked = gFP?.isChecked ? gFP.isChecked : false;
                      return (
                        <ListItemButton key={'btnFP_' + idSuffix}
                          role="listitem" disabled={!gFP.is_subscribed}
                        //value={gFP.id} onClick={fHandleFPClick}
                        >
                          <ListItemIcon>
                            <Checkbox disableRipple size='small'
                              //color="secondary"//'success', 'default'
                              id={'chkFP_' + idSuffix}
                              checked={isChecked} value={idSuffix}
                              onChange={fHandleFPClick}
                              tabIndex={-1}
                              inputProps={{
                                'aria-labelledby': 'ttlPg_' + sMK + '_' + gFP?.id,
                              }}
                            />
                          </ListItemIcon>
                          <ListItemText id={'ttlPg_' + sMK + '_' + gFP?.id} primary={smPgTitle} />
                        </ListItemButton>
                      )
                    })
                  ) : (
                    <ListItemText id={'grpFP_' + sMK + '_0'} sx={{ padding: '1rem' }}
                      secondary={'No pages to assign'} />
                  )
                }
              </List>
            </Paper>
          </Box>
        </Grid>
      );
    });
  } else {
    lstGroupFeedSMs = <Spinner2 />;
    /*setTimeout(()=> {
      setStPgMsg('Error fetching group\'s feed subscription');
    }, 4000);*/
  }
  //---------------------------------------------------------

  return (
    <Box className='bgWh padL' padding={2} id='ctnAdmGrpFPs'
      sx={{ padding: '2', minHeight: '12rem' }}
    //sx={{flexGrow: 1, width: '100%', padding: '0.5rem', alignItems: 'center'}}
    >
      <Box className='secHd'>
        <Typography className="secHd">Manage Group Feeds</Typography>
      </Box>
      <Grid container className="wA"
        direction={'column'} //direction={{xs: "column", md: "row"}}
        //spacing={1} //spacing={{xs: 2, md: 3 }} flexDirection='row'
        //columns={{ xs: 4, sm: 8, md: 12 }}
        justifyContent='center' alignItems={'top'} //disableEqualOverflow
        sx={{
          flexGrow: 1, marginTop: '1rem',
          flexDirection: { xs: "column", sm: 'row', md: "row" }
        }}
      >
        {lstGroupFeedSMs}
      </Grid>
      <Box className='dInFNoW wA xtBdr2'
        sx={{ margin: '0.8rem 0', display: { xs: 'block', sm: 'flex' } }}
      >
        <Box sx={{ width: { xs: '100%', md: '55%' }, padding: '0.5rem', alignItems: 'center' }}>
          {stPgMsg && (
            <Flasher delay={3000} resetFunction={fRestoreAjax}>
              <PageMessage text={stPgMsg} isError={stAjaxStep === -1} />
            </Flasher>
          )}
        </Box>
        <Box className='mLA' sx={{ width: { xs: '100%', sm: 'auto' } }}>
          <Button className='cBtn cBtnDark' sx={{ fontWeight: 700 }}
            onClick={fSaveGroupFeedPages} disabled={isUpdatingFF}
          >
            {isUpdatingFF && <Spinner2 />}Save</Button>
        </Box>
      </Box>
    </Box>
  );
};