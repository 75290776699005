import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_BASE_URL, FLG_SKIP_SA_TOKEN }  from 'config/app.config';
//import { j2q } from 'lib/netFx';
import { setUserCredentials, logOut } from './auth/authSlice';
//---------------------------------------------------------

const baseQuery = fetchBaseQuery({
	baseUrl: API_BASE_URL,
	prepareHeaders: (headers, {getState}) => {
		//headers.set('content-type', 'application/json');
		headers.set('Connection', 'keep-alive');
		headers.set('Accept', 'application/json');
		//headers.set('Access-Control-Allow-Origin', '*');
		let lAT;//= localStorage.getItem('aTick');lAT = 'AA';
		//let lAT = FLG_SKIP_SA_TOKEN ? getState()?.auth?.awsAuthData?.accessToken : null;

		let authToken = lAT ?? getState()?.auth?.authToken;
		if(authToken) {
			headers.set('Authorization', `Bearer ${authToken}`);
		}
		return headers;
	},
  //timeout: 5000, jsonContentType: true
});

const baseQueryWithReAuth = async(args, api, extraOptions) => {
  //try catch
	let resp = await baseQuery(args, api, extraOptions);
	//console.log('baseQWithReA', {args, api, extraOptions, resp});
	//if(args?.url === '/points' && args?.method?.toLowerCase() === 'post') {
	// console.log('HELL');resp={data: {result:{success:true},data:{test: 'abc'}}};
	//} else { console.log('HELL0000');}console.log('baseQWithReA2', resp);

	if(resp?.error?.originalStatus === 403) {
		const refRes = await baseQuery('/user/refresh', api, extraOptions);
		if(refRes?.data) {
			const user = api.getState().auth.user;
			api.dispatch(setUserCredentials({...refRes.data, user}));
			resp = await baseQuery(args, api, extraOptions);
		} else { api.dispatch(logOut()); }//if(SA_ENV !== 'local') {}
	} else if(resp.data) {
		if(resp.data?.result?.success) {
			//resp.data = resp.data.result.data;
			if(resp.data.result.hasOwnProperty('data')) {
				resp.data = resp.data.result.data;
			} else {//console.log('FOOL', resp);
				//resp.data = {success: true};
			}
		} else if(resp.data?.error) {//error.message|type|code
			//resp.data = resp.data;
		} else { resp.data = null; }
	}//console.log('finalResp', resp);
	return resp;
};

export const sSApi = createApi({
	baseQuery: baseQueryWithReAuth,
	endpoints: builder => ({}),
  //endpoints: () => ({}),
  //tagTypes: ['org', 'sMedia', 'orgFeed', 'user'],
  //overrideExisting: false,
});

/*export const {
	useGenMutateMutation,
	useGenPostMutation,
	useGenGetQuery,
	useLazyGenGetQuery,
	useGenCompGetQuery,
	//useUpdateTasMutation,
} = sSApi;*/

//export const sSApiBaseQuery = baseQueryWithReAuth;