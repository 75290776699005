import { CognitoUserPool, AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js';
import { CognitoIdentityProviderClient, SignUpCommand,
		ConfirmSignUpCommand, ResendConfirmationCodeCommand,
} from "@aws-sdk/client-cognito-identity-provider";
import { CognitoJwtVerifier } from 'aws-jwt-verify';
//import jwtVerify from 'jwt-verify';
//import jwt from 'jwt-decode';import jwt_decode from "jwt-decode";
import axios from "axios";
import { COGNITO_USER_POOL_ID, COGNITO_CLIENT_ID, COGNITO_REGION } from 'config/app.config';
//---------------------------------------------------------

export const fCognitoRegister = async(cData) => {
	const cognitoConf = { region: COGNITO_REGION, maxAttempts: 5 };
	let cogResp;

	const uEMail = cData?.email;
	const uPwd = cData?.password;
	const userName = cData?.first_name + ' ' + cData?.last_name;
	//const contactNo = cData?.mobile_number;

	if(uEMail && uPwd && userName) {
		try {
			const regCmd = new SignUpCommand({
				ClientId: COGNITO_CLIENT_ID,
				Username: uEMail,
				Password: uPwd,
				UserAttributes: [
					{ Name: "email", Value: uEMail },
					{ Name: "name", Value: userName},

					//given_name, name, middle_name, family_name, email, picture, role
					//Others

					//{ Name: "given_name", Value: userName},
					//{ Name: "name", Value: cData?.first_name},
					////{ Name: "middle_name", Value: ''},
					//{ Name: "family_name", Value: cData?.last_name},
					//{ Name: "role", Value: 'user'},
					//{ Name: "phone_number", Value: contactNo},
				],
			});
			const client = new CognitoIdentityProviderClient(cognitoConf);
			cogResp = await client.send(regCmd);
		} catch(err) {
			cogResp = err;
		}
	} else {
		throw new Error('Invalid inputs', {'regInputError': true});
	}//console.log('cogRegResp', cogResp);

	if(cogResp?.CodeDeliveryDetails && cogResp?.UserSub) {
		//cogResp = 'cognitoRegOK';
		cogResp = { 'result': true, cogUserId: cogResp.UserSub };
	} else if(cogResp?.regInputError) {
		cogResp = 'Invalid inputs for userId / password';
	} else if(cogResp?.__type) {
		let cRMsg;//cogResp?.$metadata?.httpStatusCode === 400
		switch(cogResp.__type) {//400 401 403
			case 'UsernameExistsException':
				cRMsg = 'The email id is already registered with us';
				break;
			case 'NotAuthorizedException':
			case 'ResourceNotFoundException':
			case 'InvalidParameterException':
			default:
				cRMsg = 'Something went wrong.. Please try again later..';
				break;
		}
		cogResp = cRMsg;
	} else {
		//throw new Error('Invalid inputs', {'regError': true});
	}
	return cogResp;
}

export const fAwsConfirmSignUp = async ({ uEMail, vCode }) => {
	let cogResp;
	const config = { region: COGNITO_REGION };
	const client = new CognitoIdentityProviderClient(config);

	try {
	  if(uEMail && vCode) {
		const command = new ConfirmSignUpCommand({
		  ClientId: COGNITO_CLIENT_ID,
		  Username: uEMail,
		  ConfirmationCode: vCode,
		});

		cogResp = await client.send(command);
	  } else {
		throw new Error("Invalid inputs", { verifyInputError: true });
	  }
	} catch (err) {
	  console.error("awsErr", err);
	  cogResp = err;
	}
  
	if(cogResp?.$metadata && cogResp.$metadata?.httpStatusCode === 200) {
	  cogResp = "cognitoVerifyRegOK";
	} else if (cogResp?.verifyInputError) {
	  cogResp = "Invalid inputs for verification code";
	} else if (cogResp?.__type) {
	  let cRMsg;
	  switch (cogResp.__type) {//400 401 403
		case "CodeMismatchException":
		  cRMsg = "The verification code is invalid";
		  break;
		case "ExpiredCodeException":
		  cRMsg = "The verification code is expired. Request a new one";
		  break;
		case "ResourceNotFoundException":
		case "InvalidParameterException":
		default:
		  cRMsg = "Something went wrong.. Please try again later..";
		  break;
	  }
	  cogResp = cRMsg;
	}
  
	return cogResp;
};

export const fAwsResendVCode = async (uEMail) => {    
    let cogResp;
    const config = { region: COGNITO_REGION };
    const client = new CognitoIdentityProviderClient(config);

    try {
      if(uEMail) {
      	const command = new ResendConfirmationCodeCommand({
        	ClientId: COGNITO_CLIENT_ID,
        	Username: uEMail,
        });
        cogResp = await client.send(command);
      } else {
      	throw new Error("Invalid inputs", { resendVerifyInputError: true });
      }
    } catch (err) {
      cogResp = err;
    }

    if(cogResp?.$metadata?.httpStatusCode === 200) {
		cogResp = "cognitoVCodeOK";
    } else {
		let cRMsg;
		//cRMsg = 'Something went wrong.. Please try again later..';
		//cogResp = cRMsg;
		cogResp = 'Something went wrong.. Please try again later..';
    }
};

export const fAuthenticateCogUser = async (cogUser, cogAuthData) => {
	return new Promise(function (resolve, reject) {
	  cogUser.authenticateUser(cogAuthData, {
		onSuccess: resolve,
		onFailure: reject,
	  });
	});
};
/*export const fAuthenticateCogUser2 = async (cogUser, cogAuthData) => {
	let resp = null;
	await cogUser.authenticateUser(cogAuthData, {
		onSuccess: function (result) {
			//console.log('cogAuthoken', {"idToken": result.idToken.jwtToken, "accessToken": result.getAccessToken().getJwtToken());
			resp = result;//result?.CognitoUserSession ? result : null;
		},
		onFailure: function(err) {
			console.log('cogAuthErr', err);
			resp = err;
		},
	});
	//return resp;
};*/

export const fAwsCognitoLogin = async ({ uEMail, uPwd }) => {
	let cogResp;

	try {
	  if (uEMail && uPwd) {
		const cognitoAuthData = { Username: uEMail, Password: uPwd };
		const authDetail = new AuthenticationDetails(cognitoAuthData);

		const uPoolData = {
		  UserPoolId: COGNITO_USER_POOL_ID,
		  ClientId: COGNITO_CLIENT_ID,
		};
		const userPool = new CognitoUserPool(uPoolData);
		const poolUserData = { Username: uEMail, Pool: userPool };

		const cognitoUser = new CognitoUser(poolUserData);
		cogResp = await fAuthenticateCogUser(cognitoUser, authDetail);
		//cogResp = await fAuthenticateCogUser2(cognitoUser, authDetail);
		//console.log('cogLogRespO', cogResp);
		if(cogResp && cogResp?.getAccessToken()
			&& cogResp?.idToken?.payload?.email_verified
		) {
			const cogRespN = {
				"idToken": cogResp.getIdToken(),//.getJwtToken(),
				"accessToken": cogResp.getAccessToken(),//.getJwtToken(),
				"refreshToken": cogResp.getRefreshToken(),
			};
			cogResp = cogRespN;
			//console.log('cogLoggRespp', cogRespN, "\n AAA ", cogRespN.idToken.jwtToken, cogRespN.idToken.payload);
		} else { cogResp = false; }
	  } else {
		throw new Error('Invalid inputs', { loginInputError: true });
	  }
	} catch (err) {
		// cogResp = err;
	}

	let cRMsg;
	//cogResp?.idToken.payload?. [name, email, email_verified, sub, token_use]
	if(cogResp?.accessToken.jwtToken && cogResp?.accessToken.payload
		//&& cogResp?.idToken.payload.email_verified
	) {
		//&& cogResp?.idToken.payload.email_verified) {
		//console.log('cogRespSuccess', cogResp);
	} else if (cogResp?.loginInputError) {
	  cogResp = 'Invalid inputs for userId / password';
	} else if (cogResp?.__type) {
	  switch (cogResp.__type) {
		case 'NotAuthorizedException':
		  cRMsg = 'Incorrect username or password';
		  break;
		case 'ResourceNotFoundException':
		case 'InvalidParameterException':
		default:
		  cRMsg = 'Something went wrong.. Please try again later..';
		  break;
	  }
	  cogResp = cRMsg;
	} else {
		cRMsg = 'Something went wrong.. Please try again later..';
	}//console.log('cogLogResp2', cogResp);

	return cogResp;
};

/*export const verifyToken2 = async (token) => {
	try {
	  const decodedToken = jwtVerify(token);
	  const email = decodedToken.email;

	  const userPool = new CognitoUserPool({
		UserPoolId: COGNITO_USER_POOL_ID,
		ClientId: COGNITO_CLIENT_ID
	  });
	  const user = userPool.getCurrentUser();
	  if(!user) { return false; }
	  const userData = await user.getUserData();
	  if(userData.Username !== email) { return false; }
  
	  return true;  
	} catch (error) { return false; }
};*/

export const fGetJwtUserData = (jToken) => {
	let jClaims = atob(jToken.split('.')[1]);
	try {
		jClaims = JSON.parse(jClaims);
	} catch { jClaims = null; }//console.log(jClaims);
	//jClaims.email, jClaims.sub = jClaims['cognito:username'];
	return jClaims;
};

export const fGetCogUserData = async (uToken) => {
	let uDataResp = await axios.post(
		'https://cognito-idp.'+COGNITO_REGION+'.amazonaws.com/',
		{ AccessToken: uToken },
		{
		  headers: {
			'Content-Type': 'application/x-amz-json-1.1',
			//'Content-Length': 1162,
			'X-Amz-Target': 'AWSCognitoIdentityProviderService.GetUser'
		  },
		}
	);//console.log('uDataResp', uDataResp);
	uDataResp = uDataResp && uDataResp?.data?.UserAttributes;
	//console.log('uDataRespN', uDataResp);
	if(uDataResp && uDataResp.length) {
		const uData = {};
		uDataResp.forEach(dK => {
			uData[dK?.Name] = dK?.Value;
		});
		uDataResp = uData;
	} else {
		uDataResp = null;
	}//console.log('fCUData', uDataResp);
	return uDataResp;
};

export const fVerifyCogJToken = async (cJToken) => {
	let resp;//console.log('fCJToken', cJToken);
	if(cJToken) {
		const verifier = CognitoJwtVerifier.create({
			userPoolId: COGNITO_USER_POOL_ID,
			//tokenUse: "id",
			tokenUse: "access",
			clientId: COGNITO_CLIENT_ID,
		});

		try {
			resp = await verifier.verify(cJToken);
			//console.log("Valid Token Resp:", resp);
			//resp instanceof Promise
			/*if(resp?.sub && resp?.username) {//email_verified not always coming
				let uDataResp = fGetJwtUserData(cJToken);
				if(uDataResp?.email) { resp = uDataResp; }
				else { resp = false; }
			} else { resp = false; } OR*/
			//console.log('vvvResp', resp);
			if(resp?.client_id === COGNITO_CLIENT_ID && resp?.username) {
				//console.log('fCUsr', resp?.username);
				//(resp?.exp > now)
				let uDataResp = await fGetCogUserData(cJToken);
				if(uDataResp?.email) { resp = uDataResp; }
				else { resp = false; }
				//console.log('uDataResp', uDataResp);
			} else { resp = false; }
		} catch {//console.log("Err.., Invalid Token!");
			resp = false;
		}
	}//console.log('vResp', resp);
	return resp;
};
