import { useState, useCallback, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fAwsCognitoLogin } from 'lib/cogFx';
import { fSetFMeNotSetts, fGetFMeNotSetts, fClearFMNotCooks, genNiuSAKey } from 'lib/appFx';
//import { fDelCookie } from 'lib/netFx';
import { AUTH_ROUTES } from 'config/routes.config';
import { useLoginMutation } from 'services/auth/authApi';
import { useLazyUserDetailsByIdQuery } from 'services/userApi';
import { setUserCredentials } from 'services/auth/authSlice';

import { IconButton, InputAdornment, Stack, styled, useTheme } from '@mui/material';
import { Button, FormCheckbox, FormInputField, FormProvider, Typography } from 'core';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { SignInSchema } from 'schema';
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';
import HttpsOutlinedIcon from '@mui/icons-material/HttpsOutlined';
import { grey } from '@mui/material/colors';
import { Check } from '@mui/icons-material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {
  SA_ENV, FLG_DISABLE_AUTH_PAGES,
  FLG_ENABLE_AWS_AUTH, NIU_HEALTH_BASE_URL,
} from 'config/app.config';
import { useTranslation } from 'react-i18next';
//---------------------------------------------------------

const IconStyle = {
  color: grey[500],
  marginRight: '1rem',
  width: '2rem',
  height: '2rem',
};
const StyledInputFieldContainer = styled(Stack)(() => ({
  alignItems: 'center',
  flexDirection: 'row',
}));
const InputField = styled(FormInputField)(({ theme }) => ({
  outline: `1px solid ${theme.palette.primary.main}`,
}));
const StyledCheckbox = styled('div')(({ theme, checked }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '1rem',
  height: '1rem',
  backgroundColor: checked && theme.palette.primary.light,
  borderRadius: '3px',
  transition: '150ms',
  border: `1px solid ${theme.palette.primary.light}`,
  padding: '0.7rem',
}));
const StyledCheckboxGroup = styled(Stack)(() => ({
  flexDirection: 'row',
  alignItems: 'center',
  gap: '0.5rem',
  paddingLeft: '3rem',
}));
const Label = styled(Typography)(({ theme }) => ({
  maxWidth: '25vw',
  [theme.breakpoints.down('md')]: {
    fontSize: '14px',
    maxWidth: '90vw',
  },
}));
const FormContainer = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(8),
  textAlign: 'left',
}));
//---------------------------------------------------------

export default function SignInForm() {
  // const data = useSelector(getSMediaMgr);
  // useEffect(() => {
  //   if (data === "loggged-out") {
  //     window.location.reload();
  //   }
  // });

  const theme = useTheme();
  const [errMsg, setErrMsg] = useState();
  //const [flgRemMe, setFlgRemMe] = useState(false);
  const fMeNotSettsData = fGetFMeNotSetts();
  const defaultValues = fMeNotSettsData?.defCredVals;
  const methods = useForm({
    resolver: yupResolver(SignInSchema),
    defaultValues,
  });
  const { t: home } = useTranslation('home')
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state: newUserState } = useLocation();
  const newUserEMail = newUserState?.regEMail || '';

  const [fLogin, { isLoading }] = useLoginMutation();
  const [fUserDetails] = useLazyUserDetailsByIdQuery({ userId: null });

  const fLoginHandler = useCallback(async (e) => {
    let cogLoginResp, regResp, pMsg, flgProceed = false;
    let uLoginCustomData = {};

    try {
      if (FLG_ENABLE_AWS_AUTH) {//FLG_ENABLE_TWO_WAY_HANDSHAKE) {
        cogLoginResp = await fAwsCognitoLogin({ uEMail: e.email, uPwd: e.password });

        //if(cogLoginResp?.idToken) {
        if (cogLoginResp?.accessToken?.jwtToken) {
          flgProceed = true;
          pMsg = 'cognitoLoginOK';
          uLoginCustomData = {
            accessToken: cogLoginResp.accessToken.jwtToken,
            refreshToken: cogLoginResp.refreshToken?.token,
            cogUuid: cogLoginResp?.accessToken?.payload?.sub,
            eMailVerified: true, //AlreadyCheckedIn cogFx
          };
          //console.log('uLoginCustomData', uLoginCustomData);
          /*if(cogLoginResp?.accessToken) {//DelUnImportantInfo
            delete cogLoginResp.idToken;
            delete cogLoginResp.refreshToken;//?
            delete cogLoginResp?.clockDrift;
          }*/
        } else { pMsg = cogLoginResp; }
      } else {
        flgProceed = true;
      }

      //flgProceed = false;
      if (flgProceed) {
        flgProceed = false;
        let authUser = await fLogin({
          email: e.email, password: e.password,
          //password: genNiuSAKey(e.email),
          custom_data: uLoginCustomData,
        }).unwrap();
        //console.log('authUser', authUser);

        if (authUser?.user_id && authUser?.token) {
          //if(authUser) {
          dispatch(setUserCredentials(authUser));

          let userDetail = await fUserDetails({
            userId: authUser.user_id,
            orgId: authUser?.company_id,
          }).unwrap();
          userDetail = userDetail?.users[0];
          const usrAuthData = {
            ...authUser,
            details: userDetail,
            cognitoAuthData: uLoginCustomData,
          };
          dispatch(setUserCredentials(usrAuthData));
          //console.log('DispatchedCredA', usrAuthData);
          // const userDetailNew = JSON.parse(JSON.stringify(userDetail))
          // userDetailNew.email = SA_TEMP_DEF_ADMIN_EMAIL
          if (e.remember_me) {
            fSetFMeNotSetts(
              { email: e.email, password: e.password, fmn: e.remember_me },
              { ...authUser, details: userDetail } //, {cognitoAuthData: uLoginCustomData }
            );
          } else {
            fClearFMNotCooks(); //clearLocalStorageSSq();
          }
          //if(10) {
          localStorage.setItem('usrAuthData', JSON.stringify(usrAuthData));
          //}
          flgProceed = true;
          //---------------------------

          //Clean any state etc here..
          //setUser('');
          //setPwd('');
          //---------------------------Z

          navigate('/home');
        } else {
          //---------------------------
          //setErrMsg('Invalid Credentials');
          //---------------------------
        }
      }
    } catch (err) {
      console.error(err);
      if (!err?.originalStatus) {
        setErrMsg(`${home('no_server_response')}`);
      } else if (err.originalStatus?.status === 400) {
        setErrMsg(`${home('missing_username_pwd')}`);
      } else if (err.originalStatus?.status === 401) {
        setErrMsg(`${home('unauthorized')}`);
      } else {
        setErrMsg(`${home('login_failed')}`);
      }
      //---------------------------
    }
    //---------------------------

    // if(remember_me) {
    //   document.cookie = `userName=${e.email};path=${window.location.href}`
    //   document.cookie = `password=${e.password};path=${window.location.href}`
    // }
    if (!flgProceed) {
      pMsg = pMsg ? pMsg : 'Error signing-in user!!';
      setErrMsg('Email or password is incorrect');
    }
    if (typeof pMsg === 'object') {
      if (pMsg?.name === 'NotAuthorizedException') {
        setErrMsg('Email or password is incorrect');
      }
    } else {
      // setErrMsg(pMsg);
    }
  }, []);

  const { handleSubmit, setValue, watch, formState: { errors }, } = methods;
  const onSubmit = (data) => { fLoginHandler(data); };
  const remember_me = watch('remember_me');
  const handleRememberMe = () => {
    setValue('remember_me', !remember_me);
  };
  const [isPasswordVisible, setPasswordVisible] = useState(false);
  const isError = (fieldName) => (errors[fieldName] ? theme.palette.error.light : theme.palette.primary.main);
  const changePasswordState = () => setPasswordVisible((prev) => !prev);

  if (FLG_DISABLE_AUTH_PAGES) {
    /*Redirect the user to NIU Health login page before the form is visible*/
    window.location.href = NIU_HEALTH_BASE_URL;
  }

  //return SA_ENV === 'local' ? (
  return FLG_DISABLE_AUTH_PAGES ? (<></>) : (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <FormContainer>
        <Stack spacing={2}>
          <StyledInputFieldContainer>
            <MailOutlineOutlinedIcon sx={{ ...IconStyle }} />
            <InputField
              name="email"
              type="email"
              color="success"
              placeholder="Email Id*"
              fullWidth
              InputProps={{
                style: {
                  backgroundColor: (theme) => theme.palette.background.paper,
                  minWidth: '25vw',
                  WebkitBoxShadow: '0 0 0 1000px white inset',
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton size="small">
                      <VisibilityOffIcon sx={{ opacity: 0 }} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </StyledInputFieldContainer>
          <StyledInputFieldContainer>
            <HttpsOutlinedIcon sx={{ ...IconStyle }} />

            <InputField
              name="password"
              color="success"
              placeholder="Password*"
              autoComplete="öff"
              fullWidth
              type={isPasswordVisible ? 'text' : 'password'}
              InputProps={{
                style: {
                  backgroundColor: (theme) => theme.palette.background.paper,
                  minWidth: '25vw',
                  WebkitBoxShadow: '0 0 0 1000px white inset',
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton size="small" onClick={changePasswordState}>
                      {!isPasswordVisible ? <VisibilityOffIcon color={isError('password')} /> : <VisibilityIcon color={isError('password')} />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </StyledInputFieldContainer>
          {errMsg && (
            <StyledInputFieldContainer>
              <MailOutlineOutlinedIcon sx={{ opacity: 0, ...IconStyle }} />
              <Typography style={{ color: theme.palette.error.main }}>{errMsg}</Typography>
            </StyledInputFieldContainer>
          )}
          <Stack justifyContent="space-between" alignItems="center" direction="row">
            <StyledCheckboxGroup>
              <StyledCheckbox name="remember_me" checked={remember_me} onClick={handleRememberMe}>
                <Check sx={{ color: 'white' }} />
              </StyledCheckbox>
              <Label color_variant="lightGrey" fontSize="medium">
                {home('remember_me')}
              </Label>
            </StyledCheckboxGroup>

            <Label component={Link} size="small" sx={{ '&:hover': { color: grey[600] } }} color_variant="lightGrey" to={AUTH_ROUTES.ForgotPassword.url}>
              {home('forgot_password')}
            </Label>
          </Stack>
        </Stack>
        <Stack spacing={3}>
          <StyledInputFieldContainer>
            <HttpsOutlinedIcon sx={{ ...IconStyle, opacity: 0 }} />
            <Button
              type="submit"
              size="large"
              sx={{
                color: (theme) => theme.palette.background.paper,
                fontSize: '1rem',
                fontWeight: 600,
                letterSpacing: '0.04rem',
                boxShadow: 'none',
              }}
            >
              {home('log_in')}
            </Button>
          </StyledInputFieldContainer>

          <StyledInputFieldContainer>
            <HttpsOutlinedIcon sx={{ ...IconStyle, opacity: 0 }} />
            <Button
              size="large"
              component={Link}
              to={AUTH_ROUTES.SignUp.url}
              sx={{
                background: grey[300],
                '&:hover': {
                  background: grey[400],

                  boxShadow: 'none',
                },
                color: grey[600],
                fontWeight: 600,
                fontSize: '1rem',
                boxShadow: 'none',
                letterSpacing: '0.04rem',
              }}
            >
              {home('create_new_account')}
            </Button>
          </StyledInputFieldContainer>
        </Stack>
      </FormContainer>
    </FormProvider>
  );
}
