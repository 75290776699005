import { useEffect, useState, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { fInitSMAuth, checkAnySMConnected } from 'lib/appFx';
//import SocialNetworkCard from 'sections/widgets/sMediaWidget/SocialNetworkCard';
//import SocialNetworkCard2 from './SocialNetworkCard2';
import SocialNetworkCard3 from './SocialNetworkCard3';
import Modal from 'core/Modal';
import { getSMediaMgr } from 'services/sMediaApi';
//---------------------------------------------------------

export default function SocialMediaConnectModal({
	sMediaMethods,
	uFeedFilter, setUFeedFilter, forceOpen=false, setForceOpen=null
}) {
	const redSMMgr = useSelector(getSMediaMgr);
	//redSMMgr && console.log('redSMMgrM22', redSMMgr);
	let sMConnModal, allConnMsg;

	const [open, setOpen] = useState(false);
	const [shownModal, setShownModal] = useState(false);
	const handleClose = () => {
		setOpen(false);
		setShownModal(true);
		forceOpen && setForceOpen(false);
	};//console.log('shownModal', shownModal);
	const location = useLocation();
	const { pathname } = location;
	const isHomePage = useCallback(() => {
		return ['/home', '/', ''].includes(pathname);
	}, [pathname]);
	//console.log('redSMMgrModal', redSMMgr);

	//const fFOpen = () => { console.log('Hello'); setOpen(true); }
	//if(forceOpen && isHomePage()) { setOpen(true);}

	const fCheckShowModal = useCallback(
		() => {
			if(redSMMgr) {
				const anySMConnected = checkAnySMConnected(redSMMgr);
				//console.log('redSMMgr '+anySMConnected, redSMMgr);
				if(!anySMConnected) {
					/*allConnMsg = [
						"Please connect your ",
						<br key="nLBrUReg" />,
						"social media accounts",//"social media account(s)",
					];*/
				
					if(!shownModal && isHomePage()) {
						if(!open) { setOpen(true); }
					}
				}
			}
		}
	, [isHomePage, setOpen, open, shownModal, redSMMgr]);

	useEffect(()=> { setTimeout(() => {
		fCheckShowModal()}, 100);
	}, [fCheckShowModal]);
	useEffect(()=> {forceOpen && isHomePage() && setOpen(true);}, [forceOpen, setOpen]);
	//console.log('fFOpen '+forceOpen+' '+open, typeof setForceOpen);

	sMConnModal = (//(//keepMounted disableEnforceFocus hideBackdrop={true});
		<Modal onClose={handleClose} open={open}>
			<SocialNetworkCard3
				sMediaMethods={sMediaMethods}
				onClose={handleClose}
				//setOpen={setOpen} setShownModal={setShownModal}
				uFeedFilter={uFeedFilter}
				setUFeedFilter={setUFeedFilter}
			/>
		</Modal>
	);
	//---------------------------------------------------------

	return <>{sMConnModal}</>;
};