import { lazy, useEffect } from "react";
import { Routes, Route, Outlet, Navigate,
  useLocation, useParams,
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { SA_ENV, HOME_BASE_URL, FLG_DISABLE_AUTH_PAGES,
  NIU_HEALTH_BASE_URL,
} from 'config/app.config';
import { COMM_ROUTES, AUTH_ROUTES, HOME_ROUTES,//USER_ROUTES,
  ADMIN_ROUTES, ERROR_ROUTES,
} from 'config/routes.config';
import { PublicLayout, AppCommLayout, RequireUserAuth, RequireAdminAuth } from 'layout';

//AuthedUserPages
import Home from 'pages/home/Home';
import Badges from 'pages/badges/Badges';
import Discoverd from 'pages/discoverd/Discoverd';
import Faq from 'pages/faq/Faq';
import Help from 'pages/help/Help';
import Submit from 'pages/home/Submit';
import LeaderBoard from 'pages/leaderboard/LeaderBoard';
import Settings from 'pages/settings/Settings';

//AuthedAdminPages
import AdminHome from 'pages/admin/Home';
//import AdmContentSuggestions from 'pages/admin/contentSuggestions';
import AdmGroups from 'pages/admin/Groups';//LazyLoad(lazy(
import AdmGroupDetails from 'pages/admin/GroupDetails';
import AdmGroupCreate from 'pages/admin/GroupCreate';
import AdmManageFeeds from 'pages/admin/ManageFeeds';
import AdmRewardPoints from 'pages/admin/RewardPoints';
//import AdmBroadcasts from 'pages/admin/Broadcasts';
//import AdmUsers from 'pages/admin/users';

//GuestUserPages
import TPRespHandler from 'pages/tpartyResp/TPRespHandler';
import NSocialHandshake from 'pages/tpartyResp/NSocialHandshake';
//import NSocialHandshakeAws from 'pages/tpartyResp/NSocialHandshakeAws';
//import TestComp from 'pages/tpartyResp/TestComp5';
//import CognitoTest from 'pages/tpartyResp/CognitoTest';

import { setUserCredentials } from 'services/auth/authSlice';//selectAuthUserDetails
import { selectAuthToken } from 'services/auth/authSlice';
import { Loadable } from 'core';
//import { fGetFMeNotSetts } from '../lib/appFx';
//---------------------------------------------------------

const SignUp = Loadable(lazy(() => import('pages/auth/SignUp')));
const SignIn = Loadable(lazy(() => import('pages/auth/SignIn')));
const VerifyAccount = Loadable(lazy(() => import('pages/auth/VerifyEmail')));
const ForgotPassword = Loadable(
  lazy(() => import('pages/auth/ForgotPassword'))
);
const ResetPassword = Loadable(lazy(() => import('pages/auth/ResetPassword')));

//CatchAllOtherComponentPages
const Page404 = Loadable(lazy(() => import('pages/error/404')));
// const Home = Loadable(lazy(() => import("pages/home/Home")));
//---------------------------------------------------------

export default function AppRouterN() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { pathname } = location;//console.log("pathnameN", pathname);
  let usrAuthData = null;

  const userAuthToken = useSelector(selectAuthToken);
  
  /*useEffect(() => {
    if(SA_ENV !== 'local') {
      const authPages = ['/signin', '/signup', '/verify', '/forgot-password', '/reset-password'];
      const path = location.pathname;//console.log('path', path);
      if(authPages.includes(path)) {
        if(userAuthToken) {
          window.location.href = HOME_BASE_URL;
        } else {
          window.location.href = NIU_HEALTH_BASE_URL;
        }
      }
    }
  }, [location]);*/
  //useEffect(() => {//fGetFMeNotSetts.usrRedData

	try {
		usrAuthData = JSON.parse(localStorage.getItem('usrAuthData'));
		//console.log('appNUC', usrAuthData);
		if(usrAuthData !== null) {
      setTimeout(() => {
        dispatch(setUserCredentials(usrAuthData));
      }, 50);
		}
	} catch {}
  //}, []);
  //---------------------------------------------------------

  return (
    <Routes>
      <Route path="/" element={<Outlet />}>
        {/*<Route path={AUTH_ROUTES.Root.url}
          element={<Navigate to=AUTH_ROUTES.SignIn.title />} />*/}

        {/*GuestRoutes*/}
        <Route element={<PublicLayout />}>
          <Route
            path={AUTH_ROUTES.SignIn.url}
            element={<SignIn title={AUTH_ROUTES.SignIn.title} />}
          />
          <Route
            path={AUTH_ROUTES.SignUp.url}
            element={<SignUp title={AUTH_ROUTES.SignUp.title} />}
          />
          <Route
            path={AUTH_ROUTES.Verify.url}
            element={<VerifyAccount title={AUTH_ROUTES.SignUp.title} />}
          />
          <Route
            path={AUTH_ROUTES.ForgotPassword.url}
            element={
              <ForgotPassword title={AUTH_ROUTES.ForgotPassword.title} />
            }
          />
          <Route
            path={AUTH_ROUTES.ResetPassword.url}
            element={<ResetPassword title={AUTH_ROUTES.ResetPassword.title} />}
          />
          {/*<Route
            path={COMM_ROUTES.SMConnectResp.url}
            element={<TPRespHandler />}
          />*/}
          {/*<Route path={COMM_ROUTES.AwsAuth.url} element={<CognitoTest />} />*/}
        </Route>

        <Route element={<AppCommLayout />}>
          <Route
            path={COMM_ROUTES.SMConnectResp.url}
            //element={user !== null ? <TPRespHandler /> : null}
            //element={user !== null ? <TPRespHandler /> : <Navigate replace to={"/"} /> }
            element={<TPRespHandler />}
          />
          <Route path={COMM_ROUTES.NiuHandshake.url} element={<NSocialHandshake />} />
        </Route>

        {/*AuthedUserRoutes*/}
        <Route element={<RequireUserAuth />}>
          <Route index element={<Navigate to="home" replace />} />
          <Route
            path={HOME_ROUTES.Home.url}
            element={<Home title={HOME_ROUTES.Home.title} />}
          />
          <Route
            path={HOME_ROUTES.Settings.url}
            element={<Settings title={HOME_ROUTES.Settings.title} />}
          />
          <Route
            path={HOME_ROUTES.Badges.url}
            element={<Badges title={HOME_ROUTES.Badges.title} />}
          />
          <Route
            path={HOME_ROUTES.Help.url}
            element={<Help title={HOME_ROUTES.Help.title} />}
          />
          <Route
            path={HOME_ROUTES.Leaderboard.url}
            element={<LeaderBoard title={HOME_ROUTES.Leaderboard.title} />}
          />
          <Route
            path={HOME_ROUTES.Faq.url}
            element={<Faq title={HOME_ROUTES.Faq.title} />}
          />
          <Route
            path={HOME_ROUTES.Submit.url}
            element={<Submit title={HOME_ROUTES.Submit.title} />}
          />
          <Route
            path={HOME_ROUTES.Discoverd.url}
            element={<Discoverd title={HOME_ROUTES.Discoverd.title} />}
          />
        </Route>

        {/*AuthedAdminRoutes*/}
        <Route element={<RequireAdminAuth />}>
					<Route index element={<Navigate to={ADMIN_ROUTES.Home.url} replace />} />
					<Route path={ADMIN_ROUTES.Home.url}
            element={<AdminHome title="Admin" />} />
					{/*<Route path={ADMIN_ROUTES.ContentSuggestions.url}
            element={<AdmContentSuggestions title="Content Suggestions" />} />*/}
					<Route path={ADMIN_ROUTES.Groups.url}
            element={<AdmGroups title="Groups" />} />
					<Route path={ADMIN_ROUTES.GroupDetails.url}
            element={<AdmGroupDetails title="Group Details" />} />
					<Route path={ADMIN_ROUTES.GroupCreate.url}
            element={<AdmGroupCreate title="Create Group" />} />
          {/*<Route path={ADMIN_ROUTES.ManageFeeds.url}
            element={<AdmManageFeeds title="Manage Feeds" />} />*/}
          <Route path={ADMIN_ROUTES.RewardPoints.url}
            element={<AdmRewardPoints title="Reward Points" />} />
          {/*<Route path={ADMIN_ROUTES.Broadcasts.url}
            element={<AdmBroadcasts title="Broadcasts" />} />
          <Route path={ADMIN_ROUTES.Users.url}
            element={<AdmUsers title="Users" />} />*/}
				</Route>

        {/*CatchAllOtherRoutes*/}
        <Route
          path="*"
          element={
            true ? (
              <Page404 title={ERROR_ROUTES.Page404.title} />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
      </Route>
    </Routes>
  );
}

