import { Box, Stack, Modal, styled } from '@mui/material';
import { Card, Typography } from 'core';
import React from 'react';

import ShareIcon from '@mui/icons-material/Share';
import ReferralCard from './ReferralCard';
import { useNavigate } from 'react-router-dom';
import { socialMedia } from 'config/ui.config';
import { userSelectedFeed } from 'services/orgFeedApi';
import { useDispatch } from 'react-redux';

const StyledReferAndEarnCard = styled(Stack)(({ theme, color_variant }) => ({
  backgroundColor:
    color_variant === 'dark'
      ? theme.palette.background.paper
      : theme.palette.background.section,
  padding: '1.5rem',
  alignItems: 'center',
  justifyContent: 'center',
}));
const StyledModalCard = styled(Card)(({ theme, height }) => ({
  position: 'absolute',
  backgroundColor: '#b8e3e9',
  height: 270,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -75%)',
  bgcolor: 'background.paper',
  padding: '10px',
  width: 600,
  display: 'flex',
  justifyContent: 'center',
  [theme.breakpoints.down('sm')]: {
    width: 350,
    padding: '5px',
    height: 160,
  },
}));

export default function ReferAndEarnCard({ theme }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    dispatch(userSelectedFeed(null));
    navigate('/submit');}
  const handleClose = () => setOpen(false);
  return (
    <>
      <Modal onClose={handleClose} open={open}>
        <StyledModalCard>
          <ReferralCard onClose={handleClose} theme={theme} open={open} />
        </StyledModalCard>
      </Modal>
      <Stack sx={{ borderRadius: '5rem' }}>
        <StyledReferAndEarnCard justifyContent="center" color_variant="dark">
          {/*<Typography size="xlarge" sx={{ color: '#8f9297' }} fontWeight={900}>
            40
          </Typography>
          <Typography
            size="medium"
            sx={{ color: '#8f9297', textAlign: 'center' }}
          >
            Your referral points so far
          </Typography>*/}
        </StyledReferAndEarnCard>
        <StyledReferAndEarnCard
          sx={{
            backgroundColor: theme.palette.background.paper,
          }}
          color_variant="white"
          alignItems="center"
          justifyContent="center"
          spacing={2}
          textAlign="center"
        >
          <Box
            sx={{
              padding: '1rem',
              backgroundColor: theme.palette.background.paper,
              justifyContent: 'center',
              borderRadius: '100vh',
              position: 'relative',
              height: '75px',
              width: '75px',
              boxShadow:
                '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%), inset -5px -4px 10px 0px rgb(0 0 0 / 20%)',
            }}
          >
            {/* <ShareIcon
              onClick={handleOpen}
              sx={{
                height: 50,
                width: 50,
                color: theme.palette.primary.main,
              }}
            /> */}
            <span
              style={{
                transform: 'translate(-50%, -50%)',
                left: '43%',
                top: '53%',
                position: 'absolute',
                cursor:"pointer"
              }}
              onClick={handleOpen}
            >
              {socialMedia['ShareIcon']}
            </span>
          </Box>

          <Typography size="xlarge" sx={{ color: '#8f9297' }} fontWeight={600}>
            Share Content
          </Typography>
          <Typography
            size="small"
            fontWeight={800}
            sx={{ maxWidth: 200, color: '#8f9297' }}
          >
            Earn 100 points for each content you suggest.
          </Typography>
        </StyledReferAndEarnCard>
      </Stack>
    </>
  );
}
