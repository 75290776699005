import { Box, Stack, styled } from "@mui/material";
import { Button, Typography } from "core";
import { NIU_HEALTH_BASE_URL } from 'config/app.config';

const StyledAppointmentCard = styled(Stack)(({ theme }) => ({
  //   backgroundImage: "url(/assets/images/covidBanner.png)",
  height: "14.8rem",
  justifyContent: "center",
  position: "relative",
  [theme.breakpoints.down("lg")]: {
    maxWidth: "30%",
    margin: "auto",
  },
  [theme.breakpoints.down("md")]: {
    maxWidth: "35%",
    margin: "auto",
  },
}));
const StyledImage = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "15rem",
  justifyContent: "center",
  [theme.breakpoints.down("lg")]: {
    width: "20rem",
    height: "20rem",
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  background: theme.palette.primary.lighter,
  color: theme.palette.background.paper,
  boxShadow: "none",
  fontSize: "18px",
  maxWidth: "300px",
  textTransform: "capitalize",
  "&:hover": {
    background: theme.palette.primary.lighter,
  },
}));
const StyledBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  background: "#000",
  height: "14.8rem",
  width: "100%",
  opacity: "0.3",
  [theme.breakpoints.down("lg")]: {
    width: "20rem",
    height: "20rem",
  },
}));
const StyledText = styled(Stack)(({ theme }) => ({
  left: "10px",
  rowGap: 10,
  bottom: "10px",
  border: `1px solid ${theme.palette.primary.dark}`,
  padding: "5.9rem 2rem 0.8rem",
  alignItems: "center",
  justifyContent: "center",
  position: "absolute",

  [theme.breakpoints.down("xl")]: {
    padding: "5.7rem 1.5rem 0.8rem",
    left: "9px",
  },
  [theme.breakpoints.down("lg")]: {
    left: "-3.7rem",
    bottom: "14px",
    padding: "1.2rem 4.8rem 0.8rem",
  },
  [theme.breakpoints.down("md")]: {
    left: "-1rem",
    padding: "1.6rem 4.8rem 0.8rem",
  },
  [theme.breakpoints.down("sm")]: {
    left: "-5rem",
    padding: "1.6rem 5rem 0.8rem",
  },
}));

export default function TeleHealthAndPersonCare({ theme }) {
  return (
    <StyledAppointmentCard>
      <Stack rowGap={2} alignItems="center" justifyContent="center">
        <StyledImage>
          <img
            src="/assets/images/banner.png"
            alt="Book An Appintment"
            style={{
              height: "18rem",
              width: "100%",
            }}
          />
        </StyledImage>
        <StyledBox></StyledBox>
        <StyledText>
          <Typography
            fontWeight={600}
            size="large"
            color_variant="white"
            sx={{ maxWidth: "14rem" }}
          >
            TeleHealth & In-Person Care
          </Typography>
          <StyledButton //{NIU_HEALTH_BASE_URL+'?page=book-an-appointment'}
              disableRipple
              //href={NIU_HEALTH_BASE_URL+'/patient/appointments'}
              href={NIU_HEALTH_BASE_URL+'/patient/marketplace'}
              variant="contained">
                Book an Appointment
          </StyledButton>
        </StyledText>
      </Stack>
    </StyledAppointmentCard>
  );
}
