import { sSApi } from "./sSApi";
//import { SA_DEF_ORG_ID } from 'config/app.config';
//---------------------------------------------------------

export const contentSuggestionApi = sSApi.injectEndpoints({
  endpoints: (builder) => ({
    contentSuggestionCreate: builder.mutation({
      query: ({ params }) => {
        //console.log('contentSuggestionCreate', Object.fromEntries(params.entries()));
        return {
          url: "/contentsuggestion",
          method: "POST",
          body: params,
        };
      },
    }),
    contentSuggestionUpdate: builder.mutation({
      query: (params) => {
        return {
          url: "/contentsuggestion/" + params.contentSuggestionId,
        };
      },
    }),
    contentSuggestionList: builder.query({
      query: ({ page, filter, orgId }) => {
        page = page || 1;
        //filter = filter || {is_approved: null};
        //orgId = orgId ?? SA_DEF_ORG_ID;
        //let aUrl = '/company/'+orgId+'/contentsuggestions?page='+page;
        let aUrl = '/contentsuggestion?page='+page;
        if(filter) { aUrl += '&filter='+JSON.stringify(filter); }
        return { url: aUrl };
      },
    }),
    contentSuggestionDetails: builder.query({
      query: (params) => {
        return {
          url: "/contentsuggestion/" + params.contentSuggestionId,
        };
      },
    }),
    contentSuggestionDelete: builder.query({
      query: (params) => {
        return {
          url: "/contentsuggestion/" + params.contentSuggestionId,
          method: "DELETE",
        };
      },
    }),
    contentSuggestionDeleteMedia: builder.query({
      query: (params) => {
        return {
          url: `/contentsuggestion/${params.contentSuggestionId}/media?ids=["j2hGpKAtoUgrv4V7vjU8Mi3MBZ9R4I8XJo7g9Yuy.jpg"]`,
          method: "DELETE",
        };
      },
    }),
  }),
});
//---------------------------------------------------------

export const {
  useContentSuggestionDetailsQuery,
  useContentSuggestionListQuery,
  useContentSuggestionUpdateMutation,
  useContentSuggestionCreateMutation,
  useContentSuggestionDeleteMutation,
  useContentSuggestionDeleteMediaMutation,
} = contentSuggestionApi;
