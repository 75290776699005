import { Box, Stack, styled } from "@mui/material";
import { grey } from "@mui/material/colors";
import { badges } from "config/ui.config";
import { HOME_ROUTES } from "config/routes.config";
import { Button, Card, Typography } from "core";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useBadgeEarnedQuery } from "services/badgesApi";
import { useTranslation } from "react-i18next";

const StyledViewAllButton = styled(Button)(({ theme }) => ({
  background: theme.palette.background.paper,
  color: grey[600],
  border: "2px solid grey",
  boxShadow: "none",
  fontSize: "14px",
  maxWidth: 250,
  display: "flex",
  justifyContent: "center",
  alignSelf: "center",
  textAlign: "center",
  "&:hover": {
    background: theme.palette.background.paper,
  },
}));

export default function YourBadges() {
  // const [badgesArray,setbadgesArray] = useState([])
  // const { data: Badges } = useBadgeEarnedQuery();
  const { t: home } = useTranslation('home')

  return (
    <Card sx={{
      width: {
        xs: "100vw",
        md: "100%",
      }
    }}>
      <Stack rowGap={3} margin="1rem" alignItems="center">
        <Stack>
          <Typography color_variant="dark" size="large" fontWeight={600}>
            {home('your_badges')}
          </Typography>
        </Stack>

        <Stack>
          {badges["tyro"]}
          {badges["socialStar"]}
          {badges["veteran"]}
        </Stack>

        <StyledViewAllButton
          component={Link}
          to={HOME_ROUTES.Badges.url}
          size="small"
        >
          {home('view_upcoming_badges')}
        </StyledViewAllButton>
      </Stack>
    </Card>
  );
}
