import { useEffect, useState, useTransition, } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Stack, styled } from '@mui/material';
import { checkIsAdmin } from 'services/auth/authSlice';
import {
  useGetSMediaDataQuery, useGetSMediaAccountListQuery,
  initSMediaMgr, getSMediaMgr, resetSMediaTokens, // setAccount,
} from 'services/sMediaApi';
import { checkAnySMConnected } from 'lib/appFx';
//import {fGetCookie} from 'lib/netFx';
//import { formatNumberToLocaleString } from 'lib/utils';
import SocialNetworks from './SocialNetworks';
import Spinner2 from 'core/Spinner/Spinner2';
import { Card, Typography } from 'core';//Button,
import { useTranslation } from 'react-i18next';
//import { SocialNetworkCard } from "sections";
//---------------------------------------------------------

const StyledSocialNetworks = styled(Stack)(({ theme }) => ({
  // backgroundColor: theme.palette.background.section,
  padding: '1rem 0.5rem',
  //height: '120px',
  [theme.breakpoints.down('md')]: {
    height: 'auto',
  },
}));

/*const StyledReferAndEarnCard = styled(Stack)(({ theme, color_variant }) => ({
  backgroundColor:
    color_variant === 'dark'
    ? theme.palette.background.paper
    : theme.palette.background.section,
  padding: '1.5rem',
  alignItems: 'center',
  justifyContent: 'center',
}));*/
//---------------------------------------------------------

export default function SMediaWidget({
  network_size,
  theme,
  isUserList,
  uFeedFilter,
  setUFeedFilter,
}) {
  let theLstSMedia, redSMMgr;
  const [sMMgrOK, setSMMgrOK] = useState(false);
  const dispatch = useDispatch();
  const { data: usrSMAcc } = useGetSMediaAccountListQuery();
  const isAdmin = useSelector(checkIsAdmin);
  const { t: home } = useTranslation('home');
  //const sampleNetworkSize = network_size; //ToBeRemoved
  //----------------------

  let dErr, dWait, dFetch, dData;

  const {
    data: dDataA,
    error: dErrA,
    isLoading: dWaitA,
    isFetching: dFetchA,
    isSuccess: dOKA,
    refetch: refetchA,
  } = useGetSMediaDataQuery({ isAdmin: isAdmin }, { skip: sMMgrOK }); //, refetch}

  dErr = dErrA;
  dWait = dWaitA;
  dFetch = dFetchA;
  dData = dOKA && dDataA ? dDataA : null;
  //----------------------

  //useEffect(() => { refetch(); }, []);
  redSMMgr = useSelector(getSMediaMgr);
  //console.log('sMMgrOKSMW '+sMMgrOK+' '+dData, {redSMMgr, usrSMAcc});
  useEffect(() => {
    if (dData) {
      setSMMgrOK(true);
      dispatch(initSMediaMgr(dData));
      usrSMAcc?.tokens && dispatch(resetSMediaTokens(usrSMAcc));
    }
  }, [dData, dispatch]);

  let allConnMsg, anySMConnected = checkAnySMConnected(redSMMgr);

  if (sMMgrOK && !anySMConnected) {
    allConnMsg = [
      `${home("please_connect_your")}`,
      <br key="nLBrUReg" />,
      `${home("social_media_accounts")}`,//"social media account(s)",
    ];
  }
  //---------------------------------------------------------

  let lstSMConn = (
    <>
      <Box>
        {dWait || dFetch ? (
          <Spinner2 />
        ) : dErr ? (
          ''
        ) : (
          redSMMgr && (
            <SocialNetworks
              theme={theme}
              sMediaData={redSMMgr}
              refetchA={refetchA}
              isUserList={isUserList}
              uFeedFilter={uFeedFilter}
              setUFeedFilter={setUFeedFilter}
            />
          )
        )}
      </Box>
    </>
  );

  theLstSMedia = (
    <>
      <StyledSocialNetworks className="SocialMediaInner">
        <Stack rowGap={3} alignItems="center" justifyContent="center">
          <Typography fontWeight={600} size="small">
            {isUserList ? `${home("filter_user's_feed_list")}` : `${home("your_social_networks")}`}

          </Typography>
          {lstSMConn}
          {allConnMsg && (allConnMsg)}
          {/* <Stack alignItems="center">
					<Typography color_variant="lightGrey" textAlign="left">
					Your total network size
					</Typography>
					<Typography variant="h4">
					{formatNumberToLocaleString(sampleNetworkSize)}
					</Typography>
				</Stack> */}
        </Stack>
      </StyledSocialNetworks>
    </>
  );
  //----------------------

  return (
    <>{theLstSMedia}</>
  );
}
