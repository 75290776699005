import React, { useEffect, useState } from "react";

const Flasher = props => {
  const [visible, setVisible] = useState(true);
  const mDelay = props?.delay || 3000;

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
      props?.resetFunction && props?.resetFunction()
    }, mDelay);
    return () => clearTimeout(timer);
  }, [mDelay]);

  return visible ? <div>{props.children}</div> : <div />;
};

export default Flasher;