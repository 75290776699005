import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { ClickAwayListener, List, ListItem, Popper, styled } from '@mui/material';
import { grey } from '@mui/material/colors';
import { Typography } from 'core';
import { FLG_DISABLE_AUTH_PAGES, NIU_HEALTH_BASE_URL } from 'config/app.config';
import { HOME_ROUTES } from 'config/routes.config';
import { fClearLocalStorageSSq } from 'lib/appFx';
import { logOut } from 'services/auth/authSlice';
//import { emptySMediaMgr } from 'services/sMediaApi';
//---------------------------------------------------------

const StyledMenuItem = styled(ListItem)(({ theme }) => ({
  //borderBottom: `2px solid ${grey[300]}`,
  borderRight: `2px solid ${grey[300]}`,
  borderLeft: `2px solid ${grey[300]}`,
  backgroundColor: theme.palette.background.paper,
  paddingRight: '4rem',
  cursor: 'pointer',
  '&:hover': { color: '#a4abc5' },
}));
//---------------------------------------------------------

export default function SettingsDropdownMenu({ ctnId, open, anchorEl, handleClose }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function fLogoutHandler() {
    //fDelCookie(REMEMBER_ME_CNAME);
    //fDelCookie(REMEMBER_ME_CNAME_AWS);
    fClearLocalStorageSSq();
    dispatch(logOut());
    //if(SA_ENV !== 'local') {}
    // navigate("/login", { replace: true });
    // dispatch(emptySMediaMgr());
    if(FLG_DISABLE_AUTH_PAGES) {
      //window.location.href = NIU_HEALTH_BASE_URL + '/login';
      //navigate(NIU_HEALTH_BASE_URL + '/login', { replace: true });
      window.location.replace(NIU_HEALTH_BASE_URL + '/login');
    } else {
      navigate('/signin', { replace: true });
    }
  }
  return (
    <Popper
      placement="bottom-end"
      disablePortal
      id={ctnId}
      open={open}
      anchorEl={anchorEl}
      sx={{ zIndex: 900, boxShadow: '0 3px 12px #d6e4f1' }}
      popperOptions={{
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [80, 2],
            },
          },
        ],
      }}
    >
      <ClickAwayListener onClickAway={handleClose}>
        <List sx={{ padding: '0' }}>
          <StyledMenuItem component={Link} to={HOME_ROUTES.Settings.url} sx={{ backgroundColor: '#1761fd' }}>
            <i className="ti-settings" style={{ color: '#a4abc5' }} />
            <Typography size="small" color_variant="dark" sx={{ textDecoration: 'none', color: '#fff', marginLeft: '10px' }}>
              Account Settings
            </Typography>
          </StyledMenuItem>
          <StyledMenuItem component={Link} to={HOME_ROUTES.Faq.url}>
            <i className="dripicons-lightbulb" style={{ color: '#a4abc5' }} />
            <Typography size="small" color_variant="dark" sx={{ marginLeft: '10px' }}>
              FAQs
            </Typography>
          </StyledMenuItem>
          <StyledMenuItem component={Link} to={HOME_ROUTES.Help.url}>
            <i className="ti-help" style={{ color: '#a4abc5' }} />
            <Typography size="small" color_variant="dark" sx={{ textDecoration: 'none', marginLeft: '10px' }}>
              Help
            </Typography>
          </StyledMenuItem>
          <StyledMenuItem component="a" href={HOME_ROUTES.PrivacyPolicy.url} target="_blank" rel="noopener noreferrer">
            <i className="ti-lock" style={{ color: '#a4abc5' }} />
            <Typography size="small" color_variant="dark" sx={{ textDecoration: 'none', marginLeft: '10px' }}>
              Privacy Policy
            </Typography>
          </StyledMenuItem>
          <StyledMenuItem onClick={fLogoutHandler}>
            <i className="ti-power-off" style={{ color: '#a4abc5' }} />
            <Typography size="small" color_variant="dark" sx={{ marginLeft: '10px' }}>
              Logout
            </Typography>
          </StyledMenuItem>
        </List>
      </ClickAwayListener>
    </Popper>
  );
}
