import React from "react";
import { Grid, Stack, styled } from "@mui/material";
import { Typography } from "core";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import AddIcon from "@mui/icons-material/Add";
import Remove from "@mui/icons-material/Remove";
import { grey } from "@mui/material/colors";

const StyledAnalyticsHeader = styled(Stack)(({ theme, highlight }) => ({
  padding: "0.5rem",
  backgroundColor: theme.palette.background.section,
}));

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const StyledGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    maxWidth: "100%",
    flexBasis: "100%",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      props.expanded === "false" ? (
        <AddIcon
          sx={{
            fontSize: "2rem",
            color: grey[400],
            padding: "3px",
            border: "1px solid",
          }}
        />
      ) : (
        <Remove
          sx={{
            fontSize: "2rem",
            color: grey[400],
            padding: "3px",
            border: "1px solid",
          }}
        />
      )
    }
    {...props}
  />
))(({ theme }) => ({
  paddingLeft: "5px",
  backgroundColor: theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, .03)",
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function FaqQus({ faqData }) {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Grid container spacing={5} justifyContent="center" alignItems="center" direction="row">
      <StyledGrid item xs={6}>
        <Stack spacing={5}>
          {faqData.map((section, sectionIndex) => (
            <Stack spacing={1} key={sectionIndex}>
              <StyledAnalyticsHeader>
                <Typography size="medium" fontWeight={600} sx={{ justifyContent: "center", display: "flex" }}>
                  {section.sectionTitle}
                </Typography>
              </StyledAnalyticsHeader>
              {section.qas.map((qa, qaIndex) => (
                <Accordion key={qaIndex} expanded={expanded === `panel-${sectionIndex}-${qaIndex}`} onChange={handleChange(`panel-${sectionIndex}-${qaIndex}`)}>
                  <AccordionSummary expanded={expanded === `panel-${sectionIndex}-${qaIndex}` ? "true" : "false"}>
                    <Typography fontWeight={600}>{qa.question}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography color_variant="lightGrey" fontWeight={600}>
                      <div dangerouslySetInnerHTML={{ __html: qa.answer }} />
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
            </Stack>
          ))}
        </Stack>
      </StyledGrid>
    </Grid>
  );
}
