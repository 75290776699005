import { sSApi } from './sSApi';
//---------------------------------------------------------

export const badgesApi = sSApi.injectEndpoints({
  endpoints: (builder) => ({
    badgeList: builder.query({
      query: () => {
        return {
          url: "/badge/",
        };
      },
    }),
    badgeEarned: builder.query({
      query: () => {
        return {
          url: "/badges/earned",
        };
      },
    }),
    badgeDetails: builder.query({
      query: (params) => {
        return {
          url: "/badge/" + params.badgeId,
        };
      },
    }),
    deleteBadge: builder.query({
      query: (params) => {
        return {
          url: "/badge/" + params.badgeId,
          method: "DELETE",
        };
      },
    }),
    createBadge: builder.query({
      query: (params) => {
        return {
          url: "/badge",
          method: "POST",
          body: params,
        };
      },
    }),
  }),
});
//---------------------------------------------------------

export const {
  useBadgeListQuery,
  useBadgeEarnedQuery,
  useBadgeDetailsQuery,
  useBadgeCreatesQuery,
  useDeleteBadgeMutation,
} = badgesApi;
