import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { SA_ENV } from 'config/app.config';
import appStore from './store/appStore';
import App from './App';
import 'styles/index.css';
//---------------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(//<BrowserRouter basename="{SA_APP_HOSTNAME}">
  <React.StrictMode>
    <Provider store={appStore}>
      <BrowserRouter basename="/">
        <HelmetProvider>
          <App />
          {SA_ENV !== 'local' && window.addTawkTo()}
        </HelmetProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);
