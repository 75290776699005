//AdminDashboard
//import { Link} from "react-router-dom";
//import AdmContentSuggestions from '../contentSuggestions';
import AdmContentSuggestions from '../ContentSuggestions';
//---------------------------------------------------------

export default function AdminHome() {
	let content = (
		<section className='tBdr2'>
			<h3>Admin Dashboard</h3>
			<main>
				<p>Coming Soon..</p>
			</main>
		</section>
	);
	content = <AdmContentSuggestions />;
	//---------------------------------------------------------

	return (
		<div>{ content }</div>
	);
};