import { useEffect, useState } from "react";
import { useSearchParams, useLocation } from "react-router-dom";
import { MAX_SAUTH_WIN_OPEN_TIMEMS } from 'config/app.config';
import { decodeSNectConnResp, setSelfClose } from 'lib/appFx';
import { fWriteCook, fDelCookie } from 'lib/netFx';
import LoaderPage from 'pages/LoaderPage';
//---------------------------------------------------------

//localhost:3000/ss-test?module=oauth&payload=Qfi8UbZJ0MSFTYiojIkl2XtJ3bmRXYsB3ay92d0VmbzJCLiIFZrdXMQdmbiojIkl2XuV2avR3ay92d0VmbzJCLicXWPBTc5JnViojIkl2XyV2c1Jye#_=_
//payload=base64decode(stringreverse(payload))

export default function TPRespHandler() {
	//useEffect(()=>{}, []);
	const location = useLocation();
	const [qParams] = useSearchParams();
	const params = Object.fromEntries([...qParams]);
	console.log('sMParams', params);
	setSelfClose(MAX_SAUTH_WIN_OPEN_TIMEMS + 1000);
	const [hasValidToken, setHasValidToken] = useState(false);

	useEffect(() => {
		if(hasValidToken) {
			//setTimeout(()=> {//self.window.close();
				setSelfClose(6000);
			//}, 4000);
		}
	});

	if(!hasValidToken) {
		if(('module' in params && params.module === 'oauth') &&
			('payload' in params && params.payload)
		) {
			const cName = 'sMAT_Ukn';
			fDelCookie(cName);
			let payload = params.payload;
			payload = decodeSNectConnResp(payload);
			//payload?.snetworktoken_id
			//	&& fWriteCook(cName, location.search.substring(1));//alert('Hi*'+cName);
			if(payload?.snetworktoken_id) {
				fWriteCook(cName, location.search.substring(1));
				setHasValidToken(true);
			}
			//payloadR: {"user_id":"Vryq0OYw","snetworktoken_id":"ngP1wkdR","snetworkplatform_id":"a1R3BYmO"}
			//setSelfClose(4000);
		} else { setSelfClose(2500); }//UserCancelledOrTooManyRequestsTwErr[code=&state=]
	} else { setSelfClose(2500); }

	return (
		<div style={{margin: 'auto', height:'100%', minHeight: '100%'}}>
			<LoaderPage className='xtBdr2'>
				<div style={{textAlign: 'center'}}>
					{hasValidToken && 'Connected Successfully..'}
				</div>
			</LoaderPage>
		</div>
	);
}