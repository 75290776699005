import { Stack, styled } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, FormInputField, FormProvider, Typography } from 'core';
import { ForgotPasswordSchema } from 'schema';
//import { grey } from '@mui/material/colors';
//import { Check } from '@mui/icons-material';
import { useForgotPasswordMutation } from 'services/auth/authApi';
import { NIU_HEALTH_BASE_URL } from 'config/app.config';
//---------------------------------------------------------

const StyledCheckbox = styled('div')(({ theme, checked }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '1rem',
  height: '1rem',
  backgroundColor: checked && theme.palette.primary.light,
  borderRadius: '3px',
  transition: '150ms',
  border: `1px solid ${theme.palette.primary.light}`,
  padding: '1rem',
}));

const StyledCheckboxGroup = styled(Stack)(() => ({
  flexDirection: 'row',
  alignItems: 'center',
  gap: '0.5rem',
}));

const StyledRoleCheckboxGroup = styled(Stack)(() => ({
  flexDirection: 'row',
  gap: '2rem',
}));

const InputField = styled(FormInputField)(({ theme }) => ({
  outline: `1px solid ${theme.palette.primary.main}`,
}));

const Label = styled(Typography)(({ theme }) => ({
  maxWidth: '25vw',
  [theme.breakpoints.down('md')]: {
    fontSize: '18px',
    maxWidth: '90vw',
  },
}));

const FormContainer = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(6),
}));
//---------------------------------------------------------

export default function ForgotPasswordForm() {
  const [forgotPassword] = useForgotPasswordMutation();
  const defaultValues = {
    email: '',
    role: 'employee',
  };

  const methods = useForm({
    resolver: yupResolver(ForgotPasswordSchema),
    defaultValues,
  });

  const { handleSubmit, setValue, watch } = methods;

  const role = watch('role');

  const onSubmit = async (data) => {
    // let formData = new FormData();
    // formData.append("email", data.email);

    await forgotPassword({ email: data.email });
  };
  const selectRole = (role) => {
    setValue('role', role);
  };

  /*Redirect the user to NIU Health login page before the form is visible*/
  window.location.href = NIU_HEALTH_BASE_URL;

  return (
    <></>
    // <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
    //   <FormContainer>
    //     <Stack spacing={2}>
    //       <Label size="medium" color_variant="lightGrey">
    //         Please enter your registered email address*
    //       </Label>
    //       <InputField
    //         name="email"
    //         type="email"
    //         InputProps={{
    //           style: {
    //             backgroundColor: (theme) => theme.palette.background.paper,
    //             minWidth: '25vw',
    //           },
    //         }}
    //       />
    //     </Stack>
    //     <Stack spacing={1} textAlign="left">
    //       <Label size="medium" color_variant="lightGrey">
    //         Please select your role
    //       </Label>
    //       <StyledRoleCheckboxGroup>
    //         <StyledCheckboxGroup>
    //           <StyledCheckbox name="role" checked={role === 'employee'} onClick={() => selectRole('employee')}>
    //             <Check sx={{ color: 'white' }} />
    //           </StyledCheckbox>
    //           <Typography color_variant="lightGrey">EMPLOYEE</Typography>
    //         </StyledCheckboxGroup>
    //         <StyledCheckboxGroup>
    //           <StyledCheckbox name="role" checked={role === 'admin'} onClick={() => selectRole('admin')}>
    //             <Check sx={{ color: 'white' }} />
    //           </StyledCheckbox>
    //           <Typography color_variant="lightGrey">ADMIN</Typography>
    //         </StyledCheckboxGroup>
    //       </StyledRoleCheckboxGroup>
    //     </Stack>

    //     <Stack spacing={3}>
    //       <Button
    //         variant="contained"
    //         type="submit"
    //         size="large"
    //         // component={Link}
    //         // to={AUTH_ROUTES.ResetPassword.url}
    //         sx={{
    //           color: (theme) => theme.palette.background.paper,
    //           fontWeight: 600,
    //           letterSpacing: '0.04rem',
    //         }}
    //       >
    //         RESET PASSWORD
    //       </Button>
    //       <Button
    //         variant="contained"
    //         type="submit"
    //         size="large"
    //         // component={Link}
    //         // to={AUTH_ROUTES.SignUp.url}
    //         sx={{
    //           background: '#DCE4E7',
    //           '&:hover': { background: grey[300] },
    //           color: grey[600],
    //           fontWeight: 600,
    //           letterSpacing: '0.09rem',
    //         }}
    //       >
    //         CANCEL
    //       </Button>
    //     </Stack>
    //   </FormContainer>
    // </FormProvider>
  );
}
