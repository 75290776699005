//AdminDashboard
//import { Link} from "react-router-dom";
//import AdmContentSuggestions from '../contentSuggestions';
import { useTranslation } from 'react-i18next';
import AdmContentSuggestions from '../ContentSuggestions';
//---------------------------------------------------------

export default function AdminHome() {
	const { t: home } = useTranslation('home')
	let content = (
		<section className='tBdr2'>
			<h3>{home('admin_dashboard')}</h3>
			<main>
				<p>{home("coming_soon")}</p>
			</main>
		</section>
	);


	content = <AdmContentSuggestions />;
	//---------------------------------------------------------

	return (
		<div>{content}</div>
	);
};