import React, { useState } from 'react';
import {
  Grid, IconButton, InputAdornment, Stack, styled, TextField
} from "@mui/material";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import { grey } from "@mui/material/colors";
import { useTranslation } from 'react-i18next';

const StyledGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    maxWidth: "100%",
    flexBasis: "100%",
  },
}));

function ImageAndSearch({ theme, faqData, setFilteredFaqData }) {
  const [searchTerm, setSearchTerm] = useState('');
  const { t: home } = useTranslation('home')
  const handleSearch = (event) => {
    const { value } = event.target;
    setSearchTerm(value);

    if (value.trim() === '') {
      setFilteredFaqData(faqData);
    } else {
      const filteredData = faqData.map(section => ({
        ...section,
        qas: section.qas.filter(qa =>
          qa.question.toLowerCase().includes(value.toLowerCase()) ||
          qa.answer.toLowerCase().includes(value.toLowerCase())
        )
      })).filter(section => section.qas.length > 0);
      setFilteredFaqData(filteredData);
    }
  };

  return (
    <Grid
      container
      spacing={3}
      justifyContent="center"
      alignItems="center"
      direction="row"
    >

      <StyledGrid item xs={6}>
        <Stack spacing={5}>
          <img src="/assets/images/faqImage.png" alt="faq" />
          <TextField
            name="search"
            size="small"
            placeholder={home('search_a_topic_here')}
            fullWidth
            value={searchTerm}
            onChange={handleSearch}
            InputProps={{
              style: {
                backgroundColor: theme.palette.background.paper,
                padding: "3px 0",
                border: "0px",
              },
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton>
                    <ZoomInIcon sx={{ fontSize: "2rem", color: grey[700] }} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Stack>
      </StyledGrid>
    </Grid>
  );
}

export default ImageAndSearch;
