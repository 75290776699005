import { createSlice, current } from "@reduxjs/toolkit";
import { sSApi, /*sSApiBaseQuery, baseQueryWithReAuth*/ } from './sSApi';
//---------------------------------------------------------

export const gameApi = sSApi.injectEndpoints({
	endpoints: (builder) => ({
		feedEngPoints: builder.query({//query: (params) => '/points',
			query: (page=1) => '/points?page='+page,
		}),
    feedEngPointsMult: builder.query({
      //keepUnusedDataFor: 0,
      async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
        let page = 1, pgGT = 0, apiMultResp;//console.log('_arg', _arg);
        const loopMax = 5;//arg?.maxPage || 5;
        const filter = _arg?.filter || null;
        const allRec = [];
        const _fetchData = async(page=1, argFilter=null) => {
          let aUrl = '/points?page='+page, fDResp;
          if(argFilter !== null) {
            aUrl += '&filter='+JSON.stringify(argFilter);
          }
          let fResp = await fetchWithBQ(aUrl);
          //await _queryApi.dispatch(api.endpoints.feedEngPoints.initiate(page));
          if(fResp?.error) throw fResp.error;
          fResp = fResp?.data;

		      if(fResp?.points) {
            fDResp = { points: fResp?.points };
            if(fResp?.pagination) {
              fDResp['pagination'] = fResp?.pagination;
            }
          }//console.log('fDResp', fDResp);
          return fDResp;
        };

        const dApiResp = await _fetchData(page, filter);
        if(dApiResp?.points.length) {
          allRec.push(...dApiResp?.points);
          //const currPage = dApiResp?.pagination?.page?.current;
          pgGT = dApiResp?.pagination?.page?.total;

          if(page < pgGT) {
            page++; let dApiNext;
            for(let cnt=page; cnt<=loopMax && cnt<=pgGT; cnt++) {
              dApiNext = await _fetchData(cnt, filter);
              if(dApiNext?.points.length) {
                allRec.push(...dApiNext?.points);
              } else { break; }
            }
          }
        }

        if(dApiResp?.points) {
          apiMultResp = { points: allRec };
          if(dApiResp?.pagination) {
            apiMultResp['pagination'] = dApiResp?.pagination;
          }
        }//console.log('apiFEngPtsMultResp '+pgGT, apiMultResp);
        return {data: apiMultResp};
      },
    }),
	}),
});
//---------------------------------------------------------

export const {
  useFeedEngPointsMultQuery,
	//useLazyFeedEngPointsQuery,
} = gameApi;
//---------------------------------------------------------

const gameSlice = createSlice({
  name: 'game',
  initialState: { engPointsRef: null },
  reducers: {
    rInitSAEngPoints: (state, action) => {
      //console.log('rIinitSAEngPointsAct', action.payload);
      //if(state.engPointsRef && Object.keys(state.engPointsRef).length) { return state; }
      state.engPointsRef = action.payload;
      //console.log('new_rInitSAEngPoints', current(state));
      return state;
    },
    rSetSAEngPointsDataMult: (state, action) => {
      const ePtsInData = action?.payload;
      let rCnt=0, upCnt=0, errM={};
      const newSt = {...current(state)};//current(state);
      const newEPtsRef = structuredClone(newSt?.engPointsRef);
      //const newEPtsRef = structuredClone({...newSt?.engPointsRef});
      const srcSMs = Object.keys(ePtsInData);
      //console.log('rSetSAEngPointsDataMultAttr ', {
      //  ePtsInData, srcSMs, newEPtsRef, newSt
      //});
      if(newEPtsRef && srcSMs.length) {
        srcSMs.forEach(sK => {
          //Object.entries(ePtsInData[sK]).forEach((dK, engInf) => {
          Object.keys(ePtsInData[sK]).forEach((dK) => {
            const engInf = ePtsInData[sK][dK];
            rCnt++;//console.log('damn', {sK, dK, engInf});
            //if(!newEPtsRef.hasOwnProperty(sK)) { newEPtsRef[sK] = {}; }
            //if(!newEPtsRef[sK].hasOwnProperty(dK)) { newEPtsRef[sK][dK] = {}; }
            if(newEPtsRef.hasOwnProperty(sK) &&
              newEPtsRef[sK].hasOwnProperty(dK)
            ) {
              const eT = engInf['eType'];
              //if(!newEPtsRef[sK][dK].hasOwnProperty(eT)) {
              //  newEPtsRef[sK][dK][eT] = null;
              //}
              if(newEPtsRef[sK][dK].hasOwnProperty(eT)) {
                const ePtsCurr = parseInt(newEPtsRef[sK][dK][eT]);
                const ePtsNew = parseInt(engInf?.newEngPoints);
                //console.log('uPtsInf', {sK, dK, eT, ePtsCurr, ePtsNew});
                if(engInf?.resp && ePtsCurr !== ePtsNew) {
                  newEPtsRef[sK][dK][eT] = ePtsNew;
                  upCnt++;
                }
              }
            }
          });//E4*dK
        });//E4*sK
        console.log('newStUp', {rCnt, upCnt, newSt});
        if(upCnt) {
          newSt.engPointsRef = newEPtsRef;
          return newSt;
          //state.engPointsRef = newEPtsRef;
          //return state;
        }
      }
    },
  },
});

export default gameSlice.reducer;
export const {
  rInitSAEngPoints,
  rSetSAEngPointsDataMult,
} = gameSlice.actions;

export const rGetRefEngPoints = (state) => state?.game?.engPointsRef; // || {};
//---------------------------------------------------------