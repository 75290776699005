import {
  useState, useEffect, //useRef,
  useImperativeHandle, Fragment
} from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
//import PropTypes from "prop-types";
//import { down } from "styled-breakpoints";
//import { useBreakpoint } from "styled-breakpoints/react-styled";
//import moment from "moment";
import {
  useTheme, Box, Typography, Drawer,
  IconButton, Collapse, List, ListItem, Divider,
  //CssBaseline, Stack, styled, Grid, ListItemText,
} from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

import {
  HOME_BASE_URL, FLG_DISABLE_AUTH_PAGES, NIU_HEALTH_BASE_URL, SA_ENV,
  //SA_DEF_ORG_GROUP_ID, SA_IMG_BASE_URL,
} from 'config/app.config';
import { HOME_ROUTES } from 'config/routes.config';
import { fClearLocalStorageSSq } from 'lib/appFx';
//import {fClearFMNotCooks, openNewAppTab, fEncodeAwsToken} from 'lib/appFx';
//import { isValidUrl } from 'lib/netFx';
//import { Leaderboard, ReferAndEarnCard, //BookAppointmentBanner, TeleHealthAndPersonCare,
//} from 'sections';
//import ProfileInfoCard from 'sections/home/ProfileInfoCard';
//import SMediaWidget from 'sections/widgets/sMediaWidget/SMediaWidget';
import {
  logOut, checkIsAdmin,
  //selectAuthUserDetails, selectUserOrgId, selectAuthUserId
} from 'services/auth/authSlice';
import { useTranslation } from "react-i18next";
//import "styles/_materialdesignicons.scss";
//import 'styles/fonts/dripicons-v2.woff';
//---------------------------------------------------------

/*const settings = {
  dots: false, infinite: true, slidesToShow: 1, slidesToScroll: 1,
  autoplay: true, speed: 1000, autoplaySpeed: 5000, adaptiveHeight: true,
};
const StyledAnalyticsSliderCard = styled(Box)(({ theme, highlight }) => ({
  display: 'flex', flexDirection: 'column', flexWrap: 'wrap', flexGrow: '1',
  justifyContent: 'center', alignItems: 'center',
  backgroundColor: highlight ? '#F5F5F5' : theme.palette.background.paper,
  width: '13.75rem', height: '14.75rem', marginLeft: 'auto', marginRight: 'auto',
  gap: '0.5rem', borderRadius: '8px',
  border: highlight && `3px solid ${theme.palette.background.default}`,
  boxShadow: theme.shadows[1],
  [theme.breakpoints.down('md')]: { marginTop: '0rem', width: '18.75rem', },
  [theme.breakpoints.down('lg')]: { marginLeft: '0', },
}));
const StyledCenterGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: { width: '100%', marginTop: '7rem', },
}));*/
const winWidth = (typeof window !== 'undefined') ? window.innerWidth : null;
//console.log('winWidth', winWidth);
//---------------------------------------------------------

export default function LeftMenu(props, ref) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isAdmin = useSelector(checkIsAdmin);
  //const hemRef = useRef(null);
  const navigate = useNavigate();
  const { pathname } = useLocation(); //console.log('pathname', pathname);
  let currPage = pathname.replace('/', '');//let currPage = '/home'
  const dBug = SA_ENV === 'local';
  const { t: sidebar } = useTranslation('sidebar')
  //const dBug = false;

  useImperativeHandle(ref, () => {
    return {
      fToggleDrawer() {//hemRef.current.handleDrawerToggle();
        handleDrawerToggle();
      },
    };
  }, []);
  //----------------------

  let currLtMenuShortStat;
  let drawerWidth = 258;//270
  if (winWidth) {
    if (winWidth === 768) {
      currLtMenuShortStat = true;
      drawerWidth = 80;
    } else { currLtMenuShortStat = false; }
  } else { currLtMenuShortStat = false; }
  const [lMnuShortDisplay, setLMnuShortDisplay] = useState(currLtMenuShortStat);
  //----------------------

  useEffect(() => {
    //setTimeout(()=> {
    const lMnuBtnLogout = dBug ? null :
      document.getElementById('btnSignout2');
    if (lMnuBtnLogout) {
      //lMnuBtnLogout.style.marginTop = '3.44rem';
      //lMnuBtnLogout.style.paddingTop = '4.38rem';
      //lMnuBtnLogout.style.marginBottom = '0';
    }
    //(document.window.innerHeight - (document.window.innerHeight * 0.9))+'px';
    //(screen.height - (screen.height * 0.9))+'px';
    //	}, 50);
  }, []);

  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [mySocialOpen, setMySocialOpen] = useState(
    currPage === 'home' && !dBug ? false : true
  );
  const fToggleMySocialMenu = (e) => {//currentTarget
    //console.log('cSrcId', e.target?.getAttribute('data-cid'));
    const clSrcDId = e.target?.getAttribute('data-cid');
    if (clSrcDId && ['lMnuICollp1', 'lMnuICollp2'].includes(clSrcDId)) {
      setMySocialOpen(!mySocialOpen);
    } else { navigate("/home"); }
  }

  const handleDrawerToggle = () => {
    //Toggle the state for mobile view
    //console.log('LtMenuToggled '+currLtMenuShortStat + ' - '+lMnuShortDisplay);
    setMobileOpen(!mobileOpen);
    setLMnuShortDisplay((prevSt) => !prevSt);
    const ctnMidPan = document.getElementById('ctnMidPanel');
    //console.log('BB'+ctnMidPan.currentStyle);
    //let mLPg = ctnMidPan.style.marginLeft;
    let mLPg = getComputedStyle(ctnMidPan).marginLeft;
    //console.log('mL '+mLPg);
    mLPg = parseInt(mLPg.replace('px', ''));

    //Check if the window width is for desktop view
    if (winWidth && winWidth > 600) {
      //ctnMidPan.style.marginLeft = lMnuShortDisplay ?
      //  '270px' : '-61px';
      //ctnMidPan.style.marginLeft = mLPg > 0 ? '-61px' : '270px';
      // Adjust the margin based on the updated state of lMnuShortDisplay
      ctnMidPan.style.marginLeft = lMnuShortDisplay ? '-61px' : '270px';
    }
  };
  //----------------------

  //const userDetail = useSelector(selectAuthUserDetails);
  //userDetail && console.log('userDetail', userDetail);
  //const userAwsAuthData = useSelector(selectAuthUserAwsData);
  //const awsToken = userAwsAuthData?.accessToken;
  //let qToken = awsToken ? '?awsToken=' + awsToken : '';
  let qToken = '';

  /*let userName, profilePic;
  if(userDetail?.email) {
    userName = userDetail?.first_name;// + ' ' + userDetail?.last_name;
    profilePic = isValidUrl(userDetail?.medias?.profile?.url) ?
     userDetail?.medias?.profile?.url : SA_IMG_BASE_URL + '/avtar1.png' ;
  }
  const {
    //data: userData, //isLoading: isProfileDetailsLoading,
    isFetching, refetch: refetchProfileDetails,
  } = useUserDetailsByIdQuery( { userId: userDetail?.id },
    { skip: userDetail?.email });*/
  //----------------------

  function fLogoutHandler() {
    //fClearFMNotCooks();
    fClearLocalStorageSSq();
    dispatch(logOut());
    //if(dBug) {}
    //navigate('/signin', { replace: true });
    //dispatch(emptySMediaMgr());
    if (FLG_DISABLE_AUTH_PAGES) {
      //navigate(NIU_HEALTH_BASE_URL + '/login', { replace: true });
      window.location.replace(NIU_HEALTH_BASE_URL + '/login');
    } else {
      navigate('/signin', { replace: true });
    }
  }

  const mItemBtnCss = {
    height: '1.25rem', padding: '1.7rem 0', justifyContent: 'left',
    '&:hover': { color: '#6610f2' },//'#7367f0', },//#6610f2
  };
  const mItemIconCss = {
    //fontSize: '1.5rem',//fontSize: '0.7rem',//fontSize: '1rem!important',
    //color: '#a6aed4', //color: (theme) => theme.palette.secondary.dark,
    //marginLeft: '1.88rem!important',
  };
  const mItemIconOverrides = {
    color: '#a6aed4', fontSize: '1rem',
    marginLeft: '1.5rem', //'&:hover': { color: '#6610f2', }
  };
  const mItemTxtCss = {//color: #1c1c1c
    fontFamily: 'Poppins, sans-serif',
    fontSize: '0.84rem', color: '#5a5c61', marginLeft: '1rem',// width: '5rem',
    fontWeight: '500', textAlign: 'left', whiteSpace: 'nowrap',
    '&:hover': { color: '#6610f2', textDecoration: 'underline', },//#6610f2
    //[theme.breakpoints.down('sm')]: { display: 'none', },
    display: 'none',
    [theme.breakpoints.down('sm')]: { display: 'block', },
    [theme.breakpoints.up('sm')]: { display: lMnuShortDisplay ? 'none' : 'block', },
  };

  let lMenuData = {
    'virtualVisit': {
      'title': sidebar('virtual_visit'),
      'iconCls': 'mdi mdi-video',
      'href': '/patient/virtualvisit',
      'isSelected': false
    },
    'myAppointments': {
      'title': sidebar('my_appointments'),
      'iconCls': 'mdi mdi-calendar-text',
      'href': '/patient/appointments',
      'isSelected': false
    },
    'niuSocial': {
      'title': sidebar('my_social'),
      'iconCls': 'mdi mdi-account',
      'href': HOME_BASE_URL,
      'isSelected': true
    },
    'subscriptionPayment': {
      'title': sidebar('subscription_and_payment'),
      'iconCls': 'mdi mdi-credit-card',
      'href': '/patient/subscription',
      'isSelected': false
    },
    'wallet': {
      'title': sidebar('wallet'),
      'iconCls': 'mdi mdi-wallet',
      'href': '/patient/wallet',
      'isSelected': false
    },
    'profile': {
      'title': sidebar('profile'),
      'iconCls': 'dripicons dripicons-user',
      'href': '/patient/profile',
      'isSelected': false
    }
  };

  let lMenuItems = [];
  for (let lMenu in lMenuData) {
    let selItemCss, mR = lMenuData[lMenu];
    if (mR.isSelected) {//#edf0f5
      selItemCss = { backgroundColor: '#e0e0e0', borderRadius: '0' };
    }
    const lnkTarg = (lMenu === 'niuSocial') ? HOME_BASE_URL :
      NIU_HEALTH_BASE_URL + mR.href + qToken;
    //lMenu !== 'niuSocial' && lMenuItems.push(
    !dBug && lMenu !== 'niuSocial' && lMenuItems.push(
      <IconButton key={'lMI_' + lMenu} disableRipple component={Link}
        sx={mItemBtnCss} className='mItem' style={selItemCss}
        to={lnkTarg}
      >
        <i className={mR.iconCls} style={mItemIconOverrides} ></i>
        <Typography component="h2"
          className="dCtnLMnuTxt" sx={mItemTxtCss}>
          {mR.title}
        </Typography>
      </IconButton>
    );
  }

  let mySocialSubMenuList = [], mySocialSubMenuAdminList = [], mySocialSubMenuAdminItem;
  const mySocialSubMenuItem = [//'iconCls': 'mdi mdi-home-plus',
    {
      'title': `${sidebar("submit_content")}`,
      'iconCls': 'mdi mdi-content-copy',
      'href': HOME_ROUTES.Submit.url,
      pgKey: 'submit',
      'isSelected': false
    },
    {
      'title': `${sidebar("leaderboard")}`,
      'iconCls': 'mdi mdi-google-analytics',
      'href': HOME_ROUTES.Leaderboard.url,
      pgKey: 'leaderboard',
      'isSelected': false
    },
    {
      'title': `${sidebar('social_networks')}`,
      'iconCls': 'mdi mdi-bullseye',
      'href': HOME_ROUTES.Settings.url,
      pgKey: 'settings',
      'isSelected': false
    }
  ];

  let selItemCss, gSelMItemCss = { backgroundColor: '#e0e0e0', borderRadius: '0' };
  mySocialSubMenuItem.forEach((mItem, ind) => {
    selItemCss = (mItem?.pgKey === currPage) ? gSelMItemCss : {}
    mySocialSubMenuList.push(
      /*<ListItem sx={{ pl: 8 }} key={'msSM_'+ind}>
          <ListItemText primary={mItem.title} />          
      </ListItem>*/
      <IconButton key={'msSM_' + ind} disableRipple
        sx={mItemBtnCss} className='mItem'
        style={{ ...selItemCss, width: '100%', padding: '18px 0' }}
        component={Link} to={mItem.href}
      >
        <i className={mItem?.iconCls}
          style={Object.assign({}, mItemIconOverrides, { marginLeft: '50px' })} ></i>
        <Typography className="dCtnLMnuTxt" component="h2" sx={mItemTxtCss}>
          {mItem.title}
        </Typography>
      </IconButton>
    );
  }
    //mySocialSubMenuList = (<>{mySocialSubMenuList}</>);
  );

  if (isAdmin) {
    mySocialSubMenuAdminItem = [
      {
        'title': sidebar('content_suggestions'),
        'iconCls': 'mdi mdi-content-duplicate',
        'href': 'admin/home',
        pgKey: 'admin/home', 'isSelected': false
      },
      //{'title': 'Content Suggestions', 'iconCls': 'mdi ',
      //  'href': 'admin/content-suggestions',
      //  pgKey: 'admin/content-suggestions', 'isSelected': false},
      {
        'title': sidebar('groups'),
        'iconCls': 'mdi mdi-account-multiple-outline',
        'href': 'admin/groups',
        pgKey: 'admin/groups', 'isSelected': false
      },
      /*{'title': 'Manage Feeds', 'iconCls': 'mdi mdi-chart-gantt',//mdi-book-open-page-variant
        'href': 'admin/manage-feeds',
        pgKey: 'admin/manage-feeds', 'isSelected': false},*/
      {
        'title': sidebar('reward_coins'),
        'iconCls': 'mdi mdi-gift',
        'href': 'admin/reward-points',
        pgKey: 'admin/reward-points', 'isSelected': false
      },
    ];
    mySocialSubMenuAdminItem.forEach((mItem, ind) => {
      const mItmCss = mItem?.pgKey === currPage ?
        { ...gSelMItemCss, width: '100%', padding: '18px 0' } :
        { width: '100%', padding: '18px 0' };
      const mItmIcnCss = Object.assign({}, mItemIconOverrides, { marginLeft: '50px' });
      mySocialSubMenuAdminList.push(
        <IconButton key={'msSMAdm_' + ind} disableRipple sx={mItemBtnCss}
          className='mItem' style={mItmCss} component={Link} to={mItem.href}
        >
          <i className={mItem?.iconCls} style={mItmIcnCss} ></i>
          <Typography className="dCtnLMnuTxt" component="h2" sx={mItemTxtCss}>
            {mItem.title}
          </Typography>
        </IconButton>
      );
    });
    mySocialSubMenuAdminList.push(
      <Divider key={'msSMAdmD_Sep'} sx={{ marginLeft: '3rem' }} />
    );
    mySocialSubMenuList = mySocialSubMenuAdminList.concat(mySocialSubMenuList);
  }
  const lMenuMySocial = lMenuData['niuSocial'];
  const mySocialMItem = (
    <Fragment key={'ctnLMI_niuSocial'}>
      <IconButton key={'lMI_niuSocial'} disableRipple sx={mItemBtnCss}
        className='mItem' style={currPage === 'home' ? gSelMItemCss : {}}
        onClick={fToggleMySocialMenu}
      >
        <i className={lMenuMySocial.iconCls} style={mItemIconOverrides}
          data-cid="lMnuIHome1"
        ></i>
        <Typography className="dCtnLMnuTxt" component="h2" sx={mItemTxtCss}
          data-cid="lMnuIHome2"
        >
          {lMenuMySocial.title}
        </Typography>
        {
          mySocialOpen ?
            <ExpandLess data-cid="lMnuICollp1" /> :
            <ExpandMore data-cid="lMnuICollp2" />
        }
      </IconButton>
      <Collapse in={mySocialOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {mySocialSubMenuList}
        </List>
      </Collapse>
    </Fragment>
  );
  lMenuItems.splice(2, 0, mySocialMItem);
  //----------------------

  /*const profilePicBox = (
    <Box sx={{ borderBottom: 'solid 0px #A8A8A8' }}>
      <ProfileInfoCard theme={theme} name={userName}
        profile={profilePic}
        //refetchProfileDetails={refetchProfileDetails}
        //isProfileDetailsLoading={isFetching}
      />
      <StyledCenterGrid item
        sx={{ display: { xs: 'block', lg: 'none', top: '75px',
            height: 'fit-content', padding: '14px', },
        }} xs={12} lg={4}
      >
        <Grid
          sx={{ position: 'sticky', height: 'fit-content', background: '#fff',
            borderRadius: '6px', padding: '16px 0px', marginTop: '20px',
          }}
        >
          <SMediaWidget theme={theme} network_size="1052" />
        </Grid>
        <Grid sx={{ marginTop: '20px' }}>
          <Leaderboard theme={theme} isShaddow={false}
            leaderboardUserData={leaderboardUserData}
          />
        </Grid>
        <Grid item xs={12}
          sx={{ backgroundColor: 'white', marginTop: '20px' }}
        >
          <ReferAndEarnCard theme={theme} />
        </Grid>
      </StyledCenterGrid>
    </Box>
  );*/

  const lnkLogOutSx = Object.assign({}, mItemBtnCss,
    { marginLeft: { md: '0px', lg: '0px' }, }
  );
  const btnLogout = dBug ? null : (
    //<PowerSettingsNewOutlinedIcon sx={mItemIconCss} />
    <Box className='xtBdr2'
      sx={{
        display: 'flex', flexDirection: 'column', gap: '4px',
        width: '100%',
        //position: 'absolute',
        position: 'relative',
        //position: dBug ? 'relative' : {xs: 'relative', md: 'absolute'},
        //bottom: {xs: '0', md: '5rem'},
        bottom: { xs: '0' },
      }}
    >
      <IconButton disableRipple id='btnSignout2' sx={lnkLogOutSx}
        onClick={fLogoutHandler}
      >
        <i className={'fas fa-sign-out-alt'} style={mItemIconOverrides}></i>
        {!lMnuShortDisplay && (
          <Typography component="h2" data-cid="lMnuISO"
            sx={mItemTxtCss} className="dCtnLMnuTxt"
          >
            {sidebar('logout')}
          </Typography>
        )}
      </IconButton>
    </Box>
  );

  const ctnMnuItems = (
    <Box id="ctnLMnu" //className='tBdr2' //PaperProps: {{ id: 'ctnLMnuBox' }}
      style={{ height: "calc(100% - 127px)" }}
      sx={{ overflowY: dBug ? 'auto' : null }}
    //style={dBug ? {overflowY: 'auto'} : null}
    >
      {/*profilePicBox*/}
      <Box sx={{ paddingTop: '0px', marginTop: '6px' }}>
        <ListItem disablePadding sx={{ paddingLeft: '0px', }}>
          <Box id="ctnLMnuItem"
            sx={{
              display: 'flex', flexDirection: 'column',
              width: '100%', gap: '0px', justifyContent: 'left',
            }}
          >
            {lMenuItems}
          </Box>
        </ListItem>
      </Box>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;
  //---------------------------------------------------------

  return (
    <Box>
      <Box sx={{
        display: { lg: 'flex', xs: 'block' },
        flexGrow: '1', backgroundColor: '#dae0e6',
      }}
      >
        <Box component="nav" aria-label="LtMenuDr"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 }, }}
        >
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Drawer variant="temporary" //ref={hemRef}
            className="NIUSideBarMainClass-5ooxmeUiLY"
            container={container}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{ keepMounted: true }} //BetterOpenPerformanceOnMobile.
            sx={{//border: '0px solid green',
              borderRight: '0px', marginTop: '75px', display: 'none',
              [theme.breakpoints.down('sm')]: { display: 'block', },
              "& .MuiDrawer-paper": { boxSizing: 'border-box', width: drawerWidth },
            }}
          >
            {ctnMnuItems}
            {btnLogout}
          </Drawer>
          <Drawer variant="permanent" id="ctnLMnuDrawer" open
            sx={{//position: 'fixed',
              position: { sx: 'static', md: 'fixed', },
              display: 'none',
              [theme.breakpoints.up('sm')]: { display: 'block', },
              "& .MuiDrawer-paper": {//width: drawerWidth,
                width: lMnuShortDisplay ? '80px' : drawerWidth,
                boxSizing: 'border-box', overflowY: 'clip', top: '69px',
                transition: '.3s',//-webkit-transition: '.3s',
                //transition: 'width .3s ease-in-out',
              },
            }}
          >
            {ctnMnuItems}
            {btnLogout}
          </Drawer>
        </Box>
      </Box>
    </Box>
  );
}
//---------------------------------------------------------

/*LeftMenu.propTypes = {
   //Injected by the documentation to work in an iframe.
   //You won't need it on your project.
  window: PropTypes.func,
};*/