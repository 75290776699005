import { Stack, TextField } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
//import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
//import dayjs from "dayjs";
import moment from "moment";
import { SA_DATE_DISP_FORM } from 'config/app.config';
import { /*fGetRefDates,*/ fConvertDispDateToDbDate } from 'lib/appFx';
//---------------------------------------------------------

export default function SelectDateRange({
  theme, startDate, endDate, setStartDate, setEndDate
}) {

  //const [startDate, setStartDate] = useState(
  //  dayjs("2014-08-18T21:11:54")
  //);
  //const sADateRefs = fGetRefDates();

  const selectStartdate = (newValue) => {
    //setStartDate(newValue);
    //const elTx = document.querySelector('#selDtFrom');
    //const att = 'disabled';
    //console.log('elTx '+newValue, elTx.getAttribute(att));
    //if(elTx) { elTx.removeAttribute(att); }
    setStartDate(fConvertDispDateToDbDate(newValue));
    //if(elTx) { elTx.setAttribute(att, true); }
  };
  const selectEndDate = (newValue) => {
    //setEndDate(newValue);
    setEndDate(fConvertDispDateToDbDate(newValue));
  };
  //---------------------------------------------------------

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Stack spacing={0.5} direction="row">
        <DesktopDatePicker //label={'Start'}
          inputFormat={SA_DATE_DISP_FORM} //"MM/DD/YYYY"
          disableMaskedInput={true}
          //mask={SA_DATE_DISP_FORM}
          value={startDate}
          onChange={selectStartdate}
          selected={startDate}
          maxDate={endDate}
          disableFuture
          InputAdornmentProps={{ sx: { marginLeft: "0px", }, }}
          OpenPickerButtonProps={{
            sx: { padding: "0px", marginRight: "-13px", },
          }}
          renderInput={(params) => (
            <TextField size="small" //id='selDtFrom'
              //disabled
              {...params}
              //inputProps={{ ...params.InputProps, readOnly: true }}
              inputProps={{ disabled: true }}
              value={moment(startDate).format(SA_DATE_DISP_FORM)}
              sx={{
                maxWidth: "9.5rem",
                background: theme.palette.background.paper,
              }}
            />
          )}
        />&nbsp;
        <DesktopDatePicker
          inputFormat={SA_DATE_DISP_FORM} //"MM/DD/YYYY"
          disableMaskedInput={true}
          //mask={SA_DATE_DISP_FORM}
          value={endDate}
          onChange={selectEndDate}
          selected={endDate}
          minDate={startDate}
          //disablePast
          disableFuture
          InputAdornmentProps={{ sx: { marginLeft: "0px", }, }}
          OpenPickerButtonProps={{
            sx: { padding: "0px", marginRight: "-13px", },
          }}
          renderInput={(params) => (
            <TextField size="small" //id='selDtTo'
				      //disabled
              {...params}
				      //inputProps={{ ...params.InputProps, readOnly: true }}
              inputProps={{ disabled: true }}
              value={moment(endDate).format(SA_DATE_DISP_FORM)}
              sx={{
                maxWidth: "9.5rem",
                background: theme.palette.background.paper,
              }}
            />
          )}
        />
      </Stack>
    </LocalizationProvider>
  );
}
