import { forwardRef, useRef, /*Dispatch*/ } from 'react';
import Header from 'core/Header/Header';
import LeftMenu from 'core/Sidebar/LeftMenu';
//---------------------------------------------------------

export default function AppMenu() {//Top+LtMenu
  const ltMRef = useRef(null);

  function fToggleDrawer() {
    ltMRef.current.fToggleDrawer();
  }
  const LeftMenuRef = forwardRef(LeftMenu);
  //---------------------------------------------------------

  return(
    <>
	  <Header onToggleMenuDrawer={fToggleDrawer} />
	  <LeftMenuRef ref={ltMRef} />
	</>
  );
};