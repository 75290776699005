import { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import {
  AppBar as MuiAppBar,
  Box,
  IconButton,
  Stack,
  styled,
  useTheme,
} from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
//import MenuIcon from '@mui/icons-material/Menu';
//import TopMenu from './TopMenu';
import { Logo } from 'core';
import { SA_IMG_BASE_URL, NIU_HEALTH_BASE_URL } from 'config/app.config';
//HOME_BASE_URL, SA_DEF_ORG_GROUP_ID
import { HOME_ROUTES } from 'config/routes.config';
import SettingsDropdownMenu from 'sections/settings/SettingsDropdownMenu';
import { useUserDetailsByIdQuery } from 'services/userApi';
import { isValidUrl } from 'lib/netFx';
import i18n from 'i18n';
//---------------------------------------------------------

const AppBar = styled(MuiAppBar)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 5,
  // padding: "0 15rem",
  backgroundColor: theme.palette.background.paper,
  // marginBottom: "1rem",
  [theme.breakpoints.down('md')]: { position: 'static', },
}));

const StyledStack = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-evenly',
  //gap: '2rem',
  gap: '1.4rem',
  height: '70px',
  paddingTop: '7px',
}));

const HeaderContainer = styled(Stack)(({ theme }) => ({
  //maxWidth: '1280px',//1450px
  width: '100%',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  margin: 'auto',
  [theme.breakpoints.down('xl')]: {
    //paddingLeft: '2rem',
    //paddingRight: '2rem',
  },
  //border: '2px solid green'
}));
//---------------------------------------------------------

export default function Header(props) {
  const theme = useTheme();
  const { pathname } = useLocation();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);//RtMenu
  const userAuthId = useSelector(state => state.auth.details?.id);
  const { data: userData, refetch } = useUserDetailsByIdQuery(
    { userId: userAuthId },
    { skip: !userAuthId }
  );
  const fHandleCloseRtMenu = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };
  const fHandleToggleRtMenu = () => { setOpen((pre) => !pre); };
  const ctnRtMnuId = open ? 'simple-popper' : undefined;

  useEffect(() => {
    if (pathname) { setOpen(false); }
  }, [pathname]);

  useEffect(() => {
    if (userAuthId) {
      refetch(); // Manually trigger the refetch when the component mounts
    }
  }, [userAuthId, refetch]);

  useEffect(() => {
    const userDetail = userData?.users[0];
    i18n.changeLanguage(userDetail?.language_code);
    localStorage.setItem('language_code', userDetail?.language_code || 'en');
  }, [userData])

  //----------------------

  /*const Container = styled(Stack)(({ theme }) => ({
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: '1020px',
    display: 'block',
    [theme.breakpoints.down('xl')]: {
      paddingRight: '24px',
      paddingLeft: '24px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    [theme.breakpoints.down('md')]: {
      paddingLeft: '0.5rem',
      paddingRight: '0.5rem',
    },
  }));
  const buttonSX = {
    width: '120px',
    '&:hover': { border: '1px solid #EDEFF1', },
  };*/
  //----------------------
  const userDetail = userData?.users[0];
  let userName, profilePic;
  if (userDetail?.email) {
    userName = userDetail?.first_name;// + ' ' + userDetail?.last_name;
    profilePic = isValidUrl(userDetail?.medias?.profile?.url) ?
      userDetail?.medias?.profile?.url : SA_IMG_BASE_URL + '/avtar1.png';
  }

  const uNameCss = {
    fontFamily: 'Poppins, sans-serif',
    margin: '17px',
    fontSize: '13.52px', color: '#53b143',
    fontWeight: '400', textAlign: 'left', whiteSpace: 'nowrap',
    '&:hover': { color: '#6610f2', },
    //display: {xs: 'none', vsm: 'none', sm: 'block'},
    [theme.breakpoints.down('sm')]: { display: 'none', },
  };
  //---------------------------------------------------------

  return (
    <>
      <AppBar //elevation={2}
        position="fixed" //position="sticky"
        sx={{
          width: { lg: '100%' },
          minHeight: '70px',
          ml: {
            //lg: `${drawerWidth}px`,
            backgroundColor: '#fff',
            boxShadow: 'none',
          },
        }}// className='tBdr2'
      >
        <Toolbar
          sx={{
            borderBottom: '0px solid green', maxHeight: '70px',
            width: '100%',// width:'1276px'
            //paddingLeft: '1.25rem',
          }}
          style={{ paddingLeft: '1.25rem' }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={props.onToggleMenuDrawer}
            //sx={{ mr: 2, display: { lg: 'none' } }}
            sx={{ padding: '2px 16px 8px 14px', marginRight: '6px', }}
          //disableScrollLock={true}
          >
            <i className='ti-menu' style={{ fontSize: '1rem', color: '#7081b9' }}></i>
            {/*<MenuIcon />*/}
          </IconButton>
          <HeaderContainer>
            {/*<StyledStack direction="row" alignItems="center">
              <Box paddingTop={1} component={Link} to={HOME_ROUTES.Home.url}>
                <Logo size="small" />
              </Box>
              {searchBar}
            </StyledStack>*/}
            {/*<TopMenu />*/}
            <StyledStack direction="row" alignItems="center">
              {/*<MenuIcon fontSize='small' />
              <Box  sx={{border:'0px solid red',
                  display: {xs: 'none', sm: 'none', md:'none', lg: 'inline', xl: 'inline'},
                  marginTop: {md:'-10px',},
                }} onClick={fHandleLMenuToggle}
              >
                <i className='ti-menu' style={{fontSize: '1rem', color: '#7081b9'}}></i>
              </Box>*/}
              <Box paddingTop={0} component={Link} to={NIU_HEALTH_BASE_URL + '/patient'}
                sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingTop: '0px' }}
              >
                <Logo size="small" />
              </Box>
            </StyledStack>
            {/* searchBar */}

            <IconButton
              aria-describedby={ctnRtMnuId}
              onClick={fHandleToggleRtMenu}
              ref={anchorRef}
              sx={{
                display: 'flex', flexDirection: 'row',
                padding: '0 6px 6px 6px',
                '&:hover': { background: '#fff', },
              }}
            >
              <Typography variant="p" component="p" sx={uNameCss}>
                {userName}
              </Typography>&nbsp;
              <div style={{ height: "50px", width: "50px" }}>
                <img src={profilePic} height="100%" width="100%" alt='profile' title='' style={{ borderRadius: '50%', objectFit: "cover" }} />
              </div>
              {/*<PersonOutlineOutlinedIcon sx={mItemIconCss} />
              <ExpandMoreIcon />*/}
            </IconButton>
          </HeaderContainer>
        </Toolbar>
      </AppBar>
      <SettingsDropdownMenu
        id={ctnRtMnuId}
        open={open}
        anchorEl={anchorRef.current}
        handleClose={fHandleCloseRtMenu}
      />
    </>
  );
};
