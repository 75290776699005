import { useTheme } from "@mui/material";//@emotion/react
import { Box } from "@mui/material";
//import AdmGroupList from './AdmGroupList';
import EditNetwork from 'sections/settings/EditNetwork';
import TransferList from './AdmManageFeedForm';
//---------------------------------------------------------

export default function AdmManageFeeds({ theme, title='Manage Feeds' }) {
  theme = useTheme();
	//---------------------------------------------------------

	return (
		<>
			<Box className="">
				<h2 className='pgHd'>{title}</h2>
			</Box>
			<Box className="xpadL xtBdr2" sx={{marginTop: '2rem'}}>
				<EditNetwork theme={theme} /><br/>
				<TransferList />
			</Box>
		</>
	);
};