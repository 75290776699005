import { useState, useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
//import { skipToken } from "@reduxjs/toolkit/dist/query";
//import moment from 'moment';
import { Grid } from "@mui/material";
import InfiniteScroll from "react-infinite-scroller";
import {
  FB_APP_ID, SA_DEF_ORG_GROUP_ID,
  TEMP_HIDE_USER_POST_IDS, DEF_ENG_POINT, //DEFAULT_ENG_POINTS
} from 'config/app.config';
import { fGetPostEngPoints, fParsePostEngPoints, } from 'lib/appFx';
import { actionObject, validateObj } from 'lib/utils';
import {
  useFeedActionMutation, getFeedListMetaData,
  //getSMFilterParams, useUserFeedListQuery,
  //getUserSubmittedFeed, useOrgFeedQuery,
} from 'services/orgFeedApi';
//import { getSMediaMgr } from 'services/sMediaApi';
import { CongratsPostForm, FeedCard } from 'sections';
import Spinner2 from 'core/Spinner/Spinner2';
import { useTranslation } from "react-i18next";
//---------------------------------------------------------

export default function FeedList({
  theme, updated, setUpdated, page, setPage,
  highlightPosts, refetchLeaderboardUserData,
  //getSMFilterParam, isUserList, refetchFeedQuery,
  feedQuery, setFeedQuery, dDataA, dErrA, dWaitA, dOKA, dDataFetching,
  feedData, setFeedData, paginationData,
  isFetchingData, setIsFetchingData,
}) {
  // const getUserSubmittedFeeds = useSelector(getUserSubmittedFeed);
  // const [feedQuery, setFeedQuery] = useState(skipToken);
  //const [skipCall, setSkipCall] = useState(true);

  // const {
  //   data: dDataA,
  //   error: dErrA,
  //   isLoading: dWaitA,
  //   isSuccess: dOKA,
  //   isFetching: dDataFetching,
  //   refetch: refetchFeedQuery,
  // } = useOrgFeedQuery(feedQuery, { refetchOnMountOrArgChange: true });

  /*const {//DummyCallRequired??
    data: dDataB,
    error: dErrB,
    isLoading: dWaitB,
    isSuccess: dOKB,
    isFetching: dDataFetchingB,
    refetch: refetchFeedQueryB,
  } = useUserFeedListQuery({ hello: 'helloworld' });*/
  // let isFetchingData = false;
  // const getSMFilterParam = useSelector(getSMFilterParams);
  const { t: home } = useTranslation('home')
  const [showCongratsFeedForm, setShowCongratsFeedForm] = useState({
    isOpen: false,
  });
  const feedListMeta = useSelector(getFeedListMetaData);
  //console.log('feedListMeta', feedListMeta);

  useEffect(() => {
    if (updated === 'filterStarted') {
      setFeedData([]);
    }
    if (updated === 'filterLoading' && !dDataFetching) {
      setUpdated('filterEnded');
    }
  }, [updated, dDataFetching]);
  // const getSMediaTokens = useSelector(getSMediaMgr);

  // const getSMediaTokensFunction = (actParams) => {
  //   return getSMediaTokens[actParams.destSMKey].sMTokens.properties[0].id
  // }

  const [feedPageState, setFeedPageState] = useState(1);
  const fLoadMoreFeedHandler = () => {
    setFeedPageState(feedPageState + 1);
  };

  let dErr, dWait, dData;

  // useEffect(() => {
  //   if (authUsrData && page && feedListModifiers)
  //     if (validateObj(feedListModifiers)) {
  //       const prepareParams = {
  //         page,
  //         orgId: authUsrData?.orgId,
  //         orgGroupIds: feedListModifiers?.selGroups || [],
  //         filterSMIds: getSMFilterParam,
  //       };

  //       if (feedListModifiers?.selGroupsfilterSMIds) {
  //         prepareParams['filterSMIds'] =
  //           feedListModifiers?.selGroupsfilterSMIds;
  //       }
  //       setFeedQuery(prepareParams);
  //       if (updated === 'filterStarted') {
  //         setUpdated('filterLoading');
  //       }
  //       // setSkipCall(false);
  //     }
  // }, [authUsrData, feedListModifiers, page]);

  // const [paginationData, setPaginationData] = useState({});

  const loadMore = () => {
    if (isFetchingData && updated === 'filterEnded') {
      if (page + 1 <= paginationData?.page?.total && !dDataFetching) {
        setFeedQuery({ ...feedQuery, page: page + 1 });
        setPage(page + 1);
        setIsFetchingData(false);
      }
    }
  };

  dErr = dErrA;
  dWait = dWaitA;
  dData = dOKA && dDataA ? dDataA : null;

  const [fFeedAct, { data, reset: fResetFA }] = useFeedActionMutation();

  function UserException(message) {
    this.code = 1234;
    this.message = message;
  }

  const fFeedActionHandler = async (actParams, fCallback, errCb) => {
    const postActionDone = actParams.feedData.engagements.user.map((data) => {
      return data.type;
    });
    const postInf = actParams?.pInf;
    const srcSMKey = postInf.srcKey;
    const destSMKey = actParams.destSMKey;
    const postPlatform =
      actParams.feedListMeta.sMIdKeyMap[actParams.postId.split('_')[0]];
    let faResp, actType = actParams.actType;//, defaultPointValue;
    const dest = actParams?.feedListMeta?.sMediaMgr[destSMKey]?.sMKey;
    const currGroupId = feedListMeta?.feedListModifiers.selGroups[0] || SA_DEF_ORG_GROUP_ID;

    try {
      const destSMId =
        actParams?.feedListMeta?.sMediaMgr[destSMKey]?.sMId;

      const sMAuthTokenId =
        actParams?.feedListMeta?.sMediaMgr[destSMKey]['activTokenId'];
      if (sMAuthTokenId || destSMKey === 'em' || destSMKey === 'wa') {
        let faParams;
        switch (dest) {
          case 'tw':
            faParams = {
              feed_id: actParams.postId,
              snetworkplatform_id: destSMId,
              action: actionObject(postPlatform, dest, actType) || 'quote_share',
              snetworktoken_id: sMAuthTokenId,
              snetworkproperty_id:
                actParams.feedListMeta.sMediaMgr[destSMKey].sMTokens
                  .properties[0].id,
              group_id: currGroupId,
            };
            /*if(faParams.action === 'retweet') {
              defaultPointValue = DEFAULT_ENG_POINTS.tw.share;// 50;
            } else {
              defaultPointValue = DEFAULT_ENG_POINTS.tw.share;//100;
            }//console.log('faParamsTTTT', faParams);
            */
            break;

          case 'fb':
            faParams = {
              feed_id: actParams.postId,
              snetworkplatform_id: destSMId,
              action: actionObject(postPlatform, dest, actType) || 'share',
              group_id: currGroupId,
            };
            //defaultPointValue = DEFAULT_ENG_POINTS.fb.share;
            break;

          case 'ln':
            faParams = {
              feed_id: actParams.postId,
              snetworkplatform_id: destSMId,
              action: actionObject(postPlatform, dest, actType) || 'share',
              snetworktoken_id: sMAuthTokenId,
              snetworkproperty_id:
                actParams.feedListMeta.sMediaMgr[destSMKey].sMTokens
                  .properties[0].id,
              group_id: currGroupId,
              //url: actParams.pUrl,
            };
            //Share is not working but qShare is coz we quote a post to Ln..
            actParams.text = '_';
            const qText = actParams?.text || actParams?.pText;
            if (qText && qText.length) {
              faParams.action = 'quote_share';
              faParams.text = qText;
            }
            //defaultPointValue = DEFAULT_ENG_POINTS.ln.share;
            break;

          case 'ig':
            faParams = {
              feed_id: actParams.postId,
              snetworkplatform_id: destSMId,
              action: actionObject(postPlatform, dest, actType) || 'post',
              snetworktoken_id: sMAuthTokenId,
              snetworkproperty_id:
                actParams.feedListMeta.sMediaMgr[destSMKey].sMTokens
                  .properties[0].id,
              group_id: currGroupId,
            };
            //defaultPointValue = DEFAULT_ENG_POINTS.ig.share;
            break;

          default:
            break;
        }

        if (faParams.action === 'retweet') {
          if (actParams.feedData[faParams.action] === true) {
            throw new UserException('Tweet already retweeted');
          } else {
            postActionDone.forEach((data) => {
              if (data === 'retweet') {
                throw new UserException('Tweet already retweeted');
              }
            });
          }
        }

        actParams?.pText && (faParams['text'] = actParams?.pText);
        actParams?.snetworkproperty_id &&
          actParams?.scheduleOn &&
          (faParams['execute_on'] = actParams?.scheduleOn);

        faResp = await fFeedAct(faParams).unwrap();
        //faResp = {engagement_id: '123'};

        if (faResp?.engagement_id) {
          if (faParams.action === 'retweet') {
            actParams.feedData[faParams.action] = true;
          }

          // Object.assign(actParams.feedData.engagements.user, [...actParams.feedData.engagements.user,{type:"retweet"}])
          // actParams.feedData.engagements.user = [...actParams.feedData.engagements.user,{type:"retweet"}]
          if (fCallback) {
            //==============================
            let postPtsData = postInf?.engPoints;
            /*if(!postPtsData) {
              postPtsData = fParsePostEngPoints(srcSMKey,
                feedData?.points, actParams.feedListMeta.sMIdKeyMap);
            }*/
            const postEngPts = fGetPostEngPoints(srcSMKey, postPtsData);
            let pointsEarned = postEngPts[srcSMKey][destSMKey]['engPoints'];
            if (faResp?.points_earned) {
              pointsEarned = faResp?.points_earned;
            }
            //==============================
            let points = actParams?.feedData?.points?.find((fd) => {
              return (
                fd.snetworkplatform_id === faParams.snetworkplatform_id &&
                fd.engagement_type === faParams.action
              );
            });
            //if(!points) { points = { points: defaultPointValue, }; }
            if (!points) { points = { points: DEF_ENG_POINT, }; }
            if (destSMKey && destSMKey === 'fb') {
              setTimeout(async () => { await refetchLeaderboardUserData() }, 4500);
            } else { await refetchLeaderboardUserData(); }
            await fCallback(faResp);
            const confEngParam = {
              ...showCongratsFeedForm,
              isOpen: true,
              platform:
                actParams.feedListMeta.sMIdKeyMap[faParams.snetworkplatform_id],
              points,
              pointsEarned,
              action: faParams.action,
            };
            if (destSMKey && destSMKey === 'fb') {
              setTimeout(() => { setShowCongratsFeedForm(confEngParam); }, 4500);
            } else {
              setShowCongratsFeedForm(confEngParam);
            }
          }
        }
      }
    } catch (err) {
      //let errCode = err?.data?.error?.code || err?.data?.result?.error?.code || err?.code;
      if (err?.data?.error?.code === 2506 || err.code === 2506) {//LnDuplicateEngagements
        errCb(`${home('you_have_already_shared_this_post')}`);
      } else if (err?.data?.result?.error?.code === 2505) {
        errCb(err?.data?.result?.error?.message);
      } else if (err.code === 1234) {
        errCb(err.message);
      } else {
        errCb(`${home('an_error_occurred_in')} ${actType} + . ${home('please_try_again')}`);
      }
      console.error('feedEngErr', err);
    }
    fResetFA();
  };
  let fList = dData?.feed;
  let sNetworkP = dData?.snetwork_properties;
  useEffect(() => {
    window.fbAsyncInit = () => {
      window.FB.init({
        appId: FB_APP_ID,
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v11.0',
      });
    };
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
    // }
  }, []);
  //console.log("feedData", feedData);
  //console.log("highlightPosts", highlightPosts);
  if (highlightPosts.length) {
    let selPosts = [], newFeedData = [], orderedSelPost = [];
    //feedData = feedData.filter((f, i) => {
    feedData.forEach((f, i) => {
      if (highlightPosts.includes(f.id)) {
        selPosts.push(f);//.splice(1, 1);
      } else {
        newFeedData.push(f);
      }
    });
    if (selPosts.length) {
      highlightPosts.forEach((id) => {
        selPosts.forEach((f) => {
          if (id === f.id) {
            orderedSelPost.push(f);
          }
        });
      });
    } else { orderedSelPost = selPosts; }
    feedData = [...orderedSelPost, ...newFeedData];
  }

  const tempHidePostIds = TEMP_HIDE_USER_POST_IDS ?? [];
  //---------------------------------------------------------

  return (
    <>
      {dWait || updated === 'filterStarted' || updated === 'filterLoading' ? (
        page > 1 && <Spinner2 />
      ) : dErr ? (//'Error fetching feeds! Please try again later.  .'
        `${home('no_feeds_for_selected_filter')} ${home('please_try_again_later')} .`
      ) : (
        <InfiniteScroll
          style={{
            width: '100%',
          }}
          initialLoad={true}
          loadMore={loadMore}
          threshold={10}
          useWindow={true}
          hasMore={isFetchingData}
          datalength={Object.keys(feedData).length}
          loader={
            <div key={'fLLoad_' + page} style={{ textAlign: 'center', pagging: '5px' }}>
              <Spinner2 />
            </div>
          }
        >
          {paginationData ? (
            feedData
              //.filter((data) => data.text)
              //.sort((a, b) => moment(b.created_at) - moment(a.created_at))
              ?.map((fd) => (
                fd.id !== 'a1R3BYmO_v5ean8xx' && (
                  !tempHidePostIds.includes(fd.id) && (
                    <Grid item xs={12} key={'fL_' + fd.id}
                      marginTop="12px" marginBottom="1rem"
                    >
                      <FeedCard
                        sNetworkP={sNetworkP}
                        theme={theme}
                        feedListMeta={feedListMeta}
                        feedData={fd}
                        onFeedEngagement={fFeedActionHandler}
                        showCongratsFeedForm={showCongratsFeedForm}
                        setShowCongratsFeedForm={setShowCongratsFeedForm}
                        defEngPoints=""
                        isUserList={false}
                      // handleSocialEngagementActions={handleSocialEngagementActions}
                      />
                    </Grid>
                  ))
              ))
          ) : (
            <>
              <h3>{home('no_post_available')}</h3>
            </>
          )}
        </InfiniteScroll>
      )}


      <CongratsPostForm
        actionData={data}
        authUser={feedListMeta?.authUserData}
        showCongratsFeedForm={showCongratsFeedForm}
        onCloseCongratsFeedForm={() => {
          setShowCongratsFeedForm({ ...showCongratsFeedForm, isOpen: false });
        }}
      />
    </>
  );
}
//   <>
//   {dWait || updated === 'filterStarted' || updated === 'filterLoading' ? (
//     <Spinner2 />
//   ) : dErr ? (
//     'Error fetching feeds! Please try again later.  .'
//   ) : (
//     <InfiniteScroll
//       style={{
//         width: '100%',
//       }}
//       initialLoad={true}
//       loadMore={loadMore}
//       threshold={1}
//       useWindow={true}
//       hasMore={isFetchingData}
//       datalength={Object.keys(feedData).length}
//     >
//       {paginationData ? (
//         feedData
//           .filter((data) => data.text)
//           .sort((a, b) => moment(b.created_at) - moment(a.created_at))
//           .map((fd) => (
//             //fd.id !== 'a1R3BYmO_v5ean8xx' && (
//             <Grid
//               item
//               xs={12}
//               marginTop="12px"
//               marginBottom="1rem"
//               key={'fL_' + fd.id}
//             >
//               <FeedCard
//                 sNetworkP={sNetworkP}
//                 theme={theme}
//                 feedListMeta={feedListMeta}
//                 feedData={fd}
//                 onFeedEngagement={fFeedActionHandler}
//                 showCongratsFeedForm={showCongratsFeedForm}
//                 setShowCongratsFeedForm={setShowCongratsFeedForm}
//                 defEngPoints=""
//                 isUserList={isUserList}
//                 // handleSocialEngagementActions={handleSocialEngagementActions}
//               />
//             </Grid>
//           ))
//       ) : (
//         <>
//           <h3>No post available</h3>
//         </>
//       )}
//     </InfiniteScroll>
//   )}

//   <CongratsPostForm
//     authUser={feedListMeta?.authUserData}
//     showCongratsFeedForm={showCongratsFeedForm}
//     onCloseCongratsFeedForm={() => {
//       setShowCongratsFeedForm({ ...showCongratsFeedForm, isOpen: false });
//     }}
//   />
//   {/* {fList} */}
//   {/*FeedActionModals*/}
// </>

{
  /* {fList} */
}
{
  /*FeedActionModals*
</> */
}
