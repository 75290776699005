//import { createSlice } from "@reduxjs/toolkit";
import { sSApi } from '../sSApi';
//---------------------------------------------------------

export const admGroupsApi = sSApi.injectEndpoints({
  endpoints: (builder) => ({
    groupList: builder.query({
      keepUnusedDataFor: 10,
      query: ({ page }) => {
        page = page || 1;
        return {
          url: '/group?page='+page,
        };
      },
    }),
    groupDetails: builder.query({
      keepUnusedDataFor: 10,
      query: (params) => {
        return {
          url: '/group/' + params.groupId,
        };
      },
    }),
    groupCreate: builder.mutation({
      query: ({ params }) => {
        //console.log('groupCreate', params);
        return {
          url: '/group',
          method: 'POST',
          body: params,
        };
      },
    }),
    groupUpdate: builder.mutation({
      query: ({groupId, params}) => {
        console.log('upGrpArgs', {groupId, params});
        return {
          url: '/group/' + groupId,
          method: 'POST',
          body: params,
        };
      },
    }),
    groupDelete: builder.mutation({
      query: (params) => {
        return {
          url: '/group/' + params.groupId,
          method: 'DELETE',
        };
      },
    }),
    //---------------------------------------------------------

    groupMemberList: builder.query({
      //keepUnusedDataFor: 10,
      query: ({ groupId, page, filter=null }) => {
        let aUrl = 'group/'+groupId+'/member?page='+page;
        if(filter) {
          aUrl +='&filter_json='+JSON.stringify(filter);
        }
        return {
          url: aUrl,
        };
      },
    }),
    groupAddMember: builder.mutation({
      query: (params) => {
        return {
          url: 'group/'+params.groupId+'/member',
          method: 'POST',
          body: params,//user_ids []
        };
      },
    }),
    groupDeleteMember: builder.mutation({
      query: (params) => {
        return {
          url: 'group/'+params.groupId+'/member?user_ids='+params.user_ids,
          method: 'DELETE',
        };
      },
    }),
    //---------------------------------------------------------

    groupSubscribeFeed: builder.mutation({
      query: (params) => {
        return {
          url: 'group/'+params.groupId+'/subscribe',
          method: 'POST',
          body: params,
        };
      },
    }),
    groupUnsubscribeFeed: builder.mutation({
      query: (params) => {
        return {
          url: 'group/'+params.groupId+'/unsubscribe',
          method: 'POST',
          body: params,
        };
      },
    }),
  }),
});
//---------------------------------------------------------

export const {
  useGroupListQuery,
  useGroupDetailsQuery,
  useLazyGroupDetailsQuery,
  useGroupCreateMutation,
  useGroupUpdateMutation,
  useGroupDeleteMutation,

  useGroupMemberListQuery,
  useGroupAddMemberMutation,
  useGroupDeleteMemberMutation,

  useGroupSubscribeFeedMutation,
  useGroupUnsubscribeFeedMutation,
} = admGroupsApi;
