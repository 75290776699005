import { Close } from "@mui/icons-material";
import {
  Checkbox,
  IconButton,
  InputLabel,
  Stack,
  styled,
  TextField,
} from "@mui/material";
import { Button, FormCheckbox, FormProvider, Modal, Typography } from "core";
import React from "react";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { socialMedia } from "config/ui.config";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker as DatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
//import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { grey } from "@mui/material/colors";
import { DesktopTimePicker } from "@mui/x-date-pickers/DesktopTimePicker";
import { useForm } from "react-hook-form";
import moment from "moment";

const StyledViewAllButton = styled(Button)(({ theme }) => ({
  background: theme.palette.background.paper,
  color: grey[600],
  border: "2px solid grey",
  boxShadow: "none",
  fontSize: "1rem",
  maxWidth: 250,
  display: "flex",
  justifyContent: "center",
  alignSelf: "center",
  textAlign: "center",
  "&:hover": {
    background: theme.palette.background.paper,
  },
}));

export default function SchedulePostForm({
  theme,
  showScheduleFeedForm,
  onCloseScheduleFeedForm,
  post,
  shareOn,
  srcSMKey,
  fFeedAct,
}) {
  const defaultValues = {
    date: Date.now(),
    time: Date.now(),
    ln: false,
    tw: false,
    em: false,
  };
  const methods = useForm({
    defaultValues,
  });
  const { handleSubmit, setValue, watch } = methods;

 function fFeeedEngagementCallback(resp) {
	//onCloseCreateFeedForm();
    //setShowCongratsFeedForm((prev) => !prev);
    //setQuoteText(null);
 }
  //const onSubmit = async (data) => {
  const onSubmit = (data) => {
	let engDate = new Date(data?.date);
	engDate && (engDate = engDate.toISOString().split('T')[0]);
	//let engTime = data?.time;
	//engTime && (engTime = engTime.toISOString().split('T')[1]);
	//let engTime = moment(data?.time).format("HH:MM A");

	let engTime = moment(data?.time).format("HH:MM");
	let destSMs = [];
	data?.ln && destSMs.push("ln");
    data?.tw && destSMs.push("tw");
    data?.em && destSMs.push("em");
	if(destSMs.length) {
		let faArgs = {
			postId: post.id,//destSMKey: destSMKey,
			actType: 'share',
			execute_on: engDate+'T'+engTime+':00'
		}
		destSMs.forEach((sM)=> {
			faArgs['destSMKey'] = sM;
			fFeedAct(faArgs, fFeeedEngagementCallback);
		});
	}
  };

  return (
    <Modal open={showScheduleFeedForm} onClose={onCloseScheduleFeedForm}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={2}>
        <Stack spacing={2} direction="row" justifyContent="space-between">
          <Stack spacing={1} direction="row" alignItems="center">
            <AccessTimeIcon
              sx={{ color: theme.palette.primary.dark, height: 25, width: 25 }}
            />
            <Typography variant="h6" size="medium">
              Schedule Post
            </Typography>
          </Stack>
          <IconButton onClick={onCloseScheduleFeedForm}>
            <Close color="primary" />
          </IconButton>
        </Stack>
        <Stack>
          {/*<InputLabel size="medium">Schedule Date</InputLabel>*/}
		  <InputLabel size="normal">Schedule Date</InputLabel>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              toolbarPlaceholder="Select Date to schedule post"
                disablePast
                name="date"
                value={watch("date")}
              onChange={(newValue) => {
                  setValue("date", newValue.$d);
              }}
              renderInput={(params) => (
                <TextField {...params} sx={{ width: "20rem" }} />
              )}
            />
          </LocalizationProvider>
        </Stack>
        <Stack>
          {/*<InputLabel size="medium">Schedule Time</InputLabel>*/}
		  <InputLabel size="normal">Schedule Time</InputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopTimePicker
                name="time"
                value={watch("time")}
                onChange={(newValue) => {
                  setValue("time", newValue.$d);
            }}
            inputProps={{
              step: 300, // 5 min
            }}
                renderInput={(params) => (
                  <TextField {...params} sx={{ width: "20rem" }} />
                )}
                components={{
                  OpenPickerIcon: (props) => <AccessTimeIcon {...props} />,
                }}
          />
            </LocalizationProvider>
        </Stack>
          <div role="group" aria-labelledby="checkbox-group">
        <Stack spacing={2} direction="row" alignItems="center">
          <Stack direction="row" alignItems="center">
                <FormCheckbox name="tw" value="tw" />
            {socialMedia["twitter"]}
          </Stack>
          <Stack direction="row" alignItems="center">
                <FormCheckbox name="ln" value="ln" />
            {socialMedia["linkedIn"]}
          </Stack>

          <Stack direction="row" alignItems="center">
                <FormCheckbox name="em" value="em" />
            <MailOutlineIcon />
          </Stack>
        </Stack>
          </div>
        <Stack
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
          direction="row"
        >
            <StyledViewAllButton type="submit">Save</StyledViewAllButton>
          <Typography>Total points you will earn: 200</Typography>
        </Stack>
      </Stack>
      </FormProvider>
    </Modal>
  );
}
