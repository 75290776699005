// ----------------------------------------------------------------------

const breakpoints = {
  values: {
    xs: 0,
    vsm: 425,
    sm: 600,
    smp: 768,//sm+
    md: 900,
    lg: 1200,
    xl: 1536
  }
};

export default breakpoints;
