import { FormHelperText, Stack, TextField } from "@mui/material";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";

const INPUT_FIELD_WIDTH = "25vw";
const INPUT_FIELD_BORDER_RADIUS = "5rem";

function FormInputField({ name, ...rest }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Stack direction="column">
          <TextField
            fullWidth
            {...field}
            size="small"
            error={!!error}
            InputProps={{
              style: {
                backgroundColor: (theme) => theme.palette.background.paper,
                minWidth: INPUT_FIELD_WIDTH,
                borderRadius: INPUT_FIELD_BORDER_RADIUS,
                WebkitBoxShadow: "0 0 0 1000px white inset",
              },
            }}
            {...rest}
          />
          <FormHelperText error>{error?.message}</FormHelperText>
        </Stack>
      )}
    />
  );
}

export default FormInputField;
